/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useCallback, useState, useEffect } from 'react';
import { TBatch, TDomainReasonsData, TScreeningTag } from './';
import { TExclusionReason } from '../../screening';
import { Stage, StageType } from '../../../common/types';
import {
  DecisionFilter,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveScreeningTagFilters,
  TPdfFilter,
  YearFilters,
} from '../../../apollo/screening_state';
import PreviewColumn from './preview_column';
import ReferencesListColumn from './references_list_column';
import FiltersColumn from './filters_column';
import { panelCollapsedColCss, panelExpandedColCss, TScreeningPanel } from '../../screening/list';
import { Classes } from '@blueprintjs/core';
import { TReferenceSearchData } from './references_list_layouts';
import ListHeader from './list_header';
import { useScreeningFilters } from '../../hooks/use_screening_filters';

export interface IYearsRangeFilterState {
  min: number;
  max: number;
}

interface IListProps {
  projectId: string;
  decisionFilter: DecisionFilter;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  references: TReferenceSearchData[];
  openFocusMode: () => void;
  activeReference: number;
  selectedReferences: number[];
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  // FIXME: setActiveReference is no longer needed to be passed as prop. It is kept in local state
  // and can be changed with mutation
  setActiveReference: (number) => void;
  handleActiveReferenceSelect: () => void;
  decisionReasons: TExclusionReason[];
  domains: TDomainReasonsData;
  filtersApplied: boolean;
  searchingReferences: boolean;
  searchTokens: string[];
  screeningCompleted: boolean;
  orderedBy: object;
  loadingMoreReferences: boolean;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  openImportReferencesDialog: () => void;
  openImportPDFsDialog: () => void;
  openExportReferencesDialog: () => void;
  batches: TBatch[];
  activeBatchKey: string | null;
  onlyWithComments: boolean;
  pdfFilter: TPdfFilter;
  onlyWithoutAbstract: boolean;
  allReferencesCount: number;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  onRemoveReferences: (referenceIds: string[], reason: string) => Promise<any>;
  onRestoreReferences: (referenceIds: string[]) => Promise<any>;
  onResetReferencesNoReportField: (referenceIds: string[]) => Promise<any>;
  refetchReferences: () => void;
  isDuplicatesList: boolean;
  projectStageTypes: StageType[];
  allReferencesSelected: boolean;
  onSelectAllReferences: () => void;
  formId?: string;
  stage?: Pick<Stage, 'id' | 'completed' | 'forms' | 'study_pools' | 'type'>;
  resolvedConflictsCount?: number;
  onLoadMoreReferences?: () => void;
  referencesSearchArgs?: object;
}

const List: React.FC<IListProps> = ({
  projectId,
  decisionFilter,
  references,
  openFocusMode,
  activeReference,
  selectedReferences,
  setSelectedReferences,
  setActiveReference,
  decisionReasons,
  domains,
  filtersApplied,
  searchingReferences,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  activeYearFilters,
  searchPhraseTokens,
  screeningCompleted,
  onLoadMoreReferences,
  orderedBy,
  loadingMoreReferences,
  documentTypes,
  yearsFilterData,
  openImportReferencesDialog,
  openImportPDFsDialog,
  openExportReferencesDialog,
  batches,
  activeBatchKey,
  onlyWithComments,
  pdfFilter,
  onlyWithoutAbstract,
  allReferencesCount,
  screeningTags,
  activeScreeningTagFilters,
  refetchReferences,
  projectStageTypes,
  formId,
  stage,
  resolvedConflictsCount,
  onRemoveReferences,
  onRestoreReferences,
  isDuplicatesList,
  onResetReferencesNoReportField,
  searchTokens,
  allReferencesSelected,
  onSelectAllReferences,
  referencesSearchArgs,
}) => {
  const [yearsRangeFilterState, setYearsRangeFilterState] =
    useState<IYearsRangeFilterState>(yearsFilterData);

  const [activePanel, setActivePanel] = useState<TScreeningPanel | null>(null);
  const togglePanel = useCallback(
    (panel) => setActivePanel((current) => (current === panel ? null : panel)),
    [setActivePanel]
  );
  const { clearFilters, resetYearsFilter } = useScreeningFilters();
  const activeReferenceData: TReferenceSearchData | undefined = references[activeReference];

  const handleResetYearsFilter = useCallback(() => {
    resetYearsFilter();
    setYearsRangeFilterState(yearsFilterData);
  }, [resetYearsFilter, setYearsRangeFilterState, yearsFilterData]);

  const handleResetReferencesSearch = useCallback(() => {
    clearFilters();
    setYearsRangeFilterState(yearsFilterData);
  }, [clearFilters, setYearsRangeFilterState, yearsFilterData]);

  useEffect(() => {
    setYearsRangeFilterState((current) => ({
      min: activeYearFilters.minYear ?? current.min,
      max: activeYearFilters.maxYear ?? current.max,
    }));
  }, [activeYearFilters]);

  return (
    <div className="h-full w-full flex flex-col overflow-auto bg-white">
      <div className="h-full flex flex-col flex-no-wrap overflow-auto">
        <ListHeader
          {...{
            projectId,
            references,
            activePanel,
            activeReference,
            selectedReferences,
            togglePanel,
            filtersApplied,
            resolvedConflictsCount,
            decisionFilter,
            allReferencesCount,
            searchingReferences,
            stage,
            refetchReferences,
            isDuplicatesList,
            batches,
            activeBatchKey,
            openImportReferencesDialog,
            openImportPDFsDialog,
            openExportReferencesDialog,
            openFocusMode,
            onRemoveReferences,
            onRestoreReferences,
            setSelectedReferences,
            onResetReferencesNoReportField,
            allReferencesSelected,
            onSelectAllReferences,
            referencesSearchArgs,
          }}
        />
        <div className="flex flex-row w-full flex-grow overflow-auto">
          <div
            className={Classes.ELEVATION_0}
            css={[activePanel === 'filters' ? panelExpandedColCss : panelCollapsedColCss]}
          >
            <FiltersColumn
              domains={domains}
              documentTypes={documentTypes}
              yearsFilterData={yearsFilterData}
              yearsRangeFilterState={yearsRangeFilterState}
              setYearsRangeFilterState={setYearsRangeFilterState}
              activeKeywordFilters={activeKeywordFilters}
              activeDecisionCodeFilters={activeDecisionCodeFilters}
              activeDocumentTypeFilters={activeDocumentTypeFilters}
              onToggle={() => togglePanel('filters')}
              onResetReferencesSearch={filtersApplied ? handleResetReferencesSearch : undefined}
              onlyWithComments={onlyWithComments}
              pdfFilter={pdfFilter}
              onlyWithoutAbstract={onlyWithoutAbstract}
              screeningTags={screeningTags}
              activeScreeningTagFilters={activeScreeningTagFilters}
              projectStageTypes={projectStageTypes}
            />
          </div>
          <div css={{ width: activePanel ? '67%' : '100%' }}>
            <ReferencesListColumn
              references={references}
              decisionFilter={decisionFilter}
              filtersApplied={filtersApplied}
              searchPhraseTokens={searchPhraseTokens}
              activeKeywordFilters={activeKeywordFilters}
              activeDecisionCodeFilters={activeDecisionCodeFilters}
              activeDocumentTypeFilters={activeDocumentTypeFilters}
              activeYearFilters={activeYearFilters}
              activeScreeningTagFilters={activeScreeningTagFilters}
              activeOnlyWithCommentsFilter={onlyWithComments}
              activePdfFilter={pdfFilter}
              activeOnlyWithoutAbstractFilter={onlyWithoutAbstract}
              resetYearsFilter={handleResetYearsFilter}
              activeReference={activeReference}
              selectedReferences={selectedReferences}
              setSelectedReferences={setSelectedReferences}
              setActiveReference={setActiveReference}
              searchingReferences={searchingReferences}
              onLoadMoreReferences={onLoadMoreReferences}
              openFocusMode={openFocusMode}
              loadingMoreReferences={loadingMoreReferences}
              decisionReasons={decisionReasons}
              screeningTags={screeningTags}
              projectStageTypes={projectStageTypes}
              stage={stage}
              resolvedConflictsCount={resolvedConflictsCount}
              formId={formId}
              isDuplicatesList={isDuplicatesList}
              allReferencesCount={allReferencesCount}
            />
          </div>
          <div
            className={Classes.ELEVATION_0}
            css={[activePanel === 'preview' ? panelExpandedColCss : panelCollapsedColCss]}
          >
            <PreviewColumn
              onToggle={() => togglePanel('preview')}
              activeReferenceData={activeReferenceData}
              decisionReasons={decisionReasons}
              loading={searchingReferences}
              searchTokens={searchTokens}
              screeningTags={screeningTags}
            />
          </div>
        </div>
        <div className="flex-none"></div>
      </div>
    </div>
  );
};

export default List;
