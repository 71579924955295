import React, { useState, useCallback } from 'react';
import { ResizeSensor, IResizeEntry } from '@blueprintjs/core';

export interface AutosizerDimensions {
  width: number;
  height: number;
}

const Autosizer = props => {
  const [dimensions, updateDimensions] = useState<AutosizerDimensions | null>(null);

  const handleResize = useCallback(
    (resizedEntries: IResizeEntry[]) => {
      const [targetElEntry] = resizedEntries;
      const { height, width } = targetElEntry.contentRect;

      updateDimensions({ height, width });
    },
    [updateDimensions]
  );

  return (
    <ResizeSensor onResize={handleResize}>
      <div style={{ height: '100%', width: '100%' }}>
        {dimensions ? <props.children {...dimensions} /> : null}
      </div>
    </ResizeSensor>
  );
};

export default Autosizer;
