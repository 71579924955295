/** @jsx jsx */
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import {
  find,
  isEmpty,
  map,
  partition,
  propEq,
  flatMap,
  orderBy,
  indexOf,
  equals,
  get,
  compose,
  unionBy,
  pick,
  filter,
  min,
} from 'lodash/fp';
import { ReactNode } from 'react';
import {
  ConflictType,
  InclusionStatus,
  ResolutionStrategy,
  ScreeningCriteriaResult,
  ScreeningResult,
  ScreeningTask,
  StatusColorSettings,
} from '../common/types';
import i18n from '../i18n';

interface ScreeningTaskData {
  id: string;
  task_results: {
    result: ScreeningResult;
  }[];
}

interface IScreeningTask extends ScreeningTaskData {
  team_member_id: string;
}

export type TConflictResolutionTask = Pick<
  ScreeningTask,
  'id' | 'is_draft' | 'task_results' | 'project_id' | 'study_id' | 'stage_id' | 'study_pool_id'
> & {
  study: {
    screeningTasks: IScreeningTask[];
    references: { id: string }[];
  };
};

export const statusConflictStrategyOptions: ResolutionStrategy[] = [
  ResolutionStrategy.DisagreementReconciliation,
  ResolutionStrategy.InclusionStatus,
];

export const exclusionReasonConflictStrategyOptions: ResolutionStrategy[] = [
  ResolutionStrategy.DisagreementReconciliation,
  ResolutionStrategy.ExclusionReasonHierarchy,
];

export const inclusionReasonConflictStrategyOptions: ResolutionStrategy[] = [
  ResolutionStrategy.DisagreementReconciliation,
  ResolutionStrategy.InclusionReasonHierarchy,
  ResolutionStrategy.Merge,
];

function _getAllScreeningResults(conflictTask: { study: { screeningTasks: ScreeningTaskData[] } }) {
  return flatMap(
    (screeningTask) => map('result', screeningTask.task_results),
    conflictTask.study.screeningTasks
  );
}

// partitions conflicts tasks into 3 batches: "in vs out", "out vs out" and "in vs in"
export function partitionConflictTasksByConflictType<
  T extends { study: { screeningTasks: ScreeningTaskData[] } }
>(conflictTasks: T[]): [T[], T[], T[]] {
  const [statusConflicts, reasonConflicts] = partition((conflictTask) => {
    const results = _getAllScreeningResults(conflictTask);
    const inclusionStatuses = new Set([...map('inclusionStatus', results)]);

    return inclusionStatuses.size > 1;
  }, conflictTasks);

  const [exclusionReasonConflicts, inclusionReasonConflicts] = partition(
    compose(
      equals(InclusionStatus.Excluded),
      get('study.screeningTasks[0].task_results[0].result.inclusionStatus')
    ),
    reasonConflicts
  );

  return [statusConflicts, exclusionReasonConflicts, inclusionReasonConflicts];
}

function _mergeConflictTaskScreeningResults(conflictTask: TConflictResolutionTask) {
  const taskResults = _getAllScreeningResults(conflictTask);

  const [firstResult, ...remainingResults] = taskResults;
  const firstResultFiltered = {
    ...pick(['inclusionStatus', 'criteria'], firstResult),
    conflictResolutionMethod: ResolutionStrategy.Merge,
  };

  if (isEmpty(remainingResults)) {
    return {
      task_id: conflictTask.id,
      result: firstResultFiltered,
    };
  }

  const mergedResult = remainingResults.reduce(
    (acc, result) => ({ ...acc, criteria: unionBy('id', acc.criteria, result.criteria) }),
    firstResultFiltered
  );

  return {
    task_id: conflictTask.id,
    result: mergedResult,
  };
}

function _inclusionStatusTaskResult(inclusionStatus: InclusionStatus) {
  return (conflictTask: TConflictResolutionTask) => {
    const results = _getAllScreeningResults(conflictTask);
    const inclusionStatusResult = find(propEq('inclusionStatus', inclusionStatus), results);

    if (inclusionStatusResult == null) {
      throw new Error(
        `Conflict resolution task "${conflictTask.id}" doesn't contain any ${inclusionStatus} screening result`
      );
    }

    return {
      task_id: conflictTask.id,
      result: {
        ...inclusionStatusResult,
        conflictResolutionMethod: ResolutionStrategy.InclusionStatus,
      },
    };
  };
}

function _mostPreferableReasonResult(reasonsPriority: string[], answer: 'yes' | 'no') {
  return (conflictTask: TConflictResolutionTask) => {
    // similar to _mostPreferableScreenerResult
    const mostPreferableExclusionReasonTask = orderBy(
      (screeningTask) => {
        // there can be multiple criteria selected, pick the best (lowest index in priority list) one
        return compose(
          min,
          map((criteria: ScreeningCriteriaResult) => {
            const idx = indexOf(criteria.id, reasonsPriority);

            return idx === -1 ? 999 : idx;
          }),
          filter({ answer })
        )(screeningTask.task_results[0].result.criteria);
      },
      'asc',
      conflictTask.study.screeningTasks
    )[0];

    return {
      task_id: conflictTask.id,
      result: {
        ...pick(
          ['inclusionStatus', 'criteria'],
          mostPreferableExclusionReasonTask.task_results[0].result
        ),
        conflictResolutionMethod:
          answer === 'yes'
            ? ResolutionStrategy.InclusionReasonHierarchy
            : ResolutionStrategy.ExclusionReasonHierarchy,
      },
    };
  };
}

export async function applyConflictsResolutionStrategy(
  strategy: ResolutionStrategy,
  strategyDetails: any,
  conflictTasks: TConflictResolutionTask[]
): Promise<
  {
    task_id: string;
    result: Pick<ScreeningResult, 'criteria' | 'inclusionStatus' | 'conflictResolutionMethod'>;
  }[]
> {
  switch (strategy) {
    case ResolutionStrategy.Merge:
      return conflictTasks.map(_mergeConflictTaskScreeningResults);
    case ResolutionStrategy.InclusionStatus:
      return conflictTasks.map(_inclusionStatusTaskResult(strategyDetails));
    case ResolutionStrategy.ExclusionReasonHierarchy:
      return conflictTasks.map(_mostPreferableReasonResult(strategyDetails, 'no'));
    case ResolutionStrategy.InclusionReasonHierarchy:
      return conflictTasks.map(_mostPreferableReasonResult(strategyDetails, 'yes'));
    default:
      return [];
  }
}

export function getResolutionStrategyLabel(strategy: ResolutionStrategy): string {
  switch (strategy) {
    case ResolutionStrategy.Merge:
      return i18n._(t`Merge decisions`);
    case ResolutionStrategy.InclusionStatus:
      return i18n._(t`Solve according to status`);
    case ResolutionStrategy.ExclusionReasonHierarchy:
      return i18n._(t`Hierarchy of exclusion reasons`);
    case ResolutionStrategy.InclusionReasonHierarchy:
      return i18n._(t`Hierarchy of inclusion reasons`);
    case ResolutionStrategy.DisagreementReconciliation:
      return i18n._(t`Solved by researches`);
    default:
      return '';
  }
}

export function getConflictTypeLabel(
  conflictType: ConflictType,
  statusColors?: StatusColorSettings
): ReactNode {
  const acceptedColor = statusColors ? { color: statusColors.acceptedPrimary } : undefined;
  const rejectedColor = statusColors ? { color: statusColors.rejectedPrimary } : undefined;

  switch (conflictType) {
    case ConflictType.Status:
      return (
        <Trans>
          <span className="uppercase" css={acceptedColor}>
            In
          </span>{' '}
          vs{' '}
          <span className="uppercase" css={rejectedColor}>
            Out
          </span>
        </Trans>
      );
    case ConflictType.InclusionReason:
      return (
        <Trans>
          <span className="uppercase" css={acceptedColor}>
            In
          </span>{' '}
          vs{' '}
          <span className="uppercase" css={acceptedColor}>
            In
          </span>
        </Trans>
      );
    case ConflictType.ExclusionReason:
      return (
        <Trans>
          <span className="uppercase" css={rejectedColor}>
            Out
          </span>{' '}
          vs{' '}
          <span className="uppercase" css={rejectedColor}>
            Out
          </span>
        </Trans>
      );

    default:
      return null;
  }
}

export type TConflictTaskCounts = {
  resolved: number;
  unresolved: number;
  drafts: number;
};

export const INITIAL_CONFLICT_COUNTS = {
  resolved: 0,
  unresolved: 0,
  drafts: 0,
};

export function conflictTaskCountsReducer(
  acc: TConflictTaskCounts,
  task: Pick<ScreeningTask, 'completed' | 'is_draft'>
): TConflictTaskCounts {
  const { is_draft, completed } = task;
  const countKey: keyof TConflictTaskCounts = is_draft
    ? 'drafts'
    : completed
    ? 'resolved'
    : 'unresolved';
  acc[countKey] += 1;

  return acc;
}
