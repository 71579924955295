/** @jsx jsx **/
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Classes, Colors, Dialog as BPDialog, DialogProps } from '@blueprintjs/core';

const dialogCss = css`
  background: ${Colors.WHITE};
  padding: 0;

  .${Classes.DIALOG_HEADER} {
    background: ${Colors.LIGHT_GRAY5};

    h4.${Classes.HEADING} {
      color: ${Colors.DARK_GRAY5};
      font-weight: normal;
      font-size: 20px;
    }
  }

  .${Classes.DIALOG_FOOTER} {
    border-top: 1px solid ${Colors.LIGHT_GRAY3};
    padding: 1rem 1.25rem;
    margin: 0;
  }
`;

const Dialog: React.FC<DialogProps> = (props) => <BPDialog css={dialogCss} {...props} />;

export default Dialog;
