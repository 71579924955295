/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Divider, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { bgTransition } from '../../common/styles';

interface IFiltersHeaderProps {
  onClearFilters?: () => void;
  onToggle: () => void;
}

const FiltersHeader: React.FC<IFiltersHeaderProps> = ({ onClearFilters, onToggle }) => {
  return (
    <div className="flex flex-row flex-no-wrap items-center overflow-hidden border-t border-b h-full">
      <Button
        className="h-full flex-none"
        icon={<Icon className="p-2" icon={IconNames.CROSS} iconSize={16} />}
        css={bgTransition}
        onClick={onToggle}
        large
        minimal
      />
      <Divider className="h-full m-0" />
      <div className="flex-none flex justify-between overflow-hidden flex-grow items-center">
        <span className="ml-3 text-base">
          <Trans>Filters</Trans>
        </span>
        <div>
          <Button
            className="mr-2"
            text={<Trans>Clear all filters</Trans>}
            disabled={onClearFilters == null}
            onClick={onClearFilters ? () => onClearFilters() : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersHeader;
