import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { TPdfFilter } from '../../apollo/screening_state';
const UpdateActiveAttributeMutation = loader('../../graphql/local/update_active_attribute.gql');
const UpdateActiveKeywordFiltersMutation = loader(
  '../../graphql/local/update_active_keyword_filters.gql'
);
const UpdateActiveScreeningTagMutation = loader(
  '../../graphql/local/update_active_screening_tag.gql'
);
const UpdateActiveKeywordSearchOperatorMutation = loader(
  '../../graphql/local/update_active_keyword_search_operator.gql'
);
const UpdateSearchQueryTokensMutation = loader(
  '../../graphql/local/update_search_phrase_tokens.gql'
);

const SetActiveYearFiltersMutation = loader('../../graphql/local/set_active_year_filters.gql');
const SetOnlyWithCommentsMutation = loader('../../graphql/local/set_only_with_comments_filter.gql');
const SetPdfFilterMutation = loader('../../graphql/local/set_pdf_filter.gql');
const SetOnlyWithoutAbstractMutation = loader(
  '../../graphql/local/set_only_without_abstract_filter.gql'
);
const ResetActiveYearFiltersMutation = loader('../../graphql/local/reset_active_year_filters.gql');
const ClearFiltersMutation = loader('../../graphql/local/clear_filters.gql');
export interface IScreeningFilters {
  toggleAttribute: (data: {
    key: string;
    attribute: string;
    label: string;
    active: boolean;
  }) => void;
  toggleKeyword: (
    variablePath: string,
    toggleData: {
      keyword: string;
      active: boolean;
      type: string;
    }
  ) => void;
  toggleScreeningTag: (data: { id: string; active: boolean }) => void;
  changeKeywordSearchOperator: (variablePath: string, searchOperator: string | null) => void;
  setYearsFilter: (minYear: number, maxYear: number) => void;
  resetYearsFilter: () => void;
  changeSearchTokens: (tokens: any) => void;
  setOnlyWithCommentsFilter: (active: boolean) => void;
  setPdfFilter: (pdfFilter: TPdfFilter) => void;
  setOnlyWithoutAbstractFilter: (active: boolean) => void;
  clearFilters: () => void;
}

export function useScreeningFilters(): IScreeningFilters {
  const [updateActiveScreeningTag] = useMutation(UpdateActiveScreeningTagMutation);
  const [updateActiveAttribute] = useMutation(UpdateActiveAttributeMutation);
  const [updateActiveKeywordFilters] = useMutation(UpdateActiveKeywordFiltersMutation);
  const [updateActiveKeywordSearchOperator] = useMutation(
    UpdateActiveKeywordSearchOperatorMutation
  );
  const [setActiveYearFilters] = useMutation(SetActiveYearFiltersMutation);
  const [resetYearsFilter] = useMutation(ResetActiveYearFiltersMutation);
  const [updateSearchQueryTokens] = useMutation(UpdateSearchQueryTokensMutation);
  const [clearFilters] = useMutation(ClearFiltersMutation);
  const [setOnlyWithComments] = useMutation(SetOnlyWithCommentsMutation);
  const [setPdfRequiredFilter] = useMutation(SetPdfFilterMutation);
  const [setOnlyWithoutAbstract] = useMutation(SetOnlyWithoutAbstractMutation);

  const toggleKeyword = useCallback(
    (variablePath: string, toggleData: { keyword: string; active: boolean; type: string }) => {
      const { keyword, active, type } = toggleData;
      updateActiveKeywordFilters({
        variables: {
          variablePath,
          keyword,
          active,
          type,
        },
      });
    },
    [updateActiveKeywordFilters]
  );

  const toggleAttribute = useCallback(
    (data: { key: string; attribute: string; label: string; active: boolean }) => {
      const { key, attribute, label, active } = data;
      updateActiveAttribute({
        variables: {
          key,
          attribute,
          label,
          active,
        },
      });
    },
    [updateActiveAttribute]
  );

  const toggleScreeningTag = useCallback(
    (data: { id: string; active: boolean }) => {
      const { id, active } = data;
      updateActiveScreeningTag({
        variables: {
          id,
          active,
        },
      });
    },
    [updateActiveScreeningTag]
  );

  const changeKeywordSearchOperator = useCallback(
    (variablePath: string, searchOperator: string | null) => {
      updateActiveKeywordSearchOperator({
        variables: {
          variablePath,
          searchOperator,
        },
      });
    },
    [updateActiveKeywordSearchOperator]
  );

  const setYearsFilter = useCallback(
    (minYear: number, maxYear: number) => {
      setActiveYearFilters({
        variables: {
          minYear,
          maxYear,
        },
      });
    },
    [setActiveYearFilters]
  );

  const setOnlyWithCommentsFilter = useCallback(
    (active: boolean) => {
      setOnlyWithComments({
        variables: {
          onlyWithComments: active,
        },
      });
    },
    [setOnlyWithComments]
  );

  const setPdfFilter = useCallback(
    (pdfFilter: TPdfFilter) => {
      setPdfRequiredFilter({
        variables: {
          pdfFilter,
        },
      });
    },
    [setPdfRequiredFilter]
  );

  const setOnlyWithoutAbstractFilter = useCallback(
    (active: boolean) => {
      setOnlyWithoutAbstract({
        variables: {
          onlyWithoutAbstract: active,
        },
      });
    },
    [setOnlyWithoutAbstract]
  );

  const changeSearchTokens = useCallback(
    (tokens) => {
      updateSearchQueryTokens({ variables: { tokens } });
    },
    [updateSearchQueryTokens]
  );

  return {
    toggleAttribute,
    toggleKeyword,
    toggleScreeningTag,
    changeKeywordSearchOperator,
    setYearsFilter,
    resetYearsFilter,
    changeSearchTokens,
    setOnlyWithCommentsFilter,
    setOnlyWithoutAbstractFilter,
    setPdfFilter,
    clearFilters,
  };
}
