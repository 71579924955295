/** @jsx jsx */
import { Button, Classes, Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { plural, Plural, t, Trans } from '@lingui/macro';
import React, { useCallback, useMemo, useState } from 'react';
import { useI18n } from '../../lib/utils';
import { TeamMemberWithTasksDialogMode } from './team_members_settings';

interface IDecisionsCount {
  count: number;
  decision: 'included' | 'excluded';
}

interface ITeamMemberWithTasksDialog {
  onClose: () => void;
  onApply: (shouldSendDecisions: boolean) => void;
  unsentDecisionsCount: number;
  isOpen: boolean;
  teamMemberName: string;
  mode: TeamMemberWithTasksDialogMode;
}

const TeamMemberWithTasksDialog: React.FC<ITeamMemberWithTasksDialog> = ({
  onClose,
  onApply,
  isOpen,
  unsentDecisionsCount,
  teamMemberName,
  mode,
}) => {
  const [unsentDecisionStrategy, setUnsentDecisionStrategy] = useState<'send' | 'discard'>('send');
  const i18n = useI18n();

  const handleRadioChange = useCallback(
    (evt) => {
      setUnsentDecisionStrategy(evt.target.value);
    },
    [setUnsentDecisionStrategy]
  );

  const handleApply = useCallback(() => {
    onApply(unsentDecisionStrategy === 'send');
    onClose();
  }, [onApply, onClose, unsentDecisionStrategy]);

  const title = useMemo(() => {
    switch (mode) {
      case TeamMemberWithTasksDialogMode.Removal:
        return <Trans>Remove user from the team</Trans>;
      case TeamMemberWithTasksDialogMode.RoleChange:
        return <Trans>Change user role to manager</Trans>;
    }
  }, [mode]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={title}>
      <div className={Classes.DIALOG_BODY}>
        <div className="mb-4">
          <Plural
            value={unsentDecisionsCount}
            one={
              <span>
                There is <span className="font-bold">#</span> unsent decision
              </span>
            }
            other={
              <span>
                There are <span className="font-bold">#</span> unsent decisions
              </span>
            }
          />{' '}
          <Trans>made by {teamMemberName}</Trans>
        </div>
        <RadioGroup onChange={handleRadioChange} selectedValue={unsentDecisionStrategy}>
          <Radio
            label={i18n._(
              plural({
                value: unsentDecisionsCount,
                one: 'Send decision',
                other: 'Send decisions',
              })
            )}
            value="send"
          />
          <Radio
            label={i18n._(
              plural({
                value: unsentDecisionsCount,
                one: 'Discard decision and return reference to the pool',
                other: 'Discard decisions and return references to the pool',
              })
            )}
            value="discard"
          />
        </RadioGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text={<Trans>Cancel</Trans>} />
          <Button onClick={handleApply} text={title} />
        </div>
      </div>
    </Dialog>
  );
};

export default TeamMemberWithTasksDialog;
