/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PropsWithChildren, useMemo } from 'react';
import { useStatusColor } from '../../lib/utils';
import { LABELS } from '../project/dashboard/screening_stats';

interface IDecisionIndicatorProps {
  inclusionStatus?: string;
  exclusionReason?: string;
}

const DecisionIndicator: React.FC<PropsWithChildren<IDecisionIndicatorProps>> = ({
  inclusionStatus,
  exclusionReason,
  children,
}) => {
  const getStatusColor = useStatusColor();

  const statusLabel = useMemo(() => {
    switch (inclusionStatus) {
      case 'included':
        return LABELS.included;
      case 'excluded':
        return LABELS.excluded;
      case 'conflict':
        return LABELS.conflicts;
      default:
        return LABELS.toReview;
    }
  }, [inclusionStatus]);

  return (
    <div
      className="w-full flex flex-row justify-between border-2"
      css={{ minHeight: '40px', borderColor: getStatusColor(inclusionStatus) }}
    >
      <div
        className="flex-none flex items-center justify-center uppercase px-3 py-2 border-r-2"
        css={{ borderColor: 'inherit', minWidth: '60px' }}
      >
        {statusLabel}
      </div>
      {inclusionStatus === 'excluded' && (
        <div className="flex-grow flex items-center px-3">{exclusionReason}</div>
      )}
      {children}
    </div>
  );
};

export default DecisionIndicator;
