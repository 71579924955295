/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Classes, TextArea, Button, FormGroup, NonIdealState, Card } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash/fp';
import Dialog from '../common/dialog';
import { ReferenceComment, ScreeningTag, User } from '../../common/types';
import { ScreeningTagFiltersTag } from './screening_filters';
import Immutable from 'immutable';
import { useSetState } from '../../lib/utils';
import { border1Gray5, darkGray5Color } from '../../common/styles';

interface ICommendEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (data: { comment: string; tags: string[] }) => void;
  comment?: string;
  selectedTags?: string[];
  screeningTags?: ScreeningTag[];
  previousComments?: (Pick<ReferenceComment, 'id' | 'comment'> & {
    team_member: { id: string; user: Pick<User, 'id' | 'name'> };
  })[];
}

type TCommentEditDialogState = {
  editedComment: string;
  selectedTags: Immutable.Set<string>;
};

const getInitialState = (comment?: string, selectedTags?: string[]) => ({
  editedComment: comment ?? '',
  selectedTags: Immutable.Set(selectedTags ?? []),
});

const CommentEditDialog: React.FC<ICommendEditDialogProps> = ({
  isOpen,
  screeningTags,
  selectedTags,
  previousComments,
  onClose,
  onSave,
  comment,
}) => {
  const [state, setState] = useSetState<TCommentEditDialogState>(
    getInitialState(comment, selectedTags)
  );
  const { editedComment, selectedTags: editedSelectedTags } = state;
  const readOnly = onSave == null;

  const closeDialog = useCallback(() => {
    setState(getInitialState(comment, selectedTags));
    onClose();
  }, [setState, onClose, selectedTags, comment]);

  const handleSave = () => {
    const selectedTagsArr = editedSelectedTags.toArray();
    onSave?.({
      comment: editedComment,
      tags: selectedTagsArr,
    });
    closeDialog();
  };

  const handleTagToggle = useCallback(
    (tagId, isActive) => {
      setState((current) => ({
        ...current,
        selectedTags: isActive
          ? current.selectedTags.add(tagId)
          : current.selectedTags.delete(tagId),
      }));
    },
    [setState]
  );

  useEffect(() => {
    setState(getInitialState(comment, selectedTags));
  }, [comment, selectedTags]);

  return (
    <Dialog
      isOpen={isOpen}
      className="w-4/12"
      onClose={closeDialog}
      title={<Trans>Add comment</Trans>}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 p-4`}>
        {isEmpty(screeningTags) ? null : (
          <FormGroup
            label={
              <span className="mb-4" css={darkGray5Color}>
                <Trans>Structured comments</Trans>
              </span>
            }
          >
            {screeningTags?.map(({ id, tag }) => (
              <ScreeningTagFiltersTag
                key={id}
                onToggle={handleTagToggle}
                attribute={id}
                label={tag}
                active={editedSelectedTags.includes(id)}
                disabled={readOnly}
              >
                {tag}
              </ScreeningTagFiltersTag>
            ))}
          </FormGroup>
        )}
        <FormGroup
          label={
            <span className="mb-4" css={darkGray5Color}>
              <Trans>My comments</Trans>
            </span>
          }
        >
          <TextArea
            readOnly={readOnly}
            autoFocus
            fill
            growVertically
            className="resize-none"
            css={{ minHeight: '200px' }}
            value={editedComment}
            onChange={(evt) => setState({ editedComment: evt.target.value })}
          />
        </FormGroup>
        <FormGroup
          label={
            <span className="mb-4" css={darkGray5Color}>
              <Trans>Previous comments</Trans>
            </span>
          }
        >
          <div className="py-3 px-4" css={border1Gray5}>
            {previousComments == null || isEmpty(previousComments) ? (
              <NonIdealState
                title={
                  <span className="text-xs font-normal" css={darkGray5Color}>
                    <Trans>No comments have been added</Trans>
                  </span>
                }
              />
            ) : (
              previousComments.map(({ id, comment, team_member }) => (
                <Card className="py-2 px-4" key={id}>
                  <div>{comment}</div>
                  <div className="text-right text-xs">
                    {team_member.user?.name ?? <Trans>Removed user</Trans>}
                  </div>
                </Card>
              ))
            )}
          </div>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large text={<Trans>Cancel</Trans>} onClick={closeDialog} />
          <Button disabled={readOnly} large text={<Trans>Save</Trans>} onClick={handleSave} />
        </div>
      </div>
    </Dialog>
  );
};

export default CommentEditDialog;
