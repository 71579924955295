import React, { useCallback, useState } from 'react';
import type { DomainItem, KeywordsData, UserKeyword } from '../../common/types';
import { EMPTY_KEYWORDS_DATA, mergeKeywordsDataAndUserKeywords } from '../../lib/criteria_utils';
import useKeywordsExport from '../hooks/use_keywords_export';
import KeywordsImportDialog from '../keywords/import_dialog/keywords_import_dialog';
import KeywordsForm, { KeywordsFormMode } from '../keywords/keywords_form';
import KeywordsEdit from './keywords_edit';

interface IScreenerKeywordsEditProps {
  domains: DomainItem[];
  onSave: (keywordsData: KeywordsData) => void;
  keywordsData?: KeywordsData;
  className?: string;
}

const ScreenerKeywordsEdit: React.FC<IScreenerKeywordsEditProps> = ({
  className,
  keywordsData,
  onSave,
  domains,
}) => {
  const [isKeywordsImportDialogOpen, setIsKeywordsImportDialogOpen] = useState<boolean>(false);
  const [isKeywordsExportDialogOpen, setIsKeywordsExportDialogOpen] = useState<boolean>(false);
  const exportKeywords = useKeywordsExport();

  const handleKeywordsImport = useCallback(
    (userKeywords: UserKeyword[], overwriteExisting: boolean) => {
      const currentKeywords = overwriteExisting
        ? EMPTY_KEYWORDS_DATA
        : keywordsData ?? EMPTY_KEYWORDS_DATA;
      const mergedKeywords = mergeKeywordsDataAndUserKeywords(currentKeywords, userKeywords);
      onSave(mergedKeywords);
    },
    [keywordsData, onSave]
  );

  return (
    <div className={className}>
      <KeywordsEdit
        className="mt-3"
        keywords={keywordsData ?? EMPTY_KEYWORDS_DATA}
        onExport={() => setIsKeywordsExportDialogOpen(true)}
        onImport={() => setIsKeywordsImportDialogOpen(true)}
        onSave={onSave}
      />
      {isKeywordsImportDialogOpen && (
        <KeywordsImportDialog
          onClose={() => setIsKeywordsImportDialogOpen(false)}
          onImport={handleKeywordsImport}
        />
      )}
      {isKeywordsExportDialogOpen && (
        <KeywordsForm
          domains={domains}
          keywordsData={{
            excluded: keywordsData?.excluded ?? [],
            included: keywordsData?.included ?? [],
            title: '',
            domain: null,
            variable: null,
          }}
          mode={KeywordsFormMode.InclusionExclusionCriteria}
          onSave={exportKeywords}
          onClose={() => setIsKeywordsExportDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default ScreenerKeywordsEdit;
