/** @jsx jsx */
import Immutable from 'immutable';
import { Button, Classes, NonIdealState } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { filter, isEmpty, keyBy, map, maxBy, some } from 'lodash/fp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flatten, InclusionStatus, ScreeningTag, Stage } from '../../../common/types';
import Dialog from '../../common/dialog';
import { ScreeningTagFiltersTag } from '../../screening/screening_filters';
import { useI18n } from '../../../lib/utils';
import { IconNames } from '@blueprintjs/icons';
import { TReferenceDetailsData } from './reference_details';

type TStageResultData = Flatten<TReferenceDetailsData['stage_results']>;
export type TEditedTagsHandler = (newTags: string[], currentResult: TStageResultData) => void;

interface IReferenceTagsEditDialogProps {
  stages: Pick<Stage, 'id' | 'order_number' | 'forms'>[];
  stageResults: TStageResultData[];
  tags: ScreeningTag[];
  isOpen: boolean;
  onClose: () => void;
  onApply: TEditedTagsHandler;
}

const ReferenceTagsEditDialog: React.FC<IReferenceTagsEditDialogProps> = ({
  tags,
  stages,
  stageResults,
  isOpen,
  onClose,
  onApply,
}) => {
  const i18n = useI18n();
  const stagesMap = useMemo(() => {
    return keyBy('id', stages);
  }, [stages]);

  const latestStageResult = useMemo(() => {
    return maxBy<TStageResultData>(
      ({ stage_id }) => stagesMap[stage_id].order_number,
      stageResults
    );
  }, [stagesMap, stageResults]);

  const [newSelectedTags, setNewSelectedTags] = useState<Immutable.Set<string>>(
    Immutable.Set(latestStageResult?.tags ?? [])
  );

  // allow only completed stages' tags to be enabled
  const enabledTags = useMemo(() => {
    if (
      latestStageResult?.inclusion_status == null ||
      latestStageResult.inclusion_status === InclusionStatus.Conflict
    )
      return new Set();
    const completedStages = filter(({ id }) => some({ stage_id: id }, stageResults), stages);

    return completedStages.reduce((acc, stage) => {
      const stageTags = map('id', (stage.forms[0].form.tags ?? []) as ScreeningTag[]);
      stageTags.forEach((tagId) => acc.add(tagId));

      return acc;
    }, new Set<string>());
  }, [stageResults, stages, latestStageResult?.inclusion_status]);

  const handleToggleTag = useCallback(
    (id: string, newSelected: boolean) => {
      setNewSelectedTags((current) => (newSelected ? current.add(id) : current.delete(id)));
    },
    [setNewSelectedTags]
  );

  const handleApply = () => {
    if (latestStageResult == null) return;
    onApply(newSelectedTags.toArray(), latestStageResult);
  };

  useEffect(() => {
    if (!isOpen) {
      setNewSelectedTags(Immutable.Set(latestStageResult?.tags ?? []));
    }
  }, [isOpen, latestStageResult]);

  return (
    <Dialog title={<Trans>Edit structured comments</Trans>} isOpen={isOpen} onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <div className="flex flex-wrap overflow-hidden tags-list">
          {isEmpty(tags) ? (
            <NonIdealState
              icon={IconNames.FOLDER_OPEN}
              title={
                <span className="text-sm font-normal">
                  <Trans>
                    There are no structured comments to display. Please add them in screening
                    instructions
                  </Trans>
                </span>
              }
            />
          ) : (
            tags.map(({ id, tag }) => {
              const isSelected = newSelectedTags.has(id);
              const isDisabled = !enabledTags.has(id);

              return (
                <ScreeningTagFiltersTag
                  key={id}
                  onToggle={handleToggleTag}
                  attribute={id}
                  label={tag}
                  active={isSelected}
                  disabled={isDisabled}
                  title={
                    isDisabled
                      ? i18n._(t`This tag belongs to the stage which hasn't been completed yet`)
                      : undefined
                  }
                >
                  {tag}
                </ScreeningTagFiltersTag>
              );
            })
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={onClose} />
          <Button
            text={<Trans>Save</Trans>}
            onClick={handleApply}
            disabled={latestStageResult == null}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ReferenceTagsEditDialog;
