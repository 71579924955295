/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Divider, Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useCallback, useState } from 'react';
import { border1Gray5 } from '../../common/styles';
import { Trans } from '@lingui/macro';
import InstructionItemMovementControls from './instruction_item_movement_controls';

interface IExpandableInstructionSectionItemProps {
  content: React.ReactNode;
  expandableContent: React.ReactNode;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const ExpandableInstructionSectionItem: React.FC<IExpandableInstructionSectionItemProps> = ({
  content,
  expandableContent,
  onDelete,
  onMoveDown,
  onMoveUp,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpand = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  return (
    <div className="mb-2 expandable-section-item" css={border1Gray5}>
      <div className="h-12 flex flex-row items-center bg-white">
        {(onMoveDown ?? onMoveUp) && (
          <InstructionItemMovementControls
            className="ml-2"
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
          />
        )}
        <div className="flex-1 pl-2 pr-4 py-2">{content}</div>
        <Divider className="h-full m-0" />
        <Button
          minimal
          large
          icon={isExpanded ? IconNames.CARET_UP : IconNames.CARET_DOWN}
          onClick={toggleExpand}
          className="h-full"
        />
      </div>
      <Collapse isOpen={isExpanded}>
        <div className="px-4 pt-2 pb-4 bg-gray-200 border-t border-gray-400">
          {expandableContent}
        </div>
        {onDelete && (
          <div className="px-4 py-2 bg-gray-200 border-t border-gray-400 flex justify-end">
            <Button disabled={!onDelete} icon={IconNames.TRASH} onClick={onDelete} outlined>
              <Trans>Delete reason</Trans>
            </Button>
          </div>
        )}
      </Collapse>
    </div>
  );
};

export default ExpandableInstructionSectionItem;
