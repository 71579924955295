/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { IconNames } from '@blueprintjs/icons';
import { Button, Checkbox, Colors, InputGroup } from '@blueprintjs/core';
import React, { useMemo, Fragment } from 'react';
import { isUndefined } from 'lodash/fp';
import { useSetState } from '../../lib/utils';
import { gray5Border } from '../../common/styles';

const makeSectionHeaderStyle = (hasBorder: boolean, editing: boolean) => {
  return css`
    background: ${editing ? Colors.WHITE : Colors.LIGHT_GRAY3};
    ${hasBorder ? `border-bottom: 1px solid ${Colors.GRAY5};` : ''}
  `;
};

interface IEditorSectionProps {
  checked?: boolean;
  updateSectionData: (stateObj: object) => void;
  title: string;
}

const EditorSection: React.FC<IEditorSectionProps> = ({
  checked,
  updateSectionData,
  title,
  children,
}) => {
  const [state, setState] = useSetState({ editing: false, title });
  const checkable = !isUndefined(checked);
  const hasChildren = children != null;
  const sectionHeaderStyle = useMemo(
    () => makeSectionHeaderStyle(hasChildren && (!checkable || (checked ?? false)), state.editing),
    [hasChildren, checkable, checked, state.editing]
  );

  return (
    <div className="w-full bg-white mb-1" css={gray5Border}>
      <div>
        <div
          className="px-4 py-2 flex flex-row justify-between items-center"
          css={sectionHeaderStyle}
        >
          {state.editing ? (
            <Fragment>
              <InputGroup
                fill
                onChange={(e) => setState({ title: e.target.value })}
                value={state.title}
              />
              <Button
                minimal
                icon={IconNames.TICK}
                onClick={() => {
                  updateSectionData({ title: state.title });
                  setState({ editing: !state.editing });
                }}
              />
              <Button
                minimal
                icon={IconNames.CROSS}
                onClick={() =>
                  setState({
                    title,
                    editing: !state.editing,
                  })
                }
              />
            </Fragment>
          ) : (
            <Fragment>
              <div>
                {checkable && (
                  <Checkbox
                    checked={checked}
                    onChange={() => updateSectionData({ checked: !checked })}
                    className="inline-block"
                  />
                )}
                <span className="font-bold uppercase" css={{ color: Colors.GRAY1 }}>
                  {title}
                </span>
              </div>
              <Button icon={IconNames.EDIT} onClick={() => setState({ editing: !state.editing })} />
            </Fragment>
          )}
        </div>
      </div>
      {(!checkable || checked) && children}
    </div>
  );
};

export default EditorSection;
