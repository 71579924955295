/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Spinner } from '@blueprintjs/core';
import React, {
  Fragment,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  clamp,
  compose,
  concat,
  filter,
  find,
  findIndex,
  flatMap,
  get,
  isEmpty,
  map,
  noop,
  propEq,
  round,
  some,
  toSafeInteger,
  uniqBy,
  xor,
} from 'lodash/fp';
import { RouteComponentProps } from 'react-router-dom';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import {
  Stage,
  WorkerStatus,
  ScreeningTaskType,
  ReferenceRemovalReason,
  Reference,
  GraphQLAggregateData,
} from '../../../common/types';
import {
  escapeTokens,
  getSearchTokens,
  searchTokensToSearchQueryArgs,
  TSearchWithOperatorObject,
  TSearchQueryArgs,
  getTaskCountForDecisionFilter,
  TTaskCounts,
} from '../../../lib/task_helpers';
import {
  EMPTY_ACTIVE_DECISION_CODE_FILTERS,
  EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
  EMPTY_ACTIVE_KEYWORD_FILTERS,
  EMPTY_ACTIVE_YEAR_FILTER,
  EMPTY_SEARCH_PHRASE_TOKENS,
  EMPTY_SELECTED_REFERENCES,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TFilterTarget,
  YearFilters,
  EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
  TActiveScreeningTagFilters,
  DecisionFilter,
  TPdfFilter,
} from '../../../apollo/screening_state';
import { usePrevious, useSetState, withPrevLocation } from '../../../lib/utils';
import List from './list';
import ErrorScreen from '../../common/error_screen';
import {
  getActiveAttributeFilters,
  REFERENCES_BATCH_SIZE,
  TExclusionReason,
} from '../../screening';
import { formatDate } from '../../project/helpers';
import ExportDialog from '../../project/dashboard/export_dialog';
import ImportDialog from '../import_dialog';
import FilesUploadDialog from '../../files_upload_dialog';
import UnassignedPDFsBanner from '../unassigned_attachments/unassigned_pdfs_banner';
import { decisionCodesFiltersToDecisionAndCodes } from '../../../lib/references';
import { useKeycloak } from '../../../keycloak';
import { TReferenceSearchData } from './references_list_layouts';
import useDidUpdateEffect from '../../hooks/use_did_update_effect';
import {
  locationSearchToReferenceFilters,
  referenceFiltersToLocationSearchString,
} from '../helpers';
import { centerItemCss } from '../../../common/styles';
import { getReasonCodesData, hasFiltersApplied } from '../../screening/helpers';
import NavAndDecisionControls from '../../screening/navigation_and_decision_controls';

const EMPTY_LIST = [];
export const ORDER_BY_BATCH = {
  column: 'batch',
  order: 'desc',
};

const FormUserKeywordsFragment = loader('../../../graphql/form_user_keywords_fragment.gql');
const RemoveReferencesMutation = loader('../../../graphql/remove_references.gql');
const RestoreReferencesMutation = loader('../../../graphql/restore_references.gql');
const ResetScreeningStateMutation = loader('../../../graphql/local/reset_screening_state.gql');
const ResetReferencesFiltersMutation = loader(
  '../../../graphql/local/reset_references_filters.gql'
);
const UpdateReferencesNoReportFieldAndClaimsMutation = loader(
  '../../../graphql/update_references_no_report_field_and_claims.gql'
);
const SetActiveAndSelectedReferencesMutation = loader(
  '../../../graphql/local/set_active_and_selected_references.gql'
);
const ToggleActiveReferenceSelectMutation = loader(
  '../../../graphql/local/toggle_active_reference_select.gql'
);

type TReferencesSearchQueryData = {
  all: GraphQLAggregateData;
  resolved: GraphQLAggregateData;
  references: TReferenceSearchData[];
};

const ReferencesSearchQuery = gql`
  query ReferencesQuery(
    $searchArgs: search_references2_args! = {}
    $allCountFilter: reference_bool_exp!
    $referencesFilter: reference_bool_exp!
    $offset: Int!
    $limit: Int!
    $resolvedCountFilter: reference_bool_exp
  ) {
    all: search_references2_aggregate(args: $searchArgs, where: $allCountFilter) {
      aggregate {
        count
      }
    }
    resolved: search_references2_aggregate(args: $searchArgs, where: $resolvedCountFilter) {
      aggregate {
        count
      }
    }

    references: search_references2(
      args: $searchArgs
      limit: $limit
      offset: $offset
      where: $referencesFilter
    ) @connection(key: "references_search", filter: ["args", "where"]) {
      id
      title
      attrs
      deleted_at
      deleted_by
      removal_reason
      reference_comments(
        where: { task: { completed: { _eq: true } } }
        order_by: { updated_at: desc }
      ) {
        id
        stage_id
        team_member {
          id
          user {
            id
            name
          }
        }
        comment
      }
      study {
        id
        deleted_at
        tasks(where: { completed: { _eq: true } }) {
          id
          stage_id
          task_type
          is_draft
          team_member {
            id
            deleted_at
            user_id
            user {
              id
              name
            }
          }
          task_results {
            task_id
            updated_at
            form_id
            result
          }
        }
        study_pool_studies {
          inclusion_status
          status_reason_codes
          tags
          comment
          study_pool {
            id
            stage {
              id
              type
              order_number
            }
          }
        }
      }
      import_task {
        key
        label
      }
      no_report
      reference_attachments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const SetActiveBatchKeyMutation = gql`
  mutation SetActiveBatchKeyMutation($activeBatchKey: String!) {
    setActiveBatchKey(activeBatchKey: $activeBatchKey) @client
  }
`;

const SetAllReferencesSelectedMutation = gql`
  mutation SetAllReferencesSelected(
    $allReferencesSelected: Boolean!
    $selectedReferences: [uuid!]
  ) {
    setAllReferencesSelected(
      allReferencesSelected: $allReferencesSelected
      selectedReferences: $selectedReferences
    ) @client
  }
`;

export const screeningStateQuery = gql`
  query {
    ScreeningState @client {
      decisionFilter
      activeScreeningTagFilters
      activeKeywordFilters
      activeDecisionCodeFilters
      activeDocumentTypeFilters
      activeYearFilters
      searchPhraseTokens
      filtersTarget
      activeReference
      allReferencesSelected
      selectedReferences
      orderedBy
      onlyWithComments
      pdfFilter
      onlyWithoutAbstract
      activeBatchKey
    }
  }
`;

const ProjectScreeningTagsQuery = loader('../../../graphql/get_project_screening_tags_data.gql');

const projectDataSubscription = gql`
  ${FormUserKeywordsFragment}
  subscription GetScreeningData($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      name
      completed
      stages(order_by: { order_number: asc }) {
        id
        completed
        type
        forms(order_by: { created_at: desc }, limit: 1) {
          id
          form
          form_team_member_keywords {
            ...FormUserKeywordsFragment
          }
        }
      }
      references_attributes_aggregate {
        aggregate {
          min {
            year
          }
          max {
            year
          }
        }
      }
      references_attributes(distinct_on: document_type) {
        document_type
      }
      references_import_tasks {
        label
        key
        created_at
        status
      }
      reference_attachments_aggregate(where: { reference_id: { _is_null: false } }) {
        aggregate {
          count
        }
      }
      unassigned_pdf_count: reference_attachments_aggregate(
        where: { reference_id: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export type TScreeningTag = {
  id: string;
  tag: string;
};

export type TBatch = {
  key: string;
  label: string;
  created_at: string;
};

export type TDomainReasonsData = {
  preliminary: TExclusionReason[];
  titlesAbstracts: TExclusionReason[];
  fullText: {
    inclusion: TExclusionReason[];
    exclusion: TExclusionReason[];
  };
};

type TReferenceFetchVariables = {
  offset: number;
  searchArgs: TSearchQueryArgs;
  referencesFilter: object;
  allCountFilter: object;
  limit: number;
  orderBy?: object;
  resolvedCountFilter?: object;
};

export type TStageData = Pick<Stage, 'id' | 'completed' | 'forms' | 'study_pools' | 'type'>;

interface ScreeningListRouterProps {
  projectId: string;
  stageId?: string;
  referenceStatus?: string;
}

interface IScreeningListProps extends RouteComponentProps<ScreeningListRouterProps> {
  // regular props
}

type TScreeningListState = {
  loadingMoreReferences: boolean;
  lastReferencesFetchVariables: TReferenceFetchVariables | null;
  importReferencesDialogOpen: boolean;
  importPDFsDialogOpen: boolean;
  exportReferencesDialogOpen: boolean;
};

const INITIAL_STATE: TScreeningListState = {
  loadingMoreReferences: false,
  lastReferencesFetchVariables: null,
  importReferencesDialogOpen: false,
  importPDFsDialogOpen: false,
  exportReferencesDialogOpen: false,
};

export const getAllCountFilter = (
  stageId: string | undefined,
  referenceStatus: string | undefined,
  duplicatesOnlyList: boolean,
  activeBatchKey: string | null
): object => {
  return stageId
    ? {
        removal_reason: duplicatesOnlyList
          ? { _eq: ReferenceRemovalReason.IsDuplicate }
          : { _is_null: true },
        stage_study_statuses: {
          stage_id: { _eq: stageId },
          inclusion_status: { _eq: referenceStatus?.replace(/^(conflict)s$/, '$1') },
        },
      }
    : {
        removal_reason: duplicatesOnlyList
          ? { _eq: ReferenceRemovalReason.IsDuplicate }
          : { _is_null: true },
        import_task_key: { _eq: activeBatchKey },
      };
};

export const getResolvedCountFilter = (
  stageId: string | undefined,
  referenceStatus: string | undefined,
  duplicatesOnlyList: boolean
): object | undefined => {
  if (stageId && referenceStatus === 'conflicts') {
    return {
      removal_reason: duplicatesOnlyList
        ? { _eq: ReferenceRemovalReason.IsDuplicate }
        : { _is_null: true },
      study: {
        tasks_with_status: {
          stage_id: { _eq: stageId },
          task_type: { _eq: ScreeningTaskType.ConflictResolution },
          inclusion_status: { _neq: 'to_review' },
        },
      },
    };
  }
};

export const getSearchArgs = (params: {
  projectId: string;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  filtersTarget: TFilterTarget;
  searchTokensWithOperator: TSearchWithOperatorObject[];
  onlyWithComments: boolean;
  pdfFilter: TPdfFilter;
  onlyWithoutAbstract: boolean;
  orderedBy: object;
  stageId?: string;
  isDuplicatesList?: boolean;
}): TSearchQueryArgs => {
  const {
    projectId,
    activeDocumentTypeFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
    activeYearFilters,
    searchPhraseTokens,
    filtersTarget,
    searchTokensWithOperator,
    onlyWithComments,
    pdfFilter,
    onlyWithoutAbstract,
    orderedBy,
    stageId,
    isDuplicatesList,
  } = params;
  const { decisions, decisionCodes } =
    decisionCodesFiltersToDecisionAndCodes(activeDecisionCodeFilters);
  const escapedTokensWithOperator: TSearchWithOperatorObject[] = map(
    (searchObject: TSearchWithOperatorObject) => {
      return {
        ...searchObject,
        keywords: escapeTokens(searchObject.keywords),
      };
    },
    searchTokensWithOperator
  );

  return searchTokensToSearchQueryArgs({
    projectId,
    activeDocumentTypes: getActiveAttributeFilters(activeDocumentTypeFilters),
    activeDecisions: decisions,
    activeDecisionCodes: decisionCodes,
    activeScreeningTags: getActiveAttributeFilters(activeScreeningTagFilters),
    activeYears: activeYearFilters,
    searchTokens: searchPhraseTokens,
    searchTokensWithOperator: escapedTokensWithOperator,
    filtersTarget,
    commentRequired: onlyWithComments,
    pdfFilter,
    emptyAbstractRequired: onlyWithoutAbstract,
    orderedBy,
    stageId,
    isDuplicatesList,
  });
};

const ScreeningList: React.FC<IScreeningListProps> = memo(({ match, history, location }) => {
  const { search } = location;
  const { user } = useKeycloak();
  const referencesSearchArgs = useRef<TReferenceFetchVariables>();
  const [state, setState] = useSetState(INITIAL_STATE);
  const {
    loadingMoreReferences,
    importReferencesDialogOpen,
    importPDFsDialogOpen,
    exportReferencesDialogOpen,
  } = state;
  const { projectId, stageId, referenceStatus } = match.params;
  const { data: screeningTagsData } = useQuery(ProjectScreeningTagsQuery, {
    variables: { projectId },
  });
  const { data: screeningStateData } = useQuery(screeningStateQuery);
  const { data, loading } = useSubscription(projectDataSubscription, {
    variables: {
      projectId,
    },
  });
  const importTasks = get('project.references_import_tasks', data) ?? EMPTY_LIST;
  const importTasksPrev = usePrevious(importTasks);
  const attachmentsCount =
    get('project.reference_attachments_aggregate.aggregate.count', data) ?? 0;
  const attachmentsCountPrev = usePrevious(attachmentsCount);

  const batches: TBatch[] = useMemo(() => {
    return importTasks.map((elem: TBatch) => ({
      ...elem,
      created_at: formatDate(elem.created_at, 'dd/MM/yyyy HH:mm'),
    }));
  }, [importTasks]);

  const [
    fetchReferences,
    { data: referencesData, loading: loadingRefs, fetchMore, error, called: everFetched },
  ] = useLazyQuery<TReferencesSearchQueryData>(ReferencesSearchQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [resetScreeningState] = useMutation(ResetScreeningStateMutation);
  const [resetFilters] = useMutation(ResetReferencesFiltersMutation);
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const [toggleActiveReferenceSelect] = useMutation(ToggleActiveReferenceSelectMutation);
  const [removeReferences] = useMutation<
    any,
    { referenceIds: string[]; userId: string; removalReason: string }
  >(RemoveReferencesMutation);
  const [restoreReferences] = useMutation<any, { referenceIds: string[] }>(
    RestoreReferencesMutation
  );
  const [setAllReferencesSelected] = useMutation(SetAllReferencesSelectedMutation);
  const [updateReferencesNoReportFieldAndClaims] = useMutation(
    UpdateReferencesNoReportFieldAndClaimsMutation
  );

  const allReferencesCount: number = get('all.aggregate.count', referencesData) ?? 0;
  const unassignedPDFCount = get('project.unassigned_pdf_count.aggregate.count', data);
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const duplicatesOnlyList = Boolean(queryParams.get('duplicatesOnly'));

  const screeningTags = useMemo(() => {
    return compose(
      uniqBy('id'),
      flatMap(({ tags }) => tags ?? []),
      get('forms')
    )(screeningTagsData);
  }, [screeningTagsData]);

  const referencesAttributesYearData = get(
    'project.references_attributes_aggregate.aggregate',
    data
  );
  const yearsFilterData = {
    min: toSafeInteger(get('min.year', referencesAttributesYearData)),
    max: toSafeInteger(get('max.year', referencesAttributesYearData)),
  };
  const documentTypesData = map(
    (elem: any) => elem.document_type,
    get('project.references_attributes', data)
  );
  const stages: TStageData[] = get('project.stages', data) ?? EMPTY_LIST;
  const stage: TStageData | undefined = find({ id: stageId }, stages);
  const decisionFilter: DecisionFilter =
    get('ScreeningState.decisionFilter', screeningStateData) ?? 'to_review';
  const activeKeywordFilters: TActiveKeywordFilters =
    get('ScreeningState.activeKeywordFilters', screeningStateData) ?? EMPTY_ACTIVE_KEYWORD_FILTERS;
  const searchPhraseTokens: string[] =
    get('ScreeningState.searchPhraseTokens', screeningStateData) ?? EMPTY_SEARCH_PHRASE_TOKENS;

  // searchPhraseTokens seems to be pointing at different objects on each render
  // so we're using ref there to persist tokens between renders
  const searchPhraseTokensRef: MutableRefObject<string[]> = useRef(searchPhraseTokens);
  const filtersTarget: TFilterTarget = get('ScreeningState.filtersTarget', screeningStateData);

  const activeScreeningTagFilters: TActiveScreeningTagFilters =
    get('ScreeningState.activeScreeningTagFilters', screeningStateData) ??
    EMPTY_ACTIVE_SCREENING_TAG_FILTERS;

  const activeDecisionCodeFilters: TActiveAttributeFilters =
    get('ScreeningState.activeDecisionCodeFilters', screeningStateData) ??
    EMPTY_ACTIVE_DECISION_CODE_FILTERS;
  const activeDocumentTypeFilters: TActiveAttributeFilters =
    get('ScreeningState.activeDocumentTypeFilters', screeningStateData) ??
    EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS;
  const activeYearFilters: YearFilters =
    get('ScreeningState.activeYearFilters', screeningStateData) ?? EMPTY_ACTIVE_YEAR_FILTER;
  const orderedBy: object = get('ScreeningState.orderedBy', screeningStateData) ?? ORDER_BY_BATCH;
  const onlyWithComments: boolean = get('ScreeningState.onlyWithComments', screeningStateData);
  const pdfFilter: TPdfFilter = get('ScreeningState.pdfFilter', screeningStateData);
  const onlyWithoutAbstract: boolean = get(
    'ScreeningState.onlyWithoutAbstract',
    screeningStateData
  );
  const allReferencesSelected: boolean = get(
    'ScreeningState.allReferencesSelected',
    screeningStateData
  );
  const activeBatchKey: string | null = get('ScreeningState.activeBatchKey', screeningStateData);
  const references: TReferenceSearchData[] = get('references', referencesData) ?? EMPTY_LIST;

  // TODO: (mentioned in D6387) refactor everything that depends on selectedReferences to use IDs instead of indices.
  // TODO: this refactor requires the update of common/table component as well.
  const selectedReferencesIds: string[] =
    get('ScreeningState.selectedReferences', screeningStateData) ?? EMPTY_SELECTED_REFERENCES;
  const selectedReferences: number[] = useMemo(
    () =>
      filter(
        (idx: number) => idx >= 0,
        map((id) => findIndex(propEq('id', id), references), selectedReferencesIds)
      ),
    [references, selectedReferencesIds]
  );

  const activeReferenceId = get('ScreeningState.activeReference', screeningStateData);
  const activeReferenceIdx = useMemo(
    () =>
      compose(
        clamp(0, references.length - 1),
        findIndex(propEq('id', activeReferenceId))
      )(references),
    [activeReferenceId, references]
  );

  const resolvedConflictsCount =
    referenceStatus === 'conflicts' && stageId != null
      ? get('resolved.aggregate.count', referencesData)
      : undefined;

  const screeningCompleted: boolean = get('completed', data) ?? false;

  const reasonCodesData: TDomainReasonsData = useMemo(() => getReasonCodesData(stages), [stages]);

  const decisionReasons: TExclusionReason[] = useMemo(
    () => [
      ...reasonCodesData.preliminary,
      ...reasonCodesData.titlesAbstracts,
      ...reasonCodesData.fullText.inclusion,
      ...reasonCodesData.fullText.exclusion,
    ],
    [reasonCodesData]
  );

  const formId = useMemo(() => {
    return find({ id: stageId }, stages)?.forms[0]?.id;
  }, [stages, stageId]);

  const searchTokensWithOperator: TSearchWithOperatorObject[] = useMemo(() => {
    return getSearchTokens(activeKeywordFilters);
  }, [activeKeywordFilters]);

  const taskCounts: TTaskCounts = useMemo(() => {
    const all = get('all.aggregate.count', referencesData) ?? 0;
    const included = get('included.aggregate.count', referencesData) ?? 0;
    const excluded = get('excluded.aggregate.count', referencesData) ?? 0;
    const postponed = get('postponed.aggregate.count', referencesData) ?? 0;

    return {
      total: all,
      included,
      excluded,
      toReview: all - included - excluded - postponed,
      postponed,
      progress: all === 0 ? 0 : round(((included + excluded) / all) * 100),
    };
  }, [referencesData]);

  const referencesCount = useMemo(() => {
    return getTaskCountForDecisionFilter(decisionFilter, taskCounts);
  }, [decisionFilter, taskCounts]);

  const setActiveReference = useCallback(
    (activeReference) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: references[activeReference].id },
      });
    },
    [setActiveAndSelectedReferences, references]
  );

  const shiftActiveReference = useCallback(
    (direction: -1 | 1) => {
      if (activeReferenceIdx == null) return;

      setActiveReference(clamp(0, references.length - 1, activeReferenceIdx + direction));
    },
    [references.length, activeReferenceIdx, setActiveReference]
  );

  const filterReferences = useCallback(
    (payload: {
      decisionFilter: DecisionFilter;
      searchPhraseTokens: string[];
      searchTokensWithOperator: TSearchWithOperatorObject[];
      activeDocumentTypeFilters: TActiveAttributeFilters;
      activeYearFilters: YearFilters;
      activeDecisionCodeFilters: TActiveAttributeFilters;
      activeScreeningTagFilters: TActiveScreeningTagFilters;
      filtersTarget: TFilterTarget;
      isDuplicatesList: boolean;
      orderedBy: object;
    }) => {
      const {
        decisionFilter,
        searchPhraseTokens,
        searchTokensWithOperator,
        activeDocumentTypeFilters,
        activeYearFilters,
        activeDecisionCodeFilters,
        activeScreeningTagFilters,
        filtersTarget,
        isDuplicatesList,
        orderedBy,
      } = payload;

      // since this component is used to present references in several contexts: overall project
      // references, per stage references and conflicting references, we need to prepare 3 reference
      // filters to serve those different contexts
      const allCountFilter = getAllCountFilter(
        stage?.id,
        referenceStatus,
        duplicatesOnlyList,
        activeBatchKey
      );
      const resolvedCountFilter = getResolvedCountFilter(
        stage?.id,
        referenceStatus,
        duplicatesOnlyList
      );

      referencesSearchArgs.current = {
        offset: 0,
        limit: REFERENCES_BATCH_SIZE,
        searchArgs: getSearchArgs({
          projectId,
          activeDocumentTypeFilters,
          activeDecisionCodeFilters,
          activeScreeningTagFilters,
          activeYearFilters,
          searchPhraseTokens,
          filtersTarget,
          searchTokensWithOperator,
          onlyWithComments,
          pdfFilter,
          onlyWithoutAbstract,
          stageId,
          orderedBy,
          isDuplicatesList,
        }),
        allCountFilter,
        referencesFilter: decisionFilter === 'resolved' ? resolvedCountFilter! : allCountFilter,
        resolvedCountFilter:
          referenceStatus === 'conflicts' && stage != null
            ? {
                removal_reason: duplicatesOnlyList
                  ? { _eq: ReferenceRemovalReason.IsDuplicate }
                  : { _is_null: true },
                study: {
                  tasks_with_status: {
                    stage_id: { _eq: stage.id },
                    task_type: { _eq: ScreeningTaskType.ConflictResolution },
                    inclusion_status: { _neq: 'to_review' },
                  },
                },
              }
            : undefined,
      };

      setState((current) => ({
        ...current,
        loadingMoreReferences: false,
        lastReferencesFetchVariables: referencesSearchArgs.current!,
      }));

      fetchReferences({ variables: referencesSearchArgs.current });
    },
    [
      fetchReferences,
      setState,
      projectId,
      activeBatchKey,
      onlyWithComments,
      pdfFilter,
      onlyWithoutAbstract,
      stage,
      referenceStatus,
      duplicatesOnlyList,
      stageId,
    ]
  );

  const openFocusMode = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('refIdx', activeReferenceIdx.toString());
    searchParams.set('stageId', stageId || '');
    searchParams.set('referenceStatus', referenceStatus || '');
    history.push(
      withPrevLocation({
        pathname: [
          '/projects',
          projectId,
          'references',
          references[activeReferenceIdx].id,
          'details',
        ].join('/'),
        search: searchParams.toString(),
      })
    );
  }, [
    history,
    projectId,
    references,
    activeReferenceIdx,
    stageId,
    referenceStatus,
    location.search,
  ]);

  const openImportReferencesDialog = useCallback(() => {
    setState({ importReferencesDialogOpen: true });
  }, [setState]);

  const closeImportReferencesDialog = useCallback(() => {
    setState({ importReferencesDialogOpen: false });
  }, [setState]);

  const openImportPDFsDialog = useCallback(() => {
    setState({ importPDFsDialogOpen: true });
  }, [setState]);

  const closeImportPDFsDialog = useCallback(() => {
    setState({ importPDFsDialogOpen: false });
  }, [setState]);

  const openExportReferencesDialog = useCallback(() => {
    setState({ exportReferencesDialogOpen: true });
  }, [setState]);

  const closeExportReferencesDialog = useCallback(() => {
    setState({ exportReferencesDialogOpen: false });
  }, [setState]);

  const setSelectedReferences = useCallback(
    (updateFn: (selected: number[]) => number[]) => {
      setActiveAndSelectedReferences({
        variables: {
          selectedReferences: map((idx) => references[idx].id, updateFn(selectedReferences)),
        },
      }).then(() => {
        setAllReferencesSelected({ variables: { allReferencesSelected: false } });
      });
    },
    [setActiveAndSelectedReferences, setAllReferencesSelected, selectedReferences, references]
  );

  const handleLoadMoreReferences = useCallback(() => {
    setState({ loadingMoreReferences: true });
    fetchMore({
      variables: {
        offset: references.length,
        limit: REFERENCES_BATCH_SIZE,
      },
      updateQuery: (prev: TReferencesSearchQueryData, { fetchMoreResult, variables }) => {
        if (fetchMoreResult == null) return prev;

        return {
          ...fetchMoreResult,
          references: uniqBy(get('id'), [...prev.references, ...fetchMoreResult.references]),
        };
      },
    })
      .then(({ data }) => {
        allReferencesSelected &&
          setActiveAndSelectedReferences({
            variables: {
              selectedReferences: concat(map('id', data.references), map('id', references)),
            },
          });
      })
      .finally(() => {
        setState({ loadingMoreReferences: false });
      });
  }, [setState, fetchMore, references, allReferencesSelected, setActiveAndSelectedReferences]);

  const filtersApplied: boolean = hasFiltersApplied({
    searchPhraseTokens,
    searchTokensWithOperator,
    activeDocumentTypeFilters,
    activeYearFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
    activePdfFilter: pdfFilter,
    activeOnlyWithoutAbstract: onlyWithoutAbstract,
    activeOnlyWithComments: onlyWithComments,
  });

  useEffect(() => {
    // update searchPhraseTokensRef on searchPhraseTokens change
    if (!isEmpty(xor(searchPhraseTokens, searchPhraseTokensRef.current))) {
      searchPhraseTokensRef.current = searchPhraseTokens;
    }
  }, [searchPhraseTokens, searchPhraseTokensRef]);

  const refetchReferences = useCallback(() => {
    filterReferences({
      decisionFilter,
      searchPhraseTokens: searchPhraseTokensRef.current,
      searchTokensWithOperator,
      activeDocumentTypeFilters,
      activeYearFilters,
      activeDecisionCodeFilters,
      activeScreeningTagFilters,
      filtersTarget,
      isDuplicatesList: duplicatesOnlyList,
      orderedBy,
    });
  }, [
    decisionFilter,
    searchPhraseTokensRef.current,
    searchTokensWithOperator,
    activeDocumentTypeFilters,
    activeDecisionCodeFilters,
    activeYearFilters,
    activeScreeningTagFilters,
    filtersTarget,
    filterReferences,
    duplicatesOnlyList,
    orderedBy,
  ]);

  const handleReferencesRemove = useCallback(
    (referenceIds: string[], removalReason: string) => {
      return removeReferences({
        variables: {
          referenceIds,
          removalReason,
          userId: user.id,
        },
      }).then((result) => {
        refetchReferences();
        return result;
      });
    },
    [removeReferences, user, refetchReferences]
  );

  const handleReferencesRestore = useCallback(
    (referenceIds: string[]) => {
      return restoreReferences({ variables: { referenceIds } }).then((result) => {
        refetchReferences();
        return result;
      });
    },
    [restoreReferences, refetchReferences]
  );

  const handleReferenceNoReportReset = useCallback(
    (referenceIds) => {
      return updateReferencesNoReportFieldAndClaims({
        variables: {
          referenceIds,
          noReport: false,
          claimedReferencesToDeleteIds: referenceIds,
        },
        optimisticResponse: {
          update_reference: {
            __typename: 'reference_mutation_response',
            returning: referenceIds.map((refId: string) => ({
              id: refId,
              no_report: false,
              __typename: 'reference',
            })),
          },
          delete_claimed_references: {
            __typename: 'delete_claimed_references_mutation_response',
            affected_rows: referenceIds.length,
          },
        },
        update: (proxy, { data }) => {
          const updatedReferences: Pick<Reference, 'id' | 'no_report'>[] = get(
            'update_reference.returning',
            data
          );
          const referenceFragment = gql`
            fragment ReferenceNoReportFragment on reference {
              id
              no_report
            }
          `;
          updatedReferences.forEach((updatedReference) => {
            const referenceCacheId = `reference:${updatedReference.id}`;

            proxy.writeFragment({
              id: referenceCacheId,
              fragment: referenceFragment,
              data: updatedReference,
            });
          });
        },
      });
    },
    [updateReferencesNoReportFieldAndClaims]
  );

  const handleSelectAllReferences = useCallback(() => {
    setAllReferencesSelected({
      variables: {
        allReferencesSelected: true,
        selectedReferences: map('id', references),
      },
    });
  }, [setAllReferencesSelected, references]);

  // fetch reference whenever any fetching variable changes. Since `refetchReferences` includes all
  // fetching variables as its deps, use it as a single dependency of this effect instead of
  // enumerating those variables
  useEffect(() => {
    refetchReferences();
  }, [refetchReferences]);

  // remove refs IDs from selectedReferences if they don't exist in a filtered set of references
  useEffect(() => {
    const filteredSelectedRefs = filter(
      (id) => findIndex(propEq('id', id), references) > -1,
      selectedReferencesIds
    );
    if (selectedReferencesIds.length !== filteredSelectedRefs.length) {
      setActiveAndSelectedReferences({
        variables: {
          selectedReferences: filteredSelectedRefs,
        },
      });
    }
  }, [setActiveAndSelectedReferences, references, selectedReferencesIds]);

  // on unmount
  useEffect(
    () => () => {
      resetScreeningState();
    },
    [resetScreeningState]
  );

  // apply search params reference filters
  useEffect(() => {
    const maybeAppliedFilters = locationSearchToReferenceFilters(location.search);
    if (maybeAppliedFilters) {
      resetFilters({
        variables: {
          filters: maybeAppliedFilters,
        },
      });
    }
  }, []);

  // update location search with new filters data if any
  useEffect(() => {
    const appliedFiltersString = queryParams.get('appliedFilters');

    const currentFiltersString = referenceFiltersToLocationSearchString({
      decisionFilter,
      searchPhraseTokens,
      activeKeywordFilters,
      activeDocumentTypeFilters,
      activeYearFilters,
      activeDecisionCodeFilters,
      activeScreeningTagFilters,
      filtersTarget,
      orderedBy,
      onlyWithComments,
      pdfFilter,
      onlyWithoutAbstract,
      activeBatchKey,
    });

    if (currentFiltersString !== appliedFiltersString) {
      const updatedQueryParams = new URLSearchParams(queryParams.toString());
      updatedQueryParams.set('appliedFilters', currentFiltersString);
      history.push({
        pathname: window.location.pathname,
        search: updatedQueryParams.toString(),
      });
    }
  }, [
    history,
    queryParams,
    decisionFilter,
    searchPhraseTokens,
    activeKeywordFilters,
    activeDocumentTypeFilters,
    activeYearFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
    filtersTarget,
    orderedBy,
    onlyWithComments,
    pdfFilter,
    onlyWithoutAbstract,
    activeBatchKey,
  ]);

  // reset screeningState when route params change
  useDidUpdateEffect(() => {
    resetScreeningState();
  }, [projectId, stageId, referenceStatus, resetScreeningState]);

  // re-fetch refs when import completes
  useEffect(() => {
    if (importTasks !== importTasksPrev) {
      const importedCompleted = some({ status: WorkerStatus.Completed }, importTasks);

      if (importedCompleted) {
        refetchReferences();
      }
    }
  }, [importTasks, importTasksPrev, refetchReferences]);

  // re-fetch refs when PDF (reference attachment) gets uploaded
  useEffect(() => {
    if (attachmentsCount !== attachmentsCountPrev) {
      refetchReferences();
    }
  }, [refetchReferences, attachmentsCount, attachmentsCountPrev]);

  return error ? (
    <ErrorScreen error={error} />
  ) : loading ?? !everFetched ? (
    <Spinner css={centerItemCss} />
  ) : (
    <Fragment>
      {unassignedPDFCount > 0 && (
        <UnassignedPDFsBanner count={unassignedPDFCount} projectId={projectId} />
      )}
      <List
        references={references}
        decisionFilter={decisionFilter}
        activeReference={activeReferenceIdx}
        handleActiveReferenceSelect={toggleActiveReferenceSelect}
        openFocusMode={openFocusMode}
        selectedReferences={selectedReferences}
        setActiveReference={setActiveReference}
        setSelectedReferences={setSelectedReferences}
        decisionReasons={decisionReasons}
        domains={reasonCodesData}
        documentTypes={documentTypesData}
        yearsFilterData={yearsFilterData}
        searchingReferences={loadingRefs && !loadingMoreReferences}
        loadingMoreReferences={loadingMoreReferences}
        activeKeywordFilters={activeKeywordFilters}
        activeDecisionCodeFilters={activeDecisionCodeFilters}
        activeDocumentTypeFilters={activeDocumentTypeFilters}
        activeYearFilters={activeYearFilters}
        searchPhraseTokens={searchPhraseTokens}
        filtersApplied={filtersApplied}
        screeningCompleted={screeningCompleted}
        onLoadMoreReferences={
          referencesCount > references.length ? handleLoadMoreReferences : undefined
        }
        orderedBy={orderedBy}
        openImportReferencesDialog={openImportReferencesDialog}
        openImportPDFsDialog={openImportPDFsDialog}
        openExportReferencesDialog={openExportReferencesDialog}
        batches={batches}
        activeBatchKey={activeBatchKey}
        onlyWithComments={onlyWithComments}
        pdfFilter={pdfFilter}
        onlyWithoutAbstract={onlyWithoutAbstract}
        allReferencesCount={allReferencesCount}
        screeningTags={screeningTags}
        activeScreeningTagFilters={activeScreeningTagFilters}
        projectStageTypes={map('type', stages)}
        stage={stage}
        resolvedConflictsCount={resolvedConflictsCount}
        projectId={projectId}
        formId={formId}
        refetchReferences={refetchReferences}
        onRemoveReferences={handleReferencesRemove}
        onRestoreReferences={handleReferencesRestore}
        isDuplicatesList={duplicatesOnlyList}
        onResetReferencesNoReportField={handleReferenceNoReportReset}
        searchTokens={searchPhraseTokens}
        allReferencesSelected={allReferencesSelected}
        onSelectAllReferences={handleSelectAllReferences}
        referencesSearchArgs={referencesSearchArgs.current}
      />
      <FilesUploadDialog
        isOpen={importPDFsDialogOpen}
        projectId={projectId}
        continueFn={closeImportPDFsDialog}
      />
      <ImportDialog
        isOpen={importReferencesDialogOpen}
        onClose={closeImportReferencesDialog}
        projectId={projectId}
        onImportTaskRemoved={refetchReferences}
      />
      <ExportDialog
        isOpen={exportReferencesDialogOpen}
        onClose={closeExportReferencesDialog}
        projectId={projectId}
        selectedReferencesIds={selectedReferencesIds}
        referencesSearchArgs={referencesSearchArgs.current}
        allReferencesCount={allReferencesCount}
      />
      <NavAndDecisionControls
        onlyHotkeys
        onShiftActiveReference={shiftActiveReference}
        onEnter={openFocusMode}
        onToggleActiveReferenceSelect={noop}
        onInclude={noop}
        onExclude={noop}
        onPostpone={noop}
        exclusionReasons={[]}
      />
    </Fragment>
  );
});

export default ScreeningList;
