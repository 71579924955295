import { Trans } from '@lingui/macro';
import React from 'react';
import ConfirmationDialog from '../../common/confirmation_dialog';

interface IDuplicatesRemoveConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DuplicatesRemoveConfirmation: React.FC<IDuplicatesRemoveConfirmationProps> = ({
  onClose,
  onConfirm,
  isOpen,
}) => {
  return (
    <ConfirmationDialog
      title={<Trans>Remove as duplicate</Trans>}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmBtnText={<Trans>Remove as duplicate</Trans>}
    >
      <div className="mb-2">
        <Trans>Records will be removed from the screening and marked as duplicates.</Trans>
      </div>
      <div>
        <Trans>List of duplicated records will be available in the Project tab.</Trans>
      </div>
    </ConfirmationDialog>
  );
};

export default DuplicatesRemoveConfirmation;
