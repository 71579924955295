import {
  compose,
  concat,
  curry,
  findIndex,
  isEmpty,
  map,
  prop,
  propEq,
  trim,
  uniq,
} from 'lodash/fp';
import { reduce } from 'lodash';
import { uid, updateArrayItem } from './utils';
import {
  DomainData,
  FTScreeningCriteria,
  FTScreeningFormData,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  KeywordsData,
  ScreenerInclusionExclusionCriteriaDomainData,
  ScreenerInclusionExclusionCriteriaFormKeywords,
  StageType,
  UserKeyword,
  VariableData,
} from '../common/types';
import { TVariableEditData } from '../components/criteria/variable_edit_dialog';
import { TActiveKeywordFilters } from '../apollo/screening_state';
import i18n from '../i18n';
import { t } from '@lingui/macro';

export const createDomain = (domainData: Partial<DomainData>): DomainData => ({
  id: uid(),
  name: '',
  exclusionButtonLabel: '',
  exclusionCode: '',
  variables: [],
  ...domainData,
});

export const createVariable = (variableData: TVariableEditData): VariableData => ({
  id: uid(),
  title: '',
  name: variableData.variableName,
  instructions: variableData.instructions ? '' : null,
  keywords: variableData.keywords ? { included: [], excluded: [] } : null,
});

export function createFTScreeningCriteria(
  inclusionStatus: InclusionStatus.Included | InclusionStatus.Excluded
): FTScreeningCriteria {
  return {
    id: uid(),
    name: '',
    inclusionStatus,
    code: '',
  };
}

function _updateDomainVariable(
  variableId: string,
  updater: (data: VariableData) => VariableData,
  domain: DomainData
): DomainData {
  const variableIdx = findIndex(propEq('id', variableId), domain.variables);
  if (variableIdx === -1) return domain;

  return { ...domain, variables: updateArrayItem(variableIdx, updater, domain.variables) };
}

function _updateDomain(
  domainId: string,
  updater: (domain: DomainData) => DomainData,
  domains: DomainData[]
): DomainData[] {
  const domainIdx = findIndex(propEq('id', domainId), domains);
  if (domainIdx === -1) return domains;

  return updateArrayItem(domainIdx, updater, domains);
}

export const updateDomain = curry(_updateDomain);
export const updateDomainVariable = curry(_updateDomainVariable);

export function mergeUserKeywords(
  form: InclusionExclusionCriteriaFormData,
  userKeywords: ScreenerInclusionExclusionCriteriaFormKeywords
): InclusionExclusionCriteriaFormData {
  return reduce(
    userKeywords,
    (
      currentForm: InclusionExclusionCriteriaFormData,
      variables: ScreenerInclusionExclusionCriteriaDomainData,
      domainId: string
    ) => {
      const { domains } = currentForm;
      const domainIdx = findIndex(propEq('id', domainId), domains);
      if (domainIdx === -1) return currentForm;
      return {
        ...currentForm,
        domains: updateArrayItem(
          domainIdx,
          (domain) =>
            reduce(
              variables,
              (currentDomain: DomainData, keywordsData: KeywordsData, variableId: string) => {
                const { variables } = currentDomain;
                const variableIdx = findIndex(propEq('id', variableId), variables);
                if (variableIdx === -1) return currentDomain;
                return {
                  ...currentDomain,
                  variables: updateArrayItem(
                    variableIdx,
                    (variable) => ({ ...variable, keywords: keywordsData }),
                    variables
                  ),
                };
              },
              domain
            ),
          domains
        ),
      };
    },
    form
  );
}

export interface SearchAndDesiredUndesiredKeywords {
  desiredKeywords: string[];
  searchKeywords: string[];
  undesiredKeywords: string[];
}

export const EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS: SearchAndDesiredUndesiredKeywords = {
  desiredKeywords: [],
  searchKeywords: [],
  undesiredKeywords: [],
};

export function getKeywordsFromDomains(domains: DomainData[]): SearchAndDesiredUndesiredKeywords {
  const { desiredKeywords, searchKeywords, undesiredKeywords } = reduce(
    domains,
    (currentKeywords, { variables }) =>
      reduce(
        variables,
        (acc, { keywords }) => ({
          desiredKeywords: [...acc.desiredKeywords, ...(keywords?.included ?? [])],
          searchKeywords: [...acc.searchKeywords],
          undesiredKeywords: [...acc.undesiredKeywords, ...(keywords?.excluded ?? [])],
        }),
        currentKeywords
      ),
    EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
  );
  return {
    desiredKeywords: uniq(desiredKeywords),
    searchKeywords,
    undesiredKeywords: uniq(undesiredKeywords),
  };
}

export function combineActiveKeywordFiltersSearchPhrasesAndKeywordsDataFromForm(
  activeKeywordFilters: TActiveKeywordFilters,
  searchPhraseTokens: string[],
  keywordsDataFromForm: SearchAndDesiredUndesiredKeywords
): SearchAndDesiredUndesiredKeywords {
  const activeKeywordFiltersKeywords = map('keywords', activeKeywordFilters) ?? {};
  const { desiredKeywords, undesiredKeywords } = reduce(
    activeKeywordFiltersKeywords,
    (currentKeywords, keywords) =>
      reduce(
        keywords,
        (acc, { active, type }, keyword) => {
          if (!active) return acc;
          switch (type) {
            case 'included':
              return {
                desiredKeywords: [...acc.desiredKeywords, keyword],
                searchKeywords: [],
                undesiredKeywords: [...acc.undesiredKeywords],
              };
            case 'excluded':
              return {
                desiredKeywords: [...acc.desiredKeywords],
                searchKeywords: [],
                undesiredKeywords: [...acc.undesiredKeywords, keyword],
              };
            default:
              return acc;
          }
        },
        currentKeywords
      ),
    EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
  );

  return {
    desiredKeywords: uniq(concat(desiredKeywords, keywordsDataFromForm.desiredKeywords)),
    searchKeywords: uniq(concat(searchPhraseTokens, keywordsDataFromForm.searchKeywords)),
    undesiredKeywords: uniq(concat(undesiredKeywords, keywordsDataFromForm.undesiredKeywords)),
  };
}

export const EMPTY_KEYWORDS_DATA: KeywordsData = {
  excluded: [],
  included: [],
};

export function mergeKeywordsDataAndUserKeywords(
  currentKeywords: KeywordsData,
  userKeywordsToMerge: UserKeyword[]
): KeywordsData {
  const merged = reduce<UserKeyword, KeywordsData>(
    userKeywordsToMerge,
    (acc, userKeywords) => ({
      excluded: [...acc.excluded, ...userKeywords.excluded],
      included: [...acc.included, ...userKeywords.included],
    }),
    currentKeywords
  );
  return {
    excluded: uniq(merged.excluded),
    included: uniq(merged.included),
  };
}

function isIncompleteCriteria(criteria: FTScreeningCriteria) {
  return isEmpty(criteria.code?.trim()) || isEmpty(criteria.name.trim());
}

const isEmptyTag = compose(isEmpty, trim, prop('tag'));

function hasDuplicates(values: string[]): boolean {
  return values.length !== compose(uniq, map(trim))(values).length;
}

export function validateScreeningForm(
  formData: FTScreeningFormData,
  stageType?: StageType
): string[] {
  const allCriteria: FTScreeningCriteria[] = [...formData.inclusion, ...formData.exclusion];
  const errors: string[] = [];

  // duplicate criteria
  if (hasDuplicates(map('name', allCriteria))) {
    errors.push(i18n._(t`duplicate criteria names`));
  }

  // duplicate criteria codes
  if (hasDuplicates(map('code', allCriteria))) {
    errors.push(i18n._(t`duplicate codes`));
  }

  // duplicate tags (aka structured comment)
  if (hasDuplicates(map('tag', formData.tags))) {
    errors.push(i18n._(t`duplicate structured comments`));
  }

  if (formData.exclusion.some(isIncompleteCriteria)) {
    errors.push(i18n._(t`incomplete exclusion criteria`));
  }

  if (formData.inclusion.some(isIncompleteCriteria)) {
    errors.push(i18n._(t`incomplete inclusion criteria`));
  }

  if (formData.tags.some(isEmptyTag)) {
    errors.push(i18n._(t`empty tag value`));
  }

  // inclusion criteria is required in FT stage
  if (stageType === StageType.FullTextScreening && isEmpty(formData.inclusion)) {
    errors.push(i18n._(t`no inclusion criteria`));
  }

  // exclusion criteria missing
  if (isEmpty(formData.exclusion)) {
    errors.push(i18n._(t`no exclusion criteria`));
  }

  return errors;
}
