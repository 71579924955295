/** @jsx jsx */
import {
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { compact, compose, find, includes, isEmpty, join, omit, toPairs } from 'lodash/fp';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { AccountType } from '../../common/types';
import i18n from '../../i18n';
import { useKeycloak } from '../../keycloak';
import countries from '../../lib/countries';
import AppToaster from '../../lib/toaster';
import { getAccountTypeLabel } from '../../lib/user_helpers';
import { handleUserInputError, useCurrCallback, useSetState } from '../../lib/utils';
import Validations, { combineValidators, TValidators } from '../../lib/validations';
import useActionLogger from '../hooks/use_action_logger';
import useDidUpdateEffect from '../hooks/use_did_update_effect';

const AccountTypeSelect = Select.ofType<AccountType>();

export type TUserFields = {
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  role?: AccountType;
  salutation?: string;
  title?: string;
  phoneAreaCode?: string;
  phoneNumber?: string;
  institutionName?: string;
  institutionWebsite?: string;
  institutionAreaCode?: string;
  institutionPhone?: string;
  institutionAddress?: string;
  institutionCity?: string;
  institutionPostalCode?: string;
  institutionCountry?: string;
};

const NEW_USER_DATA: TUserFields = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  role: AccountType.User,
};

const dialogCss = css`
  width: 50vw;
`;

const combinePhoneValidationError = compose(join('\n'), compact);

type SelectOption = {
  value: string;
  label: string;
};
const SALUTATION_OPTIONS: SelectOption[] = [
  { value: 'mrs', label: i18n._(t`Mrs.`) },
  { value: 'mr', label: i18n._(t`Mr`) },
];
const TITLE_OPTIONS: SelectOption[] = [
  { value: 'dr', label: i18n._(t`Doctor`) },
  { value: 'prof', label: i18n._(t`Professor`) },
  { value: 'prof dr', label: i18n._(t`Professor Doctor`) },
];
const COUNTRIES_OPTIONS: SelectOption[] = countries.map((country) => ({
  value: country,
  label: country,
}));
const OptionsSelect = Select.ofType<SelectOption>();

const RequiredFieldMark = () => <span className="text-xl text-red-600">*</span>;

type State = {
  fields: TUserFields;
  validationErrors: { [fieldName: string]: string };
  creating: boolean;
};

interface ICreateUserDialogProps {
  isOpen: boolean;
  onSave: (data: Omit<TUserFields, 'passwordRepeat'>) => Promise<any>;
  onClose: () => void;
  userData?: Omit<TUserFields, 'password' | 'passwordRepeat'>;
}

const CreateUserDialog: React.FC<ICreateUserDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  userData,
}) => {
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState<State>({
    fields: userData ?? NEW_USER_DATA,
    validationErrors: {},
    creating: false,
  });
  const { fields, validationErrors, creating } = state;
  const isNewUser = userData == null;
  const { user } = useKeycloak();

  const fieldsToValidate: TValidators = useMemo(() => {
    return {
      email: combineValidators(Validations.required, Validations.String.email),
      firstName: Validations.required,
      lastName: Validations.required,
      // validate password for new user - always, for existing - only if new password is provided
      password: isNewUser
        ? combineValidators(Validations.required, Validations.passwordPolicy)
        : (newValue: string) =>
            isEmpty(newValue) ? [true, null] : Validations.passwordPolicy(newValue),
      phoneAreaCode: Validations.String.phoneAreaCode,
      phoneNumber: Validations.String.phoneNumber,
      institutionAreaCode: Validations.String.phoneAreaCode,
      institutionPhone: Validations.String.phoneNumber,
    };
  }, [isNewUser]);

  const validateFields = useCallback(
    (fields: TUserFields) => {
      return toPairs(fieldsToValidate).reduce((acc, [fieldName, validator]) => {
        const fieldValue = fields[fieldName];
        const [valid, error] = validator(fieldValue);

        return valid ? acc : { ...acc, [fieldName]: error };
      }, {});
    },
    [fieldsToValidate]
  );

  const updateFieldValue = useCallback(
    (fieldName, value) => {
      setState((currState: State) => {
        let { validationErrors } = currState;
        const updatedFields = { ...currState.fields, [fieldName]: value };

        if (fieldName in fieldsToValidate) {
          const [valid, error] = fieldsToValidate[fieldName](value);
          if (valid && fieldName in validationErrors) {
            validationErrors = omit(fieldName, validationErrors);
          }
          if (!valid) {
            validationErrors = { ...validationErrors, [fieldName]: error };
          }
        }

        return {
          ...currState,
          fields: updatedFields,
          validationErrors,
        };
      });
    },
    [fieldsToValidate, setState]
  );

  const handleInputChange = useCurrCallback(
    (fieldName, evt) => {
      const val = evt.target.value;

      updateFieldValue(fieldName, val);
    },
    [updateFieldValue]
  );

  const handleValueSelect = useCurrCallback(
    (fieldName: string, item: SelectOption) => {
      setState((currState: State) => ({
        ...currState,
        fields: { ...currState.fields, [fieldName]: item.value },
      }));
    },
    [setState]
  );

  const renderSelectOption = useCurrCallback(
    (selected, { value, label }, { handleClick, modifiers }) => {
      return (
        <MenuItem
          key={value}
          data-value={value}
          onClick={handleClick}
          text={label}
          active={selected === value}
        />
      );
    },
    []
  );

  const handleCancel = useCallback(() => {
    setState({ creating: false, validationErrors: {}, fields: userData ?? NEW_USER_DATA });
    onClose();
  }, [setState, onClose, userData]);

  const handleCreate = useCallback(() => {
    const validationErrors = validateFields(fields);

    if (isEmpty(validationErrors)) {
      setState({ creating: true });
      onSave(fields)
        .then(() => {
          insertActionLog(`${isNewUser ? 'created' : 'updated'} user`, { fields });
          handleCancel();
          AppToaster.show({
            message: <Trans>New account created successfully.</Trans>,
            intent: Intent.SUCCESS,
          });
        })
        .catch((error) => {
          insertActionLog(`failed to ${isNewUser ? 'create' : 'update'} user`, {
            fields,
            error,
          });
          handleUserInputError(error);
        })
        .finally(() => {
          setState({ creating: false });
        });
    } else {
      setState({ validationErrors });
    }
  }, [validateFields, setState, onSave, fields, isNewUser, handleCancel, insertActionLog]);

  const handleAccountTypeSelect = useCallback(
    (accType: AccountType) => {
      updateFieldValue('role', accType);
    },
    [updateFieldValue]
  );

  const userPhoneValidationErrors = combinePhoneValidationError([
    validationErrors.phoneAreaCode,
    validationErrors.phoneNumber,
  ]);
  const institutionPhoneValidationErrors = combinePhoneValidationError([
    validationErrors.institutionAreaCode,
    validationErrors.institutionPhone,
  ]);

  useDidUpdateEffect(() => {
    setState({
      fields: userData ?? NEW_USER_DATA,
    });
  }, [setState, userData]);

  const roleItems = useMemo<AccountType[]>(() => {
    switch (user.role) {
      case AccountType.TechAdmin:
        return [AccountType.User, AccountType.TechAdmin];
      case AccountType.ItAdmin:
        return [AccountType.User, AccountType.TechAdmin, AccountType.ItAdmin];
      default:
        return [];
    }
  }, [user.role]);

  return (
    <Dialog
      css={dialogCss}
      isOpen={isOpen}
      title={isNewUser ? <Trans>Create user account</Trans> : <Trans>Edit user account</Trans>}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      onClose={onClose}
    >
      <div className={`${Classes.DIALOG_BODY} px-1`}>
        {!isEmpty(roleItems) && includes(fields.role, roleItems) && (
          <Fragment>
            <FormGroup label={<Trans>Account type</Trans>}>
              <AccountTypeSelect
                filterable={false}
                items={roleItems}
                itemRenderer={(accountType, { handleClick }) => (
                  <MenuItem
                    key={accountType}
                    onClick={handleClick}
                    text={getAccountTypeLabel(accountType)}
                    active={accountType === fields.role}
                  />
                )}
                onItemSelect={handleAccountTypeSelect}
              >
                <Button
                  name="account-type-select"
                  rightIcon={IconNames.CARET_DOWN}
                  text={fields.role ? getAccountTypeLabel(fields.role) : undefined}
                />
              </AccountTypeSelect>
            </FormGroup>
            <Divider />
          </Fragment>
        )}
        <FormGroup
          label={<Trans>Email address</Trans>}
          labelInfo={<RequiredFieldMark />}
          helperText={validationErrors.email ? validationErrors.email : undefined}
          intent={validationErrors.email ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            onChange={handleInputChange('email')}
            disabled={!isNewUser}
            name="email"
            autoComplete="off"
            value={fields.email ?? ''}
            intent={validationErrors.email ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <Fragment>
          <FormGroup
            label={
              isNewUser ? <Trans>Temporary Password</Trans> : <Trans>New Temporary Password</Trans>
            }
            labelInfo={isNewUser ? <RequiredFieldMark /> : null}
            helperText={validationErrors.password ? validationErrors.password : undefined}
            intent={validationErrors.password ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              onChange={handleInputChange('password')}
              name="password"
              type="password"
              autoComplete="off"
              value={fields.password ?? ''}
              intent={validationErrors.password ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
        </Fragment>
        <Divider />
        <div className="text-2xl my-8">
          <Trans>Contact data</Trans>
        </div>
        <div className="w-1/2 flex flex-row flex-no-wrap">
          <FormGroup label={<Trans>Salutation</Trans>} className="mr-2 flex-1">
            <OptionsSelect
              popoverProps={{ fill: true }}
              filterable={false}
              items={SALUTATION_OPTIONS}
              itemRenderer={renderSelectOption(fields.salutation)}
              onItemSelect={handleValueSelect('salutation')}
            >
              <Button
                className="w-full"
                alignText="left"
                name="salutation-select"
                text={
                  find({ value: fields.salutation }, SALUTATION_OPTIONS)?.label ?? (
                    <Trans>Select salutation</Trans>
                  )
                }
                rightIcon={IconNames.CARET_DOWN}
              />
            </OptionsSelect>
          </FormGroup>
          <FormGroup label={<Trans>Title</Trans>} className="flex-1">
            <OptionsSelect
              popoverProps={{ fill: true }}
              filterable={false}
              items={TITLE_OPTIONS}
              itemRenderer={renderSelectOption(fields.title)}
              onItemSelect={handleValueSelect('title')}
            >
              <Button
                className="w-full"
                alignText="left"
                name="title-select"
                text={
                  find({ value: fields.title }, TITLE_OPTIONS)?.label ?? <Trans>Select title</Trans>
                }
                rightIcon={IconNames.CARET_DOWN}
              />
            </OptionsSelect>
          </FormGroup>
        </div>
        <div className="flex flex-row flex-no-wrap mt-4">
          <FormGroup
            className="flex-1 mr-2"
            label={<Trans>First name</Trans>}
            labelInfo={<RequiredFieldMark />}
            helperText={validationErrors.firstName ? validationErrors.firstName : undefined}
            intent={validationErrors.firstName ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              onChange={handleInputChange('firstName')}
              value={fields.firstName ?? ''}
              name="firstName"
              autoComplete="given-name"
              intent={validationErrors.firstName ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
          <FormGroup
            className="flex-1"
            label={<Trans>Last name</Trans>}
            labelInfo={<RequiredFieldMark />}
            helperText={validationErrors.lastName ? validationErrors.lastName : undefined}
            intent={validationErrors.lastName ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              onChange={handleInputChange('lastName')}
              value={fields.lastName ?? ''}
              name="lastName"
              autoComplete="family-name"
              intent={validationErrors.lastName ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
        </div>
        <div className="w-1/2 flex flex-row flex-no-wrap relative">
          <FormGroup
            label={<Trans>Phone number</Trans>}
            className="flex-1"
            helperText={userPhoneValidationErrors}
            intent={isEmpty(userPhoneValidationErrors) ? Intent.NONE : Intent.DANGER}
          >
            <div className="flex flex-row flex-no-wrap">
              <div className="mr-2 flex-1">
                <InputGroup
                  fill
                  name="phoneAreaCode"
                  autoComplete="tel-area-code"
                  onChange={handleInputChange('phoneAreaCode')}
                  value={fields.phoneAreaCode ?? ''}
                  intent={validationErrors.phoneAreaCode ? Intent.DANGER : Intent.NONE}
                />
              </div>
              <div className="flex-1" style={{ flexGrow: 3 }}>
                <InputGroup
                  fill
                  name="phoneNumber"
                  autoComplete="tel-local"
                  onChange={handleInputChange('phoneNumber')}
                  value={fields.phoneNumber ?? ''}
                  intent={validationErrors.phoneNumber ? Intent.DANGER : Intent.NONE}
                />
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="text-2xl my-8">
          <Trans>Institution</Trans>
        </div>
        <FormGroup label={<Trans>Institution name</Trans>}>
          <InputGroup
            name="institutionName"
            autoComplete="organization"
            onChange={handleInputChange('institutionName')}
            value={fields.institutionName ?? ''}
          />
        </FormGroup>
        <div className="flex flex-row flex-no-wrap mt-4">
          <FormGroup className="flex-1 mr-2" label={<Trans>Institution website</Trans>}>
            <InputGroup
              onChange={handleInputChange('institutionWebsite')}
              value={fields.institutionWebsite ?? ''}
              name="institutionWebsite"
              autoComplete="url"
            />
          </FormGroup>
          <FormGroup
            className="flex-1"
            label={<Trans>Institution phone number</Trans>}
            helperText={institutionPhoneValidationErrors}
            intent={isEmpty(institutionPhoneValidationErrors) ? Intent.NONE : Intent.DANGER}
          >
            <div className="flex flex-row flex-no-wrap">
              <div className="mr-2 flex-1">
                <InputGroup
                  fill
                  autoComplete="tel-area-code"
                  name="institutionAreaCode"
                  onChange={handleInputChange('institutionAreaCode')}
                  value={fields.institutionAreaCode ?? ''}
                  intent={validationErrors.institutionAreaCode ? Intent.DANGER : Intent.NONE}
                />
              </div>
              <div className="flex-1" style={{ flexGrow: 3 }}>
                <InputGroup
                  fill
                  autoComplete="tel-local"
                  name="institutionPhone"
                  onChange={handleInputChange('institutionPhone')}
                  value={fields.institutionPhone ?? ''}
                  intent={validationErrors.institutionPhone ? Intent.DANGER : Intent.NONE}
                />
              </div>
            </div>
          </FormGroup>
        </div>
        <FormGroup label={<Trans>Street address</Trans>}>
          <InputGroup
            onChange={handleInputChange('institutionAddress')}
            value={fields.institutionAddress ?? ''}
            name="institutionAddress"
            autoComplete="street-address"
          />
        </FormGroup>
        <div className="flex flex-row flex-no-wrap mt-4">
          <FormGroup className="flex-1" label={<Trans>City</Trans>}>
            <InputGroup
              onChange={handleInputChange('institutionCity')}
              value={fields.institutionCity ?? ''}
              name="institutionCity"
              autoComplete="address-level2"
            />
          </FormGroup>
          <FormGroup className="mx-2 w-1/6" label={<Trans>Postal code</Trans>}>
            <InputGroup
              fill
              onChange={handleInputChange('institutionPostalCode')}
              value={fields.institutionPostalCode ?? ''}
              name="institutionPostalCode"
              autoComplete="postal-code"
            />
          </FormGroup>
          <FormGroup className="flex-1" label={<Trans>Country</Trans>}>
            <OptionsSelect
              popoverProps={{ fill: true }}
              items={COUNTRIES_OPTIONS}
              itemRenderer={renderSelectOption(fields.institutionCountry)}
              onItemSelect={handleValueSelect('institutionCountry')}
              itemPredicate={(q, item) => includes(q.toLowerCase(), item.value.toLowerCase())}
            >
              <Button
                className="w-full"
                alignText="left"
                text={fields.institutionCountry ?? <Trans>Select country</Trans>}
                rightIcon={IconNames.CARET_DOWN}
              />
            </OptionsSelect>
          </FormGroup>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={handleCancel} />
          <Button
            loading={creating}
            text={isNewUser ? <Trans>Create</Trans> : <Trans>Save</Trans>}
            intent={Intent.SUCCESS}
            onClick={handleCreate}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CreateUserDialog;
