/** @jsx jsx */
import Immutable from 'immutable';
import { jsx } from '@emotion/core';
import { Button, Divider, Intent, NonIdealState, Tag } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FTScreeningCriteria,
  FTScreeningFormData,
  InclusionStatus,
  ReferenceLog,
  ScreeningForm as IScreeningForm,
  ScreeningResult,
  Stage,
} from '../../../common/types';
import { TReferenceData } from '..';
import FTScreen from './ft_screen';
import ScreeningForm from './screening_form';
import { useKeycloak } from '../../../keycloak';
import { IconNames } from '@blueprintjs/icons';
import { filter, get, isNil, keyBy, noop, propEq, some } from 'lodash/fp';
import ReferencesConflictsData from './reference_conflicts_data';
import ScreeningTags from '../screening_tags';
import MyReferenceComment from '../../references/my_reference_comment';
import ReferenceCommentsByStage from '../../references/reference_comments_by_stage';
import {
  getReferenceComment,
  getSelectedFTScreeningCriteria,
  getTaskResultFromReference,
} from '../../../lib/task_helpers';
import { useCurrCallback, usePrevious } from '../../../lib/utils';
import useTaskResultDataUpdaters from '../../hooks/use_task_result_data_updaters';
import FTDecisionLabels from './ft_decision_labels';
import {
  collapseContainerCss,
  CollapsibleSection,
  CollapsibleSections,
  titleCss,
  useCollapsibleSections,
} from '../../common/collapsible_sections';
import NavAndDecisionControls from '../navigation_and_decision_controls';

export interface IFTConflictProps {
  projectId: string;
  stageId: string;
  form: IScreeningForm;
  onUpdateTaskResultData: (
    resultData: Partial<ScreeningResult>,
    logsData?: Partial<ReferenceLog>[]
  ) => Promise<any>;
  handleUnreview: () => void;
  onUpdateComment: (data: { id?: string; comment: string }) => void;
  onDeleteComment: (commentId: string) => void;
  projectStages: Pick<Stage, 'id' | 'name' | 'order_number'>[];
  onUpdateResultTags: (newTags: string[]) => void;
  loadNextReference: () => Promise<any>;
  unsentTasksCount: number;
  navigateToScreeningConflictsList: () => void;
  shiftActiveReference: (direction: -1 | 1) => void;
  reference?: TReferenceData;
  savingResult?: boolean;
  navigateToPrevReference?: () => Promise<any>;
  navigateToNextReference?: () => Promise<any>;
}

const FTConflict: React.FC<IFTConflictProps> = ({
  projectId,
  stageId,
  reference,
  form,
  onUpdateTaskResultData,
  handleUnreview,
  onUpdateComment,
  onDeleteComment,
  projectStages,
  onUpdateResultTags,
  loadNextReference,
  savingResult,
  unsentTasksCount,
  navigateToScreeningConflictsList,
  navigateToPrevReference,
  navigateToNextReference,
  shiftActiveReference,
}) => {
  const { user } = useKeycloak();
  const { expandedSections, toggleSection, setExpandedSection } = useCollapsibleSections([
    'conflict',
  ]);

  const [selectedCriteria, setSelectedCriteria] = useState<Immutable.Set<FTScreeningCriteria>>(
    Immutable.Set<FTScreeningCriteria>()
  );

  const excludeEnabled = selectedCriteria.some(propEq('inclusionStatus', InclusionStatus.Excluded));
  const includeEnabled =
    !excludeEnabled && selectedCriteria.some(propEq('inclusionStatus', InclusionStatus.Included));

  const resolutionResult = useMemo(() => {
    if (reference == null) return;

    return getTaskResultFromReference(reference, form.id, null, stageId);
  }, [reference, form.id, stageId]);

  const { persistUpdatedTaskResult } = useTaskResultDataUpdaters(
    onUpdateTaskResultData,
    resolutionResult
  );
  const referenceId = reference?.id;
  const referenceIdPrev = usePrevious(referenceId);

  const currentComment = reference ? getReferenceComment(reference, resolutionResult) : undefined;

  const handleUpdateTaskResultData = useCallback(
    (screeningResult: ScreeningResult, logsData?: Partial<ReferenceLog>[]) => {
      return onUpdateTaskResultData(screeningResult, logsData).then(() => loadNextReference());
    },
    [onUpdateTaskResultData, loadNextReference]
  );

  const handleDecision = useCurrCallback(
    (decision: InclusionStatus.Included | InclusionStatus.Excluded, _evt) => {
      if (reference == null) return;
      const criteria: FTScreeningCriteria[] = selectedCriteria.toArray();
      const screeningResult: ScreeningResult = {
        inclusionStatus: decision,
        criteria: criteria.map(({ id, inclusionStatus }) => ({
          id,
          answer: inclusionStatus === InclusionStatus.Included ? 'yes' : 'no',
        })),
      };

      const logsData: Partial<ReferenceLog>[] = [
        {
          reference_id: reference.id,
          data: {
            type: 'conflict_resolved',
            userId: user.id,
            stageId,
            decision,
            criteria: screeningResult.criteria,
            automaticResolution: false,
          },
        },
      ];

      handleUpdateTaskResultData(screeningResult, logsData).then(() =>
        setExpandedSection('conflict')
      );
    },
    [handleUpdateTaskResultData, reference, user, stageId, selectedCriteria, setExpandedSection]
  );

  // FIXME: copy/paste from ft_screening
  const updateSelectedCriteria = useCallback(
    (newCriteria: FTScreeningCriteria): Immutable.Set<FTScreeningCriteria> => {
      const { inclusionStatus } = newCriteria;
      switch (inclusionStatus) {
        case InclusionStatus.Included:
          const filteredCriteria = selectedCriteria.filter(
            propEq('inclusionStatus', InclusionStatus.Included)
          );
          return filteredCriteria.has(newCriteria)
            ? filteredCriteria.remove(newCriteria)
            : filteredCriteria.add(newCriteria);
        case InclusionStatus.Excluded:
          return selectedCriteria.clear().add(newCriteria);
        default:
          // do nothing
          return selectedCriteria;
      }
    },
    [selectedCriteria]
  );

  const otherComments = useMemo(() => {
    return filter(({ id }) => id !== currentComment?.id, reference?.reference_comments);
  }, [reference?.reference_comments, stageId]);

  // FIXME: copy/paste from ft_screening
  const handleCriteriaSelect = useCallback(
    (criteria: FTScreeningCriteria) => {
      const updatedSelectedCriteria = updateSelectedCriteria(criteria);
      setSelectedCriteria(updatedSelectedCriteria);
    },
    [setSelectedCriteria, updateSelectedCriteria, persistUpdatedTaskResult]
  );

  const handleApplyScreenerDecision = useCallback(
    (result: ScreeningResult) => {
      const formData: FTScreeningFormData = form.form;
      // copy result's criteria to the selected criteria state
      switch (result.inclusionStatus) {
        case InclusionStatus.Included:
          const includedCriteria = result.criteria?.filter(({ answer }) => answer === 'yes');

          includedCriteria &&
            setSelectedCriteria(
              Immutable.Set(formData.inclusion.filter(({ id }) => some({ id }, includedCriteria)))
            );
          break;
        case InclusionStatus.Excluded:
          const excludedCriteria = result.criteria?.filter(({ answer }) => answer === 'no');

          excludedCriteria &&
            setSelectedCriteria(
              Immutable.Set(formData.exclusion.filter(({ id }) => some({ id }, excludedCriteria)))
            );
        default:
          break;
      }
      // switch to final decision tab
      setExpandedSection('final_decision');
    },
    [setSelectedCriteria, form]
  );

  const handleEraseInclusionDecision = useCallback(
    (criteria: FTScreeningCriteria) => {
      if (reference == null) return;
      // remove the task result on removal of the last remaining selected criteria
      if (selectedCriteria.size === 1) {
        handleUnreview();
      } else {
        handleCriteriaSelect(criteria);
        // switch to final decision tab
        setExpandedSection('final_decision');
      }
    },
    [handleUnreview, handleCriteriaSelect, reference, selectedCriteria]
  );

  const inclusionDecisionLabels = useMemo(() => {
    if (resolutionResult && resolutionResult.result.inclusionStatus) {
      const { inclusionStatus, criteria: resultCriteria } = resolutionResult.result;

      if ([InclusionStatus.Included, InclusionStatus.Excluded].includes(inclusionStatus)) {
        const isIncluded = inclusionStatus === InclusionStatus.Included;
        const formCriteria: FTScreeningCriteria[] =
          get(`form.${isIncluded ? 'inclusion' : 'exclusion'}`, form) ?? [];
        const resultCriteriaMap = keyBy('id', resultCriteria);
        const selectedCriteria = formCriteria.filter(({ id }) => id in resultCriteriaMap);

        return (
          <FTDecisionLabels
            criteria={selectedCriteria}
            isIncluded={isIncluded}
            screeningCompleted={false}
            onEraseInclusionDecision={handleEraseInclusionDecision}
          />
        );
      }
    } else {
      return (
        <Tag intent={Intent.WARNING} large>
          <Trans>Conflict</Trans>
        </Tag>
      );
    }
  }, [handleEraseInclusionDecision, resolutionResult, form]);

  useEffect(() => {
    if (referenceId !== referenceIdPrev) {
      setSelectedCriteria(
        Immutable.Set<FTScreeningCriteria>(getSelectedFTScreeningCriteria(form, resolutionResult))
      );
    }
  }, [resolutionResult, form, referenceId, referenceIdPrev]);

  return (
    <Fragment>
      {isNil(reference) && unsentTasksCount > 0 ? (
        <NonIdealState
          title={<Trans>All conflicts have been resolved</Trans>}
          action={
            <Button
              icon={IconNames.ARROW_LEFT}
              text={<Trans>Reference list</Trans>}
              onClick={navigateToScreeningConflictsList}
            />
          }
        />
      ) : (
        <FTScreen
          projectId={projectId}
          stageId={stageId}
          reference={reference}
          sidebarContent={
            reference ? (
              <CollapsibleSections>
                <CollapsibleSection
                  id="conflict"
                  isOpen={expandedSections.includes('conflict')}
                  onToggle={toggleSection}
                  title={<Trans>Conflict</Trans>}
                  titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                  titleCss={titleCss}
                  collapseContainerCss={collapseContainerCss}
                  collapseContainerClass="border-b"
                >
                  <ReferencesConflictsData
                    stageId={stageId}
                    form={form}
                    reference={reference}
                    onApplyDecision={handleApplyScreenerDecision}
                    referenceComments={reference.reference_comments}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  id="final_decision"
                  isOpen={expandedSections.includes('final_decision')}
                  onToggle={toggleSection}
                  title={<Trans>Final decision</Trans>}
                  titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                  titleCss={titleCss}
                  collapseContainerCss={collapseContainerCss}
                  collapseContainerClass="border-b"
                >
                  <ScreeningForm
                    form={form.form}
                    selectedCriteria={selectedCriteria}
                    onCriteriaSelect={handleCriteriaSelect}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  id="comments"
                  isOpen={expandedSections.includes('comments')}
                  onToggle={toggleSection}
                  title={
                    <div className="flex items-center">
                      <span css={{ lineHeight: '1.25rem' }}>
                        <Trans>Comments</Trans> ({reference.reference_comments.length})
                      </span>{' '}
                      <Tag className="ml-2">{resolutionResult?.result.tags?.length ?? 0}</Tag>
                    </div>
                  }
                  titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                  titleCss={titleCss}
                  collapseContainerCss={collapseContainerCss}
                  collapseContainerClass="border-b"
                >
                  <div className="p-4">
                    <MyReferenceComment
                      comment={currentComment}
                      onDelete={onDeleteComment}
                      onUpdate={onUpdateComment}
                    />
                  </div>
                  <div className="p-4">
                    <ReferenceCommentsByStage comments={otherComments} stages={projectStages} />
                  </div>
                  <Divider className="m-0" />
                  <div className="p-4">
                    <ScreeningTags
                      tags={form.form.tags}
                      selectedTagIds={resolutionResult?.result.tags}
                      onTagsChange={onUpdateResultTags}
                    />
                  </div>
                </CollapsibleSection>
              </CollapsibleSections>
            ) : (
              <NonIdealState
                title={<Trans>No reference data</Trans>}
                icon={IconNames.HEART_BROKEN}
              />
            )
          }
          decisionControls={
            <React.Fragment>
              <Button
                fill
                className="mr-4"
                disabled={!includeEnabled}
                small
                intent={Intent.SUCCESS}
                text={<Trans>Include</Trans>}
                loading={savingResult}
                onClick={handleDecision(InclusionStatus.Included)}
                data-testid="decision-control"
              />
              <Button
                fill
                disabled={!excludeEnabled}
                small
                intent={Intent.DANGER}
                text={<Trans>Exclude</Trans>}
                loading={savingResult}
                onClick={handleDecision(InclusionStatus.Excluded)}
                data-testid="decision-control"
              />
            </React.Fragment>
          }
          decisionLabels={inclusionDecisionLabels}
          navigateToPrevReference={navigateToPrevReference}
          navigateToNextReference={navigateToNextReference}
        />
      )}
      <NavAndDecisionControls
        onlyHotkeys
        onShiftActiveReference={shiftActiveReference}
        onCloseFocusMode={navigateToScreeningConflictsList}
        onToggleActiveReferenceSelect={noop}
        onInclude={noop}
        onExclude={noop}
        onPostpone={noop}
        exclusionReasons={[]}
      />
    </Fragment>
  );
};

export default FTConflict;
