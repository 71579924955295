/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Card, Tag } from '@blueprintjs/core';
import React, { Fragment, memo, ReactNode, useCallback } from 'react';
import {
  InclusionStatus,
  ReferenceLog,
  Stage,
  StageType,
  TReferenceLogType,
  User,
} from '../../../common/types';
import { filter, find, get, includes, isEmpty, map, propEq } from 'lodash/fp';
import { formatDate } from '../../project/helpers';
import { Plural, Trans } from '@lingui/macro';
import { TScreeningTag } from './';
import { useKeywordTagStyles } from '../../../lib/utils';

function getStageTypeAbbreviation(stageType?: StageType) {
  switch (stageType) {
    case StageType.PreliminaryScreening:
      return 'Preliminary';
    case StageType.TitlesAbstractScreening:
      return 'TiAb';
    case StageType.FullTextScreening:
      return 'FT';
    case StageType.DataExtraction:
      return 'DEx';
    default:
      return null;
  }
}

interface IReferenceDetailsLogCardProps {
  log: ReferenceLog;
  screeningTags: TScreeningTag[];
  stages: Pick<Stage, 'id' | 'type'>[];
  usersById: { [id: string]: Pick<User, 'id' | 'name'> };
  batch?: string | null;
}

const ReferenceDetailsLogCard: React.FC<IReferenceDetailsLogCardProps> = memo(
  ({ log, batch, screeningTags, stages, usersById }) => {
    const [desiredKeywordCss, undesiredKeywordCss, neutralKeywordCss, conflictKeywordCss] =
      useKeywordTagStyles();
    const type: TReferenceLogType = get('data.type', log);
    const data = get('data', log) ?? {};
    const userId = get('userId', data);
    const createdAt = formatDate(get('created_at', log), 'HH:mm');

    const getUserName = useCallback(
      function (userId: string | undefined | null): ReactNode {
        return userId ? usersById[userId]?.name ?? <Trans>User removed</Trans> : null;
      },
      [usersById]
    );

    const getCardCss = useCallback(() => {
      if (type === 'status_changed') {
        const decision = data.decision ?? data.inclusionStatus;

        const cssObj =
          decision === InclusionStatus.Included
            ? desiredKeywordCss
            : decision === InclusionStatus.Excluded
            ? undesiredKeywordCss
            : decision === InclusionStatus.Conflict
            ? conflictKeywordCss
            : neutralKeywordCss;
        const borderColor = get('backgroundColor', cssObj);
        return {
          border: `2px solid ${borderColor}`,
        };
      }
      return {};
    }, [type, data, desiredKeywordCss, undesiredKeywordCss, neutralKeywordCss, conflictKeywordCss]);

    const getTagCssAndLabels = useCallback(
      (decision, decisionReasonCodes) => {
        const tagCss =
          decision === InclusionStatus.Included
            ? desiredKeywordCss
            : decision === InclusionStatus.Excluded
            ? undesiredKeywordCss
            : decision === InclusionStatus.Conflict
            ? conflictKeywordCss
            : neutralKeywordCss;

        const tagLabels = isEmpty(decisionReasonCodes)
          ? [
              InclusionStatus.Included,
              InclusionStatus.Conflict,
              InclusionStatus.Postponed,
            ].includes(decision)
            ? [decision]
            : []
          : decisionReasonCodes;

        return {
          tagCss,
          tagLabels,
        };
      },
      [desiredKeywordCss, undesiredKeywordCss, conflictKeywordCss, neutralKeywordCss]
    );

    const renderLogCardContent = useCallback(() => {
      switch (type) {
        case 'reference_uploaded': {
          return (
            <Fragment>
              <Trans>Reference uploaded</Trans>
              {batch ? (
                <Fragment>
                  {' '}
                  - <Trans>Batch</Trans> {batch ? formatDate(batch, 'dd/MM/yyyy HH:mm') : ''}
                </Fragment>
              ) : null}
            </Fragment>
          );
        }
        case 'attachment_assigned': {
          const { filename } = data;
          return (
            <Fragment>
              <Trans>Attachment assigned</Trans>: {filename}
            </Fragment>
          );
        }
        case 'attachment_removed': {
          const { filename } = data;
          return (
            <Fragment>
              <Trans>Attachment removed</Trans>: {filename}
            </Fragment>
          );
        }
        case 'status_changed': {
          const { stageId, decision, decisionCodes } = data;
          const stage = find(propEq('id', stageId), stages);
          const stageType = get('type', stage);
          const stageTypeAbbreviation = getStageTypeAbbreviation(stageType);
          const { tagCss, tagLabels } = getTagCssAndLabels(decision, decisionCodes);

          return (
            <div className="flex flex-row flex-wrap items-center">
              [{stageTypeAbbreviation}]: <Trans>Decision status</Trans>
              {': '}
              {tagLabels.map((tagLabel) => (
                <Tag key={tagLabel} className="ml-2" css={tagCss}>
                  {tagLabel}
                </Tag>
              ))}
            </div>
          );
        }
        case 'tags_changed': {
          const tagsIds = get('tags', data) ?? [];
          const tags = filter((screeningTag) => includes(screeningTag.id, tagsIds), screeningTags);
          return (
            <div className="flex flex-col">
              <Trans>Structured comments changed</Trans>
              {':\n'}
              {isEmpty(tags) ? null : (
                <div className="flex flex-row flex-wrap my-1">
                  <Trans>Current structured comments</Trans>:
                  {map(
                    (tagObj) => (
                      <Tag key={tagObj.id} className="ml-2 mb-1">
                        {tagObj.tag}
                      </Tag>
                    ),
                    tags
                  )}
                </div>
              )}
            </div>
          );
        }
        case 'comments_updated': {
          const { comment } = data;
          return (
            <Fragment>
              <Trans>Comment updated</Trans>
              <div className="whitespace-pre-line break-words">
                <span>
                  <Trans>Current comment</Trans>
                  {': '}
                </span>
                <p className="inline">{comment.comment}</p>
              </div>
            </Fragment>
          );
        }
        case 'comments_removed': {
          return <Trans>Comment removed</Trans>;
        }
        case 'reviewers_assigned': {
          const { reviewers, stageId } = data;
          const stage = find(propEq('id', stageId), stages);
          const stageType = get('type', stage);
          const stageTypeAbbreviation = getStageTypeAbbreviation(stageType);
          const reviewerNames: ReactNode[] = map(getUserName, reviewers);
          return (
            <div className="flex flex-row">
              [{stageTypeAbbreviation}]{': '}
              <Trans>
                Assigned to <Plural value={reviewerNames.length} one="reviewer" other="reviewers" />
              </Trans>
              {reviewerNames.reduce<ReactNode[]>(
                (acc, reviewer) => (acc.length === 0 ? [': ', reviewer] : [...acc, ', ', reviewer]),
                []
              )}
            </div>
          );
        }
        case 'reviewer_replaced': {
          const { oldReviewerUserId, newReviewerUserId, stageId } = data;
          const stage = find(propEq('id', stageId), stages);
          const stageType = get('type', stage);
          const stageTypeAbbreviation = getStageTypeAbbreviation(stageType);
          return (
            <div className="flex flex-row">
              [{stageTypeAbbreviation}]{': '}
              <Trans>Replaced a reviewer</Trans>
              {': '}
              <span className="mx-1 line-through">{getUserName(oldReviewerUserId)}</span>{' '}
              <span>{getUserName(newReviewerUserId)}</span>
            </div>
          );
        }
        case 'reviewer_removed': {
          const { reviewerUserId, stageId } = data;
          const stage = find(propEq('id', stageId), stages);
          const stageType = get('type', stage);
          const stageTypeAbbreviation = getStageTypeAbbreviation(stageType);
          return (
            <div className="flex flex-row">
              [{stageTypeAbbreviation}]{': '}
              <Trans>Removed a reviewer</Trans>
              {': '}
              <span className="mx-1 line-through">{getUserName(reviewerUserId)}</span>
            </div>
          );
        }
        case 'reviewer_decision_applied': {
          const { stageId, decision, decisionCodes } = data;
          const stage = find(propEq('id', stageId), stages);
          const stageType = get('type', stage);
          const stageTypeAbbreviation = getStageTypeAbbreviation(stageType);
          const { tagCss, tagLabels } = getTagCssAndLabels(decision, decisionCodes);

          return isEmpty(tagLabels) ? (
            <Trans>Decision removed</Trans>
          ) : (
            <div className="flex flex-row flex-wrap">
              [{stageTypeAbbreviation}]: <Trans>Partial decision applied</Trans>
              {': '}
              {tagLabels.map((tagLabel) => (
                <Tag key={tagLabel} className="ml-2" css={tagCss}>
                  {tagLabel}
                </Tag>
              ))}
            </div>
          );
        }
        case 'conflict_resolved':
          const { automaticResolution } = data;

          return automaticResolution ? (
            <Trans>Automatic conflict resolution</Trans>
          ) : (
            <Trans>Conflict resolution (3rd reviewer decision)</Trans>
          );
        case 'primary_extraction_completed':
          return <Trans>Primary Extraction completed</Trans>;
        case 'extraction_qa_review_completed':
          return <Trans>Extraction QA Review completed</Trans>;
        case 'removed_as_duplicate':
          return <Trans>Removed as duplicate</Trans>;
        case 'marked_with_no_report':
          return <Trans>Marked as "Report not retrieved"</Trans>;
        case 'restored':
          return <Trans>Restored</Trans>;
        case 'no_report_mark_removed':
          return <Trans>"Report not retrieved" mark removed</Trans>;
        case 'screening_status_overwrite':
          return <Trans>Decision was overwritten</Trans>;
        default:
          return null;
      }
    }, [type, data, batch, screeningTags, stages, getTagCssAndLabels, getUserName]);

    return (
      <Card className="flex flex-col p-4 my-2" css={getCardCss()}>
        {renderLogCardContent()}
        <div className="flex flex-row justify-end text-xs">
          {userId ? <span>{getUserName(userId)} - </span> : ''}
          {createdAt}
        </div>
      </Card>
    );
  }
);

export default ReferenceDetailsLogCard;
