/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  Classes,
  Colors,
  Dialog,
  H5,
  Icon,
  Intent,
  Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { get, isEmpty, isNil, map, some } from 'lodash/fp';
import { t, Trans } from '@lingui/macro';
import AppToaster from '../../../lib/toaster';
import { TReferencesExportFormat, WorkerStatus } from '../../../common/types';
import { generateRandomUUID, useI18n } from '../../../lib/utils';
import { formatDate } from '../helpers';
import { fancyScrollCss } from '../../../common/styles';
import DownloadButton from '../../common/download_button';

const EXPORT_FORMATS: TReferencesExportFormat[] = ['ris', 'csv'];

export const EXPORTS_ATTACHMENT_BUCKET = process.env.EXPORTS_ATTACHMENT_BUCKET || 'exports';

const CreateExportMutation = loader('../../../graphql/insert_export_task_mutation.gql');

const GetExportsQuery = gql`
  query GetExportsQuery($projectId: uuid!, $exportType: export_types_enum!, $paramsFilter: jsonb!) {
    export_tasks(
      where: {
        project_id: { _eq: $projectId }
        export_type: { _eq: $exportType }
        params: { _contains: $paramsFilter }
      }
      order_by: { start_timestamp: desc }
    ) {
      key
      created_at
      status
      references_count
      project {
        name
      }
    }
  }
`;

const GetAllReferencesSelectedQuery = gql`
  query GetAllReferencesSelectedQuery {
    ScreeningState @client {
      allReferencesSelected
    }
  }
`;

const DISPLAY_FORMAT = 'dd/MM/yyyy HH:mm:ss';
export const DOWNLOAD_FORMAT = 'dd_MM_yyyy_HH_mm_ss';

export enum ExportDialogMode {
  AllReferences = 'all_references',
  ReferencesWithoutAttachments = 'references_without_attachments',
  ReferenceClaim = 'reference_claim',
  ReferencesNotClaimed = 'references_not_claimed',
  SelectedReferences = 'selected_references',
}

type ExportTableRow = {
  key: string;
  created_at: string;
  references_count: number;
  status: WorkerStatus;
  project: {
    name: string;
  };
};

type CellProps = {
  row: ExportTableRow;
  isBtnLarge?: boolean;
};

const ExportDetailsCell: React.FC<CellProps> = ({ row }) => (
  <div>
    <Trans>
      Records: {row.references_count}, created at:{' '}
      {formatDate(new Date(row.created_at), DISPLAY_FORMAT)}
    </Trans>
  </div>
);

type DownloadCellProps = CellProps & { exportFormat: TReferencesExportFormat };

export const DownloadLinkCell: React.FC<DownloadCellProps> = ({
  row,
  isBtnLarge,
  exportFormat,
}) => {
  const {
    key,
    status,
    created_at,
    project: { name: projectName },
  } = row;
  switch (status) {
    case WorkerStatus.Error:
      return (
        <div className="w-full flex flex-row justify-center items-center">
          <Icon className="mr-1" color={Colors.GRAY1} icon={IconNames.ERROR} />
          <div>
            <Trans>Try again</Trans>
          </div>
        </div>
      );
    case WorkerStatus.Completed:
      const formattedDate = formatDate(new Date(created_at), DOWNLOAD_FORMAT);
      const downloadName = `${formattedDate}_${projectName}.${exportFormat}`;
      return (
        <div>
          <DownloadButton
            className="w-full"
            bucketName={EXPORTS_ATTACHMENT_BUCKET}
            large={isBtnLarge}
            downloadName={downloadName}
            downloadKey={key}
            text={<Trans>Download</Trans>}
          />
        </div>
      );
    default:
      return (
        <div>
          <Button className="w-full" large={isBtnLarge} disabled loading />
        </div>
      );
  }
};

const tableCss = css`
  max-height: 300px;
  overflow-y: auto;
  ${fancyScrollCss}
`;

const rowCss = css`
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 10px;
`;

type ExportDialogBodyProps = {
  projectId: string;
  mode: ExportDialogMode;
  recordsToExportCount: number;
  allReferencesSelected: boolean;
  selectedReferencesIds?: string[];
  referenceClaimId?: string | null;
  stageId?: string;
  referencesSearchArgs?: object;
};

const ExportDialogBody: React.FC<ExportDialogBodyProps> = ({
  projectId,
  mode,
  recordsToExportCount,
  selectedReferencesIds,
  referenceClaimId,
  stageId,
  referencesSearchArgs,
  allReferencesSelected,
}) => {
  const i18n = useI18n();
  const [selectedExportFormat, setExportFormat] = useState<TReferencesExportFormat>('ris');
  const [exportClicked, setExportClicked] = useState<boolean>(false);
  const { data, loading, refetch } = useQuery(GetExportsQuery, {
    variables: {
      projectId,
      exportType: selectedExportFormat,
      paramsFilter: {
        mode,
        referenceClaimId,
        allReferencesSelected:
          mode === ExportDialogMode.SelectedReferences ? allReferencesSelected : undefined,
      },
    },
    pollInterval: 1000,
  });

  const isExporting =
    loading ||
    exportClicked ||
    some(
      ({ status }) => status === WorkerStatus.New || status === WorkerStatus.InProgress,
      data.export_tasks
    );

  const [createExport] = useMutation(CreateExportMutation);
  const exportFile = useCallback(
    (selectedExportFormat: TReferencesExportFormat) => {
      setExportClicked(true);
      const key = `${projectId}_${generateRandomUUID()}`;
      createExport({
        variables: {
          key,
          projectId,
          exportType: selectedExportFormat,
          referencesCount: isEmpty(selectedReferencesIds)
            ? recordsToExportCount
            : selectedReferencesIds?.length ?? 0,
          params: {
            selectedReferencesIds: isEmpty(selectedReferencesIds)
              ? undefined
              : selectedReferencesIds,
            mode,
            referenceClaimId: isNil(referenceClaimId) ? undefined : referenceClaimId,
            stageId,
            referencesSearchArgs,
          },
        },
      })
        .then(() =>
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: i18n._(t`Export requested successfully.`),
            timeout: 3000,
          })
        )
        .then(() => refetch())
        .then(() => setExportClicked(false))
        .catch((err) => {
          setExportClicked(false);
          const errorMessagePart = err.message ? `: ${err.message}` : '';
          AppToaster.show({
            intent: Intent.DANGER,
            message: i18n._(t`Error exporting project${errorMessagePart}`),
            timeout: 3000,
          });
        });
    },
    [
      createExport,
      i18n,
      projectId,
      refetch,
      recordsToExportCount,
      selectedReferencesIds,
      referencesSearchArgs,
      mode,
      referenceClaimId,
      stageId,
    ]
  );

  return (
    <div className={Classes.DIALOG_BODY}>
      <div className="mb-4">
        <H5 className="font-normal mb-4">
          <Trans>Select export format</Trans>
        </H5>
        <div className="mt-2 flex flex-row gap-4">
          {EXPORT_FORMATS.map((exportFormat) => (
            <Card
              key={exportFormat}
              className="w-1/2"
              onClick={() => setExportFormat(exportFormat)}
            >
              <div className="flex flex-row justify-between mb-4">
                <Trans>{exportFormat.toUpperCase()} file</Trans>
                {selectedExportFormat === exportFormat ? (
                  <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.PRIMARY} />
                ) : null}
              </div>
              {selectedExportFormat === exportFormat ? (
                <Button
                  className="w-full"
                  icon={IconNames.EXPORT}
                  loading={isExporting}
                  onClick={() => exportFile(exportFormat)}
                >
                  <Trans>Generate package</Trans>
                </Button>
              ) : null}
            </Card>
          ))}
        </div>
      </div>
      <div>
        {loading ? (
          <div className="w-full flex flex-row justify-center">
            <Spinner />
          </div>
        ) : isEmpty(data.export_tasks) ? (
          <Card className="w-full p-4 mb-4 flex justify-center">
            <Trans>
              No {selectedExportFormat.toUpperCase()} export packages have been created yet
            </Trans>
          </Card>
        ) : (
          <Card className="w-full p-4 mb-4">
            <div css={tableCss}>
              {map(
                (row) => (
                  <div className="items-center" css={rowCss} key={row.key}>
                    <ExportDetailsCell row={row} />
                    <DownloadLinkCell row={row} exportFormat={selectedExportFormat} />
                  </div>
                ),
                data.export_tasks as ExportTableRow[]
              )}
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

type ExportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  allReferencesCount: number;
  referenceClaimId?: string | null;
  mode?: ExportDialogMode;
  selectedReferencesIds?: string[];
  stageId?: string;
  referencesSearchArgs?: object;
};

const ExportDialog: React.FC<ExportDialogProps> = ({
  projectId,
  isOpen,
  onClose,
  allReferencesCount,
  referenceClaimId,
  mode = ExportDialogMode.AllReferences,
  selectedReferencesIds,
  stageId,
  referencesSearchArgs,
}) => {
  const { data: allReferencesSelectedData } = useQuery(GetAllReferencesSelectedQuery, {
    skip: !isOpen,
  });

  const allReferencesSelected: boolean =
    get('ScreeningState.allReferencesSelected', allReferencesSelectedData) ?? false;
  const recordsToExportCount: number =
    mode === ExportDialogMode.SelectedReferences ||
    allReferencesSelected ||
    isEmpty(selectedReferencesIds)
      ? allReferencesCount
      : selectedReferencesIds?.length ?? 0;

  return (
    <Dialog
      className="w-1/3"
      css={{ backgroundColor: 'white' }}
      icon={IconNames.EXPORT}
      isCloseButtonShown
      isOpen={isOpen}
      onClose={onClose}
      title={
        mode === ExportDialogMode.ReferenceClaim ? (
          <Trans>Export references with missing PDFs</Trans>
        ) : (
          <Trans>Export references</Trans>
        )
      }
    >
      {isOpen && (
        <ExportDialogBody
          mode={mode}
          projectId={projectId}
          allReferencesSelected={allReferencesSelected}
          selectedReferencesIds={allReferencesSelected ? [] : selectedReferencesIds}
          recordsToExportCount={recordsToExportCount}
          referenceClaimId={referenceClaimId}
          stageId={stageId}
          referencesSearchArgs={referencesSearchArgs}
        />
      )}
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex flex-row justify-end">
          <Button className="px-4" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ExportDialog;
