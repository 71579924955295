/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { border1Gray5 } from '../../common/styles';
import InstructionItemMovementControls from './instruction_item_movement_controls';

interface IInstructionSectionItemProps {
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const InstructionSectionItem: React.FC<IInstructionSectionItemProps> = ({
  children,
  onDelete,
  onMoveDown,
  onMoveUp,
}) => {
  return (
    <div className="h-12 flex flex-row items-center bg-white mb-2" css={border1Gray5}>
      <InstructionItemMovementControls
        className="ml-2"
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
      />
      <div className="flex-1 pl-2 pr-4 py-2">{children}</div>
      <Divider className="h-full m-0" />
      <Button
        disabled={onDelete == null}
        minimal
        large
        icon={IconNames.TRASH}
        onClick={onDelete}
        className="h-full"
      />
    </div>
  );
};

export default InstructionSectionItem;
