/** @jsx jsx */
import {
  Button,
  Classes,
  Colors,
  Dialog,
  Icon,
  Intent,
  Position,
  InputGroup,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Trans, t, Plural, plural } from '@lingui/macro';
import i18n from '../../i18n';
import React, { Fragment, useCallback, useEffect } from 'react';
import { TableCol, createTable } from '../common/gba_table';
import { ReferencesImportTask, WorkerStatus, ReferenceRemovalReason } from '../../common/types';
import { alignStartCss, alignEndCss, fancyScrollCss } from '../../common/styles';
import { IconNames } from '@blueprintjs/icons';
import {
  get,
  find,
  isEmpty,
  compose,
  isNull,
  reduce,
  findIndex,
  filter,
  last,
  isNil,
} from 'lodash/fp';
import ActionsMenu from '../common/actions_menu';
import { gql } from 'graphql.macro';
import { captureException } from '@sentry/browser';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { toastify, useSetState } from '../../lib/utils';
import useActionLogger from '../hooks/use_action_logger';
import { formatDate } from '../project/helpers';
import ReferencesBatchDeleteDialog from './references_batch_delete_dialog';
import { useKeycloak } from '../../keycloak';
import { SHORT_TITLES } from '../users/user_details/header';
import { Buckets, TUploadedFile, uploadMultipleAttachments } from '../../lib/attachmentUpload';
import { UploadState } from '../files_upload_dialog';
import AppToaster from '../../lib/toaster';

const allowedExtensions: string[] = ['ris', 'txt'];

const NewReferenceImportTasksMutation = gql`
  mutation InsertReferenceImportTasks($filesData: [references_import_task_insert_input!]!) {
    insert_references_import_task(objects: $filesData) {
      affected_rows
    }
  }
`;

const ProjectTeamMembersAndStatusQuery = gql`
  query ProjectTeamMembersAndStatusQuery($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      completed
      team_members {
        id
        user {
          id
          name
          title
        }
      }
    }
  }
`;

const ReferencesImportTasksSubscription = gql`
  subscription ReferenceBatchesSubscription($projectId: uuid!) {
    reference_batches(
      where: { project_id: { _eq: $projectId } }
      order_by: { created_at: asc_nulls_last }
    ) {
      created_by
      created_at
      error_message
      failed_records
      filename
      key
      refs_count
      status
      label
      deleted_at
      deleted_by
      is_distributed
    }
  }
`;

const UpdateReferencesImportTaskLabelMutation = gql`
  mutation UpdateReferencesImportTaskLabel($key: String!, $label: String!) {
    update_references_import_task_by_pk(pk_columns: { key: $key }, _set: { label: $label }) {
      key
      label
    }
  }
`;

const UpdateReferencesImportTaskMutation = gql`
  mutation UpdateReferencesImportTaskMutation(
    $key: String!
    $userId: String!
    $removalReason: String!
  ) {
    update_references_import_task_by_pk(
      pk_columns: { key: $key }
      _set: { deleted_by: $userId, deleted_at: "now()" }
    ) {
      key
    }
    update_reference(
      where: { import_task_key: { _eq: $key } }
      _set: { deleted_by: $userId, deleted_at: "now()", removal_reason: $removalReason }
    ) {
      returning {
        id
      }
    }
    update_study(
      where: { references: { import_task_key: { _eq: $key } } }
      _set: { deleted_at: "now()" }
    ) {
      returning {
        id
      }
    }
    update_task(
      where: { study: { references: { import_task_key: { _eq: $key } } } }
      _set: { deleted_at: "now()" }
    ) {
      returning {
        id
      }
    }
    update_study_pool_study(
      where: { study: { references: { import_task_key: { _eq: $key } } } }
      _set: { deleted_at: "now()" }
    ) {
      returning {
        study_pool_id
      }
    }
  }
`;

const importDialogCss = css`
  width: 100vw;
  height: 100vh;
  max-width: 1156px;
  max-height: 768px;
`;

const okButtonCss = css`
  width: 100%;
  max-width: 160px;
`;

const refsImportTasksTableWrapperCss = css`
  flex: 1 1 500px;
`;

const ReferencesImportTasksTable = createTable<ReferencesImportTask>();

const TABLE_COLS: TableCol<ReferencesImportTask>[] = [
  {
    id: 'batch',
    label: i18n._(t`Batch`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    width: 250,
  },
  {
    id: 'filename',
    label: i18n._(t`File`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    width: 200,
  },
  {
    id: 'upload_date',
    label: i18n._(t`Upload date`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    width: 100,
  },
  {
    id: 'uploaded_by',
    label: i18n._(t`Uploaded by`),
    cellCss: alignStartCss,
    headerCellCss: alignStartCss,
  },
  {
    id: 'number_of_references',
    label: i18n._(t`Number of references`),
    cellCss: alignEndCss,
    headerCellCss: alignEndCss,
    cellProps: {
      wrapText: false,
    },
  },
  {
    id: 'status',
    label: i18n._(t`Status`),
    cellCss: alignStartCss,
    headerCellCss: alignStartCss,
  },
  {
    id: 'menu',
    width: 50,
  },
];

interface IImportDialogProps {
  isOpen: boolean;
  onClose: (didUpdate: boolean) => void;
  projectId: string;
  onImportTaskRemoved?: () => void;
}

interface IImportDialogState {
  filesToUpload: File[];
  uploadState: UploadState | null;
  uploadProgress: number;
  didUpdateReferences: boolean;
  deleteDialogBatchKey: string;
  editingTask: Pick<ReferencesImportTask, 'key' | 'label'>;
  dragging: boolean;
}

const IMPORT_DIALOG_INITIAL_STATE: IImportDialogState = {
  filesToUpload: [],
  uploadState: null,
  uploadProgress: 0,
  didUpdateReferences: false,
  deleteDialogBatchKey: '',
  editingTask: {
    key: '',
    label: '',
  },
  dragging: false,
};

const ImportDialog: React.FC<IImportDialogProps> = ({
  isOpen,
  onClose,
  projectId,
  onImportTaskRemoved,
}) => {
  const risFilesInput = React.createRef<HTMLInputElement>();
  const { user, getToken } = useKeycloak();
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState<IImportDialogState>(IMPORT_DIALOG_INITIAL_STATE);

  const { data: projectData } = useQuery(ProjectTeamMembersAndStatusQuery, {
    variables: {
      projectId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading } = useSubscription(ReferencesImportTasksSubscription, {
    variables: {
      projectId,
    },
  });

  const {
    filesToUpload,
    didUpdateReferences,
    deleteDialogBatchKey,
    editingTask,
    dragging,
    uploadState,
    uploadProgress,
  } = state;
  const teamMembers = get('project.team_members', projectData) ?? [];
  const referencesImportTasks: ReferencesImportTask[] = get('reference_batches', data) ?? [];
  const referencesImportTasksCount = referencesImportTasks.length;
  const isProjectCompleted = get('project.completed', projectData) ?? false;

  const [updateReferencesImportTask] = useMutation(UpdateReferencesImportTaskMutation);
  const [updateReferencesImportTaskLabel] = useMutation(UpdateReferencesImportTaskLabelMutation);
  const [createReferencesImportTasks] = useMutation(NewReferenceImportTasksMutation);

  const addFilesToUpload = (files: FileList | null) => {
    const updatedFilesList: File[] = compose(
      reduce<File, File[]>(
        (acc, f) => {
          findIndex({ name: f.name }, acc) === -1 && acc.push(f);
          return acc;
        },
        [...filesToUpload]
      ),
      filter<File>((f) => {
        const nameSplit = f.name.split('.');
        return nameSplit.length > 1 && allowedExtensions.includes(last(nameSplit)!);
      })
    )(files);

    setState({ filesToUpload: updatedFilesList, dragging: false });
  };

  const updateUploadProgress = useCallback(
    (value: number) => setState({ uploadProgress: value }),
    [setState]
  );

  const filesDataMapper = useCallback(
    (file: TUploadedFile) => ({
      project_id: projectId,
      filename: file.originalname,
      key: file.key,
      label: formatDate(new Date(), 'dd/MM/yyyy HH:mm'),
    }),
    [projectId]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      setState({ uploadState: UploadState.InProgress });
      try {
        const token = await getToken();
        AppToaster.show({
          icon: IconNames.INFO_SIGN,
          intent: Intent.PRIMARY,
          message: <Trans>File upload started.</Trans>,
        });
        await uploadMultipleAttachments({
          token,
          projectId,
          bucket: Buckets.ReferencesImport,
          updateUploadProgress,
          files,
          filesDataMapper,
          insertFilesMutation: createReferencesImportTasks,
        }).then(() => {
          AppToaster.show({
            icon: IconNames.TICK,
            intent: Intent.SUCCESS,
            message: i18n._(
              plural({
                value: files.length,
                one: '1 file uploaded successfully.',
                other: '# files uploaded successfully.',
              })
            ),
          });
        });
        setState({ uploadState: null, filesToUpload: [] });
      } catch (err) {
        captureException(err);
        AppToaster.show({
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
          message: `${i18n._(
            plural({
              value: files.length,
              one: 'Failed to upload 1 file',
              other: 'Failed to upload # files',
            })
          )}: ${(err as Error).toString()}`,
        });
        setState({ uploadState: UploadState.Error, filesToUpload: [] });
      }
    },
    [
      setState,
      uploadMultipleAttachments,
      getToken,
      updateUploadProgress,
      createReferencesImportTasks,
      projectId,
    ]
  );

  const handleClose = useCallback(() => {
    setState({ didUpdateReferences: false });
    onClose(didUpdateReferences);
  }, [onClose, setState, didUpdateReferences]);

  const handleRetry = useCallback(
    (key: string) => {
      updateReferencesImportTask({
        variables: {
          key,
          taskData: { status: WorkerStatus.Retry },
          removalReason: ReferenceRemovalReason.ImportTaskRemoved,
        },
      }).then(() => setState({ didUpdateReferences: true }));
    },
    [updateReferencesImportTask, setState]
  );

  const removeImportTaskAndReferences = useCallback(
    (key: string) => {
      updateReferencesImportTask({
        variables: {
          key,
          userId: user.id,
          removalReason: ReferenceRemovalReason.ImportTaskRemoved,
        },
      }).then(() => {
        insertActionLog('removed references import task with related references', { key });
        setState({
          deleteDialogBatchKey: '',
          didUpdateReferences: true,
        });
        onImportTaskRemoved?.();
      });
    },
    [setState, updateReferencesImportTask, insertActionLog, user.id, onImportTaskRemoved]
  );

  const enterEditingTask = useCallback(
    (referencesImportTask: ReferencesImportTask) => {
      setState({
        editingTask: {
          key: referencesImportTask.key,
          label: referencesImportTask.label ?? '',
        },
      });
    },
    [setState]
  );

  const closeEditingTask = useCallback(() => {
    setState({ editingTask: { key: '', label: '' } });
  }, [setState]);

  const applyUpdateEditingTaskLabel = useCallback(() => {
    const promise = updateReferencesImportTaskLabel({
      variables: { key: editingTask.key, label: editingTask.label },
    });

    return toastify(
      promise,
      i18n._(t`References batch renamed successfully`),
      i18n._(t`Error during renaming references batch`),
      {
        errorToasterProps: {
          icon: IconNames.ERROR,
        },
        successToasterProps: {
          icon: IconNames.SAVED,
        },
      }
    )
      .then(() => {
        insertActionLog('updated references import task', {
          key: editingTask.key,
          label: editingTask.label,
        });
      })
      .finally(closeEditingTask);
  }, [updateReferencesImportTaskLabel, insertActionLog, closeEditingTask, editingTask]);

  const renderTableCell = useCallback(
    (colId: string, referencesImportTask: ReferencesImportTask) => {
      switch (colId) {
        case 'batch':
          return editingTask.key === referencesImportTask.key ? (
            <div className="py-2 flex flex-row justify-between items-center">
              <InputGroup
                fill
                name="batch-name"
                onChange={(e) =>
                  setState({ editingTask: { ...editingTask, label: e.target.value } })
                }
                value={editingTask.label}
              />
              <Button
                name="save-batch-name"
                minimal
                intent={Intent.SUCCESS}
                icon={IconNames.TICK}
                onClick={applyUpdateEditingTaskLabel}
              />
              <Button
                minimal
                intent={Intent.DANGER}
                icon={IconNames.CROSS}
                onClick={closeEditingTask}
              />
            </div>
          ) : (
            <Fragment>
              <Button
                name="edit-batch-name"
                minimal
                icon={IconNames.EDIT}
                className="mr-3"
                onClick={() => enterEditingTask(referencesImportTask)}
              />
              {get('label', referencesImportTask) ?? '-'}
            </Fragment>
          );
        case 'filename':
          return get('filename', referencesImportTask) ?? '-';
        case 'upload_date':
          const date = get('created_at', referencesImportTask);
          return date ? (
            <div className="flex flex-col">
              <span>{formatDate(date, 'dd/MM/yyyy')}</span>
              <span className="text-xs text-gray-600">{formatDate(date, 'HH:mm:ss')}</span>
            </div>
          ) : (
            '-'
          );
        case 'uploaded_by':
          const userId = get('created_by', referencesImportTask);
          const user = compose(get('user'), find(['user.id', userId]))(teamMembers);
          return user && user.title && user.title in SHORT_TITLES
            ? `${SHORT_TITLES[user.title]} ${user.name}`
            : user
            ? user.name
            : '-';
        case 'number_of_references':
          const refsCount = get('refs_count', referencesImportTask) ?? 0;
          const failedRecords = get('failed_records', referencesImportTask) ?? [];
          return !refsCount ? (
            '-'
          ) : isEmpty(failedRecords) ? (
            refsCount
          ) : (
            <Fragment>
              {refsCount - failedRecords.length}/{refsCount}
              <Tooltip
                position={Position.BOTTOM}
                content={
                  <div className="m-3 text-red-500">
                    <Trans>
                      <div className="inline font-bold">
                        <Plural value={failedRecords.length} one="# record" other="# records" />
                      </div>{' '}
                      cannot be uploaded to the system
                    </Trans>
                  </div>
                }
              >
                <Icon color={Colors.RED1} icon={IconNames.ERROR} />
              </Tooltip>
            </Fragment>
          );
        case 'status':
          const status = get('status', referencesImportTask) ?? '-';
          const key = get('key', referencesImportTask);
          const deletedAt = get('deleted_at', referencesImportTask);
          const isDeleted = !isNull(deletedAt);
          const isError =
            status === WorkerStatus.Error ||
            (status === WorkerStatus.Completed &&
              !isEmpty(get('failed_records', referencesImportTask)));
          const isInProgress = status === WorkerStatus.InProgress;
          const isCompleted = status === WorkerStatus.Completed;
          const isRetry = status === WorkerStatus.Retry;
          return isDeleted ? (
            <div className="flex flex-col">
              <span>
                <Trans>DELETED</Trans>
              </span>
              <span className="text-xs text-gray-600">
                {formatDate(deletedAt, 'dd/MM/yyyy - HH:mm:ss')}
              </span>
            </div>
          ) : isError ? (
            <Button className="px-6" loading={isRetry} onClick={() => handleRetry(key)}>
              <Trans>Retry</Trans>
            </Button>
          ) : isInProgress ? (
            <Spinner size={30} />
          ) : isCompleted ? (
            <Trans>UPLOADED</Trans>
          ) : (
            status
          );
        case 'menu':
          const taskStatus = get('status', referencesImportTask);
          const isBatchDeleted = !isNull(get('deleted_at', referencesImportTask));
          const isBatchDistributed = get('is_distributed', referencesImportTask);
          const isDisabled =
            isBatchDistributed ||
            isProjectCompleted ||
            isBatchDeleted ||
            taskStatus === WorkerStatus.InProgress;
          return (
            <ActionsMenu
              menuPosition={Position.BOTTOM}
              actions={[
                {
                  disabled: isDisabled,
                  text: i18n._(t`Remove batch of references`),
                  intent: Intent.DANGER as Intent,
                  onClick: () => setState({ deleteDialogBatchKey: referencesImportTask.key }),
                  tooltipMessage: isDisabled
                    ? i18n._(t`You can't delete distributed references`)
                    : undefined,
                },
              ]}
            />
          );
        default:
          return null;
      }
    },
    [
      handleRetry,
      setState,
      applyUpdateEditingTaskLabel,
      closeEditingTask,
      enterEditingTask,
      editingTask,
      teamMembers,
      isProjectCompleted,
    ]
  );

  useEffect(() => {
    if (isNil(uploadState) && !isEmpty(filesToUpload)) {
      uploadFiles(filesToUpload);
    }
  }, [uploadFiles, uploadState, filesToUpload]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={<Trans>References import</Trans>}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      css={importDialogCss}
    >
      <div
        className={`${Classes.DIALOG_BODY} flex flex-col overflow-auto`}
        css={fancyScrollCss}
        onDragOver={(e) => {
          e.preventDefault();
          setState({ dragging: true });
        }}
        onDragLeave={(e) => {
          // ignore bubbled dragLeave events
          if ((e.currentTarget as Element)?.contains(e.relatedTarget as Node)) return;
          e.preventDefault();
          setState({ dragging: false });
        }}
        onDrop={(e) => {
          e.preventDefault();
          addFilesToUpload(e.dataTransfer.files);
        }}
      >
        {uploadState === UploadState.InProgress ? (
          <div className="w-full h-full flex flex-row justify-center items-center">
            <Spinner />
            <span className="ml-4">
              <Trans>{`Uploading ${filesToUpload.length} files... (${uploadProgress}%)`}</Trans>
            </span>
          </div>
        ) : dragging ? (
          <div className="w-full h-full flex flex-row justify-center items-center">
            <div className="flex justify-center items-center">
              <Icon className="text-gray-600" icon={IconNames.CLOUD_UPLOAD} size={32} />
            </div>
            <div className="ml-4 flex flex-col justify-center">
              <span>
                <Trans>Select a file to upload or drag it here</Trans>
              </span>
              <span className="text-xs text-gray-600">
                <Trans>RIS file type (or TXT containing RIS data) only</Trans>
              </span>
            </div>
          </div>
        ) : (
          <Fragment>
            {loading ? (
              <Spinner />
            ) : (
              <div css={refsImportTasksTableWrapperCss} className="overflow-hidden">
                <ReferencesImportTasksTable
                  className="h-full import-tasks"
                  cols={TABLE_COLS}
                  cellContentRenderer={renderTableCell}
                  numRows={referencesImportTasksCount}
                  rows={referencesImportTasks}
                />
              </div>
            )}
            <div className="flex flex-row justify-center mt-8 flex-none">
              <div className="w-1/2 flex flex-row py-8 border-2 border-dashed border-gray-500 rounded-lg bg-gray-300">
                <div className="w-1/5 flex justify-center items-center">
                  <Icon className="text-gray-600" icon={IconNames.CLOUD_UPLOAD} size={32} />
                </div>
                <div className="w-full flex flex-col justify-center">
                  <span>
                    <Trans>Select a file to upload or drag it here</Trans>
                  </span>
                  <span className="text-xs text-gray-600">
                    <Trans>RIS file type (or TXT containing RIS data) only</Trans>
                  </span>
                </div>
                <div className="w-1/3 flex flex-row justify-center items-center">
                  <Button className="px-4" onClick={() => risFilesInput.current!.click()}>
                    <Trans>Select file</Trans>
                  </Button>
                </div>
              </div>
            </div>
            <input
              className="hidden"
              type="file"
              multiple
              accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
              ref={risFilesInput}
              onChange={(e) => addFilesToUpload(e.target.files)}
            />
            {deleteDialogBatchKey && (
              <ReferencesBatchDeleteDialog
                importTaskKey={deleteDialogBatchKey}
                isOpen={!isEmpty(deleteDialogBatchKey)}
                onCancel={() => setState({ deleteDialogBatchKey: '' })}
                onConfirm={() => removeImportTaskAndReferences(deleteDialogBatchKey)}
              />
            )}
          </Fragment>
        )}
      </div>
      {uploadState === UploadState.InProgress || dragging ? null : (
        <div className={`${Classes.DIALOG_FOOTER} flex flex-row justify-end`}>
          <Button css={okButtonCss} large text={<Trans>OK</Trans>} onClick={handleClose} />
        </div>
      )}
    </Dialog>
  );
};

export default ImportDialog;
