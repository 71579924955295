/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  compose,
  filter,
  includes,
  isEmpty,
  keyBy,
  keys,
  map,
  pickBy,
  reduce,
  without,
  xor,
} from 'lodash/fp';
import { Button, Colors, Divider, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { lightGray5bg } from '../../common/styles';
import { ScreeningTag } from '../../common/types';
import { ScreeningTagFiltersTag } from './screening_filters';
import { isActive } from '../references/admin/references_filters';

export const containerCss = css`
  border: 1px solid ${Colors.LIGHT_GRAY1};

  .header {
    border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
    ${lightGray5bg};
  }
`;

type TActiveTags = {
  [tagId: string]: {
    active: boolean;
  };
};

function mapTagIdsToActiveTags(selectedTagIds?: string[]): TActiveTags {
  return reduce<string, TActiveTags>(
    (acc, tagId) => {
      acc[tagId] = {
        active: true,
      };
      return acc;
    },
    {},
    selectedTagIds
  );
}

interface IScreeningTagsProps {
  tags?: ScreeningTag[];
  readOnly?: boolean;
  onTagsChange?: (tags: string[]) => void;
  selectedTagIds?: string[];
}

const ScreeningTags: React.FC<IScreeningTagsProps> = ({
  tags,
  readOnly,
  selectedTagIds,
  onTagsChange,
}) => {
  const [editedTags, setEditedTags] = useState<null | TActiveTags>(null);

  const tagsById = useMemo(() => {
    return keyBy('id', tags);
  }, [tags]);

  // not all of selected tags ids may be present in tags collection because it contains only current
  // stage tags while selectedTagIds maybe also contain previous stage(s) tag ids
  const activeTags = useMemo(() => {
    return selectedTagIds?.reduce((acc, id) => {
      if (id in tagsById) {
        acc.push(tagsById[id]);
      }
      return acc;
    }, [] as ScreeningTag[]);
  }, [tagsById, selectedTagIds]);

  const toggleTag = useCallback(
    (id: string, active: boolean) => {
      setEditedTags((state) => ({
        ...state,
        [id]: {
          active,
        },
      }));
    },
    [setEditedTags]
  );

  const isTagActive = useCallback(
    (filterObject: TActiveTags, key: string): boolean => isActive<TActiveTags>(filterObject, key),
    [isActive]
  );

  const handleSelectedTagsUpdate = () => {
    const updatedTags = compose(keys, pickBy<TActiveTags>('active'))(editedTags);
    if (!isEmpty(xor(updatedTags, selectedTagIds))) {
      onTagsChange?.(updatedTags);
    }
    setEditedTags(null);
  };

  const handleTagRemove = (tagId: string) => {
    const updatedTags = without<string>([tagId], selectedTagIds);
    onTagsChange?.(updatedTags);
  };

  useEffect(() => {
    if (onTagsChange != null) {
      setEditedTags(isEmpty(selectedTagIds) ? mapTagIdsToActiveTags(selectedTagIds) : null);
    }
  }, [setEditedTags, selectedTagIds, onTagsChange]);

  return (
    <div className="w-full flex flex-col" css={containerCss} data-testid="screening-tags-box">
      <div className="flex items-center h-8 header">
        <div className="flex-1 truncate text-sm px-4">
          <Trans>Structured comments</Trans>
        </div>
        <Divider className="h-full m-0" />
        {!readOnly && (
          <Button
            disabled={editedTags != null}
            className="h-full"
            minimal
            icon={
              <Icon icon={IconNames.ADD} color={editedTags != null ? Colors.BLUE3 : undefined} />
            }
            onClick={() => setEditedTags(mapTagIdsToActiveTags(selectedTagIds))}
          />
        )}
      </div>
      {!readOnly && editedTags != null ? (
        <div className="flex flex-col bg-white">
          <div
            className="flex flex-row flex-wrap p-2"
            tabIndex={0}
            onBlur={handleSelectedTagsUpdate}
          >
            {map<ScreeningTag, ReactNode>(
              (tagItem) => (
                <ScreeningTagFiltersTag
                  key={tagItem.id}
                  onToggle={toggleTag}
                  attribute={tagItem.id}
                  label={tagItem.tag}
                  active={isTagActive(editedTags, tagItem.id)}
                >
                  {tagItem.tag}
                </ScreeningTagFiltersTag>
              ),
              tags
            )}
          </div>
          <div className="w-full flex flex-row">
            <Button
              className="w-full"
              text={
                <span className="text-red-700">
                  <Trans>Cancel</Trans>
                </span>
              }
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => setEditedTags(null)}
            />
            <Button
              className="w-full"
              text={
                <span className="text-green-700">
                  <Trans>OK</Trans>
                </span>
              }
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleSelectedTagsUpdate}
            />
          </div>
        </div>
      ) : isEmpty(activeTags) ? null : (
        <div className="flex flex-row flex-wrap p-2 bg-white">
          {compose(
            map<ScreeningTag, ReactNode>((screeningTag) => (
              <Tag
                key={screeningTag.id}
                active
                className="mb-1 mr-1 cursor-pointer"
                onRemove={readOnly ? undefined : () => handleTagRemove(screeningTag.id)}
              >
                {screeningTag.tag}
              </Tag>
            )),
            filter<ScreeningTag>((tag) => includes(tag.id, selectedTagIds))
          )(activeTags)}
        </div>
      )}
    </div>
  );
};

export default ScreeningTags;
