import { Intent, IToastProps } from '@blueprintjs/core';
import {
  curry,
  memoize,
  isFunction,
  map,
  isEmpty,
  mergeWith,
  isPlainObject,
  isArray,
  reduce,
  isString,
  isObject,
} from 'lodash/fp';
import React, {
  DependencyList,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { ApolloError } from 'apollo-client';
import { RecursivePartial } from '../common/types';
import { i18nHookContext } from '../lingui/i18nHookContext';
import AppToaster from './toaster';
import { useTheme } from '../components/settings/theme_context';
import { css } from '@emotion/core';
import { GQLType } from '../apollo/local_states';
import { LocationDescriptor } from 'history';

const EMPTY_LIST = [];

export const IGNORABLE_ERRORS = [
  // Resize observer error
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#50387233
  // https://bugs.chromium.org/p/chromium/issues/detail?id=706972
  // https://github.com/WICG/ResizeObserver/issues/38
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  // BP.Table scroll event after unmount processing
  // https://github.com/palantir/blueprint/issues/4472
  "Cannot read property 'offsetWidth' of null",
  "Cannot read properties of null (reading 'offsetWidth')",
  `can't access property "offsetWidth", e is null`,
  `can't access property "scrollLeft", a.quadrantRefs[q.MAIN].scrollContainer is null`,
  `can't access property "scrollTop", a.quadrantRefs[q.MAIN].scrollContainer is null`,
  // Happens in Firefox (NS_BINDING_ABORTED) when logging out due to redirect to login page while
  // fetching favicon
  'NetworkError when attempting to fetch resource.',
  // Errors
  // Invariant error 17 from Apollo - about fetching more from not existing now collection
  'Invariant Violation: 17 (see https://github.com/apollographql/invariant-packages)',
];

export const stopPropagationHandler = (e: React.SyntheticEvent): void => e.stopPropagation();

export interface ToastifyOptions {
  errorToasterProps?: Partial<IToastProps>;
  successToasterProps?: Partial<IToastProps>;
}

export const toastify = <T>(
  p: Promise<T>,
  successText,
  errorText,
  options?: ToastifyOptions
): Promise<void> => {
  const { errorToasterProps, successToasterProps } = options ?? {};
  return p.then(
    () => {
      AppToaster.show({
        message: successText,
        intent: Intent.SUCCESS,
        timeout: 3000,
        ...(successToasterProps ?? {}),
      });
    },
    () => {
      AppToaster.show({
        message: errorText,
        intent: Intent.DANGER,
        timeout: 3000,
        ...(errorToasterProps ?? {}),
      });
    }
  );
};

type TTask<T> = () => Promise<T>;

export const promiseSequence: <T>(tasks: TTask<T>[]) => Promise<T[]> = <T>(tasks) => {
  return reduce<TTask<T>, Promise<T[]>>(
    (acc, task) =>
      acc.then(async (accResult) => {
        const taskResult = await task();
        return [...accResult, taskResult];
      }),
    Promise.resolve([] as T[]),
    tasks
  );
};

export const getEnv = () => {
  const re = /([\w\.]+)\.(laser\.ai|evidenceprime\.com)$/;
  const envMatch = window.location.host.match(re);

  return envMatch ? envMatch[1] : 'local';
};

// almost copy/paste from https://github.com/matthewmueller/uid
export function uid(len: number = 5): string {
  return Math.random().toString(35).substr(2, len);
}

// FIXME: do we need 2 similar id generation helpers?
export function generateRandomUUID(): string {
  // from: https://gist.github.com/jed/982883
  function b(a?: any) {
    return a
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, b);
  }

  return b();
}

export function usePrevious<T>(value: T, depsList: DependencyList = EMPTY_LIST) {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value, ...depsList]);
  return ref.current;
}

// covers multi-argument callback into a curried callback. Also memoize optimizes performance in
// case of 2-arity callbacks passing down the component tree
export function useCurrCallback(cb: (...args: any[]) => any, deps: any[]) {
  const cbCurried = curry(cb);
  return useCallback(
    memoize((...args: any) => {
      if (args.length === cb.length) return cb.apply(null, args);

      return cbCurried.apply(null, args);
    }),
    deps
  );
}

export function useSetState<U>(initialState: U) {
  const reducer = (state: U, toMerge: Partial<U> | ((state: U) => U)): U =>
    isFunction(toMerge) ? toMerge(state) : { ...state, ...toMerge };
  return useReducer<Reducer<U, Partial<U> | ((state: U) => U)>>(reducer, initialState);
}

export const useI18n = () => React.useContext(i18nHookContext).i18n;

export enum ProjectsSort {
  DateNewest = 'DateNewest',
  DateOldest = 'DateOldest',
}

export function updateArrayItem<T>(itemIdx: number, updater: (item: T) => T, xs: T[]): T[] {
  if (itemIdx === -1 || itemIdx > xs.length - 1) return xs;

  return [...xs.slice(0, itemIdx), updater(xs[itemIdx]), ...xs.slice(itemIdx + 1)];
}

export enum ProjectsOwnerFilter {
  All = 'All',
  Member = 'Member',
}

export enum ProjectsLibraryFilter {
  All = 'All',
  WithoutUnfinishedClaims = 'WithoutUnfinishedClaims',
  WithClaims = 'WithClaims',
  WithRefsToClaim = 'WithRefsToClaim',
}

export const handleUserInputError = (err: ApolloError) => {
  const graphqlError = err.graphQLErrors[0];
  if (graphqlError?.extensions?.code === 'BAD_USER_INPUT') {
    // UserInputError in Apollo Server
    AppToaster.show({
      message: (graphqlError.extensions as any)?.localizedMessage ?? err.message,
      intent: Intent.WARNING,
    });
    return;
  }

  if (graphqlError?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
    AppToaster.show({
      message: (graphqlError.extensions as any)?.response?.body?.errorMessage,
      intent: Intent.WARNING,
    });
    return;
  }

  throw err;
};

/**
 * Returns a function that computes a conjunction of multiple conditions.
 * This is useful if you want to check multiple conditions on a value in filter operation:
 * @example
 * import { filter, propEq } from 'lodash/fp';
 * filter(conjunction(propEq('a', 1), propEq('b', 2)), [{ a: 2, b: 2 }, { a: 1, b: 1 });
 * @param functions the conjunction condition functions.
 * @return function computing conjunction of the conditions.
 */
export function conjunction<T>(...functions: ((value: T) => boolean)[]) {
  return (value: T) => {
    // Normal iteration for early returning false
    for (const func of functions) {
      if (!func(value)) {
        return false;
      }
    }
    return true;
  };
}

export function useStatusColor(): (status?: string) => string {
  const { statusColors } = useTheme();

  return (status?: string): string => {
    switch (status) {
      case 'included':
        return statusColors.acceptedPrimary;
      case 'excluded':
        return statusColors.rejectedPrimary;
      case 'conflict':
      case 'conflicts':
        return statusColors.conflictsPrimary;
      default:
        return statusColors.neutralPrimary;
    }
  };
}

export function useKeywordTagStyles(outlined?: boolean) {
  const { statusColors } = useTheme();

  return useMemo(
    () =>
      ['accepted', 'rejected', 'neutral', 'conflicts'].map((type) => {
        const primaryColor = statusColors[`${type}Primary`];
        const secondaryColor = statusColors[`${type}Secondary`];

        return outlined
          ? css`
              border: 1px solid ${primaryColor};
              color: ${primaryColor};
              background-color: transparent;

              &.active,
              &:hover {
                background-color: ${primaryColor}30;
              }
            `
          : {
              backgroundColor: primaryColor,
              color: secondaryColor,
              border: '1px solid transparent',
            };
      }),
    [statusColors, outlined]
  );
}

export function getOrdinal(n: number): string {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const val = n % 100;
  return n + (suffixes[(val - 20) % 10] || suffixes[val] || suffixes[0]);
}

export function useUnsafePDFTextLayer(layerRef, items, scale) {
  useEffect(() => {
    if (layerRef.current) {
      const actualWidths = map(
        ($el) => $el.getBoundingClientRect().width,
        layerRef.current.childNodes
      );
      layerRef.current.childNodes.forEach(($el: any, idx: number) => {
        const actualWidth = actualWidths[idx];
        const targetWidth = items[idx].width * scale;
        const transformRule = `scaleX(${targetWidth / actualWidth})`;

        $el.style.transform = isEmpty($el.style.transform)
          ? transformRule
          : `${$el.style.transform} ${transformRule}`;
      });
    }
  }, [items, scale, layerRef]);
}

type StoreUpdaterFn<T extends { [k: string]: any }> = (
  cache: any,
  toMerge: RecursivePartial<T> | ((state: T) => T)
) => void;

export function getLocalStateUpdater<T extends GQLType>(
  localStateName: string,
  localStateQuery: any
): StoreUpdaterFn<T> {
  return (cache: any, toMerge: RecursivePartial<T> | ((state: T) => T)) => {
    const data = cache.readQuery({ query: localStateQuery });
    const currentState: T = data[localStateName];

    cache.writeQuery({
      query: localStateQuery,
      data: {
        [localStateName]: isFunction(toMerge)
          ? toMerge(currentState)
          : mergeWith(
              function merger(one, other) {
                // ensure empty collection overrides
                if ((isPlainObject(other) || isArray(other)) && isEmpty(other)) {
                  return other;
                }
                // if both values are objects merge recursively
                if (isPlainObject(one) && isPlainObject(other)) {
                  return mergeWith(merger, one, other);
                }
                // otherwise just override
                return other;
              },
              currentState,
              toMerge
            ),
      },
    });
  };
}

export function swapArrayItems<T>(aIdx: number, bIdx: number, xs: T[]): T[] {
  if (aIdx < 0 || aIdx >= xs.length) return xs;
  if (bIdx < 0 || bIdx >= xs.length) return xs;

  const result = [...xs];

  result[aIdx] = xs[bIdx];
  result[bIdx] = xs[aIdx];

  return result;
}

export function withPrevLocation(descriptor: LocationDescriptor): LocationDescriptor {
  const prevLocation = {
    pathname: window.location.pathname,
    search: window.location.search,
  };

  if (isString(descriptor)) {
    const [pathname, search] = descriptor.split('?');
    return {
      pathname,
      search,
      state: { prevLocation },
    };
  } else {
    return {
      ...descriptor,
      state: isObject(descriptor.state)
        ? { ...descriptor.state, prevLocation }
        : { state: descriptor.state, prevLocation },
    };
  }
}

export function mapRight<T, U>(mapper: (x: T, idx: number, xs: T[]) => U, xs: T[]): U[] {
  return xs.reduceRight((acc, x, idx, xxs) => {
    acc.push(mapper(x, idx, xxs));
    return acc;
  }, [] as U[]);
}
