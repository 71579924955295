/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import React from 'react';

const logoCss = css`
  width: 48px;
  font-size: 10px;
  color: ${Colors.LIGHT_GRAY1};

  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: inherit;
  }
`;

interface ILogoProps {
  className?: string;
}

const Logo: React.FC<ILogoProps> = ({ className }) => {
  return (
    <div css={logoCss} className={`flex-none font-bold ${className ?? ''}`}>
      <Link className="w-full h-full flex flex-row justify-center items-center" to="/">
        <img src="/media/logo.svg" alt="Laser AI" />
      </Link>
    </div>
  );
};

export default Logo;
