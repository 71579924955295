/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Colors, Divider } from '@blueprintjs/core';
import { useMemo } from 'react';
import { TReferenceData } from '..';
import { DecisionFilter } from '../../../apollo/screening_state';
import { fancyScrollCss } from '../../../common/styles';
import { getDecisionFilterLabel } from '../../../lib/task_helpers';
import { getShortCitation } from '../../references/helpers';
import { Trans } from '@lingui/macro';

interface IReferencesHUDListProps {
  decisionFilter: DecisionFilter;
  references: readonly TReferenceData[];
  referencesCount: number;
  onReferenceSelect: (referenceId: string) => void;
  activeReferenceId?: string;
  onLoadMoreReferences?: () => void;
}

const ReferencesHUDList: React.FC<IReferencesHUDListProps> = ({
  decisionFilter,
  referencesCount,
  activeReferenceId,
  references,
  onReferenceSelect,
  onLoadMoreReferences,
}) => {
  const title = useMemo(() => {
    const titleColor =
      decisionFilter === 'in' ? '#3AEBA2' : decisionFilter === 'out' ? Colors.RED1 : Colors.BLUE1;

    return (
      <span className="font-bold text-white uppercase">
        <span className="mr-2" style={{ color: titleColor }}>
          {getDecisionFilterLabel(decisionFilter)}
        </span>
        <span>{referencesCount}</span>
      </span>
    );
  }, [decisionFilter, referencesCount]);

  return (
    <div className="flex flex-col overflow-auto">
      <div>{title}</div>
      <Divider className="mx-0" />
      <div className="text-white text-xs overflow-auto pr-1" css={fancyScrollCss}>
        {references.map((reference) => {
          const citation = getShortCitation(reference);
          const refTitle = reference.title;
          const isActive = activeReferenceId === reference.id;

          return (
            <div
              key={reference.id}
              className={`flex flex-row overflow-hidden mb-2 cursor-pointer ${
                isActive ? 'font-bold' : 'hover:underline'
              }`}
              onClick={isActive ? undefined : () => onReferenceSelect(reference.id)}
            >
              <div className="flex-none w-1/3 mr-2 truncate" title={citation}>
                {citation}
              </div>
              <div className="flex-1 truncate" title={refTitle}>
                {refTitle}
              </div>
            </div>
          );
        })}
      </div>
      {onLoadMoreReferences && (
        <div className="flex-none text-center">
          <Button
            minimal
            small
            onClick={onLoadMoreReferences}
            text={
              <span className="text-sm underline">
                <Trans>Load more references</Trans>
              </span>
            }
          />
        </div>
      )}
    </div>
  );
};

export default ReferencesHUDList;
