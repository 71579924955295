function getTextItemElementFromSelectionNode(selectionNode: Node | HTMLElement | null) {
  if (selectionNode instanceof HTMLElement) {
    return selectionNode.hasAttribute('data-item-index') ? selectionNode : null;
  }

  if (selectionNode instanceof Node) {
    return selectionNode.parentElement
      ? selectionNode.parentElement.closest('[data-item-index]')
      : null;
  }

  return null;
}

export function getSelectionText(selection: Selection): string {
  const defaultSelectionText = selection.toString();
  // amount of pixels between DOM elements to be considered a space;
  const SPACING_THRESHOLD = 2;
  // currently only single range selection supported.
  const range = selection.getRangeAt(0);

  if (range.startContainer === range.endContainer) return defaultSelectionText;
  // we do certain shortcuts here. we assume that startContainer will always be a TEXT node, thus
  // we get its parent element. Generally this is not always the case and startContainer can also
  // be an ELEMENT node. However, since this is a PDF content DOM rendered by us, it should always
  // have selection done on TEXT nodes (raw string) within ELEMENT nodes (span surrounding the text)
  // So, this shortcut should not fail us.
  const startEl = getTextItemElementFromSelectionNode(range.startContainer);
  let endEl = getTextItemElementFromSelectionNode(range.endContainer);
  if (endEl && selection.focusOffset === 0) {
    endEl = endEl.previousElementSibling;
  }
  if (startEl == null || endEl == null) return defaultSelectionText;

  const startText = range.startContainer.textContent!.slice(range.startOffset);
  const endText = range.endContainer.textContent!.slice(0, range.endOffset);
  const rangeElements: Element[] = [startEl];

  let nextEl = startEl.nextElementSibling;
  // collect intermediate elements (ones between start and end elements)
  while (nextEl && nextEl !== endEl) {
    rangeElements.push(nextEl);
    nextEl = nextEl.nextElementSibling;
  }
  rangeElements.push(endEl);

  // finally calculate the resulting string by traversing the range elements and comparing the end
  // position of one element to the start position of the next. If next element doesn't start right
  // the previous one, it means there should be a space between those elements' text contents
  return rangeElements
    .map((el, elIdx, els) => {
      let elText;

      if (el === endEl) {
        elText = endText;
      } else if (el === startEl) {
        elText = startText;
      } else {
        elText = el.textContent;
      }

      if (el === endEl) {
        return elText;
      } else {
        const { top, right } = el.getBoundingClientRect();
        const { top: nextTop, left: nextLeft } = els[elIdx + 1].getBoundingClientRect();

        // If next element is located on the next line, consider it to be separated with space.
        // If next element is far enough (distance between this el and the next one is bigger than
        // the threshold) also add a space to speparate them
        if (top !== nextTop || nextLeft - right >= SPACING_THRESHOLD) {
          return elText + ' ';
        }

        return elText;
      }
    })
    .join('')
    .replace(/\s{2,}/g, ' ') // collapse spaces
    .replace(/[\x00-\x1F\x80-\x9F]+/g, '�'); // replace any non-unicode char with REPLACEMENT char
}
