import { loader } from 'graphql.macro';
import { getLocalStateUpdater } from '../lib/utils';
import { LocalState, GQLType } from './local_states';

const AppStateQuery = loader('../graphql/local/get_app_state.gql');

export interface IAppState extends GQLType {
  showSendDecisionsConfirmDialog: boolean;
}

const initialStateProvider = (): { [key: string]: IAppState } => ({
  AppState: {
    __typename: 'AppState',
    showSendDecisionsConfirmDialog: true,
  },
});

const updateState = getLocalStateUpdater<IAppState>('AppState', AppStateQuery);

const AppLocalState: LocalState = {
  name: 'AppState',
  rootQuery: AppStateQuery,
  initial: initialStateProvider,
  resolvers: {
    Mutation: {
      resetAppState: (_root, _args, { cache }) => {
        updateState(cache, () => initialStateProvider().AppState);
      },

      toggleShowSendDecisionsConfirmDialog: (_root, _args, { cache }) => {
        updateState(cache, (currentState) => {
          const { showSendDecisionsConfirmDialog } = currentState;
          return {
            ...currentState,
            showSendDecisionsConfirmDialog: !showSendDecisionsConfirmDialog,
          };
        });
      },
    },
  },
};

export default AppLocalState;
