import { Intent, Dialog, Classes, Button } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { IconNames } from '@blueprintjs/icons';

interface IReferencesBatchDeleteDialogProps {
  importTaskKey: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (importTaskKey: string) => void;
}

const ReferencesBatchDeleteDialog: React.FC<IReferencesBatchDeleteDialogProps> = ({
  importTaskKey,
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(() => {
    setLoading(true);
    onConfirm(importTaskKey);
  }, [setLoading, onConfirm, importTaskKey]);

  useEffect(() => {
    isOpen && setLoading(false);
  }, [setLoading, isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      icon={IconNames.TRASH}
      title={<Trans>Delete batch of references</Trans>}
      isCloseButtonShown={!loading}
      onClose={onCancel}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          <Trans>Are you sure you want to remove this set of references from the project?</Trans>
        </p>
        <p className="mt-3">
          <Trans>You will not be able to recover it.</Trans>
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className="px-4"
            text={<Trans>Cancel</Trans>}
            onClick={onCancel}
            disabled={loading}
          />
          <Button
            intent={Intent.DANGER}
            className="px-4"
            text={<Trans>Delete</Trans>}
            onClick={handleConfirm}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ReferencesBatchDeleteDialog;
