/** @jsx jsx */
import { Button, Checkbox, Classes, Dialog, Intent, MenuItem, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { delay } from 'lodash/fp';
import { ChangeEvent, FormEvent, useCallback } from 'react';
import { useI18n, useSetState } from '../lib/utils';

enum FeedbackType {
  Defect = 'Defect',
  Improvement = 'Improvement',
  Question = 'Question',
  Other = 'Other',
}

const feedbackTypeOptions: FeedbackType[] = [
  FeedbackType.Defect,
  FeedbackType.Improvement,
  FeedbackType.Question,
  FeedbackType.Other,
];
const FeedbackTypeSelect = Select.ofType<FeedbackType>();

export interface Feedback {
  feedbackType: FeedbackType;
  text: string;
  withScreenshot: boolean;
}

const INITIAL_FEEDBACK = {
  feedbackType: FeedbackType.Defect,
  text: '',
  withScreenshot: true,
};

interface IFeedbackDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onSend: (feedback: Feedback) => void;
}

const FeedbackDialog: React.FC<IFeedbackDialogProps> = ({ isOpen, onClose, onSend }) => {
  const [feedback, updateFeedback] = useSetState<Feedback>(INITIAL_FEEDBACK);
  const { feedbackType, text, withScreenshot } = feedback;
  const i18n = useI18n();

  const getFeedbackTypeLabel = useCallback((feedbackType: FeedbackType) => {
    switch (feedbackType) {
      case FeedbackType.Defect:
        return <Trans>Defect</Trans>;
      case FeedbackType.Improvement:
        return <Trans>Improvement</Trans>;
      case FeedbackType.Question:
        return <Trans>Question</Trans>;
      case FeedbackType.Other:
        return <Trans>Other</Trans>;
      default:
        return null;
    }
  }, []);

  const handleClose = useCallback(() => {
    updateFeedback(INITIAL_FEEDBACK);
    onClose();
  }, [updateFeedback, onClose]);

  const handleSend = useCallback(() => {
    handleClose();
    // let this dialog close before making a screenshot
    if (feedback.withScreenshot) {
      return delay(500, () => onSend(feedback));
    }
    onSend(feedback);
  }, [handleClose, feedback, onSend]);

  const handleTextChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      updateFeedback({ text: evt.target.value });
    },
    [updateFeedback]
  );

  const renderFeedbackOption: ItemRenderer<FeedbackType> = useCallback(
    (feedbackOption, { handleClick }) => (
      <MenuItem
        key={feedbackOption}
        active={feedbackType === feedbackOption}
        onClick={handleClick}
        text={getFeedbackTypeLabel(feedbackOption)}
      />
    ),
    [feedbackType, getFeedbackTypeLabel]
  );

  const handleFeedbackOptionSelect = useCallback(
    (feedbackOption) => {
      updateFeedback({ feedbackType: feedbackOption });
    },
    [updateFeedback]
  );

  const handleScreenshotOptionChange = useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      updateFeedback({ withScreenshot: evt.currentTarget.checked });
    },
    [updateFeedback]
  );

  return (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      title={<Trans>Feedback</Trans>}
      css={{ width: '30vw' }}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="flex flex-row items-center">
          <span className="font-bold mr-2">
            <Trans>Type</Trans>:
          </span>
          <FeedbackTypeSelect
            filterable={false}
            items={feedbackTypeOptions}
            itemRenderer={renderFeedbackOption}
            onItemSelect={handleFeedbackOptionSelect}
          >
            <Button
              css={{ minWidth: '200px' }}
              rightIcon={IconNames.CHEVRON_DOWN}
              alignText="left"
              text={getFeedbackTypeLabel(feedbackType)}
            />
          </FeedbackTypeSelect>
        </div>
        <TextArea
          className="resize-none my-3"
          value={text}
          onChange={handleTextChange}
          fill
          large
          growVertically
          placeholder={i18n._(t`Please enter your feedback here`)}
        />
        <Checkbox
          checked={withScreenshot}
          onChange={handleScreenshotOptionChange}
          labelElement={
            <span className="font-bold">
              <Trans>Attach screenshot (of the current state of the application)</Trans>
            </span>
          }
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={handleClose} />
          <Button text={<Trans>Send</Trans>} intent={Intent.PRIMARY} onClick={handleSend} />
        </div>
      </div>
    </Dialog>
  );
};

export default FeedbackDialog;
