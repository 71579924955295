import { t } from '@lingui/macro';
import i18n from '../i18n';

export default [
  i18n._(t`Afghanistan`),
  i18n._(t`Albania`),
  i18n._(t`Algeria`),
  i18n._(t`Andorra`),
  i18n._(t`Angola`),
  i18n._(t`Antigua and Barbuda`),
  i18n._(t`Argentina`),
  i18n._(t`Armenia`),
  i18n._(t`Australia`),
  i18n._(t`Austria`),
  i18n._(t`Azerbaijan`),
  i18n._(t`Bahamas`),
  i18n._(t`Bahrain`),
  i18n._(t`Bangladesh`),
  i18n._(t`Barbados`),
  i18n._(t`Belarus`),
  i18n._(t`Belgium`),
  i18n._(t`Belize`),
  i18n._(t`Benin`),
  i18n._(t`Bhutan`),
  i18n._(t`Bolivia`),
  i18n._(t`Bosnia and Herzegovina`),
  i18n._(t`Botswana`),
  i18n._(t`Brazil`),
  i18n._(t`Brunei`),
  i18n._(t`Bulgaria`),
  i18n._(t`Burkina Faso`),
  i18n._(t`Burundi`),
  i18n._(t`Cabo Verde`),
  i18n._(t`Cambodia`),
  i18n._(t`Cameroon`),
  i18n._(t`Canada`),
  i18n._(t`Central African Republic (CAR)`),
  i18n._(t`Chad`),
  i18n._(t`Chile`),
  i18n._(t`China`),
  i18n._(t`Colombia`),
  i18n._(t`Comoros`),
  i18n._(t`Congo, Democratic Republic of the`),
  i18n._(t`Congo, Republic of the`),
  i18n._(t`Costa Rica`),
  i18n._(t`Cote d'Ivoire`),
  i18n._(t`Croatia`),
  i18n._(t`Cuba`),
  i18n._(t`Cyprus`),
  i18n._(t`Czechia`),
  i18n._(t`Denmark`),
  i18n._(t`Djibouti`),
  i18n._(t`Dominica`),
  i18n._(t`Dominican Republic`),
  i18n._(t`Ecuador`),
  i18n._(t`Egypt`),
  i18n._(t`El Salvador`),
  i18n._(t`Equatorial Guinea`),
  i18n._(t`Eritrea`),
  i18n._(t`Estonia`),
  i18n._(t`Eswatini (formerly Swaziland)`),
  i18n._(t`Ethiopia`),
  i18n._(t`Fiji`),
  i18n._(t`Finland`),
  i18n._(t`France`),
  i18n._(t`Gabon`),
  i18n._(t`Gambia`),
  i18n._(t`Georgia`),
  i18n._(t`Germany`),
  i18n._(t`Ghana`),
  i18n._(t`Greece`),
  i18n._(t`Grenada`),
  i18n._(t`Guatemala`),
  i18n._(t`Guinea`),
  i18n._(t`Guinea-Bissau`),
  i18n._(t`Haiti`),
  i18n._(t`Honduras`),
  i18n._(t`Hungary`),
  i18n._(t`Iceland`),
  i18n._(t`India`),
  i18n._(t`Indonesia`),
  i18n._(t`Iran`),
  i18n._(t`Iraq`),
  i18n._(t`Ireland`),
  i18n._(t`Israel`),
  i18n._(t`Italy`),
  i18n._(t`Jamaica`),
  i18n._(t`Japan`),
  i18n._(t`Jordan`),
  i18n._(t`Kazakhstan`),
  i18n._(t`Kenya`),
  i18n._(t`Kiribati`),
  i18n._(t`Kosovo`),
  i18n._(t`Kuwait`),
  i18n._(t`Kyrgyzstan`),
  i18n._(t`Laos`),
  i18n._(t`Latvia`),
  i18n._(t`Lebanon`),
  i18n._(t`Lesotho`),
  i18n._(t`Liberia`),
  i18n._(t`Libya`),
  i18n._(t`Liechtenstein`),
  i18n._(t`Lithuania`),
  i18n._(t`Luxembourg`),
  i18n._(t`Madagascar`),
  i18n._(t`Malawi`),
  i18n._(t`Malaysia`),
  i18n._(t`Maldives`),
  i18n._(t`Mali`),
  i18n._(t`Malta`),
  i18n._(t`Marshall Islands`),
  i18n._(t`Mauritania`),
  i18n._(t`Mauritius`),
  i18n._(t`Mexico`),
  i18n._(t`Micronesia`),
  i18n._(t`Moldova`),
  i18n._(t`Monaco`),
  i18n._(t`Mongolia`),
  i18n._(t`Montenegro`),
  i18n._(t`Morocco`),
  i18n._(t`Mozambique`),
  i18n._(t`Myanmar (formerly Burma)`),
  i18n._(t`Namibia`),
  i18n._(t`Nauru`),
  i18n._(t`Nepal`),
  i18n._(t`Netherlands`),
  i18n._(t`New Zealand`),
  i18n._(t`Nicaragua`),
  i18n._(t`Niger`),
  i18n._(t`Nigeria`),
  i18n._(t`North Korea`),
  i18n._(t`North Macedonia (formerly Macedonia)`),
  i18n._(t`Norway`),
  i18n._(t`Oman`),
  i18n._(t`Pakistan`),
  i18n._(t`Palau`),
  i18n._(t`Palestine`),
  i18n._(t`Panama`),
  i18n._(t`Papua New Guinea`),
  i18n._(t`Paraguay`),
  i18n._(t`Peru`),
  i18n._(t`Philippines`),
  i18n._(t`Poland`),
  i18n._(t`Portugal`),
  i18n._(t`Qatar`),
  i18n._(t`Romania`),
  i18n._(t`Russia`),
  i18n._(t`Rwanda`),
  i18n._(t`Saint Kitts and Nevis`),
  i18n._(t`Saint Lucia`),
  i18n._(t`Saint Vincent and the Grenadines`),
  i18n._(t`Samoa`),
  i18n._(t`San Marino`),
  i18n._(t`Sao Tome and Principe`),
  i18n._(t`Saudi Arabia`),
  i18n._(t`Senegal`),
  i18n._(t`Serbia`),
  i18n._(t`Seychelles`),
  i18n._(t`Sierra Leone`),
  i18n._(t`Singapore`),
  i18n._(t`Slovakia`),
  i18n._(t`Slovenia`),
  i18n._(t`Solomon Islands`),
  i18n._(t`Somalia`),
  i18n._(t`South Africa`),
  i18n._(t`South Korea`),
  i18n._(t`South Sudan`),
  i18n._(t`Spain`),
  i18n._(t`Sri Lanka`),
  i18n._(t`Sudan`),
  i18n._(t`Suriname`),
  i18n._(t`Sweden`),
  i18n._(t`Switzerland`),
  i18n._(t`Syria`),
  i18n._(t`Taiwan`),
  i18n._(t`Tajikistan`),
  i18n._(t`Tanzania`),
  i18n._(t`Thailand`),
  i18n._(t`Timor-Leste`),
  i18n._(t`Togo`),
  i18n._(t`Tonga`),
  i18n._(t`Trinidad and Tobago`),
  i18n._(t`Tunisia`),
  i18n._(t`Turkey`),
  i18n._(t`Turkmenistan`),
  i18n._(t`Tuvalu`),
  i18n._(t`Uganda`),
  i18n._(t`Ukraine`),
  i18n._(t`United Arab Emirates (UAE)`),
  i18n._(t`United Kingdom (UK)`),
  i18n._(t`United States of America (USA)`),
  i18n._(t`Uruguay`),
  i18n._(t`Uzbekistan`),
  i18n._(t`Vanuatu`),
  i18n._(t`Vatican City (Holy See)`),
  i18n._(t`Venezuela`),
  i18n._(t`Vietnam`),
  i18n._(t`Yemen`),
  i18n._(t`Zambia`),
  i18n._(t`Zimbabwe`),
];
