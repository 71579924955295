import React, { ReactNode } from 'react';
import { Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface IStatsCountProps {
  className?: string;
  count: number | string;
  label?: ReactNode;
  showIcon?: boolean;
}

const StatsCount: React.FC<IStatsCountProps> = ({ className, count, label, showIcon = true }) => (
  <div className={`flex flex-row items-center mr-2 last:mr-0 ${className ?? ''}`}>
    {label && <span className="mr-1">{label}</span>}
    <span>{count}</span>
    {showIcon && <Icon className="ml-1" icon={IconNames.DOCUMENT} color={Colors.GRAY3} />}
  </div>
);

export default StatsCount;
