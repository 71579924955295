import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import DocPageToggles from '../../screening/full_text/doc_page_toggles';

interface IAttachmentsControlsProps {
  attachmentsCount: number;
  onChange: (newAttachmentNumber: number) => void;
  current: number;
}

const AttachmentsControls: React.FC<IAttachmentsControlsProps> = ({
  current,
  onChange,
  attachmentsCount,
}) => {
  const handlePrev = () => onChange(current - 1);
  const handleNext = () => onChange(current + 1);

  return (
    <div>
      <Button icon={IconNames.CARET_LEFT} minimal onClick={handlePrev} disabled={current === 0} />
      <DocPageToggles
        activePage={current + 1}
        onChangePage={(attachmentNumber) => onChange(attachmentNumber - 1)}
        numPages={attachmentsCount}
        onNext={handleNext}
        onPrev={handlePrev}
      />
      <Button
        icon={IconNames.CARET_RIGHT}
        minimal
        onClick={handleNext}
        disabled={current === attachmentsCount - 1}
      />
    </div>
  );
};

export default AttachmentsControls;
