/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { memo, forwardRef, useCallback } from 'react';
import { Colors, Spinner, SpinnerSize } from '@blueprintjs/core';
import { Page } from 'react-pdf';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PageRendererProps } from './doc_pages';

const activePDFPageQuery = loader('../../graphql/local/get_active_pdf_page_data.gql');
const scrollToPageMutation = loader('../../graphql/local/set_scroll_to_pdf_page.gql');

const PAGE_SPACING = 5;
const pageCss = css`
  margin-bottom: ${PAGE_SPACING}px;
  cursor: pointer;

  &:before {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-page);
    z-index: 5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-weight: bold;
    color: #00000000;
    transition: font-size 0.3s ease, color 0.3s ease;
  }

  &:hover {
    &:before {
      color: rgba(0, 0, 0, 0.5);
      font-size: 40px;
    }
  }
`;

const thumbCss = (isActive: boolean) => css`
  .react-pdf__Page__canvas {
    border: 2px solid ${isActive ? Colors.BLUE5 : 'transparent'};
  }
`;

const PageThumbnail = memo(
  forwardRef((props: PageRendererProps, ref: any) => {
    const { pageNum, height } = props;
    const {
      data: {
        activePageData: { activePage },
      },
    }: { data: any } = useQuery(activePDFPageQuery);
    const [scrollDocToThisPage] = useMutation(scrollToPageMutation, {
      variables: { page: pageNum },
    });
    const isActive = activePage === pageNum;

    const activatePage = useCallback(() => {
      if (!isActive) {
        scrollDocToThisPage();
      }
    }, [scrollDocToThisPage, isActive]);

    return (
      <div
        ref={ref}
        css={pageCss}
        className="pdf_page_wrapper"
        key={`page_${pageNum}`}
        onClick={() => activatePage()}
        data-page={pageNum}
        style={{ minHeight: height }}
      >
        <Page
          pageNumber={pageNum}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          css={thumbCss(isActive)}
          height={height}
          loading={<Spinner size={SpinnerSize.LARGE} />}
        />
      </div>
    );
  })
);

export default PageThumbnail;
