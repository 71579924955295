import { Trans } from '@lingui/macro';
import React from 'react';
import ConfirmationDialog from '../../common/confirmation_dialog';

interface IMarkNoReportConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MarkNoReportConfirmation: React.FC<IMarkNoReportConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <ConfirmationDialog
      title={<Trans>Report not retrieved</Trans>}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmBtnText={<Trans>Report not retrieved</Trans>}
    >
      <div className="mb-2">
        <Trans>
          Selected records will not be available for distribution in Full text screening.
        </Trans>
      </div>
      <div>
        <Trans>This action will be undone when report is assigned to the reference.</Trans>
      </div>
    </ConfirmationDialog>
  );
};

export default MarkNoReportConfirmation;
