/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo, useState } from 'react';
import CommentEditDialog from './comment_edit_dialog';
import { Button, IMenuItemProps, Intent, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { breakWordCss } from '../../common/styles';
import DecisionIndicator from './decision_indicator';
import { isEmpty } from 'lodash/fp';
import { useI18n, useStatusColor } from '../../lib/utils';
import ActionsMenu from '../common/actions_menu';
import { t } from '@lingui/macro';
import { ReferenceComment, ScreeningTag, User } from '../../common/types';

interface DecisionIndicatorProps {
  onSaveEditedComments?: (data: { comment: string; tags: string[] }) => void;
  decision?: string;
  decisionReason?: string;
  comment?: string;
  onUnreview?: () => void;
  selectedTags?: string[];
  screeningTags?: ScreeningTag[];
  previousComments?: (Pick<ReferenceComment, 'id' | 'comment'> & {
    team_member: { id: string; user: Pick<User, 'id' | 'name'> };
  })[];
}

const DecisionAndCommentIndicator: React.FC<DecisionIndicatorProps> = ({
  decisionReason,
  decision,
  comment,
  onSaveEditedComments,
  onUnreview,
  selectedTags,
  screeningTags,
  previousComments,
}) => {
  const i18n = useI18n();
  const [dialogVisible, setDialogVisible] = useState(false);
  const getStatusColor = useStatusColor();
  const disabled = onSaveEditedComments == null;

  const borderColorCss = useMemo(
    () => ({
      borderColor: getStatusColor(decision),
    }),
    [getStatusColor, decision]
  );

  const commentActions: IMenuItemProps[] = useMemo(
    () =>
      onUnreview
        ? [
            {
              disabled,
              text: i18n._(t`Edit comment`),
              intent: Intent.NONE as Intent,
              icon: IconNames.EDIT,
              onClick: () => setDialogVisible(true),
            },
            {
              disabled,
              text: i18n._(t`Delete comment`),
              intent: Intent.DANGER as Intent,
              icon: IconNames.TRASH,
              onClick: () => onSaveEditedComments?.({ tags: selectedTags ?? [], comment: '' }),
            },
          ]
        : [],
    [onSaveEditedComments, onUnreview, disabled, i18n, selectedTags]
  );

  if (isEmpty(comment) && decision == null) return null;

  return (
    <div css={breakWordCss}>
      <div className="relative">
        <DecisionIndicator inclusionStatus={decision} exclusionReason={decisionReason}>
          <div className="flex-none flex flex-row">
            {onUnreview && decision && !disabled && (
              <div className="border-l-2" css={borderColorCss}>
                <Button icon={IconNames.ERASER} minimal large onClick={onUnreview} />
              </div>
            )}
            {comment && (
              <div className="border-l-2" css={borderColorCss}>
                <ActionsMenu
                  menuButtonProps={{
                    large: true,
                  }}
                  menuPosition={Position.BOTTOM}
                  actions={commentActions}
                />
              </div>
            )}
          </div>
        </DecisionIndicator>
        {comment && (
          <div className="border-2 border-t-0 p-3 whitespace-pre-wrap" css={borderColorCss}>
            {comment}
          </div>
        )}
      </div>
      <CommentEditDialog
        isOpen={dialogVisible}
        onClose={() => setDialogVisible(false)}
        screeningTags={screeningTags}
        selectedTags={selectedTags}
        onSave={onSaveEditedComments}
        previousComments={previousComments}
        comment={comment}
      />
    </div>
  );
};

export default DecisionAndCommentIndicator;
