import { ExecutionResult, MutationFunctionOptions } from '@apollo/react-common';
import { flatten, forEach, map, merge, sortBy, sum, sumBy } from 'lodash/fp';
import { fill } from 'lodash';
import { upload } from './storageEndpoint';
import { promiseSequence } from './utils';
import { ReferenceLog } from '../common/types';

interface IBucket {
  name: string;
  path: string;
}

interface IBuckets {
  ReferencesImport: IBucket;
  ReferencesAttachments: IBucket;
}

export const Buckets: IBuckets = {
  ReferencesImport: {
    name: process.env.IMPORTS_BUCKET || 'imports',
    path: 'project-attachment',
  },
  ReferencesAttachments: {
    name: process.env.ATTACHMENTS_BUCKET || 'attachments',
    path: 'reference-attachment',
  },
};

const MAX_CHUNK_SIZE = parseInt(process.env.UPLOAD_MAX_CHUNK_SIZE ?? '') || 5;
const MAX_CHUNK_FILE_SIZE =
  parseInt(process.env.UPLOAD_MAX_CHUNK_FILE_SIZE ?? '') || 10 * 1000 * 1000; // in bytes

type TChunk = {
  files: File[];
  size: number;
};

export function getChunksFromFiles(files: File[]): TChunk[] {
  let currentFileSize = 0;
  let currentChunk: File[] = [];
  const chunks: TChunk[] = [];

  forEach<File>((file) => {
    if (
      currentChunk.length === MAX_CHUNK_SIZE ||
      (currentChunk.length > 0 && currentFileSize + file.size >= MAX_CHUNK_FILE_SIZE)
    ) {
      chunks.push({
        files: currentChunk,
        size: currentFileSize,
      });
      currentChunk = [];
      currentFileSize = 0;
    }
    currentChunk.push(file);
    currentFileSize += file.size;
  }, sortBy('size', files));

  if (currentChunk.length) {
    chunks.push({
      files: currentChunk,
      size: currentFileSize,
    });
  }
  return chunks;
}

export type TUploadedFile = {
  projectId: string | undefined;
  originalname: string;
  key: string;
};

async function uploadChunk(
  bucket: IBucket,
  projectId: string | undefined,
  token: string,
  uploadProgressCb: (chunkPercentage: number) => void,
  chunk: TChunk
): Promise<TUploadedFile[]> {
  const formData = new FormData();
  forEach((file) => {
    formData.append('files', file);
  }, chunk.files);
  const response = await upload<TUploadedFile[]>(formData, {
    path: bucket.path,
    bucket: bucket.name,
    projectId,
    token,
    progressCb: uploadProgressCb,
  });
  return response.data;
}

interface IUploadProjectAttachmentsProps {
  token: string;
  projectId: string | undefined;
  bucket: IBucket;
  files: File[];
  updateUploadProgress: (value: number) => void;
  filesDataMapper: (file: TUploadedFile) => object;
  insertFilesMutation: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined
  ) => Promise<ExecutionResult<any>>;
  insertFilesMutationVariables?: object;
  insertFilesMutationOptions?: MutationFunctionOptions<any, Record<string, any>>;
  logsMapper?: (file: TUploadedFile) => Partial<ReferenceLog>;
}

export const uploadMultipleAttachments = async ({
  token,
  projectId,
  bucket,
  files,
  updateUploadProgress,
  filesDataMapper,
  insertFilesMutation,
  insertFilesMutationVariables,
  insertFilesMutationOptions,
  logsMapper,
}: IUploadProjectAttachmentsProps) => {
  const chunks = getChunksFromFiles(files);
  const allSize = sumBy('size', chunks);
  const uploadedSizeByChunks = fill(new Array<number>(chunks.length), 0);

  const chunkUploadProgress = (idx: number) => (percentage: number) => {
    if (allSize === 0) {
      return;
    }
    const chunk = chunks[idx];
    uploadedSizeByChunks[idx] = (percentage * chunk.size) / allSize;
    const uploadedSizePercentage = sum(uploadedSizeByChunks);
    updateUploadProgress(Math.round(uploadedSizePercentage));
  };

  const responses: TUploadedFile[][] = await promiseSequence(
    chunks.map(
      (chunk, idx) => () => uploadChunk(bucket, projectId, token, chunkUploadProgress(idx), chunk)
    )
  );

  const flattenedResponses: TUploadedFile[] = flatten(responses);
  const filesData = map(filesDataMapper, flattenedResponses);

  const logsData: Partial<ReferenceLog>[] | undefined = logsMapper
    ? map(logsMapper, flattenedResponses)
    : undefined;

  return insertFilesMutation({
    variables: merge({ ...(insertFilesMutationVariables ?? {}) }, { filesData, logsData }),
    ...insertFilesMutationOptions,
  });
};
