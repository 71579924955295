/** @jsx jsx */
import { useMutation } from '@apollo/react-hooks';
import { Button, Classes, Collapse, Colors, Intent, Alert } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Trans, t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import React, { useCallback, useState, Fragment } from 'react';
import { DomainItem, UserKeyword, VariableData } from '../../common/types';
import { EMPTY_KEYWORDS_DATA, mergeKeywordsDataAndUserKeywords } from '../../lib/criteria_utils';
import { toastify, useCurrCallback, useI18n } from '../../lib/utils';
import ActionsMenu, { createMenuItem } from '../common/actions_menu';
import useActionLogger from '../hooks/use_action_logger';
import KeywordsImportDialog from '../keywords/import_dialog/keywords_import_dialog';
import KeywordsForm, { KeywordsFormMode } from '../keywords/keywords_form';
import KeywordsEdit from './keywords_edit';
import VariableInstructionsEdit from './variable_instructions_edit';
import VariableTitleEdit from './variable_title_edit';

const variableDetailsCss = css`
  border-top: 1px solid ${Colors.LIGHT_GRAY1};
`;

const InsertKeywordsSetMutation = loader('../../graphql/insert_keywords_set_mutation.gql');

interface IDomainVariableProps {
  data: VariableData;
  domainName: string;
  domains: DomainItem[];
  onDelete: (variableId: string) => void;
  onEdit: (variableId: string) => void;
  onUpdate: (variableId: string, data: Partial<VariableData>) => Promise<any>;
  isVariableTitleTaken: (title: string) => boolean;
  saving: boolean;
  readOnly: boolean;
}

const DomainVariable: React.FC<IDomainVariableProps> = ({
  data,
  domainName,
  domains,
  onDelete,
  onEdit,
  onUpdate,
  isVariableTitleTaken,
  saving,
  readOnly,
}) => {
  const insertActionLog = useActionLogger();
  const i18n = useI18n();

  const [expanded, setExpanded] = useState(true);
  const [isKeywordsImportDialogOpen, setIsKeywordsImportDialogOpen] = useState<boolean>(false);
  const [isKeywordsExportDialogOpen, setIsKeywordsExportDialogOpen] = useState<boolean>(false);
  const [confirmVariableDeleteAlertOpen, setConfirmVariableDeleteAlertOpen] =
    useState<boolean>(false);

  const [insertKeywordsSet] = useMutation(InsertKeywordsSetMutation);

  const handleDelete = useCallback(async () => {
    await setConfirmVariableDeleteAlertOpen(false);
    onDelete(data.id);
  }, [onDelete, data.id, setConfirmVariableDeleteAlertOpen]);

  const handleEdit = useCallback(() => {
    onEdit(data.id);
  }, [onEdit, data.id]);

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, [setExpanded]);

  const handleVariableSectionSave = useCurrCallback(
    (variableDataFieldName: string, updatedValue: any) => {
      return onUpdate(data.id, { [variableDataFieldName]: updatedValue });
    },
    [onUpdate, data.id]
  );

  const handleImportKeyword = useCallback(
    (userKeywords: UserKeyword[], overwriteExisting: boolean) => {
      const currentKeywords = overwriteExisting
        ? EMPTY_KEYWORDS_DATA
        : data.keywords ?? EMPTY_KEYWORDS_DATA;
      const mergedKeywords = mergeKeywordsDataAndUserKeywords(currentKeywords, userKeywords);
      toastify(
        onUpdate(data.id, { keywords: mergedKeywords }).then(() =>
          insertActionLog('imported keywords set', {
            userKeywords,
            overwriteExisting,
            resultKeywords: mergedKeywords,
          })
        ),
        i18n._(t`Keywords successfully updated`),
        i18n._(t`Error while updating keywords`),
        {
          errorToasterProps: {
            icon: IconNames.ERROR,
          },
          successToasterProps: {
            icon: IconNames.SAVED,
          },
        }
      );
    },
    [data.id, data.keywords, i18n, onUpdate, insertActionLog]
  );

  const handleInsertKeyword = useCallback(
    (keywordsSet: UserKeyword) => {
      return toastify(
        insertKeywordsSet({ variables: { keywordsSet } }).then(() =>
          insertActionLog('inserted keywords set', { keywordsSet })
        ),
        i18n._(t`Keyword set successfully saved`),
        i18n._(t`Error while trying to save keyword set`),
        {
          errorToasterProps: {
            icon: IconNames.ERROR,
          },
          successToasterProps: {
            icon: IconNames.SAVED,
          },
        }
      );
    },
    [i18n, insertKeywordsSet, insertActionLog]
  );

  return (
    <div className={`${Classes.ELEVATION_0} my-4 rounded-sm bg-white`}>
      <div className="flex flex-row justify-between items-center p-4">
        <div className="flex-1 flex flex-row justify-between items-center">
          <span className="font-bold">{data.name}</span>
          <Button
            minimal
            className="mr-3"
            icon={expanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            onClick={toggleExpand}
          />
        </div>
        {!readOnly && (
          <Fragment>
            <ActionsMenu
              actions={[
                createMenuItem('edit', handleEdit),
                createMenuItem('delete', () => setConfirmVariableDeleteAlertOpen(true)),
              ]}
            />
            <Alert
              cancelButtonText={i18n._(t`Cancel`)}
              confirmButtonText={i18n._(t`Delete variable`)}
              intent={Intent.DANGER}
              isOpen={confirmVariableDeleteAlertOpen}
              onCancel={() => setConfirmVariableDeleteAlertOpen(false)}
              onConfirm={handleDelete}
            >
              <p className="font-bold mb-3">
                <Trans>Are you sure to delete the following variable: {data.name}?</Trans>
              </p>
            </Alert>
          </Fragment>
        )}
      </div>
      <Collapse isOpen={expanded}>
        <div className="p-4" css={variableDetailsCss}>
          <VariableTitleEdit
            isTitleTaken={isVariableTitleTaken}
            onSave={handleVariableSectionSave('title')}
            title={data.title}
            saving={saving}
            readOnly={readOnly}
          />
          {data.instructions != null && (
            <VariableInstructionsEdit
              onSave={handleVariableSectionSave('instructions')}
              instructions={data.instructions}
              saving={saving}
              readOnly={readOnly}
            />
          )}
          {data.keywords != null && (
            <KeywordsEdit
              keywords={data.keywords}
              onExport={() => setIsKeywordsExportDialogOpen(true)}
              onImport={() => setIsKeywordsImportDialogOpen(true)}
              onSave={handleVariableSectionSave('keywords')}
            />
          )}
        </div>
      </Collapse>
      {isKeywordsImportDialogOpen && (
        <KeywordsImportDialog
          defaultDomain={domainName}
          defaultVariable={data.name}
          onClose={() => setIsKeywordsImportDialogOpen(false)}
          onImport={handleImportKeyword}
        />
      )}
      {isKeywordsExportDialogOpen && (
        <KeywordsForm
          domains={domains}
          keywordsData={{
            domain: domainName,
            excluded: data.keywords?.excluded ?? [],
            included: data.keywords?.included ?? [],
            title: '',
            variable: data.name,
          }}
          mode={KeywordsFormMode.InclusionExclusionCriteria}
          onSave={handleInsertKeyword}
          onClose={() => setIsKeywordsExportDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default DomainVariable;
