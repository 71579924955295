/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React, { ReactNode } from 'react';
import { bgTransition, screeningListColHeaderHeight } from '../../../common/styles';
interface IStudiesHeaderProps {
  leftElement?: ReactNode;
  headerElement?: ReactNode;
  rightElement?: ReactNode;
  openFocusMode: () => void;
}

const StudiesHeader: React.FC<IStudiesHeaderProps> = ({
  leftElement,
  headerElement,
  rightElement,
  openFocusMode,
}) => {
  return (
    <div className="flex flex-row flex-auto items-center border" css={screeningListColHeaderHeight}>
      {leftElement}
      {headerElement}
      <Tooltip className="flex h-full" content={<Trans>Focus mode</Trans>}>
        <Button
          className="h-full flex-none"
          icon={<Icon className="p-2" icon={IconNames.FULLSCREEN} iconSize={16} />}
          css={bgTransition}
          onClick={openFocusMode}
          large
          minimal
        />
      </Tooltip>
      {rightElement}
    </div>
  );
};

export default StudiesHeader;
