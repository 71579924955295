import { Button, FormGroup, InputGroup, Intent, NonIdealState, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { set } from 'lodash/fp';
import React, { ChangeEvent, ReactNode } from 'react';
import { updateArrayItem, useCurrCallback } from '../../../lib/utils';

export interface IBibliographicDetailsData {
  title: string;
  authors: { firstNames: string[]; lastName: string }[];
  doi: string;
  venue: string;
  year: string;
  volume: string;
  issue: string;
  pages: string;
  abstract: string;
}

type TEditableField =
  | 'title'
  | 'authors'
  | 'doi'
  | 'venue'
  | 'year'
  | 'volume'
  | 'issue'
  | 'pages'
  | 'abstract';

export interface IBibliographicDetailsProps extends IBibliographicDetailsData {
  onChange?: (fieldName: TEditableField, fieldValue: any) => void;
  className?: string;
  invalidFields?: { [FieldName in keyof IBibliographicDetailsData]?: string };
}

type TTextFieldProps = {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  label: ReactNode;
  readOnly: boolean;
  value: string;
  error?: string;
  className?: string;
};

const TextField: React.FC<TTextFieldProps> = ({
  onChange,
  label,
  readOnly,
  value,
  error,
  className,
}) => (
  <FormGroup
    className={className}
    helperText={error}
    intent={error ? Intent.DANGER : undefined}
    label={<span className="text-xs">{label}</span>}
  >
    <InputGroup
      onChange={onChange}
      disabled={readOnly}
      readOnly={readOnly}
      value={value}
      intent={error ? Intent.DANGER : undefined}
    />
  </FormGroup>
);

const TextFieldsGroup: React.FC<{ fields: TTextFieldProps[] }> = ({ fields }) => (
  <div className="flex">
    {fields.map((textFieldProps, idx) => (
      <TextField className={`flex-1 ${idx > 0 ? 'ml-4' : ''}`} key={idx} {...textFieldProps} />
    ))}
  </div>
);

const BibliographicDetails: React.FC<IBibliographicDetailsProps> = ({
  title,
  authors,
  doi,
  venue,
  year,
  volume,
  issue,
  pages,
  abstract,
  onChange,
  className,
  invalidFields,
}) => {
  const isReadOnly = onChange == null;

  const handleFieldChange = useCurrCallback(
    (fieldName: TEditableField, evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(fieldName, evt.target.value);
    },
    [onChange]
  );

  const handleAuthorFieldChange =
    (authorIdx: number, fieldName: 'firstNames' | 'lastName') =>
    (evt: ChangeEvent<HTMLInputElement>) => {
      if (authors[authorIdx] == null) return;
      const fieldValue =
        fieldName === 'firstNames' ? evt.target.value.split(' ') : evt.target.value;

      onChange?.('authors', updateArrayItem(authorIdx, set(fieldName, fieldValue), authors));
    };
  const removeAuthor = (authorIdx: number) => () => {
    if (authors[authorIdx] == null) return;
    const updatedAuthors = [...authors];
    updatedAuthors.splice(authorIdx, 1);

    onChange?.('authors', updatedAuthors);
  };

  const addNewAuthor = () => {
    const updatedAuthors = authors.concat({
      firstNames: [],
      lastName: '',
    });

    onChange?.('authors', updatedAuthors);
  };

  return (
    <div className={className}>
      <TextField
        label={<Trans>Reference title</Trans>}
        onChange={handleFieldChange('title')}
        readOnly={isReadOnly}
        value={title}
      />
      <TextField
        label={<Trans>DOI</Trans>}
        onChange={handleFieldChange('doi')}
        readOnly={isReadOnly}
        value={doi}
      />
      <TextField
        label={<Trans>Published in</Trans>}
        onChange={handleFieldChange('venue')}
        readOnly={isReadOnly}
        value={venue}
      />
      <TextFieldsGroup
        fields={[
          {
            label: <Trans>Year</Trans>,
            onChange: handleFieldChange('year'),
            readOnly: isReadOnly,
            value: year,
            error: invalidFields?.year,
          },
          {
            label: <Trans>Volume</Trans>,
            onChange: handleFieldChange('volume'),
            readOnly: isReadOnly,
            value: volume,
          },
          {
            label: <Trans>Issue</Trans>,
            onChange: handleFieldChange('issue'),
            readOnly: isReadOnly,
            value: issue,
          },
          {
            label: <Trans>Pages range</Trans>,
            onChange: handleFieldChange('pages'),
            readOnly: isReadOnly,
            value: pages,
          },
        ]}
      />
      <div className="flex text-xs">
        <div className="flex-1 mr-4">
          <Trans>Authors' names</Trans>
        </div>
        <div className="flex-1">
          <FormGroup
            className="mb-1"
            label={<Trans>Authors' surnames</Trans>}
            helperText={invalidFields?.authors}
            intent={invalidFields?.authors ? Intent.DANGER : undefined}
          />
        </div>
        <span className="ml-4 w-6" />
      </div>
      {isEmpty(authors) ? (
        <NonIdealState
          className="bg-gray-300 italic mb-2 text-xs"
          description={<Trans>There are no authors</Trans>}
        />
      ) : (
        authors.map(({ firstNames, lastName }, idx) => (
          <div className="flex mb-4" key={idx}>
            <InputGroup
              className="flex-1 mr-4"
              disabled={isReadOnly}
              readOnly={isReadOnly}
              value={firstNames.join(' ')}
              onChange={handleAuthorFieldChange(idx, 'firstNames')}
            />
            <InputGroup
              className="flex-1"
              disabled={isReadOnly}
              readOnly={isReadOnly}
              value={lastName}
              onChange={handleAuthorFieldChange(idx, 'lastName')}
              intent={idx === 0 && invalidFields?.authors ? Intent.DANGER : undefined}
            />
            {!isReadOnly && (
              <Button
                className="ml-4"
                small
                icon={IconNames.CROSS}
                intent={Intent.DANGER}
                onClick={removeAuthor(idx)}
                minimal
              />
            )}
          </div>
        ))
      )}
      {!isReadOnly && (
        <Button
          className="mb-2"
          text={<Trans>Add an author</Trans>}
          icon={IconNames.ADD}
          onClick={addNewAuthor}
        />
      )}
      <FormGroup className="flex-1" label={<Trans>Abstract</Trans>}>
        <TextArea
          className="w-full"
          growVertically
          disabled={isReadOnly}
          readOnly={isReadOnly}
          value={abstract}
          onChange={handleFieldChange('abstract')}
        />
      </FormGroup>
    </div>
  );
};

export default BibliographicDetails;
