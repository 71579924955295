import { Colors, Classes } from '@blueprintjs/core';
import { css } from '@emotion/core';

export const GRID_SIZE = '20px';
export const DIVIDER_BORDER = '1px solid rgba(16, 22, 26, 0.15)';
export const BORDER = '1px solid rgba(16, 22, 26, 0.10)';

export const gray5Border = css`
  border: 1px solid ${Colors.GRAY5};
`;

export const TO_EXTRACT_COLOR = Colors.LIGHT_GRAY3;
export const TO_REVIEW_COLOR = Colors.GOLD4;
export const DONE_COLOR = Colors.GREEN3;

export const ButtonsBar = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: GRID_SIZE,
  [`& .${Classes.BUTTON}:not(:last-child)`]: {
    marginRight: GRID_SIZE,
  },
};

export const fixedHeaderTable = css`
  display: flex;
  flex-direction: column;
  flex: 1 2;
  overflow: hidden;

  tbody {
    flex: 1 1;
    overflow-y: auto;
    display: block;
  }

  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  thead {
    width: calc(100% - 0.9em);
  }
`;

export const defaultTableCss = css`
  th {
    vertical-align: middle;
    text-align: center;
    font-weight: normal;
    color: ${Colors.GRAY2};
  }

  tbody tr {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid ${Colors.GRAY5};
    transition: background 0.3s ease;

    &:not(:first-child) {
      border-top: 0;
    }

    &.selected {
      background-color: ${Colors.LIGHT_GRAY2};
    }

    &:hover {
      background-color: ${Colors.LIGHT_GRAY4};
    }
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    vertical-align: middle;

    input[type='checkbox'] {
      width: 20px;
      height: 20px;
    }
  }

  td {
    padding-top: 20px;
    padding-bottom: 20px;

    &.checkbox {
      .${Classes.CHECKBOX}:hover {
        .${Classes.CONTROL_INDICATOR} {
          box-shadow: 0 0 0 4px rgb(69, 128, 230), 0 0 0 8px rgba(69, 128, 230, 0.3),
            inset 0 1px 1px rgba(16, 22, 26, 0.2);
        }
      }
    }
  }
`;

export const alignStartCss = css`
  align-items: flex-start;
`;

export const alignEndCss = css`
  align-items: flex-end;
`;

export const alignCenterCss = css`
  align-items: center;
`;

export const lightGray1bg = css`
  background-color: ${Colors.LIGHT_GRAY1};
`;

export const lightGray3bg = css`
  background-color: ${Colors.LIGHT_GRAY3};
`;

export const lightGray4bg = css`
  background-color: ${Colors.LIGHT_GRAY4};
`;

export const lightGray5bg = css`
  background-color: ${Colors.LIGHT_GRAY5};
`;

export const darkGray1Color = css`
  color: ${Colors.DARK_GRAY1};
`;

export const darkGray5Color = css`
  color: ${Colors.DARK_GRAY5};
`;

export const border1Gray5 = css`
  border: 1px solid ${Colors.GRAY5};
`;

export const breakWordCss = css`
  overflow-wrap: anywhere;
  word-break: break-word;
`;

export const screeningListColHeaderHeight = css`
  height: 50px;
`;

export const gray1ColorCss = css`
  color: ${Colors.GRAY1};
`;

export const maxContentWidth = css`
  max-width: 1292px;
`;

export const fancyScrollCss = css`
  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: ${Colors.GRAY1} transparent;
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.GRAY1};
    border-radius: 20px;
    border: 3px solid transparent;
  }
`;

export const bgTransition = css`
  transition: 0.5s background-color ease-in-out;
`;

export const centerItemCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const projectHeaderCss = css`
  left: 48px;
`;

export const cursorCss = css`
  cursor: pointer;
`;

export const headerCellBgCss = css`
  background-color: #e2e8f0;
`;
