import { setupI18n } from '@lingui/core';
import deTranslation from './locales/de/messages';
import enTranslation from './locales/en/messages';

export default setupI18n({
  language: window.REACT_APP_DEFAULT_LOCALE || 'en',
  catalogs: {
    de: deTranslation,
    en: enTranslation,
  },
});
