import { Trans } from '@lingui/macro';
import { Text } from '@blueprintjs/core';
import React, { useState } from 'react';
import { ScreeningType } from '../../../common/types';
import { getScreeningTypeInfo } from '../helpers';
import ChangeScreeningTypeDialog from './change_screening_type_dialog';
import { DashboardCardWithButton } from './dashboard_card';

interface IScreeningTypeCardProps {
  selectedTypeId: string;
  screeningTypes: ScreeningType[];
  onChange?: (screeningTypeId: string) => Promise<any>;
}

const ScreeningTypeCard: React.FC<IScreeningTypeCardProps> = ({
  onChange,
  screeningTypes,
  selectedTypeId,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const { label, helpText } = getScreeningTypeInfo(selectedTypeId);

  return (
    <React.Fragment>
      <DashboardCardWithButton
        buttonProps={{ text: <Trans>Change</Trans>, disabled: !onChange }}
        name={label}
        onClick={onChange ? () => setEditOpen(true) : undefined}
      >
        <Text className="px-6">{helpText}</Text>
      </DashboardCardWithButton>
      {onChange && (
        <ChangeScreeningTypeDialog
          isOpen={editOpen}
          onClose={() => setEditOpen(false)}
          onApply={onChange}
          currentValue={selectedTypeId}
          screeningTypes={screeningTypes}
        />
      )}
    </React.Fragment>
  );
};

export default ScreeningTypeCard;
