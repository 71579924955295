/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  ButtonGroup,
  Classes,
  Colors,
  Divider,
  IconName,
  Intent,
  MaybeElement,
  Popover,
  Position,
  Tag,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { propEq, some } from 'lodash/fp';
import { FC, Fragment, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useCurrCallback } from '../../../lib/utils';
import { useTheme } from '../../settings/theme_context';
import { lightGray5bg } from '../../../common/styles';

export type ControlType = 'standard' | 'navigation';

export const iconCss = css`
  padding: 3px;
  border: 1px solid currentColor;
  border-radius: 3px;
  font-size: 0.625rem; // 10px
`;

const popoverCss = css`
  .${Classes.POPOVER_WRAPPER} {
    flex: 1 1 0%;
    position: relative;
  }
  .${Classes.POPOVER_CONTENT} {
    background: transparent;
  }
`;

type TDecisionsControlProps = Pick<
  ControlSpec,
  'id' | 'intent' | 'shortcutIcon' | 'text' | 'rightIcon' | 'type'
> & {
  isFirst: boolean;
  isActive: boolean;
  vertical: boolean;
  onClick: (id: string, type: ControlType) => void;
  disabled?: boolean;
};

const DecisionControl: React.FC<TDecisionsControlProps> = ({
  id,
  intent,
  shortcutIcon,
  text,
  type,
  isFirst,
  isActive,
  vertical,
  onClick,
  rightIcon,
  disabled,
}) => {
  const { statusColors } = useTheme();
  const handleControlClick = useCurrCallback(
    (controlId, type, _evt) => {
      onClick(controlId, type);
    },
    [onClick]
  );

  const color =
    intent === Intent.SUCCESS
      ? statusColors.acceptedPrimary
      : intent === Intent.DANGER
      ? statusColors.rejectedPrimary
      : statusColors.neutralPrimary;

  return (
    <Fragment>
      {!isFirst && <Divider className="border-none" />}
      <div
        data-testid="decision-control"
        css={{ minHeight: '35px' }}
        className={`${vertical ? '' : 'flex-1'} bg-white rounded inline-flex max-w-sm`}
      >
        <Tag
          icon={
            <span css={{ color, fill: `${color} !important`, lineHeight: '15px' }}>
              {shortcutIcon}
            </span>
          }
          css={[{ color }, lightGray5bg]}
          onClick={disabled ? undefined : handleControlClick(id, type)}
          className={`w-full py-1 px-2 rounded ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${isActive ? 'active' : ''}`}
          rightIcon={rightIcon}
        >
          <span className="block w-full text-center text-sm">{text}</span>
        </Tag>
      </div>
    </Fragment>
  );
};

type TControlsGroupProps = Pick<ControlSpec, 'id' | 'text' | 'intent' | 'type'> & {
  controls: Pick<ControlSpec, 'id' | 'text' | 'intent' | 'shortcutIcon' | 'type'>[];
  onClick: (id: string, type: ControlType) => void;
  activeControlId?: string;
  vertical: boolean;
  controlsDisabled?: boolean;
};

const ControlsGroup: React.FC<TControlsGroupProps> = ({
  controls,
  onClick,
  activeControlId,
  id,
  text,
  intent,
  vertical,
  controlsDisabled,
  type,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleControlClick = useCallback(
    (controlId: string, type: ControlType) => {
      onClick(controlId, type);
      setOpen(false);
    },
    [onClick, setOpen]
  );

  const menu = useMemo(() => {
    return (
      <div
        className="p-4 flex flex-col"
        css={{ minWidth: '250px', backgroundColor: `${Colors.GRAY1}7a` }}
      >
        {controls.map(({ id, ...rest }, idx) => {
          return (
            <DecisionControl
              key={id}
              id={id}
              {...rest}
              disabled={controlsDisabled}
              onClick={handleControlClick}
              isActive={activeControlId === id}
              vertical
              isFirst={idx === 0}
            />
          );
        })}
      </div>
    );
  }, [controls, activeControlId, controlsDisabled, handleControlClick]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      content={menu}
      minimal
      position={Position.TOP}
      usePortal={false}
    >
      <DecisionControl
        id={id}
        text={text}
        intent={intent}
        onClick={() => setOpen(!isOpen)}
        isActive={some(propEq('id', activeControlId), controls)}
        isFirst={false}
        vertical={vertical}
        rightIcon={isOpen ? IconNames.CROSS : IconNames.CHEVRON_UP}
        type={type}
        disabled={false}
      />
    </Popover>
  );
};

export type ControlSpec = {
  id: string;
  text: ReactNode;
  intent: Intent;
  shortcutIcon?: ReactElement;
  type: ControlType;
  subControls?: ControlSpec[];
  rightIcon?: IconName | MaybeElement;
};

interface IDecisionsControlsProps {
  onClick: (controlId: string, controlType: ControlType) => void;
  controls: ControlSpec[];
  vertical?: boolean;
  activeControl?: string;
  disabled?: boolean;
}

const DecisionControls: FC<IDecisionsControlsProps> = ({
  activeControl,
  disabled,
  onClick,
  vertical = true,
  controls,
}) => {
  return (
    <ButtonGroup vertical={vertical} className="p-2 w-full" css={popoverCss}>
      {controls.map((control, idx) => {
        const { subControls, id, ...rest } = control;

        return subControls == null ? (
          <DecisionControl
            key={id}
            id={id}
            {...rest}
            isFirst={idx === 0}
            isActive={activeControl === id}
            onClick={onClick}
            vertical={vertical}
            disabled={disabled}
          />
        ) : (
          <ControlsGroup
            key={id}
            id={id}
            {...rest}
            controls={subControls}
            activeControlId={activeControl}
            onClick={onClick}
            vertical={vertical}
            controlsDisabled={disabled}
          />
        );
      })}
    </ButtonGroup>
  );
};

export default DecisionControls;
