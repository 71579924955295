/** @jsx jsx **/
import { Button, Card, Tag } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import React from 'react';
import { UserKeyword } from '../../common/types';
import { useI18n, useKeywordTagStyles } from '../../lib/utils';
import { useTheme } from '../settings/theme_context';

interface IKeywordsCardProps {
  keyword: UserKeyword;
  onDelete: () => void;
  onEdit: () => void;
}

const marginBottomCss = css`
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const KeywordsCard: React.FC<IKeywordsCardProps> = ({ keyword, onDelete, onEdit }) => {
  const { domain, excluded, included, title, variable } = keyword;
  const i18n = useI18n();
  const { statusColors } = useTheme();
  const [desiredKeywordCss, undesiredKeywordCss] = useKeywordTagStyles();

  return (
    <Card className="p-0" css={marginBottomCss}>
      <div className="border-b border-solid p-5 flex">
        <div className="text-lg flex-grow">
          <span className="font-bold">{title}</span>
        </div>
        <div>
          <Button minimal icon="edit" onClick={onEdit} title={i18n._(t`Edit`)} />
          <Button minimal icon="trash" onClick={onDelete} title={i18n._(t`Delete`)} />
        </div>
      </div>
      <div className="flex">
        <div className="flex-1">
          <div className="border-r border-b border-solid p-5">
            <span className="font-bold" css={{ color: statusColors.acceptedPrimary }}>
              <Trans>Desired keywords</Trans>:
            </span>
          </div>
          <div className="flex-grow flex flex-wrap border-r border-solid p-5">
            {included.map((keyword, keywordIdx) => (
              <Tag className="mr-1 mb-1" css={desiredKeywordCss} key={keywordIdx}>
                {keyword}
              </Tag>
            ))}
          </div>
        </div>
        <div className="flex-1">
          <div className="border-b border-solid p-5">
            <span className="font-bold" css={{ color: statusColors.rejectedPrimary }}>
              <Trans>Undesired keywords</Trans>:
            </span>
          </div>
          <div className="flex-grow flex flex-wrap p-5">
            {excluded.map((keyword, keywordIdx) => (
              <Tag className="mr-1 mb-1" css={undesiredKeywordCss} key={keywordIdx}>
                {keyword}
              </Tag>
            ))}
          </div>
        </div>
      </div>
      {domain && (
        <div className="flex border-t border-solid p-5">
          <div>
            <span className="font-bold">
              <Trans>Domain:</Trans>
            </span>{' '}
            {domain}
          </div>
          {variable && (
            <div className="ml-5">
              <span className="font-bold">
                <Trans>Variable:</Trans>
              </span>{' '}
              {variable}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default KeywordsCard;
