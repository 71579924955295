/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { get, isEmpty } from 'lodash/fp';
import React, {
  memo,
  forwardRef,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useCallback,
} from 'react';
import { TextContentItem } from 'pdfjs-dist';
import { Page } from 'react-pdf';
import { PDFPageItem } from 'react-pdf/dist/Page';
import DocTextLayer from './unsafe_doc_page_text_layer';
import PDFTextHighlightsLayer from './pdf_text_highlights_layer';
import { Spinner } from '@blueprintjs/core';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import withIntersectionObserver, {
  WithIntersectionObserverWrapperState,
} from '../hoc/with_intersection_observer';
import { PageRendererProps } from './doc_pages';
import SearchMatchHighlighter from './search_match_hightlighter';
import { DocActiveRanges, PDFTextRangeType } from '../../apollo/pdf_viewer_state';
import { StrFragmentSpec } from '../../lib/decorated_string';

const activePDFTextRangesQuery = loader('../../graphql/local/get_active_pdf_text_ranges.gql');
const setActivePDFDocPageMutation = loader('../../graphql/local/set_active_pdf_doc_page.gql');

const PAGE_SPACING = 15; // amount of space between pages

const pageCss = css`
  padding-top: 1px;
  &:not(:last-child) {
    margin-bottom: ${PAGE_SPACING}px;
  }

  .react-pdf__Page__canvas {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
      0 0 0 rgba(16, 22, 26, 0);
  }
`;
type TPageHighlightedRanges = { [itemIdx: number]: StrFragmentSpec[] };

const HIGHLIGHTED_RANGES_TYPES = [
  /* PDFTextRangeType.selection, TODO: temporary disabled */
  PDFTextRangeType.search,
  PDFTextRangeType.custom,
];

const DocPage = memo(
  forwardRef((props: PageRendererProps & WithIntersectionObserverWrapperState, ref: any) => {
    const {
      pageNum,
      scale,
      height,
      isIntersecting,
      intersectionRatio,
      customRangeDecorator,
      width,
      rotationAngle,
    } = props;
    const [setActivePDFDocPage] = useMutation(setActivePDFDocPageMutation);
    const [shouldRender, setShouldRender] = useState(false);
    const [contentItems, setContentItems] = useState<TextContentItem[]>([]);
    const [PageProxy, setPageProxy] = useState<null | PDFPageItem>(null);

    useEffect(() => {
      if (isIntersecting && !shouldRender) {
        setShouldRender(true);
      }
    }, [setShouldRender, isIntersecting, shouldRender]);

    useLayoutEffect(() => {
      if (shouldRender) {
        setShouldRender(false);
      }
    }, [setShouldRender, scale]);

    const pageScale = useMemo(() => {
      if (PageProxy == null) {
        return 1;
      }

      let pageScale = 1;

      // Passing scale explicitly null would cause the page not to render
      const scaleWithDefault = scale === null ? 1 : scale;

      // If width/height is defined, calculate the scale of the page so it could be of desired width.
      if (height) {
        const viewport = PageProxy.getViewport({ scale: 1 });
        pageScale = height / viewport.height;
      } else if (width) {
        const viewport = PageProxy.getViewport({ scale: 1 });
        pageScale = width / viewport.width;
      }

      return scaleWithDefault * pageScale;
    }, [height, scale, width, PageProxy]);

    const pageViewport = useMemo(() => {
      if (PageProxy == null) return null;
      return PageProxy.getViewport({ scale: pageScale });
    }, [PageProxy, pageScale]);

    const handlePageLoad = useCallback(
      async (Page) => {
        setPageProxy(Page);
        const content = await Page.getTextContent();
        setContentItems(content.items);
      },
      [setPageProxy, setContentItems]
    );

    const getPageFontData = useCallback(
      (fontName) => {
        try {
          const font = (PageProxy!.commonObjs as any).get(fontName);
          return font;
        } catch (error) {
          return null;
        }
      },
      [PageProxy]
    );

    const { data: activeRangesData, loading: loadingRanges } = useQuery<{
      activeRanges: DocActiveRanges;
    }>(activePDFTextRangesQuery);

    const highlightedRangesByType = useMemo<
      Record<PDFTextRangeType, TPageHighlightedRanges>
    >(() => {
      if (loadingRanges) return {} as Record<PDFTextRangeType, TPageHighlightedRanges>;

      return HIGHLIGHTED_RANGES_TYPES.reduce((acc, rangeType) => {
        const ranges = get(['activeRanges', rangeType, pageNum], activeRangesData) ?? null;
        if (ranges !== null) {
          acc[rangeType] = ranges;
        }
        return acc;
      }, {} as Record<PDFTextRangeType, TPageHighlightedRanges>);
    }, [activeRangesData, pageNum, loadingRanges]);

    // when more than half of this page is visible, report it as active
    useEffect(() => {
      isIntersecting && intersectionRatio > 0.5 && setActivePDFDocPage({ variables: { pageNum } });
    }, [setActivePDFDocPage, isIntersecting, intersectionRatio, pageNum]);

    return (
      <div ref={ref} css={pageCss} className="pdf_page_wrapper" style={{ minHeight: height }}>
        {shouldRender && (
          <Page
            pageNumber={pageNum}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            height={height}
            width={width}
            scale={scale}
            onLoadSuccess={handlePageLoad}
            rotate={rotationAngle}
            loading={<Spinner className="w-full h-full" size={150} />}
          >
            {/* renders our implementation of text items */}
            {pageViewport && !isEmpty(contentItems) && (
              <DocTextLayer
                items={contentItems}
                scale={pageScale}
                viewport={pageViewport}
                getFontData={getPageFontData}
                pageNum={pageNum}
              />
            )}
            {/* highlighted ranges layers */}
            {pageViewport && !isEmpty(contentItems) && (
              <React.Fragment>
                {HIGHLIGHTED_RANGES_TYPES.map((rangeType) => {
                  const highlightedRanges = highlightedRangesByType[rangeType];
                  if (isEmpty(highlightedRanges)) return null;
                  if (rangeType === PDFTextRangeType.custom && !customRangeDecorator) return null;

                  const rangeHighlighter =
                    rangeType === PDFTextRangeType.custom
                      ? customRangeDecorator!
                      : SearchMatchHighlighter;

                  return (
                    <PDFTextHighlightsLayer
                      key={rangeType}
                      highlightedRanges={highlightedRanges}
                      items={contentItems}
                      scale={pageScale}
                      pageNum={pageNum}
                      viewport={pageViewport}
                      getPageFontData={getPageFontData}
                      highlighter={rangeHighlighter}
                    />
                  );
                })}
              </React.Fragment>
            )}
          </Page>
        )}
      </div>
    );
  })
);

const IntersectionAwareDocPage = withIntersectionObserver<PageRendererProps>({
  root: '.pages-container .document-pages',
  threshold: [0.1, 0.49, 0.51],
})(DocPage);

export default IntersectionAwareDocPage;
