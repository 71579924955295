/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { Button } from '@blueprintjs/core';
import EditorItem from './editor_item';
import { PrismaEditorItem } from '../../common/types';

interface IEditorItemsListProps {
  caption: ReactNode;
  items: PrismaEditorItem[];
  sumSectionTitle: ReactNode;
  sumSectionValue: number;
  isInvalid?: boolean;
  onItemUpdate: (id, stateObj: object) => void;
  onItemAdd: () => void;
  onItemRemove: (id: string) => void;
}

const EditorItemsList: React.FC<IEditorItemsListProps> = ({
  items,
  caption,
  sumSectionTitle,
  sumSectionValue,
  isInvalid,
  onItemUpdate,
  onItemAdd,
  onItemRemove,
}) => {
  return (
    <div className="px-4 py-2 mb-2">
      <div className="flex flex-row justify-between items-center">
        <div>
          {sumSectionTitle}: <span className="font-bold">{sumSectionValue}</span>
        </div>
        <Button
          onClick={onItemAdd}
          className="px-8 my-2"
          text={
            <span>
              <Trans>Add</Trans> <span className="lowercase">{caption}</span>
            </span>
          }
        />
      </div>
      {items.map((item) => {
        return (
          <EditorItem
            key={item.id}
            id={item.id}
            caption={caption}
            title={item.title}
            value={item.value}
            isInvalid={isInvalid}
            onItemUpdate={onItemUpdate}
            onRemove={onItemRemove}
          />
        );
      })}
    </div>
  );
};

export default EditorItemsList;
