import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useDidUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const canEffectRef = useRef<boolean>(false);

  useEffect(() => {
    if (canEffectRef.current) {
      return effect();
    }
    canEffectRef.current = true;
  }, deps);
}

export default useDidUpdateEffect;
