declare global {
  interface Navigator {
    msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
  }
}

export default function saveFileBlob(blob: Blob, fileName: string): void {
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName);
  } else {
      var link = document.createElement("a");

      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }

}
