/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { AnchorButton, Button, Divider, Icon, IconSize, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React, { Fragment, ReactNode } from 'react';
import { DecisionFilter } from '../../apollo/screening_state';
import { screeningListColHeaderHeight } from '../../common/styles';
import CountTag from '../common/count_tag';
import { useCurrCallback } from '../../lib/utils';
import useSetDecisionFilter from '../hooks/use_set_decision_filter';
import { ScreeningListMode, StageType } from '../../common/types';
import SendDecisionsButton from './send_decisions_button';

const reRankLabelCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7px;
`;

interface IScreeningHeaderProps {
  stageType: StageType;
  stageId: string;
  decisionFilter: DecisionFilter;
  onApplyNewRanking: () => void;
  reRankEnabled: boolean;
  excludedCount: number;
  includedCount: number;
  toReviewCount: number;
  postponedCount: number;
  totalCount: number;
  highlightsVisible: boolean;
  onHighlightsToggle: () => void;
  screeningListMode: ScreeningListMode;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  openInstructions: () => void;
  loadingReferences?: boolean;
  filteredCount?: number;
  showReRank?: boolean;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  loadingCounts?: boolean;
  openFocusMode?: () => void;
}

const ScreeningHeader: React.FC<IScreeningHeaderProps> = ({
  stageId,
  stageType,
  decisionFilter,
  loadingReferences,
  onApplyNewRanking,
  excludedCount,
  filteredCount,
  includedCount,
  toReviewCount,
  postponedCount,
  totalCount,
  showReRank,
  leftElement,
  rightElement,
  loadingCounts,
  reRankEnabled,
  openFocusMode,
  highlightsVisible,
  onHighlightsToggle,
  screeningListMode,
  onSendDecisions,
  openInstructions,
}) => {
  const setDecisionFilter = useSetDecisionFilter();
  const handleTagClick = useCurrCallback(
    (filterVal: DecisionFilter, _evt) => {
      setDecisionFilter(filterVal);
    },
    [setDecisionFilter]
  );
  const isToScreenMode = screeningListMode === ScreeningListMode.ToScreen;

  return (
    <div className="flex flex-row flex-none items-center border" css={screeningListColHeaderHeight}>
      {leftElement}
      <div className="flex flex-row flex-grow ml-4 mr-6 overflow-hidden items-center">
        <CountTag
          active={decisionFilter === 'all'}
          className="mr-4"
          count={filteredCount ?? totalCount}
          onClick={loadingReferences ? undefined : handleTagClick('all')}
          title={filteredCount == null ? <Trans>All references</Trans> : <Trans>Filtered</Trans>}
          loading={loadingCounts}
        />
        <span className="mr-4">:</span>
        <CountTag
          active={decisionFilter === 'in'}
          count={includedCount}
          onClick={loadingReferences ? undefined : handleTagClick('in')}
          className="mr-4"
          title={<Trans>In</Trans>}
          inclusionStatus="included"
          loading={loadingCounts}
        />
        {isToScreenMode && (
          <React.Fragment>
            <CountTag
              active={decisionFilter === 'to_review'}
              count={toReviewCount}
              onClick={loadingReferences ? undefined : handleTagClick('to_review')}
              className="mr-4"
              title={<Trans>To review</Trans>}
              loading={loadingCounts}
            />
            {[StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(
              stageType
            ) && (
              <CountTag
                active={decisionFilter === 'postponed'}
                className="mr-4"
                count={postponedCount}
                onClick={loadingReferences ? undefined : handleTagClick('postponed')}
                title={<Trans>Postponed</Trans>}
                loading={loadingCounts}
              />
            )}
          </React.Fragment>
        )}
        <CountTag
          active={decisionFilter === 'out'}
          className="mr-4"
          count={excludedCount}
          onClick={loadingReferences ? undefined : handleTagClick('out')}
          inclusionStatus="excluded"
          title={<Trans>Out</Trans>}
          loading={loadingCounts}
        />
      </div>
      {screeningListMode === ScreeningListMode.ToScreen && (
        <React.Fragment>
          <SendDecisionsButton className="mr-2 w-40" stageId={stageId} onSend={onSendDecisions} />
          <Divider className="h-full m-0" />
        </React.Fragment>
      )}
      {[StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(stageType) && (
        <Tooltip
          className="flex h-full"
          content={
            highlightsVisible ? (
              <Trans>Click to hide highlighted keywords</Trans>
            ) : (
              <Trans>Click to highlight keywords</Trans>
            )
          }
        >
          <Button
            className="h-full"
            minimal
            intent={highlightsVisible ? Intent.PRIMARY : Intent.NONE}
            icon={highlightsVisible ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
            text={<Trans>Keywords</Trans>}
            onClick={onHighlightsToggle}
          />
        </Tooltip>
      )}
      {isToScreenMode && showReRank && (
        <Fragment>
          <Divider className="h-full m-0" />
          <Tooltip className="flex h-full" content={<Trans>Apply new ranking</Trans>}>
            <AnchorButton
              className={`h-full relative ${reRankEnabled ? 'px-6' : ''}`}
              disabled={!reRankEnabled}
              icon={<Icon icon={IconNames.REFRESH} className="m-0 p-2" iconSize={16} />}
              text={<span css={reRankLabelCss}>AI</span>}
              onClick={onApplyNewRanking}
              minimal
              intent={Intent.NONE}
            />
          </Tooltip>
        </Fragment>
      )}
      <Divider className="h-full m-0" />
      <Tooltip className="flex h-full" content={<Trans>Focus mode</Trans>}>
        <AnchorButton
          className="h-full flex-none"
          icon={<Icon className="p-2" icon={IconNames.FULLSCREEN} iconSize={16} />}
          onClick={openFocusMode}
          disabled={openFocusMode == null}
          large
          minimal
        />
      </Tooltip>
      {rightElement}
      <Divider className="m-0 h-full" />
      <Tooltip content={<Trans>Inclusion and Exclusion Instructions</Trans>}>
        <AnchorButton
          className="flex h-full"
          icon={<Icon className="p-2" icon={IconNames.MANUAL} iconSize={16} />}
          onClick={openInstructions}
          disabled={openInstructions == null}
          large
          minimal
        />
      </Tooltip>
    </div>
  );
};

export default ScreeningHeader;
