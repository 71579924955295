import { Button } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { map } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import {
  FormTemplate,
  FTScreeningFormData,
  InclusionExclusionCriteriaFormData,
} from '../../common/types';
import LoadCriteriaDialog from './load_criteria_dialog';
import SaveCriteriaDialog from './save_criteria_dialog';

interface ITemplateImportExportControlsProps {
  templates: FormTemplate[];
  onImport: (
    templateId: string,
    template: InclusionExclusionCriteriaFormData | FTScreeningFormData
  ) => Promise<any>;
  onExport: (name: string) => Promise<any>;
  currentFormTemplateId: string | null;
  exportDisabled?: boolean;
  importDisabled?: boolean;
}

const TemplateImportExportControls: React.FC<ITemplateImportExportControlsProps> = ({
  templates,
  onImport,
  onExport,
  currentFormTemplateId,
  importDisabled,
  exportDisabled,
}) => {
  const templateNames = useMemo(() => map('name', templates), [templates]);
  const [loadCriteriaDialogOpen, setLoadCriteriaDialogOpen] = useState(false);
  const [saveCriteriaDialogOpen, setSaveCriteriaDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        className="mr-3"
        text={<Trans>Load template</Trans>}
        onClick={() => setLoadCriteriaDialogOpen(true)}
        disabled={importDisabled}
      />
      <Button
        text={<Trans>Save template</Trans>}
        onClick={() => setSaveCriteriaDialogOpen(true)}
        disabled={exportDisabled}
      />
      <LoadCriteriaDialog
        isOpen={loadCriteriaDialogOpen}
        onClose={() => setLoadCriteriaDialogOpen(false)}
        onLoad={onImport}
        currentFormTemplateId={currentFormTemplateId}
        templates={templates}
      />
      <SaveCriteriaDialog
        isOpen={saveCriteriaDialogOpen}
        onClose={() => setSaveCriteriaDialogOpen(false)}
        onSave={onExport}
        existingSets={templateNames}
      />
    </React.Fragment>
  );
};

export default TemplateImportExportControls;
