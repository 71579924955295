/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Spinner } from '@blueprintjs/core';
import { isEmpty } from 'lodash/fp';
import React, { Fragment } from 'react';
import { TReferenceData } from '.';
import {
  SearchAndDesiredUndesiredKeywords,
  EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS,
} from '../../lib/criteria_utils';
import DecisionAndCommentIndicator from './decision_and_comment_indicator';
import PreviewHeader from './preview_header';
import ReferencePreview from './reference_preview';
import { fancyScrollCss } from '../../common/styles';
import { ReferenceComment, ScreeningTag, User } from '../../common/types';

interface PreviewColumnProps {
  highlightsVisible: boolean;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  withDecisionIndicator: boolean;
  onUnreview: () => void;
  onToggle: () => void;
  onSaveEditedComments?: (data: { comment: string; tags: string[] }) => void;
  reference?: TReferenceData;
  exclusionReason?: string;
  comment?: string;
  inclusionStatus?: string;
  loading?: boolean;
  selectedTags?: string[];
  screeningTags?: ScreeningTag[];
  previousComments?: (Pick<ReferenceComment, 'id' | 'comment'> & {
    team_member: { id: string; user: Pick<User, 'id' | 'name'> };
  })[];
}

const PreviewColumn: React.FC<PreviewColumnProps> = ({
  highlightsVisible,
  keywordsData,
  withDecisionIndicator,
  onSaveEditedComments,
  onUnreview,
  onToggle,
  reference,
  exclusionReason,
  comment,
  inclusionStatus,
  loading,
  selectedTags,
  screeningTags,
  previousComments,
}) => {
  return (
    <div className="h-full flex flex-col overflow-auto" data-testid="preview-column">
      <PreviewHeader onToggle={onToggle} />
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex-grow overflow-auto" css={fancyScrollCss}>
          {!isEmpty(reference) && (
            <Fragment>
              {withDecisionIndicator && (
                <DecisionAndCommentIndicator
                  comment={comment}
                  decision={inclusionStatus}
                  decisionReason={exclusionReason}
                  onSaveEditedComments={onSaveEditedComments}
                  onUnreview={onUnreview}
                  selectedTags={selectedTags}
                  screeningTags={screeningTags}
                  previousComments={previousComments}
                />
              )}
              <ReferencePreview
                keywordsData={
                  highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
                }
                reference={reference!}
              />
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default PreviewColumn;
