/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InputGroup, Button, Checkbox, Icon, MenuItem, Position } from '@blueprintjs/core';
import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { ProjectsOwnerFilter, useCurrCallback } from '../../lib/utils';
import { Trans, t } from '@lingui/macro';
import i18n from '../../i18n';
import PageContentWrapper from '../common/page_content_wrapper';
import { Select } from '@blueprintjs/select';
import { debounce } from 'lodash/fp';
import { TProjectsListSortOrder } from '../../common/types';

const ProjectsSortSelect = Select.ofType<TProjectsListSortOrder>();

interface ProjectsListFiltersState {
  searchPhrase: string;
}

function getInitialState(searchPhrase: string): ProjectsListFiltersState {
  return {
    searchPhrase,
  };
}

function getSortOrderLabel(sortOrder: TProjectsListSortOrder) {
  return sortOrder === 'latest_first' ? <Trans>Latest first</Trans> : <Trans>From A to Z</Trans>;
}

interface IProjectsListFiltersProps {
  searchText: string;
  onUpdateSearchText: (projectsSearchText: string) => void;
  activeSortOrder: TProjectsListSortOrder;
  updateListSortOrder: (newSortOrder: TProjectsListSortOrder) => void;
  allCollapsed: boolean;
  toggleCollapseAllFolders?: () => void;
  projectsOwnerFilter?: ProjectsOwnerFilter;
  onChangeProjectsOwnerFilter?: (owner: ProjectsOwnerFilter) => void;
}

const ProjectsListFilters: React.FC<IProjectsListFiltersProps> = ({
  projectsOwnerFilter,
  onChangeProjectsOwnerFilter,
  searchText,
  onUpdateSearchText,
  activeSortOrder,
  updateListSortOrder,
  allCollapsed,
  toggleCollapseAllFolders,
}) => {
  const [state, setState] = useState<ProjectsListFiltersState>(getInitialState(searchText));
  const { searchPhrase } = state;

  const handleChangeProjectsOwnerFilter = useCallback(
    (evt: FormEvent<HTMLInputElement>) =>
      onChangeProjectsOwnerFilter?.(
        evt.currentTarget.checked ? ProjectsOwnerFilter.Member : ProjectsOwnerFilter.All
      ),
    [onChangeProjectsOwnerFilter]
  );

  const renderSelectOption = useCurrCallback((selected, sortOrder, { handleClick }) => {
    return (
      <MenuItem
        key={sortOrder}
        onClick={handleClick}
        text={getSortOrderLabel(sortOrder)}
        active={selected === sortOrder}
      />
    );
  }, []);

  const handleSortOrderChange = useCallback(
    (newSortOrder: TProjectsListSortOrder) => {
      updateListSortOrder(newSortOrder);
    },
    [updateListSortOrder]
  );

  const handleUpdateSearchText = useCallback(
    debounce(300, (searchPhrase: string) => onUpdateSearchText(searchPhrase)),
    [onUpdateSearchText]
  );

  useEffect(() => {
    handleUpdateSearchText(searchPhrase);
  }, [handleUpdateSearchText, searchPhrase]);

  return (
    <PageContentWrapper>
      <div className="overflow-auto p-3 mt-4 mr-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row w-1/3">
            <InputGroup
              autoFocus
              fill
              type="search"
              leftIcon={IconNames.SEARCH}
              name="search"
              placeholder={i18n._(t`Search`)}
              value={searchPhrase}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                setState({ searchPhrase: evt.target.value })
              }
            />
          </div>
          <div className="flex flex-row justify-between items-center">
            {onChangeProjectsOwnerFilter && (
              <Checkbox
                className="mb-0"
                label={i18n._(t`Show only my projects`)}
                checked={projectsOwnerFilter === ProjectsOwnerFilter.Member}
                onChange={handleChangeProjectsOwnerFilter}
              />
            )}
            <Button
              minimal
              className="mx-4"
              icon={allCollapsed ? IconNames.EXPAND_ALL : IconNames.COLLAPSE_ALL}
              text={allCollapsed ? i18n._(t`Expand all`) : i18n._(t`Collapse all`)}
              onClick={toggleCollapseAllFolders}
            />
            <ProjectsSortSelect
              filterable={false}
              items={['latest_first', 'alphabetical']}
              itemRenderer={renderSelectOption(activeSortOrder)}
              onItemSelect={handleSortOrderChange}
              popoverProps={{ boundary: 'viewport', position: Position.BOTTOM }}
            >
              <Button minimal className="px-2" rightIcon={IconNames.CARET_DOWN}>
                <Icon icon={IconNames.SORT} />
                <span className="ml-2">{getSortOrderLabel(activeSortOrder)}</span>
              </Button>
            </ProjectsSortSelect>
          </div>
        </div>
      </div>
    </PageContentWrapper>
  );
};

export default ProjectsListFilters;
