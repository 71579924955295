/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import {
  Button,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuItem,
  MenuDivider,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans } from '@lingui/macro';
import { memoize, random } from 'lodash/fp';
import { useKeycloak } from '../keycloak';
import { AppContentProps, User } from '../common/types';
import ColorSettingsDialog from './settings/color_settings_dialog';
import FeedbackDialog, { Feedback } from './feedback_dialog';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import htmlToImage from 'html-to-image';
import AppToaster from '../lib/toaster';
import { useI18n } from '../lib/utils';

const SendFeedbackMutation = gql`
  mutation SendFeedback($feedback: FeedbackInput!) {
    sendUserFeedback(feedback: $feedback) {
      sent
      task_id
    }
  }
`;

const AVA_COLORS = [
  Colors.VERMILION1,
  Colors.ROSE1,
  Colors.VIOLET1,
  Colors.INDIGO1,
  Colors.TURQUOISE1,
  Colors.COBALT1,
  Colors.FOREST1,
  Colors.LIME1,
  Colors.GOLD1,
  Colors.SEPIA1,
];

const avaCss = css`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const headerCss = css`
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.DARK_GRAY5};
  border: 1px solid ${Colors.GRAY1};
`;

// Memoized so in one session particular user will have always same background.
const getUserBgIndex = memoize((_userId: string) => random(0, AVA_COLORS.length - 1));

type UserAvatarUser = Pick<User, 'id' | 'firstName' | 'lastName'>;

export const UserAvatar: React.FC<{ user: UserAvatarUser | null }> = ({ user }) => {
  const i18n = useI18n();
  const initials = useMemo(() => {
    if (user == null) return '-';

    return `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
  }, [user]);

  const bg = useMemo(() => getUserBgIndex(user?.id ?? '-'), [user]);

  return (
    <div
      css={avaCss}
      className="cursor-pointer text-white flex items-center justify-center"
      style={{ backgroundColor: AVA_COLORS[bg] }}
      title={user ? `${user.firstName} ${user.lastName}` : i18n._(t`Removed user`)}
      data-testid="user-avatar"
    >
      {initials}
    </div>
  );
};

interface IHeader2Props extends AppContentProps {
  className?: string;
}

const Header2: React.FC<IHeader2Props> = ({ children, className, history }) => {
  const { logout, user, isItAdmin } = useKeycloak();
  const [sendFeedback] = useMutation(SendFeedbackMutation);
  const [colorSettingsOpen, setColorSettingsOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const handleSendFeedback = useCallback(
    async (feedback: Feedback) => {
      const { withScreenshot, text, feedbackType } = feedback;
      const feedbackData = {
        userId: user.id,
        text,
        feedbackType,
        timestamp: new Date(Date.now()).toString(),
        host: window.location.hostname,
        url: window.location.href,
      };
      let feedbackPromise: Promise<any>;

      if (withScreenshot) {
        const screenshotData = await htmlToImage.toPng(document.body);
        feedbackPromise = sendFeedback({
          variables: { feedback: { ...feedbackData, screenshotData } },
        });
      } else {
        feedbackPromise = sendFeedback({ variables: { feedback: feedbackData } });
      }

      return feedbackPromise
        .then(() =>
          AppToaster.show({ message: <Trans>Feedback was sent</Trans>, intent: Intent.SUCCESS })
        )
        .catch((err) =>
          AppToaster.show({
            message: (
              <span>
                <Trans>Feedback was not sent</Trans>: {err.message}
              </span>
            ),
            intent: Intent.WARNING,
          })
        );
    },
    [sendFeedback, user]
  );

  return (
    <header
      css={headerCss}
      className={`flex flex-row flex-no-wrap items-center ${className ?? ''}`}
    >
      <div className="flex-1 flex items-center h-full">{children}</div>
      {!isItAdmin && (
        <div className="flex-none ml-4">
          <Tooltip content={<Trans>Keywords setup</Trans>}>
            <Button
              minimal
              icon={<Icon icon={IconNames.HIGHLIGHT} color={Colors.LIGHT_GRAY1} />}
              onClick={() => history.push('/keywords')}
            />
          </Tooltip>
        </div>
      )}
      <div className="flex-none mx-4">
        <Popover minimal={true} position={Position.BOTTOM_RIGHT}>
          <UserAvatar user={user} />
          <Menu>
            <MenuItem
              icon={IconNames.STYLE}
              onClick={() => setColorSettingsOpen(true)}
              text={<Trans>Color settings</Trans>}
            />
            <MenuDivider />
            <MenuItem text={<Trans>Feedback</Trans>} onClick={() => setFeedbackDialogOpen(true)} />
            <MenuDivider />
            <MenuItem icon="log-out" onClick={() => logout()} text={<Trans>Log out</Trans>} />
          </Menu>
        </Popover>
      </div>
      <ColorSettingsDialog isOpen={colorSettingsOpen} onClose={() => setColorSettingsOpen(false)} />
      <FeedbackDialog
        isOpen={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        onSend={handleSendFeedback}
      />
    </header>
  );
};

export default Header2;
