import React, { ComponentPropsWithRef } from 'react';
import { assign } from 'lodash/fp';

interface TextHighlightProps extends ComponentPropsWithRef<any> {
  text: string;
  highlightColor: string;
}

const TextHighlight = (props: TextHighlightProps) => {
  const { text, style, highlightColor, ...rest } = props;
  return (
    <span style={assign({ backgroundColor: props.highlightColor }, props.style)} {...rest}>
      {props.text}
    </span>
  );
};

export default TextHighlight;
