/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useQuery } from '@apollo/react-hooks';
import { Icon, Spinner } from '@blueprintjs/core';
import gql from 'graphql-tag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose, find, get, last, propEq } from 'lodash/fp';
import { AppContentProps, GraphQLAggregateData, ProjectsFolder, Stage } from '../../common/types';
import { useKeycloak } from '../../keycloak';
import ErrorScreen from '../common/error_screen';
import { fancyScrollCss } from '../../common/styles';
import ListHeader from './list_header';
import StageCard from './stage_card';
import CurrentStageColumn, { currentStageColumnHeaderCss } from './current_stage_column';
import { IconNames } from '@blueprintjs/icons';

const projectDataQuery = gql`
  query ProjectStages($projectId: uuid!, $userId: String!) {
    project: project_by_pk(id: $projectId) {
      id
      name
      folder {
        id
        name
      }
      stages(order_by: { order_number: asc }) {
        id
        name
        type
        tasks_by_status(where: { team_member: { user_id: { _eq: $userId } } }) {
          inclusion_status
          count
        }
        to_screen_aggregate: tasks_aggregate(
          where: {
            team_member: { user_id: { _eq: $userId } }
            completed: { _eq: false }
            deleted_at: { _is_null: true }
          }
        ) {
          aggregate {
            count
          }
        }
        completed_aggregate: tasks_aggregate(
          where: {
            team_member: { user_id: { _eq: $userId } }
            completed: { _eq: true }
            deleted_at: { _is_null: true }
          }
        ) {
          aggregate {
            count
          }
        }
        conflicts_aggregate: tasks_aggregate(
          where: {
            task_type: { _eq: conflict_resolution }
            completed: { _eq: false }
            deleted_at: { _is_null: true }
            is_draft: { _eq: false }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

type IProjectStage = Pick<
  Stage,
  'id' | 'name' | 'completed' | 'type' | 'tasks_by_status' | 'tasks'
> & {
  to_screen_aggregate: GraphQLAggregateData;
  conflicts_aggregate: GraphQLAggregateData;
  completed_aggregate: GraphQLAggregateData;
};

interface IProjectData {
  id: string;
  name: string;
  stages: IProjectStage[];
  folder?: Pick<ProjectsFolder, 'id' | 'name'>;
}

interface IStagesListProps extends AppContentProps {}

const StagesList: React.FC<IStagesListProps> = ({ match }) => {
  const {
    user: { id: userId },
  } = useKeycloak();

  const { projectId } = match.params;
  const { data, loading, error, refetch } = useQuery<{ project: IProjectData }>(projectDataQuery, {
    variables: { projectId, userId },
    fetchPolicy: 'network-only',
    pollInterval: 1500,
  });

  const [currentStageId, setCurrentStageId] = useState<string | null>(null);

  const {
    name: projectName,
    stages,
    folder,
  } = data?.project ?? {
    stages: [] as IProjectStage[],
    name: '',
  };

  // Set stage to the first active stage or to last one
  useEffect(() => {
    if (currentStageId || loading) return;
    const maybeFirstActiveStage = find<IProjectStage>(
      (stage) =>
        stage.to_screen_aggregate.aggregate.count > 0 ||
        stage.conflicts_aggregate.aggregate.count > 0,
      stages
    );
    setCurrentStageId(get('id', maybeFirstActiveStage ?? last(stages)) ?? null);
  }, [currentStageId, loading, setCurrentStageId, stages]);

  const currentStageName = useMemo<string | undefined>(() => {
    if (!currentStageId) return;
    return compose(get('name'), find(propEq('id', currentStageId)))(stages);
  }, [currentStageId, stages]);

  const activateCard = useCallback(
    (id: string) => () => setCurrentStageId(id),
    [setCurrentStageId]
  );

  if (error) {
    return <ErrorScreen error={error} retry={() => refetch()} />;
  }

  return loading ? (
    <Spinner className="h-full" />
  ) : (
    <div className="w-full h-full flex flex-col overflow-auto">
      <div className="flex-none">
        <ListHeader
          title={
            <div className="flex flex-row items-center">
              {projectName}
              {folder ? (
                <div className="flex ml-4 text-gray-500">
                  <Icon icon={IconNames.FOLDER_OPEN} className="mr-1" />
                  <span className="text-xs">{folder?.name}</span>
                </div>
              ) : null}
            </div>
          }
          fixed={false}
          rightElement={
            currentStageName ? (
              <div className="p-6 h-full text-xl" css={currentStageColumnHeaderCss}>
                {currentStageName}
              </div>
            ) : undefined
          }
          className="h-full"
        />
      </div>
      <div className="flex-1 flex flex-row">
        <div
          className="flex-1 overflow-auto mt-8 mx-8"
          css={fancyScrollCss}
          data-testid="stages-list"
        >
          {stages.map(
            ({
              id,
              name,
              type,
              tasks_by_status,
              to_screen_aggregate,
              conflicts_aggregate,
              completed_aggregate,
            }) => (
              <StageCard
                active={currentStageId === id}
                key={id}
                id={id}
                projectId={projectId!}
                name={name}
                type={type}
                tasksByStatus={tasks_by_status}
                toScreenAggregate={to_screen_aggregate}
                conflictsAggregate={conflicts_aggregate}
                completedAggregate={completed_aggregate}
                onActivate={activateCard(id)}
              />
            )
          )}
        </div>
        <CurrentStageColumn stageId={currentStageId} />
      </div>
    </div>
  );
};

export default StagesList;
