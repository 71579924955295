/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { Colors, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { filterOutNotNumbers, textRightCss } from '.';
import { useSetState } from '../../lib/utils';

interface IEditorItemProps {
  id: string;
  caption: ReactNode;
  title: string;
  value: number;
  isInvalid?: boolean;
  onItemUpdate: (id: string, stateObj: object) => void;
  onRemove: (id: string) => void;
}

interface IEditorItemState {
  title: string;
  value: number;
}

const EditorItem: React.FC<IEditorItemProps> = ({
  id,
  caption,
  title: propTitle,
  value: propValue,
  isInvalid,
  onItemUpdate,
  onRemove,
}) => {
  const [state, setState] = useSetState<IEditorItemState>({
    title: propTitle,
    value: propValue,
  });
  const { title, value } = state;

  const handleBlur = () => onItemUpdate(id, { ...state });

  return (
    <div className="p-4 mb-2 border rounded">
      <FormGroup label={caption}>
        <InputGroup
          name="editorItemTitle"
          value={title}
          onChange={(e) => setState({ title: e.target.value })}
          onBlur={handleBlur}
        />
      </FormGroup>

      <div className="justify-between">
        <FormGroup className="block" label={<Trans>Number of records</Trans>}>
          <div className="flex flex-row justify-between items-end">
            <InputGroup
              className="w-1/2"
              css={textRightCss}
              intent={isInvalid ? Intent.DANGER : Intent.PRIMARY}
              name="numberOfRecords"
              type="text"
              value={value.toString()}
              onChange={(e) => setState({ value: Number(filterOutNotNumbers(e.target.value)) })}
              onBlur={handleBlur}
            />

            <button
              className="underline"
              css={{ color: Colors.GRAY1 }}
              onClick={() => onRemove(id)}
            >
              &#x2717; <Trans>remove</Trans>
            </button>
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default EditorItem;
