/** @jsx jsx */
import { Button, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { FC, Fragment } from 'react';
import { TTaskCounts } from '../../../lib/task_helpers';

interface IEmptyStateProps {
  taskCounts: TTaskCounts;
  filtersApplied: boolean;
  onResetReferencesSearch: () => void;
  onGoToReferencesList: () => void;
}

const EmptyState: FC<IEmptyStateProps> = ({
  taskCounts,
  filtersApplied,
  onResetReferencesSearch,
  onGoToReferencesList,
}) => {
  const allTasksReviewed = taskCounts.total !== 0 && taskCounts.toReview === 0;

  return (
    <Fragment>
      {allTasksReviewed ? (
        <NonIdealState
          icon={IconNames.CROWN}
          title={
            <Fragment>
              <Trans>Congratulations!</Trans>
              <br />
              {filtersApplied ? (
                <Trans>All references matching your criteria have been reviewed.</Trans>
              ) : (
                <Trans>All references have been reviewed.</Trans>
              )}
            </Fragment>
          }
          action={
            <Button
              className="rounded"
              onClick={filtersApplied ? onResetReferencesSearch : onGoToReferencesList}
              text={filtersApplied ? <Trans>Clear all filters</Trans> : <Trans>Go to list</Trans>}
            />
          }
        />
      ) : (
        <NonIdealState
          icon={IconNames.DOCUMENT}
          title={<Trans>There are no references in a selected category.</Trans>}
        />
      )}
    </Fragment>
  );
};

export default EmptyState;
