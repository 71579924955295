import React from 'react';
import { Classes } from '@blueprintjs/core';
import PageContentWrapper from './page_content_wrapper';

interface IPageContentProps {
  className?: string;
}

const PageContent: React.FC<IPageContentProps> = ({ children, className }) => (
  <PageContentWrapper className={className}>
    <div className="h-full overflow-auto p-8 pt-1">
      <div className={`h-full flex flex-col overflow-auto ${Classes.ELEVATION_0} bg-white`}>
        {children}
      </div>
    </div>
  </PageContentWrapper>
);

export default PageContent;
