import {
  InMemoryCache,
  defaultDataIdFromObject,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { ApolloCache } from 'apollo-cache';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { LocalState, localStates } from './local_states';
import * as Sentry from '@sentry/browser';
import { ReferenceTaskResultsTypeTmp, StudyPoolTeamMemberCompoundId } from '../common/types';
import { memoize } from 'lodash/fp';
import { getTaskResultCacheKey } from '../lib/task_helpers';

function initLocalStates<T>(cache: ApolloCache<NormalizedCacheObject>, localStates: LocalState[]) {
  localStates.forEach((localState) =>
    cache.writeQuery({
      query: localState.rootQuery,
      data: localState.initial(),
    })
  );
}

function addLocalStates(client: ApolloClient<NormalizedCacheObject>, localStates: LocalState[]) {
  // init states' default values
  initLocalStates(client.cache, localStates);
  // subscribe for reset event
  client.onResetStore(() => Promise.resolve(initLocalStates(client.cache, localStates)));
  // add local state resolvers
  localStates.forEach(
    (localState) => localState.resolvers && client.addResolvers(localState.resolvers)
  );
}

function _getClient(getToken: () => Promise<string>) {
  const getAuthHeader = async () => {
    const token = await getToken();
    return `Bearer ${token}`;
  };

  const authHeader = setContext(async (operation, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: await getAuthHeader(),
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL || `https://${window.location.host}/api/v1/graphql`,
    fetch,
  });

  const wsLink = new WebSocketLink({
    uri:
      process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL ||
      `wss://${window.location.host}/api/v1/graphql`,
    options: {
      lazy: false,
      reconnect: true,
      // FIXME: this sets the header when the connection is initiated, so if the token expires, it
      // will not be updated. I wasn't able to force Auth0 to set shorter token expiration to
      // test it. Also see https://github.com/apollographql/subscriptions-transport-ws/issues/171
      connectionParams: async () => ({
        headers: { Authorization: await getAuthHeader() },
      }),
    },
  });

  const link = split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return (
        def.kind === 'OperationDefinition' && 'operation' in def && def.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const clientCache = new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        // form object is not a collection item, so it doesn't have id or any other uniq field
        // nor does it need one since there is exactly one object of such kind
        case 'FormEditor.Form':
          return 'FormEditor.Form';
        case 'study_pool_team_member':
          const studyPoolTeamMember = object as StudyPoolTeamMemberCompoundId;
          return `${studyPoolTeamMember.study_pool_id}_${studyPoolTeamMember.team_member_id}`;
        case 'reference_task_results_type_tmp':
          const screeningReference = object as ReferenceTaskResultsTypeTmp;
          return screeningReference.reference.id;
        case 'stage_results':
          return 'study_id' in object && 'study_pool_id' in object
            ? `stage_results:${object['study_pool_id']}_${object['study_id']}`
            : defaultDataIdFromObject(object);
        case 'task_result':
          return 'task_id' in object && 'form_id' in object
            ? getTaskResultCacheKey(object['task_id'], object['form_id'])
            : defaultDataIdFromObject(object);
        case 'stage_progress_tmp':
          return 'stage_id' in object ? object['stage_id'] : defaultDataIdFromObject(object);
        default:
          return defaultDataIdFromObject(object);
      }
    },
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            if (extensions?.code === 'BAD_USER_INPUT') return;

            const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

            Sentry.withScope((scope) => {
              scope.setLevel("error");

              scope.addBreadcrumb({
                message,
                category: 'graphqlError',
                data: {
                  location: locations,
                  path,
                },
              });

              Sentry.captureException(new Error(errorMessage));
            });

            console.warn(errorMessage);
          });
        }
        if (networkError) {
          Sentry.captureException(networkError);
          console.warn(`[Network error]: ${networkError}`);
        }
      }),
      authHeader,
      link,
    ]),
    cache: clientCache,
    resolvers: {},
  });

  addLocalStates(client, localStates);

  return client;
}

export const getClient = memoize(_getClient);
