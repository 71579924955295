/** @jsx jsx */
import { Button, Colors, FormGroup, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import React, { Fragment, useCallback } from 'react';
import { KeywordsData } from '../../common/types';
import { useCurrCallback, useSetState, useStatusColor } from '../../lib/utils';
import i18n from '../../i18n';
import { compact, isEmpty, mapValues } from 'lodash/fp';
import EditToggles from '../common/edit_toggles';
import CustomTagsInput from '../common/custom_tag_input';
import { keywordsFromInputValue } from '../../lib/task_helpers';

const KEYWORDS_INPUT_PLACEHOLDER = i18n._(t`Type a phrase and hit enter to make it a keyword`);

const keywordsBarCss = css`
  margin-left: -1rem;
  margin-right: -1rem;
  border-top: 1px solid ${Colors.LIGHT_GRAY1};
  border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
`;

type KeywordsEditState = {
  editing: boolean;
  editedKeywords: {
    included: null | string[];
    excluded: null | string[];
  };
  keywordInputs: {
    included: string;
    excluded: string;
  };
};

const INITIAL_STATE: KeywordsEditState = {
  editing: false,
  editedKeywords: { included: null, excluded: null },
  keywordInputs: {
    included: '',
    excluded: '',
  },
};

interface IKeywordsEditProps {
  keywords: KeywordsData;
  onExport: () => void;
  onImport: () => void;
  onSave: (keywordsData: KeywordsData) => void;
  className?: string;
}

const KeywordsEdit: React.FC<IKeywordsEditProps> = ({
  keywords,
  onExport,
  onImport,
  onSave,
  className,
}) => {
  const { included, excluded } = keywords;
  const [state, setState] = useSetState<KeywordsEditState>(INITIAL_STATE);
  const { editing, editedKeywords, keywordInputs } = state;
  const getStatusColor = useStatusColor();

  const edited = !(
    isEmpty(included) &&
    isEmpty(excluded) &&
    isEmpty(editedKeywords.included) &&
    isEmpty(editedKeywords.excluded) &&
    isEmpty(keywordInputs.included) &&
    isEmpty(keywordInputs.excluded)
  );

  const handleKeywordsSave = useCallback(() => {
    // user may have typed keywords but not pressed enter
    const typedKeywords = mapValues((v) => keywordsFromInputValue(v), keywordInputs);

    onSave({
      included: compact((editedKeywords.included ?? included).concat(typedKeywords.included)),
      excluded: compact((editedKeywords.excluded ?? excluded).concat(typedKeywords.excluded)),
    });
    setState(INITIAL_STATE);
  }, [setState, onSave, editedKeywords, keywordInputs, included, excluded]);

  const handleKeywordsChange = useCurrCallback(
    (keywordsKind: 'included' | 'excluded', keywords: string[]) => {
      setState((current) => ({
        ...current,
        editedKeywords: { ...current.editedKeywords, [keywordsKind]: keywords },
      }));
    },
    [setState]
  );

  const handleKeywordInputChange = useCurrCallback(
    (keywordsKind: 'included' | 'excluded', value: string) => {
      setState((current) => ({
        ...current,
        keywordInputs: { ...current.keywordInputs, [keywordsKind]: value },
      }));
    },
    []
  );

  const handleCancel = useCallback(() => {
    setState(INITIAL_STATE);
  }, [setState]);

  return (
    <div className={className}>
      <div
        css={keywordsBarCss}
        className="flex flex-row flex-no-wrap justify-between items-center px-4"
      >
        <span>
          <Trans>Keywords</Trans>
        </span>
        <div className="flex flex-row items-center p-2">
          {!state.editing && (
            <Fragment>
              <Button
                minimal
                className="mr-2"
                disabled={isEmpty(included) && isEmpty(excluded)}
                onClick={onExport}
                icon={IconNames.FLOPPY_DISK}
                text={<Trans>Save</Trans>}
              />
              <Button
                minimal
                icon={IconNames.IMPORT}
                className="mr-2"
                onClick={onImport}
                text={<Trans>Load</Trans>}
              />
            </Fragment>
          )}
          <EditToggles
            editing={editing}
            saveIcon={IconNames.TICK}
            onEdit={() => setState({ editing: true })}
            onCancel={handleCancel}
            onSave={handleKeywordsSave}
            editText={<Trans>Edit</Trans>}
          />
        </div>
      </div>
      <div className="flex flex-row flex-no-wrap mt-3">
        {['included', 'excluded'].map((keywordsKind, idx) => {
          const includedKind = keywordsKind === 'included';
          const labelColor = getStatusColor(keywordsKind);

          return (
            <div
              key={keywordsKind}
              className={`flex-1 ${idx > 0 ? 'ml-3' : ''}`}
              data-testid="keywords-edit-block"
            >
              <FormGroup
                label={
                  <span css={{ color: labelColor }}>
                    {includedKind ? (
                      <Trans>Desired keywords</Trans>
                    ) : (
                      <Trans>Undesired keywords</Trans>
                    )}
                  </span>
                }
              >
                <CustomTagsInput
                  disabled={!editing}
                  inclusionIntent={includedKind ? 'desired' : 'undesired'}
                  values={editedKeywords[keywordsKind] ?? keywords[keywordsKind]}
                  onChange={handleKeywordsChange(keywordsKind)}
                  placeholder={KEYWORDS_INPUT_PLACEHOLDER}
                  inputValue={keywordInputs[keywordsKind]}
                  onInputChange={handleKeywordInputChange(keywordsKind)}
                />
              </FormGroup>
            </div>
          );
        })}
      </div>
      {edited && (
        <div className="mt-2 flex flex-row items-center">
          <Icon icon={IconNames.INFO_SIGN} className="mr-3" color={Colors.GRAY2} />
          <span className="text-gray-700 text-xs">
            <Trans>
              To highlight all the keywords sharing common core, eg. child and children, please use
              the wildcard ('*') at the end or in the beginning of the core. In this exemplary case
              this would be 'child*'.
            </Trans>
          </span>
        </div>
      )}
    </div>
  );
};

export default KeywordsEdit;
