/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { User } from '../../common/types';

type UserAvatarProps = {
  user?: User | null;
  withName?: boolean;
  size?: number;
};

const UserAvatar: React.FC<UserAvatarProps> = ({ user, withName = false, size = 40 }) => {
  const initials = user ? `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}` : '';

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        '& .avatar': {
          display: 'inline-block',
          marginRight: '10px',
          marginTop: '5px',
          marginBottom: '5px',
          height: size,
          width: size,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '50%',
        },
        '& p': {
          height: '100%',
          lineHeight: `${size}px`,
          width: 'auto',
          background: '#022a02',
          textAlign: 'center',
        },
      }}
    >
      <div className="avatar">
        <p>{initials}</p>
      </div>
      {withName && user && <div>{user?.name}</div>}
    </div>
  );
};

export default UserAvatar;
