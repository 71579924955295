import { ApolloProvider, useMutation } from '@apollo/react-hooks';
import { FocusStyleManager, Intent } from '@blueprintjs/core';
import * as Sentry from '@sentry/browser';
import closestPolyfill from 'element-closest';
import 'intersection-observer';
import React, { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ReadableStream as ReadableStreamPolyfill } from 'web-streams-polyfill/ponyfill/es6';
import stringMatchAllPolyfill from 'string.prototype.matchall';
import { getClient } from './apollo/';
import './App.css';
import {
  AdminReferenceDetailsMatchParams,
  AppContentProps,
  ProjectMatchParams,
  StageMatchParams,
  User,
} from './common/types';
import useActionLogger from './components/hooks/use_action_logger';
import AdminTabs from './components/project/admin_tabs';
import ListForScreeners from './components/project/list_for_screeners';
import AdminReferencesList from './components/references/admin';
import Screening from './components/screening';
import TeamMembersSettings from './components/team/team_members_settings';
import KeywordsList from './components/keywords/keywords_list';
import PrismaDiagram from './components/prisma_diagram';
import { useKeycloak } from './keycloak';
import AppToaster from './lib/toaster';
import { getEnv, IGNORABLE_ERRORS } from './lib/utils';
import './tailwind-generated-styles.css';
import UsersPanel, { IUsersProps } from './components/users/users_panel';
import { loader } from 'graphql.macro';
import { withQuery } from './components/common/with_query';
import { ThemeProvider } from './components/settings/theme_context';
import StagesList from './components/project/stages_list';
import StageDashboard from './components/project/dashboard/stage_dashboard';
import UnassignedAttachments from './components/references/unassigned_attachments/unassigned_attachments';
import StagesDashboard from './components/project/dashboard/stages_dashboard';
import ScreeningInstructions from './components/criteria/screening_instructions';
import AdminReferenceDetails from './components/references/admin/reference_details';
import ScreeningConflicts from './components/screening/screening_conflicts';
import ProjectDashboard from './components/project/dashboard/project_dashboard';
import Page404 from './components/pages/page404';
import AppModulePage from './components/pages/app_module_page';
import DefaultPage from './components/pages/default_page';
import ProjectPage from './components/pages/project_page';
import RestrictedAccess from './components/restricted_access';

const ResetAppStateMutation = loader('./graphql/local/reset_app_state.gql');
const GetProjectsQuery = loader('./graphql/get_projects.gql');

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

if (!window['ReadableStream']) {
  // @ts-ignore
  window['ReadableStream'] = ReadableStreamPolyfill;
}
closestPolyfill(window);
FocusStyleManager.onlyShowFocusOnTabs();
stringMatchAllPolyfill.shim();
const initSentry = (user: User) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getEnv(),
    release: process.env.REACT_APP_GBA_HASH,
    ignoreErrors: IGNORABLE_ERRORS,
    beforeSend(event) {
      if (event.exception) {
        console.error(event.exception);
        AppToaster.show({
          message: `We could not complete this action. There was a problem while sending the data. Please try again. If the problem persists, please contact the Laser team and report the error using the following code: ${event.event_id}`,
          intent: Intent.WARNING,
          timeout: 10000,
        });
      }
      return event;
    },
  });
  Sentry.configureScope((scope) => {
    scope.setUser({
      username: user.username,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });
  });
};

const AppContent = withRouter(({ history }: AppContentProps) => {
  const insertActionLog = useActionLogger();
  const { isAdmin, user, isItAdmin } = useKeycloak();
  const { id: userId } = user;
  const [resetAppState] = useMutation(ResetAppStateMutation);

  useEffect(() => {
    insertActionLog('appLoaded', {});

    const unregisterListener = history.listen((_location, action) => {
      insertActionLog('routeChanged', { action });
    });

    return () => {
      resetAppState();
      unregisterListener();
    };
  }, []);

  return (
    <div className="content max-w-full">
      {isItAdmin ? (
        <Switch>
          <Route
            path="/users"
            render={(props: AppContentProps) => (
              <DefaultPage {...props}>
                {React.createElement(
                  withQuery<IUsersProps>({
                    query: GetProjectsQuery,
                    queryDataPath: 'projects',
                    dataPropName: 'projects',
                    targetProps: { filterBy: 'accountType' },
                    variables: { userId },
                  })(UsersPanel)
                )}
              </DefaultPage>
            )}
          />
          <Redirect to="/users" />
        </Switch>
      ) : (
        <Switch>
          <Redirect exact from="/" to="/projects" />
          <Route
            path="/users"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <AppModulePage {...props}>
                <RestrictedAccess onlyAdmin {...props}>
                  {React.createElement(
                    withQuery<IUsersProps>({
                      query: GetProjectsQuery,
                      queryDataPath: 'projects',
                      dataPropName: 'projects',
                      targetProps: { filterBy: 'projects' },
                      variables: { userId },
                    })(UsersPanel)
                  )}
                </RestrictedAccess>
              </AppModulePage>
            )}
          />
          <Route
            path="/projects/:projectId/references/unassigned-attachments"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <UnassignedAttachments {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/references/:referenceId/details"
            render={(props: AppContentProps<AdminReferenceDetailsMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin {...props}>
                  <AdminReferenceDetails {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/references"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin {...props}>
                  <AdminReferencesList {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/stages/:stageId/instructions"
            render={(props: AppContentProps<StageMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <ScreeningInstructions {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/prisma"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin {...props}>
                  <PrismaDiagram {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/team-members"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <TeamMembersSettings {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          {/* Here the unnamed optional matching group (screening)* covers "/screening" part which
              appears only when route is accessed by admin user. For regular users it's not present.
            */}
          <Route
            path="/projects/:projectId/(screening)?/stages/:stageId/conflicts/"
            render={(props: AppContentProps<StageMatchParams>) => (
              <DefaultPage withProjectBreadcrumbs {...props}>
                <RestrictedAccess activeProjectOnly {...props}>
                  <ScreeningConflicts {...props} />
                </RestrictedAccess>
              </DefaultPage>
            )}
          />
          <Route
            path="/projects/:projectId/(screening)?/stages/:stageId/screening/"
            render={(props: AppContentProps<StageMatchParams>) => (
              <DefaultPage withProjectBreadcrumbs {...props}>
                <RestrictedAccess activeProjectOnly {...props}>
                  <Screening {...props} />
                </RestrictedAccess>
              </DefaultPage>
            )}
          />

          <Route
            path="/projects/:projectId/stages/:stageId/references/:referenceStatus?"
            render={(props: AppContentProps<StageMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <AdminReferencesList {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/stages/:stageId"
            render={(props: AppContentProps<StageMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <StageDashboard {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/stages"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <StagesDashboard {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/screening"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin {...props}>
                  <StagesList {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId/dashboard"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <ProjectPage {...props}>
                <RestrictedAccess onlyAdmin privileged {...props}>
                  <ProjectDashboard {...props} />
                </RestrictedAccess>
              </ProjectPage>
            )}
          />
          <Route
            path="/projects/:projectId"
            render={(props: AppContentProps<ProjectMatchParams>) => (
              <DefaultPage withProjectBreadcrumbs {...props}>
                <RestrictedAccess activeProjectOnly {...props}>
                  <StagesList {...props} />
                </RestrictedAccess>
              </DefaultPage>
            )}
          />
          <Route
            path="/projects"
            render={(props: AppContentProps) =>
              isAdmin ? (
                <AppModulePage {...props}>
                  <AdminTabs />
                </AppModulePage>
              ) : (
                <DefaultPage {...props}>
                  <ListForScreeners />
                </DefaultPage>
              )
            }
          />
          <Route
            path="/404"
            render={(props: AppContentProps) =>
              isAdmin ? (
                <AppModulePage {...props}>
                  <Page404 />
                </AppModulePage>
              ) : (
                <DefaultPage {...props}>
                  <Page404 />
                </DefaultPage>
              )
            }
          />
          <Route
            path="/keywords"
            render={(props: AppContentProps) => (
              <DefaultPage {...props}>
                <KeywordsList />
              </DefaultPage>
            )}
          />
          <Redirect to="/404" />
        </Switch>
      )}
    </div>
  );
});

export const App: React.FC = () => {
  const { authenticated, initialized, getToken, user } = useKeycloak();

  useEffect(() => {
    if (!initialized) return;
    if (authenticated && process.env.NODE_ENV !== 'development') {
      initSentry(user);
    }
  }, [authenticated, initialized, user]);

  return (
    <ApolloProvider client={getClient(getToken)}>
      <ThemeProvider>
        <div className="App">
          <Router>
            <AppContent />
          </Router>
        </div>
      </ThemeProvider>
    </ApolloProvider>
  );
};
