/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import React, { ReactElement, ReactNode, useCallback, useState } from 'react';
import { Button, Collapse, Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { fancyScrollCss, lightGray3bg, lightGray5bg } from '../../common/styles';
import { without } from 'lodash/fp';

export const titleCss = css`
  ${lightGray5bg};
  border-color: ${Colors.GRAY5};
`;

export const collapseContainerCss = css`
  ${lightGray3bg};
  border-color: ${Colors.GRAY5};
`;

export function useCollapsibleSections<T = string>(initiallyOpenSections: T[]) {
  const [expandedSections, setExpandedSections] = useState<T[]>(initiallyOpenSections ?? []);

  const handleToggleSection = useCallback(
    (sectionId: T) =>
      setExpandedSections((prevState) =>
        prevState.includes(sectionId)
          ? without<T>([sectionId], prevState)
          : [...prevState, sectionId]
      ),
    [setExpandedSections]
  );

  const setExpandedSection = useCallback(
    (sectionId: T) => setExpandedSections([sectionId]),
    [setExpandedSections]
  );

  return {
    expandedSections,
    toggleSection: handleToggleSection,
    setExpandedSection,
  };
}

interface ICollapsibleSectionProps {
  title: ReactNode;
  id: string;
  className?: string;
  titleClass?: string;
  titleCss?: SerializedStyles;
  isOpen?: boolean;
  onToggle?: (id: string, isOpen: boolean) => void;
  collapseContainerCss?: SerializedStyles;
  collapseContainerClass?: string;
  toggleColor?: string;
}

export const CollapsibleSection: React.FC<ICollapsibleSectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
  id,
  className,
  titleClass,
  toggleColor,
  collapseContainerClass,
  collapseContainerCss,
  titleCss,
}) => {
  return (
    <div
      className={`overflow-hidden flex flex-col ${isOpen ? 'flex-1' : ''} ${className ?? ''}`}
      data-testid="collapsible-section"
    >
      <div className={`flex-none flex flex-row items-center ${titleClass}`} css={titleCss}>
        <span className="flex-1 truncate">{title}</span>
        <Button
          minimal
          icon={
            <Icon
              icon={isOpen ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              color={toggleColor}
            />
          }
          onClick={() => onToggle?.(id, !isOpen)}
        />
      </div>
      <div
        className={`flex-1 overflow-auto ${collapseContainerClass ?? ''}`}
        css={collapseContainerCss ? [fancyScrollCss, collapseContainerCss] : fancyScrollCss}
      >
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    </div>
  );
};

interface ICollapsibleSectionsProps {
  children: ReactElement<ICollapsibleSectionProps, typeof CollapsibleSection>[];
  className?: string;
}

export const CollapsibleSections: React.FC<ICollapsibleSectionsProps> = ({
  children,
  className,
}) => <div className={`flex flex-col overflow-hidden h-full ${className ?? ''}`}>{children}</div>;
