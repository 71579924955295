/** @jsx jsx */
import { jsx } from '@emotion/core';
import CountTag, { ICountTagProps } from './count_tag';
import { useSpring, animated } from 'react-spring';
import { Classes } from '@blueprintjs/core';
interface IAnimatedCountTagProps extends ICountTagProps {
  animationBlinkColor: string;
}

const AnimatedCountTag: React.FC<IAnimatedCountTagProps> = ({
  count,
  onClick,
  className,
  title,
  inclusionStatus,
  loading,
  animationBlinkColor,
  active,
}) => {
  const props = useSpring({
    config: {
      duration: 250,
    },
    from: {
      background: 'rgba(0,0,0,0)',
    },
    to: async (next) => {
      await next({
        background: animationBlinkColor,
      });
      await next({
        background: 'rgba(0,0,0,0)',
      });
    },
  });
  return (
    <animated.div style={props} className={`${Classes.TAG} p-0 mr-2`}>
      <CountTag
        count={count}
        onClick={onClick}
        className={className}
        active={active}
        title={title}
        inclusionStatus={inclusionStatus}
        loading={loading}
      />
    </animated.div>
  );
};

export default AnimatedCountTag;
