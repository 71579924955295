/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { get, omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { domainsListCss } from './tiab_criteria';
import {
  DomainItem,
  FTScreeningCriteria,
  FTScreeningFormData,
  InclusionExclusionCriteriaForm,
  InclusionStatus,
  KeywordsData,
  ScreenerInclusionExclusionCriteriaDomainData,
  ScreenerInclusionExclusionCriteriaForm,
  StageType,
} from '../../common/types';
import { useKeycloak } from '../../keycloak';
import { mergeUserKeywords } from '../../lib/criteria_utils';
import AppToaster from '../../lib/toaster';
import { generateRandomUUID, useCurrCallback, useI18n } from '../../lib/utils';
import ErrorScreen from '../common/error_screen';
import { darkGray5Color, fancyScrollCss } from '../../common/styles';
import { tiabFormDataToFTFormData } from '../project/helpers';
import ExpandableInstructionSectionItem from './expandable_instruction_section_item';
import { useTheme } from '../settings/theme_context';
import ScreenerKeywordsEdit from './screener_keywords_edit';
import { useStatusColor } from '../../lib/utils';

const FormUserKeywordsFragment = loader('../../graphql/form_user_keywords_fragment.gql');
const CriteriaDataQuery = gql`
  query GetCriteriaData($projectId: uuid!, $userId: String!, $stageId: uuid!) {
    domains: form_domains_and_variables {
      domain
      variables
    }
    stage: stage_by_pk(id: $stageId) {
      id
      type
      forms(order_by: { created_at: desc }, limit: 1) {
        ...FormFragment
      }
    }
    project: project_by_pk(id: $projectId) {
      id
      team_members(where: { user_id: { _eq: $userId } }) {
        id
      }
    }
  }
  ${FormUserKeywordsFragment}
  fragment FormFragment on form {
    id
    form
    form_team_member_keywords {
      ...FormUserKeywordsFragment
    }
  }
`;

const SaveUserKeywordsMutation = gql`
  mutation SaveUserKeywordsMutation($keywordsFormToSave: form_team_member_keywords_insert_input!) {
    insert_form_team_member_keywords_one(
      object: $keywordsFormToSave
      on_conflict: { constraint: form_team_member_keywords_pkey, update_columns: [keywords] }
    ) {
      id
    }
  }
`;

interface ICriteriaScreenerProps {
  projectId: string;
  stageId: string;
}

const CriteriaScreener: React.FC<ICriteriaScreenerProps> = ({ projectId, stageId }) => {
  const {
    user: { id: userId },
  } = useKeycloak();
  const getStatusColor = useStatusColor();
  const { data, loading, error, refetch } = useQuery(CriteriaDataQuery, {
    variables: { projectId, userId, stageId },
    pollInterval: 2000,
  });
  const i18n = useI18n();
  const [saveScreenerKeywords] = useMutation(SaveUserKeywordsMutation);
  const { statusColors } = useTheme();

  const stageType: StageType = get('stage.type', data);

  const myTeamMemberId = get('project.team_members[0].id', data) ?? '';
  const domains: DomainItem[] = get('domains', data) ?? [];
  const criteriaForm: InclusionExclusionCriteriaForm | undefined = get('stage.forms[0]', data);
  const currentForm = criteriaForm?.form;
  const currentScreenerKeywordsForm = useMemo(() => {
    const definedKeywords = get('form_team_member_keywords[0]', criteriaForm);
    return (
      definedKeywords ??
      (criteriaForm
        ? ({
            id: generateRandomUUID(),
            form_id: criteriaForm!.id ?? '',
            team_member_id: myTeamMemberId,
            keywords: {},
          } as ScreenerInclusionExclusionCriteriaForm)
        : undefined)
    );
  }, [criteriaForm, myTeamMemberId]);

  const currentScreenerKeywords = currentScreenerKeywordsForm?.keywords ?? {};

  const formData = useMemo(() => {
    if (currentForm == null || stageType == null) return;
    const mergedForm = mergeUserKeywords(currentForm, currentScreenerKeywords);

    return [StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(stageType)
      ? tiabFormDataToFTFormData(mergedForm)
      : (mergedForm as unknown as FTScreeningFormData);
  }, [currentForm, currentScreenerKeywords, stageType]);

  const handleUpdateVariableKeywords = useCurrCallback(
    (domainId: string, variableId: string, keywords: KeywordsData) => {
      const domainKeywords = (get(domainId, currentScreenerKeywords) ??
        {}) as ScreenerInclusionExclusionCriteriaDomainData;
      const keywordsFormToSave = omit('__typename', {
        ...currentScreenerKeywordsForm,
        keywords: {
          ...currentScreenerKeywords,
          [domainId]: {
            ...domainKeywords,
            [variableId]: keywords,
          },
        },
      }) as ScreenerInclusionExclusionCriteriaForm;
      return saveScreenerKeywords({ variables: { keywordsFormToSave } })
        .then(() =>
          AppToaster.show({
            icon: IconNames.SAVED,
            intent: Intent.SUCCESS,
            message: i18n._(t`Keywords successfully updated`),
          })
        )
        .catch(() =>
          AppToaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: i18n._(t`Error while updating keywords`),
          })
        )
        .then(() => refetch());
    },
    [currentScreenerKeywordsForm, currentScreenerKeywords, i18n, refetch, saveScreenerKeywords]
  );

  const criteriaRenderer = useCurrCallback(
    (
      inclusionStatus: InclusionStatus,
      { id, name, code, instruction, keywords, variableId }: FTScreeningCriteria
    ) => {
      return (
        <ExpandableInstructionSectionItem
          key={id}
          content={
            <div className="flex flex-row">
              <span className="mr-4 truncate flex-1">{name}</span>
              <span
                className="w-16 mx-8"
                css={{
                  color: getStatusColor(inclusionStatus),
                }}
              >
                {code}
              </span>
            </div>
          }
          expandableContent={
            <div>
              <div className="h-6">
                <Trans>Instruction</Trans>
              </div>
              <div>{instruction}</div>
              {[StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(
                stageType
              ) ? (
                <ScreenerKeywordsEdit
                  className="mt-3"
                  domains={domains}
                  keywordsData={keywords}
                  onSave={handleUpdateVariableKeywords(id, variableId!)}
                />
              ) : null}
            </div>
          }
        />
      );
    },
    [stageType]
  );

  if (error) {
    return <ErrorScreen error={error} retry={refetch} />;
  }

  return loading ? (
    <Spinner />
  ) : (
    <div className="flex flex-col h-full w-full overflow-auto" css={fancyScrollCss}>
      <div className="flex flex-col flex-1 items-center w-full px-8 py-3" css={domainsListCss}>
        {formData == null ? (
          <NonIdealState
            icon={IconNames.HEART_BROKEN}
            title={
              <span className="text-sm font-normal">
                <Trans>Form data is missing</Trans>
              </span>
            }
          />
        ) : (
          <React.Fragment>
            <div css={darkGray5Color} className="flex justify-between mb-1 w-full">
              <div className="ml-4">
                <Trans>Reason</Trans>
              </div>
              <div className="mr-32">
                <Trans>Tag code</Trans>
              </div>
            </div>
            <div className="w-full">
              {formData.inclusion.map(criteriaRenderer(InclusionStatus.Included))}
              {formData.exclusion.map(criteriaRenderer(InclusionStatus.Excluded))}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CriteriaScreener;
