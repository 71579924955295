/** @jsx jsx */
import { Alignment, Button, Checkbox, Intent, MenuItem, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { get } from 'lodash/fp';
import { useCallback, useState } from 'react';
import Immutable from 'immutable';
import { FTScreeningCriteria, InclusionStatus, ScreeningForm } from '../../../common/types';
import { useCurrCallback } from '../../../lib/utils';

export const CriteriaSelect = Select.ofType<FTScreeningCriteria>();

interface IFTConflictResolutionControlsProps {
  disabled: boolean;
  onResolve: (
    decision: InclusionStatus.Included | InclusionStatus.Excluded,
    reason: FTScreeningCriteria[]
  ) => void;
  form: ScreeningForm;
  saving?: boolean;
}

const FTConflictResolutionControls: React.FC<IFTConflictResolutionControlsProps> = ({
  disabled,
  onResolve,
  form,
  saving,
}) => {
  const inclusionCriteria: FTScreeningCriteria[] = get('form.inclusion', form) ?? [];
  const exclusionCriteria: FTScreeningCriteria[] = get('form.exclusion', form) ?? [];

  const [selectedInclusions, setSelectedInclusions] = useState<Immutable.Set<string>>(Immutable.Set());

  const toggleSelectedInclusion = useCurrCallback((inclusionId: string, _evt) => {
    setSelectedInclusions((currentlySelectedInclusions: Immutable.Set<string>) => {
      return currentlySelectedInclusions.has(inclusionId)
        ? currentlySelectedInclusions.delete(inclusionId)
        : currentlySelectedInclusions.add(inclusionId);
    });
  }, []);

  const handleSendSelectedInclusions = useCallback(() => {
    const selectedInclusionsCriteria = inclusionCriteria.filter(({ id }) => selectedInclusions.has(id));
    onResolve(InclusionStatus.Included, selectedInclusionsCriteria);
  }, [inclusionCriteria, selectedInclusions, onResolve]);

  const handleDecision = useCurrCallback(
    (
      decision: InclusionStatus.Included | InclusionStatus.Excluded,
      criteria: FTScreeningCriteria
    ) => {
      onResolve(decision, [criteria]);
    },
    [onResolve]
  );

  const renderCriteriaOption: ItemRenderer<FTScreeningCriteria> = useCallback(
    (criteria, { handleClick, modifiers }) => (
      <MenuItem
        key={criteria.id}
        active={modifiers.active}
        onClick={handleClick}
        text={criteria.name}
      />
    ),
    []
  );

  return (
    <div className="flex flex-row w-full">
      <Popover
        content={
          <div className="p-3">
            {inclusionCriteria.map(({ id, name }) => (
              <Checkbox key={id} checked={selectedInclusions.has(id)} label={name} onChange={toggleSelectedInclusion(id)} />
            ))}
            <div className="text-center">
              <Button disabled={!selectedInclusions.size} onClick={handleSendSelectedInclusions}>
                <Trans>Send</Trans>
              </Button>
            </div>
          </div>
        }
        className="flex-1 mr-4"
        targetClassName="w-full"
        minimal={true}
      >
        <Button
          fill
          className="h-full"
          alignText={Alignment.LEFT}
          disabled={disabled}
          intent={Intent.SUCCESS}
          text={<Trans>Include</Trans>}
          rightIcon={IconNames.CHEVRON_UP}
          loading={saving}
        />
      </Popover>
      <CriteriaSelect
        items={exclusionCriteria}
        itemRenderer={renderCriteriaOption}
        onItemSelect={handleDecision(InclusionStatus.Excluded)}
        filterable={false}
        popoverProps={{
          className: 'flex-1',
          targetClassName: 'w-full',
          minimal: true,
        }}
      >
        <Button
          fill
          disabled={disabled}
          alignText={Alignment.LEFT}
          intent={Intent.DANGER}
          text={<Trans>Exclude</Trans>}
          rightIcon={IconNames.CHEVRON_UP}
          loading={saving}
        />
      </CriteriaSelect>
    </div>
  );
};

export default FTConflictResolutionControls;
