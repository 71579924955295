/** @jsx jsx */
import { Button, InputGroup, MenuItem, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, MultiSelect, Select } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { filter, get, isEmpty, without } from 'lodash/fp';
import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  DomainData,
  Flatten,
  FTScreeningCriteria,
  FTScreeningFormData,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  Stage,
  StageType,
} from '../../../common/types';
import { getInclusionStatusLabel } from '../../../lib/task_helpers';
import { useI18n } from '../../../lib/utils';
import { TReferenceDetailsData } from './reference_details';

const decisionTagCss = css`
  max-width: 240px;
`;

type TDecisionOption = {
  inclusionStatus: InclusionStatus;
  reasonCode: string | null;
  label: ReactNode;
};

type TStudyStageResultData = Flatten<TReferenceDetailsData['stage_results']>;

const DecisionSelect = Select.ofType<TDecisionOption>();
const ftCriteriaToDecisionOption = ({ inclusionStatus, code, name }: FTScreeningCriteria) => ({
  inclusionStatus,
  reasonCode: code,
  label: `${code}: ${name}`,
});
const tiabDomainToDecisionOption = ({ exclusionButtonLabel, exclusionCode }: DomainData) => ({
  inclusionStatus: InclusionStatus.Excluded,
  reasonCode: exclusionCode,
  label: `${exclusionCode}: ${exclusionButtonLabel}`,
});

function updateReasonCodes(
  stageType: StageType,
  stageResult: TStudyStageResultData,
  item: TDecisionOption
): string[] {
  if (item.reasonCode == null) return [];

  switch (stageType) {
    case StageType.PreliminaryScreening:
    case StageType.TitlesAbstractScreening:
      return item.reasonCode ? [item.reasonCode] : [];
    case StageType.FullTextScreening:
      return stageResult.inclusion_status === item.inclusionStatus &&
        stageResult.inclusion_status === InclusionStatus.Included
        ? stageResult.status_reason_codes.includes(item.reasonCode)
          ? without([item.reasonCode], stageResult.status_reason_codes)
          : [...stageResult.status_reason_codes, item.reasonCode]
        : [item.reasonCode];
    default:
      return [];
  }
}

interface IReferenceStageDecisionSelectProps {
  stage: Pick<Stage, 'id' | 'type' | 'order_number' | 'name' | 'forms'>;
  onSelect?: (data: { inclusionStatus: InclusionStatus; reasonCodes: string[] }) => void;
  stageResult?: TStudyStageResultData;
}

const ReferenceStageDecisionSelect: React.FC<IReferenceStageDecisionSelectProps> = ({
  stage,
  stageResult,
  onSelect,
}) => {
  const i18n = useI18n();
  const stageDecision = useMemo(() => {
    if (stageResult) {
      return isEmpty(stageResult.status_reason_codes)
        ? getInclusionStatusLabel(stageResult.inclusion_status)
        : stageResult.status_reason_codes.join(', ');
    }
  }, [stageResult]);
  const disabled =
    onSelect == null ||
    stageDecision == null ||
    stageResult?.inclusion_status === InclusionStatus.Conflict;

  const decisionOptions: TDecisionOption[] = useMemo(() => {
    const form = stage.forms[0];
    if (form == null) return [];
    const stageType = stage.type;

    switch (stageType) {
      case StageType.TitlesAbstractScreening:
      case StageType.PreliminaryScreening:
        const tiabOptions: TDecisionOption[] = [
          {
            inclusionStatus: InclusionStatus.Included,
            reasonCode: null,
            label: <Trans>Include</Trans>,
          },
        ];
        return tiabOptions.concat(
          (form.form as InclusionExclusionCriteriaFormData).domains.map(tiabDomainToDecisionOption)
        );
      case StageType.FullTextScreening:
        const { inclusion, exclusion } = form.form as FTScreeningFormData;

        return [
          ...inclusion.map(ftCriteriaToDecisionOption),
          ...exclusion.map(ftCriteriaToDecisionOption),
        ];
      default:
        return [];
    }
  }, [stage.forms, stage.type]);

  const statusReasonCodes = get('status_reason_codes', stageResult) ?? [];
  const selectedItems = useMemo(() => {
    return stage.type === StageType.FullTextScreening
      ? filter(
          (elem: TDecisionOption) => statusReasonCodes.includes(elem.reasonCode!),
          decisionOptions
        )
      : [];
  }, [statusReasonCodes, decisionOptions, stage.type]);

  const optionRenderer: ItemRenderer<TDecisionOption> = useCallback(
    (option, { modifiers, handleClick, index }) => {
      return (
        <MenuItem key={index} active={modifiers.active} text={option.label} onClick={handleClick} />
      );
    },
    []
  );

  const selectedDecisionsTagRenderer = useCallback((item: TDecisionOption) => {
    return <Tag css={decisionTagCss}>{item.label}</Tag>;
  }, []);

  const handleSelect = useCallback(
    (item: TDecisionOption) => {
      if (onSelect == null || stageResult == null) return;

      onSelect({
        inclusionStatus: item.inclusionStatus,
        reasonCodes: updateReasonCodes(stage.type, stageResult, item),
      });
    },
    [onSelect, stage.type, stageResult]
  );

  const handleRemoveSelectedItem = useCallback(
    (item: TDecisionOption) => {
      if (onSelect == null || stageResult == null) return;

      onSelect({
        inclusionStatus: stageResult.inclusion_status,
        reasonCodes: without([item.reasonCode!], stageResult?.status_reason_codes),
      });
    },
    [onSelect, stageResult]
  );

  return stage.type === StageType.FullTextScreening ? (
    <MultiSelect
      fill
      placeholder={i18n._(t`Select decisions...`)}
      items={decisionOptions}
      itemRenderer={optionRenderer}
      onItemSelect={handleSelect}
      selectedItems={selectedItems}
      tagRenderer={selectedDecisionsTagRenderer}
      onRemove={handleRemoveSelectedItem}
    />
  ) : (
    <DecisionSelect
      filterable={false}
      items={decisionOptions}
      onItemSelect={handleSelect}
      itemRenderer={optionRenderer}
      disabled={disabled}
      popoverProps={{ minimal: true }}
      fill
    >
      <InputGroup
        fill
        readOnly
        value={stageDecision ?? i18n._(t`No decision`)}
        disabled={disabled}
        rightElement={disabled ? undefined : <Button icon={IconNames.CARET_DOWN} minimal small />}
      />
    </DecisionSelect>
  );
};

export default ReferenceStageDecisionSelect;
