/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { t } from '@lingui/macro';
import React, { ReactNode } from 'react';
import { Colors, Spinner, SpinnerSize } from '@blueprintjs/core';
import i18n from '../../../i18n';
import { PieChart } from 'react-minimal-pie-chart';
import CardWithMarker from '../../common/card_with_marker';
import { StudyCounts } from '../../../lib/study_helpers';
import { useStatusColor } from '../../../lib/utils';
import { useTheme } from '../../settings/theme_context';

export const LABELS = {
  included: i18n._(t`In`),
  excluded: i18n._(t`Out`),
  conflicts: i18n._(t`Conflicts`),
  toReview: i18n._(t`To review`),
  total: i18n._(t`Initially to review`),
};

const countLabelCss = css`
  width: 260px;
  margin-top: 20px;

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

interface ICountLabelProps {
  label: ReactNode;
  count: number;
  status?: string;
  loading?: boolean;
}

const CountLabel: React.FC<ICountLabelProps> = ({ status, label, count, loading }) => {
  const getStatusColor = useStatusColor();

  return (
    <div className="flex flex-no-wrap items-center p-3 justify-between flex-1">
      <div className="text-base" css={{ color: getStatusColor(status) }}>
        {label}
      </div>
      <div className="text-2xl text-gray-800">
        {loading ? <Spinner size={SpinnerSize.SMALL} /> : count}
      </div>
    </div>
  );
};

interface IScreeningStatsProps {
  studyCounts: {
    total: number;
    toReview: number;
    included: number;
    excluded: number;
    conflicts: number;
    progress: number;
  } | null;
  navigateToReferences: (referenceStatus: string) => void;
  screenersCount: number;
  className?: string;
  isImportingReferences?: boolean;
}

const ScreeningStats: React.FC<IScreeningStatsProps> = ({
  className,
  isImportingReferences,
  navigateToReferences,
  studyCounts,
  screenersCount,
}) => {
  const counts = studyCounts || {
    included: 0,
    excluded: 0,
    conflicts: 0,
    progress: 0,
    total: 0,
    toReview: 0,
  };

  return (
    <div className={className}>
      <div className="flex flex-no-wrap items-center">
        <div className="flex-grow flex-shrink-0">
          <StatsPieChart counts={counts} style={{ height: '170px' }} lineWidth={30} displayLabel />
        </div>
        <div style={{ flexGrow: 3 }} className="flex flex-row flex-wrap">
          {['toReview', 'included', 'excluded', 'conflicts'].map((status) => {
            // don't display Conflicts counter for single-screener projects
            if (status === 'conflicts' && screenersCount === 1) return null;
            // in case of "conflicts" status we want it to be presented in neutral colors if all
            // conflicts were resolved
            const labelStatus = status === 'conflicts' && counts[status] === 0 ? undefined : status;

            return (
              <CardWithMarker
                css={countLabelCss}
                key={status}
                status={labelStatus}
                onClick={() => navigateToReferences(status)}
              >
                <CountLabel
                  status={labelStatus}
                  count={counts[status]}
                  label={LABELS[status]}
                  loading={status === 'toReview' && isImportingReferences}
                />
              </CardWithMarker>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScreeningStats;

interface IStatsPieChart {
  counts: StudyCounts | null;
  style: any;
  lineWidth: number;
  displayLabel?: boolean;
}

export const StatsPieChart: React.FC<IStatsPieChart> = ({
  counts,
  style,
  lineWidth,
  displayLabel,
}) => {
  const { statusColors } = useTheme();
  return (
    <PieChart
      style={style}
      lineWidth={lineWidth}
      label={
        displayLabel && counts != null
          ? ({ dataIndex }) => (dataIndex === 0 ? `${counts.progress} %` : null)
          : undefined
      }
      labelPosition={0}
      data={
        counts == null || counts.total === 0
          ? [{ value: 1, color: Colors.GRAY5 }]
          : [
              {
                title: LABELS.included,
                value: counts.included,
                color: statusColors.acceptedPrimary,
              },
              {
                title: LABELS.excluded,
                value: counts.excluded,
                color: statusColors.rejectedPrimary,
              },
              {
                title: LABELS.conflicts,
                value: counts.conflicts,
                color: statusColors.conflictsPrimary,
              },
              {
                title: LABELS.toReview,
                value: counts.toReview,
                color: statusColors.neutralPrimary,
              },
            ]
      }
    />
  );
};
