/** @jsx jsx **/
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Classes,
  Colors,
  Icon,
  IconSize,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { compose, find, get, isEmpty, keyBy, map, noop, pick, propEq } from 'lodash/fp';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { TExclusionReason, TReferenceData } from '..';
import {
  DecisionFilter,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveScreeningTagFilters,
  YearFilters,
} from '../../../apollo/screening_state';
import { alignStartCss } from '../../../common/styles';
import {
  ConflictTaskScreeningResult,
  DomainData,
  FTScreeningCriteria,
  ScreeningTaskResult,
  ScreeningTaskType,
  StageType,
} from '../../../common/types';
import i18n from '../../../i18n';
import { useKeycloak } from '../../../keycloak';
import { SearchAndDesiredUndesiredKeywords } from '../../../lib/criteria_utils';
import {
  getConflictTasksFromReference,
  getReferenceComment,
  getTaskResultControlId,
  getTaskResultFromReference,
  TTaskCounts,
} from '../../../lib/task_helpers';
import { useStatusColor } from '../../../lib/utils';
import { createTable, TableCol } from '../../common/gba_table';
import TextWithHighlights from '../../common/text_with_highlights';
import useReferencesListSort from '../../hooks/use_references_list_sort';
import useSetDecisionFilter from '../../hooks/use_set_decision_filter';
import { formatDate } from '../../project/helpers';
import {
  firstAuthorLastNameCol,
  lastChangedCol,
  titleCol,
  yearCol,
} from '../../../common/table_cols';
import SendDecisionsButton from '../send_decisions_button';
import FTConflictsListHeader from './ft_conflicts_list_header';
import LoadMoreReferencesButton from '../../references/admin/load_more_references_button';
import DecisionTag from '../decision_tag';
import { TDomainReasonsData, TScreeningTag } from '../../references/admin';
import { IYearsRangeFilterState } from '../../references/admin/list';
import { panelCollapsedColCss, panelExpandedColCss, TScreeningPanel } from '../list';
import { renderFiltersToggle, renderPreviewToggle } from '../list_header_toggles';
import PreviewColumn from '../preview_column';
import FiltersColumn from '../filters_column';
import ActiveFiltersBar from '../active_filters_bar';
import { useScreeningFilters } from '../../hooks/use_screening_filters';
import NavAndDecisionControls from '../navigation_and_decision_controls';

const SetActiveAndSelectedReferencesMutation = loader(
  '../../../graphql/local/set_active_and_selected_references.gql'
);

const formDataQuery = gql`
  query getForm($formId: uuid!) {
    form_by_pk(id: $formId) {
      id
      form
    }
  }
`;

const ReferencesTable = createTable<TReferenceData>();

const containerCss = css`
  margin-left: 1px;
  padding: 10px 20px 5px;
  background-color: ${Colors.LIGHT_GRAY5};
`;

const conflictLabelCss = css`
  color: ${Colors.DARK_GRAY5};
  border: 1px solid currentColor;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const ConflictLabel: React.FC<{
  username: string;
  selectedCriteria: Pick<FTScreeningCriteria, 'code' | 'inclusionStatus'>[];
}> = ({ username, selectedCriteria }) => {
  const getStatusColor = useStatusColor();

  return (
    <div className="px-1 rounded" css={conflictLabelCss}>
      <span>{username}</span>
      {selectedCriteria.map(({ code, inclusionStatus }, idx) => (
        <span key={idx} className="ml-2" style={{ color: getStatusColor(inclusionStatus) }}>
          {code}
        </span>
      ))}
    </div>
  );
};

const COLS: TableCol<TReferenceData>[] = [
  {
    id: 'fullScreen',
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    sortable: false,
    width: 70,
  },
  firstAuthorLastNameCol({
    label: i18n._(t`Author`),
    width: 100,
  }),
  yearCol({ width: 70 }),
  titleCol(),
  {
    id: 'conflicts',
    label: i18n._(t`Conflicts`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    sortable: false,
  },
  {
    id: 'finalDecision',
    label: i18n._(t`Final decision`),
    headerCellCss: alignStartCss,
    cellCss: alignStartCss,
    sortable: false,
  },
  lastChangedCol(),
];

interface IFTConflictsListProps {
  stageId: string;
  stageType: StageType;
  taskCounts: TTaskCounts;
  references: TReferenceData[];
  openFocusMode: () => void;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  formId: string;
  highlightsVisible: boolean;
  onHighlightsToggle: () => void;
  decisionFilter: DecisionFilter;
  loadingReferences: boolean;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  unsentTasksCount: number;
  totalReferencesCount: number;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  exclusionReasons: TExclusionReason[];
  domains: DomainData[];
  reasonCodesData: TDomainReasonsData;
  filtersApplied: boolean;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  shiftActiveReference: (direction: -1 | 1) => void;
  searchingReferences?: boolean;
  activeReference?: number;
  onLoadMore?: () => void;
  loadingCounts?: boolean;
  activeReferenceResult?: Pick<
    ScreeningTaskResult,
    'form_id' | 'task_id' | 'result' | 'updated_at' | 'comment_id'
  >;
}

const FTConflictsList: React.FC<IFTConflictsListProps> = ({
  stageId,
  stageType,
  taskCounts,
  references,
  activeReference,
  openFocusMode,
  keywordsData,
  formId,
  highlightsVisible,
  onHighlightsToggle,
  onLoadMore,
  decisionFilter,
  loadingReferences,
  searchingReferences,
  onSendDecisions,
  unsentTasksCount,
  loadingCounts,
  totalReferencesCount,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  activeYearFilters,
  searchPhraseTokens,
  exclusionReasons,
  domains,
  reasonCodesData,
  filtersApplied,
  screeningTags,
  activeScreeningTagFilters,
  documentTypes,
  yearsFilterData,
  activeReferenceResult,
  shiftActiveReference,
}) => {
  const { clearFilters, resetYearsFilter } = useScreeningFilters();
  const setDecisionFilter = useSetDecisionFilter();
  const [yearsRangeFilterState, setYearsRangeFilterState] =
    useState<IYearsRangeFilterState>(yearsFilterData);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<TScreeningPanel | null>('preview');
  const onlySearchKeywords: SearchAndDesiredUndesiredKeywords = useMemo(() => {
    return {
      desiredKeywords: [],
      undesiredKeywords: [],
      searchKeywords: keywordsData.searchKeywords,
    };
  }, [keywordsData.searchKeywords]);
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const { user } = useKeycloak();
  const { sortedBy, onSortBy, onClearSort } = useReferencesListSort(stageType);
  const { data: formData, loading } = useQuery(formDataQuery, { variables: { formId } });
  const form = get('form_by_pk.form', formData);
  const activeReferenceData = activeReference == null ? undefined : references[activeReference];
  const activeReferenceId = activeReferenceData?.id;
  const activeReferenceResultControlId: string | null = useMemo(() => {
    return activeReferenceResult == null
      ? null
      : getTaskResultControlId(activeReferenceResult.result);
  }, [activeReferenceResult]);
  const criteriaMap = useMemo(() => {
    const inclusion = keyBy('id', form?.inclusion as FTScreeningCriteria[]);
    const exclusion = keyBy('id', form?.exclusion as FTScreeningCriteria[]);

    return { ...inclusion, ...exclusion };
  }, [form]);

  const togglePanel = useCallback(
    (panel) => setActivePanel((current) => (current === panel ? null : panel)),
    [setActivePanel]
  );

  const renderCellContent = useCallback(
    (colId: string, reference: TReferenceData) => {
      switch (colId) {
        case 'fullScreen':
          const isActive = activeReferenceId === reference.id;
          return isActive ? (
            <Button
              minimal
              icon={
                <Icon
                  icon={IconNames.FULLSCREEN}
                  intent={Intent.PRIMARY}
                  iconSize={IconSize.LARGE}
                />
              }
              intent={Intent.PRIMARY}
              onClick={openFocusMode}
            />
          ) : null;
        case 'firstAuthorsLastName':
          return (
            <TextWithHighlights
              keywordsData={onlySearchKeywords}
              text={reference.attrs?.authors[0]?.lastName ?? '-'}
            />
          );

        case 'year':
          return reference.attrs?.year ?? '-';
        case 'title':
          return (
            <span title={reference.title}>
              <TextWithHighlights
                className="truncate"
                keywordsData={keywordsData}
                text={reference.title}
              />
            </span>
          );
        case 'conflicts': {
          const tasks: ConflictTaskScreeningResult[] = getConflictTasksFromReference(
            reference,
            stageId
          );
          return (
            <div className="flex flex-row">
              {tasks.map(({ result, team_member }, idx) => {
                const { criteria } = result;

                return (
                  <ConflictLabel
                    key={idx}
                    username={team_member.user?.name ?? i18n._(t`User removed`)}
                    selectedCriteria={
                      isEmpty(criteriaMap)
                        ? []
                        : (criteria ?? []).map(({ id }) =>
                            pick(['code', 'inclusionStatus'], criteriaMap[id])
                          )
                    }
                  />
                );
              })}
            </div>
          );
        }
        case 'finalDecision': {
          const conflictResolutionTaskResult = getTaskResultFromReference(
            reference,
            formId,
            null,
            stageId
          );
          const criteria = compose(
            map(({ id }) => pick(['code', 'inclusionStatus'], criteriaMap[id])),
            get('result.criteria')
          )(conflictResolutionTaskResult);
          return isEmpty(criteria) ? (
            '-'
          ) : (
            <Fragment>
              {criteria.map(({ inclusionStatus, code }) => (
                <DecisionTag
                  className="mr-1"
                  key={code}
                  decision={inclusionStatus}
                  decisionReason={code}
                />
              ))}
            </Fragment>
          );
        }
        case 'documentType':
          return reference.attrs?.documentType ?? '-';
        case 'lastChanged': {
          const screenedTaskResult = getTaskResultFromReference(reference, formId, user.id);
          const conflictResolutionTaskResult = getTaskResultFromReference(
            reference,
            formId,
            null,
            stageId
          );
          const timestamp =
            get('updated_at', conflictResolutionTaskResult) ??
            get('updated_at', screenedTaskResult) ??
            get('import_task.created_at', reference);
          return timestamp ? formatDate(timestamp, 'dd/MM/yyyy HH:mm') : '-';
        }
        default:
          return null;
      }
    },
    [
      openFocusMode,
      onlySearchKeywords,
      formId,
      activeReferenceId,
      user.id,
      keywordsData,
      stageId,
      criteriaMap,
    ]
  );

  const activeReferenceExclusionReason: string | undefined = useMemo(() => {
    return compose(
      get('label'),
      find(propEq('id', activeReferenceResultControlId))
    )(exclusionReasons);
  }, [activeReferenceResultControlId, exclusionReasons]);

  const handleResetYearsFilter = useCallback(() => {
    resetYearsFilter();
    setYearsRangeFilterState(yearsFilterData);
  }, [resetYearsFilter, setYearsRangeFilterState, yearsFilterData]);

  const handleResetReferencesSearch = useCallback(() => {
    clearFilters();
    setYearsRangeFilterState(yearsFilterData);
  }, [clearFilters, setYearsRangeFilterState, yearsFilterData]);

  const setActiveReference = useCallback(
    (activeReference) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: references[activeReference].id },
      });
    },
    [setActiveAndSelectedReferences, references]
  );

  const handleClick = useCallback(
    (rowIdx) => {
      setActiveReference(rowIdx);
    },
    [setActiveReference]
  );

  const filtersToggle = useMemo(
    () =>
      renderFiltersToggle({
        filtersApplied,
        activePanel,
        togglePanel,
      }),
    [togglePanel, activePanel, filtersApplied]
  );

  const previewToggle = useMemo(
    () =>
      renderPreviewToggle({
        activeReference,
        activePanel,
        togglePanel,
      }),
    [togglePanel, activePanel, activeReference]
  );

  const myCurrentComment = useMemo(() => {
    if (activeReferenceData == null) return;

    return getReferenceComment(activeReferenceData, activeReferenceResult);
  }, [activeReferenceData, user, stageId, activeReferenceResult]);

  const handleScrollBottomChange = useCallback((isScrollBottomReached) => {
    setShowLoadMoreButton(isScrollBottomReached);
  }, []);

  // ensure proper decision filter is set on mount
  useEffect(() => {
    setDecisionFilter('to_review');
  }, [setDecisionFilter]);

  useEffect(() => {
    setActivePanel('preview');
  }, [decisionFilter, setActivePanel]);

  return loading ? (
    <Spinner className="h-full" />
  ) : (
    <div className="flex flex-col flex-no-wrap overflow-auto flex-1">
      <FTConflictsListHeader
        {...{
          decisionFilter,
          taskCounts,
          loadingReferences,
          loadingCounts,
          highlightsVisible,
          onHighlightsToggle,
          stageId,
          onSendDecisions,
          openFocusMode,
          references,
          leftElement: filtersToggle,
          rightElement: previewToggle,
        }}
      />

      <div className="flex flex-row w-full flex-grow overflow-auto">
        <div
          className={`${Classes.ELEVATION_0} bg-white`}
          css={[activePanel === 'filters' ? panelExpandedColCss : panelCollapsedColCss]}
        >
          <FiltersColumn
            stageType={stageType}
            domains={domains}
            reasonCodesData={reasonCodesData}
            documentTypes={documentTypes}
            yearsFilterData={yearsFilterData}
            yearsRangeFilterState={yearsRangeFilterState}
            setYearsRangeFilterState={setYearsRangeFilterState}
            activeKeywordFilters={activeKeywordFilters}
            activeDecisionCodeFilters={activeDecisionCodeFilters}
            activeDocumentTypeFilters={activeDocumentTypeFilters}
            onToggle={() => togglePanel('filters')}
            onResetReferencesSearch={filtersApplied ? handleResetReferencesSearch : undefined}
            screeningTags={screeningTags}
            activeScreeningTagFilters={activeScreeningTagFilters}
          />
        </div>

        <div className="h-full flex-1 flex flex-col flex-no-wrap overflow-auto">
          {filtersApplied && (
            <ActiveFiltersBar
              screeningTags={screeningTags}
              phraseTokens={searchPhraseTokens}
              keywordFilters={activeKeywordFilters}
              decisionCodeFilters={activeDecisionCodeFilters}
              documentTypeFilters={activeDocumentTypeFilters}
              screeningTagFilters={activeScreeningTagFilters}
              yearsFilters={activeYearFilters}
              onYearsFilterReset={handleResetYearsFilter}
            />
          )}
          <div
            className="flex-1 flex flex-col flex-no-wrap overflow-hidden relative"
            css={containerCss}
          >
            {isEmpty(references) ? (
              !filtersApplied && decisionFilter === 'to_review' && unsentTasksCount > 0 ? (
                <NonIdealState
                  icon={IconNames.CLEAN}
                  title={<Trans>All conflicts have been resolved</Trans>}
                  action={
                    <SendDecisionsButton
                      stageId={stageId}
                      taskType={ScreeningTaskType.ConflictResolution}
                      onSend={onSendDecisions}
                    />
                  }
                />
              ) : !filtersApplied && decisionFilter === 'to_review' && unsentTasksCount === 0 ? (
                <NonIdealState icon={IconNames.ENDORSED} title={<Trans>Thank you!</Trans>}>
                  <Trans>All done</Trans>.
                </NonIdealState>
              ) : (
                <NonIdealState
                  icon={<Icon icon={IconNames.DOCUMENT} color={Colors.GRAY1} iconSize={30} />}
                  title={<Trans>There are no references in selected category</Trans>}
                />
              )
            ) : (
              <ReferencesTable
                className="h-full"
                numRows={references.length}
                rows={references}
                cols={COLS}
                cellContentRenderer={renderCellContent}
                activeRow={activeReference}
                onClick={handleClick}
                onDoubleClick={openFocusMode}
                tableId={decisionFilter}
                onSortBy={onSortBy}
                sortedBy={sortedBy}
                onClearSort={onClearSort(decisionFilter)}
                onScrollBottomChange={handleScrollBottomChange}
              />
            )}
            {onLoadMore && (
              <LoadMoreReferencesButton
                showLoadMoreButton={showLoadMoreButton}
                referenceCount={references.length}
                totalReferencesCount={totalReferencesCount}
                onLoadMore={onLoadMore}
                loadingMore={loadingReferences}
              />
            )}
          </div>
        </div>
        <div
          className={`${Classes.ELEVATION_0} bg-white`}
          css={[activePanel === 'preview' ? panelExpandedColCss : panelCollapsedColCss]}
        >
          <PreviewColumn
            onToggle={() => togglePanel('preview')}
            reference={activeReferenceData}
            highlightsVisible={highlightsVisible}
            exclusionReason={activeReferenceExclusionReason}
            keywordsData={keywordsData}
            comment={myCurrentComment?.comment}
            inclusionStatus={get('result.inclusionStatus', activeReferenceResult)}
            loading={searchingReferences}
            onUnreview={noop}
            withDecisionIndicator
          />
        </div>
      </div>
      <NavAndDecisionControls
        onlyHotkeys
        activeControl={activeReferenceResultControlId ?? undefined}
        onShiftActiveReference={shiftActiveReference}
        onEnter={openFocusMode}
        onToggleActiveReferenceSelect={noop}
        onInclude={noop}
        onExclude={noop}
        onPostpone={noop}
        exclusionReasons={[]}
      />
    </div>
  );
};

export default FTConflictsList;
