/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Colors, Text } from '@blueprintjs/core';
import { ReactNode } from 'react';

const filterItemCss = css`
  border-radius: 5px;

  &.selected,
  &:hover,
  .filter-item__count {
    background-color: ${Colors.LIGHT_GRAY3};
  }

  .filter-item__label,
  .filter-item__count {
    padding: 10px;
  }

  .filter-item__count {
    padding: 10px 15px;
    border-radius: 5px;
  }
`;

interface IFilterItemProps {
  label: ReactNode;
  count: number;
  selected?: boolean;
  onClick?: () => any;
}

const FilterItem: React.FC<IFilterItemProps> = ({ label, count, selected, onClick }) => (
  <div
    css={filterItemCss}
    className={`flex flex-row flex-no-wrap justify-between items-center cursor-pointer my-4 ${
      selected ? 'selected' : ''
    }`}
    onClick={onClick}
  >
    <Text className="filter-item__label text-base" ellipsize>
      {label}
    </Text>
    <span className="filter-item__count">{count}</span>
  </div>
);

export default FilterItem;
