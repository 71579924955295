/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Hotkey, Hotkeys, HotkeysTarget, Icon, Intent } from '@blueprintjs/core';
import DecisionControls, {
  ControlSpec,
  ControlType,
  iconCss,
} from './title_and_abstract/decision_controls';
import { TExclusionReason } from '.';
import { find, isEmpty, isNil, propEq } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { t } from '@lingui/macro';
import i18n from '../../i18n';
import { ScreeningHistoryLength } from './title_and_abstract';

const alternateExclusionHotkeysEnabled: boolean =
  window.REACT_APP_ALTERNATE_EXCLUSION_HOTKEYS_ENABLED === 'true';
const alternateInclusionHotkey: string | undefined = window.REACT_APP_ALTERNATE_INCLUSION_HOTKEY;

const EXCLUSION_HOTKEYS_COUNT = alternateExclusionHotkeysEnabled
  ? 10 // keys: 1-0
  : 12; //keys F1-F12

function getExclusionControlLabel(idx: number): string {
  return alternateExclusionHotkeysEnabled
    ? `${(idx + 1) % EXCLUSION_HOTKEYS_COUNT}`
    : `f${idx + 1}`;
}

function getExclusionControlShortcutIcon(idx: number) {
  return idx < EXCLUSION_HOTKEYS_COUNT ? (
    <span css={iconCss}>{getExclusionControlLabel(idx).toUpperCase()}</span>
  ) : undefined;
}

const INCLUDE_CONTROL: ControlSpec = {
  id: 'in',
  text: i18n._(t`IN`),
  intent: Intent.SUCCESS,
  shortcutIcon: alternateInclusionHotkey ? (
    <span css={iconCss}>{alternateInclusionHotkey.toUpperCase()}</span>
  ) : (
    <Icon css={iconCss} icon={IconNames.ARROW_LEFT} size={10} />
  ),
  type: 'standard',
};

const POSTPONE_CONTROL: ControlSpec = {
  id: 'postpone',
  text: i18n._(t`POSTPONE`),
  intent: Intent.NONE,
  type: 'standard',
};

interface INavAndDecisionControlsProps {
  exclusionReasons: TExclusionReason[];
  onToggleActiveReferenceSelect: () => void;
  onExclude: (reasonCode: string) => void;
  onInclude: () => void;
  onPostpone: () => void;
  onShiftActiveReference?: (direction: -1 | 1) => void;
  onEnter?: () => void;
  onUndoScreeningDecision?: () => void;
  onRedoScreeningDecision?: () => void;
  onCloseFocusMode?: () => void;
  vertical?: boolean;
  activeControl?: string;
  groupExclusionReasons?: boolean;
  screeningHistoryLength?: ScreeningHistoryLength;
  visibleExclusionReasonsLimit?: number;
  onlyHotkeys?: boolean;
  disabled?: boolean;
}

interface INavAndDecisionControlsState {
  activeControl?: string;
}

class NavAndDecisionControls extends React.PureComponent<
  INavAndDecisionControlsProps,
  INavAndDecisionControlsState
> {
  handleControl(controlId: string, controlType: ControlType) {
    if (controlType !== 'navigation' && this.props.disabled) return;
    switch (controlId) {
      case 'in':
        return this.props.onInclude();
      case 'back':
        return this.props.onShiftActiveReference?.(-1);
      case 'postpone':
        return this.props.onPostpone();
      default:
        if (this.props.exclusionReasons.some(propEq('id', controlId))) {
          return this.props.onExclude(controlId);
        }
        return;
    }
  }

  public renderHotkeys() {
    const { disabled, screeningHistoryLength } = this.props;
    return (
      <Hotkeys>
        <Hotkey
          disabled={disabled}
          global
          combo="esc"
          label={i18n._(t`Close focus mode`)}
          onKeyDown={() => {
            this.props.onCloseFocusMode && this.props.onCloseFocusMode();
          }}
        />
        <Hotkey
          disabled={disabled}
          global
          combo="enter"
          label={i18n._(t`Enter focus mode`)}
          onKeyDown={() => {
            this.props.onEnter?.();
          }}
        />
        <Hotkey
          disabled={disabled}
          global
          combo="f"
          label={i18n._(t`Enter focus mode`)}
          onKeyDown={() => {
            this.props.onEnter?.();
          }}
        />
        <Hotkey
          global
          disabled={isNil(this.props.onShiftActiveReference)}
          combo="up"
          label={i18n._(t`Activate previous row`)}
          onKeyDown={() => {
            this.setState({ activeControl: 'back' });
            this.props.onShiftActiveReference?.(-1);
          }}
          onKeyUp={() => this.setState({ activeControl: undefined })}
        />
        <Hotkey
          global
          disabled={isNil(this.props.onShiftActiveReference)}
          combo="down"
          label={i18n._(t`Activate next row`)}
          onKeyDown={() => {
            this.setState({ activeControl: 'skip' });
            this.props.onShiftActiveReference?.(1);
          }}
          onKeyUp={() => this.setState({ activeControl: undefined })}
        />
        <Hotkey
          disabled={disabled}
          global
          combo="space"
          label={i18n._(t`Select/unselect active row`)}
          onKeyUp={this.props.onToggleActiveReferenceSelect}
        />
        <Hotkey
          disabled={disabled}
          global
          combo={alternateInclusionHotkey ?? 'left'}
          label={i18n._(t`Include selected/active reference`)}
          onKeyDown={() => {
            this.setState({ activeControl: 'in' });
          }}
          onKeyUp={() => {
            this.props.onInclude();
            this.setState({ activeControl: undefined });
          }}
        />
        <Hotkey
          disabled={disabled || !screeningHistoryLength || screeningHistoryLength.undoLength === 0}
          global
          combo="ctrl+z"
          label={i18n._(t`Undo screening decision`)}
          onKeyUp={() => {
            this.props.onUndoScreeningDecision!();
          }}
        />
        <Hotkey
          disabled={disabled || !screeningHistoryLength || screeningHistoryLength.redoLength === 0}
          global
          combo="ctrl+y"
          label={i18n._(t`Redo screening decision`)}
          onKeyUp={() => {
            this.props.onRedoScreeningDecision!();
          }}
        />
        {/*
            DEFAULT:
            there are only 12 fn keys on the keyboard, so we limit the number of exclusion hotkeys
            to 12.
            ALTERNATE HOTKEYS ENABLED:
            limit only to 10 hotkeys (1-0 on the keyboard)
         */}
        {this.props.exclusionReasons.slice(0, EXCLUSION_HOTKEYS_COUNT).map(({ id, label }, idx) => (
          <Hotkey
            disabled={disabled}
            key={id}
            global
            combo={getExclusionControlLabel(idx)}
            label={label}
            preventDefault
            onKeyDown={() => {
              this.setState({ activeControl: id });
            }}
            onKeyUp={() => {
              this.props.onExclude(id);
              this.setState({ activeControl: undefined });
            }}
          />
        ))}
      </Hotkeys>
    );
  }

  private groupExclusionControls(
    exclusionControls: ControlSpec[],
    groupControlSpec?: Partial<ControlSpec>
  ): ControlSpec {
    const selectedReason = find(
      propEq('id', this.props.activeControl),
      this.props.exclusionReasons
    );
    const label = selectedReason == null ? i18n._(t`Out`) : selectedReason.label;

    return {
      text: label,
      subControls: exclusionControls,
      id: 'exclusionControlsGroup',
      intent: Intent.DANGER,
      type: 'standard',
      ...(groupControlSpec ?? {}),
    };
  }

  private getControls() {
    const { groupExclusionReasons, visibleExclusionReasonsLimit } = this.props;
    let result: ControlSpec[] = [INCLUDE_CONTROL];
    const exclusionControls: ControlSpec[] = this.props.exclusionReasons.map(
      ({ id, label }, idx) => ({
        id,
        text: label,
        intent: Intent.DANGER,
        shortcutIcon: getExclusionControlShortcutIcon(idx),
        type: 'standard',
      })
    );

    if (groupExclusionReasons) {
      result.push(this.groupExclusionControls(exclusionControls));
    } else {
      const controlsToGroup =
        visibleExclusionReasonsLimit == null
          ? []
          : exclusionControls.slice(visibleExclusionReasonsLimit);

      result = isEmpty(controlsToGroup)
        ? [...result, ...exclusionControls]
        : [
            ...result,
            ...exclusionControls.slice(0, visibleExclusionReasonsLimit),
            this.groupExclusionControls(controlsToGroup, {
              text: i18n._(t`More exclusion reasons`),
            }),
          ];
    }
    result.push(POSTPONE_CONTROL);
    return result;
  }

  public render() {
    const vertical = this.props.vertical ?? true;

    return this.props.onlyHotkeys ? (
      <Fragment>{this.props.children}</Fragment>
    ) : (
      <div className={`flex ${vertical ? 'flex-col overflow-auto' : 'flex-row'} w-full h-full`}>
        <DecisionControls
          disabled={this.props.disabled}
          onClick={this.handleControl}
          activeControl={this.state.activeControl}
          vertical={vertical}
          controls={this.getControls()}
        />
        {this.props.children}
      </div>
    );
  }
}
// https://github.com/vercel/next.js/issues/8973
function Wrapper(this: any, props: INavAndDecisionControlsProps) {
  React.PureComponent.call(this, props);

  this.state = { activeControl: props.activeControl };
  this.handleControl = this.handleControl.bind(this);
}
Wrapper.prototype = Object.create(NavAndDecisionControls.prototype);
Wrapper.getDerivedStateFromProps = (
  props: INavAndDecisionControlsProps,
  _state: INavAndDecisionControlsState
) => {
  return { activeControl: props.activeControl };
};

export default HotkeysTarget(
  Wrapper as unknown as new (props: INavAndDecisionControlsProps) => NavAndDecisionControls
);
