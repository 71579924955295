/** @jsx jsx */
import { Button, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash/fp';
import React, { useEffect } from 'react';
import { TExclusionReason, TReferenceData } from '.';
import {
  DecisionFilter,
  ORDER_BY_RANK_AND_UPDATED_AT,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveScreeningTagFilters,
  YearFilters,
} from '../../apollo/screening_state';
import { StageType } from '../../common/types';
import {
  EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS,
  SearchAndDesiredUndesiredKeywords,
} from '../../lib/criteria_utils';
import { TTaskCounts } from '../../lib/task_helpers';
import ProgressBar from '../common/progress_bar';
import useReferencesListSort from '../hooks/use_references_list_sort';
import { useScreeningFilters } from '../hooks/use_screening_filters';
import { TScreeningTag } from '../references/admin';
import ActiveFiltersBar from './active_filters_bar';
import ReferencesList from './references_list';
import SendDecisionsButton from './send_decisions_button';

interface IReferencesListColumnProps {
  stageId: string;
  stageType: StageType;
  screeningTags: TScreeningTag[];
  selectedReferences: number[];
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  setActiveReference: (number) => void;
  taskCounts: TTaskCounts;
  decisionFilter: DecisionFilter;
  formId: string;
  orderedBy: object;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  highlightsVisible: boolean;
  loadingMoreReferences: boolean;
  exclusionReasons: TExclusionReason[];
  references: readonly TReferenceData[];
  unsentTasksCount: number;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  filtersApplied: boolean;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  onYearsFilterReset: () => void;
  showProgress?: boolean;
  openFocusMode?: () => void;
  searchingReferences?: boolean;
  onLoadMoreReferences?: () => void;
  activeReference?: number;
  totalReferencesCount: number;
}

const ReferencesListColumn: React.FC<IReferencesListColumnProps> = ({
  stageId,
  stageType,
  screeningTags,
  showProgress,
  references,
  filtersApplied,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  activeScreeningTagFilters,
  activeYearFilters,
  searchPhraseTokens,
  onYearsFilterReset,
  activeReference,
  selectedReferences,
  setSelectedReferences,
  setActiveReference,
  taskCounts,
  searchingReferences,
  decisionFilter,
  formId,
  onLoadMoreReferences,
  orderedBy,
  openFocusMode,
  keywordsData,
  highlightsVisible,
  loadingMoreReferences,
  exclusionReasons,
  onSendDecisions,
  unsentTasksCount,
  totalReferencesCount,
}) => {
  const { onSortBy, onClearSort, sortedBy, resetSortedBy } = useReferencesListSort(stageType);
  const { clearFilters } = useScreeningFilters();

  // In case of new ranking application (down outside of this component), the ordered will be forced
  // to study.score. In that case we need to reset the current sortedBy value.
  useEffect(() => {
    if (orderedBy === ORDER_BY_RANK_AND_UPDATED_AT) {
      resetSortedBy();
    }
  }, [orderedBy, resetSortedBy]);

  return (
    <div className="h-full flex flex-col overflow-auto">
      {filtersApplied && (
        <ActiveFiltersBar
          screeningTags={screeningTags}
          phraseTokens={searchPhraseTokens}
          keywordFilters={activeKeywordFilters}
          decisionCodeFilters={activeDecisionCodeFilters}
          documentTypeFilters={activeDocumentTypeFilters}
          screeningTagFilters={activeScreeningTagFilters}
          yearsFilters={activeYearFilters}
          onYearsFilterReset={onYearsFilterReset}
        />
      )}
      {showProgress && (
        <ProgressBar
          studiesIn={taskCounts?.included ?? 0}
          studiesOut={taskCounts?.excluded ?? 0}
          total={taskCounts?.total ?? 0}
          loading={searchingReferences}
        />
      )}
      {searchingReferences ? (
        <Spinner className="h-full" />
      ) : isEmpty(references) ? (
        filtersApplied ? (
          <NonIdealState
            action={<Button onClick={clearFilters} text={<Trans>Clear all filters</Trans>} />}
            icon={IconNames.DOCUMENT}
            title={
              <Trans>
                No references found matching your criteria <br />
                in selected category
              </Trans>
            }
          />
        ) : decisionFilter === 'to_review' && taskCounts.postponed === 0 && unsentTasksCount > 0 ? (
          <NonIdealState
            title={<Trans>All tasks have been completed!</Trans>}
            action={
              <SendDecisionsButton className="w-40" stageId={stageId} onSend={onSendDecisions} />
            }
          />
        ) : decisionFilter === 'to_review' && unsentTasksCount === 0 ? (
          <NonIdealState title={<Trans>Thank you!</Trans>} icon={IconNames.ENDORSED}>
            <Trans>All done</Trans>.
          </NonIdealState>
        ) : (
          <NonIdealState
            icon={IconNames.DOCUMENT}
            title={<Trans>There are no references in selected category</Trans>}
          />
        )
      ) : (
        <ReferencesList
          formId={formId}
          references={references}
          activeReference={activeReference}
          selectedReferences={selectedReferences}
          onChangeSelectedReferences={setSelectedReferences}
          onChangeActiveReference={setActiveReference}
          onLoadMore={onLoadMoreReferences}
          decisionFilter={decisionFilter}
          onSortBy={onSortBy}
          sortedBy={sortedBy}
          screeningTags={screeningTags}
          onClearSort={onClearSort(decisionFilter)}
          openFocusMode={openFocusMode}
          totalReferencesCount={totalReferencesCount}
          keywordsData={
            highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
          }
          exclusionReasons={exclusionReasons}
          loadingMore={loadingMoreReferences}
          stageType={stageType}
          stageId={stageId}
        />
      )}
    </div>
  );
};

export default ReferencesListColumn;
