/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useCallback, useMemo } from 'react';
import { Classes, Button, NonIdealState, Colors, InputGroup, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { fixedHeaderTable, defaultTableCss } from '../../common/styles';
import { useCurrCallback, useI18n } from '../../lib/utils';
import { formatDateDistance, getLocalizedDateTime } from '../project/helpers';
import { UserData, MemberedProject } from './users_panel';
import CreateUserDialog from './edit_user_dialog';
import { t, Trans } from '@lingui/macro';
import i18n from '../../i18n';
import { find, propEq, isEmpty, kebabCase, includes, debounce } from 'lodash/fp';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const AddUserMutation = gql`
  mutation AddUser($user: UserInsertInput!) {
    insert_user(object: $user) {
      id
    }
  }
`;

const usersTableCss = css`
  ${fixedHeaderTable}
  ${defaultTableCss}
`;

interface IUsersListProps {
  users: UserData[];
  onUserSelect: (userId: string) => void;
  userProjects: { [userId: string]: MemberedProject[] };
  userActionLogs: {
    server_timestamp: string;
    user_id: string[];
  }[];
}

const headerCss = css`
  background-color: ${Colors.LIGHT_GRAY4};
`;

const COL_NAMES = {
  name: i18n._(t`Name`),
  projectsCount: i18n._(t`Active projects assigned`),
  createdAt: i18n._(t`Account created`),
  lastSeen: i18n._(t`Last activity`),
};

const COLS = ['name', 'projectsCount', 'createdAt', 'lastSeen'];

const UsersList: React.FC<IUsersListProps> = ({
  users,
  userProjects,
  onUserSelect,
  userActionLogs,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [saveUser] = useMutation(AddUserMutation);
  const i18n = useI18n();

  const filteredUsers = useMemo(() => {
    if (isEmpty(filterQuery)) return users;

    return users.filter((user) => includes(filterQuery, user.name.toLowerCase()));
  }, [filterQuery, users]);

  const setFilterQueryDebounced = useCallback(
    debounce(300, (query: string) => {
      setFilterQuery(query.toLowerCase());
    }),
    [setFilterQuery]
  );

  const handleSearchQueryChange = useCallback(
    (evt) => {
      const query = evt.target.value;
      setSearchQuery(query);
      isEmpty(query) ? setFilterQuery(query) : setFilterQueryDebounced(query);
    },
    [setSearchQuery, setFilterQuery, setFilterQueryDebounced]
  );

  const getCellContent = useCallback(
    (colId: string, user: UserData) => {
      const projects = userProjects[user.id] ?? [];
      switch (colId) {
        case 'name':
          return user.name;
        case 'projectsCount':
          return projects.length;
        case 'createdAt':
          return getLocalizedDateTime(user.createdAt);
        case 'lastSeen':
          const userLastLog = find(propEq('user_id', user.id), userActionLogs);

          return userLastLog ? (
            formatDateDistance(userLastLog.server_timestamp)
          ) : (
            <Tag minimal>
              <Trans>Activation pending</Trans>
            </Tag>
          );
        default:
          return '-';
      }
    },
    [userProjects, userActionLogs]
  );

  const handleRowClick = useCurrCallback(
    (userId, _evt) => {
      onUserSelect(userId);
    },
    [onUserSelect]
  );

  const handleUserCreate = useCallback(
    (userData) => {
      return saveUser({
        variables: {
          user: {
            ...userData,
            // keycloak's UserRole enum doesn't contain 'gba-app:' prefix
            role: userData.role.replace('gba-app:', ''),
          },
        },
      });
    },
    [saveUser]
  );

  return (
    <div className="h-full flex flex-col overflow-auto">
      <div css={headerCss} className={`p-5 ${Classes.ELEVATION_0}`}>
        <div className="flex flex-row flex-no-wrap items-center justify-between">
          <div className="flex flex-row items-center w-1/2">
            <span className="mr-8">
              <Trans>All users</Trans>
            </span>
            <InputGroup
              className="flex-1"
              type="search"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              leftIcon={IconNames.SEARCH}
              placeholder={`${i18n._(t`Search`)}...`}
            />
          </div>
          <Button
            className="px-6"
            text={<Trans>Add new user</Trans>}
            onClick={() => setCreateUserDialogOpen(true)}
          />
          <CreateUserDialog
            isOpen={createUserDialogOpen}
            onClose={() => setCreateUserDialogOpen(false)}
            onSave={handleUserCreate}
          />
        </div>
      </div>
      <div className="flex flex-col overflow-auto flex-1 px-5">
        {isEmpty(filteredUsers) ? (
          isEmpty(searchQuery) ? (
            <NonIdealState
              icon={IconNames.HEART_BROKEN}
              title={<Trans>No users yet</Trans>}
              description={<Trans>There are no users at the moment. Why not create one?</Trans>}
              action={
                <Button
                  className="px-6"
                  onClick={() => setCreateUserDialogOpen(true)}
                  text={<Trans>Add new user</Trans>}
                />
              }
            />
          ) : (
            <NonIdealState icon={IconNames.SEARCH} title={<Trans>Users not found</Trans>} />
          )
        ) : (
          <table className="w-full table-fixed border-collapse" css={usersTableCss}>
            <thead>
              <tr>
                {COLS.map((colId) => (
                  <th key={colId} className={kebabCase(colId)}>
                    {i18n._(t`${COL_NAMES[colId]}`)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={Classes.ELEVATION_1}>
              {filteredUsers.map((user) => (
                <tr key={user.id} onClick={handleRowClick(user.id)}>
                  {COLS.map((colId) => (
                    <td key={colId} className={kebabCase(colId)}>
                      {getCellContent(colId, user)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UsersList;
