/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Immutable from 'immutable';
import { Trans } from '@lingui/macro';
import React, { ReactNode, useCallback, useState } from 'react';
import { useCurrCallback } from '../../../lib/utils';
import { Button, Checkbox, Collapse, Colors, Divider, Radio, RadioGroup } from '@blueprintjs/core';
import { FTScreeningCriteria, FTScreeningFormData } from '../../../common/types';
import { lightGray5bg } from '../../../common/styles';
import { noop } from 'lodash/fp';
import { useTheme } from '../../settings/theme_context';

const criteriaItemCss = css`
  border: 1px solid ${Colors.LIGHT_GRAY1};
  ${lightGray5bg};

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .criteria-checkbox-container {
    padding-left: 8px;
  }

  .instruction-toggle {
    color: ${Colors.GRAY1};
  }

  .criteria-label {
    min-height: 38px;
    border-left: 1px solid ${Colors.LIGHT_GRAY1};
  }

  .criteria-instruction {
    font-size: 12px;
  }
`;

interface IScreeningProps {
  form: FTScreeningFormData;
  selectedCriteria: Immutable.Set<FTScreeningCriteria>;
  onCriteriaSelect: (criteria: FTScreeningCriteria) => void;
  readOnly?: boolean;
}

const Screening: React.FC<IScreeningProps> = ({
  form,
  selectedCriteria,
  onCriteriaSelect,
  readOnly,
}) => {
  const { statusColors } = useTheme();

  const [expandedInstructions, setExpandedInstructions] = useState<Immutable.Set<string>>(
    Immutable.Set()
  );

  const handleCriteriaSelect = useCurrCallback(
    (criteria: FTScreeningCriteria, _evt) => {
      onCriteriaSelect(criteria);
    },
    [onCriteriaSelect]
  );

  const toggleInstructions = useCurrCallback(
    (criteriaId: string, _evt) => {
      setExpandedInstructions((current) =>
        current.includes(criteriaId) ? current.remove(criteriaId) : current.add(criteriaId)
      );
    },
    [setExpandedInstructions]
  );

  const renderCriteriaItems = useCallback(
    (
      CriteriaItemComponent: typeof Checkbox | typeof Radio,
      criteriaList: FTScreeningCriteria[]
    ): ReactNode => {
      return criteriaList.map((criteria) => {
        const { id, name, code, instruction } = criteria;
        return (
          <div key={id} css={criteriaItemCss} className="criteria-item">
            <div className="criteria-checkbox-container flex flex-row overflow-hidden">
              <CriteriaItemComponent
                disabled={readOnly}
                labelElement={
                  <div title={name} className="criteria-label truncate py-2 pl-2">
                    {code ? `${code}: ${name}` : name}
                  </div>
                }
                checked={selectedCriteria.has(criteria)}
                onChange={handleCriteriaSelect(criteria)}
                className="mb-0 px-4 flex items-center flex-1 overflow-hidden"
              />
              {instruction && (
                <React.Fragment>
                  <Divider className="m-0" />
                  <Button
                    text={code}
                    minimal
                    className="instruction-toggle flex-none w-8"
                    onClick={toggleInstructions(id)}
                  />
                </React.Fragment>
              )}
            </div>
            <Collapse isOpen={expandedInstructions.includes(id)}>
              <div className="px-3 py-2 border-t border-gray-400 criteria-instruction">
                {instruction}
              </div>
            </Collapse>
          </div>
        );
      });
    },
    [handleCriteriaSelect, toggleInstructions, expandedInstructions, readOnly, selectedCriteria]
  );

  return (
    <div>
      <div data-testid="ft-criteria-block">
        <div
          className="m-4 mb-0 pb-4 border-b-8"
          css={{ borderColor: statusColors.acceptedPrimary }}
        >
          <span className="text-base">
            <Trans>Inclusion</Trans>
          </span>
        </div>
        <div className="p-4">{renderCriteriaItems(Checkbox, form.inclusion)}</div>
      </div>
      <div data-testid="ft-criteria-block">
        <div
          className="m-4 mb-0 pb-4 border-b-8"
          css={{ borderColor: statusColors.rejectedPrimary }}
        >
          <span className="text-base">
            <Trans>Exclusion</Trans>
          </span>
        </div>
        <div className="p-4">
          <RadioGroup onChange={noop}>{renderCriteriaItems(Radio, form.exclusion)}</RadioGroup>
        </div>
      </div>
    </div>
  );
};

export default Screening;
