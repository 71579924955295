import { useSubscription } from '@apollo/react-hooks';
import { Colors, Spinner } from '@blueprintjs/core';
import gql from 'graphql-tag';
import { get, merge } from 'lodash/fp';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { StatusColorSettings, UserSettings } from '../../common/types';
import { useKeycloak } from '../../keycloak';

const UserSettingsSubscription = gql`
  subscription UserSettings($userId: String!) {
    settingsData: user_settings_by_pk(user_id: $userId) {
      user_id
      settings
    }
  }
`;

interface ThemeContext {
  statusColors: StatusColorSettings;
}

export const DEFAULT_THEME: ThemeContext = {
  statusColors: {
    neutralPrimary: Colors.GRAY2,
    neutralSecondary: Colors.WHITE,
    acceptedPrimary: Colors.GREEN4,
    acceptedSecondary: Colors.WHITE,
    rejectedPrimary: Colors.RED4,
    rejectedSecondary: Colors.WHITE,
    conflictsPrimary: Colors.ORANGE4,
    conflictsSecondary: Colors.WHITE,
  },
};

const themeContext = React.createContext<ThemeContext>(DEFAULT_THEME);

interface IThemeProviderProps {
  waitUserSettings?: boolean;
}

export const ThemeProvider: React.FC<PropsWithChildren<IThemeProviderProps>> = ({
  children,
  waitUserSettings,
}) => {
  const { user, isItAdmin } = useKeycloak();
  const { data, loading } = useSubscription<UserSettings, { userId: string }>(
    UserSettingsSubscription,
    { variables: { userId: user.id }, skip: isItAdmin }
  );
  const [theme, updateTheme] = useState(DEFAULT_THEME);

  useEffect(() => {
    const statusColorsSettings: StatusColorSettings | undefined = get(
      'settingsData.settings.statusColors',
      data
    );
    const statusColorsMode: 'default' | 'custom' | undefined = get(
      'settingsData.settings.statusColorsMode',
      data
    );

    if (statusColorsMode !== 'custom') {
      return updateTheme({ statusColors: DEFAULT_THEME.statusColors });
    }

    if (statusColorsSettings == null) return;

    updateTheme((currentTheme) => ({
      ...currentTheme,
      statusColors: merge(currentTheme.statusColors, statusColorsSettings),
    }));
  }, [data, updateTheme]);

  return (
    <themeContext.Provider value={theme}>
      {waitUserSettings && loading ? <Spinner /> : children}
    </themeContext.Provider>
  );
};

export const useTheme: () => ThemeContext = () => useContext(themeContext);
