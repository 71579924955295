import Immutable from 'immutable';
import { find, get, map, mapValues, merge, isNil } from 'lodash/fp';
import { loader } from 'graphql.macro';
import { StrFragmentSpec } from '../lib/decorated_string';
import { LocalState, GQLType } from './local_states';
import { SearchResults } from '../lib/pdf_search_provider';
import { searchResultsToQueryMatches } from '../lib/pdf_search_utils';
import { getLocalStateUpdater } from '../lib/utils';
import { GenPDFTextRanges } from '../common/types';

const PDFViewerStateQuery = loader('../graphql/local/get_pdf_viewer_state.gql');
const activePDFPageQuery = loader('../graphql/local/get_active_pdf_page_data.gql');
const PDFSearchDataQuery = loader('../graphql/local/get_pdf_search_data.gql');

export const INITIAL_PDF_ROTATION_ANGLE = 0;
export const INITIAL_PDF_SCALE = 1.2;
export const INITIAL_ACTIVE_PAGE = 1;
export const INITIAL_NUM_PAGES = 0;

export type PDFTextRanges = GenPDFTextRanges<StrFragmentSpec>;

export enum PDFTextRangeType {
  search = 'searchResult',
  selection = 'selectedRange',
  custom = 'customRange',
}

export type DocActiveRanges = Record<PDFTextRangeType, PDFTextRanges>;

export interface PDFSearchData extends GQLType {
  query: string | null;
  isSearching: boolean;
  isOpen: boolean;
  results: SearchResults;
  progress: number;
  searchKey: string | null;
  ignoredResults: SearchResults;
  matches: SearchResults[];
  activeMatchRanges: SearchResults;
}

export interface PDFTextSelectionData extends GQLType {
  text: string | null;
  ranges: PDFTextRanges;
}

interface PDFViewerState extends GQLType {
  pdfScale: number;
  numPages: number;
  activePage: number;
  pdfRotationAngle: number;
  scrollToRange: string | null;
  scrollToPage: number | null;
  search: PDFSearchData;
  selection: PDFTextSelectionData;
  customRanges: PDFTextRanges;
}

function mapRanges<T, U>(
  ranges: GenPDFTextRanges<T>,
  mapper: (range: T) => U
): GenPDFTextRanges<U> {
  return mapValues(mapValues(map(mapper)), ranges);
}

export const initialPDFSearchData = {
  activeMatchRanges: {},
  matches: [],
  ignoredResults: {},
  isSearching: false,
  progress: 0,
  query: null,
  results: {},
  searchKey: null,
  isOpen: false,
};

export const initialSelectionData = {
  text: null,
  ranges: {},
};

export const initialStateProvider = (): { [key: string]: PDFViewerState } => ({
  PDFViewer: {
    __typename: 'PDFViewer',
    activePage: INITIAL_ACTIVE_PAGE,
    numPages: INITIAL_NUM_PAGES,
    pdfScale: INITIAL_PDF_SCALE,
    pdfRotationAngle: INITIAL_PDF_ROTATION_ANGLE,
    customRanges: {},
    scrollToPage: null,
    scrollToRange: null,
    search: {
      __typename: 'PDFSearchData',
      ...initialPDFSearchData,
    },
    selection: {
      __typename: 'PDFTextSelectionData',
      ...initialSelectionData,
    },
  },
});

const updateState = getLocalStateUpdater<PDFViewerState>('PDFViewer', PDFViewerStateQuery);

const PDFViewerState: LocalState = {
  name: 'PDFViewer',
  rootQuery: PDFViewerStateQuery,
  initial: initialStateProvider,
  resolvers: {
    Query: {
      // There are 3 sources of active ranges: manual selection, search results and custom ranges
      // activated with setCustomPDFTextRanges mutation from outside. This query combines them
      // together.
      getActivePDFRanges: (_root, { pageNum }, { cache }): DocActiveRanges => {
        const {
          PDFViewer: { search, selection, customRanges },
        }: { PDFViewer: PDFViewerState } = cache.readQuery({
          query: PDFViewerStateQuery,
        });

        const searchResults = mapRanges(
          isNil(pageNum) ? search.results : { [pageNum]: search.results[pageNum] || {} },
          (range) => merge({ ...range }, { data: { type: PDFTextRangeType.search } })
        );
        const selectedRanges = mapRanges(
          isNil(pageNum) ? selection.ranges : { [pageNum]: selection.ranges[pageNum] || {} },
          (range) => merge({ ...range }, { data: { type: PDFTextRangeType.selection } })
        );

        const _customRanges = mapRanges(
          isNil(pageNum) ? customRanges : { [pageNum]: customRanges[pageNum] || {} },
          (range) => merge({ ...range }, { data: { type: PDFTextRangeType.custom } })
        );

        return {
          [PDFTextRangeType.selection]: selectedRanges,
          [PDFTextRangeType.search]: searchResults,
          [PDFTextRangeType.custom]: _customRanges,
        };
      },

      isSearchMatchIgnored: (_root, { pageNum, itemIndex, offset, length }, { cache }) => {
        const { PDFViewer } = cache.readQuery({ query: PDFSearchDataQuery });
        const ignoreItemMatches = get([pageNum, itemIndex], PDFViewer.search.ignoredResults);

        return ignoreItemMatches && !isNil(find({ offset, length }, ignoreItemMatches));
      },
    },
    Mutation: {
      scrollToPage: (_root, { page }, { cache }) => {
        const { PDFViewer }: { PDFViewer: PDFViewerState } = cache.readQuery({
          query: PDFViewerStateQuery,
        });

        cache.writeQuery({
          query: PDFViewerStateQuery,
          data: {
            PDFViewer: { ...PDFViewer, scrollToPage: page },
          },
        });

        return null;
      },

      setCustomRanges: (_root, { ranges }: { ranges: PDFTextRanges }, { cache }) => {
        // TODO: to be implemented
      },

      setScrollToRange: (_root, { range }, { cache }) => {
        const { PDFViewer }: { PDFViewer: PDFViewerState } = cache.readQuery({
          query: PDFViewerStateQuery,
        });

        cache.writeQuery({
          query: PDFViewerStateQuery,
          data: {
            PDFViewer: { ...PDFViewer, scrollToRange: range },
          },
        });

        return null;
      },

      setActivePDFDocPage: (_root, { pageNum }, { cache }) => {
        const { activePageData } = cache.readQuery({ query: activePDFPageQuery });

        if (activePageData.activePage !== pageNum) {
          cache.writeQuery({
            query: activePDFPageQuery,
            data: {
              activePageData: {
                activePage: pageNum,
                __typename: activePageData.__typename,
              },
            },
          });
        }
        return null;
      },

      setPDFTextSelection: (_root, { text, ranges }, { cache }) => {
        const { PDFViewer }: { PDFViewer: PDFViewerState } = cache.readQuery({
          query: PDFViewerStateQuery,
        });
        const selectionData = {
          text,
          ranges: ranges ? ranges : {},
        };

        cache.writeQuery({
          query: PDFViewerStateQuery,
          data: {
            PDFViewer: { ...PDFViewer, selection: { ...PDFViewer.selection, ...selectionData } },
          },
        });

        return null;
      },

      ignoreSearchMatch: (_root, { pageNum, itemIndex, offset, length }, { cache }) => {
        const { PDFViewer } = cache.readQuery({ query: PDFSearchDataQuery });
        const ignoredResults = Immutable.fromJS(PDFViewer.search.ignoredResults)
          .mergeDeep(Immutable.fromJS({ [pageNum]: { [itemIndex]: [{ offset, length }] } }))
          .toJS();

        cache.writeQuery({
          query: PDFSearchDataQuery,
          data: { PDFViewer: { ...PDFViewer, search: { ...PDFViewer.search, ignoredResults } } },
        });
        return null;
      },

      updatePDFSearchData: (_root, { searchData }, { cache }) => {
        const { PDFViewer } = cache.readQuery({ query: PDFSearchDataQuery });
        const { query, matches } = PDFViewer.search;

        cache.writeQuery({
          query: PDFSearchDataQuery,
          data: {
            PDFViewer: {
              ...PDFViewer,
              search: {
                ...PDFViewer.search,
                ...searchData,
                matches:
                  'results' in searchData
                    ? searchResultsToQueryMatches(searchData.results, query)
                    : matches,
              },
            },
          },
        });
        return null;
      },

      setCustomPDFTextRanges: (_root, { ranges }: { ranges: PDFTextRanges }, { cache }) => {
        updateState(cache, (currentState) => ({
          ...currentState,
          customRanges: ranges,
        }));
      },

      resetPDFRotationAngle: (_root, _, { cache }) => {
        updateState(cache, (currentState) => ({
          ...currentState,
          pdfRotationAngle: INITIAL_PDF_ROTATION_ANGLE,
        }));
      },

      updatePDFViewerState: (_root, { data }: { data: Partial<PDFViewerState> }, { cache }) => {
        updateState(cache, data);
      },
    },
  },
};

export default PDFViewerState;
