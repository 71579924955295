/** @jsx jsx */
import { Button, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx, css } from '@emotion/core';
import { Plural } from '@lingui/macro';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const unassignedPDFsWarningCss = css`
  background-color: #ffd9b3;
  color: ${Colors.SEPIA1};
`;

interface IUnassignedPDFsBannerProps {
  projectId: string;
  count: number;
}

const UnassignedPDFsBanner: React.FC<IUnassignedPDFsBannerProps> = ({ projectId, count }) => {
  const history = useHistory();

  const navToUnassignedPDFs = useCallback(() => {
    history.push(`/projects/${projectId}/references/unassigned-attachments`);
  }, [history, projectId]);

  return (
    <div
      className="flex flex-row items-center text-sm h-12 px-4 cursor-pointer"
      onClick={navToUnassignedPDFs}
      css={unassignedPDFsWarningCss}
    >
      <div className="flex-1 truncate">
        <Plural
          value={count}
          one="There is # PDF file not matched with the reference. View file"
          other="There are # PDF files not matched with the references. View files"
        />
      </div>
      <Button minimal icon={IconNames.CHEVRON_RIGHT} />
    </div>
  );
};

export default UnassignedPDFsBanner;
