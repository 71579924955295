/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { maxContentWidth } from '../../common/styles';

interface IPageContentWrapperProps {
  className?: string;
}

const PageContentWrapper: React.FC<IPageContentWrapperProps> = ({ className, children }) => (
  <div
    className={`h-full w-full flex flex-col mx-auto overflow-auto ${className ?? ''}`}
    css={maxContentWidth}
  >
    {children}
  </div>
);

export default PageContentWrapper;
