import { useMutation } from '@apollo/react-hooks';
import { IconNames } from '@blueprintjs/icons';
import { t } from '@lingui/macro';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { UserKeyword } from '../../common/types';
import { toastify, useI18n } from '../../lib/utils';
import useActionLogger from './use_action_logger';

const InsertKeywordsSetMutation = loader('../../graphql/insert_keywords_set_mutation.gql');

export default function useKeywordsExport() {
  const i18n = useI18n();
  const insertActionLog = useActionLogger();
  const [insertKeywordsSet] = useMutation(InsertKeywordsSetMutation);

  const handleKeywordsExport = useCallback(
    (keywordsSet: UserKeyword) => {
      return toastify(
        insertKeywordsSet({ variables: { keywordsSet } }).then(() =>
          insertActionLog('inserted keywords set', { keywordsSet })
        ),
        i18n._(t`Keyword set successfully saved`),
        i18n._(t`Error while trying to save keyword set`),
        {
          errorToasterProps: {
            icon: IconNames.ERROR,
          },
          successToasterProps: {
            icon: IconNames.SAVED,
          },
        }
      );
    },
    [i18n, insertKeywordsSet, insertActionLog]
  );

  return handleKeywordsExport;
}
