import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { ReferenceCommentFragmentType } from '../../graphql/reference_comment_fragment';
import CommentBox from './comment_box';

interface IMyReferenceCommentProps {
  comment?: ReferenceCommentFragmentType;
  onDelete?: (commentId: string) => void;
  onUpdate?: (data: { id?: string; comment: string }) => void;
}

const MyReferenceComment: React.FC<IMyReferenceCommentProps> = ({
  comment,
  onDelete,
  onUpdate,
}) => {
  const handleAdminCommentChange = useMemo(() => {
    if (onDelete && onUpdate) {
      return (newComment: string) => {
        if (newComment !== (comment?.comment ?? '')) {
          if (isEmpty(newComment)) {
            onDelete(comment!.id);
          } else
            onUpdate({
              comment: newComment,
              id: comment?.id,
            });
        }
      };
    }
  }, [onDelete, onUpdate]);

  return (
    <CommentBox
      title={<Trans>My comment</Trans>}
      comment={comment?.comment ?? ''}
      onChange={handleAdminCommentChange}
    />
  );
};

export default MyReferenceComment;
