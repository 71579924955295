import React, { useRef } from 'react';
import { keys, map } from 'lodash/fp';
import { useUnsafePDFTextLayer } from '../../lib/utils';
import {
  DecoratedString,
  StrFragmentSpec,
  StringFragmentDecorator,
} from '../../lib/decorated_string';
import { DocTextLayerItem } from './unsafe_doc_page_text_layer';
import { PDFPageViewport, TextContentItem } from 'pdfjs-dist';

interface PDFTextHighlightsLayerProps {
  highlightedRanges: { [itemIdx: number]: StrFragmentSpec[] };
  items: TextContentItem[];
  scale: number;
  pageNum: number;
  viewport: PDFPageViewport;
  getPageFontData: (fontName: string) => any;
  highlighter: StringFragmentDecorator;
}

const PDFTextHighlightsLayer: React.FC<PDFTextHighlightsLayerProps> = ({
  highlightedRanges,
  items,
  scale,
  pageNum,
  viewport,
  getPageFontData,
  highlighter,
}) => {
  const highlightedItems = keys(highlightedRanges)
    .sort()
    .map((itemIndex) => ({
      itemIndex,
      itemData: items[itemIndex],
    }));
  const ref = useRef(null);
  useUnsafePDFTextLayer(ref, map('itemData', highlightedItems), scale);

  return (
    <div ref={ref}>
      {highlightedItems.map(({ itemIndex, itemData }) => {
        const itemContent = (
          <DecoratedString
            string={itemData.str}
            decorationRanges={highlightedRanges[itemIndex]}
            decorator={highlighter}
            decoratorProps={{ pageNum, itemIndex }}
          />
        );

        return (
          <DocTextLayerItem
            key={itemIndex}
            {...itemData}
            str={itemContent}
            scale={scale}
            viewport={viewport}
            getFontData={getPageFontData}
            pageNum={pageNum}
            itemIdx={itemIndex}
            style={{ color: 'transparent' }}
          />
        );
      })}
    </div>
  );
};

export default PDFTextHighlightsLayer;
