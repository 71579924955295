/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useApolloClient } from '@apollo/react-hooks';
import { Card, Colors, Divider, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';
import { ReactNode, useCallback, useState } from 'react';
import { lightGray5bg } from '../../common/styles';
import {
  FormTemplate,
  FTScreeningFormData,
  InclusionExclusionCriteriaFormData,
  ScreeningForm,
  StageType,
} from '../../common/types';
import AppToaster from '../../lib/toaster';
import LoadCriteriaDialog from './load_criteria_dialog';
import CopyPreviousSvg from '../project/assets/copy_previous.svg';
import AddEmptySvg from '../project/assets/add_empty.svg';
import ChecklistSvg from '../project/assets/checklist.svg';
import { useI18n } from '../../lib/utils';
import { BASE_STAGE_TEMPLATES } from '../project/helpers';

const cardCss = css`
  width: 328px;
`;

const GetFormTemplateByNameQuery = gql`
  query GetFormTemplate($formName: String!) {
    form_template(where: { name: { _eq: $formName } }) {
      id
      name
      template
    }
  }
`;

const getProjectStageFormByOrderNumberQuery = gql`
  query getProjectStageFormByOrderNumber($projectId: uuid!, $stageOrderNumber: Int!) {
    stage(where: { project_id: { _eq: $projectId }, order_number: { _eq: $stageOrderNumber } }) {
      id
      forms(order_by: { created_at: desc }, limit: 1) {
        id
        form
        template_id
      }
    }
  }
`;

interface IInstructionCardProps {
  icon: ReactNode;
  title: ReactNode;
  className?: string;
  onClick?: () => void;
}

const InstructionCard: React.FC<IInstructionCardProps> = ({ onClick, icon, title, className }) => (
  <Card interactive className={className} onClick={onClick} css={cardCss}>
    <div className="flex flex-col justify-center items-center">
      {icon}
      <div className="text-base leading-5 mt-4 py-2 text-center">{title}</div>
    </div>
  </Card>
);

interface IInitiateInstructionsScreenProps {
  onLoad: (
    templateId: string | null,
    template: FTScreeningFormData | InclusionExclusionCriteriaFormData
  ) => Promise<any>;
  templates: FormTemplate[];
  projectId: string;
  stageName?: string;
  stageType?: StageType;
  stageOrderNumber?: number;
}

const InitiateInstructionsScreen: React.FC<IInitiateInstructionsScreenProps> = ({
  onLoad,
  stageType,
  templates,
  stageName,
  stageOrderNumber,
  projectId,
}) => {
  const apolloClient = useApolloClient();
  const i18n = useI18n();
  const [fetchingStageTemplate, setFetchingStageTemplate] = useState(false);
  const [loadCriteriaDialogOpen, setLoadCriteriaDialogOpen] = useState(false);

  const handleLoadBaseStageTemplate = useCallback(async () => {
    const maybeFormName = stageType ? get(stageType, BASE_STAGE_TEMPLATES) : stageType;
    if (maybeFormName == null) return;

    setFetchingStageTemplate(true);
    try {
      const response = await apolloClient.query({
        query: GetFormTemplateByNameQuery,
        variables: { formName: maybeFormName },
      });

      const maybeTemplate: FormTemplate | undefined = get('data.form_template[0]', response);
      if (maybeTemplate) {
        onLoad(maybeTemplate.id, maybeTemplate.template);
      }
    } catch (error) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: <Trans>Failed to fetch template: {maybeFormName}</Trans>,
      });
    } finally {
      setFetchingStageTemplate(false);
    }
  }, [stageType, setFetchingStageTemplate, onLoad]);

  const handleLoadPreviousStageInstruction = useCallback(async () => {
    if (stageOrderNumber == null) {
      return AppToaster.show({
        intent: Intent.WARNING,
        message: <Trans>Previous stage instructions could not be loaded</Trans>,
      });
    }

    const targetStageOrderNumber = stageOrderNumber - 1;

    try {
      const { data: previousStageFormData } = await apolloClient.query({
        query: getProjectStageFormByOrderNumberQuery,
        variables: {
          projectId,
          stageOrderNumber: targetStageOrderNumber,
        },
      });
      const maybeForm: ScreeningForm | undefined = get('stage[0].forms[0]', previousStageFormData);
      if (maybeForm == null) {
        throw new Error(
          i18n._(t`Could not find form of stage with order number ${targetStageOrderNumber}`)
        );
      } else {
        onLoad(maybeForm.template_id, maybeForm.form);
      }
    } catch (error) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: <Trans>Instructions in previous state are not yet set up.</Trans>,
      });
    }
  }, [projectId, stageOrderNumber, onLoad]);

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className="h-12 flex-none flex flex-row items-center flex-no-wrap overflow-hidden px-4"
        css={lightGray5bg}
      >
        <span className="flex-1 truncate text-xl">
          {stageName ? (
            <Trans>{stageName} Instruction</Trans>
          ) : (
            <Trans>Screening Instruction</Trans>
          )}
        </span>
      </div>
      <Divider className="m-0" />
      <div className="flex-1 overflow-auto flex flex-col items-center justify-center">
        <h2 className="text-3xl font-normal">
          {stageName ? (
            <Trans>{stageName} Instruction</Trans>
          ) : (
            <Trans>Screening Instruction</Trans>
          )}
        </h2>
        <div className="text-sm mt-4 mb-12">
          <Trans>Select an option to start</Trans>
        </div>
        <div className="flex flex-row">
          {(stageOrderNumber ?? 1) > 1 ? (
            <InstructionCard
              className="mr-12"
              icon={<img src={CopyPreviousSvg} className="h-8" />}
              title={<Trans>Load instruction from the previous stage</Trans>}
              onClick={handleLoadPreviousStageInstruction}
            />
          ) : (
            <InstructionCard
              className="mr-12"
              icon={<img src={ChecklistSvg} className="h-8" />}
              title={<Trans>Load standard instruction</Trans>}
              onClick={fetchingStageTemplate ? undefined : handleLoadBaseStageTemplate}
            />
          )}
          <InstructionCard
            className="mr-12"
            icon={<Icon icon={IconNames.FOLDER_OPEN} size={32} color={Colors.GRAY3} />}
            title={<Trans>Pick from templates</Trans>}
            onClick={() => setLoadCriteriaDialogOpen(true)}
          />
          <InstructionCard
            className="mr-12"
            icon={<img src={AddEmptySvg} className="h-8" />}
            title={<Trans>Use an empty form</Trans>}
            onClick={() => onLoad(null, { inclusion: [], exclusion: [], tags: [] })}
          />
        </div>
      </div>
      <LoadCriteriaDialog
        isOpen={loadCriteriaDialogOpen}
        onClose={() => setLoadCriteriaDialogOpen(false)}
        onLoad={onLoad}
        currentFormTemplateId={null}
        templates={templates}
      />
    </div>
  );
};

export default InitiateInstructionsScreen;
