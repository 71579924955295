import { loader } from 'graphql.macro';
import { includes, omitBy, propEq, set, unset, without } from 'lodash/fp';
import { getLocalStateUpdater } from '../lib/utils';
import { LocalState, GQLType } from './local_states';

export const EMPTY_SEARCH_PHRASE_TOKENS = [];
export const EMPTY_ACTIVE_KEYWORD_FILTERS = {};
export const EMPTY_SELECTED_REFERENCES = [];
export const EMPTY_ACTIVE_DECISION_CODE_FILTERS = {};
export const EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS = {};
export const EMPTY_ACTIVE_YEAR_FILTER = {};
export const EMPTY_ACTIVE_SCREENING_TAG_FILTERS = {};
export const EMPTY_ORDERED_BY = {};
export const ORDER_BY_LAST_CHANGED = {
  column: 'last_changed',
  order: 'desc',
};
export const ORDER_BY_RANK_AND_UPDATED_AT = {
  column: 'last_changed_and_study_score',
  order: 'desc',
};

const ScreeningStateQuery = loader('../graphql/local/get_screening_state.gql');

export type TReferenceFilters = Partial<
  Pick<
    IScreeningState,
    | 'decisionFilter'
    | 'searchPhraseTokens'
    | 'activeKeywordFilters'
    | 'activeDocumentTypeFilters'
    | 'activeYearFilters'
    | 'activeDecisionCodeFilters'
    | 'activeScreeningTagFilters'
    | 'filtersTarget'
    | 'orderedBy'
    | 'onlyWithComments'
    | 'pdfFilter'
    | 'onlyWithoutAbstract'
    | 'activeBatchKey'
  >
>;
export type TSearchOperator = 'and' | 'or';
export type TFilterTarget =
  | 'all_fields'
  | 'text_fields'
  | 'author'
  | 'authors'
  | 'year'
  | 'title'
  | 'accession_number'
  | 'record_number'
  | 'doi'
  | 'comments';
export type TKeywordType = 'included' | 'excluded';

export type TKeywordData = {
  active: boolean;
  type: TKeywordType;
};

export type TActiveKeywordVariable = {
  searchOperator: TSearchOperator | null;
  keywords: {
    [keyword: string]: TKeywordData;
  };
};

export type TActiveKeywordFilters = {
  [variablePath: string /* domainId::variableId */]: TActiveKeywordVariable;
};

export type TActiveScreeningTagFilters = {
  [variablePath: string /* domainId::variableId */]: {
    active: boolean;
  };
};

export type TActiveAttributeFilters = {
  [attribute: string]: {
    label: string;
    active: boolean;
  };
};

export type YearFilters = {
  minYear?: number;
  maxYear?: number;
  active?: boolean;
};

export type DecisionFilter = 'all' | 'in' | 'out' | 'to_review' | 'postponed' | 'resolved';
export type TPdfFilter = 'all' | 'with_pdf' | 'without_pdf';

export interface IScreeningState extends GQLType {
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activeKeywordFilters: TActiveKeywordFilters;
  decisionFilter: DecisionFilter;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  filtersTarget: TFilterTarget;
  highlightsVisible: boolean;
  activeReference: string | null;
  allReferencesSelected: boolean;
  selectedReferences: string[];
  orderedBy: object;
  onlyWithComments: boolean;
  pdfFilter: TPdfFilter;
  onlyWithoutAbstract: boolean;
  activeBatchKey: string | null;
}

const initialStateProvider = (
  initialValues?: Partial<Omit<IScreeningState, '__typename'>>
): { [key: string]: IScreeningState } => ({
  ScreeningState: {
    __typename: 'ScreeningState',
    activeScreeningTagFilters: EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
    searchPhraseTokens: EMPTY_SEARCH_PHRASE_TOKENS,
    activeKeywordFilters: EMPTY_ACTIVE_KEYWORD_FILTERS,
    activeDecisionCodeFilters: EMPTY_ACTIVE_DECISION_CODE_FILTERS,
    activeDocumentTypeFilters: EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
    activeYearFilters: EMPTY_ACTIVE_YEAR_FILTER,
    decisionFilter: 'to_review',
    filtersTarget: 'all_fields',
    highlightsVisible: true,
    activeReference: null,
    allReferencesSelected: false,
    selectedReferences: EMPTY_SELECTED_REFERENCES,
    orderedBy: EMPTY_ORDERED_BY,
    onlyWithComments: false,
    pdfFilter: 'all',
    onlyWithoutAbstract: false,
    activeBatchKey: null,
    ...initialValues,
  },
});

const updateState = getLocalStateUpdater<IScreeningState>('ScreeningState', ScreeningStateQuery);

const ScreeningLocalState: LocalState = {
  name: 'ScreeningState',
  rootQuery: ScreeningStateQuery,
  initial: initialStateProvider,
  resolvers: {
    Mutation: {
      updateActiveKeywordFilters: (_root, { variablePath, keyword, active, type }, { cache }) => {
        updateState(cache, (state) => {
          const { activeKeywordFilters } = state;
          const updateVariableKeywords = {
            ...activeKeywordFilters,
            [variablePath]: {
              ...(activeKeywordFilters[variablePath] ?? {
                searchOperator: 'or',
              }),
              keywords: {
                ...(activeKeywordFilters[variablePath]?.keywords ?? {}),
                [keyword]: { active, type },
              },
            },
          };

          return { ...state, activeKeywordFilters: updateVariableKeywords };
        });
      },

      updateActiveKeywordSearchOperator: (_root, { variablePath, searchOperator }, { cache }) => {
        updateState(cache, (state) => {
          const { activeKeywordFilters } = state;
          const updateVariableKeywords = {
            ...activeKeywordFilters,
            [variablePath]: {
              ...(activeKeywordFilters[variablePath] ?? {}),
              searchOperator,
            },
          };

          return { ...state, activeKeywordFilters: updateVariableKeywords };
        });
      },

      updateActiveAttribute: (_root, { key, attribute, label, active }, { cache }) => {
        updateState(cache, (state) => {
          return {
            ...state,
            [key]: omitBy(propEq('active', false), {
              ...state[key],
              [attribute]: { label, active },
            }),
          };
        });
      },

      updateActiveScreeningTag: (_root, { id, active }, { cache }) => {
        updateState(cache, (state) => {
          return {
            ...state,
            activeScreeningTagFilters: (active
              ? set(`${id}`, { active }, state.activeScreeningTagFilters)
              : unset(`${id}`, state.activeScreeningTagFilters)) as TActiveScreeningTagFilters,
          };
        });
      },

      setActiveYearFilters: (_root, { minYear, maxYear }, { cache }) => {
        updateState(cache, (state) => {
          return {
            ...state,
            activeYearFilters: {
              ...state.activeYearFilters,
              minYear,
              maxYear,
              active: true,
            },
          };
        });
      },

      resetActiveYearFilters: (_root, _args, { cache }) => {
        updateState(cache, (state) => {
          return {
            ...state,
            activeYearFilters: EMPTY_ACTIVE_YEAR_FILTER,
          };
        });
      },

      updateSearchPhraseTokens: (_root, { tokens }, { cache }) => {
        updateState(cache, { searchPhraseTokens: tokens });
      },

      setFiltersTarget: (_root, { filtersTarget }, { cache }) => {
        updateState(cache, { filtersTarget });
      },

      setOnlyWithComments: (_root, { onlyWithComments }, { cache }) => {
        updateState(cache, { onlyWithComments });
      },

      setPdfFilter: (_root, { pdfFilter }, { cache }) => {
        updateState(cache, { pdfFilter });
      },

      setOnlyWithoutAbstract: (_root, { onlyWithoutAbstract }, { cache }) => {
        updateState(cache, { onlyWithoutAbstract });
      },

      setActiveBatchKey: (_root, { activeBatchKey }, { cache }) => {
        updateState(cache, { activeBatchKey });
      },

      setAllReferencesSelected: (
        _root,
        { allReferencesSelected, selectedReferences },
        { cache }
      ) => {
        updateState(cache, { allReferencesSelected, selectedReferences });
      },

      clearFilters: (_root, _args, { cache }) => {
        updateState(cache, {
          searchPhraseTokens: EMPTY_SEARCH_PHRASE_TOKENS,
          activeScreeningTagFilters: EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
          activeKeywordFilters: EMPTY_ACTIVE_KEYWORD_FILTERS,
          activeDecisionCodeFilters: EMPTY_ACTIVE_DECISION_CODE_FILTERS,
          activeDocumentTypeFilters: EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
          activeYearFilters: EMPTY_ACTIVE_YEAR_FILTER,
          allReferencesSelected: false,
          selectedReferences: EMPTY_SELECTED_REFERENCES,
          activeReference: null,
          onlyWithComments: false,
          pdfFilter: 'all',
          onlyWithoutAbstract: false,
          activeBatchKey: null,
        });
      },

      resetScreeningState: (_root, { initialValues }, { cache }) => {
        updateState(cache, () => initialStateProvider(initialValues).ScreeningState);
      },

      setHighlightsVisible: (_root, { visible }, { cache }) => {
        updateState(cache, { highlightsVisible: visible });
      },

      setDecisionFilter: (
        _root,
        { newDecisionFilter }: { newDecisionFilter: DecisionFilter },
        { cache }
      ) => {
        updateState(cache, (current) => {
          return {
            ...current,
            decisionFilter: newDecisionFilter,
            orderedBy:
              current.orderedBy === EMPTY_ORDERED_BY &&
              ['in', 'out', 'postponed'].includes(newDecisionFilter)
                ? EMPTY_ORDERED_BY
                : current.orderedBy,
            activeReference: null,
            selectedReferences: [],
          };
        });
      },

      setActiveAndSelectedReferences: (
        _root,
        data: { activeReference?: string; selectedReferences?: string[] },
        { cache }
      ) => {
        updateState(cache, (current) => ({
          ...current,
          activeReference: data.activeReference ?? current.activeReference,
          selectedReferences: data.selectedReferences ?? current.selectedReferences,
        }));
      },

      resetActiveAndSelectedReferences: (_root, _args, { cache }) => {
        updateState(cache, {
          selectedReferences: EMPTY_SELECTED_REFERENCES,
          activeReference: null,
        });
      },

      toggleActiveReferenceSelect: (_root, _args, { cache }) => {
        updateState(cache, (currentState) => {
          const { selectedReferences: currentlySelected, activeReference } = currentState;

          if (activeReference == null) return currentState;

          return {
            ...currentState,
            selectedReferences: includes(activeReference, currentlySelected)
              ? without([activeReference], currentlySelected)
              : [...currentlySelected, activeReference],
          };
        });
      },

      setReferencesListOrderedBy: (_root, { orderedBy }, { cache }) => {
        updateState(cache, (current) => ({
          ...current,
          orderedBy,
          activeReference: null,
          selectedReferences: EMPTY_SELECTED_REFERENCES,
        }));
      },

      resetReferencesFilters: (_root, data: { filters: TReferenceFilters }, { cache }) => {
        updateState(cache, (current) => ({
          ...current,
          ...data.filters,
        }));
      },
    },
  },
};

export default ScreeningLocalState;
