import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { find, get, propEq } from 'lodash/fp';
import React, { useCallback } from 'react';
import { EMPTY_SEARCH_PHRASE_TOKENS, TFilterTarget } from '../../apollo/screening_state';
import i18n from '../../i18n';
import { useCurrCallback, useI18n } from '../../lib/utils';
import CustomTagsInput from '../common/custom_tag_input';
import { FilterTargetSelect } from './screening_filters';

const SetFiltersTargetMutation = loader('../../graphql/local/set_filters_target.gql');
const UpdateSearchQueryTokensMutation = loader(
  '../../graphql/local/update_search_phrase_tokens.gql'
);

const screeningStateQuery = gql`
  query {
    ScreeningState @client {
      searchPhraseTokens
      filtersTarget
    }
  }
`;

export type FiltersTarget = {
  target: TFilterTarget;
  label: string;
};

const filtersTargetOptions: FiltersTarget[] = [
  { target: 'all_fields', label: i18n._(t`All fields`) },
  { target: 'text_fields', label: i18n._(t`Text fields`) },
  { target: 'author', label: i18n._(t`First Author`) },
  { target: 'authors', label: i18n._(t`All authors`) },
  { target: 'title', label: i18n._(t`Title`) },
  { target: 'accession_number', label: i18n._(t`Accession number`) },
  { target: 'record_number', label: i18n._(t`Ref. num.`) },
  { target: 'doi', label: i18n._(t`DOI`) },
  { target: 'comments', label: i18n._(t`Comments`) },
];

interface IReferencesSearchWidgetProps {
  className?: string;
}

const ReferencesSearchWidget: React.FC<IReferencesSearchWidgetProps> = ({ className }) => {
  const i18n = useI18n();
  const [setFiltersTarget] = useMutation(SetFiltersTargetMutation);
  const [updateSearchQueryTokens] = useMutation(UpdateSearchQueryTokensMutation);
  const { data: screeningStateData } = useQuery(screeningStateQuery);

  const searchPhraseTokens: string[] =
    get('ScreeningState.searchPhraseTokens', screeningStateData) ?? EMPTY_SEARCH_PHRASE_TOKENS;

  const filtersTarget: TFilterTarget = get('ScreeningState.filtersTarget', screeningStateData);

  const currentFilterTarget =
    find(propEq('target', filtersTarget), filtersTargetOptions) ?? filtersTargetOptions[0];

  const handleFiltersTargetChange = useCallback(
    (filtersTarget: TFilterTarget) => {
      setFiltersTarget({
        variables: {
          filtersTarget,
        },
      });
    },
    [setFiltersTarget]
  );

  const handleSearchTokensChange = useCallback(
    (tokens) => {
      updateSearchQueryTokens({ variables: { tokens } });
    },
    [updateSearchQueryTokens]
  );

  const renderSelectOption = useCurrCallback((selected, { target, label }, { handleClick }) => {
    return (
      <MenuItem key={target} onClick={handleClick} text={label} active={selected === target} />
    );
  }, []);

  return (
    <div className={`flex items-center ${className}`} data-testid="references-search-widget">
      <FilterTargetSelect
        className="mr-2"
        filterable={false}
        items={filtersTargetOptions}
        itemRenderer={renderSelectOption(filtersTarget)}
        onItemSelect={(item) => handleFiltersTargetChange(item.target)}
      >
        <Button
          className="w-full"
          alignText="left"
          text={currentFilterTarget?.label}
          rightIcon={IconNames.CARET_DOWN}
        />
      </FilterTargetSelect>
      <CustomTagsInput
        className="flex-grow"
        placeholder={i18n._(t`Type a phrase and hit enter to apply it`)}
        values={searchPhraseTokens}
        onChange={handleSearchTokensChange}
      />
    </div>
  );
};

export default ReferencesSearchWidget;
