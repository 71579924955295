import { isEmpty, propEq, reject, some, sum, values } from 'lodash/fp';
import { Study, TeamMember } from '../common/types';

export type TTeamMemberTaskLimits = { [teamMemberId: string]: number };
export type TScreenerData = Pick<
  TeamMember,
  | 'id'
  | 'deleted_at'
  | 'user_id'
  | 'user'
  | 'project'
  | 'role'
  | 'tasks_aggregate'
  | 'tasks_by_status'
>;

export function getTeamMemberLimits(
  teamMembers: TScreenerData[],
  studies: Pick<Study, 'tasks'>[],
  isRedistribution?: boolean
): TTeamMemberTaskLimits {
  if (isRedistribution) {
    return teamMembers.reduce((acc, teamMember) => {
      const { id } = teamMember;
      const assignableStudiesCount = reject(
        (study) => some(propEq('team_member_id', id), study.tasks),
        studies
      ).length;

      if (assignableStudiesCount < studies.length) {
        acc[id] = assignableStudiesCount;
      }

      return acc;
    }, {} as { [teamMemberId: string]: number });
  } else {
    return {};
  }
}

export function getDistributionCountBase(
  studiesCount: number,
  screenersPerStudy: number,
  isRedistribution?: boolean
): number {
  return isRedistribution ? studiesCount : studiesCount * screenersPerStudy;
}

export const getStudiesPerMember = (
  teamMembers: string[],
  countBase: number,
  limit: number,
  memberLimits: TTeamMemberTaskLimits
) => {
  const membersCount = teamMembers.length;
  const averagePerMember = Math.min(limit, Math.floor(countBase / membersCount));
  const studiesPerMember = teamMembers.reduce((acc, memberId) => {
    acc[memberId] = Math.min(averagePerMember, memberLimits[memberId] ?? averagePerMember);

    return acc;
  }, {});

  let remaining = countBase - sum(values(studiesPerMember));

  if (remaining > 0) {
    const membersForRemainingStudies = teamMembers.filter(
      (memberId) => studiesPerMember[memberId] < Math.min(limit, memberLimits[memberId] ?? limit)
    );

    if (!isEmpty(membersForRemainingStudies)) {
      const averageRemaining = Math.ceil(remaining / membersForRemainingStudies.length);

      membersForRemainingStudies.forEach((memberId) => {
        if (remaining > 0) {
          const canTakeCount = limit - studiesPerMember[memberId];
          const memberExtraStudies = Math.min(averageRemaining, remaining, canTakeCount);
          studiesPerMember[memberId] += memberExtraStudies;
          remaining -= memberExtraStudies;
        }
      });
    }
  }

  return studiesPerMember;
};
