/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { RouteComponentProps } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Classes, Colors, FormGroup, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import {
  __,
  assign,
  compact,
  compose,
  curry,
  findIndex,
  forEach,
  get,
  isEmpty,
  map,
  merge,
  propEq,
  some,
  sumBy,
  reduce,
  find,
  includes,
} from 'lodash/fp';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import ErrorScreen from '../common/error_screen';
import {
  PrismaData,
  PrismaEditorItem,
  PrismaExportFormat,
  PrismaSection,
  Stage,
  StageResultsCounts,
  StageType,
} from '../../common/types';
import { centerItemCss, lightGray4bg } from '../../common/styles';
import { uid, updateArrayItem, useI18n, useSetState } from '../../lib/utils';
import { getStageStudyCounts, StageStats } from '../../lib/study_helpers';
import AdminPageContentWrapper from '../common/admin_page_content_wrapper';
import ArrowNode, { dualArrow, halfArrow, sideRightArrow } from './arrow_node';
import DiagramVerticalNode from './diagram_vertical_node';
import ExportControls from './export_controls';
import EditorSection from './editor_section';
import Diagram from './diagram';
import DiagramNode from './diagram_node';
import DiagramCustomNode from './diagram_custom_node';
import EditorItemsList from './editor_items_list';
import { TExclusionReason } from '../screening';
import useActionLogger from '../hooks/use_action_logger';
import AppToaster from '../../lib/toaster';
const FormUserKeywordsFragment = loader('../../graphql/form_user_keywords_fragment.gql');

const PrismaDataSubscription = gql`
  ${FormUserKeywordsFragment}
  subscription getPrismaData($id: uuid!) {
    project: project_by_pk(id: $id) {
      id
      prisma
      references_count: references_aggregate(where: { removal_reason: { _is_null: true } }) {
        aggregate {
          count
        }
      }
      stages {
        id
        studies_by_status {
          inclusion_status
          count
        }
        type
        forms(order_by: { created_at: desc }, limit: 1) {
          id
          form
          form_team_member_keywords {
            ...FormUserKeywordsFragment
          }
        }
        stage_results_counts {
          stage_id
          inclusion_status
          status_reason_codes
          count
        }
      }
    }
  }
`;

const UpdateProjectPrismaMutation = gql`
  mutation UpdateProjectPrisma($projectId: uuid!, $prismaData: jsonb!) {
    update_project_by_pk(pk_columns: { id: $projectId }, _set: { prisma: $prismaData }) {
      id
      prisma
    }
  }
`;

const exportFormats = [PrismaExportFormat.SVG, PrismaExportFormat.PNG];

const STAGE_LABEL_IDS = {
  IDENTIFICATION: 'label-identification',
  SCREENING: 'label-screening',
  ELIGIBILITY: 'label-eligibility',
  INCLUDED: 'label-included',
};

export function filterOutNotNumbers(val: string) {
  return val.replace(/[^0-9]/g, '');
}

const renderEditorError = () => {
  return (
    <span className="block text-red-500">
      <Trans>Fields do not sum up.</Trans>
    </span>
  );
};

const getFTReasonLabel = (reasonWithCounts: TExclusionReasonWithCounts): string =>
  `${get('code', reasonWithCounts)}: ${get('label', reasonWithCounts)}`;

const reasonsWithCountsMapper = (
  domainsWithCounts: TDomainReasonsDataWithCounts,
  domainName: 'preliminary' | 'titlesAbstracts' | 'fullText.exclusion',
  getReasonLabel: (reasonWithCounts: TExclusionReasonWithCounts) => string
) =>
  compose(
    map((reasonWithCounts) => (
      <div
        key={get('id', reasonWithCounts)}
        className="w-3/4 p-2 my-1 border-0 rounded flex flex-row justify-between"
        css={lightGray4bg}
      >
        <span className="text-red-500">
          {getReasonLabel(reasonWithCounts as TExclusionReasonWithCounts)}
        </span>
        <span>{get('count', reasonWithCounts)}</span>
      </div>
    )),
    get(domainName)
  )(domainsWithCounts);

const renderReasonsWithCounts = (
  domainsWithCounts: TDomainReasonsDataWithCounts
): { preliminary: HTMLElement[]; tiAb: HTMLElement[]; ftExclusion: HTMLElement[] } => ({
  preliminary: reasonsWithCountsMapper(domainsWithCounts, 'preliminary', get('label')),
  tiAb: reasonsWithCountsMapper(domainsWithCounts, 'titlesAbstracts', get('label')),
  ftExclusion: reasonsWithCountsMapper(domainsWithCounts, 'fullText.exclusion', getFTReasonLabel),
});

const getDomainReasonCount = (stage: Stage, exclusionCode: string): number =>
  compose(
    get('count'),
    find(({ status_reason_codes }: StageResultsCounts) =>
      includes(exclusionCode, status_reason_codes)
    ),
    get('stage_results_counts')
  )(stage) ?? 0;

const fullTextMapperWithCounts = (field: 'inclusion' | 'exclusion', stage: Stage) =>
  compose(
    map(({ id, code, name }) => ({
      id,
      code,
      label: name,
      count: getDomainReasonCount(stage, code),
    })),
    get(`forms[0].form.${field}`)
  )(stage);

const tiAbMapperWithCounts = (stage: Stage) =>
  compose(
    map(({ exclusionButtonLabel, exclusionCode, id }) => ({
      id,
      code: exclusionCode,
      label: exclusionButtonLabel,
      count: getDomainReasonCount(stage, exclusionCode),
    })),
    get('forms[0].form.domains')
  )(stage);

const sumRecordsValues = sumBy((record: PrismaEditorItem) => Number(record.value));

export const textRightCss = css`
  .${Classes.INPUT} {
    text-align: right;
  }
`;

type TExclusionReasonWithCounts = TExclusionReason & {
  count: number;
};

type TDomainReasonsDataWithCounts = {
  preliminary: TExclusionReasonWithCounts[];
  titlesAbstracts: TExclusionReasonWithCounts[];
  fullText: {
    inclusion: TExclusionReasonWithCounts[];
    exclusion: TExclusionReasonWithCounts[];
  };
};

interface IPrismaDiagramRouterProps {
  projectId: string;
}

interface IPrismaDiagramProps extends RouteComponentProps<IPrismaDiagramRouterProps> {
  // regular props
}

const PrismaDiagram: React.FC<IPrismaDiagramProps> = ({ match, history }) => {
  const { projectId } = match.params;
  const i18n = useI18n();
  const insertActionLog = useActionLogger();

  const initialPrismaState: PrismaData = {
    recordsFromDatabases: {
      title: i18n._(t`Records identified through publication databases`),
      itemHeaderTitle: i18n._(t`Source`),
      records: [{ id: uid(), title: '', value: 0 }],
    },
    recordsFromAdditionalResources: {
      checked: false,
      title: i18n._(t`Identification of new studies via other methods`),
      itemHeaderTitle: i18n._(t`Source`),
      records: [{ id: uid(), title: '', value: 0 }],
    },
    duplicatesRemoved: {
      title: i18n._(t`Duplicate records`),
      records: [],
    },
    recordsScreenedPreliminary: {
      title: i18n._(t`Preliminary screened`),
      records: [],
    },
    recordsScreenedAgainstTitles: {
      title: i18n._(t`Records screened`),
      records: [],
    },
    recordsNotRetrievedFullText: {
      title: i18n._(t`Reports not retrieved`),
      records: [],
    },
    recordsScreenedFullText: {
      title: i18n._(t`Reports assessed for eligibility`),
      itemHeaderTitle: 'Exclusion reason',
      records: [{ id: uid(), title: '', value: 0 }],
    },
    newStudiesIncludedInReview: {
      checked: true,
      records: [],
      title: i18n._(t`New studies included in review`),
      value: 0,
    },
  };

  const [updateProjectPrisma] = useMutation(UpdateProjectPrismaMutation);
  const { data, loading, error } = useSubscription(PrismaDataSubscription, {
    variables: { id: projectId },
  });
  const referencesCount = get('project.references_count.aggregate.count', data) ?? 0;
  const stages: Stage[] = get('project.stages', data) ?? [];
  const preliminaryStage: StageStats | undefined = find(
    propEq('type', StageType.PreliminaryScreening),
    stages
  );
  const tiAbStage: StageStats | undefined = find(
    propEq('type', StageType.TitlesAbstractScreening),
    stages
  );
  const ftStage: StageStats | undefined = find(propEq('type', StageType.FullTextScreening), stages);

  const {
    total: preliminaryReferencesCount,
    excluded: preliminaryReferencesExcludedCount,
    included: preliminaryReferencesIncludedCount,
  } = getStageStudyCounts(preliminaryStage) ?? {
    total: 0,
    included: 0,
    excluded: 0,
  };

  const {
    total: tiAbReferencesCount,
    excluded: tiAbReferencesExcludedCount,
    included: tiAbReferencesIncludedCount,
  } = getStageStudyCounts(tiAbStage) ?? {
    total: 0,
    included: 0,
    excluded: 0,
  };

  const {
    total: ftReferencesCount,
    excluded: ftReferencesExcludedCount,
    included: ftReferencesIncludedCount,
  } = getStageStudyCounts(ftStage) ?? {
    total: 0,
    included: 0,
    excluded: 0,
  };

  const [exportFormat, setExportFormat] = useState<PrismaExportFormat>(PrismaExportFormat.SVG);
  const [state, setState] = useSetState<PrismaData>(initialPrismaState);
  const {
    recordsFromDatabases,
    recordsFromAdditionalResources,
    duplicatesRemoved,
    recordsScreenedPreliminary,
    recordsScreenedAgainstTitles,
    recordsNotRetrievedFullText,
    recordsScreenedFullText,
    newStudiesIncludedInReview,
  } = state;

  const totalRecordsFromDatabases = useMemo(
    () => sumRecordsValues(recordsFromDatabases.records),
    [recordsFromDatabases.records]
  );
  const totalRecordsFromAdditionalResources = useMemo(
    () =>
      recordsFromAdditionalResources.checked
        ? sumRecordsValues(recordsFromAdditionalResources.records)
        : 0,
    [recordsFromAdditionalResources.records, recordsFromAdditionalResources.checked]
  );

  const totalRecordsPassedToFirstScreeningStage = preliminaryStage
    ? preliminaryReferencesCount
    : tiAbStage
    ? tiAbReferencesCount
    : ftStage
    ? ftReferencesCount
    : referencesCount;

  const totalDuplicatesRemoved =
    totalRecordsFromDatabases +
    totalRecordsFromAdditionalResources -
    totalRecordsPassedToFirstScreeningStage;

  const recordsNotRetrievedFullTextCount =
    (tiAbStage
      ? tiAbReferencesIncludedCount
      : preliminaryStage
      ? preliminaryReferencesIncludedCount
      : totalRecordsPassedToFirstScreeningStage) - ftReferencesCount ?? 0;

  const newStudiesIncludedInReviewValue = newStudiesIncludedInReview.value ?? 0;

  // the number of references that could be passed to FT screening
  const reportsSoughtForRetrieval = tiAbStage
    ? tiAbReferencesIncludedCount
    : preliminaryStage
    ? preliminaryReferencesIncludedCount
    : totalRecordsPassedToFirstScreeningStage;

  // TODO: remove magic numbers
  // magic numbers - diagram row indices
  // duplicates removed section starts in the row 3
  // preliminary screening (if exists in a given project) starts in the next row after duplicates removed and is 4 rows long
  // TiAb screening (if exists in a given project) starts right after the preliminary screening and is 4 rows long
  // FT section (if exists) starts after TiAb and is 10 rows long
  // newStudiesIncludedInReview starts right after FT
  const duplicatesRemovedRowStart = 3;
  const preliminaryRowStart = duplicatesRemovedRowStart + 1;
  const tiAbRowStart = preliminaryRowStart + (preliminaryStage ? 4 : 0);
  const ftRowStart = tiAbRowStart + (tiAbStage ? 4 : 0);
  const newStudiesIncludedInReviewRowStart = ftRowStart + (ftStage ? 10 : 0);

  const domainsWithCounts: TDomainReasonsDataWithCounts = useMemo(
    () =>
      reduce(
        (acc: TDomainReasonsDataWithCounts, stage: Stage) => {
          switch (stage.type) {
            case StageType.PreliminaryScreening:
              acc['preliminary'].push(...tiAbMapperWithCounts(stage));
              break;
            case StageType.TitlesAbstractScreening:
              acc['titlesAbstracts'].push(...tiAbMapperWithCounts(stage));
              break;
            case StageType.FullTextScreening:
              acc['fullText']['inclusion'].push(...fullTextMapperWithCounts('inclusion', stage));
              acc['fullText']['exclusion'].push(...fullTextMapperWithCounts('exclusion', stage));
          }
          return acc;
        },
        {
          preliminary: [],
          titlesAbstracts: [],
          fullText: {
            inclusion: [],
            exclusion: [],
          },
        },
        stages
      ),
    [stages]
  );

  function _updateSectionData(sectionKey: string, obj: object) {
    const updatedPrismaData: PrismaData = {
      ...state,
      [sectionKey]: assign(state[sectionKey], obj),
    };
    updateProjectPrisma({
      variables: {
        projectId,
        prismaData: updatedPrismaData,
      },
    })
      .then(() => {
        setState(updatedPrismaData);
      })
      .catch((err) => {
        insertActionLog('failed to update prisma', { err, state, sectionKey, obj });
        AppToaster.show({
          message: (
            <span>
              <Trans>Failed to update PRISMA diagram</Trans>: {err.message}
            </span>
          ),
          intent: Intent.DANGER,
        });
      });
  }

  function handleItemAdd(sectionKey: string) {
    _updateSectionData(sectionKey, {
      records: [...state[sectionKey].records, { id: uid(), title: '', value: 0 }],
    });
  }

  function _handleItemChange(sectionKey: string, id: string, stateObj: object) {
    const existingRecordIdx = findIndex(propEq('id', id), state[sectionKey].records);
    if (existingRecordIdx === -1) {
      return;
    }
    _updateSectionData(sectionKey, {
      records: updateArrayItem(existingRecordIdx, merge(__, stateObj), state[sectionKey].records),
    });
  }

  function _handleItemRemove(sectionKey: string, id: string) {
    const existingRecordIdx = findIndex(propEq('id', id), state[sectionKey].records);
    if (existingRecordIdx === -1) {
      return;
    }
    _updateSectionData(sectionKey, {
      records: [
        ...state[sectionKey].records.slice(0, existingRecordIdx),
        ...state[sectionKey].records.slice(existingRecordIdx + 1),
      ],
    });
  }

  const hasNodeConnectionWith = (...sections: PrismaSection[]) => {
    return some(get('checked'), sections);
  };

  const getDiagramDataUrl = async (exportFormat: PrismaExportFormat): Promise<string | null> => {
    const $diagram = document.getElementById('prisma-diagram');
    if (!$diagram) return null;

    switch (exportFormat) {
      case PrismaExportFormat.PNG:
        return htmlToImage.toPng($diagram);
      case PrismaExportFormat.SVG:
        return htmlToImage.toSvgDataURL($diagram);
      default:
        return null;
    }
  };

  const handleExportDiagram = async () => {
    const $labelElements = compose(
      compact,
      map((value: string) => document.getElementById(value))
    )(Object.values(STAGE_LABEL_IDS));

    forEach((elem) => {
      elem.style.display = 'flex';
    }, $labelElements);

    const dataUrl = await getDiagramDataUrl(exportFormat);
    if (dataUrl) {
      const ext = exportFormat === PrismaExportFormat.SVG ? 'svg' : 'png';
      await saveAs(dataUrl, `PRISMA_diagram.${ext}`);
    }

    forEach((elem) => {
      elem.style.display = 'none';
    }, $labelElements);
  };

  const updateSectionData = curry(_updateSectionData);
  const handleItemChange = curry(_handleItemChange);
  const handleItemRemove = curry(_handleItemRemove);

  const isNewStudiesIncludedInReviewValueInvalid =
    newStudiesIncludedInReviewValue >
    (isEmpty(stages)
      ? totalRecordsPassedToFirstScreeningStage
      : ftStage
      ? ftReferencesIncludedCount
      : tiAbStage
      ? tiAbReferencesIncludedCount
      : preliminaryStage
      ? preliminaryReferencesIncludedCount
      : 0);

  const {
    preliminary: preliminaryReasonsWithCounts,
    tiAb: tiAbReasonsWithCounts,
    ftExclusion: ftExclusionReasonsWithCounts,
  } = renderReasonsWithCounts(domainsWithCounts);

  useEffect(() => {
    if (!isEmpty(data)) {
      const prismaData = get('project.prisma', data);

      setState((state) => ({
        ...merge({ ...state }, { ...prismaData }),
      }));
    }
  }, [setState, data]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <AdminPageContentWrapper
      rightHeaderElement={
        <ExportControls
          exportFormats={exportFormats}
          currentFormat={exportFormat}
          handleExportFormatChange={setExportFormat}
          handleExportDiagram={handleExportDiagram}
        />
      }
      title={<Trans>PRISMA diagram editor</Trans>}
    >
      {loading ? (
        <Spinner css={centerItemCss} />
      ) : (
        <div className="flex flex-row w-full">
          <div className="w-2/5 mb-4">
            <EditorSection
              title={recordsFromDatabases.title}
              updateSectionData={updateSectionData('recordsFromDatabases')}
            >
              <EditorItemsList
                caption={<Trans>Source</Trans>}
                items={recordsFromDatabases.records}
                sumSectionTitle={<Trans>Total number of records</Trans>}
                sumSectionValue={totalRecordsFromDatabases}
                onItemUpdate={handleItemChange('recordsFromDatabases')}
                onItemAdd={() => handleItemAdd('recordsFromDatabases')}
                onItemRemove={handleItemRemove('recordsFromDatabases')}
              />
            </EditorSection>

            <EditorSection
              checked={recordsFromAdditionalResources.checked}
              title={recordsFromAdditionalResources.title}
              updateSectionData={updateSectionData('recordsFromAdditionalResources')}
            >
              <EditorItemsList
                caption={<Trans>Source</Trans>}
                items={recordsFromAdditionalResources.records}
                sumSectionTitle={<Trans>Total number of records</Trans>}
                sumSectionValue={totalRecordsFromAdditionalResources}
                onItemUpdate={handleItemChange('recordsFromAdditionalResources')}
                onItemAdd={() => {
                  handleItemAdd('recordsFromAdditionalResources');
                }}
                onItemRemove={handleItemRemove('recordsFromAdditionalResources')}
              />
            </EditorSection>

            <EditorSection
              title={duplicatesRemoved.title}
              updateSectionData={updateSectionData('duplicatesRemoved')}
            >
              <div className="px-4 py-2 mb-2">
                <Trans>Number of duplicates removed</Trans>:{' '}
                <span className="font-bold">{Math.max(0, totalDuplicatesRemoved)}</span>
                <div
                  className="p-2 mt-4 mb-2 border-0 rounded flex flex-row justify-between"
                  css={lightGray4bg}
                >
                  <span
                    className="font-bold"
                    css={{
                      color: Colors.GRAY1,
                    }}
                  >
                    <Trans>To review</Trans>
                  </span>
                  <span>{totalRecordsPassedToFirstScreeningStage}</span>
                </div>
              </div>
            </EditorSection>

            {preliminaryStage && (
              <EditorSection
                title={recordsScreenedPreliminary.title}
                updateSectionData={updateSectionData('recordsScreenedPreliminary')}
              >
                <div className="flex flex-col px-4 py-2 mb-2">
                  <div
                    className="p-2 mt-4 mb-2 border-0 rounded flex flex-row justify-between"
                    css={lightGray4bg}
                  >
                    <span className="font-bold text-red-500">
                      <Trans>Out</Trans>
                    </span>
                    <span>{preliminaryReferencesExcludedCount}</span>
                  </div>
                  <div className="flex flex-col items-end my-1">{preliminaryReasonsWithCounts}</div>
                  <div
                    className="p-2 my-2 border-0 rounded flex flex-row justify-between"
                    css={lightGray4bg}
                  >
                    <span className="font-bold text-green-500">
                      <Trans>In</Trans>
                    </span>
                    <span>{preliminaryReferencesIncludedCount}</span>
                  </div>
                </div>
              </EditorSection>
            )}

            {tiAbStage && (
              <EditorSection
                title={recordsScreenedAgainstTitles.title}
                updateSectionData={updateSectionData('recordsScreenedAgainstTitles')}
              >
                <div className="flex flex-col px-4 py-2 mb-2">
                  <div
                    className="p-2 mt-4 mb-2 border-0 rounded flex flex-row justify-between"
                    css={lightGray4bg}
                  >
                    <span className="font-bold text-red-500">
                      <Trans>Out</Trans>
                    </span>
                    <span>{tiAbReferencesExcludedCount}</span>
                  </div>
                  <div className="flex flex-col items-end my-1">{tiAbReasonsWithCounts}</div>
                  <div
                    className="p-2 my-2 border-0 rounded flex flex-row justify-between"
                    css={lightGray4bg}
                  >
                    <span className="font-bold text-green-500">
                      <Trans>In</Trans>
                    </span>
                    <span>{tiAbReferencesIncludedCount}</span>
                  </div>
                </div>
              </EditorSection>
            )}

            {ftStage && (
              <Fragment>
                <EditorSection
                  checked={recordsNotRetrievedFullText.checked}
                  title={recordsNotRetrievedFullText.title}
                  updateSectionData={updateSectionData('recordsNotRetrievedFullText')}
                >
                  <div className="px-4 py-2 mb-2">
                    <div className="flex flex-col">
                      <div
                        className="p-2 mt-4 mb-2 border-0 rounded flex flex-row justify-between"
                        css={lightGray4bg}
                      >
                        <span
                          className="font-bold"
                          css={{
                            color: Colors.GRAY1,
                          }}
                        >
                          <Trans>Number of reports not retrieved</Trans>
                        </span>
                        <span>{recordsNotRetrievedFullTextCount}</span>
                      </div>
                    </div>
                  </div>
                </EditorSection>

                <EditorSection
                  title={recordsScreenedFullText.title}
                  updateSectionData={updateSectionData('recordsScreenedFullText')}
                >
                  <div className="flex flex-col px-4 py-2 mb-2">
                    <div
                      className="p-2 mt-4 mb-2 border-0 rounded flex flex-row justify-between"
                      css={lightGray4bg}
                    >
                      <span className="font-bold text-red-500">
                        <Trans>Out</Trans>
                      </span>
                      <span>{ftReferencesExcludedCount}</span>
                    </div>
                    <div className="flex flex-col items-end my-1">
                      {ftExclusionReasonsWithCounts}
                    </div>
                    <div
                      className="p-2 my-2 border-0 rounded flex flex-row justify-between"
                      css={lightGray4bg}
                    >
                      <span className="font-bold text-green-500">
                        <Trans>In</Trans>
                      </span>
                      <span>{ftReferencesIncludedCount}</span>
                    </div>
                  </div>
                </EditorSection>
              </Fragment>
            )}

            <EditorSection
              checked={newStudiesIncludedInReview.checked}
              title={newStudiesIncludedInReview.title}
              updateSectionData={updateSectionData('newStudiesIncludedInReview')}
            >
              <div className="px-4 py-2 mb-2">
                <FormGroup
                  label={<Trans>Number of studies</Trans>}
                  helperText={isNewStudiesIncludedInReviewValueInvalid ? renderEditorError() : ''}
                >
                  <InputGroup
                    className="w-1/2"
                    css={textRightCss}
                    name="newStudiesIncludedInReviewValue"
                    type="text"
                    intent={
                      isNewStudiesIncludedInReviewValueInvalid ? Intent.DANGER : Intent.PRIMARY
                    }
                    value={newStudiesIncludedInReviewValue.toString()}
                    onChange={(e) =>
                      updateSectionData('newStudiesIncludedInReview', {
                        value: Number(filterOutNotNumbers(e.target.value)),
                      })
                    }
                  />
                </FormGroup>
              </div>
            </EditorSection>
          </div>
          <div className="w-full h-full border-l">
            <div id="prisma-diagram">
              <Diagram>
                <DiagramCustomNode
                  title={recordsFromDatabases.title}
                  records={recordsFromDatabases.records}
                  value={totalRecordsFromDatabases}
                  row={1}
                  col={hasNodeConnectionWith(recordsFromAdditionalResources) ? 2 : 4}
                />
                {hasNodeConnectionWith(recordsFromAdditionalResources) && (
                  <DiagramCustomNode
                    title={recordsFromAdditionalResources.title}
                    records={recordsFromAdditionalResources.records}
                    value={totalRecordsFromAdditionalResources}
                    row={1}
                    col={6}
                  />
                )}
                {hasNodeConnectionWith(recordsFromAdditionalResources) ? (
                  <ArrowNode
                    dual
                    noHead
                    path={dualArrow(700, 60, true)}
                    rowStart={2}
                    rowEnd={3}
                    colStart={2}
                    colEnd={9}
                  />
                ) : (
                  <ArrowNode noHead rowStart={2} rowEnd={3} colStart={4} colEnd={7} />
                )}
                <ArrowNode
                  irregularScale
                  noHead
                  rowStart={duplicatesRemovedRowStart}
                  rowEnd={duplicatesRemovedRowStart + 1}
                  colStart={4}
                  colEnd={7}
                />
                <DiagramNode row={duplicatesRemovedRowStart} col={6}>
                  <div
                    className="p-2 border-0 rounded flex flex-row justify-between"
                    css={lightGray4bg}
                  >
                    <span className="font-bold text-red-500">{duplicatesRemoved.title}</span>
                    <span>{Math.max(0, totalDuplicatesRemoved)}</span>
                  </div>
                </DiagramNode>
                <ArrowNode
                  path={sideRightArrow(300, 60)}
                  rowStart={duplicatesRemovedRowStart}
                  rowEnd={duplicatesRemovedRowStart + 1}
                  colStart={4}
                  colEnd={7}
                />
                {preliminaryStage && (
                  <Fragment>
                    <ArrowNode
                      rowStart={preliminaryRowStart}
                      rowEnd={preliminaryRowStart + 1}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={recordsScreenedPreliminary.title}
                      value={preliminaryReferencesCount}
                      row={preliminaryRowStart + 1}
                      col={4}
                    />
                    <ArrowNode
                      path={
                        hasNodeConnectionWith(newStudiesIncludedInReview) || tiAbStage
                          ? undefined
                          : halfArrow(300, 60)
                      }
                      irregularScale
                      noHead
                      rowStart={preliminaryRowStart + 2}
                      rowEnd={preliminaryRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <ArrowNode
                      path={sideRightArrow(300, 60)}
                      rowStart={preliminaryRowStart + 3}
                      rowEnd={preliminaryRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramNode row={preliminaryRowStart + 3} col={6}>
                      <div className="flex flex-col">
                        <div
                          className="p-2 mb-2 border-0 rounded flex flex-row justify-between"
                          css={lightGray4bg}
                        >
                          <span className="font-bold text-red-500">
                            <Trans>Records excluded</Trans>
                          </span>
                          <span>{preliminaryReferencesExcludedCount}</span>
                        </div>
                        <div className="flex flex-col items-end my-1">
                          {preliminaryReasonsWithCounts}
                        </div>
                      </div>
                    </DiagramNode>
                  </Fragment>
                )}
                {tiAbStage && (
                  <Fragment>
                    <ArrowNode
                      rowStart={tiAbRowStart}
                      rowEnd={tiAbRowStart + 1}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={recordsScreenedAgainstTitles.title}
                      value={tiAbReferencesCount}
                      row={tiAbRowStart + 1}
                      col={4}
                    />
                    <ArrowNode
                      path={
                        hasNodeConnectionWith(newStudiesIncludedInReview) || ftStage
                          ? undefined
                          : halfArrow(300, 60)
                      }
                      irregularScale
                      noHead
                      rowStart={tiAbRowStart + 2}
                      rowEnd={tiAbRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <ArrowNode
                      path={sideRightArrow(300, 60)}
                      rowStart={tiAbRowStart + 3}
                      rowEnd={tiAbRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramNode row={tiAbRowStart + 3} col={6}>
                      <div className="flex flex-col">
                        <div
                          className="p-2 mb-2 border-0 rounded flex flex-row justify-between"
                          css={lightGray4bg}
                        >
                          <span className="font-bold text-red-500">
                            <Trans>Records excluded</Trans>
                          </span>
                          <span>{tiAbReferencesExcludedCount}</span>
                        </div>
                        <div className="flex flex-col items-end my-1">{tiAbReasonsWithCounts}</div>
                      </div>
                    </DiagramNode>
                  </Fragment>
                )}
                {ftStage && (
                  <Fragment>
                    <ArrowNode
                      rowStart={ftRowStart}
                      rowEnd={ftRowStart + 1}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={<Trans>Reports sought for retrieval</Trans>}
                      value={reportsSoughtForRetrieval}
                      row={ftRowStart + 1}
                      col={4}
                    />
                    <ArrowNode
                      irregularScale
                      noHead
                      rowStart={ftRowStart + 2}
                      rowEnd={ftRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramNode row={ftRowStart + 3} col={6}>
                      <div
                        className="p-2 border-0 rounded flex flex-row justify-between"
                        css={lightGray4bg}
                      >
                        <span className="font-bold text-red-500">
                          {recordsNotRetrievedFullText.title}
                        </span>
                        <span>{recordsNotRetrievedFullTextCount}</span>
                      </div>
                    </DiagramNode>
                    <ArrowNode
                      path={sideRightArrow(300, 60)}
                      rowStart={ftRowStart + 3}
                      rowEnd={ftRowStart + 4}
                      colStart={4}
                      colEnd={7}
                    />
                    <ArrowNode
                      rowStart={ftRowStart + 4}
                      rowEnd={ftRowStart + 5}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={recordsScreenedFullText.title}
                      value={ftReferencesCount}
                      row={ftRowStart + 5}
                      col={4}
                    />
                    <ArrowNode
                      irregularScale
                      noHead
                      rowStart={ftRowStart + 6}
                      rowEnd={ftRowStart + 8}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramNode row={ftRowStart + 7} col={6}>
                      <div className="flex flex-col">
                        <div
                          className="p-2 mb-2 border-0 rounded flex flex-row justify-between"
                          css={lightGray4bg}
                        >
                          <span className="font-bold text-red-500">
                            <Trans>Reports excluded</Trans>
                          </span>
                          <span>{ftReferencesExcludedCount}</span>
                        </div>
                        <div className="flex flex-col items-end my-1">
                          {ftExclusionReasonsWithCounts}
                        </div>
                      </div>
                    </DiagramNode>
                    <ArrowNode
                      path={sideRightArrow(300, 60)}
                      rowStart={ftRowStart + 7}
                      rowEnd={ftRowStart + 8}
                      colStart={4}
                      colEnd={7}
                    />
                    <ArrowNode
                      rowStart={ftRowStart + 8}
                      rowEnd={ftRowStart + 9}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={<Trans>Reports of included studies</Trans>}
                      value={ftReferencesIncludedCount}
                      row={ftRowStart + 9}
                      col={4}
                    />
                  </Fragment>
                )}
                {hasNodeConnectionWith(newStudiesIncludedInReview) && (
                  <Fragment>
                    <ArrowNode
                      rowStart={newStudiesIncludedInReviewRowStart}
                      rowEnd={newStudiesIncludedInReviewRowStart + 1}
                      colStart={4}
                      colEnd={7}
                    />
                    <DiagramCustomNode
                      title={newStudiesIncludedInReview.title}
                      value={newStudiesIncludedInReviewValue}
                      row={newStudiesIncludedInReviewRowStart + 1}
                      col={4}
                    />
                  </Fragment>
                )}
                <DiagramVerticalNode
                  id={STAGE_LABEL_IDS.IDENTIFICATION}
                  col={1}
                  rowStart={1}
                  rowEnd={duplicatesRemovedRowStart + 1}
                >
                  <Trans>Identification</Trans>
                </DiagramVerticalNode>
                {(preliminaryStage || tiAbStage) && (
                  <DiagramVerticalNode
                    id={STAGE_LABEL_IDS.SCREENING}
                    col={1}
                    rowStart={preliminaryRowStart}
                    rowEnd={ftRowStart}
                  >
                    <Trans>Screening</Trans>
                  </DiagramVerticalNode>
                )}
                {ftStage && (
                  <DiagramVerticalNode
                    id={STAGE_LABEL_IDS.ELIGIBILITY}
                    col={1}
                    rowStart={ftRowStart}
                    rowEnd={newStudiesIncludedInReviewRowStart - 1}
                  >
                    <Trans>Eligibility</Trans>
                  </DiagramVerticalNode>
                )}
                {(hasNodeConnectionWith(newStudiesIncludedInReview) || ftStage) && (
                  <DiagramVerticalNode
                    id={STAGE_LABEL_IDS.INCLUDED}
                    col={1}
                    rowStart={newStudiesIncludedInReviewRowStart + (ftStage ? -1 : 0)}
                    rowEnd={newStudiesIncludedInReviewRowStart + 3}
                  >
                    <Trans>Included</Trans>
                  </DiagramVerticalNode>
                )}
              </Diagram>
            </div>
          </div>
        </div>
      )}
    </AdminPageContentWrapper>
  );
};

export default PrismaDiagram;
