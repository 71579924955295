/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export const diagramBlock = (row: number, column: number) => css`
  grid-column-start: ${column};
  grid-column-end: ${column + 3};
  grid-row-start: ${row};
  grid-row-end: ${row + 1};
`;
interface IDiagramNodeProps {
  col: number;
  row: number;
}

const DiagramNode: React.FC<IDiagramNodeProps> = ({ children, col, row }) => {
  return (
    <div className="px-4 py-4 border border-black rounded bg-white" css={diagramBlock(row, col)}>
      {children}
    </div>
  );
};

export default DiagramNode;
