import React, { ReactNode } from 'react';
import EditToggles from '../common/edit_toggles';
import { isEmpty, isFunction } from 'lodash/fp';
import { Trans } from '@lingui/macro';
import { IconName } from '@blueprintjs/core';

interface ToString {
  toString(): string;
}

const defaultContentRender = (content: ToString) => {
  return (
    <div className="whitespace-pre-line leading-normal">
      {isEmpty(content) ? (
        <span className="italic text-gray-700">
          <Trans>not specified</Trans>
        </span>
      ) : (
        content.toString()
      )}
    </div>
  );
};

interface IEditableSectionProps<T> {
  title: ReactNode;
  content: T;
  editRenderer?: (editedContent: T, setEditedContent: (newContent: T) => void) => ReactNode;
  contentRenderer?: (content: T) => ReactNode;
  onSave?: (content: T) => Promise<any>;
  saving?: boolean;
  className?: string;
  saveDisabled?: boolean | ((editedContent: T) => boolean);
  saveIcon?: IconName;
}

interface IEditableSectionState<T> {
  editedContent: T | null;
}

export default class EditableSection<T> extends React.PureComponent<
  IEditableSectionProps<T>,
  IEditableSectionState<T>
> {
  public static ofType<T>() {
    return EditableSection as new (props: IEditableSectionProps<T>) => EditableSection<T>;
  }
  constructor(props) {
    super(props);

    this.state = { editedContent: null };
  }

  setEditedContent = (editedContent: T | null) => {
    this.setState({ editedContent });
  };

  handleSave = () => {
    if (!isFunction(this.props.onSave)) return;
    this.props.onSave(this.state.editedContent!).then(() => this.setEditedContent(null));
  };

  render() {
    const {
      title,
      className,
      content,
      editRenderer,
      contentRenderer,
      saving,
      saveDisabled,
      saveIcon,
    } = this.props;
    const { editedContent } = this.state;
    const editing = editedContent != null;
    const readOnly = editRenderer == null;

    return (
      <div className={className}>
        <div className="flex flex-row justify-between items-center mb-2">
          <span className="text-xs text-gray-600">{title}</span>
          {!readOnly && (
            <EditToggles
              editing={editing}
              onCancel={() => this.setEditedContent(null)}
              onSave={this.handleSave}
              onEdit={() => this.setEditedContent(content)}
              saving={saving}
              saveDisabled={
                editing
                  ? isFunction(saveDisabled)
                    ? saveDisabled(editedContent!)
                    : saveDisabled
                  : undefined
              }
              saveIcon={saveIcon}
            />
          )}
        </div>
        {readOnly || !editing
          ? (contentRenderer ?? defaultContentRender)(content)
          : editRenderer!(editedContent!, this.setEditedContent)}
      </div>
    );
  }
}
