import React, { Fragment, ReactNode } from 'react';
import {
  Popover,
  Button,
  IconName,
  PopoverPosition,
  Menu,
  MenuItem,
  IMenuItemProps,
  Intent,
  IButtonProps,
  Tooltip,
  MenuDivider,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import i18n from '../../i18n';
import { t } from '@lingui/macro';
import { ActionProps, CategoryWithActions } from '../../common/types';
interface IActionsMenuProps {
  disabled?: boolean;
  menuButtonProps?: IButtonProps;
  actions?: ActionProps[];
  categoriesWithActions?: CategoryWithActions[];
  menuPosition?: PopoverPosition;
}

const actionRenderer: (action: ActionProps, idx: number) => ReactNode = (
  { tooltipMessage, ...menuItemProps }: ActionProps,
  idx: number
) =>
  tooltipMessage ? (
    <Tooltip key={idx} content={tooltipMessage as string}>
      <MenuItem {...menuItemProps} />
    </Tooltip>
  ) : (
    <MenuItem key={idx} {...menuItemProps} />
  );

const Actions = ({
  actions,
  categoriesWithActions,
}: Pick<IActionsMenuProps, 'actions' | 'categoriesWithActions'>) => (
  <Menu>
    {categoriesWithActions?.map((categoryWithActions, idx) => (
      <Fragment key={idx}>
        <MenuDivider title={categoryWithActions.name} />
        {categoryWithActions.actions.map(actionRenderer)}
      </Fragment>
    ))}
    {categoriesWithActions && actions ? <MenuDivider title={i18n._(t`Other actions`)} /> : null}
    {actions?.map(actionRenderer)}
  </Menu>
);

const commonActions = {
  delete: {
    icon: IconNames.DELETE as IconName,
    text: i18n._(t`Delete`),
    intent: Intent.DANGER as Intent,
  },
  edit: {
    icon: IconNames.EDIT as IconName,
    text: i18n._(t`Edit`),
  },
};

export function createMenuItem(
  action: 'delete' | 'edit',
  handler: (evt: React.MouseEvent) => void
): IMenuItemProps {
  return { ...commonActions[action], onClick: handler };
}

const DEFAULT_MENU_BTN_PROPS: IButtonProps = {
  minimal: true,
  icon: IconNames.MORE,
};

const ActionsMenu: React.FC<IActionsMenuProps> = ({
  actions,
  categoriesWithActions,
  disabled,
  menuPosition,
  menuButtonProps,
}) => (
  <Popover
    content={<Actions actions={actions} categoriesWithActions={categoriesWithActions} />}
    disabled={disabled}
    position={menuPosition}
  >
    <Button {...DEFAULT_MENU_BTN_PROPS} disabled={disabled} {...(menuButtonProps ?? {})} />
  </Popover>
);

export default ActionsMenu;
