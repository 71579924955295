/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Card, TextArea } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { useCallback } from 'react';
import EditableSection from './editable_section';

const QuestionEditSection = EditableSection.ofType<string>();

interface IQuestionProps {
  question?: string;
  onSave?: (question: string) => Promise<any>;
  saving?: boolean;
  readOnly?: boolean;
}

const QuestionEdit: React.FC<IQuestionProps> = ({ question, onSave, saving, readOnly }) => {
  const editRenderer = useCallback(
    (editedQuestion, setEditedQuestion) => (
      <TextArea
        autoFocus
        className="w-full mt-3"
        value={editedQuestion}
        onChange={(evt) => setEditedQuestion(evt.target.value.replace(/\n/g, ' '))}
      />
    ),
    []
  );

  return (
    <Card>
      <QuestionEditSection
        title={<Trans>Question</Trans>}
        onSave={onSave}
        saving={saving}
        content={question ?? ''}
        editRenderer={readOnly ? undefined : editRenderer}
      />
    </Card>
  );
};

export default QuestionEdit;
