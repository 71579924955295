/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Card, Classes, Dialog, FormGroup, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash/fp';
import { useState } from 'react';
import { InclusionExclusionCriteriaFormData } from '../../common/types';
import { useCurrCallback } from '../../lib/utils';
import { lightGray3bg } from '../../common/styles';

interface ILoadCriteriaDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onLoad: (templateId: string, template: InclusionExclusionCriteriaFormData) => Promise<any>;
  currentFormTemplateId?: string | null;
  templates: { id: string; name: string; template: any }[];
}

const LoadCriteriaDialog: React.FC<ILoadCriteriaDialogProps> = ({
  isOpen,
  onClose,
  onLoad,
  currentFormTemplateId,
  templates,
}) => {
  const [loading, setLoading] = useState(false);

  const handleTemplateLoad = useCurrCallback(
    (templateId, template, _evt) => {
      setLoading(true);
      onLoad(templateId, template)
        .then(() => onClose())
        .finally(() => setLoading(false));
    },
    [onLoad]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      title={<Trans>Load criteria set</Trans>}
      className="overflow-auto"
      css={{ maxHeight: '70vh', width: '40vw' }}
    >
      <div className={`${Classes.DIALOG_BODY} overflow-auto`}>
        <FormGroup
          label={<Trans>Pick a criteria set you want to load</Trans>}
          helperText={<Trans>The existing set will be overridden</Trans>}
        />
        <div css={lightGray3bg} className="p-3 flex flex-col items-center justify-between">
          {isEmpty(templates) ? (
            <NonIdealState
              icon={IconNames.FOLDER_OPEN}
              title={<Trans>There are no criteria sets to load</Trans>}
            />
          ) : (
            templates.map(({ id, name, template }) => (
              <Card
                key={id}
                className="p-2 w-full  mb-2 flex flex-row justify-between items-center"
              >
                <span>{name}</span>
                <Button
                  disabled={id === currentFormTemplateId}
                  className="w-32"
                  loading={loading}
                  text={id === currentFormTemplateId ? <Trans>Loaded</Trans> : <Trans>Load</Trans>}
                  onClick={handleTemplateLoad(id, template)}
                />
              </Card>
            ))
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
};

export default LoadCriteriaDialog;
