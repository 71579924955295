import {
  compose,
  entries,
  filter,
  find,
  get,
  isEmpty,
  isEqual,
  keyBy,
  propEq,
  round,
  some,
} from 'lodash/fp';
import { Stage, Study, StudyDistribution } from '../common/types';
import { TScreenerData } from './distribution_helpers';

export type StageStats = Pick<Stage, 'id' | 'studies_by_status'>;
export type StudyCounts = {
  total: number;
  toReview: number;
  included: number;
  excluded: number;
  conflicts: number;
  progress: number;
};

export function getStageStudyCounts(stageStats: StageStats | undefined): StudyCounts | null {
  if (stageStats == null) return null;
  const stats = keyBy('inclusion_status', stageStats.studies_by_status);
  const included = stats.included?.count ?? 0;
  const excluded = stats.excluded?.count ?? 0;
  const conflicts = stats.conflict?.count ?? 0;
  const toReview = stats.null?.count ?? 0;
  const total = toReview + included + excluded + conflicts;
  return {
    total,
    toReview,
    included,
    excluded,
    conflicts,
    progress: total === 0 ? 0 : round((1 - toReview / total) * 100),
  };
}
export type TStudyToDistribute = Pick<Study, 'id' | 'study_pool_studies' | 'tasks' | 'references'>;
export function prepareStudyPoolInsertInput(
  projectId: string,
  stageId: string,
  distributionType: StudyDistribution,
  studiesPerMember: { [memberId: string]: number },
  studies: TStudyToDistribute[],
  projectScreeners: TScreenerData[],
  poolId?: string,
  poolMembers?: { task_count: number; team_member_id: string }[]
) {
  const newStudies = filter((study) => {
    return (
      isEmpty(study.study_pool_studies) ||
      !some(compose(isEqual(stageId), get('study_pool.stage_id')), study.study_pool_studies)
    );
  }, studies);

  const studyPoolMembers = entries(studiesPerMember).map(([team_member_id, task_count]) => {
    const currentData = find(propEq('team_member_id', team_member_id), poolMembers);
    const currentTaskCount = currentData
      ? currentData.task_count
      : find({ id: team_member_id }, projectScreeners)?.tasks_aggregate.aggregate.count ?? 0;

    return {
      team_member_id,
      task_count: task_count + currentTaskCount,
      completed: false,
    };
  });

  const newStudyPoolStudies = newStudies.map((study) => ({
    study_id: study.id,
    project_id: projectId,
  }));

  return {
    ...(poolId ? { id: poolId } : {}),
    project_id: projectId,
    stage_id: stageId,
    last_distribution: 'now()',
    distribution_type: distributionType,
    study_pool_team_members: {
      data: studyPoolMembers,
      on_conflict: {
        constraint: 'study_pool_team_member_pkey',
        update_columns: ['task_count', 'completed'],
      },
    },
    study_pool_studies: {
      data: newStudyPoolStudies,
    },
  };
}
