/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Immutable from 'immutable';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  Button,
  Card,
  Collapse,
  Colors,
  Divider,
  InputGroup,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { t, Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { compact, debounce, first, get, isEmpty, isNil, omit, trim, uniqBy } from 'lodash/fp';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect } from 'react';
import { fancyScrollCss, lightGray5bg } from '../../../common/styles';
import { GraphQLAggregateData, Reference } from '../../../common/types';
import { getAuthors, getYear } from '../helpers';
import { IconNames } from '@blueprintjs/icons';
import { useCurrCallback, useI18n, useSetState } from '../../../lib/utils';
import { REFERENCES_BATCH_SIZE } from '../../screening';
import BibliographicDetails, { IBibliographicDetailsData } from './bibliographic_details';
import AppToaster from '../../../lib/toaster';

const referenceTitleCss = css`
  color: ${Colors.DARK_GRAY5};
`;

const referencesQuery = gql`
  query getReferences($projectId: uuid!, $query: String, $offset: Int!, $limit: Int!) {
    reference_aggregate(
      where: {
        project_id: { _eq: $projectId }
        deleted_at: { _is_null: true }
        _or: [
          { title: { _ilike: $query } }
          { reference_attributes: { first_authors_last_name: { _ilike: $query } } }
        ]
        _not: { reference_attachments: {} }
      }
    ) {
      aggregate {
        count
      }
    }
    references: reference(
      where: {
        project_id: { _eq: $projectId }
        deleted_at: { _is_null: true }
        _or: [
          { title: { _ilike: $query } }
          { reference_attributes: { first_authors_last_name: { _ilike: $query } } }
        ]
        _not: { reference_attachments: {} }
      }
      order_by: { study_id: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      title
      attrs
    }
  }
`;

function getBibliographicDetails(
  reference?: Pick<Reference, 'attrs' | 'title'>
): IBibliographicDetailsData {
  return {
    title: reference?.title ?? '',
    authors: reference ? getAuthors(reference) : [{ firstNames: [], lastName: '' }],
    doi: reference?.attrs.doi ?? '',
    venue: reference?.attrs.venue ?? '',
    year: reference?.attrs.year ?? '',
    volume: reference?.attrs.volume ?? '',
    issue: reference?.attrs.issue ?? '',
    pages: reference?.attrs.pages ?? '',
    abstract: reference?.attrs.abstract ?? '',
  };
}

interface IReferencesColumnState {
  searchValue: string;
  searchQuery: string | null;
  loadingMoreReferences: boolean;
  expandedReferences: Immutable.Set<string>;
  newReferenceData: IBibliographicDetailsData | null;
  invalidFields: { [fieldName: string]: string };
}

const INITIAL_STATE: IReferencesColumnState = {
  searchValue: '',
  searchQuery: '',
  loadingMoreReferences: false,
  expandedReferences: Immutable.Set(),
  newReferenceData: null,
  invalidFields: {},
};

interface IReferencesColumnProps {
  projectId: string;
  onAssign: (referenceId: string) => Promise<any>;
  onCreateAndAssignReference?: (referenceFields: IBibliographicDetailsData) => void;
  assigning?: boolean;
}

type TReferencesQueryData = {
  reference_aggregate: GraphQLAggregateData;
  references: Pick<Reference, 'id' | 'attrs' | 'title'>[];
};

const ReferencesColumn: React.FC<IReferencesColumnProps> = ({
  projectId,
  onAssign,
  onCreateAndAssignReference,
  assigning,
}) => {
  const i18n = useI18n();
  const [fetchReferences, { loading, data, fetchMore, refetch }] =
    useLazyQuery<TReferencesQueryData>(referencesQuery, { variables: { projectId } });
  const [state, setState] = useSetState<IReferencesColumnState>(INITIAL_STATE);
  const {
    searchValue,
    searchQuery,
    loadingMoreReferences,
    expandedReferences,
    newReferenceData,
    invalidFields,
  } = state;
  const { references, reference_aggregate } = data ?? {};
  const referencesCount = (references ?? []).length;
  const totalReferencesCount = get('aggregate.count', reference_aggregate) ?? 0;
  const hasMoreReferences = totalReferencesCount > referencesCount;

  const updateSearchQuery = useCallback(
    debounce(200, (searchValue: string) => {
      const trimmed = trim(searchValue);
      setState({
        searchQuery: trimmed ?? null,
      });
    }),
    [setState]
  );

  const handleSearchQueryChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setState({ searchValue: value });
    updateSearchQuery(value);
  };

  const handleLoadMoreReferences = useCallback(() => {
    setState({ loadingMoreReferences: true });
    fetchMore({
      variables: {
        offset: referencesCount,
        limit: REFERENCES_BATCH_SIZE,
      },
      updateQuery: (prev: TReferencesQueryData, { fetchMoreResult }) => {
        if (isNil(fetchMoreResult)) return prev;

        return {
          ...fetchMoreResult,
          references: uniqBy(get('id'), [...prev.references, ...fetchMoreResult.references]),
        };
      },
    }).finally(() => {
      setState({ loadingMoreReferences: false });
    });
  }, [setState, fetchMore, referencesCount]);

  const toggleReferenceExpand = useCurrCallback(
    (referenceId, _evt) => {
      setState((currentState) => ({
        ...currentState,
        expandedReferences: currentState.expandedReferences.has(referenceId)
          ? currentState.expandedReferences.delete(referenceId)
          : currentState.expandedReferences.add(referenceId),
      }));
    },
    [setState]
  );

  const handleAssign = useCurrCallback(
    (refId: string, _evt) => {
      onAssign(refId)
        .then(() => refetch())
        .catch((err) =>
          AppToaster.show({
            message: err.toString(),
            intent: Intent.WARNING,
          })
        );
    },
    [onAssign, refetch]
  );

  const openCreateReference = useCallback(() => {
    setState((current) =>
      current.newReferenceData == null
        ? { ...current, newReferenceData: getBibliographicDetails() }
        : current
    );
  }, [setState]);

  const clearSearch = useCallback(() => {
    setState({ searchQuery: '', searchValue: '' });
  }, [setState]);

  const handleKey = useCallback(
    (evt: KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Escape') return clearSearch();
    },
    [setState]
  );

  const handleNewReferenceFieldChange = useCallback(
    (fieldName: string, fieldValue: any) => {
      setState((current) => ({
        ...current,
        invalidFields:
          fieldName in current.invalidFields
            ? omit([fieldName], current.invalidFields)
            : current.invalidFields,
        newReferenceData: {
          ...current.newReferenceData!,
          [fieldName]: fieldValue,
        },
      }));
    },
    [setState]
  );

  const handleCreateAndAssign = () => {
    if (newReferenceData == null) return;
    // validate the input: there should be proper year field and the first author with his last name
    const { year, authors } = newReferenceData;
    const invalidFields = {};
    if (isEmpty(year) || isNaN(Number(year))) {
      invalidFields['year'] = i18n._(t`This field is required and must contain numeric value`);
    }
    if (isEmpty(authors) || isEmpty(first(authors)!.lastName)) {
      invalidFields['authors'] = i18n._(t`There must be at least one filled author`);
    }

    // process the new reference if there are no validation errors. report errors otherwise
    if (isEmpty(invalidFields)) {
      onCreateAndAssignReference?.(newReferenceData);
      setState({ newReferenceData: null, invalidFields: {} });
    } else {
      setState({ invalidFields });
    }
  };

  useEffect(() => {
    fetchReferences({
      variables: {
        projectId,
        query: `%${searchQuery}%`,
        offset: 0,
        limit: REFERENCES_BATCH_SIZE,
      },
    });
  }, [fetchReferences, searchQuery, projectId]);

  return (
    <div className="h-full flex flex-col flex-1 overflow-hidden">
      <div className="flex h-12 items-center px-8">
        <InputGroup
          leftIcon={IconNames.SEARCH}
          className="mr-4 flex-1"
          autoFocus
          placeholder={i18n._(t`Search for references`)}
          onChange={handleSearchQueryChange}
          value={searchValue}
          onKeyDown={handleKey}
          disabled={newReferenceData != null}
          rightElement={
            isEmpty(searchValue) ? undefined : (
              <Button minimal icon={IconNames.CROSS} intent={Intent.DANGER} onClick={clearSearch} />
            )
          }
        />
        <Button
          text={<Trans>Create new reference</Trans>}
          className="w-40"
          onClick={openCreateReference}
          disabled={onCreateAndAssignReference == null}
        />
      </div>
      <Divider className="m-0" />
      <div className="flex-1 overflow-auto references-list px-6 py-4 bg-white" css={fancyScrollCss}>
        {loading && references == null ? (
          <Spinner />
        ) : newReferenceData ? (
          <Card css={lightGray5bg} className="mb-4 p-0">
            <div className="flex flex-row w-full items-center py-3 px-4">
              <div className="truncate flex-1 text-xl" css={referenceTitleCss}>
                <Trans>New reference</Trans>
              </div>
            </div>
            <Divider className="m-0" />
            <div className="bg-white flex flex-col">
              <BibliographicDetails
                className="px-4 py-2"
                {...newReferenceData}
                onChange={handleNewReferenceFieldChange}
                invalidFields={invalidFields}
              />
              <Divider className="m-0" />
              <div className="text-right px-3 my-2">
                <Button
                  text={<Trans>Cancel</Trans>}
                  className="mr-4"
                  onClick={() => setState({ newReferenceData: null, invalidFields: {} })}
                />
                <Button
                  onClick={handleCreateAndAssign}
                  text={<Trans>Assign reference to PDF file</Trans>}
                />
              </div>
            </div>
          </Card>
        ) : isEmpty(references) ? (
          isEmpty(searchQuery) ? (
            <NonIdealState
              icon={IconNames.INBOX}
              title={<Trans>There are no references without attachments</Trans>}
            />
          ) : (
            <NonIdealState
              icon={IconNames.DOCUMENT}
              title={
                <span className="text-xl font-normal" css={{ color: Colors.GRAY1 }}>
                  <Trans>No references found</Trans>
                </span>
              }
              action={
                <Button
                  onClick={openCreateReference}
                  disabled={onCreateAndAssignReference == null}
                  text={<Trans>Create new reference</Trans>}
                />
              }
            />
          )
        ) : (
          <React.Fragment>
            {references?.map((reference) => {
              const pubYear = getYear(reference);
              const authors = getAuthors(reference);
              const citation = compact([get('[0].lastName', authors) ?? '', pubYear ?? '']).join(
                ' '
              );
              const titleText = compact([citation, reference.title]).join(' - ');

              return (
                <Card key={reference.id} css={lightGray5bg} className="mb-4 p-0">
                  <div className="flex flex-row w-full items-center py-3 px-4">
                    <div
                      className="truncate flex-1 text-xl"
                      title={titleText}
                      css={referenceTitleCss}
                    >
                      {titleText}
                    </div>
                    <Button
                      minimal
                      className="flex-none ml-2"
                      icon={IconNames.CHEVRON_DOWN}
                      onClick={toggleReferenceExpand(reference.id)}
                    />
                  </div>
                  <Collapse isOpen={expandedReferences.has(reference.id)}>
                    <Divider className="m-0" />
                    <div className="bg-white flex flex-col">
                      <BibliographicDetails
                        className="px-4 py-2"
                        {...getBibliographicDetails(reference)}
                      />
                      <Divider className="m-0" />
                      <div className="text-right px-3 my-2">
                        <Button
                          onClick={handleAssign(reference.id)}
                          text={<Trans>Assign reference to PDF file</Trans>}
                          loading={assigning}
                        />
                      </div>
                    </div>
                  </Collapse>
                </Card>
              );
            })}
            {hasMoreReferences && (
              <div className="flex flex-col justify-center items-center mt-4">
                <Trans>
                  Currently displaying {referencesCount} of {totalReferencesCount} references.
                </Trans>
                <Button
                  className="mt-2"
                  text={<Trans>Load more references</Trans>}
                  onClick={handleLoadMoreReferences}
                  loading={loadingMoreReferences}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ReferencesColumn;
