import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { DecisionFilter } from '../../apollo/screening_state';

const SetDecisionFilterMutation = loader('../../graphql/local/set_decision_filter.gql');

export default function useSetDecisionFilter() {
  const [setDecisionFilter] = useMutation(SetDecisionFilterMutation);

  const doSetDecisionFilter = useCallback(
    (newDecisionFilter: DecisionFilter) => {
      setDecisionFilter({ variables: { newDecisionFilter } });
    },
    [setDecisionFilter]
  );

  return doSetDecisionFilter;
}
