/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Classes } from '@blueprintjs/core';
import { useMemo } from 'react';
import { useTheme } from '../settings/theme_context';

interface IProgressBarProps {
  className?: string;
  loading?: boolean;
  showIfTotal0?: boolean;
  studiesIn: number;
  studiesOut: number;
  total: number;
  height?: 1 | 2;
}

const ProgressBar: React.FC<IProgressBarProps> = ({
  className,
  loading,
  showIfTotal0,
  studiesIn,
  studiesOut,
  total,
  height = 1,
}) => {
  const {
    statusColors: { acceptedPrimary, rejectedPrimary, neutralPrimary },
  } = useTheme();
  const inWidth = useMemo(() => {
    if (total === 0) return 0;
    return 100 * (studiesIn / total);
  }, [studiesIn, total]);
  const outWidth = useMemo(() => {
    if (total === 0) return 0;
    return 100 * (studiesOut / total);
  }, [studiesOut, total]);
  const remainingWidth = useMemo(() => {
    if (total === 0) return 100;
    return (100 * (total - (studiesIn + studiesOut))) / total;
  }, [studiesIn, studiesOut, total]);

  if (total === 0 && !showIfTotal0) return null;

  return (
    <div
      data-testid="progress-bar"
      className={`flex flex-row flex-no-wrap w-full relative h-${height} ${
        loading ? Classes.SKELETON : ''
      } ${className ?? ''}`}
    >
      <div className="h-full" css={{ width: `${inWidth}%`, backgroundColor: acceptedPrimary }} />
      <div className="h-full" css={{ width: `${outWidth}%`, backgroundColor: rejectedPrimary }} />
      <div
        className="h-full"
        css={{ width: `${remainingWidth}%`, backgroundColor: neutralPrimary }}
      />
    </div>
  );
};

export default ProgressBar;
