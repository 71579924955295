/** @jsx jsx **/
import { Button, Divider, Icon, IconSize, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { ReactNode } from 'react';
import { bgTransition } from '../../common/styles';
import { TScreeningPanel } from './list';

interface IToggleProps {
  filtersApplied?: boolean;
  activePanel: TScreeningPanel | null;
  activeReference?: number;
  togglePanel: (value: React.SetStateAction<TScreeningPanel | null>) => void;
}

export const renderFiltersToggle = ({
  filtersApplied,
  activePanel,
  togglePanel,
}: IToggleProps): ReactNode => {
  const icon = filtersApplied ? IconNames.FILTER_KEEP : IconNames.FILTER;
  const hasFiltersPanelActive = activePanel === 'filters';
  return (
    <div className="h-full flex flex-row">
      <Tooltip className="flex h-full" content={<Trans>Filter</Trans>}>
        <Button
          className="h-full flex-none"
          icon={<Icon className="p-2" icon={icon} iconSize={16} />}
          css={bgTransition}
          onClick={() => togglePanel('filters')}
          intent={hasFiltersPanelActive ? Intent.PRIMARY : Intent.NONE}
          large
          minimal
        />
      </Tooltip>
      <Divider className="h-full m-0" />
    </div>
  );
};

export const renderPreviewToggle = ({
  activeReference,
  activePanel,
  togglePanel,
}: IToggleProps): ReactNode => {
  const hasPreviewPanelActive = activePanel === 'preview';
  return (
    <div className="h-full flex flex-row">
      <Divider className="h-full m-0" />
      <Tooltip className="flex h-full" content={<Trans>Bibliographic data</Trans>}>
        <Button
          disabled={activeReference == null}
          className="h-full flex-none"
          icon={<Icon className="p-2" icon={IconNames.INFO_SIGN} iconSize={IconSize.LARGE} />}
          css={bgTransition}
          onClick={() => togglePanel('preview')}
          intent={hasPreviewPanelActive ? Intent.PRIMARY : Intent.NONE}
          large
          minimal
        />
      </Tooltip>
    </div>
  );
};
