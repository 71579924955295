/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import {
  clamp,
  get,
  isEmpty,
  merge,
  pick,
  propEq,
  find,
  compose,
  uniqBy,
  map,
  findIndex,
  filter,
  noop,
  constant,
  toSafeInteger,
} from 'lodash/fp';
import { RouteComponentProps } from 'react-router-dom';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { Trans } from '@lingui/macro';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/react-hooks';
import {
  DomainData,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  ReferenceLog,
  ScreenerInclusionExclusionCriteriaFormKeywords,
  ScreeningForm,
  ScreeningResult,
  ScreeningTask,
  ScreeningTaskType,
  StageType,
} from '../../common/types';
import {
  excludeStudy,
  includeStudy,
  unreviewStudy,
  taskResultGraphqlCacheUpdate,
  getTaskResultFromReference,
  getTaskFromReference,
  TTaskCounts,
  combineMutationUpdaterFns,
  screeningDecisionReferencesSearchCacheUpdater,
  getDistributedReferencesSearchQueryArgs,
  getTaskCountForDecisionFilter,
  TDistributedReferencesSearchArgs,
  getScreeningTaskCounts,
  resetCachedTaskResultCommentId,
  getDistributedReferenceCommentUpdateOptimisticResponse,
  addNewDistributedReferenceCommentToCache,
  updateCachedTaskResultWithNewDistributedReferenceComment,
  TTaskResult,
  TSearchWithOperatorObject,
  getSearchTokens,
  shouldRefetchUpdatedTasks,
} from '../../lib/task_helpers';
import { useKeycloak } from '../../keycloak';
import {
  DecisionFilter,
  TActiveKeywordFilters,
  TFilterTarget,
  TActiveAttributeFilters,
  EMPTY_ACTIVE_DECISION_CODE_FILTERS,
  EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS,
  YearFilters,
  EMPTY_ACTIVE_YEAR_FILTER,
  TActiveScreeningTagFilters,
  EMPTY_ACTIVE_SCREENING_TAG_FILTERS,
  ORDER_BY_LAST_CHANGED,
} from '../../apollo/screening_state';
import { useCurrCallback, usePrevious, useSetState } from '../../lib/utils';
import {
  combineActiveKeywordFiltersSearchPhrasesAndKeywordsDataFromForm,
  getKeywordsFromDomains,
  mergeUserKeywords,
} from '../../lib/criteria_utils';
import { decisionFilterToInclusionStatus } from '../../lib/references';
import {
  EMPTY_ACTIVE_KEYWORD_FILTERS,
  EMPTY_SEARCH_PHRASE_TOKENS,
  EMPTY_SELECTED_REFERENCES,
} from '../../apollo/screening_state';
import ErrorScreen from '../common/error_screen';
import useActionLogger from '../hooks/use_action_logger';
import { IconNames } from '@blueprintjs/icons';
import FTConflict from './full_text/ft_conflict';
import TiAbConflictsList from './title_and_abstract/tiab_conflicts_list';
import FTConflictsList from './full_text/ft_conflicts_list';
import {
  REFERENCES_BATCH_SIZE,
  TExclusionReason,
  TReferenceData,
  TReferencesSearchQueryData,
  TDistributedReferencesSearchVariables,
  TUpdateTaskResultArgs,
  TReferencesCountsQueryData,
  TReferencesCountsQueryVariables,
  getActiveAttributeFilters,
} from '.';
import useTaskResultDataUpdaters from '../hooks/use_task_result_data_updaters';
import {
  DeleteReferenceCommentMutationDataType,
  DeleteReferenceCommentMutationVariablesType,
} from '../../graphql/delete_reference_comment';
import useReferenceDetailsLogger, {
  createSimpleReferenceDetailsLogs,
} from '../hooks/use_reference_details_logger';
import {
  removeCachedReferenceComment,
  updateCachedReferenceCommentText,
} from '../references/helpers';
import AppToaster from '../../lib/toaster';
import {
  UpsertReferenceTaskResultWithCommentMutationDataType,
  UpsertReferenceTaskResultWithCommentMutationVariablesType,
} from '../../graphql/upsert_reference_task_result_with_comment';
import { TDomainReasonsData } from '../references/admin';
import { getReasonCodesData, hasFiltersApplied } from './helpers';
import useDidUpdateEffect from '../hooks/use_did_update_effect';
import { prepareReferenceConflictResolutionLogs } from '../project/conflicts_resolution/conflicts_management_dialog';

const FormUserKeywordsFragment = loader('../../graphql/form_user_keywords_fragment.gql');
const ReferencesSearchQuery = loader('../../graphql/distributed_references_search_query.gql');
const ReferencesCountsQuery = loader('../../graphql/distributed_references_counts_query.gql');
const UpdateTaskResultMutation = loader('../../graphql/update_task_result.gql');
const MarkTasksCompletedAndNotifyAdminsMutation = loader(
  '../../graphql/mark_tasks_completed_and_notify_admins.gql'
);
const DeleteReferenceCommentMutation = loader('../../graphql/delete_reference_comment.gql');
const UpsertReferenceTaskResultWithCommentMutation = loader(
  '../../graphql/upsert_reference_task_result_with_comment.gql'
);

const SetHighlightsVisibleMutation = gql`
  mutation SetHighlightsVisible($visible: Boolean!) {
    setHighlightsVisible(visible: $visible) @client
  }
`;

const ResetScreeningStateMutation = loader('../../graphql/local/reset_screening_state.gql');

const SetActiveAndSelectedReferencesMutation = loader(
  '../../graphql/local/set_active_and_selected_references.gql'
);

const EMPTY_LIST = [];

const stageAndProjectDataQuery = gql`
  query GetStageAndProjectData($projectId: uuid!, $stageId: uuid!, $userId: String!) {
    project_by_pk(id: $projectId) {
      id
      stages {
        id
        name
        order_number
      }
      team_members(where: { user_id: { _eq: $userId } }) {
        id
      }
      references_attributes_aggregate {
        aggregate {
          min {
            year
          }
          max {
            year
          }
        }
      }
      references_attributes(distinct_on: document_type) {
        document_type
      }
    }
    stage_by_pk(id: $stageId) {
      id
      type
      forms(order_by: { created_at: desc }, limit: 1) {
        id
        form
        form_team_member_keywords {
          ...FormUserKeywordsFragment
        }
      }
    }
  }
  ${FormUserKeywordsFragment}
`;

const unsentTasksCountSubscription = gql`
  subscription GetUnsentTasksCount($stageId: uuid!, $formId: uuid!) {
    task_aggregate(
      where: {
        stage_id: { _eq: $stageId }
        completed: { _eq: false }
        task_results: {
          _or: [
            { result: { _contains: { inclusionStatus: "included" } } }
            { result: { _contains: { inclusionStatus: "excluded" } } }
          ]
          form_id: { _eq: $formId }
        }
        task_type: { _eq: conflict_resolution }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        task_id: id
        task_results(where: { form_id: { _eq: $formId } }) {
          form_id
          inclusionStatus: result(path: "inclusionStatus")
        }
      }
    }
  }
`;

const screeningStateQuery = gql`
  query {
    ScreeningState @client {
      activeKeywordFilters
      activeDecisionCodeFilters
      activeDocumentTypeFilters
      activeScreeningTagFilters
      activeYearFilters
      decisionFilter
      highlightsVisible
      searchPhraseTokens
      filtersTarget
      activeReference
      selectedReferences
      orderedBy
    }
  }
`;

interface ScreeningConflictsRouterProps {
  projectId: string;
  stageId: string;
}

interface IScreeningConflictsProps extends RouteComponentProps<ScreeningConflictsRouterProps> {
  // regular props
}

type TScreeningConflictsState = {
  loadingMoreReferences: boolean;
  lastReferencesFetchVariables: TDistributedReferencesSearchVariables | null;
};

const INITIAL_STATE: TScreeningConflictsState = {
  loadingMoreReferences: false,
  lastReferencesFetchVariables: null,
};

const ScreeningConflicts: React.FC<IScreeningConflictsProps> = memo((props) => {
  const { match, history, location } = props;
  const queryParams = new URLSearchParams(location.search);
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState(INITIAL_STATE);
  const { loadingMoreReferences, lastReferencesFetchVariables } = state;
  const { projectId, stageId } = match.params;
  const displayMode: 'focus-mode' | 'list' = queryParams.has('focus') ? 'focus-mode' : 'list';

  const { user } = useKeycloak();
  const apolloClient = useApolloClient();

  const addReferencesDetailsLog = useReferenceDetailsLogger();
  const { data: screeningStateData } = useQuery(screeningStateQuery);
  const {
    data: stageAndProjectData,
    loading: loadingStageData,
    error: stageAndProjectDataError,
  } = useQuery(stageAndProjectDataQuery, {
    variables: {
      stageId,
      projectId,
      userId: user.id,
    },
  });

  const [
    fetchReferences,
    {
      data: referencesData,
      loading: loadingRefs,
      fetchMore,
      error,
      called: everFetched,
      refetch: refetchReferences,
    },
  ] = useLazyQuery<TReferencesSearchQueryData, TDistributedReferencesSearchVariables>(
    ReferencesSearchQuery,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    fetchReferencesCounts,
    {
      data: referencesCountsData,
      loading: loadingCounts,
      refetch: refetchReferencesCounts,
      called: everFetchedCounts,
    },
  ] = useLazyQuery<TReferencesCountsQueryData, TReferencesCountsQueryVariables>(
    ReferencesCountsQuery,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const searchingReferences = loadingRefs && !loadingMoreReferences;
  const [resetScreeningState] = useMutation(ResetScreeningStateMutation);
  const [updateTaskResult, { loading: updatingTaskResult }] = useMutation(UpdateTaskResultMutation);
  const [setHighlightsVisible] = useMutation(SetHighlightsVisibleMutation);
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const [markTasksCompletedAndNotifyAdmins] = useMutation(
    MarkTasksCompletedAndNotifyAdminsMutation
  );
  const [deleteReferenceComment] = useMutation<
    DeleteReferenceCommentMutationDataType,
    DeleteReferenceCommentMutationVariablesType
  >(DeleteReferenceCommentMutation);
  const [upsertReferenceComment] = useMutation<
    UpsertReferenceTaskResultWithCommentMutationDataType,
    UpsertReferenceTaskResultWithCommentMutationVariablesType
  >(UpsertReferenceTaskResultWithCommentMutation);

  const stage = get('stage_by_pk', stageAndProjectData);
  const stages = get('project_by_pk.stages', stageAndProjectData) ?? EMPTY_LIST;
  const myTeamMemberId = get('project_by_pk.team_members[0].id', stageAndProjectData) ?? '';
  const stageType: StageType = get('type', stage) ?? StageType.TitlesAbstractScreening;
  const form: ScreeningForm | undefined = stage?.forms[0];
  const formId = form?.id ?? '';

  const { data: unsentTasksData, loading: loadingUnsentTasksData } = useSubscription(
    unsentTasksCountSubscription,
    {
      variables: {
        stageId,
        formId,
      },
      skip: formId == '',
    }
  );

  const unsentTasksCount = get('task_aggregate.aggregate.count', unsentTasksData) ?? 0;
  const unsentTaskStatuses = get('task_aggregate.nodes', unsentTasksData);
  const unsentTaskStatusesPrev = usePrevious(unsentTaskStatuses);
  const referencesAttributesYearData = get(
    'project_by_pk.references_attributes_aggregate.aggregate',
    stageAndProjectData
  );
  const yearsFilterData = {
    min: toSafeInteger(get('min.year', referencesAttributesYearData)),
    max: toSafeInteger(get('max.year', referencesAttributesYearData)),
  };
  const documentTypesData = map(
    (elem: any) => elem.document_type,
    get('project_by_pk.references_attributes', stageAndProjectData)
  );
  const screeningTags = useMemo(() => get('form.tags', form) ?? EMPTY_LIST, [form]);
  const formData: InclusionExclusionCriteriaFormData = form?.form ?? { domains: [] };
  const screenerKeywords: ScreenerInclusionExclusionCriteriaFormKeywords =
    get('form_team_member_keywords[0].keywords', form) ?? {};
  const mergedForm = useMemo(
    () => mergeUserKeywords(formData, screenerKeywords),
    [formData, screenerKeywords]
  );
  const formDomains: DomainData[] = [
    StageType.PreliminaryScreening,
    StageType.TitlesAbstractScreening,
  ].includes(stageType)
    ? mergedForm.domains
    : [];
  const reasonCodesData: TDomainReasonsData = useMemo(() => getReasonCodesData([stage]), [stage]);
  const activeKeywordFilters: TActiveKeywordFilters =
    get('ScreeningState.activeKeywordFilters', screeningStateData) ?? EMPTY_ACTIVE_KEYWORD_FILTERS;
  const searchPhraseTokens: string[] =
    get('ScreeningState.searchPhraseTokens', screeningStateData) ?? EMPTY_SEARCH_PHRASE_TOKENS;
  const filtersTarget: TFilterTarget = get('ScreeningState.filtersTarget', screeningStateData);

  const keywordsDataFromForm = useMemo(() => getKeywordsFromDomains(formDomains), [formDomains]);
  const keywordsData = useMemo(
    () =>
      combineActiveKeywordFiltersSearchPhrasesAndKeywordsDataFromForm(
        activeKeywordFilters,
        searchPhraseTokens,
        keywordsDataFromForm
      ),
    [activeKeywordFilters, keywordsDataFromForm, searchPhraseTokens]
  );
  const activeScreeningTagFilters: TActiveScreeningTagFilters =
    get('ScreeningState.activeScreeningTagFilters', screeningStateData) ??
    EMPTY_ACTIVE_SCREENING_TAG_FILTERS;
  const highlightsVisible = get('ScreeningState.highlightsVisible', screeningStateData) ?? false;
  const decisionFilter: DecisionFilter =
    get('ScreeningState.decisionFilter', screeningStateData) ?? 'to_review';
  const activeDecisionCodeFilters: TActiveAttributeFilters =
    get('ScreeningState.activeDecisionCodeFilters', screeningStateData) ??
    EMPTY_ACTIVE_DECISION_CODE_FILTERS;
  const activeDocumentTypeFilters: TActiveAttributeFilters =
    get('ScreeningState.activeDocumentTypeFilters', screeningStateData) ??
    EMPTY_ACTIVE_DOCUMENT_TYPE_FILTERS;
  const activeYearFilters: YearFilters =
    get('ScreeningState.activeYearFilters', screeningStateData) ?? EMPTY_ACTIVE_YEAR_FILTER;
  const orderedBy: object = isEmpty(get('ScreeningState.orderedBy', screeningStateData))
    ? ORDER_BY_LAST_CHANGED
    : get('ScreeningState.orderedBy', screeningStateData);

  const references: TReferenceData[] | undefined = get('references', referencesData) ?? [];

  // TODO: (mentioned in D6387) refactor everything that depends on selectedReferences to use IDs instead of indices.
  // TODO: this refactor requires the update of common/table component as well.
  const selectedReferencesIds: string[] =
    get('ScreeningState.selectedReferences', screeningStateData) ?? EMPTY_SELECTED_REFERENCES;
  const selectedReferences: number[] = useMemo(
    () =>
      filter(
        (idx: number) => idx >= 0,
        map((id) => findIndex(propEq('id', id), references), selectedReferencesIds)
      ),
    [references, selectedReferencesIds]
  );

  const activeReferenceId = get('ScreeningState.activeReference', screeningStateData);
  const activeReferenceIdx = useMemo(() => {
    // fallback to the 1st reference if none is explicitly selected
    if (activeReferenceId == null) return 0;

    const idx = findIndex(propEq('id', activeReferenceId), references);

    return idx >= 0 ? idx : undefined;
  }, [activeReferenceId, references]);

  const activeReference: TReferenceData | undefined =
    activeReferenceIdx == null ? undefined : references[activeReferenceIdx];

  const taskCounts: TTaskCounts = useMemo(() => {
    return getScreeningTaskCounts(referencesCountsData);
  }, [referencesCountsData]);

  const exclusionReasons: TExclusionReason[] = useMemo(() => {
    switch (stage?.type) {
      case StageType.PreliminaryScreening:
      case StageType.TitlesAbstractScreening:
        return formDomains.map(({ exclusionButtonLabel, exclusionCode, id }) => ({
          id,
          label: exclusionButtonLabel,
          code: exclusionCode,
        }));
      case StageType.FullTextScreening:
        return stage.forms[0].form.exclusion.map(({ id, code, name }) => ({
          id,
          label: name,
          code,
        }));
      default:
        return [];
    }
  }, [stage, formDomains]);

  const searchTokensWithOperator: TSearchWithOperatorObject[] = useMemo(() => {
    return getSearchTokens(activeKeywordFilters);
  }, [activeKeywordFilters]);

  const activeReferenceResult = useMemo(() => {
    if (activeReference == null) return;
    return getTaskResultFromReference(activeReference, formId, null, stageId);
  }, [references, activeReference, formId, user.id, stageId]);

  const referencesCount = useMemo(() => {
    return getTaskCountForDecisionFilter(decisionFilter, taskCounts);
  }, [decisionFilter, taskCounts]);

  const setActiveReference = useCallback(
    (activeReference) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: references[activeReference].id },
      });
    },
    [setActiveAndSelectedReferences, references]
  );

  const filterReferences = useCallback(
    (searchArgs: TDistributedReferencesSearchArgs) => {
      const fetchVariables: TDistributedReferencesSearchVariables = {
        stageId,
        offset: 0,
        limit: REFERENCES_BATCH_SIZE,
        searchArgs,
      };

      setState((current) => ({
        ...current,
        loadingMoreReferences: false,
        lastReferencesFetchVariables: fetchVariables,
      }));

      fetchReferences({ variables: fetchVariables });
    },
    [stageId, fetchReferences, setState]
  );

  const doUpdateTaskResult = useCallback(
    ({
      newResults,
      oldResults,
      logsData,
      refIdsToUpdate,
      undoReference,
      prevDecision,
    }: TUpdateTaskResultArgs) => {
      return updateTaskResult({
        variables: { newResults, logsData: logsData ?? [] },
        optimisticResponse: {
          __typename: 'Mutation',
          insert_task_result: {
            __typename: 'task_result_mutation_response',
            returning: newResults.map(merge({ __typename: 'task_result' })),
          },
          insert_reference_details_log: {
            __typename: 'reference_details_log_mutation_response',
            returning: logsData?.map(merge({ __typename: 'reference_details_log' })) ?? [],
          },
        },
        update:
          refIdsToUpdate && lastReferencesFetchVariables && oldResults
            ? combineMutationUpdaterFns(
                screeningDecisionReferencesSearchCacheUpdater(
                  lastReferencesFetchVariables,
                  decisionFilter,
                  refIdsToUpdate,
                  get('[0].result.inclusionStatus', newResults),
                  formId,
                  oldResults,
                  undoReference,
                  prevDecision
                ),
                taskResultGraphqlCacheUpdate
              )
            : taskResultGraphqlCacheUpdate,
      });
      // TODO: error handling
    },
    [updateTaskResult, lastReferencesFetchVariables, decisionFilter, formId]
  );

  const openFocusMode = useCallback(() => {
    const nextQueryParams = new URLSearchParams(queryParams.toString());
    nextQueryParams.set('focus', '1');

    history.push({
      pathname: ['/projects', projectId, 'stages', stageId, 'conflicts'].join('/'),
      search: nextQueryParams.toString(),
    });
  }, [history, projectId, stageId, queryParams]);

  const navigateToScreeningConflictsList = useCallback(() => {
    history.push({
      pathname: ['/projects', projectId, 'stages', stageId, 'conflicts'].join('/'),
    });
  }, [history, projectId, stageId]);

  const loadNextReference = useCallback(() => {
    if (activeReferenceIdx == null) return Promise.resolve();

    const refs = isEmpty(selectedReferences) ? [activeReferenceIdx] : selectedReferences.slice(0);
    const includesActiveReference = refs.includes(activeReferenceIdx);

    // calculate new active reference index
    // if we change the last element, select the new last element
    // otherwise select the next element
    const newActiveReferenceIdx = includesActiveReference
      ? clamp(
          0,
          references.length - 1,
          activeReferenceIdx === references.length - 1
            ? activeReferenceIdx - 1
            : activeReferenceIdx + 1
        )
      : activeReferenceIdx;

    const newActiveReferenceId = references[newActiveReferenceIdx].id;

    return setActiveAndSelectedReferences({
      variables: {
        selectedReferences: EMPTY_SELECTED_REFERENCES,
        activeReference: newActiveReferenceId,
      },
    }).then(() => {
      if (displayMode === 'focus-mode') {
        openFocusMode();
      }
    });
  }, [
    displayMode,
    selectedReferences,
    activeReferenceIdx,
    references,
    setActiveAndSelectedReferences,
  ]);

  const applyDecision = useCallback(
    (decisionFn) => {
      if (activeReferenceIdx == null && isEmpty(selectedReferences)) return;
      const refs = isEmpty(selectedReferences)
        ? [activeReferenceIdx!]
        : selectedReferences.slice(0);
      const referenceIds = refs.map((ref) => references[ref].id);

      const { newResults, logsData, oldResults } = refs.reduce(
        (acc, ref) => {
          const referenceData = references[ref];
          const task = getTaskFromReference(referenceData, null, stageId);
          const currentResult = getTaskResultFromReference(referenceData, formId, null);
          const newResult = {
            task_id: task!.id,
            form_id: formId,
            updated_at: new Date().toISOString(),
            comment_id: currentResult?.comment_id ?? null,
            result: decisionFn(
              currentResult == null
                ? {}
                : pick(['inclusionStatus', 'comment', 'tags'], currentResult.result),
              formDomains
            ),
          };
          acc.newResults.push(newResult);

          const logsData: Pick<ReferenceLog, 'reference_id' | 'data'>[] =
            prepareReferenceConflictResolutionLogs({
              referenceId: referenceData.id,
              stageId,
              inclusionStatus: newResult.result.inclusionStatus,
              criteria: newResult.result.criteria,
              userId: user.id,
              automaticResolution: false,
            });

          acc.logsData.push(...logsData);
          if (currentResult) {
            acc.oldResults.push(currentResult);
          }

          return acc;
        },
        {
          newResults: [],
          logsData: [],
          oldResults: [],
        } as {
          newResults: TTaskResult[];
          logsData: Pick<ReferenceLog, 'reference_id' | 'data'>[];
          oldResults: TTaskResult[];
        }
      );

      doUpdateTaskResult({ newResults, oldResults, logsData, refIdsToUpdate: referenceIds })
        .then(() => loadNextReference())
        .then(() => {
          insertActionLog('screening decision applied', {
            activeReference: activeReferenceIdx,
            selectedReferences,
            newResults,
            referenceIds,
          });
        });
    },
    [
      formId,
      references,
      doUpdateTaskResult,
      formDomains,
      user.id,
      user.name,
      insertActionLog,
      setState,
      activeReferenceIdx,
      selectedReferences,
      stageId,
      loadNextReference,
      exclusionReasons,
      stageType,
    ]
  );

  const handleInclude = useCallback(() => applyDecision(includeStudy), [applyDecision]);

  const handleExclude = useCallback(
    (reasonCode: string) => applyDecision(excludeStudy(reasonCode)),
    [applyDecision]
  );

  const handleUnreview = useCallback(() => applyDecision(unreviewStudy), [applyDecision]);

  const setSelectedReferences = useCallback(
    (updateFn: (selected: number[]) => number[]) => {
      setActiveAndSelectedReferences({
        variables: {
          selectedReferences: map((idx) => references[idx].id, updateFn(selectedReferences)),
        },
      });
    },
    [setActiveAndSelectedReferences, selectedReferences, references]
  );

  const handleToggleHighlights = useCallback(() => {
    setHighlightsVisible({
      variables: {
        visible: !highlightsVisible,
      },
    });
  }, [setHighlightsVisible, highlightsVisible]);

  const updateTaskResultData = useCallback(
    (resultData: Partial<ScreeningResult>, logs?: Partial<ReferenceLog>[]) => {
      if (activeReference == null) return Promise.resolve();
      const task = getTaskFromReference(activeReference, null, stageId);
      const prevResult = find(propEq('form_id', formId), task!.task_results);

      const prevResultData: ScreeningResult = compose(
        pick(['inclusionStatus', 'criteria', 'comment', 'tags']),
        get('result')
      )(prevResult);

      const updatedResult = { ...prevResultData, ...resultData };

      const resultPayload = {
        task_id: task!.id,
        form_id: formId,
        result: updatedResult,
        comment_id: prevResult?.comment_id ?? null,
        updated_at: new Date().toISOString(),
      };

      const logsData = logs ?? [];

      // since this method is used by FT screening to update inclusion status, need to provided
      // additional params to result updater to allow it to update decision filter counters
      const updateArgs: TUpdateTaskResultArgs =
        'inclusionStatus' in resultData
          ? {
              newResults: [resultPayload],
              oldResults: prevResult ? [prevResult] : [],
              refIdsToUpdate: [activeReference.id],
              logsData,
              undoReference: activeReference,
              prevDecision: prevResultData.inclusionStatus ?? null,
            }
          : { newResults: [resultPayload], logsData };

      return doUpdateTaskResult(updateArgs);
    },
    [doUpdateTaskResult, activeReference, references, formId, user.id, stageId]
  );

  const { updateTags } = useTaskResultDataUpdaters(updateTaskResultData, activeReferenceResult);

  const handleLoadMoreReferences = useCallback(() => {
    setState({ loadingMoreReferences: true });
    fetchMore({
      variables: {
        offset: references.length,
        limit: REFERENCES_BATCH_SIZE,
      },
      updateQuery: (prev: TReferencesSearchQueryData, { fetchMoreResult, variables }) => {
        if (fetchMoreResult == null) return prev;

        return {
          ...fetchMoreResult,
          references: uniqBy(get('id'), [...prev.references, ...fetchMoreResult.references]),
        };
      },
    }).finally(() => {
      setState({ loadingMoreReferences: false });
    });
  }, [setState, fetchMore, references]);

  const filtersApplied: boolean = hasFiltersApplied({
    searchPhraseTokens,
    searchTokensWithOperator,
    activeDocumentTypeFilters,
    activeYearFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
  });

  const noTasks = isEmpty(references) && !filtersApplied && decisionFilter === 'all';

  useEffect(() => {
    const referenceSearchArguments = getDistributedReferencesSearchQueryArgs({
      projectId,
      stageId,
      activeDocumentTypes: getActiveAttributeFilters(activeDocumentTypeFilters),
      activeDecisionCodes: getActiveAttributeFilters(activeDecisionCodeFilters),
      activeScreeningTags: getActiveAttributeFilters(activeScreeningTagFilters),
      activeYears: activeYearFilters,
      searchTokens: searchPhraseTokens,
      searchTokensWithOperator,
      filtersTarget,
      taskType: ScreeningTaskType.ConflictResolution,
      inclusionStatus: decisionFilterToInclusionStatus(decisionFilter),
      taskCompleted: false,
      orderedBy: orderedBy,
    });
    filterReferences(referenceSearchArguments);
  }, [
    projectId,
    stageId,
    activeDocumentTypeFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
    activeYearFilters,
    searchTokensWithOperator,
    decisionFilter,
    orderedBy,
    searchPhraseTokens,
    filtersTarget,
  ]);

  useEffect(() => {
    const referenceSearchArgumentsForCounts = getDistributedReferencesSearchQueryArgs({
      projectId,
      stageId,
      taskType: ScreeningTaskType.ConflictResolution,
      taskCompleted: false,
      searchTokens: searchPhraseTokens,
      filtersTarget,
      activeDocumentTypes: getActiveAttributeFilters(activeDocumentTypeFilters),
      activeDecisionCodes: getActiveAttributeFilters(activeDecisionCodeFilters),
      activeScreeningTags: getActiveAttributeFilters(activeScreeningTagFilters),
      activeYears: activeYearFilters,
      searchTokensWithOperator,
    });

    fetchReferencesCounts({
      variables: {
        allSearchArgs: referenceSearchArgumentsForCounts,
        includedSearchArgs: {
          ...referenceSearchArgumentsForCounts,
          inclusion_status: InclusionStatus.Included,
        },
        excludedSearchArgs: {
          ...referenceSearchArgumentsForCounts,
          inclusion_status: InclusionStatus.Excluded,
        },
        postponedSearchArgs: {
          ...referenceSearchArgumentsForCounts,
          inclusion_status: InclusionStatus.Postponed,
        },
      },
    });
  }, [
    projectId,
    stageId,
    searchPhraseTokens,
    filtersTarget,
    activeDocumentTypeFilters,
    activeDecisionCodeFilters,
    activeScreeningTagFilters,
    activeYearFilters,
    searchTokensWithOperator,
  ]);

  const handleSendTaskDecisions = useCallback(
    (taskIds: string[]) =>
      markTasksCompletedAndNotifyAdmins({
        variables: {
          ids: taskIds,
          notification: {
            userId: user.id,
            projectId,
            stageId,
            decisionsCount: taskIds.length,
          },
        },
      }).then(() => {
        refetchReferences();
        refetchReferencesCounts();
      }),
    [
      refetchReferences,
      markTasksCompletedAndNotifyAdmins,
      refetchReferencesCounts,
      user,
      projectId,
      stageId,
    ]
  );

  const handleCommentUpdate = useCallback(
    (commentData: { id?: string; comment: string }) => {
      if (activeReference == null) return;

      const task: ScreeningTask | undefined = getTaskFromReference(activeReference, null, stageId);

      if (task) {
        const logsData = createSimpleReferenceDetailsLogs(
          'comments_updated',
          user,
          [activeReference.id],
          { comment: commentData }
        );

        const promise = upsertReferenceComment({
          variables: {
            formId: formId,
            taskId: task.id,
            comment: {
              ...commentData,
              reference_id: activeReference.id,
              team_member_id: myTeamMemberId,
              stage_id: stageId,
              form_id: formId,
              task_id: task.id,
            },
          },
          // provide optimistic response in case of update only (comment id was provided)
          optimisticResponse: commentData.id
            ? getDistributedReferenceCommentUpdateOptimisticResponse({
                taskId: task.id,
                formId: formId,
                referenceId: activeReference.id,
                commentId: commentData.id,
                comment: commentData.comment,
                stageId,
                teamMemberId: myTeamMemberId,
                userId: user.id,
                userName: user.name,
              })
            : undefined,
          update: commentData.id
            ? // if existing comment was updated (commentId != null), simply update the comment
              // field of reference_comment entry in cache
              (proxy, _result) =>
                updateCachedReferenceCommentText(proxy, commentData.id!, commentData.comment)
            : // use more complicated helper to update several cache entities otherwise
              combineMutationUpdaterFns(
                addNewDistributedReferenceCommentToCache,
                updateCachedTaskResultWithNewDistributedReferenceComment
              ),
        })
          .then(() => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: commentData.id ? (
                <Trans>Comment updated successfully</Trans>
              ) : (
                <Trans>Comment added successfully</Trans>
              ),
            });
          })
          .catch((err) => {
            AppToaster.show({
              intent: Intent.WARNING,
              message: <Trans>Error occurred: {err.toString()}</Trans>,
            });
          });

        addReferencesDetailsLog(promise, constant(logsData));
      }
    },
    [activeReference, myTeamMemberId, stageId, formId, user]
  );

  const handleCommentDelete = useCallback(
    (commentId: string) => {
      if (activeReference == null) return;

      const logsData = createSimpleReferenceDetailsLogs(
        'comments_removed',
        user,
        [activeReference.id],
        { removedCommentId: commentId }
      );

      const task: ScreeningTask | undefined = getTaskFromReference(
        activeReference,
        user.id,
        stageId
      );

      if (task) {
        const promise = deleteReferenceComment({
          variables: {
            id: commentId,
          },
          optimisticResponse: {
            delete_reference_comment_by_pk: {
              id: commentId,
              form_id: formId,
              reference_id: activeReference.id,
              task_id: task.id,
              __typename: 'reference_comment',
            },
          },
          update: combineMutationUpdaterFns<DeleteReferenceCommentMutationDataType>(
            resetCachedTaskResultCommentId,
            removeCachedReferenceComment
          ),
        })
          .then(() => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: <Trans>Comment removed successfully</Trans>,
            });
          })
          .catch((err) => {
            AppToaster.show({
              intent: Intent.WARNING,
              message: <Trans>Failed to remove comment: {err.toString()}</Trans>,
            });
          });

        addReferencesDetailsLog(promise, constant(logsData));
      }

      deleteReferenceComment({ variables: { id: commentId } });
    },
    [activeReference, stageId, user]
  );

  const handleTagsUpdate = useCallback(
    (tagIds: string[]) => {
      if (activeReference == null) return;

      updateTags({
        tagIds,
        stageId,
        user,
        referenceId: activeReference.id,
      });
    },
    [updateTags, activeReference, stageId, user]
  );

  // remove refs IDs from selectedReferences if they don't exist in a filtered set of references
  useEffect(() => {
    const filteredSelectedRefs = filter(
      (id) => findIndex(propEq('id', id), references) > -1,
      selectedReferencesIds
    );
    if (selectedReferencesIds.length !== filteredSelectedRefs.length) {
      setActiveAndSelectedReferences({
        variables: {
          selectedReferences: filteredSelectedRefs,
        },
      });
    }
  }, [setActiveAndSelectedReferences, references, selectedReferencesIds]);

  // on unmount
  useEffect(
    () => () => {
      resetScreeningState();
    },
    [resetScreeningState]
  );

  // detect changes done by other screeners using results inclusion status subscription data
  useDidUpdateEffect(() => {
    if (
      unsentTaskStatuses &&
      unsentTaskStatusesPrev &&
      unsentTaskStatuses !== unsentTaskStatusesPrev
    ) {
      const decisionsSent = isEmpty(unsentTaskStatuses) && !isEmpty(unsentTaskStatusesPrev);

      if (
        decisionsSent ||
        shouldRefetchUpdatedTasks(unsentTaskStatuses, unsentTaskStatusesPrev, apolloClient)
      ) {
        refetchReferences?.();
        refetchReferencesCounts?.();
      }
    }
  }, [refetchReferences, refetchReferencesCounts, unsentTaskStatuses, unsentTaskStatusesPrev]);

  // there must be selected reference when rendered in focus mode
  useEffect(() => {
    if (displayMode === 'focus-mode' && activeReference == null && !isEmpty(references)) {
      setActiveReference(0);
    }
  }, [references, activeReference, displayMode, setActiveReference]);

  const handleNavigateToAnotherReference = useCurrCallback(
    (directionOffset: -1 | 1, _evt) => {
      if (activeReferenceIdx == null) return Promise.resolve();

      const newActiveReferenceIdx: number = clamp(
        0,
        references.length - 1,
        activeReferenceIdx + directionOffset
      );
      return setActiveAndSelectedReferences({
        variables: {
          activeReference: references[newActiveReferenceIdx].id,
        },
      });
    },
    [activeReferenceIdx, references, setActiveAndSelectedReferences]
  );

  const shiftActiveReference = useCallback(
    (direction: -1 | 1) => {
      if (activeReferenceIdx == null) return;

      setActiveReference(clamp(0, references.length - 1, activeReferenceIdx + direction));
    },
    [references.length, activeReferenceIdx, setActiveReference]
  );

  if (error || stageAndProjectDataError) {
    return <ErrorScreen error={(error || stageAndProjectDataError)!} />;
  }

  if (loadingStageData || loadingUnsentTasksData || !everFetched || !everFetchedCounts) {
    return <Spinner className="h-full" />;
  }

  if (form == null) {
    return (
      <NonIdealState
        icon={IconNames.HEART_BROKEN}
        title={<Trans>Screening form is not available</Trans>}
      />
    );
  }

  if (noTasks && !searchingReferences && isEmpty(searchPhraseTokens)) {
    return (
      <NonIdealState icon={IconNames.HEART_BROKEN} title={<Trans>No conflicts to resolve</Trans>} />
    );
  }

  return [StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(stageType) ? (
    <TiAbConflictsList
      references={references}
      openFocusMode={noop}
      activeReference={activeReferenceIdx}
      selectedReferences={selectedReferences}
      setSelectedReferences={setSelectedReferences}
      handleInclude={handleInclude}
      handleExclude={handleExclude}
      taskCounts={taskCounts}
      exclusionReasons={exclusionReasons}
      searchingReferences={searchingReferences}
      keywordsData={keywordsData}
      highlightsVisible={highlightsVisible}
      decisionFilter={decisionFilter}
      form={form}
      activeReferenceResult={activeReferenceResult}
      onLoadMoreReferences={
        referencesCount > references.length ? handleLoadMoreReferences : undefined
      }
      loadingReferences={loadingMoreReferences}
      onHighlightsToggle={handleToggleHighlights}
      stageId={stageId}
      unsentTasksCount={unsentTasksCount}
      onSendDecisions={handleSendTaskDecisions}
      stageType={stageType}
      loadingCounts={loadingCounts}
      onUpdateResultTags={handleTagsUpdate}
      totalReferencesCount={referencesCount}
      onUpdateComment={handleCommentUpdate}
      onDeleteComment={handleCommentDelete}
      projectStages={stages}
      filtersApplied={filtersApplied}
      projectId={projectId}
      shiftActiveReference={shiftActiveReference}
    />
  ) : displayMode === 'list' ? (
    <FTConflictsList
      taskCounts={taskCounts}
      references={references}
      activeReference={activeReferenceIdx}
      openFocusMode={openFocusMode}
      keywordsData={keywordsData}
      formId={formId}
      onLoadMore={referencesCount > references.length ? handleLoadMoreReferences : undefined}
      decisionFilter={decisionFilter}
      highlightsVisible={highlightsVisible}
      onHighlightsToggle={handleToggleHighlights}
      searchingReferences={searchingReferences}
      loadingReferences={loadingMoreReferences}
      stageId={stageId}
      onSendDecisions={handleSendTaskDecisions}
      unsentTasksCount={unsentTasksCount}
      stageType={stageType}
      loadingCounts={loadingCounts}
      totalReferencesCount={referencesCount}
      exclusionReasons={exclusionReasons}
      domains={formDomains}
      reasonCodesData={reasonCodesData}
      documentTypes={documentTypesData}
      yearsFilterData={yearsFilterData}
      activeKeywordFilters={activeKeywordFilters}
      activeDecisionCodeFilters={activeDecisionCodeFilters}
      activeDocumentTypeFilters={activeDocumentTypeFilters}
      activeYearFilters={activeYearFilters}
      searchPhraseTokens={searchPhraseTokens}
      filtersApplied={filtersApplied}
      activeReferenceResult={activeReferenceResult}
      screeningTags={screeningTags}
      activeScreeningTagFilters={activeScreeningTagFilters}
      shiftActiveReference={shiftActiveReference}
    />
  ) : (
    <FTConflict
      projectId={projectId}
      form={form}
      stageId={stageId}
      reference={activeReference}
      onUpdateTaskResultData={updateTaskResultData}
      handleUnreview={handleUnreview}
      loadNextReference={loadNextReference}
      savingResult={updatingTaskResult}
      unsentTasksCount={unsentTasksCount}
      navigateToScreeningConflictsList={navigateToScreeningConflictsList}
      onUpdateComment={handleCommentUpdate}
      onDeleteComment={handleCommentDelete}
      projectStages={stages}
      onUpdateResultTags={handleTagsUpdate}
      navigateToPrevReference={
        activeReferenceIdx === 0 ? undefined : handleNavigateToAnotherReference(-1)
      }
      navigateToNextReference={
        activeReferenceIdx === references.length - 1
          ? undefined
          : handleNavigateToAnotherReference(1)
      }
      shiftActiveReference={shiftActiveReference}
    />
  );
});

export default ScreeningConflicts;
