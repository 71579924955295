/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { projectHeaderCss } from '../../common/styles';
import Header2 from '../header2';
import ProjectBreadcrumbs from '../project/breadcrumbs';
import ProjectSidebar from '../project/project_page_sidebar';

interface IProjectPageProps extends RouteComponentProps {}

const ProjectPage: React.FC<IProjectPageProps> = ({ children, ...props }) => {
  return (
    <div className="w-full h-full flex flex-row flex-no-wrap">
      <ProjectSidebar {...props} />
      <div className="flex flex-col flex-1 overflow-auto" style={{ paddingTop: 50 }}>
        <Header2 {...props} css={projectHeaderCss}>
          <ProjectBreadcrumbs {...props} />
        </Header2>
        {children}
      </div>
    </div>
  );
};

export default ProjectPage;
