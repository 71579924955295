import { useCallback } from 'react';
import { ReferenceLog, ScreeningResult, ScreeningTaskResult } from '../../common/types';
import { createSimpleReferenceDetailsLogs } from './use_reference_details_logger';

export default function useTaskResultDataUpdaters(
  onUpdateTaskResultData: (
    resultData: Partial<ScreeningResult>,
    logs?: Partial<ReferenceLog>[]
  ) => Promise<any>,
  taskResult?: Pick<ScreeningTaskResult, 'result'>
) {
  const persistUpdatedTaskResult = useCallback(
    (screeningResult: Partial<ScreeningResult>, logs?: Partial<ReferenceLog>[]) => {
      const newResult: ScreeningResult = taskResult
        ? { ...taskResult.result, ...screeningResult }
        : { ...screeningResult, criteria: screeningResult.criteria ?? [] };
      return onUpdateTaskResultData(newResult, logs);
    },
    [onUpdateTaskResultData, taskResult]
  );

  const updateTags = useCallback(
    (payload: {
      tagIds: string[];
      referenceId: string;
      user: {
        id: string;
        name: string;
      };
      stageId: string;
    }) => {
      const { tagIds, referenceId, user, stageId } = payload;
      const logsData: Partial<ReferenceLog>[] = createSimpleReferenceDetailsLogs(
        'tags_changed',
        user,
        [referenceId],
        { stageId, tags: tagIds }
      );
      persistUpdatedTaskResult({ tags: tagIds }, logsData);
    },
    [persistUpdatedTaskResult]
  );

  // FIXME: remove this method once T5382 is implemented
  const updateComment = useCallback(
    (payload: {
      newComment: string;
      referenceId: string;
      user: {
        id: string;
        name: string;
      };
      stageId: string;
    }) => {
      const { newComment, referenceId, user, stageId } = payload;
      const logsData: Partial<ReferenceLog>[] = createSimpleReferenceDetailsLogs(
        'comments_updated',
        user,
        [referenceId],
        { stageId, comment: newComment }
      );
      persistUpdatedTaskResult({ comment: newComment }, logsData);
    },
    [persistUpdatedTaskResult]
  );

  return {
    persistUpdatedTaskResult,
    updateComment,
    updateTags,
  };
}
