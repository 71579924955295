/** @jsx jsx **/
import { Classes, Colors, Icon, Intent, PopoverPosition, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { get, keyBy, map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { jsx } from '@emotion/core';
import ProgressBar from '../../common/progress_bar';
import { UserAvatar } from '../../header2';
import { getScreeningTypeInfo } from '../helpers';
import { gray1ColorCss, lightGray5bg } from '../../../common/styles';
import StatsCount from '../stats_count';
import { TScreenerData } from '../../../lib/distribution_helpers';

interface IReviewerProps {
  reviewer: TScreenerData;
}

interface IReviewersProps {
  referencesCount: number;
  reviewers: TScreenerData[];
  selectedScreeningTypeId: string;
}

const Reviewer: React.FC<IReviewerProps> = ({ reviewer }) => {
  const { deleted_at, user } = reviewer;
  const tasksByStatus = useMemo(
    () => keyBy('inclusion_status', get('tasks_by_status', reviewer)),
    [reviewer]
  );
  const { included, excluded } = tasksByStatus;
  const totalTaskCount = get('tasks_aggregate.aggregate.count', reviewer) ?? 0;

  return (
    <div className={`${Classes.CARD} ${Classes.ELEVATION_0} p-4`}>
      <div className="flex flex-row items-center">
        <div className={`flex-grow flex items-center w-full ${deleted_at ? 'text-gray-500' : ''}`}>
          <UserAvatar user={user} />
          <span className="ml-2">{user?.name ?? <Trans>User removed</Trans>}</span>
          {deleted_at && (
            <Tooltip
              content={<Trans>Removed from the team</Trans>}
              position={PopoverPosition.RIGHT}
            >
              <Icon className="ml-1" icon={IconNames.FOLLOWER} />
            </Tooltip>
          )}
          <div className="ml-auto" css={{ width: '200px' }}>
            <ProgressBar
              className="h-2 rounded-full overflow-hidden"
              studiesIn={included?.count ?? 0}
              studiesOut={excluded?.count ?? 0}
              total={totalTaskCount}
            />
          </div>
        </div>
        <div className="flex justify-end min-w-1/5">
          <span className="ml-6">{(excluded?.count ?? 0) + (included?.count ?? 0)}</span>
          <span className="text-gray-600 mr-2">/{totalTaskCount}</span>
        </div>
      </div>
    </div>
  );
};

const Reviewers: React.FC<IReviewersProps> = ({
  referencesCount,
  reviewers,
  selectedScreeningTypeId,
}) => {
  const typeInfo = getScreeningTypeInfo(selectedScreeningTypeId);

  return (
    <div className="mx-6 p-2 pt-4 rounded-lg" css={lightGray5bg}>
      {typeInfo && (
        <div className="text-center flex items-center mb-4">
          <div className="mr-2" css={gray1ColorCss}>
            {typeInfo.label}
          </div>
          <div>
            {selectedScreeningTypeId === 'single' ? (
              <Icon color={Colors.GRAY1} icon={IconNames.EYE_OPEN} />
            ) : selectedScreeningTypeId === 'double' ? (
              <React.Fragment>
                <Icon color={Colors.GRAY1} icon={IconNames.EYE_OPEN} />
                <Icon className="ml-1" color={Colors.GRAY1} icon={IconNames.EYE_OPEN} />
              </React.Fragment>
            ) : (
              <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />
            )}
          </div>
          <StatsCount className="ml-auto" count={referencesCount} />
        </div>
      )}
      <div>
        {reviewers.length > 0 ? (
          map((reviewer) => <Reviewer key={reviewer.user_id} reviewer={reviewer} />, reviewers)
        ) : (
          <Trans>No reviewers in project</Trans>
        )}
      </div>
    </div>
  );
};

export default Reviewers;
