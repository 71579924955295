/** @jsx jsx */
import React, { ReactNode, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { screeningListColHeaderHeight } from '../../../common/styles';
import CountTag from '../../common/count_tag';
import { Trans } from '@lingui/macro';
import { AnchorButton, Button, Divider, Icon, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import SendDecisionsButton from '../send_decisions_button';
import { ScreeningTaskType } from '../../../common/types';
import { isEmpty } from 'lodash/fp';
import { useCurrCallback } from '../../../lib/utils';
import { DecisionFilter } from '../../../apollo/screening_state';
import useSetDecisionFilter from '../../hooks/use_set_decision_filter';
import { TTaskCounts } from '../../../lib/task_helpers';
import { TReferenceData } from '../index';

interface IFTConflictsListHeaderProps {
  decisionFilter: DecisionFilter;
  taskCounts: TTaskCounts;
  loadingReferences: boolean;
  highlightsVisible: boolean;
  onHighlightsToggle: () => void;
  stageId: string;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  openFocusMode: () => void;
  references: TReferenceData[];
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  loadingCounts?: boolean;
}

const FTConflictsListHeader: React.FC<IFTConflictsListHeaderProps> = ({
  decisionFilter,
  taskCounts,
  loadingReferences,
  loadingCounts,
  highlightsVisible,
  onHighlightsToggle,
  stageId,
  onSendDecisions,
  openFocusMode,
  references,
  leftElement,
  rightElement,
}) => {
  const setDecisionFilter = useSetDecisionFilter();

  // ensure proper decision filter is set on mount
  useEffect(() => {
    setDecisionFilter('to_review');
  }, [setDecisionFilter]);

  const handleTagClick = useCurrCallback(
    (decisionFilter: DecisionFilter, _evt) => {
      setDecisionFilter(decisionFilter);
    },
    [loadingReferences, setDecisionFilter]
  );

  return (
    <div
      className="flex flex-row flex-none items-center border bg-white"
      css={screeningListColHeaderHeight}
    >
      {leftElement}
      <div className="flex-1 ml-2">
        <CountTag
          active={decisionFilter === 'to_review'}
          className="mr-2"
          count={taskCounts.toReview}
          onClick={loadingReferences ? undefined : handleTagClick('to_review')}
          title={<Trans>To resolve</Trans>}
          loading={loadingCounts}
          inclusionStatus="conflict"
        />
        <CountTag
          active={decisionFilter === 'in'}
          count={taskCounts.included}
          onClick={loadingReferences ? undefined : handleTagClick('in')}
          className="mr-2"
          title={<Trans>In</Trans>}
          inclusionStatus="included"
          loading={loadingCounts}
        />
        <CountTag
          active={decisionFilter === 'out'}
          className="mr-2"
          count={taskCounts.excluded}
          onClick={loadingReferences ? undefined : handleTagClick('out')}
          inclusionStatus="excluded"
          title={<Trans>Out</Trans>}
          loading={loadingCounts}
        />
      </div>
      <Tooltip
        className="flex h-full"
        content={
          highlightsVisible ? (
            <Trans>Click to hide highlighted keywords</Trans>
          ) : (
            <Trans>Click to highlight keywords</Trans>
          )
        }
      >
        <Button
          className="h-full ml-2"
          minimal
          intent={highlightsVisible ? Intent.PRIMARY : Intent.NONE}
          icon={highlightsVisible ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
          text={<Trans>Keywords</Trans>}
          onClick={onHighlightsToggle}
        />
      </Tooltip>
      <SendDecisionsButton
        stageId={stageId}
        onSend={onSendDecisions}
        taskType={ScreeningTaskType.ConflictResolution}
        className="mr-2"
      />
      <Divider className="h-full m-0" />
      <Tooltip className="flex h-full" content={<Trans>Focus mode</Trans>}>
        <AnchorButton
          className="h-full flex-none"
          icon={<Icon className="p-2" icon={IconNames.FULLSCREEN} iconSize={16} />}
          onClick={openFocusMode}
          large
          minimal
          disabled={isEmpty(references)}
        />
      </Tooltip>
      {rightElement}
    </div>
  );
};

export default FTConflictsListHeader;
