/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';
import { Classes } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { useI18n } from '../../lib/utils';
import { lightGray4bg, maxContentWidth } from '../../common/styles';

interface IListHeaderProps {
  title?: ReactNode;
  fixed?: boolean;
  rightElement?: ReactNode;
  className?: string;
}

const ListHeader: React.FC<IListHeaderProps> = ({
  title,
  fixed = true,
  rightElement,
  className,
}) => {
  const i18n = useI18n();

  return (
    <div
      className={`w-full flex flex-row items-center ${Classes.ELEVATION_0} ${
        fixed ? 'fixed' : ''
      } ${className ?? ''}`}
      css={lightGray4bg}
    >
      <div
        className="flex-1 flex flex-row flex-no-wrap justify-between items-center px-4 py-6 mx-auto"
        css={rightElement ? undefined : maxContentWidth}
      >
        <div className={`text-2xl ${rightElement ? 'pl-5' : ''}`}>
          {title ? title : <Trans>Active projects</Trans>}
        </div>
      </div>
      {rightElement}
    </div>
  );
};

export default ListHeader;
