/** @jsx jsx */
import { Colors, Spinner, Tag, SpinnerSize } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { useStatusColor } from '../../lib/utils';

const ACTIVE_BORDER_CLASSES = 'border-blue-500 border-2 border-solid';

export interface ICountTagProps {
  count?: number;
  title: ReactNode;
  inclusionStatus?: 'included' | 'excluded' | 'conflict' | 'postponed';
  onClick?: VoidFunction;
  active?: boolean;
  loading?: boolean;
  className?: string;
}

const CountTag: React.FC<ICountTagProps> = ({
  count,
  onClick,
  title,
  loading,
  inclusionStatus,
  active,
  className,
}) => {
  const getStatusColor = useStatusColor();
  const titleTextColor = inclusionStatus ? getStatusColor(inclusionStatus) : Colors.BLACK;

  return (
    <Tag
      className={`w-40 flex-shrink ${active ? ACTIVE_BORDER_CLASSES : ''} ${className ?? ''} ${
        onClick != null ? 'cursor-pointer' : ''
      }`}
      large
      minimal
      onClick={onClick}
      data-testid="count-tag"
    >
      <span className="flex flex-row justify-between text-xs">
        <span css={{ color: titleTextColor }}>{title}</span>
        <span>{loading ? <Spinner size={SpinnerSize.SMALL} /> : count}</span>
      </span>
    </Tag>
  );
};

export default CountTag;
