/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Colors } from '@blueprintjs/core';

export const arrowBlock = (
  rowStart: number,
  rowEnd: number,
  columnStart: number,
  columnEnd: number
) => css`
  grid-column-start: ${columnStart};
  grid-column-end: ${columnEnd};
  grid-row-start: ${rowStart};
  grid-row-end: ${rowEnd};
`;

enum lineDirection {
  horizontal,
  vertical,
}

enum arrowHeadDirection {
  down,
  right,
}

const drawArrowHead = (direction: arrowHeadDirection) => {
  switch (direction) {
    case arrowHeadDirection.down:
      return 'm -5 -5 l 5 5 m 5 -5 l -5 5';
    case arrowHeadDirection.right:
      return 'm -5 -5 l 5 5 m -5 5 l 5 -5';
  }
};

const drawLine = (direction: lineDirection, length: number) => {
  switch (direction) {
    case lineDirection.horizontal:
      return `l ${length} 0`;
    case lineDirection.vertical:
      return `l 0 ${length}`;
  }
};

const drawArrowDown = (width: number, length: number, noHead?: boolean) => {
  return `M ${width / 2} 0 ${drawLine(lineDirection.vertical, length)} ${
    noHead ? '' : drawArrowHead(arrowHeadDirection.down)
  } Z`;
};

// dualArrow, halfArrow and sideRightArrow are low-level hard-coded
export const dualArrow = (width: number, length: number, noHead?: boolean) => {
  return `M ${(1.5 / 7) * width} 0 ${drawLine(lineDirection.vertical, length / 2)} m 0 0 ${drawLine(
    lineDirection.horizontal,
    (4 / 7) * width
  )} m 0 0 ${drawLine(lineDirection.vertical, -length / 2)} M ${width / 2} ${length / 2} ${drawLine(
    lineDirection.vertical,
    length / 2
  )} ${noHead ? '' : drawArrowHead(arrowHeadDirection.down)} Z`;
};

export const halfArrow = (width: number, length: number) => {
  return `M ${width / 2} 0 ${drawLine(lineDirection.vertical, length / 2)} Z`;
};

export const sideRightArrow = (width: number, length: number) => {
  return `M ${width / 2} 0 ${drawLine(lineDirection.vertical, length / 2)} m 0 0 ${drawLine(
    lineDirection.horizontal,
    width / 6
  )} ${drawArrowHead(arrowHeadDirection.right)} Z`;
};

interface IArrowNodeProps {
  colStart: number;
  colEnd: number;
  rowStart: number;
  rowEnd: number;
  viewbox?: string;
  dual?: boolean;
  path?: string;
  noHead?: boolean;
  irregularScale?: boolean;
}

const ArrowNode: React.FC<IArrowNodeProps> = ({
  rowStart,
  rowEnd,
  colStart,
  colEnd,
  viewbox,
  dual,
  path,
  noHead,
  irregularScale,
}) => {
  const width = (colEnd - colStart) * 100;
  const value = path ?? drawArrowDown(width, 60, noHead);
  return (
    <div css={arrowBlock(rowStart, rowEnd, colStart, colEnd)}>
      <svg
        width={(colEnd - colStart) * 100}
        viewBox={viewbox ?? `0 0 ${dual ? 700 : 300} 60`}
        height="100%"
        preserveAspectRatio={`${irregularScale ? 'none' : 'xMidYMid meet'}`}
      >
        <path d={value} stroke={Colors.BLUE1} strokeWidth="2" fill="none" />
      </svg>
    </div>
  );
};

export default ArrowNode;
