import React, { useRef, memo, useMemo, ReactElement, CSSProperties } from 'react';
import { merge } from 'lodash/fp';
import { TextContentItem } from 'pdfjs-dist';
import { useUnsafePDFTextLayer } from '../../lib/utils';

interface DocTextItemProps {
  dir: string;
  str: string | ReactElement;
  width: number;
  height: number;
  transform: number[];
  fontName: string;
  scale: number;
  viewport: any;
  getFontData: (fontName: string) => any;
  pageNum: number;
  itemIdx: number;
  style?: object;
}

export const DocTextLayerItem = memo((props: DocTextItemProps) => {
  const {
    transform,
    scale,
    str: text,
    viewport,
    getFontData,
    fontName,
    pageNum,
    itemIdx,
    style,
  } = props;
  const ref = useRef<HTMLSpanElement>(null);
  // FIXME: this should be either replaced with proper calculation or removed with dependant code
  // refactoring
  const defaultSideways = useMemo(() => {
    return false;
  }, []);

  const fontSize = useMemo(() => {
    const [fontHeightPx, fontWidthPx] = transform;
    return defaultSideways ? fontWidthPx : fontHeightPx;
  }, [defaultSideways, transform]);

  const top = useMemo(() => {
    // transform = [fontHeightPx, fontWidthPx, offsetX, offsetY, x, y]
    const [, , offsetX, offsetY, x, y] = transform;
    // viewport.viewBox = [xMin, yMin, xMax, yMax]
    const [, yMin, , yMax] = viewport.viewBox;
    return defaultSideways ? x + offsetX + yMin : yMax - (y + offsetY);
  }, [transform, defaultSideways, viewport]);

  const left = useMemo(() => {
    // transform = [fontHeightPx, fontWidthPx, offsetX, offsetY, x, y]
    const [, , , , x, y] = transform;
    const [xMin] = viewport.viewBox;
    return defaultSideways ? y - xMin : x - xMin;
  }, [transform, viewport, defaultSideways]);

  const [fontFamily, fontFamilyFallback] = useMemo(() => {
    const fontData = getFontData(fontName);
    const fontFamilyFallback = fontData ? fontData.fallbackName : 'sans-serif';
    return [fontName, fontFamilyFallback];
  }, [getFontData, fontName]);

  return (
    <span
      style={
        merge(style, {
          height: '1em',
          fontFamily: `${fontFamily}, ${fontFamilyFallback}`,
          fontSize: `${fontSize * scale}px`,
          position: 'absolute',
          top: `${top * scale}px`,
          left: `${left * scale}px`,
          transformOrigin: 'left bottom',
          whiteSpace: 'pre',
          pointerEvents: 'all',
        }) as CSSProperties
      }
      ref={ref}
      data-page-num={pageNum}
      data-item-index={itemIdx}
    >
      {text}
    </span>
  );
});

interface DocTextLayerProps {
  items: TextContentItem[];
  viewport: any;
  scale: number;
  getFontData: (fontName: string) => any;
  pageNum: number;
}

const DocTextLayer = memo((props: DocTextLayerProps) => {
  const { items, viewport, scale, getFontData, pageNum } = props;
  const ref = useRef<null | HTMLDivElement>(null);
  useUnsafePDFTextLayer(ref, items, scale);

  return (
    <div
      ref={ref}
      className="react-pdf__Page__textContent"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `100%`,
        height: `100%`,
        color: 'transparent',
        pointerEvents: 'none',
      }}
    >
      {items.map((item, idx) => (
        <DocTextLayerItem
          key={idx}
          {...item}
          scale={scale}
          viewport={viewport}
          getFontData={getFontData}
          pageNum={pageNum}
          itemIdx={idx}
        />
      ))}
    </div>
  );
});

export default DocTextLayer;
