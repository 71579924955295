/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { fancyScrollCss, lightGray5bg } from '../../../common/styles';
import { Reference } from '../../../common/types';
import ReferencePreview from '../reference_preview';

const EMPTY_KEYWORDS_DATA = {
  desiredKeywords: [],
  searchKeywords: [],
  undesiredKeywords: [],
};

interface IBibliographicDataProps {
  reference: Pick<Reference, 'id' | 'title' | 'attrs'>;
  onClose: () => void;
}

const BibliographicData: React.FC<IBibliographicDataProps> = ({ reference, onClose }) => {
  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="h-12 flex flex-row items-center" css={lightGray5bg}>
        <div className="flex-1 truncate text-xl px-4">
          <Trans>Bibliographic data</Trans>
        </div>
        <Divider className="m-0 h-full" />
        <Button className="h-full" minimal large icon={IconNames.CROSS} onClick={onClose} />
      </div>
      <Divider className="m-0" />
      <div className="flex-1 overflow-auto" css={fancyScrollCss}>
        <ReferencePreview reference={reference} keywordsData={EMPTY_KEYWORDS_DATA} />
      </div>
    </div>
  );
};

export default BibliographicData;
