/** @jsx jsx */
import { useMutation, useSubscription } from '@apollo/react-hooks';
import {
  Button,
  Callout,
  Classes,
  Dialog,
  Intent,
  Radio,
  RadioGroup,
  Spinner,
} from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { capitalize, get, merge } from 'lodash/fp';
import { Fragment, useCallback, useEffect } from 'react';
import { StatusColorSettings, UserSettings } from '../../common/types';
import { useKeycloak } from '../../keycloak';
import AppToaster from '../../lib/toaster';
import { useCurrCallback, useSetState } from '../../lib/utils';
import StatusColorsPicker from './status_colors_picker';
import { DEFAULT_THEME } from './theme_context';

type TStatusId = 'neutral' | 'accepted' | 'rejected' | 'conflicts';

const UserSettingsSubscription = gql`
  subscription UserSettings($userId: String!) {
    settingsData: user_settings_by_pk(user_id: $userId) {
      user_id
      settings
    }
  }
`;

const UpdateUserSettingsMutation = gql`
  mutation UpdateUserSettings($userId: String!, $settings: jsonb!) {
    insert_user_settings_one(
      object: { user_id: $userId, settings: $settings }
      on_conflict: { constraint: user_settings_pkey, update_columns: settings }
    ) {
      settings
      user_id
    }
  }
`;

interface IColorSettingsDialogState {
  colorsMode: 'default' | 'custom';
  colors: StatusColorSettings;
}

const INIT_STATE: IColorSettingsDialogState = {
  colorsMode: 'default',
  colors: DEFAULT_THEME.statusColors,
};

interface IColorSettingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const ColorSettingsDialog: React.FC<IColorSettingsDialogProps> = ({ isOpen, onClose }) => {
  const { user } = useKeycloak();
  const userId = get('id', user);
  const { data, loading } = useSubscription<UserSettings>(UserSettingsSubscription, {
    variables: { userId },
  });
  const [updateSettings] = useMutation(UpdateUserSettingsMutation);
  const [state, setState] = useSetState<IColorSettingsDialogState>(INIT_STATE);
  const { colorsMode, colors } = state;
  const settings = get('settings', data);

  const currentColorsOption: 'default' | 'custom' =
    get('settingsData.settings.statusColorsMode', data) ?? 'default';

  const customColors: StatusColorSettings | undefined = get(
    'settingsData.settings.statusColors',
    data
  );

  useEffect(() => {
    setState((currentState) => ({
      colorsMode: currentColorsOption,
      colors: merge(currentState.colors, customColors ?? {}),
    }));
  }, [setState, currentColorsOption, customColors]);

  const handleColorOptionChange = useCallback(
    (evt) => {
      setState({ colorsMode: evt.currentTarget.value });
    },
    [setState]
  );

  const handleClose = useCallback(() => {
    setState({
      colorsMode: currentColorsOption,
      colors: customColors ?? DEFAULT_THEME.statusColors,
    });
    onClose();
  }, [setState, onClose, currentColorsOption, customColors]);

  const handleSave = useCallback(async () => {
    const currentSettings = settings ?? {};
    try {
      await updateSettings({
        variables: {
          userId: userId,
          settings: { ...currentSettings, statusColorsMode: colorsMode, statusColors: colors },
        },
      });
      handleClose();
    } catch (error) {
      AppToaster.show({
        intent: Intent.WARNING,
        message: (error instanceof Error && error.message) || 'Unknown error',
      });
    }
  }, [updateSettings, colorsMode, colors, settings, userId, handleClose]);

  const handleStatusColorPick = useCurrCallback(
    (statusId: TStatusId, { type, color }: { type: 'primary' | 'secondary'; color: string }) => {
      setState((current) => ({
        ...current,
        colors: { ...current.colors, [`${statusId}${capitalize(type)}`]: color },
      }));
    },
    [setState]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      title={<Trans>Color Settings</Trans>}
      css={{ width: '800px' }}
    >
      <div className={Classes.DIALOG_BODY}>
        <Callout icon={null} intent={Intent.PRIMARY}>
          <Trans>
            Colors will be used consistently on various User Interface components across the whole
            system
          </Trans>
        </Callout>
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <div className="my-4">
              <RadioGroup onChange={handleColorOptionChange} selectedValue={colorsMode}>
                <Radio value="default" labelElement={<Trans>Use default colors</Trans>} />
                <Radio value="custom" labelElement={<Trans>Personalize colors</Trans>} />
              </RadioGroup>
            </div>
            {colorsMode === 'custom' && (
              <div className="flex flex-row justify-between">
                {['neutral', 'accepted', 'rejected', 'conflicts'].map((statusId) => (
                  <StatusColorsPicker
                    key={statusId}
                    statusId={statusId as TStatusId}
                    primaryColor={colors[`${statusId}Primary`]}
                    secondaryColor={colors[`${statusId}Secondary`]}
                    onSetColor={handleStatusColorPick(statusId)}
                  />
                ))}
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={handleClose} />
          <Button text={<Trans>Save</Trans>} onClick={handleSave} />
        </div>
      </div>
    </Dialog>
  );
};

export default ColorSettingsDialog;
