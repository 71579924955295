/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Alert,
  Button,
  Classes,
  Colors,
  Divider,
  InputGroup,
  Intent,
  Spinner,
  TextArea,
} from '@blueprintjs/core';
import gql from 'graphql-tag';
import { filter, findIndex, isEmpty, merge, noop, propEq, set, trim, __ } from 'lodash/fp';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import ScreeningForm from '../screening/full_text/screening_form';
import {
  DomainItem,
  FormTemplate,
  FTScreeningCriteria,
  FTScreeningFormData,
  InclusionExclusionCriteriaFormData,
  InclusionStatus,
  ScreeningTag,
  Stage,
  StageType,
} from '../../common/types';
import {
  createFTScreeningCriteria,
  EMPTY_KEYWORDS_DATA,
  validateScreeningForm,
} from '../../lib/criteria_utils';
import { swapArrayItems, uid, useCurrCallback, useI18n, useSetState } from '../../lib/utils';
import ErrorScreen from '../common/error_screen';
import {
  ftFormDataToTiabFormData,
  isTiabFormData,
  markScreeningTagsCopied,
  tiabFormDataToFTFormData,
} from '../project/helpers';
import ExpandableInstructionSectionItem from './expandable_instruction_section_item';
import { t, Trans } from '@lingui/macro';
import Immutable from 'immutable';
import AppToaster from '../../lib/toaster';
import { darkGray5Color, fancyScrollCss, lightGray1bg, lightGray5bg } from '../../common/styles';
import FTInstructionSection from './ft_instruction_section';
import InstructionSectionItem from './instruction_section_item';
import useActionLogger from '../hooks/use_action_logger';
import TemplateImportExportControls from './template_import_export_controls';
import TitleAndAbstractScreeningControlsPreview from './title_and_abstract_screening_decision_controls_preview';
import KeywordsEditor from './keywords_editor';
import InitiateInstructionsScreen from './initiate_instructions_screen';

const EMPTY_SET = Immutable.Set();
const EMPTY_ARRAY = [];

const redInputColorCss = css`
  .${Classes.INPUT} {
    color: ${Colors.RED3};
  }
`;

const greenInputColorCss = css`
  .${Classes.INPUT} {
    color: ${Colors.FOREST3};
  }
`;

const UpsertFormMutation = gql`
  mutation InsertForm($form: form_insert_input!) {
    insert_form_one(
      object: $form
      on_conflict: { constraint: form_pkey, update_columns: [form, template_id] }
    ) {
      id
      stage_id
      template_id
      form
    }
  }
`;

const StageDataQuery = gql`
  query StageData($stageId: uuid!) {
    stage_by_pk(id: $stageId) {
      id
      name
      type
      order_number
      forms(order_by: { created_at: desc }, limit: 1) {
        id
        template_id
        stage_id
        form
      }
      tasks_aggregate {
        aggregate {
          count
        }
      }
    }
    templates: form_template {
      id
      name
      template
    }
    domains: form_domains_and_variables {
      domain
      variables
    }
  }
`;

const SaveFormTemplateMutation = gql`
  mutation SaveFormTemplate($template: form_template_insert_input!) {
    insert_form_template_one(object: $template) {
      id
    }
  }
`;

type TStageData = Pick<
  Stage,
  'id' | 'name' | 'type' | 'forms' | 'tasks_aggregate' | 'order_number'
>;

interface ScreeningInstructionsRouterProps {
  projectId: string;
  stageId: string;
}

interface IScreeningInstructionsProps
  extends RouteComponentProps<ScreeningInstructionsRouterProps> {}

interface IInstructionsState {
  editedForm: { form: FTScreeningFormData; templateId: string | null } | null;
  showInvalidFields: boolean;
  formErrors: string[];
}

function getInitialState(): IInstructionsState {
  return {
    editedForm: null,
    showInvalidFields: false,
    formErrors: [],
  };
}

const ScreeningInstructions: React.FC<IScreeningInstructionsProps> = (props) => {
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState<IInstructionsState>(getInitialState());
  const { editedForm, showInvalidFields, formErrors } = state;
  const i18n = useI18n();
  const [saveForm, { loading: saving }] = useMutation(UpsertFormMutation);
  const [saveFormTemplate] = useMutation(SaveFormTemplateMutation);
  const { stageId, projectId } = props.match.params;
  const { data, loading, error, refetch } = useQuery<
    { stage_by_pk: TStageData; templates: FormTemplate[]; domains: DomainItem[] },
    { stageId: string }
  >(StageDataQuery, { variables: { stageId }, fetchPolicy: 'network-only' });
  const templates = data?.templates ?? EMPTY_ARRAY;
  const stage = data?.stage_by_pk;
  const stageType = stage?.type;
  const stageForm = stage?.forms[0];
  const domains = data?.domains ?? EMPTY_ARRAY;
  const readOnly = (stage?.tasks_aggregate?.aggregate.count ?? 0) > 0;

  const form = useMemo(() => {
    if (stageForm == null) return;

    return isTiabFormData(stageForm.form)
      ? { ...stageForm, form: tiabFormDataToFTFormData(stageForm.form) }
      : stageForm;
  }, [stageForm, stageType]);

  const formDataToRender = editedForm?.form ?? form?.form;

  const updateEditedFormData = useCallback(
    (updater: (currentData: FTScreeningFormData) => FTScreeningFormData) => {
      setState((current) => {
        const currentForm = current.editedForm?.form ?? form?.form;
        if (currentForm == null) return current;

        return {
          ...current,
          editedForm: {
            form: updater(currentForm),
            templateId: (current.editedForm?.templateId ?? form?.template_id)!,
          },
        };
      });
    },
    [setState, form]
  );

  const addCriteria = useCallback(
    (inclusionStatus: InclusionStatus.Included | InclusionStatus.Excluded) => {
      const criteriaSetName =
        inclusionStatus === InclusionStatus.Included ? 'inclusion' : 'exclusion';

      updateEditedFormData((currentForm) => ({
        ...currentForm,
        [criteriaSetName]: [
          ...currentForm[criteriaSetName],
          createFTScreeningCriteria(inclusionStatus),
        ],
      }));
    },
    [updateEditedFormData]
  );

  const updateOrRemoveCriteria = useCallback(
    (
      inclusionStatus: InclusionStatus.Included | InclusionStatus.Excluded,
      id: string,
      updater?: (current: FTScreeningCriteria) => FTScreeningCriteria
    ) => {
      const criteriaSetName =
        inclusionStatus === InclusionStatus.Included ? 'inclusion' : 'exclusion';

      updateEditedFormData((currentForm) => {
        const criteriaIdx = findIndex({ id }, currentForm[criteriaSetName]);
        if (criteriaIdx < 0) return currentForm;

        const updatedCriteria = [...currentForm[criteriaSetName]];
        if (updater) {
          updatedCriteria.splice(criteriaIdx, 1, updater(updatedCriteria[criteriaIdx]));
        } else {
          updatedCriteria.splice(criteriaIdx, 1);
        }

        return {
          ...currentForm,
          [criteriaSetName]: updatedCriteria,
        };
      });
    },
    [updateEditedFormData]
  );

  const updateOrRemoveTag = useCallback(
    (id: string, updater?: (current: ScreeningTag) => ScreeningTag) => {
      updateEditedFormData((currentForm) => {
        const tagIdx = findIndex({ id }, currentForm.tags);
        if (tagIdx < 0) return currentForm;

        const updatedTags = [...currentForm.tags];
        if (updater) {
          updatedTags.splice(tagIdx, 1, updater(updatedTags[tagIdx]));
        } else {
          updatedTags.splice(tagIdx, 1);
        }

        return {
          ...currentForm,
          tags: updatedTags,
        };
      });
    },
    [updateEditedFormData]
  );

  const handleCriteriaChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { dataset, value } = evt.target;
      const criteriaInclusionStatus = dataset.inclusionStatus! as
        | InclusionStatus.Included
        | InclusionStatus.Excluded;
      const criteriaId = dataset.id;
      const criteriaFieldName = evt.target.getAttribute('name')!;

      if (criteriaId) {
        updateOrRemoveCriteria(
          criteriaInclusionStatus,
          criteriaId,
          merge(__, { [criteriaFieldName]: value })
        );
      }
    },
    [updateOrRemoveCriteria]
  );

  const moveFormItem = useCallback(
    (collectionPath: 'tags' | 'exclusion' | 'inclusion', fromIdx: number, toIdx: number) => {
      updateEditedFormData((currentForm) => ({
        ...currentForm,
        [collectionPath]: swapArrayItems<any>(fromIdx, toIdx, currentForm[collectionPath]),
      }));
    },
    [updateEditedFormData]
  );

  const validateTag = useCallback(
    (tagValue: string): boolean =>
      filter(propEq('tag', tagValue), formDataToRender.tags).length === 1,
    [formDataToRender]
  );

  const validateCriteriaProps = useCallback(
    (propName: string, propValue: string): boolean => {
      const trimmed = trim(propValue);
      return (
        filter(
          (elem: FTScreeningCriteria) => trim(elem[propName]) === trimmed,
          [...formDataToRender.inclusion, ...formDataToRender.exclusion]
        ).length === 1
      );
    },
    [formDataToRender]
  );

  const criteriaRenderer = (
    { id, name, inclusionStatus, code, instruction, keywords }: FTScreeningCriteria,
    idx: number,
    items: FTScreeningCriteria[]
  ) => {
    const criteriaType = inclusionStatus === InclusionStatus.Included ? 'inclusion' : 'exclusion';
    const isNameValid = validateCriteriaProps('name', name);
    const isCodeValid = validateCriteriaProps('code', code);

    return (
      <ExpandableInstructionSectionItem
        key={id}
        onDelete={readOnly ? undefined : () => updateOrRemoveCriteria(inclusionStatus, id)}
        onMoveUp={idx > 0 ? () => moveFormItem(criteriaType, idx, idx - 1) : undefined}
        onMoveDown={
          idx < items.length - 1 ? () => moveFormItem(criteriaType, idx, idx + 1) : undefined
        }
        content={
          <div className="flex flex-row">
            <InputGroup
              className="mr-4 flex-1"
              value={name}
              data-id={id}
              data-inclusion-status={inclusionStatus}
              name="name"
              onChange={handleCriteriaChange}
              readOnly={readOnly}
              intent={
                (showInvalidFields && isEmpty(name.trim())) || !isNameValid
                  ? Intent.DANGER
                  : Intent.NONE
              }
            />
            <InputGroup
              className="w-16 mx-8"
              css={
                inclusionStatus === InclusionStatus.Included ? greenInputColorCss : redInputColorCss
              }
              value={code}
              data-id={id}
              data-inclusion-status={inclusionStatus}
              name="code"
              onChange={handleCriteriaChange}
              readOnly={readOnly}
              intent={
                (showInvalidFields && isEmpty(code?.trim())) || !isCodeValid
                  ? Intent.DANGER
                  : Intent.NONE
              }
            />
          </div>
        }
        expandableContent={
          <div>
            <div className="h-6">
              <Trans>Instruction</Trans>
            </div>
            <TextArea
              name="instruction"
              value={instruction}
              onChange={handleCriteriaChange}
              data-id={id}
              data-inclusion-status={inclusionStatus}
              // edition of instruction details allowed even after distribution, as requested in T5677
              // readOnly={readOnly}
              className="w-full"
              rows={4}
            />
            {stageType !== StageType.FullTextScreening && (
              <KeywordsEditor
                className="mt-3"
                keywords={keywords ?? EMPTY_KEYWORDS_DATA}
                domains={domains}
                onChange={(newKeywords) =>
                  updateOrRemoveCriteria(inclusionStatus, id, set('keywords', newKeywords))
                }
              />
            )}
          </div>
        }
      />
    );
  };

  const handleTagChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { dataset, value } = evt.target;
      const tagId = dataset.id!;

      updateOrRemoveTag(tagId, (current) => ({ ...current, tag: value }));
    },
    [updateOrRemoveTag]
  );

  const handleTagDelete = useCurrCallback(
    (tagId: string, _evt) => {
      updateOrRemoveTag(tagId);
    },
    [updateOrRemoveTag]
  );

  const handleImportTemplate = useCallback(
    async (
      templateId: string | null,
      template: InclusionExclusionCriteriaFormData | FTScreeningFormData
    ) => {
      const withCopiedTags = markScreeningTagsCopied(template);
      const importedForm = isTiabFormData(withCopiedTags)
        ? tiabFormDataToFTFormData(withCopiedTags)
        : withCopiedTags;
      // drop inclusion criteria if Full text template is imported in non-ft stage instructions
      if (stageType != StageType.FullTextScreening) {
        importedForm.inclusion = [];
      }

      setState({
        editedForm: {
          form: importedForm,
          templateId,
        },
      });
    },
    [setState, stageType]
  );

  const handleExportTemplate = useCallback(
    async (templateName: string) => {
      const currentForm = editedForm?.form ?? form!.form;

      const template =
        stageType === StageType.FullTextScreening
          ? currentForm
          : ftFormDataToTiabFormData(currentForm);

      saveFormTemplate({
        variables: {
          template: {
            name: templateName,
            template,
          },
        },
        refetchQueries: [
          {
            query: StageDataQuery,
            variables: { stageId },
          },
        ],
      })
        .then(() => {
          insertActionLog('criteria set saved', { templateName, template });
          AppToaster.show({
            message: <Trans>Instructions template saved</Trans>,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          insertActionLog('error saving criteria set', {
            templateName,
            template,
            err,
          });
          AppToaster.show({
            message: <Trans>Error saving instructions template: {err.message}</Trans>,
            intent: Intent.WARNING,
          });
        });
    },
    [saveFormTemplate, insertActionLog, form, stageType, editedForm]
  );

  const handleSave = async () => {
    if (editedForm == null) return;
    const { form: editedFormData, templateId } = editedForm;

    const validationErrors = validateScreeningForm(editedFormData, stageType);
    const isValid = isEmpty(validationErrors);

    setState({
      showInvalidFields: !isValid,
      formErrors: validationErrors,
    });

    if (isValid) {
      try {
        const result = await saveForm({
          variables: {
            form: {
              id: form?.id,
              template_id: templateId,
              stage_id: stageId,
              form:
                stageType === StageType.FullTextScreening
                  ? editedFormData
                  : ftFormDataToTiabFormData(editedFormData),
            },
          },
        });
        // fetch the newly saved form before nullifying the edited one
        await refetch();
        setState({ editedForm: null });

        AppToaster.show({
          intent: Intent.SUCCESS,
          message: i18n._(t`Instructions saved`),
        });

        insertActionLog('Saved form', { form: result.data?.insert_form });
      } catch (error) {
        AppToaster.show({
          intent: Intent.WARNING,
          message: `${i18n._(t`Failed to save instructions`)}: ${(error as Error).toString()} }`,
        });
      }
    }
  };

  const addTag = useCallback(
    () =>
      updateEditedFormData((currentForm) => ({
        ...currentForm,
        tags: currentForm.tags.concat({ id: uid(), tag: '' }),
      })),
    [updateEditedFormData]
  );

  if (error) {
    return <ErrorScreen error={error} retry={refetch} />;
  }

  if (loading) {
    return <Spinner className="h-full" />;
  }

  if (formDataToRender == null) {
    return (
      <InitiateInstructionsScreen
        onLoad={handleImportTemplate}
        stageType={stageType}
        templates={templates}
        stageName={stage?.name}
        stageOrderNumber={stage?.order_number}
        projectId={projectId}
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <Prompt
        when={editedForm != null}
        message={i18n._(t`There are unsaved changes. Are you sure you want to leave?`)}
      />
      {/* FIXME: hidden for T5072 {readOnly && (
        <div css={bannerCss} className="py-3 text-center">
          <Trans>This instruction is already used in screening process and cannot be edited</Trans>
        </div>
      )} */}
      <div className="flex-1 flex flex-row flex-no-wrap overflow-hidden relative">
        <div
          data-testid="ft-instructions-col"
          className="flex flex-col flex-1 overflow-auto"
          css={lightGray1bg}
        >
          <div
            className="h-12 flex-none flex flex-row items-center flex-no-wrap overflow-hidden px-4"
            css={lightGray5bg}
          >
            <span className="flex-1 truncate text-xl">
              {stage ? (
                <Trans>{stage?.name} Instruction</Trans>
              ) : (
                <Trans>Screening Instruction</Trans>
              )}
            </span>
            <TemplateImportExportControls
              templates={templates}
              onImport={handleImportTemplate}
              onExport={handleExportTemplate}
              currentFormTemplateId={editedForm?.templateId ?? form?.template_id ?? null}
              exportDisabled={isEmpty(formDataToRender)}
              importDisabled={readOnly}
            />
          </div>
          <Divider className="m-0" />
          <div className="overflow-auto" css={fancyScrollCss}>
            {stageType === StageType.FullTextScreening && (
              <FTInstructionSection
                title={
                  <span className="text-xl text-green-700">
                    <Trans>Include</Trans>
                  </span>
                }
                onAddItem={readOnly ? undefined : () => addCriteria(InclusionStatus.Included)}
              >
                <div css={darkGray5Color} className="flex justify-between mb-1">
                  <div className="ml-4">
                    <Trans>Reason</Trans>
                  </div>
                  <div className="mr-32">
                    <Trans>Tag code</Trans>
                  </div>
                </div>
                <div className="criteria-list">
                  {formDataToRender.inclusion.map(criteriaRenderer)}
                </div>
              </FTInstructionSection>
            )}
            <FTInstructionSection
              title={
                <span className="text-xl text-red-700">
                  <Trans>Exclude</Trans>
                </span>
              }
              onAddItem={readOnly ? undefined : () => addCriteria(InclusionStatus.Excluded)}
            >
              <div css={darkGray5Color} className="flex justify-between mb-1">
                <div className="ml-4">
                  <Trans>Reason</Trans>
                </div>
                <div className="mr-32">
                  <Trans>Tag code</Trans>
                </div>
              </div>
              <div className="criteria-list">
                {formDataToRender.exclusion.map(criteriaRenderer)}
              </div>
            </FTInstructionSection>
            <FTInstructionSection
              title={
                <span className="text-xl" css={darkGray5Color}>
                  <Trans>Structured comments</Trans>
                </span>
              }
              onAddItem={addTag}
            >
              <div className="tags-list">
                {(formDataToRender.tags as ScreeningTag[]).map(({ id, tag, copied }, idx, tags) => (
                  <InstructionSectionItem
                    key={id}
                    onDelete={readOnly ? undefined : handleTagDelete(id)}
                    onMoveUp={idx > 0 ? () => moveFormItem('tags', idx, idx - 1) : undefined}
                    onMoveDown={
                      idx < tags.length - 1 ? () => moveFormItem('tags', idx, idx + 1) : undefined
                    }
                  >
                    <InputGroup
                      name="structured-comment"
                      title={
                        copied
                          ? i18n._(
                              t`This tags was copied from another form/template. It cannot be edited.`
                            )
                          : undefined
                      }
                      className="mr-4 flex-1"
                      value={tag}
                      data-id={id}
                      onChange={handleTagChange}
                      // can't edit tags copied from other forms
                      disabled={copied}
                      intent={
                        (showInvalidFields && isEmpty(tag.trim())) || !validateTag(tag)
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                    />
                  </InstructionSectionItem>
                ))}
              </div>
            </FTInstructionSection>
          </div>
        </div>
        <Divider className="m-0" />
        <div
          data-testid="ft-instructions-col"
          className="flex flex-col flex-none max-w-lg w-1/3 overflow-auto"
        >
          <div className="h-12 flex flex-row items-center justify-end px-4" css={lightGray5bg}>
            <span className="flex-1 text-xl">
              <Trans>Preview</Trans>
            </span>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col flex-1 overflow-auto" css={fancyScrollCss}>
            {stageType === StageType.FullTextScreening ? (
              <ScreeningForm
                form={formDataToRender}
                selectedCriteria={EMPTY_SET}
                onCriteriaSelect={noop}
                readOnly
              />
            ) : (
              <TitleAndAbstractScreeningControlsPreview form={formDataToRender} />
            )}
          </div>
        </div>
      </div>
      <Divider className="m-0" />
      <div
        className="flex-none h-12 px-4 flex flex-row items-center justify-end"
        css={lightGray5bg}
      >
        <Button
          intent={Intent.PRIMARY}
          text={<Trans>Save instruction</Trans>}
          disabled={editedForm == null}
          onClick={handleSave}
          loading={saving}
        />
        <Alert
          isOpen={!isEmpty(formErrors)}
          intent={Intent.WARNING}
          confirmButtonText={i18n._(t`Ok`)}
          onConfirm={() => setState({ formErrors: [] })}
        >
          <p>
            <Trans>The form cannot be saved. Errors: {formErrors.join('; ')}.</Trans>
          </p>
        </Alert>
      </div>
    </div>
  );
};

export default ScreeningInstructions;
