/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Divider } from '@blueprintjs/core';
import { t, Trans } from '@lingui/macro';
import { isEmpty, orderBy, find, compose } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { fancyScrollCss } from '../../../common/styles';
import { InclusionStatus, Flatten } from '../../../common/types';
import { useKeycloak } from '../../../keycloak';
import CommentBox from '../comment_box';
import { TReferenceDetailsData } from './reference_details';
import ReferenceCommentsByStage from '../reference_comments_by_stage';
import MyReferenceComment from '../my_reference_comment';

interface IReferenceCommentsPanelProps {
  reference: TReferenceDetailsData;
  onUpdateComment: (data: {
    id?: string;
    comment: string;
    teamMemberId: string;
    stageId: string;
  }) => void;
  onDeleteComment: (commentId: string) => void;
}

const ReferenceCommentsPanel: React.FC<IReferenceCommentsPanelProps> = ({
  reference,
  onDeleteComment,
  onUpdateComment,
}) => {
  const { project, reference_comments: comments, id: referenceId } = reference;
  const studyCurrentStageId = reference.study.current_stage_id;
  const { user } = useKeycloak();

  const myTeamMemberId = useMemo(() => {
    return find({ user_id: user.id }, project.team_members)?.id;
  }, reference.project.team_members);

  const projectStagesOrdered = useMemo(() => {
    return orderBy('order_number', 'asc', reference.project.stages);
  }, [reference.project.stages]);

  const currentAdminComment = useMemo(() => {
    return compose(
      find<Flatten<TReferenceDetailsData['reference_comments']>>(
        ({ stage_id, team_member }) =>
          stage_id === studyCurrentStageId && team_member.user?.id === user.id
      ),
      orderBy('updated_at', 'desc')
    )(comments);
  }, [comments, myTeamMemberId, studyCurrentStageId, referenceId, projectStagesOrdered, user.id]);

  const restComments = useMemo(() => {
    return comments.filter(({ id }) => id !== currentAdminComment?.id);
  }, [comments, currentAdminComment]);

  const canEdit = useMemo(() => {
    if (myTeamMemberId == null) return false;
    // can edit comment only if stage hasn't started (no stage result) or if screening has completed
    // (stage result's inclusion status is either Include or Exclude)
    const commentStageResult = reference.stage_results.find(
      ({ stage_id }) => stage_id === (currentAdminComment?.stage_id ?? studyCurrentStageId)
    );

    return (
      commentStageResult == null ||
      [InclusionStatus.Included, InclusionStatus.Excluded].includes(
        commentStageResult.inclusion_status
      )
    );
  }, [myTeamMemberId, currentAdminComment, reference.stage_results, studyCurrentStageId]);

  const handleAdminCommentChange = useCallback(
    (data: { id?: string; comment: string }) => {
      if (myTeamMemberId) {
        const { id, comment } = data;

        onUpdateComment({
          comment,
          id,
          teamMemberId: myTeamMemberId,
          stageId: currentAdminComment?.stage_id ?? studyCurrentStageId ?? project.stages[0].id,
        });
      }
    },
    [myTeamMemberId, currentAdminComment, studyCurrentStageId, project.stages[0].id]
  );

  return (
    <div className="flex flex-col pt-6 overflow-hidden">
      <div className="px-8 flex-none">
        <MyReferenceComment
          comment={currentAdminComment}
          onDelete={canEdit ? onDeleteComment : undefined}
          onUpdate={canEdit ? handleAdminCommentChange : undefined}
        />
      </div>
      {!isEmpty(restComments) && (
        <React.Fragment>
          <Divider className="m-0 mt-4" />
          <ReferenceCommentsByStage
            className="flex-1 overflow-auto px-8"
            css={fancyScrollCss}
            comments={restComments}
            stages={reference.project.stages}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default ReferenceCommentsPanel;
