/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { UserData } from '../users_panel';
import { FormGroup, Classes, Colors } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { getFullPhoneNumber } from '../../project/helpers';

const containerCss = css`
  .${Classes.LABEL} {
    color: ${Colors.GRAY1};
  }
`;

const DetailsField = ({ label, content }) => (
  <FormGroup className="flex-auto" label={label}>
    <span className="text-lg">{content}</span>
  </FormGroup>
);

interface IUserDetailsProps {
  user: UserData;
}

const UserDetails: React.FC<IUserDetailsProps> = ({ user }) => {
  return (
    <div className="p-6" css={containerCss}>
      <div className="flex flex-row flex-no-wrap">
        <DetailsField label={<Trans>Email address</Trans>} content={user.email} />
        <DetailsField
          label={<Trans>Phone number</Trans>}
          content={getFullPhoneNumber(user.phoneAreaCode, user.phoneNumber)}
        />
      </div>
      <div className="flex flex-row flex-no-wrap">
        <DetailsField
          label={<Trans>Institution name</Trans>}
          content={user.institutionName ?? '-'}
        />
      </div>
      <div className="flex flex-row flex-no-wrap">
        <DetailsField
          label={<Trans>Institution website</Trans>}
          content={user.institutionWebsite ?? '-'}
        />
        <DetailsField
          label={<Trans>Institution phone number</Trans>}
          content={getFullPhoneNumber(user.institutionAreaCode, user.institutionPhone)}
        />
      </div>
      <div className="flex flex-row flex-no-wrap">
        <DetailsField
          label={<Trans>Institution street address</Trans>}
          content={user.institutionAddress ?? '-'}
        />
      </div>
      <div className="flex flex-row flex-no-wrap">
        <DetailsField label={<Trans>City</Trans>} content={user.institutionCity ?? '-'} />
        <DetailsField
          label={<Trans>Postal code</Trans>}
          content={user.institutionPostalCode ?? '-'}
        />
        <DetailsField label={<Trans>Country</Trans>} content={user.institutionCountry ?? '-'} />
      </div>
    </div>
  );
};

export default UserDetails;
