/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Divider, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { gray1ColorCss } from '../../common/styles';
import { containerCss } from '../screening/screening_tags';
import Textarea from '../common/textarea';
import { isEmpty } from 'lodash/fp';
import { Trans } from '@lingui/macro';

interface ICommentBoxProps {
  comment?: string;
  onChange?: (comment: string) => void;
  title?: ReactNode;
  authorName?: string;
  className?: string;
}

const CommentBox: React.FC<ICommentBoxProps> = ({
  comment,
  onChange,
  title,
  authorName,
  className,
}) => {
  const [editedComment, setEditedComment] = useState<null | string>(null);

  const handleSaveEditedComment = () => {
    if (onChange && editedComment != null) {
      onChange(editedComment);
      setEditedComment(null);
    }
  };

  const handleCommentChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setEditedComment(evt.target.value);
  };

  useEffect(() => {
    if (onChange != null) {
      setEditedComment(isEmpty(comment) ? comment ?? '' : null);
    }
  }, [setEditedComment, comment, onChange]);

  return (
    <div
      className={`w-full flex flex-col bg-white ${className ?? ''}`}
      css={containerCss}
      data-testid="comment-box"
    >
      {(onChange || title) && (
        <div className="flex items-center h-8 header">
          <span className="flex-1 truncate text-sm px-4">{title}</span>
          {onChange && (
            <React.Fragment>
              <Divider className="m-0 h-full" />
              <Button
                minimal
                disabled={editedComment != null}
                icon={IconNames.EDIT}
                intent={Intent.PRIMARY}
                onClick={() => setEditedComment(comment ?? '')}
              />
            </React.Fragment>
          )}
        </div>
      )}
      <Textarea
        value={editedComment ?? comment ?? ''}
        onChange={handleCommentChange}
        readOnly={onChange == null || editedComment == null}
        className="resize-none w-full p-2"
        css={{ minHeight: '50px' }}
        onBlur={handleSaveEditedComment}
        grow
      />
      {editedComment == null ? (
        authorName && (
          <div className="text-right text-xs m-2 mt-0" css={gray1ColorCss}>
            {authorName}
          </div>
        )
      ) : (
        <div className="w-full flex flex-row">
          <Button
            className="w-full"
            intent={Intent.NONE}
            text={
              <span className="text-red-700">
                <Trans>Cancel</Trans>
              </span>
            }
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => setEditedComment(null)}
          />
          <Button
            className="w-full"
            text={
              <span className="text-green-700">
                <Trans>OK</Trans>
              </span>
            }
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleSaveEditedComment}
          />
        </div>
      )}
    </div>
  );
};

export default CommentBox;
