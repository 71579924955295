/** @jsx jsx */
import { ReactNode, Fragment } from 'react';
import { jsx } from '@emotion/core';
import { Icon, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import DiagramNode from './diagram_node';
import { compose, map, reject, isEmpty, get } from 'lodash/fp';
import { PrismaEditorItem } from '../../common/types';

const renderRecord = (elem) => {
  return (
    <div className="flex flex-row justify-between items-center mb-1" key={elem.id}>
      <span>{elem.title}</span>
      <span
        css={{
          background: Colors.GRAY3,
          color: Colors.WHITE,
          padding: '2px 10px',
          borderRadius: '7px',
        }}
      >
        {elem.value}
      </span>
    </div>
  );
};

interface IDiagramCustomNodeProps {
  title: ReactNode;
  records?: PrismaEditorItem[];
  value: number;
  col: number;
  row: number;
}

const DiagramCustomNode: React.FC<IDiagramCustomNodeProps> = ({
  title,
  records,
  value,
  col,
  row,
}) => {
  return (
    <DiagramNode col={col} row={row}>
      <Fragment>
        <div className="flex flex-row justify-between items-center">
          <span
            css={{
              color: Colors.GRAY1,
            }}
            className="w-4/5 uppercase"
          >
            {title}
          </span>
          <span>
            {value} <Icon icon={IconNames.DOCUMENT} />
          </span>
        </div>
        <div className="mt-2">
          {compose(map(renderRecord), reject(compose(isEmpty, get('title'))))(records)}
        </div>
      </Fragment>
    </DiagramNode>
  );
};

export default DiagramCustomNode;
