/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Colors, H1, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { get, keyBy, mapValues } from 'lodash/fp';
import { Trans } from '@lingui/macro';
import { css, jsx } from '@emotion/core';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import PageContentWrapper from '../../common/page_content_wrapper';
import EditProjectDialog from '../create_or_edit_project_dialog';
import {
  AppContentProps,
  ProjectMatchParams,
  ProjectsFolder,
  Stage,
  StageType,
} from '../../../common/types';
import StatsCount from '../stats_count';
import ImportDialog from '../../references/import_dialog';
import { ProjectSummary } from './project_summary';
import { DashboardCardWithChevron } from './dashboard_card';
import DashboardStageCard from './dashboard_stage_card';
import ExportDialog from './export_dialog';
import UnassignedPDFsBanner from '../../references/unassigned_attachments/unassigned_pdfs_banner';
import { centerItemCss } from '../../../common/styles';

interface IProjectDashboardProps extends AppContentProps<ProjectMatchParams> {}

const ProjectFragment = loader('../../../graphql/project_fragment.gql');
const GetProjectStatusQuery = gql`
  ${ProjectFragment}
  query GetProjectStatusQuery($id: uuid!) {
    project: project_by_pk(id: $id) {
      ...ProjectFragment
      created_by_user {
        id
        name
      }
      stages(order_by: { order_number: asc }) {
        id
        name
        completed
        study_pools {
          id
          stage_id
          study_pool_team_members {
            study_pool_id
            team_member_id
            team_member {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
        reference_count: stage_references_aggregate(
          where: { reference: { deleted_at: { _is_null: true } } }
        ) {
          aggregate {
            count
          }
        }
        reference_with_pdf_count: stage_reference_attachments_aggregate {
          aggregate {
            count
          }
        }
        studies_by_status {
          inclusion_status
          count
        }
        tasks_aggregate(where: { task_type: { _eq: screening }, deleted_at: { _is_null: true } }) {
          aggregate {
            count
          }
        }
        type
      }
      team_members_aggregate(where: { role: { _eq: screener } }) {
        aggregate {
          count
        }
      }
      references_aggregate(where: { deleted_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }
      duplicate_references_aggregate: references_aggregate(
        where: { deleted_at: { _is_null: false }, removal_reason: { _eq: "is_duplicate" } }
      ) {
        aggregate {
          count
        }
      }
      references_with_pdf: references_aggregate(
        where: {
          deleted_at: { _is_null: true }
          reference_attachments: { key: { _is_null: false } }
        }
      ) {
        aggregate {
          count
        }
      }
      unassigned_pdf_count: reference_attachments_aggregate(
        where: { reference_id: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
    }
    folders: project_folder(where: { deleted_at: { _is_null: true } }) {
      id
      name
      status
      deleted_at
    }
  }
`;

const ProjectSummaryCss = css`
  background-color: ${Colors.LIGHT_GRAY1};
  border-radius: 5px;
`;

const ProjectDashboardGridCss = css`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
`;

const ProjectDashboard: React.FC<IProjectDashboardProps> = ({ match, history }) => {
  const {
    params: { projectId },
  } = match;

  const { loading, data } = useQuery(GetProjectStatusQuery, {
    variables: { id: projectId },
    pollInterval: 1500,
  });
  const folders: ProjectsFolder[] = get('folders', data) ?? [];
  const project = get('project', data);
  const projectName = get('name', project);
  const folderId = get('folder.id', project);
  const stages = get('stages', project) ?? [];
  const dueDate = get('due_date', project);
  const referencesCount = get('references_aggregate.aggregate.count', project) ?? 0;
  const referencesWithPDFCount = get('references_with_pdf.aggregate.count', project) ?? 0;
  const unassignedPDFCount = get('unassigned_pdf_count.aggregate.count', project) ?? 0;
  const teamMembersCount = get('team_members_aggregate.aggregate.count', project) ?? 0;
  const duplicateReferencesCount =
    get('duplicate_references_aggregate.aggregate.count', project) ?? 0;

  const stageIdsByType = useMemo(
    () => mapValues((stage: Stage) => stage.id, keyBy('type', stages as Stage[])),
    [stages]
  );

  const referencesSearchArgs = {
    searchArgs: {
      project_id: projectId,
    },
    referencesFilter: { removal_reason: { _is_null: true }, import_task_key: { _eq: null } },
  };

  const [projectsSettingsDialogOpen, setProjectsSettingsDialogOpen] = useState(false);
  const [importReferencesDialogOpen, setImportReferencesDialogOpen] = useState(false);
  const [exportReferencesDialogOpen, setExportReferencesDialogOpen] = useState(false);

  const goToSubmodule = useCallback(
    (submodule: string, queryString?: string) => () => {
      history.push({
        pathname: ['/projects', projectId, submodule].join('/'),
        search: queryString,
      });
    },
    [history, projectId]
  );
  const goToStageSubmodule = useCallback(
    (stageId: string, submodule: string) => () => {
      history.push(['/projects', projectId, 'stages', stageId, submodule].join('/'));
    },
    [history, projectId]
  );

  const openImportReferencesDialog = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setImportReferencesDialogOpen(true);
  }, []);

  const openExportReferencesDialog = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportReferencesDialogOpen(true);
  }, []);

  return loading ? (
    <Spinner css={centerItemCss} />
  ) : (
    <PageContentWrapper className="px-2">
      <H1 className="my-5 font-normal">{projectName}</H1>
      {unassignedPDFCount > 0 && (
        <UnassignedPDFsBanner count={unassignedPDFCount} projectId={projectId} />
      )}
      <div css={ProjectSummaryCss}>
        <ProjectSummary
          onEditProject={() => setProjectsSettingsDialogOpen(true)}
          project={project}
        />
      </div>
      <div className="mt-8" css={ProjectDashboardGridCss}>
        <div>
          {stages.map((stage: Stage) => (
            <DashboardStageCard key={stage.id} projectId={projectId} stage={stage} />
          ))}
        </div>
        <div>
          <DashboardCardWithChevron
            name={<Trans>References</Trans>}
            onClick={goToSubmodule('references')}
          >
            <div className="flex justify-between items-center px-6">
              <div className="flex items-center reference-counts">
                <StatsCount className="mr-3" count={referencesCount} />
                <StatsCount
                  className="mr-3"
                  count={`(${referencesWithPDFCount} pdf)`}
                  showIcon={false}
                />
              </div>
              <div className="ml-auto">
                <Button
                  className="mr-2"
                  name="references-import"
                  icon={IconNames.IMPORT}
                  minimal
                  onClick={openImportReferencesDialog}
                >
                  <Trans>Import</Trans>
                </Button>
                <Button icon={IconNames.EXPORT} minimal onClick={openExportReferencesDialog}>
                  <Trans>Export</Trans>
                </Button>
              </div>
            </div>
          </DashboardCardWithChevron>
          {duplicateReferencesCount > 0 && (
            <DashboardCardWithChevron
              name={
                <React.Fragment>
                  <Trans>Duplicated References</Trans>
                  {` (${duplicateReferencesCount})`}
                </React.Fragment>
              }
              onClick={goToSubmodule('references', 'duplicatesOnly=1')}
            />
          )}
          <DashboardCardWithChevron
            name={
              <React.Fragment>
                <Trans>Team</Trans>
                {` (${teamMembersCount})`}
              </React.Fragment>
            }
            onClick={goToSubmodule('team-members')}
          />
          {stageIdsByType[StageType.PreliminaryScreening] && (
            <DashboardCardWithChevron
              name={<Trans>Preliminary Screening Instruction</Trans>}
              onClick={goToStageSubmodule(
                stageIdsByType[StageType.PreliminaryScreening],
                'instructions'
              )}
            />
          )}
          {stageIdsByType[StageType.TitlesAbstractScreening] && (
            <DashboardCardWithChevron
              name={<Trans>Title and Abstract Screening Instruction</Trans>}
              onClick={goToStageSubmodule(
                stageIdsByType[StageType.TitlesAbstractScreening],
                'instructions'
              )}
            />
          )}
          {stageIdsByType[StageType.FullTextScreening] && (
            <DashboardCardWithChevron
              name={<Trans>Full Text Screening Instruction</Trans>}
              onClick={goToStageSubmodule(
                stageIdsByType[StageType.FullTextScreening],
                'instructions'
              )}
            />
          )}
          {(stageIdsByType[StageType.PreliminaryScreening] ||
            stageIdsByType[StageType.TitlesAbstractScreening] ||
            stageIdsByType[StageType.FullTextScreening]) && (
            <DashboardCardWithChevron
              name={<Trans>PRISMA</Trans>}
              onClick={goToSubmodule('prisma')}
            />
          )}
        </div>
      </div>
      <EditProjectDialog
        dueDate={dueDate}
        isOpen={projectsSettingsDialogOpen}
        onClose={() => setProjectsSettingsDialogOpen(false)}
        projectId={projectId}
        projectName={projectName}
        folderId={folderId}
        folders={folders}
      />
      <ImportDialog
        isOpen={importReferencesDialogOpen}
        onClose={() => setImportReferencesDialogOpen(false)}
        projectId={projectId}
      />
      <ExportDialog
        isOpen={exportReferencesDialogOpen}
        onClose={() => setExportReferencesDialogOpen(false)}
        projectId={projectId}
        allReferencesCount={referencesCount}
        referencesSearchArgs={referencesSearchArgs}
      />
    </PageContentWrapper>
  );
};

export default ProjectDashboard;
