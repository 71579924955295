/** @jsx jsx */
import {
  Alert,
  Alignment,
  Button,
  Checkbox,
  Classes,
  Dialog,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
  Spinner,
  Switch,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';
import React, { Fragment, useCallback, useState } from 'react';
import { gray1ColorCss, lightGray3bg } from '../../../common/styles';
import { UserKeyword } from '../../../common/types';
import { useKeycloak } from '../../../keycloak';
import { useI18n } from '../../../lib/utils';
import ErrorScreen from '../../common/error_screen';
import KeywordsTree from '../keywords_tree';
import { useKeywordsListQuery } from '../keywords_utils';
import KeywordsImportDialogTable from './keywords_import_dialog_table';

const KeywordsSetFragment = loader('../../../graphql/keywords_set_fragment.gql');

const GetKeywordsQuery = gql`
  query GetKeywordsSetsQuery {
    domains: form_domains_and_variables {
      domain
      variables
    }
    keywords_set {
      ...KeywordsSetFragment
    }
  }
  ${KeywordsSetFragment}
`;

const dialogCss = css`
  height: 75vh;
`;

const dialogBodyCss = css`
  margin-bottom: 20px;
`;

const paddingXCss = css`
  padding-left: 20px;
  padding-right: 20px;
`;

const grayBgWithPaddingXCss = css`
  ${paddingXCss}
  ${lightGray3bg}
`;

interface IKeywordsImportDialogProps {
  onClose: () => void;
  onImport: (selectedKeywords: UserKeyword[], overwriteExisting: boolean) => void;
  defaultDomain?: string;
  defaultVariable?: string;
}

const KeywordsImportDialog: React.FC<IKeywordsImportDialogProps> = ({
  defaultDomain,
  defaultVariable,
  onClose,
  onImport,
}) => {
  const i18n = useI18n();
  const {
    user: { id: userId },
    isAdmin,
  } = useKeycloak();

  const [onlyPersonalSets, setOnlyPersonalSets] = useState<boolean>(false);

  const additionalFilterFn = useCallback(
    (keyword: UserKeyword) => keyword.user_id === userId,
    [userId]
  );

  const {
    activeTreeItem: { domain: currentDomain, variable: currentVariable },
    domains,
    filteredKeywords,
    error,
    keywordsToShow,
    loading,
    refetch,
    searchQuery,
    setActiveTreeItem,
    setSearchQuery,
  } = useKeywordsListQuery({
    additionalFilterFn,
    defaultDomain,
    defaultVariable,
    useAdditionalFilter: onlyPersonalSets,
    query: GetKeywordsQuery,
  });

  const [overwriteExisting, setOverwriteExisting] = useState<boolean>(false);
  const [overwriteConfirmationAlertOpen, setOverwriteConfirmationAlertOpen] =
    useState<boolean>(false);
  const [selectedKeywordsSets, setSelectedKeywordsSets] = useState(
    Immutable.Map<string, UserKeyword>()
  );

  const toggleOverwriteExisting = useCallback(
    () => setOverwriteExisting(!overwriteExisting),
    [overwriteExisting]
  );

  const toggleOnlyPersonalSets = useCallback(
    () => setOnlyPersonalSets(!onlyPersonalSets),
    [onlyPersonalSets]
  );

  const toggleKeywordsSet = useCallback(
    (keyword: UserKeyword) => {
      const id = keyword.id!;
      if (selectedKeywordsSets.get(id) ?? false) {
        setSelectedKeywordsSets(selectedKeywordsSets.delete(keyword.id!));
      } else {
        setSelectedKeywordsSets(selectedKeywordsSets.set(id, keyword));
      }
    },
    [selectedKeywordsSets]
  );

  const handleImport = useCallback(() => {
    onImport([...selectedKeywordsSets.values()], overwriteExisting);
    onClose();
  }, [overwriteExisting, onClose, onImport, selectedKeywordsSets]);

  const handleImportClicked = useCallback(() => {
    if (overwriteExisting) {
      setOverwriteConfirmationAlertOpen(true);
    } else {
      handleImport();
    }
  }, [handleImport, overwriteExisting, setOverwriteConfirmationAlertOpen]);

  const handleTreeItemClicked = ({ domain, variable }) => {
    setActiveTreeItem({ domain, variable });
  };

  return (
    <Dialog
      canOutsideClickClose={false}
      className="w-2/3"
      css={dialogCss}
      icon={IconNames.IMPORT}
      isCloseButtonShown
      isOpen
      onClose={onClose}
      title={<Trans>Import keyword set</Trans>}
    >
      <div
        className={`${Classes.DIALOG_BODY} border-b border-solid m-0 flex flex-col overflow-auto`}
        css={dialogBodyCss}
      >
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorScreen error={error} retry={refetch} />
        ) : (
          <Fragment>
            <div
              className="flex flex-row items-center justify-between py-3"
              css={grayBgWithPaddingXCss}
            >
              <InputGroup
                className="flex-grow mr-5 last:mr-0"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder={i18n._(t`Search...`)}
                rightElement={<Icon className="m-2" icon="search" iconSize={16} />}
                value={searchQuery}
              />
              {!isAdmin && (
                <div className="flex flex-row items-center">
                  <Switch
                    alignIndicator={Alignment.RIGHT}
                    checked={onlyPersonalSets}
                    className="m-0"
                    label={i18n._(t`Show only personal sets`)}
                    onChange={toggleOnlyPersonalSets}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-grow flex-row items-stretch overflow-auto">
              <div
                className="bg-white border-r border-solid overflow-auto"
                css={{ width: '300px' }}
              >
                <KeywordsTree
                  currentDomain={currentDomain}
                  currentVariable={currentVariable}
                  domainItems={domains}
                  keywords={filteredKeywords}
                  onItemClicked={handleTreeItemClicked}
                />
              </div>
              <div className="flex-grow">
                {isEmpty(keywordsToShow) ? (
                  <NonIdealState
                    icon={IconNames.SEARCH}
                    title={i18n._(t`No keywords sets found`)}
                  />
                ) : (
                  <KeywordsImportDialogTable
                    keywordsToShow={keywordsToShow}
                    selectedKeywords={selectedKeywordsSets}
                    toggleKeyword={toggleKeywordsSet}
                  />
                )}
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-between py-3"
              css={grayBgWithPaddingXCss}
            >
              <Trans>Selected sets</Trans>
              {': '}
              {selectedKeywordsSets.size}
            </div>
          </Fragment>
        )}
      </div>
      <div className={`${Classes.DIALOG_FOOTER} flex flex-row items-center justify-between`}>
        <Checkbox className="mb-0" checked={overwriteExisting} onChange={toggleOverwriteExisting}>
          <Trans>Overwrite existing set</Trans>
        </Checkbox>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon={IconNames.CROSS}
            intent={Intent.DANGER}
            onClick={onClose}
            text={<Trans>Cancel</Trans>}
          />
          <Button
            disabled={selectedKeywordsSets.isEmpty()}
            icon={IconNames.IMPORT}
            intent={Intent.PRIMARY}
            onClick={handleImportClicked}
            text={<Trans>Import keywords</Trans>}
          />
        </div>
      </div>
      <Alert
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Overwrite keywords`)}
        intent={Intent.PRIMARY}
        isOpen={overwriteConfirmationAlertOpen}
        onCancel={() => setOverwriteConfirmationAlertOpen(false)}
        onConfirm={handleImport}
      >
        {defaultDomain && defaultVariable ? (
          <React.Fragment>
            <p className="font-bold mb-3">
              <Trans>
                Are you sure to overwrite all keywords in variable {defaultVariable}, domain{' '}
                {defaultDomain}?
              </Trans>
            </p>
            <p css={gray1ColorCss}>
              <Trans>
                All existing keywords in variable {defaultVariable}, domain {defaultDomain} will be
                deleted and overwritten with selected keywords set(s).
              </Trans>
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="font-bold mb-3">
              <Trans>Are you sure to overwrite all keywords?</Trans>
            </p>
            <p css={gray1ColorCss}>
              <Trans>
                All existing keywords will be deleted and overwritten with selected keywords set(s).
              </Trans>
            </p>
          </React.Fragment>
        )}
      </Alert>
    </Dialog>
  );
};

export default KeywordsImportDialog;
