import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React from 'react';
import ConfirmationDialog from '../../common/confirmation_dialog';

interface IRemoveNoReportStatusConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RemoveNoReportStatusConfirmation: React.FC<IRemoveNoReportStatusConfirmationProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <ConfirmationDialog
      title={<Trans>Remove "No PDF" status</Trans>}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmBtnText={<Trans>Remove "No PDF" status</Trans>}
      confirmBtnIntent={Intent.SUCCESS}
      icon={IconNames.UNDO}
    >
      <div className="mb-2">
        <Trans>
          This will remove the "No report retrieved" status. Affected references will be available
          for distribution in Full text screening.
        </Trans>
      </div>
    </ConfirmationDialog>
  );
};

export default RemoveNoReportStatusConfirmation;
