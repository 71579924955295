/** @jsx jsx */
import { jsx } from '@emotion/core';
import Immutable from 'immutable';
import { Button, Colors, Divider, Icon, Intent, NonIdealState, Tag } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { compose, filter, get, isNil, keyBy, map, noop, propEq, toArray, uniq } from 'lodash/fp';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import ScreeningForm from './screening_form';
import {
  FTScreeningCriteria,
  InclusionStatus,
  ReferenceLog,
  ScreeningCriteriaResult,
  ScreeningForm as IScreeningForm,
  ScreeningResult,
  Stage,
} from '../../../common/types';
import { useCurrCallback, usePrevious, useSetState } from '../../../lib/utils';
import { TReferenceData } from '..';
import {
  getReferenceComment,
  getSelectedFTScreeningCriteria,
  TTaskResult,
} from '../../../lib/task_helpers';
import FTScreen from './ft_screen';
import { useKeycloak } from '../../../keycloak';
import { IconNames } from '@blueprintjs/icons';
import { DecisionFilter } from '../../../apollo/screening_state';
import useTaskResultDataUpdaters from '../../hooks/use_task_result_data_updaters';
import { TScreeningTag } from '../../references/admin';
import ReferenceCommentsByStage from '../../references/reference_comments_by_stage';
import MyReferenceComment from '../../references/my_reference_comment';
import ScreeningTags from '../screening_tags';
import FTDecisionLabels from './ft_decision_labels';
import {
  collapseContainerCss,
  CollapsibleSection,
  CollapsibleSections,
  titleCss,
  useCollapsibleSections,
} from '../../common/collapsible_sections';
import NavAndDecisionControls from '../navigation_and_decision_controls';

export function criteriaMapper(
  criteria: Immutable.Set<FTScreeningCriteria>
): ScreeningCriteriaResult[] {
  return criteria.toArray().map(({ id, inclusionStatus }) => ({
    id,
    answer: inclusionStatus === InclusionStatus.Included ? 'yes' : 'no',
  }));
}

export interface IFTScreeningProps {
  projectId: string;
  stageId: string;
  form: IScreeningForm;
  onUpdateTaskResultData: (
    resultData: Partial<ScreeningResult>,
    logs?: Partial<ReferenceLog>[]
  ) => Promise<any>;
  loadNextReference: () => Promise<any>;
  screeningCompleted: boolean;
  decisionFilter: DecisionFilter;
  closeFocusMode: () => void;
  screeningTags: TScreeningTag[];
  handleUnreview: () => void;
  onUpdateComment: (data: { comment: string; id?: string }) => void;
  onDeleteComment: (commentId: string) => void;
  projectStages: Pick<Stage, 'id' | 'name' | 'order_number'>[];
  shiftActiveReference: (direction: -1 | 1) => void;
  reference?: TReferenceData;
  savingResult?: boolean;
  taskResult?: TTaskResult;
  navigateToNextReference?: () => Promise<any>;
  navigateToPrevReference?: () => Promise<any>;
}

interface IFTScreeningState {
  selectedCriteria: Immutable.Set<FTScreeningCriteria>;
}

const FTScreening: React.FC<IFTScreeningProps> = ({
  projectId,
  stageId,
  reference,
  form,
  onUpdateTaskResultData,
  loadNextReference,
  screeningCompleted,
  savingResult,
  taskResult,
  decisionFilter,
  handleUnreview,
  closeFocusMode,
  navigateToNextReference,
  navigateToPrevReference,
  screeningTags,
  onUpdateComment,
  onDeleteComment,
  projectStages,
  shiftActiveReference,
}) => {
  const { user } = useKeycloak();
  const { persistUpdatedTaskResult, updateTags } = useTaskResultDataUpdaters(
    onUpdateTaskResultData,
    taskResult
  );

  const [state, setState] = useSetState<IFTScreeningState>({
    selectedCriteria: Immutable.Set<FTScreeningCriteria>(
      getSelectedFTScreeningCriteria(form, taskResult)
    ),
  });
  const { selectedCriteria } = state;
  const { expandedSections, toggleSection } = useCollapsibleSections(['criteria']);
  const currentComment = reference ? getReferenceComment(reference, taskResult) : undefined;
  const currentInclusionsStatus = taskResult?.result.inclusionStatus;

  const updateSelectedCriteria = useCallback(
    (newCriteria: FTScreeningCriteria): Immutable.Set<FTScreeningCriteria> => {
      const { inclusionStatus } = newCriteria;
      switch (inclusionStatus) {
        case InclusionStatus.Included:
          const filteredCriteria = selectedCriteria.filter(
            propEq('inclusionStatus', InclusionStatus.Included)
          );
          return filteredCriteria.has(newCriteria)
            ? filteredCriteria.remove(newCriteria)
            : filteredCriteria.add(newCriteria);
        case InclusionStatus.Excluded:
          return selectedCriteria.clear().add(newCriteria);
        default:
          // do nothing
          return selectedCriteria;
      }
    },
    [selectedCriteria]
  );

  const handleCriteriaSelect = useCallback(
    (criteria: FTScreeningCriteria) => {
      const updatedSelectedCriteria = updateSelectedCriteria(criteria);
      setState({
        selectedCriteria: updatedSelectedCriteria,
      });

      // persist selected criteria if either there is no inclusion status assigned to the reference
      if (currentInclusionsStatus == null) {
        persistUpdatedTaskResult({
          criteria: criteriaMapper(updatedSelectedCriteria),
        });
      }
    },
    [setState, persistUpdatedTaskResult, updateSelectedCriteria, currentInclusionsStatus]
  );
  const { form: formData } = form;
  const referenceId = reference?.id;
  const referenceIdPrev = usePrevious(referenceId);

  const previousComments = useMemo(() => {
    // FIXME: this is not perfect: once refs reaches FT stage and has some comments added there, it
    // will list those comments when that same reference is viewed in TiAb screening view (since stage
    // id will be different)
    return filter(
      ({ stage_id: commentStageId, task_id }) =>
        // show comments from admin (task_id = null) or comments from other stages than this one
        task_id == null || commentStageId !== stageId,
      reference?.reference_comments
    );
  }, [reference?.reference_comments, stageId]);

  const handleEraseInclusionDecision = useCallback(
    (criteria: FTScreeningCriteria) => {
      if (reference == null) return;
      // remove the task result on removal of the last remaining selected criteria
      selectedCriteria.size === 1 ? handleUnreview() : handleCriteriaSelect(criteria);
    },
    [handleUnreview, handleCriteriaSelect, reference, selectedCriteria]
  );

  const inclusionDecisionLabels = useMemo(() => {
    if (taskResult) {
      const { inclusionStatus, criteria: resultCriteria } = taskResult.result;
      if (inclusionStatus == null) return null;

      if ([InclusionStatus.Included, InclusionStatus.Excluded].includes(inclusionStatus)) {
        const isIncluded = inclusionStatus === InclusionStatus.Included;
        const formCriteria: FTScreeningCriteria[] =
          get(`form.${isIncluded ? 'inclusion' : 'exclusion'}`, form) ?? [];
        const resultCriteriaMap = keyBy('id', resultCriteria);
        const selectedCriteria = formCriteria.filter(({ id }) => id in resultCriteriaMap);

        return (
          <FTDecisionLabels
            criteria={selectedCriteria}
            isIncluded={isIncluded}
            screeningCompleted={screeningCompleted}
            onEraseInclusionDecision={handleEraseInclusionDecision}
          />
        );
      }
    }
  }, [handleEraseInclusionDecision, taskResult, form, screeningCompleted]);

  const handleDecision = useCurrCallback(
    (decision: InclusionStatus.Included | InclusionStatus.Excluded, _evt) => {
      if (reference == null) return;
      const decisionCodes = compose(
        uniq,
        map('code'),
        filter(propEq('inclusionStatus', decision))
      )(toArray(selectedCriteria));

      const logsData: Partial<ReferenceLog>[] = [
        {
          reference_id: reference.id,
          data: {
            type: 'reviewer_decision_applied',
            userId: user.id,
            stageId,
            decision,
            decisionCodes,
          },
        },
      ];
      persistUpdatedTaskResult(
        {
          inclusionStatus: decision,
          criteria: criteriaMapper(selectedCriteria),
        },
        logsData
      ).then(() => loadNextReference());
    },
    [persistUpdatedTaskResult, selectedCriteria, reference, user, stageId, loadNextReference]
  );

  // tags change is saved right away to allow adding tags without making a decision
  const handleChangedTags = useCallback(
    (tagIds: string[]) => {
      if (reference == null) return;

      updateTags({
        tagIds,
        stageId,
        user,
        referenceId: reference.id,
      });
    },
    [updateTags, reference, user, stageId]
  );

  // reset local state when reference is switched
  useEffect(() => {
    if (referenceId !== referenceIdPrev) {
      setState({
        selectedCriteria: Immutable.Set<FTScreeningCriteria>(
          getSelectedFTScreeningCriteria(form, taskResult)
        ),
      });
    }
  }, [referenceId, referenceIdPrev, taskResult, form, setState]);

  const excludeEnabled = selectedCriteria.some(propEq('inclusionStatus', InclusionStatus.Excluded));
  const includeEnabled =
    !excludeEnabled && selectedCriteria.some(propEq('inclusionStatus', InclusionStatus.Included));

  return (
    <Fragment>
      {isNil(reference) && decisionFilter === 'to_review' ? (
        <NonIdealState
          title={<Trans>All tasks have been completed!</Trans>}
          action={
            <Button
              icon={IconNames.ARROW_LEFT}
              text={<Trans>Reference list</Trans>}
              onClick={closeFocusMode}
            />
          }
        />
      ) : isNil(reference) ? (
        <NonIdealState
          icon={<Icon icon={IconNames.DOCUMENT} color={Colors.GRAY1} iconSize={30} />}
          title={<Trans>There are no references in selected category</Trans>}
        />
      ) : (
        <FTScreen
          projectId={projectId}
          stageId={stageId}
          reference={reference}
          closeFocusMode={closeFocusMode}
          navigateToPrevReference={navigateToPrevReference}
          navigateToNextReference={navigateToNextReference}
          sidebarContent={
            <CollapsibleSections>
              <CollapsibleSection
                id="criteria"
                isOpen={expandedSections.includes('criteria')}
                onToggle={toggleSection}
                title={<Trans>Criteria</Trans>}
                titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                titleCss={titleCss}
                collapseContainerCss={collapseContainerCss}
                collapseContainerClass="border-b"
              >
                <ScreeningForm
                  form={formData}
                  selectedCriteria={selectedCriteria}
                  onCriteriaSelect={handleCriteriaSelect}
                  readOnly={screeningCompleted}
                />
              </CollapsibleSection>
              <CollapsibleSection
                id="comments"
                isOpen={expandedSections.includes('comments')}
                onToggle={toggleSection}
                title={
                  <div className="flex items-center">
                    <span css={{ lineHeight: '1.25rem' }}>
                      <Trans>Comments</Trans> ({previousComments.length + (currentComment ? 1 : 0)})
                    </span>{' '}
                    <Tag className="ml-2">{taskResult?.result.tags?.length ?? 0}</Tag>
                  </div>
                }
                titleClass="text-gray-700 px-4 py-1 text-xl h-12 border-b"
                titleCss={titleCss}
                collapseContainerCss={collapseContainerCss}
                collapseContainerClass="border-b"
              >
                <div className="p-4">
                  <MyReferenceComment
                    comment={currentComment}
                    onDelete={screeningCompleted ? undefined : onDeleteComment}
                    onUpdate={screeningCompleted ? undefined : onUpdateComment}
                  />
                </div>
                <div className="p-4">
                  <ReferenceCommentsByStage comments={previousComments} stages={projectStages} />
                </div>
                <Divider className="m-0" />
                <div className="p-4">
                  <ScreeningTags
                    tags={screeningTags}
                    selectedTagIds={taskResult?.result.tags}
                    onTagsChange={handleChangedTags}
                    readOnly={screeningCompleted}
                  />
                </div>
              </CollapsibleSection>
            </CollapsibleSections>
          }
          decisionControls={
            <Fragment>
              <Button
                fill
                className="mr-4"
                disabled={screeningCompleted || !includeEnabled}
                small
                intent={Intent.SUCCESS}
                text={<Trans>Include</Trans>}
                loading={savingResult}
                onClick={handleDecision(InclusionStatus.Included)}
                data-testid="decision-control"
              />
              <Button
                fill
                disabled={screeningCompleted || !excludeEnabled}
                small
                intent={Intent.DANGER}
                text={<Trans>Exclude</Trans>}
                loading={savingResult}
                onClick={handleDecision(InclusionStatus.Excluded)}
                data-testid="decision-control"
              />
            </Fragment>
          }
          decisionLabels={inclusionDecisionLabels}
        />
      )}
      <NavAndDecisionControls
        onlyHotkeys
        onCloseFocusMode={closeFocusMode}
        onShiftActiveReference={shiftActiveReference}
        onToggleActiveReferenceSelect={noop}
        onInclude={noop}
        onExclude={noop}
        onPostpone={noop}
        exclusionReasons={[]}
      />
    </Fragment>
  );
};

export default FTScreening;
