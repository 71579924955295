/** @jsx jsx */
import { Colors, Popover, Position } from '@blueprintjs/core';
import { jsx, css } from '@emotion/core';
import { t } from '@lingui/macro';
import { ColorResult, SketchPicker } from 'react-color';
import i18n from '../../i18n';
import { useCurrCallback } from '../../lib/utils';

const containerCss = css`
  width: 160px;

  .color-preview {
    width: 60px;
    height: 30px;
    overflow: hidden;
    padding: 2px;
    background-clip: content-box;
    border: 1px solid ${Colors.GRAY1};
  }
`;

interface IColorPickerProps {
  color: string;
  onChange: (newColor: ColorResult) => void;
}

const ColorPicker: React.FC<IColorPickerProps> = ({ color, onChange }) => (
  <Popover
    content={<SketchPicker color={color} onChangeComplete={onChange} />}
    position={Position.TOP}
  >
    <div css={{ backgroundColor: color }} className="color-preview" />
  </Popover>
);

const LABELS: Record<'neutral' | 'accepted' | 'rejected' | 'conflicts', string> = {
  neutral: i18n._(t`Neutral`),
  accepted: `${i18n._(t`Accepted`)} (${i18n._(t`In`).toUpperCase()})`,
  rejected: `${i18n._(t`Rejected`)} (${i18n._(t`Out`).toUpperCase()})`,
  conflicts: i18n._(t`Conflicts`),
};

interface IStatusColorsPickerProps {
  statusId: 'neutral' | 'accepted' | 'rejected' | 'conflicts';
  primaryColor: string;
  secondaryColor: string;
  onSetColor: ({ type, color }: { type: 'primary' | 'secondary'; color: string }) => void;
}

const StatusColorsPicker: React.FC<IStatusColorsPickerProps> = ({
  statusId,
  primaryColor,
  secondaryColor,
  onSetColor,
}) => {
  const label = LABELS[statusId];
  const handleColorChange = useCurrCallback(
    (type: 'primary' | 'secondary', color: ColorResult) => {
      onSetColor({ type, color: color.hex });
    },
    [onSetColor]
  );

  return (
    <div css={containerCss}>
      <div className="flex w-full flex-row justify-between">
        <ColorPicker onChange={handleColorChange('primary')} color={primaryColor} />
        <ColorPicker onChange={handleColorChange('secondary')} color={secondaryColor} />
      </div>
      <div
        css={{ backgroundColor: primaryColor, color: secondaryColor }}
        className="mt-4 p-2 text-center"
      >
        <span>{label}</span>
      </div>
    </div>
  );
};

export default StatusColorsPicker;
