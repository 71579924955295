import { STAGE_PREFIXES } from './task_helpers';
import { Intent } from '@blueprintjs/core';
import { compose, filter, get, includes, map, omit, omitBy, propEq } from 'lodash/fp';
import { DecisionFilter, TActiveAttributeFilters } from '../apollo/screening_state';
import { InclusionStatus } from '../common/types';

export const convertRefsToReferenceInput = (references) => {
  return references.map(({ result, record }) => ({
    title: result.title,
    attrs: omit(['title'], result),
    record_string: record,
  }));
};

export function decisionFilterToInclusionStatus(
  decisionFilter: DecisionFilter
):
  | InclusionStatus.Included
  | InclusionStatus.Excluded
  | InclusionStatus.Postponed
  | 'to_review'
  | undefined {
  switch (decisionFilter) {
    case 'in':
      return InclusionStatus.Included;
    case 'out':
      return InclusionStatus.Excluded;
    case 'postponed':
      return InclusionStatus.Postponed;
    case 'to_review':
      return 'to_review';
    default:
      return;
  }
}

export function inclusionStatusToIntent(inclusionStatus?: string): Intent {
  switch (inclusionStatus) {
    case 'toReview':
      return Intent.NONE;
    case 'included':
      return Intent.SUCCESS;
    case 'excluded':
      return Intent.DANGER;
    case 'conflict':
      return Intent.WARNING;
    default:
      return Intent.PRIMARY;
  }
}

type DecisionAndCodes = {
  decisions: string[];
  decisionCodes: string[];
};

export function decisionCodesFiltersToDecisionAndCodes(
  filters: TActiveAttributeFilters
): DecisionAndCodes {
  const decisionFilters = [
    `${STAGE_PREFIXES.preliminary}included`,
    `${STAGE_PREFIXES.preliminary}conflict`,
    `${STAGE_PREFIXES.preliminary}toReview`,
    `${STAGE_PREFIXES.titlesAndAbstracts}included`,
    `${STAGE_PREFIXES.titlesAndAbstracts}conflict`,
    `${STAGE_PREFIXES.titlesAndAbstracts}toReview`,
    `${STAGE_PREFIXES.fullText}conflict`,
    `${STAGE_PREFIXES.fullText}toReview`,
  ];
  const decisions = filter((filterName) => get([filterName, 'active'], filters), decisionFilters);
  const decisionCodes = compose(
    map('label'),
    omitBy(
      (filter, filterName) =>
        propEq('active', false)(filter) || includes(filterName, decisionFilters)
    )
  )(filters);

  return {
    decisions,
    decisionCodes,
  } as DecisionAndCodes;
}
