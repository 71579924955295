import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, IButtonProps, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { get } from 'lodash/fp';
import React, { FunctionComponent, useCallback } from 'react';
import {
  INITIAL_ACTIVE_PAGE,
  INITIAL_NUM_PAGES,
  INITIAL_PDF_ROTATION_ANGLE,
  INITIAL_PDF_SCALE,
} from '../../apollo/pdf_viewer_state';

const UpdatePDFViewerStateMutation = loader('../../graphql/local/update_pdf_viewer_state.gql');
const ZOOM_STEP = 0.25;

const GetPDFScaleQuery = gql`
  query getPDFScaleQuery {
    PDFViewer @client {
      pdfScale
      activePage
    }
  }
`;

const SearchQuery = gql`
  query PDFSearch {
    PDFViewer @client {
      search {
        isOpen
      }
    }
  }
`;

interface IZoomToggleRendererProps {
  onChangeZoom: (scale: number) => void;
  currentZoom: number;
  className?: string;
}

export interface IZoomToggleProps {
  renderer: FunctionComponent<IZoomToggleRendererProps>;
  className?: string;
}

export const ZoomToggle: React.FC<IZoomToggleProps> = ({ renderer: Renderer, className }) => {
  const { data } = useQuery(GetPDFScaleQuery);
  const [updatePDFViewerState] = useMutation(UpdatePDFViewerStateMutation);
  const pdfScale = get('PDFViewer.pdfScale', data) || INITIAL_PDF_SCALE;
  const activePage = get('PDFViewer.activePage', data) ?? INITIAL_ACTIVE_PAGE;
  const handleZoomUpdate = useCallback(
    (newScale: number) => {
      updatePDFViewerState({
        variables: {
          data: {
            pdfScale: newScale,
            scrollToPage: activePage,
          },
        },
      });
    },
    [updatePDFViewerState, activePage]
  );

  return <Renderer currentZoom={pdfScale} onChangeZoom={handleZoomUpdate} className={className} />;
};

interface IZoomControlProps extends IButtonProps {
  zoomKind: 'in' | 'out' | 'reset';
  currentZoom: number;
  onChangeZoom: (newZoom: number) => void;
  zoomStep?: number;
}

export const ZoomControl: React.FC<IZoomControlProps> = (props) => {
  const { zoomKind, onChangeZoom, currentZoom, zoomStep = ZOOM_STEP, ...btnProps } = props;

  const handleClick = () => {
    const newZoom =
      zoomKind === 'reset'
        ? INITIAL_PDF_SCALE
        : currentZoom + zoomStep * (zoomKind === 'in' ? 1 : -1);

    onChangeZoom(newZoom);
  };

  return <Button {...btnProps} onClick={handleClick} />;
};

const GetPagesDataQuery = gql`
  query getPDFPagesQuery {
    PDFViewer @client {
      activePage
      numPages
    }
  }
`;

interface IPageTogglesRenderProps {
  activePage: number;
  numPages: number;
  onNext: () => void;
  onPrev: () => void;
  onChangePage: (pageNum: number) => void;
}

export interface IPageTogglesProps {
  renderer: FunctionComponent<IPageTogglesRenderProps>;
}

export const PageToggles: React.FC<IPageTogglesProps> = ({ renderer }) => {
  const { data } = useQuery(GetPagesDataQuery);
  const [updatePDFViewerState] = useMutation(UpdatePDFViewerStateMutation);
  const activePage = get('PDFViewer.activePage', data) ?? INITIAL_ACTIVE_PAGE;
  const numPages = get('PDFViewer.numPages', data) ?? INITIAL_NUM_PAGES;

  const updateActivePage = (pageNum: number) => {
    updatePDFViewerState({
      variables: {
        data: {
          activePage: pageNum,
          scrollToPage: pageNum,
        },
      },
    });
  };

  const onNext = () => {
    updateActivePage(Math.min(numPages, activePage + 1));
  };

  const onPrev = () => {
    updateActivePage(Math.max(1, activePage - 1));
  };

  return renderer({ activePage, numPages, onNext, onPrev, onChangePage: updateActivePage });
};

export const SearchToggle: React.FC<IButtonProps> = (props) => {
  const { data } = useQuery(SearchQuery);
  const [updatePDFViewerState] = useMutation(UpdatePDFViewerStateMutation);

  const isSearchOpen = get('PDFViewer.search.isOpen', data) ?? false;

  const handleTogglePDFSearch = () => {
    updatePDFViewerState({
      variables: {
        data: {
          search: {
            isOpen: !isSearchOpen,
          },
        },
      },
    });
  };

  return (
    <Tooltip className="flex h-full" content={<Trans>Search in PDF file</Trans>}>
      <Button
        active={isSearchOpen}
        icon={IconNames.SEARCH}
        onClick={handleTogglePDFSearch}
        {...props}
      />
    </Tooltip>
  );
};

const GetRotationDataQuery = gql`
  query getPDFRotationAngleQuery {
    PDFViewer @client {
      activePage
      pdfRotationAngle
    }
  }
`;

export const RotationToggle: React.FC<IButtonProps> = (props) => {
  const { data } = useQuery(GetRotationDataQuery);
  const [updatePDFViewerState] = useMutation(UpdatePDFViewerStateMutation);
  const activePage = get('PDFViewer.activePage', data) ?? INITIAL_ACTIVE_PAGE;
  const pdfRotationAngle = get('PDFViewer.pdfRotationAngle', data) ?? INITIAL_PDF_ROTATION_ANGLE;

  const toggleRotationAngle = () => {
    updatePDFViewerState({
      variables: {
        data: {
          pdfRotationAngle: (pdfRotationAngle + 90) % 360,
          scrollToPage: activePage,
        },
      },
    });
  };

  return (
    <Tooltip className="flex h-full" content={<Trans>Rotate PDF file</Trans>}>
      <Button
        large
        minimal
        icon={IconNames.ROTATE_DOCUMENT}
        onClick={toggleRotationAngle}
        {...props}
      />
    </Tooltip>
  );
};
