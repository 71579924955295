/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  map,
  get,
  compose,
  omitBy,
  keys,
  isEqual,
  sum,
  values,
  pick,
  reject,
  propEq,
  orderBy,
} from 'lodash/fp';
import { StudyDistribution, ScreeningType, Study, Role } from '../../../common/types';
import {
  Classes,
  Button,
  Divider,
  RadioGroup,
  Radio,
  Alert,
  Intent,
  Icon,
  Colors,
  NumericInput,
} from '@blueprintjs/core';
import { Trans, t } from '@lingui/macro';
import { useI18n } from '../../../lib/utils';
import CardWithMarker from '../../common/card_with_marker';
import { useCallback, useMemo, FormEvent, useEffect, useState } from 'react';
import ReferencesDistributionProportional from './references_distribution_proportional';
import ReferencesDistributionIndividual from './references_distribution_individual';
import { useSetState } from '../../../lib/utils';
import { getScreeningTypeInfo } from '../helpers';
import {
  getDistributionCountBase,
  getStudiesPerMember,
  getTeamMemberLimits,
  TScreenerData,
  TTeamMemberTaskLimits,
} from '../../../lib/distribution_helpers';
import { IconNames } from '@blueprintjs/icons';
import Dialog from '../../common/dialog';
import {TStudyToDistribute} from '../../../lib/study_helpers';

const dialogCss = css`
  width: 40vw;

  .${Classes.RADIO}.${Classes.CONTROL} {
    margin-bottom: 0;
  }
`;

type DialogState = {
  distributionType: StudyDistribution;
  selectedMembers: string[];
  studiesPerMember: { [memberId: string]: number };
  memberLocks: { [memberId: string]: boolean };
};

const getInitialState = (
  teamMembers: TScreenerData[],
  countBase: number,
  limit: number,
  memberLimits: TTeamMemberTaskLimits
) => {
  // don't assign any studies to manager role member initially
  const withoutManagers = reject(propEq('role', Role.Manager), teamMembers);
  const studiesPerMember = getStudiesPerMember(
    map(get('id'), withoutManagers),
    countBase,
    limit,
    memberLimits
  );

  return {
    distributionType: StudyDistribution.Proportional,
    selectedMembers: compose(keys, omitBy(isEqual(0)))(studiesPerMember),
    studiesPerMember,
    memberLocks: {},
  };
};

interface IReferencesDistributionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (
    studiesPerMember: { [memberId: string]: number },
    distributionType: StudyDistribution,
    studies: TStudyToDistribute[]
  ) => void;
  studies: TStudyToDistribute[];
  teamMembers: TScreenerData[];
  screeningType: ScreeningType;
  isRedistribution?: boolean;
}

const ReferencesDistributionDialog: React.FC<IReferencesDistributionDialogProps> = ({
  isOpen,
  onClose,
  onApply,
  studies,
  teamMembers,
  screeningType,
  isRedistribution,
}) => {
  const i18n = useI18n();
  const [studiesCount, setStudiesCount] = useState(studies.length);
  const teamMembersOrdered = useMemo(() => {
    // place manager members at bottom
    return orderBy('role', 'desc', teamMembers);
  }, [teamMembers]);
  const memberLimits = useMemo(
    () => getTeamMemberLimits(teamMembersOrdered, studies, isRedistribution),
    [teamMembersOrdered, studies, isRedistribution]
  );

  const countBase = useMemo(
    () => getDistributionCountBase(studiesCount, screeningType.screeners_no, isRedistribution),
    [studiesCount, screeningType.screeners_no, isRedistribution]
  );
  const [state, setState] = useSetState<DialogState>(
    getInitialState(teamMembersOrdered, countBase, studiesCount, memberLimits)
  );
  const { distributionType, selectedMembers, studiesPerMember, memberLocks } = state;

  const undistributedCount = useMemo(() => {
    return countBase - sum(values(studiesPerMember));
  }, [studiesPerMember, countBase]);

  const [confirmDistributionAlertOpen, setConfirmDistributionAlertOpen] = useState<boolean>(false);

  const handleStudiesCountChange = useCallback(
    (valueAsNumber: number) => setStudiesCount(valueAsNumber)
  , []);

  const setSelectedMembers = useCallback(
    (selectedMembers: DialogState['selectedMembers']) => {
      setState({
        selectedMembers,
        studiesPerMember: getStudiesPerMember(
          selectedMembers,
          countBase,
          studiesCount,
          memberLimits
        ),
      });
    },
    [setState, countBase, studiesCount, memberLimits]
  );

  const setStudiesPerMember = useCallback(
    (studiesPerMember: DialogState['studiesPerMember']) => {
      setState({ studiesPerMember });
    },
    [setState]
  );

  const setMemberLocks = useCallback(
    (memberLocks: DialogState['memberLocks']) => {
      setState({ memberLocks });
    },
    [setState]
  );

  const handleDistributionTypeChange = useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      const selectedType = evt.currentTarget.value as StudyDistribution;

      setState((current) => {
        const updatedFields =
          selectedType === StudyDistribution.Proportional
            ? {
                distributionType: selectedType,
                studiesPerMember: getStudiesPerMember(
                  current.selectedMembers,
                  countBase,
                  studiesCount,
                  memberLimits
                ),
              }
            : {
                distributionType: selectedType,
              };

        return { ...current, ...updatedFields };
      });
    },
    [setState, teamMembersOrdered, countBase, studiesCount, memberLimits]
  );

  const closeDialog = useCallback(() => {
    setState(getInitialState(teamMembersOrdered, countBase, studiesCount, memberLimits));
    onClose();
  }, [onClose, setState, teamMembersOrdered, countBase, studiesCount, memberLimits]);

  const handleApply = useCallback(async () => {
    await setConfirmDistributionAlertOpen(false);
    onApply(pick(selectedMembers, studiesPerMember), distributionType, studies.slice(0, studiesCount));
    closeDialog();
  }, [closeDialog, onApply, selectedMembers, studiesPerMember, distributionType]);

  useEffect(() => {
    setState(getInitialState(teamMembersOrdered, countBase, studiesCount, memberLimits));
  }, [setState, teamMembersOrdered, countBase, studiesCount, memberLimits]);

  return (
    <Dialog
      css={dialogCss}
      isOpen={isOpen}
      onClose={closeDialog}
      title={<Trans>Distribute references - assignment</Trans>}
    >
      <div className={`${Classes.DIALOG_BODY} m-0 flex flex-col`}>
        <div className="p-4">
          <CardWithMarker>
            <div className="p-3 flex flex-row flex-no-wrap items-center justify-between">
              <div>
                <Trans>To distribute in</Trans>{' '}
                <span className="uppercase">{getScreeningTypeInfo(screeningType.id).label}</span>
              </div>
              <div>
                <NumericInput
                  value={studiesCount}
                  min={1}
                  max={studies.length}
                  onValueChange={handleStudiesCountChange}
                  minorStepSize={null}
                  stepSize={1}
                />
                {/* <Icon icon={IconNames.DOCUMENT} color={Colors.GRAY4} className="ml-1" /> */}
              </div>
            </div>
          </CardWithMarker>
          <div className="flex mt-6 mb-2 items-center">
            <span className="mr-2">
              <Trans>Assignment</Trans>:
            </span>
            <RadioGroup
              onChange={handleDistributionTypeChange}
              selectedValue={distributionType}
              inline
            >
              <Radio
                value={StudyDistribution.Proportional}
                labelElement={<Trans>Proportional</Trans>}
              />
              <Radio
                value={StudyDistribution.Individual}
                labelElement={<Trans>Individual</Trans>}
              />
            </RadioGroup>
          </div>
        </div>
        <Divider className="my-0 mx-4" />
        <div className="p-4">
          {distributionType === StudyDistribution.Proportional ? (
            <ReferencesDistributionProportional
              teamMembers={teamMembersOrdered}
              studiesPerMember={studiesPerMember}
              selectedMembers={selectedMembers}
              onChangeSelectedMembers={setSelectedMembers}
            />
          ) : (
            <ReferencesDistributionIndividual
              teamMembers={teamMembersOrdered}
              memberLocks={memberLocks}
              studiesPerMember={studiesPerMember}
              toDistribute={countBase}
              maxCount={studiesCount}
              undistributedCount={undistributedCount}
              onChangeStudiesPerMember={setStudiesPerMember}
              onChangeMemberLocks={setMemberLocks}
              memberLimits={memberLimits}
            />
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={closeDialog} large text={<Trans>Cancel</Trans>} />
          <Button
            onClick={() => setConfirmDistributionAlertOpen(true)}
            large
            text={<Trans>Distribute</Trans>}
            disabled={undistributedCount > 0}
            intent={Intent.SUCCESS}
          />
        </div>
      </div>
      <Alert
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Distribute studies`)}
        intent={Intent.PRIMARY}
        isOpen={confirmDistributionAlertOpen}
        onCancel={() => setConfirmDistributionAlertOpen(false)}
        onConfirm={handleApply}
      >
        <p className="font-bold mb-3">
          <Trans>Are you sure to distribute studies using {distributionType} method?</Trans>
        </p>
      </Alert>
    </Dialog>
  );
};

export default ReferencesDistributionDialog;
