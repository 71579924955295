/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction } from 'react';
import {
  TActiveKeywordFilters,
  TActiveAttributeFilters,
  TActiveScreeningTagFilters,
  TPdfFilter,
} from '../../../apollo/screening_state';
import { screeningListColHeaderHeight } from '../../../common/styles';
import FiltersHeader from '../../screening/filters_header';
import ReferencesFilters from './references_filters';
import { TDomainReasonsData, TScreeningTag } from '.';
import { IYearsRangeFilterState } from './list';
import { StageType } from '../../../common/types';

interface IFiltersColumnProps {
  onToggle: () => void;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  onResetReferencesSearch?: () => void;
  domains: TDomainReasonsData;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  yearsRangeFilterState: IYearsRangeFilterState;
  setYearsRangeFilterState: Dispatch<SetStateAction<IYearsRangeFilterState>>;
  onlyWithComments: boolean;
  pdfFilter: TPdfFilter;
  onlyWithoutAbstract: boolean;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  projectStageTypes: StageType[];
}

const FiltersColumn: React.FC<IFiltersColumnProps> = ({
  onResetReferencesSearch,
  domains,
  documentTypes,
  yearsFilterData,
  yearsRangeFilterState,
  setYearsRangeFilterState,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  onToggle,
  onlyWithComments,
  pdfFilter,
  onlyWithoutAbstract,
  screeningTags,
  activeScreeningTagFilters,
  projectStageTypes,
}) => {
  return (
    <div className="h-full flex flex-col overflow-auto">
      <div className="flex-none" css={screeningListColHeaderHeight}>
        <FiltersHeader onClearFilters={onResetReferencesSearch} onToggle={onToggle} />
      </div>
      <ReferencesFilters
        domains={domains}
        documentTypes={documentTypes}
        yearsFilterData={yearsFilterData}
        yearsRangeFilterState={yearsRangeFilterState}
        setYearsRangeFilterState={setYearsRangeFilterState}
        activeDecisionCodeFilters={activeDecisionCodeFilters}
        activeDocumentTypeFilters={activeDocumentTypeFilters}
        onlyWithComments={onlyWithComments}
        pdfFilter={pdfFilter}
        onlyWithoutAbstract={onlyWithoutAbstract}
        screeningTags={screeningTags}
        activeScreeningTagFilters={activeScreeningTagFilters}
        projectStageTypes={projectStageTypes}
      />
    </div>
  );
};

export default FiltersColumn;
