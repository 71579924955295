/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button, Icon, PopoverPosition, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React, { useMemo } from 'react';
import { InclusionStatus, ScreeningResult, ScreeningTag } from '../../common/types';
import { useStatusColor } from '../../lib/utils';
import CommentBox from '../references/comment_box';
import ScreeningTags from './screening_tags';
import { isArray, isString } from 'lodash/fp';

interface IConflictDecisionProps {
  inclusionStatus: ScreeningResult['inclusionStatus'];
  screenerName: string;
  screenerDeleted: boolean;
  screeningTags: ScreeningTag[];
  selectedTags?: string[];
  decisionReason?: string | { inclusionStatus: InclusionStatus; reason?: string }[];
  comment?: string;
  onApply?: () => void;
  className?: string;
}

const ConflictDecision: React.FC<IConflictDecisionProps> = ({
  className,
  inclusionStatus,
  screenerDeleted,
  screenerName,
  onApply,
  decisionReason,
  screeningTags,
  selectedTags,
  comment,
}) => {
  const getStatusColor = useStatusColor();

  const decisionReasonsToRender: { inclusionStatus: InclusionStatus; reason?: string }[] =
    useMemo(() => {
      if (decisionReason == null && inclusionStatus != null) {
        return [{ inclusionStatus }];
      }

      if (isString(decisionReason) && inclusionStatus != null) {
        return [
          {
            inclusionStatus,
            reason: decisionReason,
          },
        ];
      }
      return isArray(decisionReason) ? decisionReason : [];
    }, [decisionReason, inclusionStatus]);

  return (
    <div className={className} data-testid="conflict-decision">
      <div
        className="flex flex-row items-center m-4 pb-4 border-b-8"
        css={{ borderColor: getStatusColor(inclusionStatus) }}
      >
        <span className={`flex-1 truncate text-base ${screenerDeleted ? 'text-gray-500' : ''}`}>
          {screenerName}
          {screenerDeleted && (
            <Tooltip
              content={<Trans>Removed from the team</Trans>}
              position={PopoverPosition.RIGHT}
            >
              <Icon className="ml-1" icon={IconNames.FOLLOWER} />
            </Tooltip>
          )}
        </span>
        <Button
          disabled={onApply == null}
          className="w-32"
          text={<Trans>Apply</Trans>}
          onClick={onApply}
        />
      </div>
      <div className="px-4">
        <div className="mb-4">
          {decisionReasonsToRender.map(({ inclusionStatus, reason }, idx) => {
            const statusText =
              inclusionStatus === InclusionStatus.Excluded ? (
                <Trans>Out</Trans>
              ) : inclusionStatus === InclusionStatus.Included ? (
                <Trans>In</Trans>
              ) : (
                ''
              );

            return (
              <div
                key={idx}
                className="flex flex-row flex-no-wrap items-center px-4 py-2 mb-4 bg-white"
                css={{ border: '1px solid #dfe0e1' }}
                style={{
                  color: getStatusColor(inclusionStatus),
                }}
              >
                {reason ? (
                  <React.Fragment>
                    <span className="uppercase mr-2">{statusText}:</span>
                    <span className="flex-1 truncate">{reason}</span>
                  </React.Fragment>
                ) : (
                  <span className="uppercase mr-2">{statusText}</span>
                )}
              </div>
            );
          })}
        </div>
        <CommentBox title={<Trans>Comment</Trans>} className="mb-4" comment={comment} />
        <ScreeningTags tags={screeningTags} selectedTagIds={selectedTags} readOnly />
      </div>
    </div>
  );
};

export default ConflictDecision;
