import _isEmpty from 'lodash/isEmpty';
import { t } from '@lingui/macro';
import i18n from '../i18n';

const phoneAreaCodeRegEx = /^\+(?:[0-9] ?){2,}$/;
const phoneRegex = /^[0-9]{4,}$/;
const emailRegex = /^(([A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+)*)|("[\u0020-\u007E]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const combineValidators = (...validators) => (value) =>
  validators.reduce((acc, validator) => (acc[0] ? validator(value) : acc), [true, null]);

export type TValidator = (value: any) => [boolean, string | null];
export type TValidators = { [name: string]: TValidator };

const StringValidations: { [name: string]: TValidator } = {
  email: (value) => {
    if (emailRegex.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`You have entered invalid e-mail address`)];
    }
  },
  phoneAreaCode: (value) => {
    if (value == null || phoneAreaCodeRegEx.test(value)) {
      return [true, null];
    } else {
      return [
        false,
        i18n._(t`The area code should start with a plus sign, followed by the country code.`),
      ];
    }
  },
  phoneNumber: (value) => {
    if (value == null || phoneRegex.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`Phone number may only contain 0-9 characters.`)];
    }
  },
  passwordMinLength: (value) => {
    if (value.length < 10) {
      return [false, i18n._(t`Password must contain at least 10 characters`)];
    } else {
      return [true, null];
    }
  },
  passwordContainsNumber: (value) => {
    if (/[0-9]+/.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`Password must contain at least 1 number character`)];
    }
  },
  passwordContainsLowerCaseChar: (value) => {
    // matches any lowercase letter char
    if (/\p{Ll}+/u.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`Password must contain at least 1 lowercase character`)];
    }
  },
  passwordContainsUpperCaseChar: (value) => {
    // matches any uppercase letter char
    if (/\p{Lu}+/u.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`Password must contain at least 1 uppercase character`)];
    }
  },

  passwordContainsSpecialChar: (value) => {
    // matches non-letter and non-decimal-digit char
    if (/[^\p{L}\p{Nd}]+/u.test(value)) {
      return [true, null];
    } else {
      return [false, i18n._(t`Password must contain at least 1 special character`)];
    }
  },
};

export default {
  String: StringValidations,
  passwordPolicy: combineValidators(
    StringValidations.passwordMinLength,
    StringValidations.passwordContainsLowerCaseChar,
    StringValidations.passwordContainsUpperCaseChar,
    StringValidations.passwordContainsSpecialChar,
    StringValidations.passwordContainsNumber
  ),
  required: (value): [boolean, string | null] => {
    return _isEmpty(value) ? [false, i18n._(t`This field is required`)] : [true, null];
  },
};
