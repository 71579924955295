import React, { Fragment, ReactNode } from 'react';
import { Button } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';

export interface IEditControlsProps {
  editing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  editDisabled?: boolean;
  saving?: boolean;
  saveDisabled?: boolean;
  saveIcon?: IconName;
  editText?: ReactNode;
}

const EditToggles: React.FC<IEditControlsProps> = ({
  editing,
  onEdit,
  onCancel,
  onSave,
  saving,
  saveDisabled,
  saveIcon,
  editDisabled,
  editText,
}) => (
  <div className="flex flex-row flex-no-wrap">
    {editing ? (
      <Fragment>
        <Button
          loading={saving}
          className="mr-3"
          icon={saveIcon ?? IconNames.FLOPPY_DISK}
          minimal
          onClick={onSave}
          disabled={saveDisabled}
        />
        <Button minimal disabled={saving} icon={IconNames.CROSS} onClick={onCancel} />
      </Fragment>
    ) : (
      <Button
        minimal
        icon={IconNames.EDIT}
        onClick={onEdit}
        disabled={editDisabled}
        text={editText}
      />
    )}
  </div>
);

export default EditToggles;
