/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { memo, forwardRef } from 'react';
import Autosizer from '../common/autosizer';
import { StringFragmentDecorator } from '../../lib/decorated_string';
import { fancyScrollCss } from '../../common/styles';

export interface PageRendererProps {
  pageNum: number;
  scale: number;
  height?: number;
  width?: number;
  customRangeDecorator?: StringFragmentDecorator;
  rotationAngle?: number;
}

interface DocPagesProps {
  pageNums: number[];
  pageRenderer: React.ComponentType<PageRendererProps>;
  customRangeDecorator?: StringFragmentDecorator;
  scale?: number;
  itemSize?: number;
  fitToWidth?: boolean;
  rotationAngle?: number;
}

const DocPages = memo(
  forwardRef((props: DocPagesProps, ref) => {
    const {
      pageNums,
      scale,
      itemSize,
      pageRenderer: Page,
      customRangeDecorator,
      fitToWidth,
      rotationAngle,
    } = props;

    return (
      <Autosizer>
        {memo(({ height, width }: any) => (
          <div
            className="document-pages"
            ref={ref as React.RefObject<HTMLDivElement>}
            css={fancyScrollCss}
            style={{ height, overflow: 'auto' }}
          >
            {pageNums.map((pageNum) => (
              <Page
                key={pageNum}
                pageNum={pageNum}
                scale={fitToWidth ? 1 : scale || 1}
                height={fitToWidth ? undefined : itemSize ? itemSize : height}
                width={fitToWidth ? width - 15 : undefined}
                rotationAngle={rotationAngle}
                customRangeDecorator={customRangeDecorator}
              />
            ))}
          </div>
        ))}
      </Autosizer>
    );
  })
);

export default DocPages;
