import { Trans } from '@lingui/macro';
import { some, compose, filter, maxBy, keyBy, isNil, isEmpty } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Flatten, InclusionStatus, Stage, StageType } from '../../../common/types';
import ConfirmationDialog from '../../common/confirmation_dialog';
import { TReferenceDetailsData } from './reference_details';
import ReferenceStageDecisionSelect from './reference_stage_decision_select';

export type TDecisionOverwriteHandler = (
  decision: { inclusionStatus: InclusionStatus; reasonCodes: string[] },
  currentResult: Flatten<TReferenceDetailsData['stage_results']>
) => void;

interface IDecisionOverwriteDialogProps {
  isOpen: boolean;
  stages: Pick<Stage, 'id' | 'type' | 'order_number' | 'name' | 'forms'>[];
  stageResults: TReferenceDetailsData['stage_results'];
  onClose: () => void;
  onApply: TDecisionOverwriteHandler;
}

const DecisionOverwriteDialog: React.FC<IDecisionOverwriteDialogProps> = ({
  isOpen,
  stages,
  stageResults,
  onApply,
  onClose,
}) => {
  const stageResultsMap = useMemo(() => {
    return keyBy('stage_id', stageResults);
  }, [stageResults]);

  const latestStartedStage: Flatten<typeof stages> | undefined = useMemo(() => {
    return compose(
      maxBy('order_number'),
      filter(({ id }) => some({ stage_id: id }, stageResults))
    )(stages);
  }, [stages, stageResults]);

  const [overwrittenDecision, setOverwrittenDecision] = useState<{
    inclusionStatus: InclusionStatus;
    reasonCodes: string[];
  } | null>(null);

  const handleCancel = useCallback(() => {
    onClose();
    setOverwrittenDecision(null);
  }, [onClose, setOverwrittenDecision]);

  const handleApply = () => {
    if (overwrittenDecision != null && latestStartedStage != null) {
      onApply(overwrittenDecision, stageResultsMap[latestStartedStage.id]);
    }
    handleCancel();
  };

  return (
    <ConfirmationDialog
      title={<Trans>Overwrite Decision</Trans>}
      isOpen={isOpen}
      onClose={handleCancel}
      onConfirm={handleApply}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      confirmBtnText={<Trans>Change decision</Trans>}
      confirmBtnDisabled={
        latestStartedStage?.type === StageType.FullTextScreening &&
        (isNil(overwrittenDecision) || isEmpty(overwrittenDecision.reasonCodes))
      }
      css={{ width: 530 }}
    >
      <div className="text-base">
        <Trans>Final stage decision will be overwritten.</Trans>
      </div>
      {stages.map((stage) => {
        const isLatestStage = latestStartedStage?.id === stage.id;
        const stageResult = stageResultsMap[stage.id];

        return (
          <div className="flex flex-row mt-6 items-center stage-decision" key={stage.id}>
            <div className="w-5/12 flex-none truncate text-sm pl-1">{stage.name}</div>
            <ReferenceStageDecisionSelect
              stage={stage}
              stageResult={
                isLatestStage && overwrittenDecision
                  ? {
                      ...stageResult,
                      inclusion_status: overwrittenDecision.inclusionStatus,
                      status_reason_codes: overwrittenDecision.reasonCodes,
                    }
                  : stageResult
              }
              onSelect={isLatestStage ? setOverwrittenDecision : undefined}
            />
          </div>
        );
      })}
    </ConfirmationDialog>
  );
};

export default DecisionOverwriteDialog;
