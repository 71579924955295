/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  AnchorButton,
  Button,
  Colors,
  Divider,
  Icon,
  Intent,
  NonIdealState,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { find, get, isNil, propEq } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { fancyScrollCss, lightGray5bg } from '../../../common/styles';
import PDFViewer from '../../pdf_viewer/pdf_viewer';
import {
  PageToggles as PDFPages,
  SearchToggle as PDFSearchToggle,
  ZoomToggle as PDFZoomToggle,
  RotationToggle as PDFRotationToggle,
} from '../../pdf_viewer/controls';
import DocPageToggles from './doc_page_toggles';
import DocZoomToggles from './doc_zoom_toggles';
import { TReferenceData } from '..';
import { useCurrCallback, useSetState } from '../../../lib/utils';
import { getReferenceStudyIdAndTitle } from '../../references/helpers';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { POPOVER_TARGET } from '@blueprintjs/core/lib/esm/common/classes';
import DownloadButton from '../../common/download_button';
import { Buckets } from '../../../lib/attachmentUpload';

const titleCss = css`
  color: ${Colors.DARK_GRAY1};
`;

const pdfContainerCss = css`
  ${fancyScrollCss};
  background-color: ${Colors.GRAY2};
`;

const pdfManagementCss = css`
  .pdf-nav {
    border: 0;
  }
`;

const truncateTitleCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .${POPOVER_TARGET} {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const ResetPDFViewerRotationAngleMutation = loader(
  '../../../graphql/local/reset_pdf_viewer_rotation_angle.gql'
);

export interface IAttachmentsProps {
  reference: TReferenceData;
  navigateToPrevReference?: () => Promise<any>;
  navigateToNextReference?: () => Promise<any>;
}

interface IAttachmentsState {
  remoteContentLoaded: boolean;
  attachmentKey: string | null;
  attachmentFilename: string | null;
}

function getInitialState({
  attachmentKey,
  attachmentFilename,
}: Pick<IAttachmentsState, 'attachmentKey' | 'attachmentFilename'>): IAttachmentsState {
  return {
    remoteContentLoaded: false,
    attachmentKey,
    attachmentFilename,
  };
}

const Attachments: React.FC<IAttachmentsProps> = ({
  reference,
  navigateToPrevReference,
  navigateToNextReference,
}) => {
  const [resetPDFViewerRotationAngle] = useMutation(ResetPDFViewerRotationAngleMutation);
  const { reference_attachments: referenceAttachments } = reference;
  const defaultAttachmentKey = get([0, 'key'], referenceAttachments);
  const defaultAttachmentFilename = get([0, 'filename'], referenceAttachments);

  const [{ remoteContentLoaded, attachmentKey, attachmentFilename }, setState] =
    useSetState<IAttachmentsState>(
      getInitialState({
        attachmentKey: defaultAttachmentKey,
        attachmentFilename: defaultAttachmentFilename,
      })
    );

  const [previousAttachmentKey, nextAttachmentKey] = useMemo(() => {
    if (!attachmentKey) {
      return [];
    }
    const attachmentIdx = referenceAttachments.findIndex(({ key }) => key === attachmentKey);
    return [
      get([attachmentIdx - 1, 'key'], referenceAttachments),
      get([attachmentIdx + 1, 'key'], referenceAttachments),
    ];
  }, [attachmentKey, referenceAttachments]);

  const handleSetAttachment = useCurrCallback(
    (newAttachmentKey, _evt) => {
      const attachment = find(propEq('key', newAttachmentKey), referenceAttachments);
      setState({
        attachmentKey: newAttachmentKey,
        attachmentFilename: attachment?.filename,
      });
    },
    [setState, referenceAttachments]
  );

  // Reset state when next reference arrives (the component is only getting new props,
  // it isn't unmounted, so we need to reset all 3 state variables we have).
  useEffect(() => {
    resetPDFViewerRotationAngle();
    setState({
      remoteContentLoaded: false,
      attachmentKey: get('reference_attachments[0].key', reference) ?? null,
      attachmentFilename: get('reference_attachments[0].filename', reference) ?? null,
    });
  }, [resetPDFViewerRotationAngle, reference.id, setState]);

  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <div
        className="h-12 flex-none flex flex-row items-center flex-no-wrap overflow-hidden"
        css={lightGray5bg}
      >
        <PDFSearchToggle className="h-full" large minimal />
        <Divider className="my-0 ml-0 h-full" />
        <Tooltip
          boundary="viewport"
          css={truncateTitleCss}
          content={getReferenceStudyIdAndTitle(reference)}
          className="w-full"
        >
          <span className="flex-1 text-xl" css={titleCss}>
            {getReferenceStudyIdAndTitle(reference)}
          </span>
        </Tooltip>

        <Divider className="my-0 mx-0 h-full" />
        <Tooltip className="flex h-full" content={<Trans>Previous reference</Trans>}>
          <AnchorButton
            large
            minimal
            disabled={isNil(navigateToPrevReference)}
            className="h-full w-12"
            icon={IconNames.ARROW_UP}
            onClick={navigateToPrevReference}
          />
        </Tooltip>
        <Divider className="my-0 mx-0 h-full" />
        <Tooltip className="flex h-full" content={<Trans>Next reference</Trans>}>
          <AnchorButton
            large
            minimal
            disabled={isNil(navigateToNextReference)}
            className="h-full w-12"
            icon={IconNames.ARROW_DOWN}
            onClick={navigateToNextReference}
          />
        </Tooltip>
      </div>
      <Divider className="m-0" />
      {isNil(attachmentKey) ? (
        <div className="flex flex-col flex-1 overflow-hidden">
          <iframe
            key={reference.attrs.fullText}
            className={`${remoteContentLoaded ? 'flex-1' : 'w-0 h-0'}`}
            onLoad={() => setState({ remoteContentLoaded: true })}
            sandbox="allow-scripts"
            src={reference.attrs.fullText}
            title="Reference"
          />
          {remoteContentLoaded ? (
            <div className="text-xs flex items-center p-1">
              <Icon icon={IconNames.INFO_SIGN} className="mr-2" intent={Intent.PRIMARY} />
              <span>
                <Trans>
                  If there are troubles with the loaded content, please use{' '}
                  <a
                    href={reference.attrs.fullText}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="underline text-blue-700"
                  >
                    the original link
                  </a>{' '}
                  to try loading it manually
                </Trans>
              </span>
            </div>
          ) : (
            <NonIdealState
              icon={<Spinner size={SpinnerSize.SMALL} />}
              title={
                <span className="font-normal">
                  <Trans>Fetching the full text data</Trans>
                </span>
              }
              action={
                <span className="text-xs">
                  <Trans>
                    Due to remote server restrictions and/or browser security restrictions, it could
                    happen that remote resource could not be fetched. In such case, please use{' '}
                    <a
                      href={reference.attrs.fullText}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="underline text-blue-700"
                    >
                      this link
                    </a>
                  </Trans>{' '}
                  to manually open the remote resource URL.
                </span>
              }
            />
          )}
        </div>
      ) : (
        <React.Fragment>
          <div className="flex-1 overflow-auto" css={pdfContainerCss}>
            <PDFViewer fileKey={attachmentKey} searchBarPosition="topRight" />
          </div>
          <Divider className="m-0" />
          <div
            className="flex-0 h-10 flex flex-row items-center justify-between px-3"
            css={[lightGray5bg, pdfManagementCss]}
          >
            <div>
              {previousAttachmentKey && (
                <Button
                  icon={IconNames.CARET_LEFT}
                  className="uppercase pdf-nav"
                  outlined
                  onClick={handleSetAttachment(previousAttachmentKey)}
                >
                  <Trans>Previous PDF</Trans>
                </Button>
              )}
            </div>
            <div className="flex">
              <PDFPages renderer={DocPageToggles} />
              <Divider className="h-6" />
              <PDFZoomToggle renderer={DocZoomToggles} />
              <Divider className="h-6" />
              <PDFRotationToggle />
              <Tooltip content={<Trans>Download PDF</Trans>}>
                <DownloadButton
                  minimal
                  large
                  bucketName={Buckets.ReferencesAttachments.name}
                  downloadKey={attachmentKey}
                  downloadName={attachmentFilename ?? defaultAttachmentFilename}
                />
              </Tooltip>
            </div>
            <div>
              {nextAttachmentKey && (
                <Button
                  rightIcon={IconNames.CARET_RIGHT}
                  className="uppercase pdf-nav"
                  outlined
                  onClick={handleSetAttachment(nextAttachmentKey)}
                >
                  <Trans>Next PDF</Trans>
                </Button>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Attachments;
