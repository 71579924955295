/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Classes, Divider, Intent, Spinner, Switch, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { t, Trans, Plural } from '@lingui/macro';
import gql from 'graphql-tag';
import { compose, find, get, isEmpty, map, pick, propEq, set, keyBy, defaults } from 'lodash/fp';
import React, { FormEvent, FormEventHandler, useCallback, useEffect, useMemo } from 'react';
import {
  InclusionStatus,
  TeamMember,
  User,
  DomainData,
  StageType,
  FTScreeningCriteria,
  ResolutionStrategy,
  ConflictType,
  Stage,
  ScreeningTaskResult,
  ReferenceLog,
  ConflictsResolutionSettings,
} from '../../../common/types';
import { useKeycloak } from '../../../keycloak';
import {
  applyConflictsResolutionStrategy,
  getConflictTypeLabel,
  getResolutionStrategyLabel,
  partitionConflictTasksByConflictType,
  TConflictResolutionTask,
  statusConflictStrategyOptions,
  inclusionReasonConflictStrategyOptions,
  exclusionReasonConflictStrategyOptions,
} from '../../../lib/conflict_resolution_helpers';
import AppToaster from '../../../lib/toaster';
import { useCurrCallback, useI18n, useSetState, useStatusColor } from '../../../lib/utils';
import ErrorScreen from '../../common/error_screen';
import { useTheme } from '../../settings/theme_context';
import HierarchyStrategy from './hierarchy_strategy';
import InclusionStatusStrategy from './inclusion_status_strategy';
import { border1Gray5, fancyScrollCss } from '../../../common/styles';
import Dialog from '../../common/dialog';
import { captureException } from '@sentry/browser';

const ResolutionStrategySelect = Select.ofType<ResolutionStrategy>();

const EMPTY_LIST = [];

const dialogCss = css`
  width: 90vw;
  max-width: 610px;
  max-height: 90vh;
  overflow: auto;
`;

const automationSwitchCss = css`
  ${border1Gray5};

  &.active {
    border-color: transparent;
    background-color: #ebf2f8;
  }
`;

export const DEFAULT_RESOLUTION_STRATEGIES: ConflictsResolutionSettings = {
  [ConflictType.Status]: {
    strategy: ResolutionStrategy.DisagreementReconciliation,
    automated: true,
  },
  [ConflictType.ExclusionReason]: {
    strategy: ResolutionStrategy.DisagreementReconciliation,
    automated: true,
  },
  [ConflictType.InclusionReason]: {
    strategy: ResolutionStrategy.DisagreementReconciliation,
    automated: true,
  },
};

const ApplyRapidConflictsResolutionMutation = gql`
  mutation ApplyRapidConflictsResolution(
    $resolutionResults: [task_result_insert_input!]!
    $releasedTasks: [task_insert_input!]!
    $stageId: uuid!
    $resolutionSettings: jsonb!
    $logs: [reference_details_log_insert_input!]!
    $resolvedTasks: [uuid!]!
  ) {
    insert_task_result(
      objects: $resolutionResults
      on_conflict: { constraint: task_result_pkey, update_columns: result }
    ) {
      returning {
        task_id
        form_id
        result
      }
    }

    update_stage_by_pk(
      pk_columns: { id: $stageId }
      _set: { conflicts_resolution_strategies: $resolutionSettings }
    ) {
      id
      conflicts_resolution_strategies
    }

    insert_task(
      objects: $releasedTasks
      on_conflict: { constraint: task_pkey, update_columns: is_draft }
    ) {
      returning {
        id
        is_draft
      }
    }

    update_task(
      where: { id: { _in: $resolvedTasks } }
      _set: { completed: true, is_draft: false }
    ) {
      returning {
        id
        completed
      }
    }

    insert_reference_details_log(objects: $logs) {
      returning {
        reference_id
        data
      }
    }
  }
`;

const ConflictsDataQuery = gql`
  query GetConflictsData($projectId: uuid!, $stageId: uuid!) {
    project: project_by_pk(id: $projectId) {
      id
      questions {
        id
        current_form {
          id
          form
        }
      }
      team_members(where: { role: { _eq: screener } }) {
        id
        deleted_at
        user {
          id
          name
          enabled
        }
      }
      stages(where: { id: { _eq: $stageId } }) {
        id
        type
        conflicts_resolution_strategies
        forms(order_by: { created_at: desc }, limit: 1) {
          id
          form
        }
        tasks(
          where: {
            task_type: { _eq: conflict_resolution }
            completed: { _eq: false }
            study: {
              study_pool_studies: {
                inclusion_status: { _eq: conflict }
                study_pool: { stage_id: { _eq: $stageId } }
              }
            }
          }
        ) {
          id
          study_id
          project_id
          stage_id
          study_pool_id
          completed
          is_draft
          task_results {
            result
          }
          study {
            id
            references(limit: 1) {
              id
            }
            screeningTasks: tasks(
              where: { task_type: { _eq: screening }, stage_id: { _eq: $stageId } }
            ) {
              id
              team_member_id
              task_results {
                result
              }
            }
          }
        }
      }
    }
  }
`;

type TStageData = Pick<Stage, 'id' | 'conflicts_resolution_strategies' | 'type' | 'forms'> & {
  tasks: TConflictResolutionTask[];
};

type TQueryData = {
  project: {
    id: string;
    team_members: {
      id: TeamMember['id'];
      deleted_at: TeamMember['deleted_at'];
      user: Pick<User, 'id' | 'name' | 'enabled'>;
    }[];
    stages: TStageData[];
  };
};

type TQueryVariables = {
  projectId: string;
  stageId: string;
};

type TReason = { label: string; id: string; code: string };

export function prepareReferenceConflictResolutionLogs({
  referenceId,
  stageId,
  criteria,
  inclusionStatus,
  userId,
  automaticResolution,
}: {
  referenceId: string;
  stageId: string;
  criteria: any;
  inclusionStatus: InclusionStatus | undefined;
  userId: string;
  automaticResolution: boolean;
}): Pick<ReferenceLog, 'reference_id' | 'data'>[] {
  return [
    {
      reference_id: referenceId,
      data: {
        type: 'conflict_resolved',
        userId,
        stageId,
        decision: inclusionStatus,
        criteria,
        automaticResolution,
      },
    },
  ];
}

interface IConflictsManagementDialogProps {
  isOpen: boolean;
  projectId: string;
  stageId: string;
  conflictType: ConflictType;
  onClose: (resolved?: boolean) => void;
}

const ConflictsManagementDialog: React.FC<IConflictsManagementDialogProps> = ({
  isOpen,
  onClose,
  projectId,
  stageId,
  conflictType,
}) => {
  const i18n = useI18n();
  const { user } = useKeycloak();
  const getStatusColor = useStatusColor();
  const { statusColors } = useTheme();
  const [state, setState] = useSetState({
    resolutionSettings: DEFAULT_RESOLUTION_STRATEGIES,
  });
  const { resolutionSettings } = state;
  const activeConflictTypeResolutionSettings = defaults(
    DEFAULT_RESOLUTION_STRATEGIES[conflictType],
    resolutionSettings[conflictType]
  );

  const automationEnabled = activeConflictTypeResolutionSettings.automated;

  const conflictTypeLabel = getConflictTypeLabel(conflictType);
  const conflictTypeLabelColored = getConflictTypeLabel(conflictType, statusColors);

  const {
    loading: loadingConflictTasks,
    error,
    data,
    refetch,
  } = useQuery<TQueryData, TQueryVariables>(ConflictsDataQuery, {
    variables: { projectId, stageId },
    fetchPolicy: 'network-only',
    skip: !isOpen,
  });
  const [applyConflictsResolution, { loading: applyingResolution }] = useMutation(
    ApplyRapidConflictsResolutionMutation
  );

  const stageType = get('project.stages[0].type', data);
  const form = get('project.stages[0].forms[0]', data);
  const formId = get('id', form);
  const formData = get('form', form);
  const exclusionReasons: TReason[] = useMemo(() => {
    switch (stageType) {
      case StageType.PreliminaryScreening:
      case StageType.TitlesAbstractScreening:
        const domains = get('domains', formData) ?? [];

        return domains.map((domain: DomainData) => ({
          code: domain.exclusionCode,
          label: domain.exclusionButtonLabel,
          id: domain.id,
        }));
      case StageType.FullTextScreening:
        const criteria = get('exclusion', formData) ?? [];

        return criteria.map((criteria: FTScreeningCriteria) => ({
          code: criteria.code,
          label: criteria.name,
          id: criteria.id,
        }));
      default:
        return [];
    }
  }, [formData, stageType]);

  const inclusionReasons: TReason[] = useMemo(() => {
    if (stageType === StageType.FullTextScreening) {
      const criteria = get('inclusion', formData) ?? [];

      return criteria.map((criteria: FTScreeningCriteria) => ({
        code: criteria.code,
        label: criteria.name,
        id: criteria.id,
      }));
    }
    return [];
  }, [formData, stageType]);

  const conflictTasks: TConflictResolutionTask[] =
    get('project.stages[0].tasks', data) ?? EMPTY_LIST;

  const stageConflictsResolutionSettings: ConflictsResolutionSettings | null = get(
    'project.stages[0].conflicts_resolution_strategies',
    data
  );

  const strategyOptions = useMemo(() => {
    switch (conflictType) {
      case ConflictType.Status:
        return statusConflictStrategyOptions;
      case ConflictType.InclusionReason:
        return inclusionReasonConflictStrategyOptions;
      case ConflictType.ExclusionReason:
        return exclusionReasonConflictStrategyOptions;
      default:
        return [];
    }
  }, [conflictType]);

  const tasksByConflictType: Record<ConflictType, TConflictResolutionTask[]> = useMemo(() => {
    const [statusConflicts, exclusionReasonConflicts, inclusionReasonConflicts] =
      partitionConflictTasksByConflictType(conflictTasks);

    return {
      [ConflictType.Status]: statusConflicts,
      [ConflictType.InclusionReason]: inclusionReasonConflicts,
      [ConflictType.ExclusionReason]: exclusionReasonConflicts,
    };
  }, [conflictTasks]);

  const updateActiveConflictTypeResolutionSettings = useCallback(
    (
      newSettings: Partial<{
        automated: boolean;
        strategy: ResolutionStrategy;
        strategyDetails: any;
      }>
    ) => {
      setState((currentState) => ({
        ...currentState,
        resolutionSettings: {
          ...currentState.resolutionSettings,
          [conflictType]: {
            ...currentState.resolutionSettings[conflictType],
            ...newSettings,
          },
        },
      }));
    },
    [conflictType, setState]
  );

  const renderExclusionInclusionReasonLabel = useCurrCallback(
    (inclusionStatus: InclusionStatus, reasonId: string) => {
      const reasons =
        inclusionStatus === InclusionStatus.Included ? inclusionReasons : exclusionReasons;

      const color = getStatusColor(inclusionStatus);

      const reason = find(propEq('id', reasonId), reasons);
      return reason ? <span css={{ color }}>{reason.label}</span> : null;
    },
    [inclusionReasons, exclusionReasons, getStatusColor]
  );

  const affectedTasks = tasksByConflictType[conflictType];

  const conflictsCountsText = useMemo(() => {
    if (automationEnabled) {
      return affectedTasks.length ? (
        <Trans>{affectedTasks.length} current and future conflicts</Trans>
      ) : (
        <Trans>Future conflicts</Trans>
      );
    } else {
      return (
        <Plural value={affectedTasks.length} one="# current conflict" other="# current conflicts" />
      );
    }
  }, [affectedTasks, automationEnabled]);

  const resolutionStrategyDetails = useMemo(() => {
    switch (activeConflictTypeResolutionSettings.strategy) {
      case ResolutionStrategy.InclusionStatus:
        return (
          <InclusionStatusStrategy
            value={activeConflictTypeResolutionSettings.strategyDetails}
            onChange={(evt: FormEvent<HTMLInputElement>) => {
              updateActiveConflictTypeResolutionSettings({
                strategyDetails: evt.currentTarget.value as InclusionStatus,
              });
            }}
          />
        );
      case ResolutionStrategy.Merge:
        return (
          <div className="text-sm text-gray-700 my-3">
            <Trans>Both decisions will be included in report</Trans>
          </div>
        );
      case ResolutionStrategy.ExclusionReasonHierarchy:
        return (
          <HierarchyStrategy
            className="py-3"
            label={<Trans>The exclusion reason with the higher priority will be applied.</Trans>}
            itemRenderer={renderExclusionInclusionReasonLabel(InclusionStatus.Excluded)}
            itemsOrder={
              activeConflictTypeResolutionSettings.strategyDetails ?? map('id', exclusionReasons)
            }
            onChange={(newOrder: string[]) =>
              updateActiveConflictTypeResolutionSettings({ strategyDetails: newOrder })
            }
          />
        );
      case ResolutionStrategy.InclusionReasonHierarchy:
        return (
          <HierarchyStrategy
            className="py-3"
            label={<Trans>The inclusion reason with the higher priority will be applied.</Trans>}
            itemRenderer={renderExclusionInclusionReasonLabel(InclusionStatus.Included)}
            itemsOrder={
              activeConflictTypeResolutionSettings.strategyDetails ?? map('id', inclusionReasons)
            }
            onChange={(newOrder: string[]) =>
              updateActiveConflictTypeResolutionSettings({ strategyDetails: newOrder })
            }
          />
        );
      case ResolutionStrategy.DisagreementReconciliation:
        return (
          <div>
            <div className="text-sm text-gray-700 py-3">
              <Trans>
                Conflict resolution module will be visible for all Reviewers involved in screening
                at this stage.
              </Trans>
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    activeConflictTypeResolutionSettings,
    conflictTasks,
    updateActiveConflictTypeResolutionSettings,
    exclusionReasons,
    inclusionReasons,
  ]);

  const renderResolutionStrategyOption: ItemRenderer<ResolutionStrategy> = (
    option,
    { handleClick }
  ) => {
    const isActive = activeConflictTypeResolutionSettings.strategy === option;

    return (
      <MenuItem
        key={option}
        active={isActive}
        text={getResolutionStrategyLabel(option)}
        onClick={handleClick}
      />
    );
  };

  const handleResolutionStrategyOptionSelect = useCallback(
    (newStrategy: ResolutionStrategy) => {
      updateActiveConflictTypeResolutionSettings({ strategy: newStrategy });
    },
    [updateActiveConflictTypeResolutionSettings]
  );

  const handleAutomationToggle: FormEventHandler<HTMLInputElement> = useCallback(
    (evt) => {
      updateActiveConflictTypeResolutionSettings({
        automated: (evt.target as HTMLInputElement).checked,
      });
    },
    [updateActiveConflictTypeResolutionSettings]
  );

  const handleSave = useCallback(async () => {
    try {
      const { strategy, strategyDetails } = activeConflictTypeResolutionSettings;
      const tasksToRelease =
        strategy === ResolutionStrategy.DisagreementReconciliation
          ? affectedTasks.map(
              compose(
                set('is_draft', false),
                pick(['id', 'study_id', 'project_id', 'stage_id', 'study_pool_id'])
              )
            )
          : [];

      const resolutionResults = await applyConflictsResolutionStrategy(
        strategy,
        strategyDetails,
        affectedTasks
      );
      const resultsToSave = resolutionResults.map(set('form_id', formId)) as Pick<
        ScreeningTaskResult,
        'form_id' | 'task_id' | 'result'
      >[];

      const affectedTasksMap = keyBy('id', affectedTasks);

      const logs: Pick<ReferenceLog, 'reference_id' | 'data'>[] = resultsToSave.reduce<
        Pick<ReferenceLog, 'reference_id' | 'data'>[]
      >((acc, { task_id, result }) => {
        const taskData = affectedTasksMap[task_id];

        const logsData: Pick<ReferenceLog, 'reference_id' | 'data'>[] =
          prepareReferenceConflictResolutionLogs({
            referenceId: taskData.study.references[0].id,
            stageId,
            inclusionStatus: result.inclusionStatus,
            criteria: result.criteria,
            userId: user.id,
            automaticResolution: true,
          });
        acc.push(...logsData);
        return acc;
      }, []);

      await applyConflictsResolution({
        variables: {
          resolutionResults: resultsToSave,
          resolvedTasks: map('task_id', resultsToSave),
          releasedTasks: tasksToRelease,
          stageId,
          resolutionSettings,
          logs,
        },
      });

      refetch();

      AppToaster.show({
        message: i18n._(t`Conflicts resolution updated successfully`),
        intent: Intent.SUCCESS,
      });

      onClose();
    } catch (error) {
      captureException(error);
    }
  }, [
    onClose,
    affectedTasks,
    activeConflictTypeResolutionSettings,
    formId,
    refetch,
    stageId,
    resolutionSettings,
    user,
  ]);

  useEffect(() => {
    if (!isEmpty(stageConflictsResolutionSettings)) {
      setState({ resolutionSettings: stageConflictsResolutionSettings ?? undefined });
    }
  }, [stageConflictsResolutionSettings]);

  // reset state on close
  useEffect(() => {
    if (!isOpen) {
      setState({
        resolutionSettings: isEmpty(stageConflictsResolutionSettings)
          ? DEFAULT_RESOLUTION_STRATEGIES
          : stageConflictsResolutionSettings ?? undefined,
      });
    }
  }, [isOpen, stageConflictsResolutionSettings]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => onClose()}
      canOutsideClickClose={false}
      title={<Trans>{conflictTypeLabel} conflicts</Trans>}
      css={dialogCss}
    >
      <div className={`${Classes.DIALOG_BODY} flex flex-col m-0 p-5 overflow-auto`}>
        {loadingConflictTasks ? (
          <Spinner />
        ) : error == null ? (
          <div className="flex-1 overflow-auto flex flex-col">
            <div
              css={automationSwitchCss}
              className={`px-4 py-3 flex flex-row items-center ${
                automationEnabled ? 'active' : ''
              }`}
            >
              <Switch
                checked={automationEnabled}
                onChange={handleAutomationToggle}
                className="flex-none mb-0"
              />
              <div className="flex-1">
                <div className="text-base">
                  <Trans>Automate conflict management</Trans>
                </div>
              </div>
            </div>
            <div css={border1Gray5} className="flex flex-col mt-3 overflow-auto">
              <div className="px-3 py-2">
                <span>{conflictTypeLabelColored}: </span>
                <span className="font-bold ml-4">{conflictsCountsText}</span>
              </div>
              <Divider className="m-0" />
              <div className="flex flex-row px-3 items-center my-3">
                <div className="mr-2">
                  <Trans>Solving strategy</Trans>:
                </div>
                <ResolutionStrategySelect
                  className="w-64"
                  filterable={false}
                  items={strategyOptions}
                  itemRenderer={renderResolutionStrategyOption}
                  onItemSelect={handleResolutionStrategyOptionSelect}
                >
                  <Button
                    className="p-3"
                    small
                    fill
                    alignText="left"
                    text={getResolutionStrategyLabel(activeConflictTypeResolutionSettings.strategy)}
                    rightIcon={IconNames.CHEVRON_DOWN}
                  />
                </ResolutionStrategySelect>
              </div>
              <div className="px-3 overflow-auto" css={fancyScrollCss}>
                {resolutionStrategyDetails}
              </div>
            </div>
          </div>
        ) : (
          <ErrorScreen error={error} />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button className="w-20" text={<Trans>Cancel</Trans>} onClick={() => onClose()} />
          <Button
            className="w-20"
            text={<Trans>Save</Trans>}
            onClick={handleSave}
            loading={applyingResolution}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConflictsManagementDialog;
