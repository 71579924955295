/** @jsx jsx */
import { jsx } from '@emotion/core';

const Diagram: React.FC = ({ children }) => {
  return (
    <div
      css={{
        marginTop: '50px',
        display: 'grid',
        gridTemplateColumns: 'repeat(9, 100px)',
        gridTemplateRows: 'repeat(28, minmax(50px, auto))',
        msGridColumns: '100px 100px 100px 100px 100px 100px 100px 100px 100px',
      }}
    >
      {children}
    </div>
  );
};

export default Diagram;
