import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const PROJECT_ID_REGEX = /projects\/([^\/]+)/;

const InsertActionLogMutation = gql`
  mutation InsertActionLogMutation(
    $eventType: String!
    $url: String!
    $clientTimestamp: timestamptz!
    $eventData: jsonb
    $projectId: uuid
  ) {
    insert_user_actions_log(
      objects: {
        event_type: $eventType
        url: $url
        client_timestamp: $clientTimestamp
        event_data: $eventData
        project_id: $projectId
      }
    ) {
      affected_rows
    }
  }
`;

export type InsertActionLogFunction = (eventType: string, eventData: object) => void;

function useActionLogger() {
  const [insertActionLogMutation] = useMutation(InsertActionLogMutation);

  const insertActionLog: InsertActionLogFunction = (eventType, eventData) => {
    const regexMatch = window.location.pathname.match(PROJECT_ID_REGEX);
    const projectId = regexMatch ? regexMatch[1] : null;

    insertActionLogMutation({
      variables: {
        eventType,
        url: window.location.pathname,
        clientTimestamp: new Date().toISOString(),
        eventData,
        projectId,
      },
    });
  };

  return insertActionLog;
}

export default useActionLogger;
