/** @jsx jsx */
import { Button, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { get } from 'lodash/fp';
import React from 'react';
import { Project } from '../../../common/types';
import { getDate, getProjectStatus } from '../helpers';

const ProjectInfoWithLabel = ({ label, text }) => (
  <div className="flex flex-row items-center mr-10">
    <div className={`${Classes.TEXT_MUTED} text-xs mr-1`}>
      {label}
      {':'}
    </div>
    <div className="text-xs">{text}</div>
  </div>
);

type ProjectSummaryProps = {
  project: Project;
  onEditProject: () => void;
};

export const ProjectSummary: React.FC<ProjectSummaryProps> = ({ project, onEditProject }) => {
  const projectStatus = getProjectStatus(project);
  const creationDay = getDate(get('created_at', project));
  const dueDateValue = get('due_date', project);
  const dueDate = getDate(dueDateValue);
  const createdBy = get('created_by_user.name', project);
  const folder = get('folder', project);

  return (
    <div className="flex flex-no-wrap p-5">
      {folder && <ProjectInfoWithLabel label={<Trans>Folder</Trans>} text={folder.name} />}
      <ProjectInfoWithLabel label={<Trans>Status</Trans>} text={projectStatus} />
      <ProjectInfoWithLabel
        label={<Trans>Created by</Trans>}
        text={createdBy ?? <Trans>User removed</Trans>}
      />
      <ProjectInfoWithLabel label={<Trans>Created on</Trans>} text={creationDay} />
      {dueDateValue && <ProjectInfoWithLabel label={<Trans>Due date</Trans>} text={dueDate} />}
      <Button className="ml-auto" icon={IconNames.COG} onClick={onEditProject}>
        <Trans>Settings</Trans>
      </Button>
    </div>
  );
};
