/** @jsx jsx */
import { useMutation } from '@apollo/react-hooks';
import {
  Alert,
  Button,
  Classes,
  Icon,
  InputGroup,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { includes, isEmpty } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { lightGray3bg, lightGray5bg } from '../../common/styles';
import { UserKeyword } from '../../common/types';
import { useKeycloak } from '../../keycloak';
import { toastify, useI18n } from '../../lib/utils';
import ErrorScreen from '../common/error_screen';
import ScreenHeader from '../common/screen_header';
import useActionLogger from '../hooks/use_action_logger';
import KeywordsCard from './keywords_card';
import KeywordsForm, { KeywordsFormMode } from './keywords_form';
import KeywordsTree from './keywords_tree';
import { KEYWORDS_GENERAL_CATEGORIES, useKeywordsListQuery } from './keywords_utils';

const keywordsTreeCss = css`
  width: 300px;
  min-width: 300px;
`;

const KeywordsSetFragment = loader('../../graphql/keywords_set_fragment.gql');

const GetKeywordsSetQuery = gql`
  query GetKeywordsSetQuery($userId: String!) {
    domains: form_domains_and_variables {
      domain
      variables
    }
    keywords_set(where: { user_id: { _eq: $userId } }) {
      ...KeywordsSetFragment
    }
  }
  ${KeywordsSetFragment}
`;

const InsertKeywordsSetMutation = loader('../../graphql/insert_keywords_set_mutation.gql');

const UpdateKeywordsSetMutation = gql`
  mutation UpdateKeywordsSetMutation(
    $id: uuid!
    $domain: String
    $excluded: jsonb
    $included: jsonb
    $title: String
    $variable: String
  ) {
    update_keywords_set_by_pk(
      pk_columns: { id: $id }
      _set: {
        domain: $domain
        excluded: $excluded
        included: $included
        title: $title
        variable: $variable
      }
    ) {
      id
    }
  }
`;

const DeleteKeywordsSetMutation = gql`
  mutation DeleteKeywordsSetMutation($keywordsSetId: uuid!) {
    delete_keywords_set_by_pk(id: $keywordsSetId) {
      id
    }
  }
`;

interface IKeywordsListProps extends RouteComponentProps {}

const KeywordsList: React.FC<IKeywordsListProps> = ({ history }) => {
  const insertActionLog = useActionLogger();
  const i18n = useI18n();
  const {
    user: { id: userId },
  } = useKeycloak();

  const {
    activeTreeItem: { domain: currentDomain, variable: currentVariable },
    domains,
    error,
    filteredKeywords,
    keywordsToShow,
    loading,
    refetch,
    searchQuery,
    setActiveTreeItem,
    setSearchQuery,
  } = useKeywordsListQuery<{ userId: string }>({
    query: GetKeywordsSetQuery,
    variables: { userId },
  });

  const [keywordsSetToDelete, setKeywordsSetToDelete] = useState<UserKeyword | null>(null);
  const [editingSet, setEditingSet] = useState<UserKeyword | null>(null);
  const [insertKeywordsSet] = useMutation(InsertKeywordsSetMutation);
  const [updateKeywordsSet] = useMutation(UpdateKeywordsSetMutation);
  const [deleteKeywordsSet] = useMutation(DeleteKeywordsSetMutation);

  const onBack = () => history.push('/');
  const onItemClicked = ({ domain, variable }) => {
    setActiveTreeItem({ domain, variable });
  };

  const handleKeywordsSetDelete = useCallback(() => {
    const keywordsSetId = keywordsSetToDelete!.id!;
    setKeywordsSetToDelete(null);
    toastify(
      deleteKeywordsSet({ variables: { keywordsSetId } }).then(() => {
        insertActionLog('deleted keywords set', { id: keywordsSetId });
        refetch({ userId });
      }),
      i18n._(t`Keywords set deleted`),
      i18n._(t`Error during deleting keywords set`),
      {
        errorToasterProps: {
          icon: IconNames.ERROR,
        },
        successToasterProps: {
          icon: IconNames.TRASH,
        },
      }
    );
  }, [deleteKeywordsSet, i18n, keywordsSetToDelete, refetch, userId, insertActionLog]);

  const handleKeywordsSetSave = (editedSetData: UserKeyword) => {
    const { domain, excluded, id, included, title, variable } = editedSetData;

    const promise =
      id === undefined
        ? insertKeywordsSet({ variables: { keywordsSet: editedSetData } })
        : updateKeywordsSet({ variables: { domain, excluded, id, included, title, variable } });

    return toastify(
      promise.then(() =>
        insertActionLog(`${id == null ? 'created' : 'updated'} keywords set`, {
          keywordsSet: editedSetData,
        })
      ),
      i18n._(t`Keywords set saved`),
      i18n._(t`Error during saving keywords set`),
      {
        errorToasterProps: {
          icon: IconNames.ERROR,
        },
        successToasterProps: {
          icon: IconNames.SAVED,
        },
      }
    );
  };

  return (
    <div className="h-full w-full flex">
      <div
        className={`m-10 flex-grow flex flex-col bg-white rounded overflow-auto ${Classes.ELEVATION_0}`}
      >
        <ScreenHeader
          className="shadow-none border-b border-solid"
          backIcon={IconNames.ARROW_LEFT}
          onBack={onBack}
          title={
            <div className="mx-3 flex-auto text-xl">
              <Trans>My keywords sets</Trans>
            </div>
          }
        />
        {loading ? (
          <Spinner className="h-full" />
        ) : error ? (
          <ErrorScreen error={error} retry={() => refetch({ userId })} />
        ) : (
          <div className="flex-grow flex flex-col overflow-auto">
            <div className="px-6 py-3 border-b border-solid flex flex-none" css={lightGray3bg}>
              <InputGroup
                className="flex-grow mr-5"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder={i18n._(t`Search...`)}
                rightElement={<Icon className="m-2" icon="search" iconSize={16} />}
                value={searchQuery}
              />
              <Button
                icon="add"
                onClick={() =>
                  setEditingSet({
                    domain: includes(currentDomain, KEYWORDS_GENERAL_CATEGORIES)
                      ? null
                      : currentDomain,
                    included: [],
                    excluded: [],
                    title: '',
                    variable: currentVariable,
                  })
                }
                text={i18n._(t`Add keyword set`)}
              />
            </div>
            <div className="flex-grow flex overflow-auto items-stretch">
              <div css={keywordsTreeCss} className="border-r border-solid overflow-auto">
                <KeywordsTree
                  currentDomain={currentDomain}
                  currentVariable={currentVariable}
                  domainItems={domains}
                  keywords={filteredKeywords}
                  onItemClicked={onItemClicked}
                />
              </div>
              <div className="flex-grow px-6 py-3 overflow-auto h-full" css={lightGray5bg}>
                {isEmpty(keywordsToShow) ? (
                  <NonIdealState
                    description={i18n._(t`Why not create one?`)}
                    icon="heart-broken"
                    title={i18n._(t`No keywords sets found`)}
                  />
                ) : (
                  keywordsToShow.map((keyword) => (
                    <KeywordsCard
                      key={keyword.id!}
                      keyword={keyword}
                      onDelete={() => {
                        setKeywordsSetToDelete(keyword);
                      }}
                      onEdit={() => setEditingSet(keyword)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Alert
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Delete`)}
        icon={IconNames.TRASH}
        intent={Intent.DANGER}
        isOpen={keywordsSetToDelete != null}
        onCancel={() => setKeywordsSetToDelete(null)}
        onConfirm={handleKeywordsSetDelete}
      >
        <p>
          <Trans
            id="Are you sure to delete keyword set{titlePart}?"
            values={{
              titlePart: keywordsSetToDelete !== null ? ` ${keywordsSetToDelete.title}` : '',
            }}
          />
        </p>
      </Alert>
      {editingSet !== null && (
        <KeywordsForm
          domains={domains}
          keywordsData={editingSet}
          mode={KeywordsFormMode.KeywordsList}
          onClose={() => setEditingSet(null)}
          onSave={handleKeywordsSetSave}
        />
      )}
    </div>
  );
};

export default withRouter(KeywordsList);
