import { Resolvers } from 'apollo-client';
import AppLocalState from './app_state';
import PDFViewerState from './pdf_viewer_state';
import ScreeningLocalState from './screening_state';

export interface GQLType {
  __typename: string;
}

interface InitialLocalStateVal {
  // key: name of the localState
  [key: string]: GQLType;
}

export interface LocalState {
  name: string;
  initial: (data?: any) => InitialLocalStateVal;
  resolvers?: Resolvers;
  rootQuery: any;
}

export const localStates = [AppLocalState, PDFViewerState, ScreeningLocalState];
