import React, { useCallback } from 'react';
import { Card, InputGroup } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import EditableSection from './editable_section';

const QuestionTypeSection = EditableSection.ofType<string>();

interface IQuestionTypeProps {
  questionType?: string;
  onSave?: (questionType: string) => Promise<any>;
  saving?: boolean;
  readOnly?: boolean;
}

const QuestionTypeEdit: React.FC<IQuestionTypeProps> = ({
  questionType,
  onSave,
  saving,
  readOnly,
}) => {
  const editRenderer = useCallback(
    (editedQuestionType, setEditedQuestionType) => (
      <InputGroup
        autoFocus
        value={editedQuestionType}
        onChange={(evt) => setEditedQuestionType(evt.target.value)}
      />
    ),
    []
  );

  return (
    <Card>
      <QuestionTypeSection
        title={<Trans>Question type</Trans>}
        content={questionType ?? ''}
        editRenderer={readOnly ? undefined : editRenderer}
        onSave={onSave}
        saving={saving}
      />
    </Card>
  );
};

export default QuestionTypeEdit;
