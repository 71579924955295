/** @jsx jsx */
import {
  Button,
  Checkbox,
  Classes,
  Colors,
  Icon,
  Intent,
  IResizeEntry,
  ResizeSensor,
} from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  ICellProps,
  IColumnProps,
  IRegion,
  IStyledRegionGroup,
  Rect,
  Regions,
  RenderMode,
  SelectionModes,
  Table2,
} from '@blueprintjs/table';
import { ColumnIndices, RowIndices } from '@blueprintjs/table/lib/esm/common/grid';
import {
  TABLE_TOP_CONTAINER,
  TABLE_QUADRANT_SCROLL_CONTAINER,
  TABLE_COLUMN_HEADERS,
  TABLE_HEADER_CONTENT,
  TABLE_QUADRANT,
  TABLE_QUADRANT_BODY_CONTAINER,
  TABLE_CELL,
  TABLE_QUADRANT_MAIN,
} from '@blueprintjs/table/lib/esm/common/classes';
import { css, jsx, SerializedStyles } from '@emotion/core';
import {
  constant,
  debounce,
  delay,
  equals,
  compose,
  get,
  includes,
  isEmpty,
  nthArg,
  reject,
  sum,
  update,
  slice,
  isNil,
} from 'lodash/fp';
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { fancyScrollCss } from '../../common/styles';
import { useCurrCallback } from '../../lib/utils';

const updateColWidth = (arr: (number | undefined)[], col: number, width: number) =>
  compose(update(col, constant(width)), slice(0, arr.length))(arr);

const SELECTED_ROW_CLASS = 'gba-table-selected-row';

const tableCss = css`
  box-shadow: none;
  background-color: transparent;

  .${TABLE_COLUMN_HEADERS} {
    border: 1px solid transparent;

    .${TABLE_HEADER_CONTENT} {
      height: 100%;
      display: flex;
      flex-flow: column;
    }
  }

  .${TABLE_COLUMN_HEADERS} {
    border: none;
  }

  .${TABLE_QUADRANT} {
    background-color: transparent;

    .${TABLE_QUADRANT_SCROLL_CONTAINER} {
      ${fancyScrollCss};
      overflow-anchor: none;
    }
  }

  // 2px padding-bottom to prevent showing empty lists with scrollbar
  .${TABLE_QUADRANT}.${TABLE_QUADRANT_MAIN} .${TABLE_QUADRANT_SCROLL_CONTAINER} {
    padding-bottom: 2px;
  }

  .${TABLE_QUADRANT_BODY_CONTAINER} {
    border: 1px solid ${Colors.LIGHT_GRAY1};
  }

  .${SELECTED_ROW_CLASS} {
    border: none;
    background-color: rgba(138, 155, 168, 0.3);
  }
`;

const defaultHeaderCellCss = css`
  height: 100%;
  line-height: 20px;
  padding: 15px 10px;
  background-color: transparent;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.GRAY1};
`;

const defaultCellCss = css`
  box-shadow: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Colors.LIGHT_GRAY1};
`;

const selectCellCss = css`
  .${Classes.CHECKBOX} {
    margin-bottom: 0;
    &:hover {
      .${Classes.CONTROL_INDICATOR} {
        box-shadow: 0 0 0 4px rgb(69, 128, 230), 0 0 0 8px rgba(69, 128, 230, 0.3),
          inset 0 1px 1px rgba(16, 22, 26, 0.2);
      }
    }
  }
`;

const getTableCollapseExpandCss = (isCollapsed: boolean = false) => css`
  .${TABLE_QUADRANT_SCROLL_CONTAINER} {
    overflow: ${isCollapsed ? 'hidden' : 'inherit'};
    height: ${isCollapsed ? '60px' : '100%'};
    transition: all 0.5s ease-in-out;
  }

  .${TABLE_QUADRANT_BODY_CONTAINER} {
    overflow: hidden;
    height: ${isCollapsed ? '0' : '100%'};
    transition: all 0.5s ease-in-out;
  }
`;

export const RowSelectionColumn: TableCol<any> = {
  id: 'rowSelection',
  width: 40,
};

export interface TableCol<R> {
  id: string;
  label?: string;
  labelIcon?: IconName;
  labelIconIntent?: Intent;
  labelIconOnClick?: () => void;
  nameRenderer?: IColumnProps['nameRenderer'];
  cellCss?: SerializedStyles;
  cellClassName?: string;
  headerCellCss?: SerializedStyles;
  cellProps?: ICellProps;
  width?: number;
  sortable?: boolean;
  customHeaderContent?: ReactNode;
}

export interface IGbaTableProps<R = any> {
  numRows: number;
  rows: readonly R[];
  cols: TableCol<R>[];
  cellContentRenderer: (colId: string, item: R) => ReactNode;
  onLoadMore?: (pagination: { offset: number; limit: number }) => void;
  selection?: {
    rows: number[];
    onChange: (updateFn: (selected: number[]) => number[]) => void;
  };
  defaultRowHeight?: number;
  autoSizeCols?: boolean;
  onClick?: (rowIdx: number, colIdx: number) => void;
  activeRow?: number;
  tableId?: string;
  onSortBy?: (colId: string, ascending: boolean) => void;
  sortedBy?: {
    columnId: string;
    asc: boolean;
  };
  onClearSort?: () => void;
  numFrozenColumns?: number;
  onDoubleClick?: () => void;
  onScrollBottomChange?: (isScrollBottomReached: boolean) => void;
  className?: string;
  tableCustomCss?: SerializedStyles;
  isCollapsed?: boolean;
}

export function createTable<T>(): React.FC<IGbaTableProps<T>> {
  return GbaTable as React.FC<IGbaTableProps<T>>;
}

const GbaTable: React.FC<IGbaTableProps> = memo(
  ({
    numRows,
    rows,
    cols,
    cellContentRenderer,
    onLoadMore,
    selection,
    defaultRowHeight,
    autoSizeCols,
    onClick,
    activeRow,
    tableId,
    onSortBy,
    sortedBy,
    onClearSort,
    numFrozenColumns,
    onDoubleClick,
    onScrollBottomChange,
    className,
    tableCustomCss,
    isCollapsed,
  }) => {
    const [renderedCompletely, setRenderedCompletely] = useState<boolean>(false);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);
    const [colWidths, setColWidths] = useState(cols.map(get('width')));
    const tableRef = useRef<null | Table2>(null);
    const rowsSelected = get('rows', selection);
    const selectionOnChange = selection?.onChange;

    const styledRegions = useMemo(() => {
      let styledRegions: IStyledRegionGroup[] = [];

      if (rowsSelected) {
        styledRegions = [
          ...styledRegions,
          {
            regions: rowsSelected.map((row) => Regions.row(row)),
            className: SELECTED_ROW_CLASS,
          },
        ];
      }
      return styledRegions;
    }, [rowsSelected]);

    const handleSelectAll = useCallback(() => {
      selectionOnChange?.((selected) => (isEmpty(selected) ? rows.map(nthArg(1)) : []));
    }, [selectionOnChange, rows]);

    const handleColumnSort = useCurrCallback(
      (colId, _evt) => {
        if (onSortBy == null) return;
        if (onClearSort && colId === sortedBy?.columnId && sortedBy?.asc === false) {
          onClearSort();
        } else {
          const isAscending = sortedBy == null || sortedBy.columnId !== colId || !sortedBy.asc;
          onSortBy(colId, isAscending);
        }
      },
      [onClearSort, onSortBy, sortedBy]
    );

    const headerCellRenderer = useCallback(
      (colIdx: number) => {
        const {
          label,
          id,
          labelIcon,
          labelIconIntent,
          labelIconOnClick,
          headerCellCss,
          sortable,
          customHeaderContent,
        } = cols[colIdx];

        if (id === 'rowSelection') {
          return (
            <ColumnHeaderCell style={{ boxShadow: 'none' }}>
              <div css={[defaultHeaderCellCss, headerCellCss, selectCellCss]}>
                {labelIcon ? (
                  <Icon icon={labelIcon} intent={labelIconIntent} onClick={labelIconOnClick} />
                ) : (
                  <Checkbox
                    checked={rowsSelected?.length === rows.length}
                    onChange={handleSelectAll}
                    name="select-all-rows"
                    indeterminate={
                      (rowsSelected?.length ?? 0) > 0 && rowsSelected?.length !== rows.length
                    }
                  />
                )}
              </div>
            </ColumnHeaderCell>
          );
        }

        const isOrdered = sortedBy?.columnId === id;

        return (
          <ColumnHeaderCell style={{ boxShadow: 'none' }}>
            <div css={[defaultHeaderCellCss, headerCellCss]}>
              {customHeaderContent ? (
                customHeaderContent
              ) : sortable ? (
                <div
                  className={`flex flex-row h-full items-center ${isOrdered ? 'underline' : ''}`}
                  style={{ maxWidth: colWidths[colIdx] }}
                >
                  {labelIcon ? <Icon icon={labelIcon} onClick={labelIconOnClick} /> : null}
                  <span className="truncate">{label}</span>
                  <Button
                    className="flex-none ml-2"
                    minimal
                    small
                    onClick={handleColumnSort(id)}
                    icon={
                      isOrdered
                        ? sortedBy!.asc
                          ? IconNames.CARET_UP
                          : IconNames.CARET_DOWN
                        : IconNames.DOUBLE_CARET_VERTICAL
                    }
                  />
                </div>
              ) : (
                <div style={{ maxWidth: colWidths[colIdx] }} className="truncate">
                  {labelIcon ? <Icon icon={labelIcon} /> : null}
                  <span>{label}</span>
                </div>
              )}
            </div>
          </ColumnHeaderCell>
        );
      },
      [cols, rowsSelected, rows.length, sortedBy, colWidths, handleColumnSort, handleSelectAll]
    );

    const handleSelectRow = useCurrCallback(
      (rowIdx, _evt) => {
        selectionOnChange?.((selected) =>
          includes(rowIdx, selected) ? reject(equals(rowIdx), selected) : [...selected, rowIdx]
        );
      },
      [selectionOnChange]
    );

    const handleColumnWidthChange = useCallback(
      (colIdx: number, size: number) => {
        setColWidths((colWidths) => updateColWidth(colWidths, colIdx, size));
      },
      [setColWidths]
    );

    const cellRenderer = useCallback(
      (rowIdx, colIdx) => {
        const { id, cellProps, cellCss, cellClassName } = cols[colIdx];
        const item = rows[rowIdx];

        if (item == null) {
          return <Cell css={defaultCellCss} loading />;
        }

        if (id === 'rowSelection') {
          return (
            <Cell
              css={[defaultCellCss, selectCellCss, cellCss]}
              className={cellClassName}
              truncated={false}
            >
              <Checkbox
                checked={includes(rowIdx, rowsSelected)}
                onChange={handleSelectRow(rowIdx)}
                className="select-toggle"
                name="select-row"
              />
            </Cell>
          );
        }
        return (
          <Cell {...cellProps} css={[defaultCellCss, cellCss]} className={cellClassName}>
            {cellContentRenderer(id, item)}
          </Cell>
        );
      },
      [cellContentRenderer, handleSelectRow, cols, rowsSelected, rows]
    );

    const colMapper = useCallback(
      ({ label, nameRenderer, id }: TableCol<any>) => (
        <Column
          key={id}
          id={id}
          name={label}
          cellRenderer={cellRenderer}
          nameRenderer={nameRenderer}
          columnHeaderCellRenderer={headerCellRenderer}
        />
      ),
      [cellRenderer, headerCellRenderer]
    );

    const requestMoreRows = useCallback(
      debounce(300, (pagination: { offset: number; limit: number }) => {
        tableRef.current != null && onLoadMore && onLoadMore(pagination);
      }),
      [onLoadMore, tableRef]
    );

    const handleVisibleCellsChange = useCallback(
      (rowIndices: RowIndices, _colsIndices: ColumnIndices) => {
        const { rowIndexEnd } = rowIndices;
        const shouldFetch = rows.length !== numRows && rowIndexEnd >= rows.length - 1;

        if (shouldFetch) {
          const offset = rows.length;
          let limit = rowIndexEnd - rows.length + 11; // +10 rows below the last visible one
          // do not request more than there exists
          if (limit + offset > numRows) {
            limit = numRows - offset;
          }

          if (limit > 0) {
            return requestMoreRows({ offset, limit });
          }
        }
      },
      [requestMoreRows, rows, numRows]
    );

    const recalculateColWidths = useCallback(
      (containerWidth: number) => {
        const definedWidths = cols.reduce(
          (acc, col) => (col.width ? [...acc, col.width] : acc),
          [] as number[]
        );
        // all columns have pre-defined widths
        if (definedWidths.length === cols.length) return;
        const undistributedWidth = containerWidth - sum(definedWidths);
        // no free space to distribute
        if (undistributedWidth < 0) return;

        const newColumnWidth = Math.floor(
          (undistributedWidth - 20) /**TODO: fix magic number */ /
            (cols.length - definedWidths.length)
        );
        setColWidths(
          cols.map((col) => {
            if (col.width != null) return col.width;

            return newColumnWidth;
          })
        );
      },
      [setColWidths, cols]
    );

    useEffect(() => {
      if (cols.length !== colWidths.length) {
        setColWidths(cols.map(get('width')));
      }
    }, [cols, colWidths]);

    useEffect(() => {
      if (containerWidth && (autoSizeCols ?? true)) {
        // reduce container width by one pixel to fix pixel rounding issue causing horizontal bar to
        // appear
        recalculateColWidths(containerWidth - 1);
      }
    }, [containerWidth, recalculateColWidths, autoSizeCols]);

    const getTableDOMElement = useCallback(() => {
      return tableRef.current ? findDOMNode(tableRef.current) : null;
    }, [tableRef]);

    // this method is almost copy/paste of BP.Table's scrollBodyToFocusedCell private method with
    // minor modifications related to not accessible private instances of BP.Table which it uses in
    // its original method. Also the horizontal-scroll-related code was excluded
    const scrollRowIntoView = useCallback(
      (row: number) => {
        if (tableRef.current == null) return;
        if (!tableRef.current.grid) return;

        const { viewportRect } = tableRef.current.state;

        if (viewportRect == null) return;

        const $table = getTableDOMElement() as Element | null;

        if ($table == null) return;

        const $headerContainer = $table.querySelector(`.${TABLE_TOP_CONTAINER}`);
        const topCorrection = $headerContainer!.clientHeight;

        const viewportBounds = {
          top: viewportRect.top ?? 0,
          bottom: (viewportRect.top ?? 0) + viewportRect.height,
        };
        const focusedCellBounds = {
          top: tableRef.current.grid.getCumulativeHeightBefore(row),
          bottom: tableRef.current.grid.getCumulativeHeightAt(row),
        };

        const focusedCellHeight = focusedCellBounds.bottom - focusedCellBounds.top;

        const isFocusedCellTallerThanViewport = focusedCellHeight > viewportRect.height;

        let nextScrollTop = viewportRect.top;

        // keep the top end of an overly tall focused cell in view when moving left and right
        // (without this OR check, the body seesaws to fit the top end, then the bottom end, etc.)
        if (focusedCellBounds.top < viewportBounds.top || isFocusedCellTallerThanViewport) {
          // scroll up (minus one pixel to avoid clipping the focused-cell border)
          nextScrollTop = Math.max(0, focusedCellBounds.top - 1);
        } else if (focusedCellBounds.bottom + topCorrection > viewportBounds.bottom) {
          // scroll down
          const scrollDelta = focusedCellBounds.bottom + topCorrection + 1 - viewportBounds.bottom;
          nextScrollTop = viewportBounds.top + scrollDelta;
        }

        const didScrollTopChange = nextScrollTop !== viewportRect.top;

        if (didScrollTopChange) {
          // we need to modify the body element explicitly for the viewport to shift
          if (didScrollTopChange) {
            const $table = getTableDOMElement() as Element | null;
            if ($table) {
              const $scrollContainer = $table.querySelector(`.${TABLE_QUADRANT_SCROLL_CONTAINER}`);
              $scrollContainer!.scrollTop = nextScrollTop;
            }
          }

          const nextViewportRect = new Rect(
            viewportRect.left,
            nextScrollTop,
            viewportRect.width,
            viewportRect.height
          );
          tableRef.current.setState({ viewportRect: nextViewportRect });
        }
      },
      [getTableDOMElement]
    );

    // abuse regions transform to implement "onClick" handler for the table
    const handleRegionsTransform = useMemo(() => {
      if (onClick) {
        return (region: IRegion, evt: MouseEvent | KeyboardEvent) => {
          // ignore clicks on select toggle
          if ((evt.target as Element).closest('.select-toggle')) {
            return region;
          }

          const rowIdx = get('rows[0]', region);
          const colIdx = get('cols[0]', region);

          // since we are actually creating side effects in the table state changing function, make it
          // off the current call stack, letting the table state get changed before the side effect
          rowIdx != null &&
            colIdx != null &&
            rowIdx < rows.length &&
            delay(0, () => onClick(rowIdx, colIdx));
          return region;
        };
      }
    }, [onClick, rows.length]);

    const handleResize = useCallback(
      (entries: IResizeEntry[]) => {
        const { contentRect } = entries[0];
        setContainerWidth(contentRect.width);
      },
      [setContainerWidth]
    );

    useEffect(() => {
      if (activeRow != null && renderedCompletely) {
        // The "renderedCompletely" variable was added because the scrolling didn't execute in case of situation just
        // after opening the page (when "activeRow" is not null and the component is just rendered). This was caused by
        // the fact that ref was yet undefined (so the table wasn't fully mounted). So to solve this, we need to wait
        // until the render of the table is completed and then scroll it. The "renderedCompletely" is should be set to
        // true and never changed later.
        scrollRowIntoView(activeRow);
      }
    }, [scrollRowIntoView, activeRow, tableId, renderedCompletely]);

    const getScrollContainer = useCallback(() => {
      const $table = getTableDOMElement() as HTMLElement | null;
      return $table?.querySelector<HTMLElement>(`.${TABLE_QUADRANT_SCROLL_CONTAINER}`);
    }, []);

    const checkScrollPosition = useCallback(() => {
      if (!onScrollBottomChange) {
        return;
      }
      const $scrollContainer = getScrollContainer();
      const isScrollBottomReached =
        $scrollContainer &&
        $scrollContainer.scrollTop + $scrollContainer.offsetHeight >=
          $scrollContainer.scrollHeight - 3;
      if (isScrollBottomReached != null) {
        onScrollBottomChange(isScrollBottomReached);
      }
    }, [getScrollContainer, onScrollBottomChange]);

    // workaround for https://github.com/palantir/blueprint/issues/3757,
    const onCompleteRender = useCallback(() => {
      if (tableRef.current == null) return;

      const { viewportRect } = tableRef.current.state;
      if (viewportRect && viewportRect.top == null) {
        const nextViewportRect = new Rect(
          viewportRect.left,
          0,
          viewportRect.width,
          viewportRect.height
        );
        tableRef.current.setState({ viewportRect: nextViewportRect });
      }
      setRenderedCompletely(true);
      checkScrollPosition();
    }, [checkScrollPosition]);

    const handleDoubleClick = useCallback(
      (evt) => {
        if (onDoubleClick && evt.target.closest(`.${TABLE_CELL}`)) {
          onDoubleClick();
        }
      },
      [onDoubleClick]
    );

    return (
      <ResizeSensor onResize={handleResize}>
        <div
          className={`w-full overflow-hidden ${className ?? ''}`}
          onDoubleClick={handleDoubleClick}
        >
          {containerWidth == null ? null : (
            <Table2
              ref={tableRef}
              css={css(
                tableCss,
                tableCustomCss,
                isNil(isCollapsed) ? null : getTableCollapseExpandCss(isCollapsed)
              )}
              numRows={numRows}
              numFrozenColumns={numFrozenColumns}
              selectionModes={SelectionModes.ROWS_ONLY}
              enableRowHeader={false}
              defaultRowHeight={defaultRowHeight ?? 50}
              onVisibleCellsChange={handleVisibleCellsChange}
              columnWidths={colWidths.length === cols.length ? colWidths : undefined}
              selectedRegions={activeRow == null ? activeRow : [Regions.row(activeRow)]}
              onColumnWidthChanged={handleColumnWidthChange}
              selectedRegionTransform={handleRegionsTransform}
              styledRegionGroups={styledRegions}
              renderMode={RenderMode.NONE}
              onCompleteRender={onCompleteRender}
              cellRendererDependencies={[rowsSelected, cellContentRenderer, containerWidth]}
            >
              {cols.map(colMapper)}
            </Table2>
          )}
        </div>
      </ResizeSensor>
    );
  }
);

export default GbaTable;
