/** @jsx jsx */
import { jsx } from '@emotion/core';
// import { differenceInDays } from 'date-fns/fp';
import { Alert, Classes, Divider, Intent } from '@blueprintjs/core';
import { MemberedProject, UserData } from '../users_panel';
import Header from './header';
import AccountDetails from './account_details';
import UserInfo from './user_info';
import UserProjects from './user_projects';
import EditUserDialog from '../edit_user_dialog';
import PageContent from '../../common/page_content';
import React, { useCallback, useMemo, useState } from 'react';
import { userDataToUserFields } from '../../../lib/user_helpers';
import { t, Trans } from '@lingui/macro';
import { useI18n } from '../../../lib/utils';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
// import { get } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
// import { AccountType } from '../../../common/types';
import useActionLogger from '../../hooks/use_action_logger';
// import { getHumanizedDuration } from '../../project/helpers';

// const AUTOMATIC_DELETION_PERIOD = 90;
// const AUTOMATIC_DELETION_WARNING_PERIOD = 30;

// const UserFragment = gql`
//   fragment UserFragment on User {
//     id
//     autoDeleteEnabled
//   }
// `;

const UpdateUserMutation = gql`
  mutation UpdateUser($id: String!, $userData: UserUpdateInput!) {
    update_user(id: $id, object: $userData) {
      id
      # autoDeleteEnabled
    }
  }
`;

const DeleteUserMutation = gql`
  mutation DeleteUser($id: String!) {
    delete_user(id: $id) {
      id
    }
  }
`;

interface IUserDetailsProps {
  user: UserData;
  userProjects: MemberedProject[];
  onClose: () => void;
  lastActivity?: string;
}

const UserDetails: React.FC<IUserDetailsProps> = ({
  user,
  onClose,
  userProjects,
  lastActivity,
}) => {
  const insertActionLog = useActionLogger();
  const [userEditOpen, setUserEditOpen] = useState(false);
  const [updateUser] = useMutation(UpdateUserMutation);
  const [deleteUser] = useMutation(DeleteUserMutation);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const i18n = useI18n();
  // const excludedFromAutoDelete = !(user.autoDeleteEnabled ?? true);

  const toggleUserEdit = useCallback(() => {
    setUserEditOpen((isOpen) => !isOpen);
  }, [setUserEditOpen]);

  const handleUserDataSave = useCallback(
    async (userData) => {
      const variables = {
        id: user.id,
        userData: {
          ...userData,
          role: userData.role.replace('gba-app:', ''),
        },
      };
      return updateUser({
        variables,
      }).then(() => insertActionLog('updated user', variables));
    },
    [updateUser, user.id, insertActionLog]
  );
  const handleUserDelete = useCallback(() => {
    deleteUser({
      variables: {
        id: user.id,
      },
    }).then(() => {
      insertActionLog('deleted user', { id: user.id });
      onClose();
    });
    setDeleteConfirmationVisible(false);
  }, [deleteUser, user, setDeleteConfirmationVisible, insertActionLog, onClose]);

  // const handleDeletionRoutineExclude = useCallback(
  //   (evt) => {
  //     const excluded = evt.target.checked;

  //     updateUser({
  //       variables: {
  //         id: user.id,
  //         userData: {
  //           autoDeleteEnabled: !excluded,
  //         },
  //       },
  //       optimisticResponse: {
  //         update_user: {
  //           autoDeleteEnabled: !excluded,
  //           id: user.id,
  //           __typename: 'User',
  //         },
  //       },
  //       update: (proxy, { data }) => {
  //         const mutationResult = get('update_user', data);
  //         if (mutationResult == null) return;
  //         const cacheId = `User:${mutationResult.id}`;
  //         proxy.writeFragment({
  //           id: cacheId,
  //           fragment: UserFragment,
  //           data: { ...mutationResult },
  //         });
  //       },
  //     });
  //   },
  //   [updateUser, user.id]
  // );

  // const [daysUntilDeletion, daysUntilDeletionString] = useMemo(() => {
  //   const daysInactive = lastActivity ? differenceInDays(new Date(lastActivity), new Date()) : 0;
  //   // if daysInactive >= AUTOMATIC_DELETION_PERIOD, it means that the account will be
  //   // deleted on the following day
  //   const daysUntilDeletion = Math.max(AUTOMATIC_DELETION_PERIOD - daysInactive, 1);
  //   return [daysUntilDeletion, getHumanizedDuration({ days: daysUntilDeletion })];
  // }, [lastActivity]);

  const userData = useMemo(() => {
    return userDataToUserFields(user);
  }, [user]);

  return (
    <PageContent>
      <Header
        userName={user.name}
        onClose={onClose}
        salutation={user.salutation ?? ''}
        userTitle={user.title ?? ''}
        userRole={user.role}
        // excludedFromAutoDelete={excludedFromAutoDelete}
        // handleDeletionRoutineExclude={handleDeletionRoutineExclude}
        onUserEdit={toggleUserEdit}
        onUserDelete={() => setDeleteConfirmationVisible(true)}
      />
      {/* {daysUntilDeletion < AUTOMATIC_DELETION_WARNING_PERIOD &&
        user.role === AccountType.User &&
        !excludedFromAutoDelete && (
          <div className="flex flex-row justify-between items-center p-3 bg-red-200 text-xs">
            <Trans>
              User account will be removed from the system in {daysUntilDeletionString}.
            </Trans>
          </div>
        )} */}
      <div className={`${Classes.ELEVATION_0} h-full flex flex-row flex-no-wrap overflow-auto`}>
        <div className="flex-1">
          <AccountDetails
            user={user}
            lastActivity={lastActivity}
            // inactive={daysUntilDeletion < AUTOMATIC_DELETION_WARNING_PERIOD}
          />
          <Divider className="w-full m-0" />
          <UserInfo user={user} />
        </div>
        <Divider className="h-full m-0" />
        <div className="flex-1 overflow-auto">
          <UserProjects projects={userProjects} />
        </div>
      </div>
      <EditUserDialog
        userData={userData}
        isOpen={userEditOpen}
        onSave={handleUserDataSave}
        onClose={toggleUserEdit}
      />
      <Alert
        isOpen={deleteConfirmationVisible}
        intent={Intent.DANGER}
        icon={IconNames.WARNING_SIGN}
        confirmButtonText={i18n._(t`Delete`)}
        cancelButtonText={i18n._(t`Cancel`)}
        onCancel={() => setDeleteConfirmationVisible(false)}
        onConfirm={handleUserDelete}
      >
        <Trans>Are you sure you want to delete the user?</Trans>
      </Alert>
    </PageContent>
  );
};

export default UserDetails;
