/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Intent, OverflowList, Popover, Position, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { join, map } from 'lodash/fp';
import { TScreeningTag } from './';

const ScreeningTag = ({ tag }) => {
  return (
    <div className="border rounded p-1 flex flex-row justify-between items-center">
      <span>{tag}</span>
    </div>
  );
};

const tagToLabel = ({ id, tag }: TScreeningTag) => <ScreeningTag key={id} tag={tag} />;

const overflowRenderer = (tags: TScreeningTag[]) => (
  <Popover
    interactionKind="hover"
    position={Position.BOTTOM}
    content={<div className="p-2">{join(', ', map('tag', tags))}</div>}
  >
    <Tag className="ml-2" minimal intent={Intent.PRIMARY} icon={IconNames.PLUS}>
      {tags.length}
    </Tag>
  </Popover>
);

interface IReferenceTagsProps {
  tags: TScreeningTag[];
}

const ReferenceTags: React.FC<IReferenceTagsProps> = ({ tags }) => {
  return (
    <div className="inline-flex flex-row flex-no-wrap whitespace-normal">
      <OverflowList
        className="items-center"
        collapseFrom="end"
        items={tags}
        overflowRenderer={overflowRenderer}
        visibleItemRenderer={tagToLabel}
      />
    </div>
  );
};

export default ReferenceTags;
