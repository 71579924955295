/** @jsx jsx */
import { Alert, Intent } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { plural, t } from '@lingui/macro';
import { isNil } from 'lodash/fp';
import React, { useCallback } from 'react';
import { ProjectStatus } from '../../common/types';
import { useI18n } from '../../lib/utils';

interface BatchActionsAlertProps {
  type: ProjectStatus | null;
  isOpen: boolean;
  projectIds: string[];
  onConfirm: (payload: { projectIds: string[]; status: ProjectStatus }) => Promise<any>;
  onCancel: () => void;
}

const BatchActionsAlert: React.FC<BatchActionsAlertProps> = ({
  type,
  isOpen,
  projectIds,
  onConfirm,
  onCancel,
}: BatchActionsAlertProps) => {
  const i18n = useI18n();

  const alertIntent =
    type === ProjectStatus.Archived
      ? Intent.WARNING
      : type === ProjectStatus.InTrash
      ? Intent.DANGER
      : Intent.PRIMARY;

  const projectsCount = projectIds.length;

  const getAlertPromptAndConfirmButtonLabel = useCallback(() => {
    switch (type) {
      case ProjectStatus.Archived:
        return {
          alertPrompt: i18n._(
            plural({
              value: projectsCount,
              one: 'Are you sure to archive the project?',
              other: 'Are you sure to archive # projects?',
            })
          ),
          confirmButtonLabel: i18n._(
            plural({
              value: projectsCount,
              one: 'Archive project',
              other: 'Archive # projects',
            })
          ),
        };
      case ProjectStatus.InTrash:
        return {
          alertPrompt: i18n._(
            plural({
              value: projectsCount,
              one: 'Are you sure to delete the project?',
              other: 'Are you sure to delete # projects?',
            })
          ),
          confirmButtonLabel: i18n._(
            plural({
              value: projectsCount,
              one: 'Delete project',
              other: 'Delete # projects',
            })
          ),
        };
      case ProjectStatus.Active:
        return {
          alertPrompt: i18n._(
            plural({
              value: projectsCount,
              one: 'Are you sure to restore the project?',
              other: 'Are you sure to restore # projects?',
            })
          ),
          confirmButtonLabel: i18n._(
            plural({
              value: projectsCount,
              one: 'Restore project',
              other: 'Restore # projects',
            })
          ),
        };
      default:
        return {};
    }
  }, [i18n, type, projectsCount]);

  const { alertPrompt, confirmButtonLabel } = getAlertPromptAndConfirmButtonLabel();

  const handleConfirm = useCallback(() => {
    if (isNil(type)) return;
    onConfirm({
      projectIds,
      status: type,
    });
  }, [onConfirm, projectIds, type]);

  return (
    <Alert
      isOpen={isOpen}
      cancelButtonText={i18n._(t`Cancel`)}
      confirmButtonText={confirmButtonLabel}
      intent={alertIntent}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <p className="font-bold mb-3">{alertPrompt}</p>
    </Alert>
  );
};

export default BatchActionsAlert;
