/** @jsx jsx */
import { Classes, Colors, Intent, Spinner } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Document, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { download } from '../../lib/storageEndpoint';
import { usePrevious, useSetState } from '../../lib/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import AppToaster from '../../lib/toaster';
import SearchBar, { ISearchBarProps } from './search_bar';
import DocPages from './doc_pages';
import DocPage from './doc_page';
import PageThumbnail from './page_thumbnail';
import { debounce, get, isEmpty, keys, min, range } from 'lodash/fp';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import PDFSearch, { SearchResults } from '../../lib/pdf_search_provider';
import { StringFragmentDecorator } from '../../lib/decorated_string';
import {
  INITIAL_PDF_SCALE,
  initialStateProvider,
  INITIAL_NUM_PAGES,
  INITIAL_ACTIVE_PAGE,
  initialSelectionData,
  INITIAL_PDF_ROTATION_ANGLE,
} from '../../apollo/pdf_viewer_state';
import { useKeycloak } from '../../keycloak';
import { getPDFSearchConfigFromCurrentSelection } from '../../lib/pdf_search_utils';
import { getSelectionText } from '../../lib/pdf_utils';
import { Buckets } from '../../lib/attachmentUpload';

const scrollToPageMutation = loader('../../graphql/local/set_scroll_to_pdf_page.gql');
const UpdatePDFViewerStateMutation = loader('../../graphql/local/update_pdf_viewer_state.gql');
const setPDFTextSelectionMutation = loader('../../graphql/local/set_pdf_text_selection_data.gql');

const containerCss = css`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: transparent;
  flex-flow: row nowrap;

  &.${Classes.DARK} {
    background-color: ${Colors.DARK_GRAY1};
  }

  input[readonly] {
    cursor: pointer;
  }
`;

const thumbnailsCss = css`
  position: relative;
  flex: 0 0 142px;
  display: flex;
  flex-flow: column;
  overflow: auto;

  .thumbs-top-bar {
    position: relative;
    &:before {
      display: block;
      border-right: 1px solid ${Colors.LIGHT_GRAY2};
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 40px;
    }
  }
`;

const pagesCss = css`
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const pdfViewerCss = css`
  position: relative;
  flex: 1 1 auto;
  overflow: auto;

  .dexter-pdf-viewer {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    overflow: auto;

    .document-pages {
      & > div {
        position: relative;
      }
    }

    .thumbnails-container .document-pages {
      padding: 0 0.75rem 0 0;
    }
  }

  .pdf_page_wrapper {
    text-align: center;
  }

  .react-pdf__Page {
    display: inline-block;
  }

  .react-pdf__Page__textContent {
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
  }
`;

const GetPDFViewerStateQuery = gql`
  query GetPDFViewerState {
    PDFViewer @client {
      activePage
      numPages
      pdfScale
      pdfRotationAngle
      customRanges
      scrollToPage
      scrollToRange
      search {
        isOpen
        results
        activeMatchRanges
      }
    }
  }
`;

interface IPDFViewerProps {
  fileKey: string;
  customRangeDecorator?: StringFragmentDecorator;
  onDocumentLoadSuccess?: (doc: PDFDocumentProxy) => void;
  showThumbnails?: boolean;
  searchBarPosition?: ISearchBarProps['position'];
  withSearch?: boolean;
  fitToWidth?: boolean;
}

interface PdfViewerState {
  file: File | null;
  Doc: pdfjs.PDFDocumentProxy | null;
  $pagesContainer: HTMLDivElement | null;
}

const PDFViewer: React.FC<IPDFViewerProps> = ({
  fileKey,
  customRangeDecorator,
  onDocumentLoadSuccess,
  showThumbnails,
  searchBarPosition,
  withSearch = true,
  fitToWidth,
}) => {
  const fetchedFilesCache = useRef<{[key: string]: File}>({});
  const apolloAuthClient = useKeycloak();
  const { data } = useQuery(GetPDFViewerStateQuery);
  const [state, setState] = useSetState<PdfViewerState>({
    file: null,
    Doc: null,
    $pagesContainer: null,
  });
  const [setPDFTextSelection] = useMutation(setPDFTextSelectionMutation);
  const [clearPDFTextSelection] = useMutation(setPDFTextSelectionMutation, {
    variables: { ...initialSelectionData },
  });
  const [resetScrollToPage] = useMutation(scrollToPageMutation, { variables: { page: null } });
  const [updatePDFViewerState] = useMutation(UpdatePDFViewerStateMutation);
  const pageThumbsRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
  const pagesRef = useCallback(
    (instance: HTMLDivElement) => setState({ $pagesContainer: instance }),
    [setState]
  );
  const { file, Doc, $pagesContainer } = state;
  const pdfScale = get('PDFViewer.pdfScale', data) ?? INITIAL_PDF_SCALE;
  const numPages = get('PDFViewer.numPages', data) ?? INITIAL_NUM_PAGES;
  const activePage = get('PDFViewer.activePage', data) ?? INITIAL_ACTIVE_PAGE;
  const pdfRotationAngle = get('PDFViewer.pdfRotationAngle', data) ?? INITIAL_PDF_ROTATION_ANGLE;
  const pageToScrollTo = get('PDFViewer.scrollToPage', data) ?? null;
  const activeMatchRanges = get('PDFViewer.search.activeMatchRanges', data) ?? {};

  const handleDocumentLoad = (Doc: PDFDocumentProxy): void => {
    setState({ Doc });
    updatePDFViewerState({
      variables: {
        data: {
          numPages: Doc.numPages,
        },
      },
    });

    onDocumentLoadSuccess?.(Doc);
  };

  const pageNums = useMemo(() => (Doc ? range(1, numPages + 1) : []), [numPages, Doc]);
  const searchDoc = useMemo(() => (Doc ? PDFSearch.getDocSearch(Doc) : null), [Doc]);

  const scrollToPage = useCallback(
    (pageIdx) => {
      if ($pagesContainer) {
        $pagesContainer.children[pageIdx]?.scrollIntoView();
      }
    },
    [$pagesContainer]
  );

  // PDF fetching
  useEffect(() => {
    const maybeCached = fetchedFilesCache.current[fileKey];
    if (maybeCached) {
      setState({ file: maybeCached });
      return;
    }
    // reset current file
    setState({ file: null });
    // cancelled flag is used for cleanup purposes in case component receives a different fileKey
    // while previous one is being fetch
    let cancelled = false;
    // for similar reason we are using an AbortController to cancel the fetch request
    const abortController = new AbortController();
    // tracking fetching state to avoid unneeded cancelling
    let fetching = false;

    (async function() {
      try {
        fetching = true;
        const token = await apolloAuthClient.getToken();
        const response = await download(Buckets.ReferencesAttachments.name, fileKey, token, abortController.signal);
        fetching = false;
        if (response?.data && !cancelled) {
          const file = new File([response.data], fileKey);
          // update the fetched files cache
          fetchedFilesCache.current[fileKey] = file;
          setState({ file });
        }
      } catch (error) {
        fetching = false;
        AppToaster.show({
          intent: Intent.WARNING,
          message: (error as Error).message ?? (error as Error).toString(),
        });
      }
    })();

    return () => {
      if (fetching) {
        abortController.abort();
        cancelled = true;
      }
    };
  }, [fileKey]);

  // scroll the active match text into view
  useEffect(() => {
    if (!isEmpty(activeMatchRanges)) {
      const pageNum = min(keys(activeMatchRanges));
      if (pageNum) {
        const pageIdx = parseInt(pageNum) - 1;
        scrollToPage(pageIdx);
      }
    }
  }, [scrollToPage, activeMatchRanges]);

  const scrollToActivePage = useCallback(() => {
    scrollToPage(activePage);
  }, [scrollToPage, activePage]);

  // when scaling the active page may get off the viewport
  useEffect(scrollToActivePage, [pdfScale]);

  // scrolls to requested page once
  useEffect(() => {
    if (pageToScrollTo != null && $pagesContainer != null) {
      scrollToPage(pageToScrollTo - 1);
      resetScrollToPage();
    }
  }, [pageToScrollTo, scrollToPage, resetScrollToPage, $pagesContainer]);

  useEffect(() => {
    if (!searchDoc) return;

    const selectionHandler = debounce(200, () => {
      const selection = document.getSelection();
      const selectedTextSearchConfig = getPDFSearchConfigFromCurrentSelection();

      if (selection && selectedTextSearchConfig) {
        const selectionText = selection.toString().trim();
        const searchHandle = searchDoc(selectionText, selectedTextSearchConfig);

        searchHandle.promise
          .then((results: SearchResults) => {
            if (!isEmpty(results)) {
              setPDFTextSelection({
                variables: {
                  // selectionText returned by Selection.toString() (above) sometimes misses spaces.
                  // It depends on PDF structure. However it has to be used to be able to search
                  // within the doc.
                  // But here we can use user-friendly (improved selection text) since this is not
                  // used for search, but for value binding
                  text: getSelectionText(selection),
                  ranges: results,
                },
              });
            }
          })
          .catch((err) => AppToaster.show({ intent: Intent.DANGER, message: err }));
      } else {
        // clear selection
        clearPDFTextSelection();
      }
    });

    document.addEventListener('selectionchange', selectionHandler);
    return () => {
      document.removeEventListener('selectionchange', selectionHandler);
    };
  }, [searchDoc, clearPDFTextSelection, setPDFTextSelection]);

  return file == null ? (
    <Spinner className="h-full" size={150} />
  ) : (
    <div className="w-full h-full">
      <div css={containerCss}>
        <div css={pdfViewerCss}>
          {withSearch && <SearchBar searchDoc={searchDoc} position={searchBarPosition} />}
          <Document
            className="dexter-pdf-viewer"
            file={file}
            onLoadSuccess={handleDocumentLoad}
            loading={<Spinner className="h-full w-full" intent={Intent.WARNING} size={150} />}
          >
            {showThumbnails && (
              <div css={thumbnailsCss} className="thumbnails-container">
                <DocPages
                  ref={pageThumbsRef}
                  pageNums={pageNums}
                  scale={1}
                  pageRenderer={PageThumbnail}
                  itemSize={160}
                />
              </div>
            )}
            <div css={pagesCss} className="pages-container">
              <DocPages
                ref={pagesRef}
                pageNums={pageNums}
                scale={pdfScale}
                pageRenderer={DocPage}
                customRangeDecorator={customRangeDecorator}
                fitToWidth={fitToWidth}
                rotationAngle={pdfRotationAngle}
              />
            </div>
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;
