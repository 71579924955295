/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { SearchAndDesiredUndesiredKeywords } from '../../lib/criteria_utils';
import { HighlightingType, highlightKeywords } from '../../lib/highlighter';
import { useTheme } from '../settings/theme_context';

interface ITextWithHighlights {
  className?: string;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  text: string;
}

const TextWithHighlights: React.FC<ITextWithHighlights> = ({ className, keywordsData, text }) => {
  const { desiredKeywords, searchKeywords, undesiredKeywords } = keywordsData;
  const { statusColors } = useTheme();

  const textWithHighlightsCss = useMemo(
    () => css`
      & > mark.${HighlightingType.Desired} {
        color: ${statusColors.acceptedSecondary};
        background: ${statusColors.acceptedPrimary};
      }
      & > mark.${HighlightingType.Undesired} {
        color: ${statusColors.rejectedSecondary};
        background: ${statusColors.rejectedPrimary};
      }
      & > mark.${HighlightingType.DesiredAndUndesired} {
        color: ${statusColors.acceptedSecondary};
        background: linear-gradient(
          to bottom,
          ${statusColors.rejectedPrimary} 50%,
          ${statusColors.acceptedPrimary} 0%
        );
      }
      & > mark.${HighlightingType.Search} {
        background: ${statusColors.neutralPrimary};
        color: ${statusColors.neutralSecondary};
      }
    `,
    [statusColors]
  );

  const chunks = useMemo(
    () => highlightKeywords(desiredKeywords, undesiredKeywords, searchKeywords, text),
    [desiredKeywords, searchKeywords, undesiredKeywords, text]
  );

  return (
    <span className={className ?? ''} css={textWithHighlightsCss}>
      {chunks.map(({ start, end, type }) => {
        const key = `${start}_${end}_${type}`;
        if (type === HighlightingType.NoHighlight) {
          return <span key={key}>{text.substring(start, end)}</span>;
        } else {
          return (
            <mark className={type} key={key}>
              {text.substring(start, end)}
            </mark>
          );
        }
      })}
    </span>
  );
};

export default TextWithHighlights;
