/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ReactNode } from 'react';
import { swapArrayItems } from '../../../lib/utils';

interface IHierarchyStrategyProps {
  itemsOrder: string[];
  onChange: (newOrder: string[]) => void;
  itemRenderer: (itemKey: string) => ReactNode;
  label?: ReactNode;
  className?: string;
}

const HierarchyStrategy: React.FC<IHierarchyStrategyProps> = ({
  itemsOrder,
  onChange,
  itemRenderer,
  label,
  className,
}) => {
  const swapItems = (aIdx, bIdx) => () => {
    const newOrder = swapArrayItems(aIdx, bIdx, itemsOrder);

    onChange(newOrder);
  };

  return (
    <div className={className}>
      {label && <div className="text-sm text-gray-700 mb-4">{label}</div>}
      <div>
        {itemsOrder.map((itemKey, idx) => (
          <div
            key={itemKey}
            className={`rounded flex flex-row items-center bg-white py-2 px-3 ${
              idx === 0 ? '' : 'mt-2'
            } ${Classes.ELEVATION_0}`}
          >
            <div className="flex-1">{itemRenderer(itemKey)}</div>
            <div className="flex flex-col flex-none">
              <Button
                minimal
                small
                disabled={idx === 0}
                icon={IconNames.CARET_UP}
                onClick={swapItems(idx - 1, idx)}
              />
              <Button
                minimal
                small
                disabled={idx === itemsOrder.length - 1}
                onClick={swapItems(idx, idx + 1)}
                icon={IconNames.CARET_DOWN}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HierarchyStrategy;
