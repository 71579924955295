/** @jsx jsx */
import { Colors, Divider } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header2 from '../header2';
import AppLogo from '../logo';
import ProjectBreadcrumbs from '../project/breadcrumbs';

interface IDefaultPageProps extends RouteComponentProps {
  withProjectBreadcrumbs?: boolean;
}

const DefaultPage: React.FC<IDefaultPageProps> = ({
  withProjectBreadcrumbs,
  children,
  ...props
}) => {
  return (
    <div className="w-full h-full flex flex-col flex-1 overflow-auto" style={{ paddingTop: 50 }}>
      <Header2 {...props}>
        <AppLogo className="h-full" />
        {withProjectBreadcrumbs && (
          <React.Fragment>
            <Divider className="h-full m-0" css={{ borderColor: Colors.GRAY1 }} />
            <ProjectBreadcrumbs {...props} />
          </React.Fragment>
        )}
      </Header2>
      {children}
    </div>
  );
};

export default DefaultPage;
