/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Button, Divider, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { screeningListColHeaderHeight } from '../../common/styles';

interface IPreviewHeaderProps {
  onToggle?: () => void;
}

const PreviewHeader: React.FC<IPreviewHeaderProps> = ({ onToggle }) => {
  return (
    <div
      className="flex-none flex flex-row flex-no-wrap items-center border-b"
      css={screeningListColHeaderHeight}
    >
      <div className="flex flex-grow flex-row justify-between">
        <span className="ml-3 text-lg text-gray-700">
          <Trans>Bibliographic data</Trans>
        </span>
      </div>
      {onToggle && (
        <Fragment>
          <Button
            className="h-full flex-none"
            icon={<Icon className="p-2" icon={IconNames.CROSS} iconSize={16} />}
            onClick={onToggle}
            large
            minimal
          />
          <Divider className="h-full m-0" />
        </Fragment>
      )}
    </div>
  );
};

export default PreviewHeader;
