/** @jsx jsx **/
import { Colors, Divider, ITreeNode, Tree, TreeEventHandler } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { t } from '@lingui/macro';
import { countBy, filter, get, isEmpty, update, reduce } from 'lodash/fp';
import React, { Fragment, useMemo, useState } from 'react';
import { DomainItem, UserKeyword } from '../../common/types';
import { useCurrCallback, useI18n } from '../../lib/utils';
import { KEYWORDS_GENERAL_CATEGORIES } from './keywords_utils';

const domainFilterItemCss = css`
  cursor: pointer;

  &:hover {
    background-color: ${Colors.LIGHT_GRAY2};
  }

  &.active {
    color: #ffffff;
    background-color: ${Colors.BLUE3};
  }
`;

interface ITreeNodeData {
  domain: string;
  variable: string | null;
}

interface IKeywordsTreeProps {
  domainItems: DomainItem[];
  currentDomain: string;
  currentVariable: string | null;
  keywords: UserKeyword[];
  onItemClicked: (nodeData: ITreeNodeData) => void;
}

type ExpandedElements = {
  [domain: string]: boolean;
};

const KeywordsTree: React.FC<IKeywordsTreeProps> = ({
  domainItems,
  currentDomain,
  currentVariable,
  keywords,
  onItemClicked,
}) => {
  const i18n = useI18n();

  const [expandedElements, setExpandedElements] = useState<ExpandedElements>({
    [currentDomain]: true,
  });
  const countByDomains = useMemo(() => countBy('domain', keywords), [keywords]);
  const uncategorizedCount = useMemo(() => filter({ domain: null }, keywords).length, [keywords]);
  const countByDomainsByVariables = useMemo(
    () =>
      reduce(
        (acc, { domain, variable }) => {
          if (domain && variable) {
            return update(`${domain}.${variable}`, (count) => (count ?? 0) + 1, acc);
          }
          return acc;
        },
        {} as { [domain: string]: { [variable: string]: number } },
        keywords
      ),
    [keywords]
  );

  const contents = useMemo(
    () =>
      domainItems.map<ITreeNode<ITreeNodeData>>(({ domain, variables }) => {
        const isExpanded = !isEmpty(variables) && (get(domain, expandedElements) ?? false);
        const count = countByDomains[domain] ?? 0;
        return {
          id: domain,
          label: `${domain} (${count})`,
          icon: isExpanded ? IconNames.FOLDER_OPEN : IconNames.FOLDER_CLOSE,
          childNodes: variables.map<ITreeNode<ITreeNodeData>>((variable) => {
            const count = get(`${domain}.${variable}`, countByDomainsByVariables) ?? 0;
            return {
              id: `${domain}_${variable}`,
              label: `${variable} (${count})`,
              icon: IconNames.VARIABLE,
              hasCaret: false,
              isExpanded: false,
              isSelected: currentDomain === domain && currentVariable === variable,
              nodeData: { domain, variable },
            };
          }),
          hasCaret: !isEmpty(variables),
          isExpanded,
          isSelected: currentDomain === domain && (!isExpanded || currentVariable === null),
          nodeData: { domain, variable: null },
        };
      }),
    [
      countByDomains,
      countByDomainsByVariables,
      currentDomain,
      currentVariable,
      domainItems,
      expandedElements,
    ]
  );

  const onNodeExpand: TreeEventHandler<ITreeNodeData> = ({ nodeData }) => {
    const domain = nodeData!.domain;
    const newExpandedElements = { ...expandedElements, [domain]: true };
    setExpandedElements(newExpandedElements);
  };
  const onNodeCollapse: TreeEventHandler<ITreeNodeData> = ({ nodeData }) => {
    const domain = nodeData!.domain;
    const newExpandedElements = { ...expandedElements, [domain]: false };
    setExpandedElements(newExpandedElements);
  };
  const onNodeClick: TreeEventHandler<ITreeNodeData> = ({ nodeData }) => {
    const { domain } = nodeData!;
    onItemClicked(nodeData!);
    setExpandedElements({ ...expandedElements, [domain]: true });
  };

  const handleGeneralCategorySelect = useCurrCallback(
    (name: string, _evt) => {
      onItemClicked({ domain: name, variable: null });
    },
    [onItemClicked]
  );

  const generalCategoriesCounts = {
    All: keywords.length,
    Uncategorized: uncategorizedCount,
  };

  const generalCategoriesNames = {
    All: i18n._(t`All`),
    Uncategorized: i18n._(t`Uncategorized`),
  };

  return (
    <div className="flex flex-col overflow-auto">
      {KEYWORDS_GENERAL_CATEGORIES.map((generalCategory) => (
        <Fragment key={generalCategory}>
          <div
            className={`${
              currentDomain === generalCategory ? 'active ' : ''
            }flex p-3 text-base text-gray-600 justify-between`}
            css={domainFilterItemCss}
            onClick={handleGeneralCategorySelect(generalCategory)}
          >
            <span>{generalCategoriesNames[generalCategory]}</span>
            <span>({generalCategoriesCounts[generalCategory]})</span>
          </div>
          <Divider />
        </Fragment>
      ))}
      <div className="overflow-auto">
        <Tree
          contents={contents}
          onNodeClick={onNodeClick}
          onNodeCollapse={onNodeCollapse}
          onNodeExpand={onNodeExpand}
        />
      </div>
    </div>
  );
};

export default KeywordsTree;
