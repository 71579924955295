/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { Card, Colors, Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import {
  CountByStatus,
  GraphQLAggregateData,
  ScreeningListMode,
  StageType,
} from '../../common/types';
import { getTaskCounts } from '../../lib/task_helpers';
import ProgressBar from '../common/progress_bar';
import { IconNames } from '@blueprintjs/icons';
import { LocationDescriptor } from 'history';
import { useKeycloak } from '../../keycloak';

const collapseContentCss = css`
  background-color: ${Colors.LIGHT_GRAY1};
  padding: 13px;
`;

const cardTopCss = css`
  cursor: pointer;
`;

const activeCardTopCss = css`
  background: #5c7080;
  color: ${Colors.WHITE};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  ${cardTopCss}
`;

const markCss = css`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

interface INavToScreeningCardProps {
  path: LocationDescriptor;
  label: ReactNode;
  count: number;
  markColor: string;
}

const NavToScreeningCard: React.FC<INavToScreeningCardProps> = ({
  markColor,
  path,
  label,
  count,
}) => {
  const history = useHistory();
  const navToScreening = useCallback(() => {
    history.push(path);
  }, [history, path]);

  return (
    <Card
      onClick={navToScreening}
      interactive
      className="p-0 cursor-pointer h-16 mb-4"
      data-testid="list-link"
    >
      <div className="flex flex-row items-center h-full">
        <div
          className="flex-none w-2  h-full"
          css={markCss}
          style={{ backgroundColor: markColor }}
        />
        <div className="flex-1 text-xl pl-5 h-full flex items-center">
          <span>{label}</span>
          <span className="ml-2">({count})</span>
        </div>
        <Icon icon={IconNames.CHEVRON_RIGHT} className="mx-5" />
      </div>
    </Card>
  );
};

export type StageCardProps = {
  active: boolean;
  id: string;
  projectId: string;
  name: string;
  type: StageType;
  tasksByStatus: CountByStatus[];
  toScreenAggregate: GraphQLAggregateData;
  completedAggregate: GraphQLAggregateData;
  conflictsAggregate: GraphQLAggregateData;
  onActivate: () => void;
};

const StageCard: React.FC<StageCardProps> = ({
  active,
  id,
  name,
  type,
  toScreenAggregate,
  tasksByStatus,
  conflictsAggregate,
  projectId,
  completedAggregate,
  onActivate,
}) => {
  const { isAdmin } = useKeycloak();
  const stageUrl = useMemo(() => {
    return ['/projects', projectId, `${isAdmin ? 'screening/' : ''}stages`, id].join('/');
  }, [projectId, id, isAdmin]);

  const conflictsCount = conflictsAggregate.aggregate.count;
  const completedCount = completedAggregate.aggregate.count;
  const toScreenCount = toScreenAggregate.aggregate.count;
  const taskCounts = useMemo(() => {
    if (type === StageType.DataExtraction) return null;
    return getTaskCounts(toScreenCount + completedCount, tasksByStatus);
  }, [tasksByStatus, toScreenCount, completedCount, type]);

  return (
    <div className="mb-4" data-testid="stage-card">
      <Card key={projectId} className="p-0">
        <div className="p-4" css={active ? activeCardTopCss : cardTopCss} onClick={onActivate}>
          <div className="flex flex-row flex-no-wrap items-center text-xl">
            <div className="flex-1 truncate">{name}</div>
            {taskCounts && <span>{taskCounts.progress}%</span>}
          </div>
        </div>
        {taskCounts && (
          <ProgressBar
            studiesIn={taskCounts.included}
            studiesOut={taskCounts.excluded}
            total={taskCounts.total}
            className="h-1"
          />
        )}
      </Card>
      <div css={collapseContentCss} className="py-3 pl-8 pr-10">
        {toScreenCount > 0 && (
          <NavToScreeningCard
            path={{
              pathname: `${stageUrl}/screening`,
              search: `?listMode=${ScreeningListMode.ToScreen}`,
            }}
            label={<Trans>To screen</Trans>}
            count={toScreenCount}
            markColor={Colors.GRAY3}
          />
        )}
        {completedCount > 0 && (
          <NavToScreeningCard
            path={{
              pathname: `${stageUrl}/screening`,
              search: `?listMode=${ScreeningListMode.Completed}`,
            }}
            label={<Trans>Completed</Trans>}
            count={completedCount}
            markColor={Colors.BLUE3}
          />
        )}
        {conflictsCount > 0 && (
          <NavToScreeningCard
            path={`${stageUrl}/conflicts`}
            label={<Trans>Conflicts</Trans>}
            count={conflictsCount}
            markColor={Colors.ORANGE3}
          />
        )}
      </div>
    </div>
  );
};

export default StageCard;
