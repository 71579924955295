/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Spinner } from '@blueprintjs/core';
import { find, get, isNil, orderBy, propEq } from 'lodash/fp';
import React, { Fragment, useMemo } from 'react';
import { SearchAndDesiredUndesiredKeywords } from '../../../lib/criteria_utils';
import DecisionAndCommentIndicator from '../../screening/decision_and_comment_indicator';
import PreviewHeader from '../../screening/preview_header';
import ReferencePreview from '../../screening/reference_preview';
import { fancyScrollCss } from '../../../common/styles';
import { TExclusionReason } from '../../screening';
import {
  getReferenceInclusionStatusAndReasonCodes,
  TReferenceSearchData,
} from './references_list_layouts';
import { ScreeningTag } from '../../../common/types';

interface PreviewColumnProps {
  decisionReasons: TExclusionReason[];
  onToggle: () => void;
  searchTokens: string[];
  screeningTags: ScreeningTag[];
  activeReferenceData?: TReferenceSearchData;
  loading?: boolean;
}

const PreviewColumn: React.FC<PreviewColumnProps> = ({
  activeReferenceData,
  decisionReasons,
  loading,
  onToggle,
  searchTokens,
  screeningTags,
}) => {
  const withDecisionIndicator = activeReferenceData
    ? !isNil(getReferenceInclusionStatusAndReasonCodes(activeReferenceData).inclusionStatus)
    : false;
  const inclusionStatus = get('decision', activeReferenceData);
  const decisionReason = get('decision_reason_code', activeReferenceData);
  const decisionReasonCode = find(propEq('id', decisionReason), decisionReasons)?.code;
  const highlightedWordsData: SearchAndDesiredUndesiredKeywords = useMemo(() => {
    return {
      desiredKeywords: [],
      undesiredKeywords: [],
      searchKeywords: searchTokens,
    };
  }, [searchTokens]);

  const selectedTags = useMemo(() => {
    if (activeReferenceData == null) return [];
    const latestStageResults = orderBy(
      'study_pool.stage.order_number',
      'desc',
      activeReferenceData.study.study_pool_studies
    )[0];
    return latestStageResults?.tags;
  }, [activeReferenceData]);

  return (
    <div className="h-full flex flex-col overflow-auto">
      <PreviewHeader onToggle={onToggle} />
      {loading || isNil(activeReferenceData) ? (
        <Spinner className="justify-self-center align-self-center h-full" />
      ) : (
        <div className="flex-grow overflow-auto" css={fancyScrollCss}>
          <Fragment>
            {withDecisionIndicator && (
              <DecisionAndCommentIndicator
                decision={inclusionStatus}
                decisionReason={decisionReasonCode}
                screeningTags={screeningTags}
                selectedTags={selectedTags}
                previousComments={activeReferenceData?.reference_comments}
              />
            )}
            <ReferencePreview keywordsData={highlightedWordsData} reference={activeReferenceData} />
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default PreviewColumn;
