/** @jsx jsx */
import { Button, Classes, Drawer, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { TExclusionReason, TReferenceData } from './index';
import { DomainData, ScreeningListMode, ScreeningTaskResult, StageType } from '../../common/types';
import { compose, find, get, isEmpty, isEqual, noop, propEq, reject } from 'lodash/fp';
import {
  DecisionFilter,
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveScreeningTagFilters,
  YearFilters,
} from '../../apollo/screening_state';
import { SearchAndDesiredUndesiredKeywords } from '../../lib/criteria_utils';
import { getTaskResultControlId, getReferenceComment, TTaskCounts } from '../../lib/task_helpers';
import PreviewColumn from './preview_column';
import ReferencesListColumn from './references_list_column';
import FiltersColumn from './filters_column';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { IYearsRangeFilterState } from '../references/admin/list';
import { TDomainReasonsData, TScreeningTag } from '../references/admin';
import ScreeningHeader from './screening_header';
import useReferencesListSort from '../hooks/use_references_list_sort';
import NavAndDecisionControls from './navigation_and_decision_controls';
import CommentEditDialog from './comment_edit_dialog';
import { useKeycloak } from '../../keycloak';
import { renderFiltersToggle, renderPreviewToggle } from './list_header_toggles';
import { useScreeningFilters } from '../hooks/use_screening_filters';
import CriteriaScreener from '../criteria/criteria_screener';

export const panelCollapsedColCss = css`
  width: 0;
  opacity: 0;
`;

export const panelExpandedColCss = css`
  width: 33%;
  max-width: 33vw;
  opacity: 1;
`;

export type TScreeningPanel = 'filters' | 'preview';

const SetActiveAndSelectedReferencesMutation = loader(
  '../../graphql/local/set_active_and_selected_references.gql'
);
interface IListProps {
  projectId: string;
  stageId: string;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  stageType: StageType;
  references: readonly TReferenceData[];
  selectedReferences: number[];
  setSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  handleActiveReferenceSelect: () => void;
  shiftActiveReference: (direction: -1 | 1) => void;
  handleInclude: () => void;
  handleExclude: (reasonCode: string) => void;
  handlePostpone: () => void;
  handleUnreview: () => void;
  taskCounts: TTaskCounts;
  exclusionReasons: TExclusionReason[];
  domains: DomainData[];
  reasonCodesData: TDomainReasonsData;
  filtersApplied: boolean;
  searchingReferences: boolean;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  highlightsVisible: boolean;
  onToggleHighlights: () => void;
  decisionFilter: DecisionFilter;
  onUpdateComment: (data: { comment: string; id?: string }) => Promise<any>;
  onDeleteComment: (commentId: string) => Promise<any>;
  onUpdateSelectedTags: (tags: string[]) => void;
  formId: string;
  newRankingAvailable: boolean;
  onApplyNewRanking: () => void;
  orderedBy: object;
  loadingMoreReferences: boolean;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  screeningListMode: ScreeningListMode;
  onSendDecisions: (taskIds: string[]) => Promise<any>;
  unsentTasksCount: number;
  activeReferenceResult?: Pick<
    ScreeningTaskResult,
    'form_id' | 'task_id' | 'result' | 'updated_at' | 'comment_id'
  >;
  activeReference?: number;
  onLoadMoreReferences?: () => void;
  openFocusMode?: () => void;
  loadingCounts?: boolean;
  totalReferencesCount: number;
}

const List: React.FC<IListProps> = ({
  projectId,
  stageId,
  stageType,
  references,
  openFocusMode,
  activeReference,
  selectedReferences,
  setSelectedReferences,
  handleActiveReferenceSelect,
  shiftActiveReference,
  handleInclude,
  handleExclude,
  handlePostpone,
  handleUnreview,
  taskCounts,
  exclusionReasons,
  domains,
  reasonCodesData,
  filtersApplied,
  searchingReferences,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  activeYearFilters,
  searchPhraseTokens,
  keywordsData,
  highlightsVisible,
  onToggleHighlights,
  decisionFilter,
  onUpdateComment,
  onDeleteComment,
  onUpdateSelectedTags,
  formId,
  activeReferenceResult,
  onLoadMoreReferences,
  newRankingAvailable,
  onApplyNewRanking,
  orderedBy,
  loadingMoreReferences,
  documentTypes,
  yearsFilterData,
  screeningTags,
  activeScreeningTagFilters,
  screeningListMode,
  onSendDecisions,
  unsentTasksCount,
  loadingCounts,
  totalReferencesCount,
}) => {
  const { clearFilters, resetYearsFilter } = useScreeningFilters();
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const [yearsRangeFilterState, setYearsRangeFilterState] =
    useState<IYearsRangeFilterState>(yearsFilterData);
  const [activePanel, setActivePanel] = useState<TScreeningPanel | null>('preview');
  const [instructionsOpen, setInstructionsOpen] = useState<boolean>(false);
  const [commentDialogVisible, setCommentDialogVisible] = useState(false);
  const { user } = useKeycloak();
  const isCompletedListMode = screeningListMode === ScreeningListMode.Completed;

  const noTasks = isEmpty(references) && !filtersApplied && decisionFilter === 'all';
  const togglePanel = useCallback(
    (panel) => setActivePanel((current) => (current === panel ? null : panel)),
    [setActivePanel]
  );

  const activeReferenceData = activeReference == null ? undefined : references[activeReference];
  const activeReferenceResultControlId: string | null = useMemo(() => {
    return activeReferenceResult == null
      ? null
      : getTaskResultControlId(activeReferenceResult.result);
  }, [activeReferenceResult]);

  const activeReferenceExclusionReason: string | undefined = useMemo(() => {
    return compose(
      get('label'),
      find(propEq('id', activeReferenceResultControlId))
    )(exclusionReasons);
  }, [activeReferenceResultControlId, exclusionReasons]);

  const handleResetYearsFilter = useCallback(() => {
    resetYearsFilter();
    setYearsRangeFilterState(yearsFilterData);
  }, [resetYearsFilter, setYearsRangeFilterState, yearsFilterData]);

  const handleResetReferencesSearch = useCallback(() => {
    clearFilters();
    setYearsRangeFilterState(yearsFilterData);
  }, [clearFilters, setYearsRangeFilterState, yearsFilterData]);

  const setActiveReference = useCallback(
    (activeReference) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: references[activeReference].id },
      });
    },
    [setActiveAndSelectedReferences, references]
  );

  const { sortedBy } = useReferencesListSort(stageType);
  const selectedCount = selectedReferences.length;
  const controlsDisabled =
    (activeReferenceData == null && isEmpty(selectedReferences)) || isCompletedListMode;

  const filtersToggle = useMemo(
    () =>
      renderFiltersToggle({
        filtersApplied,
        activePanel,
        togglePanel,
      }),
    [togglePanel, activePanel, filtersApplied]
  );

  const previewToggle = useMemo(
    () =>
      renderPreviewToggle({
        activeReference,
        activePanel,
        togglePanel,
      }),
    [togglePanel, activePanel, activeReference]
  );

  const previousComments = useMemo(() => {
    return reject({ stage_id: stageId }, activeReferenceData?.reference_comments);
  }, [activeReferenceData?.reference_comments, stageId]);

  const myCurrentComment = useMemo(() => {
    if (activeReferenceData == null) return;

    return getReferenceComment(activeReferenceData, activeReferenceResult);
  }, [activeReferenceData, user, stageId, activeReferenceResult]);

  const handleEditedCommentsAndTagsSave = async ({
    comment: editedComment,
    tags,
  }: {
    comment: string;
    tags: string[];
  }) => {
    if (editedComment != (myCurrentComment?.comment ?? '')) {
      if (isEmpty(editedComment)) {
        await onDeleteComment(myCurrentComment!.id);
      } else {
        await onUpdateComment({
          comment: editedComment,
          id: myCurrentComment?.id,
        });
      }
    }

    if (!isEqual(activeReferenceResult?.result.tags, tags)) {
      onUpdateSelectedTags(tags);
    }
  };

  useEffect(() => {
    setActivePanel('preview');
  }, [decisionFilter, setActivePanel]);

  return (
    <div className="h-full w-full flex flex-col overflow-auto bg-white">
      {noTasks && !searchingReferences ? (
        <NonIdealState
          icon={IconNames.HEART_BROKEN}
          title={<Trans>No references to extract</Trans>}
        />
      ) : (
        <div className="h-full flex flex-col flex-no-wrap overflow-auto">
          <ScreeningHeader
            stageId={stageId}
            stageType={stageType}
            showReRank={decisionFilter === 'to_review'}
            reRankEnabled={newRankingAvailable || sortedBy != null}
            onApplyNewRanking={onApplyNewRanking}
            leftElement={filtersToggle}
            rightElement={previewToggle}
            decisionFilter={decisionFilter}
            totalCount={taskCounts.total}
            excludedCount={taskCounts.excluded}
            filteredCount={filtersApplied ? taskCounts.total : undefined}
            includedCount={taskCounts.included}
            loadingReferences={loadingMoreReferences}
            toReviewCount={taskCounts.toReview}
            postponedCount={taskCounts.postponed}
            loadingCounts={loadingCounts}
            openFocusMode={openFocusMode}
            highlightsVisible={highlightsVisible}
            onHighlightsToggle={onToggleHighlights}
            screeningListMode={screeningListMode}
            onSendDecisions={onSendDecisions}
            openInstructions={() => setInstructionsOpen(true)}
          />
          <div className="flex flex-row w-full flex-grow overflow-auto">
            <div
              className={Classes.ELEVATION_0}
              css={[activePanel === 'filters' ? panelExpandedColCss : panelCollapsedColCss]}
            >
              <FiltersColumn
                stageType={stageType}
                domains={domains}
                reasonCodesData={reasonCodesData}
                documentTypes={documentTypes}
                yearsFilterData={yearsFilterData}
                yearsRangeFilterState={yearsRangeFilterState}
                setYearsRangeFilterState={setYearsRangeFilterState}
                activeKeywordFilters={activeKeywordFilters}
                activeDecisionCodeFilters={activeDecisionCodeFilters}
                activeDocumentTypeFilters={activeDocumentTypeFilters}
                onToggle={() => togglePanel('filters')}
                onResetReferencesSearch={filtersApplied ? handleResetReferencesSearch : undefined}
                screeningTags={screeningTags}
                activeScreeningTagFilters={activeScreeningTagFilters}
              />
            </div>
            <div css={{ width: activePanel ? '67%' : '100%' }}>
              <ReferencesListColumn
                stageId={stageId}
                showProgress
                screeningTags={screeningTags}
                stageType={stageType}
                references={references}
                filtersApplied={filtersApplied}
                activeKeywordFilters={activeKeywordFilters}
                activeDecisionCodeFilters={activeDecisionCodeFilters}
                activeDocumentTypeFilters={activeDocumentTypeFilters}
                activeScreeningTagFilters={activeScreeningTagFilters}
                activeYearFilters={activeYearFilters}
                searchPhraseTokens={searchPhraseTokens}
                onYearsFilterReset={handleResetYearsFilter}
                activeReference={activeReference}
                selectedReferences={selectedReferences}
                setSelectedReferences={setSelectedReferences}
                setActiveReference={setActiveReference}
                taskCounts={taskCounts}
                searchingReferences={searchingReferences}
                decisionFilter={decisionFilter}
                formId={formId}
                onLoadMoreReferences={onLoadMoreReferences}
                orderedBy={orderedBy}
                openFocusMode={openFocusMode}
                keywordsData={keywordsData}
                highlightsVisible={highlightsVisible}
                loadingMoreReferences={loadingMoreReferences}
                exclusionReasons={exclusionReasons}
                onSendDecisions={onSendDecisions}
                unsentTasksCount={unsentTasksCount}
                totalReferencesCount={totalReferencesCount}
              />
            </div>
            <div
              className={Classes.ELEVATION_0}
              css={[activePanel === 'preview' ? panelExpandedColCss : panelCollapsedColCss]}
            >
              <PreviewColumn
                onToggle={() => togglePanel('preview')}
                reference={activeReferenceData}
                highlightsVisible={highlightsVisible}
                exclusionReason={activeReferenceExclusionReason}
                onSaveEditedComments={
                  isCompletedListMode ? undefined : handleEditedCommentsAndTagsSave
                }
                keywordsData={keywordsData}
                comment={myCurrentComment?.comment}
                inclusionStatus={get('result.inclusionStatus', activeReferenceResult)}
                loading={searchingReferences}
                onUnreview={handleUnreview}
                selectedTags={activeReferenceResult?.result.tags}
                screeningTags={screeningTags}
                previousComments={previousComments}
                withDecisionIndicator
              />
            </div>
          </div>
          <footer className="flex-none">
            {isEmpty(references) || screeningListMode === ScreeningListMode.Completed ? (
              <NavAndDecisionControls
                onlyHotkeys
                activeControl={activeReferenceResultControlId ?? undefined}
                onToggleActiveReferenceSelect={noop}
                onShiftActiveReference={shiftActiveReference}
                onEnter={openFocusMode}
                onInclude={noop}
                onExclude={noop}
                onPostpone={noop}
                exclusionReasons={[]}
              />
            ) : stageType === StageType.FullTextScreening ? (
              <NavAndDecisionControls
                onlyHotkeys
                disabled={controlsDisabled}
                activeControl={activeReferenceResultControlId ?? undefined}
                onToggleActiveReferenceSelect={handleActiveReferenceSelect}
                onShiftActiveReference={shiftActiveReference}
                onEnter={openFocusMode}
                onInclude={noop}
                onExclude={noop}
                onPostpone={noop}
                exclusionReasons={[]}
              />
            ) : (
              <div
                className="flex flex-row flex-no-wrap w-full bg-gray-600 items-center"
                css={{ height: '60px' }}
              >
                {selectedCount > 0 && (
                  <div className="flex-none flex flex-row items-center text-white px-3">
                    <Trans>Selected references</Trans>:
                    <span className="text-base font-bold ml-2">{selectedCount}</span>
                  </div>
                )}
                <NavAndDecisionControls
                  disabled={controlsDisabled}
                  activeControl={activeReferenceResultControlId ?? undefined}
                  onToggleActiveReferenceSelect={handleActiveReferenceSelect}
                  onShiftActiveReference={shiftActiveReference}
                  onEnter={openFocusMode}
                  onInclude={handleInclude}
                  onExclude={handleExclude}
                  onPostpone={handlePostpone}
                  exclusionReasons={exclusionReasons}
                  vertical={false}
                  visibleExclusionReasonsLimit={4}
                >
                  <div className="flex flex-row flex-no-wrap">
                    {!selectedCount && (
                      <div className="py-2 mr-2">
                        <Button
                          className="h-full"
                          icon={IconNames.COMMENT}
                          disabled={controlsDisabled}
                          onClick={() => setCommentDialogVisible(true)}
                          large
                        />
                        <CommentEditDialog
                          isOpen={commentDialogVisible}
                          onClose={() => setCommentDialogVisible(false)}
                          screeningTags={screeningTags}
                          selectedTags={activeReferenceResult?.result.tags}
                          onSave={handleEditedCommentsAndTagsSave}
                          previousComments={previousComments}
                          comment={myCurrentComment?.comment}
                        />
                      </div>
                    )}
                  </div>
                </NavAndDecisionControls>
              </div>
            )}
          </footer>
          <Drawer
            onClose={() => setInstructionsOpen(false)}
            isOpen={instructionsOpen}
            title={<Trans>Inclusion and Exclusion Instructions</Trans>}
            size="50%"
          >
            <div className={Classes.DRAWER_BODY}>
              <CriteriaScreener projectId={projectId} stageId={stageId} />
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default List;
