/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Classes, Colors, Intent, OverflowList, Popover, Position, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { sortBy, get, keyBy, propEq } from 'lodash/fp';
import { memo, useMemo } from 'react';
import { InclusionStatus, ScreeningResult, StageType } from '../../common/types';
import { TExclusionReason } from '../screening';
import DecisionTag from '../screening/decision_tag';
import i18n from '../../i18n';
import { t } from '@lingui/macro';

const REMOVED_USER_NAME = i18n._(t`User removed`);

const screenerLabelCss = css`
  border: 1px solid ${Colors.DARK_GRAY5};

  &:not(:last-child),
  .${Classes.TAG}:not(:last-child) {
    margin-right: 5px;
  }
`;
interface IScreenerLabelProps {
  stageType: StageType;
  screenerName: string;
  inclusionStatus?: InclusionStatus;
  reasonCodes?: string[];
}

const ScreenerLabel: React.FC<IScreenerLabelProps> = ({
  stageType,
  inclusionStatus,
  screenerName,
  reasonCodes,
}) => {
  return (
    <div className="rounded p-1 flex flex-row justify-between items-center" css={screenerLabelCss}>
      <span className="mr-2 flex-none">{screenerName}</span>
      <span className="truncate flex-1" title={reasonCodes?.join(', ')}>
        {[StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(stageType) &&
        inclusionStatus === InclusionStatus.Included ? (
          <DecisionTag decision={InclusionStatus.Included} minimal />
        ) : (
          reasonCodes?.map((reason) => (
            <DecisionTag key={reason} decision={inclusionStatus} decisionReason={reason} minimal />
          ))
        )}
      </span>
    </div>
  );
};

type TaskData = {
  id: string;
  teamMember: { user: { name: string } };
  taskResult?: ScreeningResult;
};

const taskToScreenerLabel = (stageType: StageType, decisionReasons: TExclusionReason[]) => {
  const decisionReasonsMapped = keyBy('id', decisionReasons);

  return (task: TaskData) => {
    const { teamMember, taskResult, id } = task;
    const reasonCodes = taskResult?.criteria
      ?.filter(
        propEq('answer', taskResult.inclusionStatus === InclusionStatus.Included ? 'yes' : 'no')
      )
      .map(({ id }) => decisionReasonsMapped[id].code);

    return (
      <ScreenerLabel
        key={id}
        stageType={stageType}
        inclusionStatus={taskResult?.inclusionStatus}
        screenerName={teamMember.user?.name ?? REMOVED_USER_NAME}
        reasonCodes={reasonCodes}
      />
    );
  };
};

const overflowRenderer =
  (stageType: StageType, decisionReasons: TExclusionReason[]) => (tasks: TaskData[]) =>
    (
      <Popover
        interactionKind="hover"
        position={Position.BOTTOM}
        content={
          <div className="flex p-2">
            {tasks.map(taskToScreenerLabel(stageType, decisionReasons))}
          </div>
        }
      >
        <Tag className="ml-2" minimal intent={Intent.PRIMARY} icon={IconNames.PLUS}>
          {tasks.length}
        </Tag>
      </Popover>
    );

interface IReferenceScreenersProps {
  stageType: StageType;
  tasks: TaskData[];
  decisionReasons: TExclusionReason[];
}

const ReferenceScreeners: React.FC<IReferenceScreenersProps> = memo(
  ({ stageType, tasks, decisionReasons }) => {
    const sortedTasks = useMemo(
      () => sortBy((task: TaskData) => get('teamMember.user.name', task), tasks),
      [tasks]
    );
    return (
      <div className="inline-flex flex-row flex-no-wrap whitespace-normal">
        <OverflowList
          className="items-center"
          collapseFrom="end"
          items={sortedTasks}
          overflowRenderer={overflowRenderer(stageType, decisionReasons)}
          visibleItemRenderer={taskToScreenerLabel(stageType, decisionReasons)}
        />
      </div>
    );
  }
);

export default ReferenceScreeners;
