/** @jsx jsx */

import { TABLE_TRUNCATED_TEXT } from '@blueprintjs/table/lib/esm/common/classes';
import { createTable } from '../../common/gba_table';
import { IReferenceListLayout, TReferenceSearchData } from './references_list_layouts';
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import React, { useCallback, useMemo, useState } from 'react';
import LoadMoreReferencesButton from './load_more_references_button';

const ReferencesTable = createTable<TReferenceSearchData>();

const containerCss = css`
  margin-left: 1px;
  padding: 10px 20px 5px;
  background-color: ${Colors.LIGHT_GRAY5};
  .reference-title {
    .${TABLE_TRUNCATED_TEXT} {
      width: 100%;
    }
  }
`;

interface IReferencesListProps {
  references: TReferenceSearchData[];
  activeReference: number;
  selectedReferences: number[];
  listLayout: IReferenceListLayout;
  loadingMore: boolean;
  totalReferencesCount: number;
  onChangeActiveReference: (activeReference: number) => void;
  onChangeSelectedReferences: (updateFn: (selected: number[]) => number[]) => void;
  onSortBy: (colId: string, ascending: boolean) => void;
  onClearSort: () => void;
  onLoadMore?: () => void;
  openFocusMode: () => void;
  sortedBy?: {
    columnId: string;
    asc: boolean;
  };
}

const ReferencesList: React.FC<IReferencesListProps> = ({
  references,
  listLayout,
  loadingMore,
  activeReference,
  onChangeActiveReference,
  onLoadMore,
  onSortBy,
  sortedBy,
  onClearSort,
  openFocusMode,
  selectedReferences,
  onChangeSelectedReferences,
  totalReferencesCount,
}) => {
  const { columns, cellContentRenderer, numFrozenColumns } = listLayout;
  // TODO: uncomment when T4824 is implemented
  // const activeReferenceId = references[activeReference].reference_id;

  const handleClick = useCallback(
    (rowIdx) => {
      onChangeActiveReference(rowIdx);
    },
    [onChangeActiveReference]
  );

  const selection = useMemo(() => {
    return {
      rows: selectedReferences,
      onChange: onChangeSelectedReferences,
    };
  }, [selectedReferences, onChangeSelectedReferences]);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);

  const handleScrollBottomChange = useCallback((isScrollBottomReached) => {
    setShowLoadMoreButton(isScrollBottomReached);
  }, []);

  return (
    <div className="h-full flex relative flex-col flex-no-wrap overflow-hidden" css={containerCss}>
      <ReferencesTable
        className="h-full references-list"
        numRows={references.length}
        rows={references}
        cols={columns}
        cellContentRenderer={cellContentRenderer}
        activeRow={activeReference}
        onClick={handleClick}
        onLoadMore={onLoadMore}
        onSortBy={onSortBy}
        sortedBy={sortedBy}
        onClearSort={onClearSort}
        numFrozenColumns={numFrozenColumns}
        onDoubleClick={openFocusMode}
        selection={selection}
        onScrollBottomChange={handleScrollBottomChange}
      />
      {onLoadMore && (
        <LoadMoreReferencesButton
          showLoadMoreButton={showLoadMoreButton}
          referenceCount={references.length}
          totalReferencesCount={totalReferencesCount}
          onLoadMore={onLoadMore}
          loadingMore={loadingMore}
        />
      )}
    </div>
  );
};

export default ReferencesList;
