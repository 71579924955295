/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FC, Fragment } from 'react';
import { TReferenceData, TExclusionReason } from '..';
import { useTransition, animated } from 'react-spring';
import { Button, Classes, Divider } from '@blueprintjs/core';
import TextWithHighlights from '../../common/text_with_highlights';
import { get } from 'lodash/fp';
import { SearchAndDesiredUndesiredKeywords } from '../../../lib/criteria_utils';
import { Trans } from '@lingui/macro';
import { getTaskInclusionStatus } from '../../../lib/task_helpers';
import { useKeycloak } from '../../../keycloak';
import { useStatusColor } from '../../../lib/utils';
import { IconNames } from '@blueprintjs/icons';
import { getShortCitation } from '../../references/helpers';

const inclusionStatusContainerCss = (color: string) => css`
  border: 1px solid ${color};
  height: 50px;
  color: ${color};

  .${Classes.DIVIDER} {
    border-left: 1px solid ${color};
  }
`;

interface IReferenceCardProps {
  reference: TReferenceData;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  exclusionReasons: TExclusionReason[];
  onEraseDecision?: () => void;
  formId: string;
}

const ReferenceCard: FC<IReferenceCardProps> = ({
  reference,
  keywordsData,
  exclusionReasons,
  onEraseDecision,
  formId,
}) => {
  const {
    user: { id: userId },
  } = useKeycloak();
  const getStatusColor = useStatusColor();
  const transitions = useTransition(reference, (item) => item.id, {
    from: { opacity: 0 },
    leave: { opacity: 0 },
    enter: { opacity: 1 },
    delay: 200,
  });
  const { inclusionStatus, exclusionReason } = getTaskInclusionStatus(
    reference,
    formId,
    userId,
    exclusionReasons
  );
  const referenceScreened = inclusionStatus === 'included' || inclusionStatus === 'excluded';
  const included = inclusionStatus === 'included';
  const inclusionColor = getStatusColor(inclusionStatus);

  return (
    <div className="flex-1 flex flex-col overflow-auto relative">
      {transitions.map(({ props, key }) => {
        return (
          <animated.div
            key={key}
            className="card flex-1 flex flex-col overflow-auto bg-white absolute h-full w-full"
            style={{ ...props }}
          >
            {referenceScreened && (
              <div
                className={`${Classes.HEADING} w-full flex flex-row items-center flex-none`}
                css={inclusionStatusContainerCss(inclusionColor)}
              >
                <span className="px-4 flex justify-center uppercase">
                  {included ? <Trans>in</Trans> : <Trans>out</Trans>}
                </span>
                <Divider className="h-full m-0" />
                <div className="flex-1">
                  {exclusionReason && <span className="px-4">{exclusionReason}</span>}
                </div>
                {onEraseDecision && (
                  <Fragment>
                    <Divider className="h-full m-0" />
                    <Button
                      className="h-full"
                      icon={IconNames.ERASER}
                      minimal
                      large
                      onClick={() => onEraseDecision()}
                    />
                  </Fragment>
                )}
              </div>
            )}
            <div className="px-8 py-5">{getShortCitation(reference)}</div>
            <h5 className={`${Classes.HEADING} px-8 w-full flex-none`}>
              <TextWithHighlights keywordsData={keywordsData} text={reference.title} />
            </h5>
            <div className="px-8 py-4 w-full flex flex-col overflow-auto flex-1">
              <p>
                <TextWithHighlights
                  className="whitespace-pre-wrap"
                  keywordsData={keywordsData}
                  text={get('attrs.abstract', reference) ?? ''}
                />
              </p>
            </div>
          </animated.div>
        );
      })}
    </div>
  );
};

export default ReferenceCard;
