/** @jsx jsx */
import { get, some } from 'lodash/fp';
import { css, jsx } from '@emotion/core';
import TextHighlight from './text_highlight';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql.macro';
import { StringFragmentDecorator } from '../../lib/decorated_string';
import { useMemo, useEffect, useRef } from 'react';

const HIGHLIGHT_COLOR = 'rgba(175, 255, 255, 0.63)';
const HIGHLIGHT_COLOR_ACTIVE = 'rgba(175, 192, 255, 0.63)';

const dataQuery = gql`
  query {
    PDFViewer @client {
      search {
        activeMatchRanges
      }
    }
  }
`;

const attrValPDFInstanceCss = css`
  position: relative;
  height: inherit;
  display: inline-flex;
  align-items: center;
`;

const SearchTextHighlight: StringFragmentDecorator = ({ text, fragmentSpec, decoratorProps }) => {
  const ref = useRef<HTMLElement | null>(null);
  const { pageNum, itemIndex } = decoratorProps;
  const fragmentId = `${fragmentSpec.offset}_${fragmentSpec.length}`;
  const { data } = useQuery(dataQuery);
  const activeMatchRanges = get('PDFViewer.search.activeMatchRanges', data);
  const { offset, length } = fragmentSpec;

  const isActive = useMemo(() => {
    return (
      pageNum in activeMatchRanges &&
      itemIndex in activeMatchRanges[pageNum] &&
      some({ offset, length }, activeMatchRanges[pageNum][itemIndex])
    );
  }, [activeMatchRanges, pageNum, offset, length, itemIndex]);

  useEffect(() => {
    if (isActive) {
      ref.current && ref.current.scrollIntoView(true);
    }
  }, [isActive]);

  return (
    <span key={fragmentId} ref={ref} css={attrValPDFInstanceCss}>
      <TextHighlight
        text={text}
        highlightColor={isActive ? HIGHLIGHT_COLOR_ACTIVE : HIGHLIGHT_COLOR}
      />
    </span>
  );
};

export default SearchTextHighlight;
