/** @jsx jsx */
import { Colors } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { t } from '@lingui/macro';
import { groupBy, isEmpty, orderBy } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { gray1ColorCss } from '../../common/styles';
import { Flatten, Stage } from '../../common/types';
import { ReferenceCommentFragmentType } from '../../graphql/reference_comment_fragment';
import { mapRight, useI18n } from '../../lib/utils';
import CommentBox from './comment_box';

const cardCss = css`
  border-radius: 3px;
  overflow: hidden;
  color: ${Colors.DARK_GRAY1};
`;
interface IReferenceCommentsByStageProps {
  stages: Pick<Stage, 'id' | 'name' | 'order_number'>[];
  comments: ReferenceCommentFragmentType[];
  className?: string;
}

const ReferenceCommentsByStage: React.FC<IReferenceCommentsByStageProps> = ({
  stages,
  comments,
  className,
}) => {
  const i18n = useI18n();

  const projectStagesOrdered = useMemo(() => {
    return orderBy('order_number', 'asc', stages);
  }, [stages]);

  const commentsByStage = useMemo(() => {
    return groupBy('stage_id', comments);
  }, [comments]);

  const commentMapper = useCallback(
    (commentData: Flatten<typeof comments>, _idx: number, _comments: typeof comments) => {
      const { id, comment, team_member } = commentData;
      return (
        <CommentBox
          key={id}
          css={cardCss}
          comment={comment}
          authorName={team_member.user?.name ?? i18n._(t`Removed user`)}
          className="mb-4"
        />
      );
    },
    []
  );

  return (
    <div className={className}>
      {commentsByStage['null']?.map(commentMapper)}
      {mapRight((stage) => {
        const stageComments = commentsByStage[stage.id];

        return isEmpty(stageComments) ? null : (
          <div className="mt-5" key={stage.id} data-testid="stage-comments-container">
            <div className="text-sm text-right mb-4">{stage.name}</div>
            {stageComments.map(commentMapper)}
          </div>
        );
      }, projectStagesOrdered)}
    </div>
  );
};

export default ReferenceCommentsByStage;
