/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Collapse, RadioGroup, Radio } from '@blueprintjs/core';
import { useState, useCallback, FormEvent } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { ProjectsLibraryFilter, useCurrCallback } from '../../lib/utils';
import { Trans } from '@lingui/macro';
import { fancyScrollCss } from '../../common/styles';
import { projectsLibraryFilterOptions } from './helpers';

interface ProjectsFiltersState {
  showProjectAttributes: boolean;
}

interface IProjectsFiltersProps {
  projectsLibraryFilter: ProjectsLibraryFilter;
  onChangeProjectsLibraryFilter: (libraryFilter: ProjectsLibraryFilter) => void;
}

const ProjectsFilters: React.FC<IProjectsFiltersProps> = ({
  projectsLibraryFilter,
  onChangeProjectsLibraryFilter,
}) => {
  const [state, setState] = useState<ProjectsFiltersState>({
    showProjectAttributes: true,
  });
  const { showProjectAttributes } = state;

  const toggleFlag = useCurrCallback(
    (flagName, _evt) => {
      setState((state) => ({
        ...state,
        [flagName]: !state[flagName],
      }));
    },
    [setState]
  );

  const handleChangeProjectsLibraryFilter = useCallback(
    (evt: FormEvent<HTMLInputElement>) => {
      onChangeProjectsLibraryFilter(evt.currentTarget.value as ProjectsLibraryFilter);
    },
    [onChangeProjectsLibraryFilter]
  );

  return (
    <div className="flex flex-col overflow-auto h-full bg-gray-500" css={fancyScrollCss}>
      <div>
        <div className="border flex flex-no-wrap justify-between items-center pl-4 bg-white">
          <span className="text-xl">
            <Trans>Project</Trans>
          </span>
          <Button
            minimal
            large
            icon={showProjectAttributes ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
            onClick={toggleFlag('showProjectAttributes')}
          />
        </div>
        <Collapse isOpen={showProjectAttributes}>
          <div className="px-4 py-2">
            <div className="rounded border bg-white">
              <div className="border-b p-3">
                <span className="font-bold">
                  <Trans>Library filters</Trans>
                </span>
              </div>
              <div className="flex p-3">
                <RadioGroup
                  selectedValue={projectsLibraryFilter}
                  onChange={handleChangeProjectsLibraryFilter}
                >
                  {projectsLibraryFilterOptions.map(({ value, labelElement }) => (
                    <Radio
                      className="flex flex-row items-center"
                      key={value}
                      {...{ value, labelElement }}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ProjectsFilters;
