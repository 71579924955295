/** @jsx jsx */
import { useMutation } from '@apollo/react-hooks';
import { Button, Checkbox, Classes, Dialog } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { plural, t, Trans } from '@lingui/macro';
import { gql } from 'graphql.macro';
import React from 'react';
import { useI18n } from '../../lib/utils';

const ToggleShowSendDecisionsConfirmDialogMutation = gql`
  mutation ToggleShowSendDecisionsConfirmDialog {
    toggleShowSendDecisionsConfirmDialog @client
  }
`;

interface SendDecisionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  decisionsToSendCount: number;
  showSendDecisionsConfirmDialog: boolean;
}

const SendDecisionsDialog: React.FC<SendDecisionsDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  decisionsToSendCount,
  showSendDecisionsConfirmDialog,
}: SendDecisionsDialogProps) => {
  const i18n = useI18n();
  const title = i18n._(
    plural({
      value: decisionsToSendCount,
      one: 'Send decision',
      other: 'Send # decisions',
    })
  );

  const [toggleShowSendDecisionsConfirmDialog] = useMutation(
    ToggleShowSendDecisionsConfirmDialogMutation
  );

  return (
    <Dialog
      isOpen={isOpen}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      onClose={onClose}
      title={title}
      icon={IconNames.WARNING_SIGN}
      className="send-decisions-confirmation-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        <p className="mb-3">
          <Trans>Please note that sent decisions cannot be changed or reverted to review</Trans>.
        </p>
        <Checkbox
          className="text-xs"
          checked={!showSendDecisionsConfirmDialog}
          label={i18n._(t`Don't show the message again`)}
          onChange={() => toggleShowSendDecisionsConfirmDialog()}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button className="px-4" text={<Trans>Cancel</Trans>} onClick={onClose} />
          <Button className="px-4" text={title} onClick={onConfirm} />
        </div>
      </div>
    </Dialog>
  );
};

export default SendDecisionsDialog;
