/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction } from 'react';
import {
  TActiveKeywordFilters,
  TActiveAttributeFilters,
  TActiveScreeningTagFilters,
} from '../../apollo/screening_state';
import { screeningListColHeaderHeight } from '../../common/styles';
import { DomainData, StageType } from '../../common/types';
import { TDomainReasonsData, TScreeningTag } from '../references/admin';
import { IYearsRangeFilterState } from '../references/admin/list';
import FiltersHeader from './filters_header';
import ScreeningFilters from './screening_filters';

interface IFiltersColumnProps {
  stageType: StageType;
  onToggle: () => void;
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  onResetReferencesSearch?: () => void;
  domains: DomainData[];
  reasonCodesData: TDomainReasonsData;
  documentTypes: string[];
  yearsFilterData: { min: number; max: number };
  yearsRangeFilterState: IYearsRangeFilterState;
  setYearsRangeFilterState: Dispatch<SetStateAction<IYearsRangeFilterState>>;
  screeningTags: TScreeningTag[];
  activeScreeningTagFilters: TActiveScreeningTagFilters;
}

const FiltersColumn: React.FC<IFiltersColumnProps> = ({
  stageType,
  onResetReferencesSearch,
  domains,
  reasonCodesData,
  documentTypes,
  yearsFilterData,
  yearsRangeFilterState,
  setYearsRangeFilterState,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  onToggle,
  screeningTags,
  activeScreeningTagFilters,
}) => {
  return (
    <div className="h-full flex flex-col overflow-auto">
      <div className="flex-none" css={screeningListColHeaderHeight}>
        <FiltersHeader onClearFilters={onResetReferencesSearch} onToggle={onToggle} />
      </div>
      <ScreeningFilters
        stageType={stageType}
        domains={domains}
        reasonCodesData={reasonCodesData}
        documentTypes={documentTypes}
        yearsFilterData={yearsFilterData}
        yearsRangeFilterState={yearsRangeFilterState}
        setYearsRangeFilterState={setYearsRangeFilterState}
        activeKeywordFilters={activeKeywordFilters}
        activeDecisionCodeFilters={activeDecisionCodeFilters}
        activeDocumentTypeFilters={activeDocumentTypeFilters}
        screeningTags={screeningTags}
        activeScreeningTagFilters={activeScreeningTagFilters}
      />
    </div>
  );
};

export default FiltersColumn;
