/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useCallback, useState } from 'react';
import { Button, ButtonProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t } from '@lingui/macro';
import AppToaster from '../../lib/toaster';
import { download } from '../../lib/storageEndpoint';
import saveFileBlob from '../../lib/save_file_blob';
import { useKeycloak } from '../../keycloak';
import { useI18n } from '../../lib/utils';

export const EXPORTS_ATTACHMENT_BUCKET = process.env.EXPORTS_ATTACHMENT_BUCKET || 'exports';

interface DownloadButtonProps extends ButtonProps {
  bucketName: string;
  downloadKey: string;
  downloadName: string;
}
const DownloadButton: React.FC<DownloadButtonProps> = ({
  bucketName,
  downloadKey,
  downloadName,
  className,
  large,
  minimal,
  text,
}) => {
  const { getToken } = useKeycloak();
  const [isDownloading, setIsDownloading] = useState(false);
  const i18n = useI18n();

  const doDownload = useCallback(() => {
    setIsDownloading(true);
    getToken()
      .then((token) => download(bucketName, downloadKey, token))
      .then((response) => {
        setIsDownloading(false);
        saveFileBlob(response.data, downloadName);
      })
      .catch((err) => {
        setIsDownloading(false);
        const errorMessagePart = err.message ? `: ${err.message}` : '';
        AppToaster.show({
          intent: Intent.DANGER,
          message: i18n._(t`Error downloading file${errorMessagePart}`),
          timeout: 3000,
        });
      });
  }, [downloadKey, downloadName, getToken, i18n]);

  return (
    <Button
      minimal={minimal}
      large={large}
      className={className}
      icon={IconNames.DOWNLOAD}
      loading={isDownloading}
      onClick={doDownload}
    >
      {text}
    </Button>
  );
};

export default DownloadButton;
