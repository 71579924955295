import { useQuery, useSubscription } from '@apollo/react-hooks';
import { AnchorButton, IAnchorButtonProps, Intent, Tooltip } from '@blueprintjs/core';
import { plural, Trans } from '@lingui/macro';
import { gql, loader } from 'graphql.macro';
import { get, isEmpty, map } from 'lodash/fp';
import React, { Fragment, useCallback } from 'react';
import { IAppState } from '../../apollo/app_state';
import { ScreeningTaskType } from '../../common/types';
import { useKeycloak } from '../../keycloak';
import AppToaster from '../../lib/toaster';
import { useI18n, useSetState } from '../../lib/utils';
import SendDecisionsDialog from './send_decisions_dialog';

const AppStateQuery = loader('../../graphql/local/get_app_state.gql');

const UnsentTasksSubscription = gql`
  subscription getUnsentScreeningTasks(
    $stageId: uuid!
    $taskType: task_types_enum!
    $userId: String!
  ) {
    unsent: task(
      where: {
        stage_id: { _eq: $stageId }
        completed: { _eq: false }
        _or: [
          { team_member: { user_id: { _eq: $userId } } }
          { task_type: { _eq: conflict_resolution } }
        ]
        task_results: {
          _or: [
            { result: { _contains: { inclusionStatus: "included" } } }
            { result: { _contains: { inclusionStatus: "excluded" } } }
          ]
        }
        task_type: { _eq: $taskType }
      }
    ) {
      id
    }
  }
`;

interface ISendDecisionsButtonProps extends IAnchorButtonProps {
  stageId: string;
  onSend: (taskIds: string[]) => Promise<any>;
  taskType?: ScreeningTaskType;
}

interface ISendDecisionsButtonState {
  confirmationDialogOpen: boolean;
  sending: boolean;
}

const initialState: ISendDecisionsButtonState = {
  confirmationDialogOpen: false,
  sending: false,
};

const SendDecisionsButton: React.FC<ISendDecisionsButtonProps> = ({
  stageId,
  onSend,
  taskType,
  ...rest
}) => {
  const {
    user: { id: userId },
  } = useKeycloak();
  const [state, setState] = useSetState<ISendDecisionsButtonState>(initialState);
  const { confirmationDialogOpen, sending } = state;
  const i18n = useI18n();

  const { data: appStateData } = useQuery<IAppState>(AppStateQuery);
  const showSendDecisionsConfirmDialog =
    get('AppState.showSendDecisionsConfirmDialog', appStateData) ?? true;

  const { data, loading } = useSubscription(UnsentTasksSubscription, {
    variables: {
      stageId,
      taskType: taskType ?? ScreeningTaskType.Screening,
      userId,
    },
  });

  const unsentIds = map('id', get('unsent', data) ?? []);

  const handleSend = () => {
    setState({ confirmationDialogOpen: false, sending: true });
    onSend(unsentIds)
      .then(() => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: i18n._(
            plural({
              value: unsentIds.length,
              one: 'Decision was sent successfully!',
              other: '# decisions were sent successfully!',
            })
          ),
        });
      })
      .finally(() => setState({ sending: false }));
  };

  const handleButtonClick = useCallback(() => {
    if (showSendDecisionsConfirmDialog) {
      setState({ confirmationDialogOpen: true });
    } else {
      handleSend();
    }
  }, [handleSend, setState, showSendDecisionsConfirmDialog]);

  return (
    <Fragment>
      <Tooltip content={<Trans>No decisions yet</Trans>} disabled={!isEmpty(unsentIds) || loading}>
        <AnchorButton
          {...rest}
          text={
            <span>
              <Trans>Send decisions</Trans>
              {!isEmpty(unsentIds) && <span> ({unsentIds.length})</span>}
            </span>
          }
          disabled={isEmpty(unsentIds)}
          onClick={handleButtonClick}
          loading={loading || sending}
        />
      </Tooltip>

      <SendDecisionsDialog
        isOpen={confirmationDialogOpen}
        onClose={() => setState({ confirmationDialogOpen: false })}
        onConfirm={handleSend}
        decisionsToSendCount={unsentIds.length}
        showSendDecisionsConfirmDialog={showSendDecisionsConfirmDialog}
      />
    </Fragment>
  );
};

export default SendDecisionsButton;
