import React, { useMemo, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { values, every, identity, flow, fromPairs, map, isNaN, clamp, has } from 'lodash/fp';
import { Colors, Icon, Card, Text, Slider, NumericInput, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useCurrCallback } from '../../../lib/utils';
import { TScreenerData, TTeamMemberTaskLimits } from '../../../lib/distribution_helpers';

const areAllLocked = flow(values, every(identity));
const markMembersUnlocked = flow(
  map((m: TScreenerData) => [m.id, false]),
  fromPairs
);

interface IReferencesDistributionIndividualProps {
  teamMembers: TScreenerData[];
  toDistribute: number;
  maxCount: number;
  studiesPerMember: { [memberId: string]: number };
  undistributedCount: number;
  onChangeStudiesPerMember: (studiesPerMember: { [memberId: string]: number }) => void;
  memberLocks: { [memberId: string]: boolean };
  onChangeMemberLocks: (memberLocks: { [memberId: string]: boolean }) => void;
  memberLimits: TTeamMemberTaskLimits;
}

const ReferencesDistributionIndividual: React.FC<IReferencesDistributionIndividualProps> = ({
  teamMembers,
  toDistribute,
  maxCount,
  studiesPerMember,
  memberLocks,
  onChangeStudiesPerMember,
  onChangeMemberLocks,
  undistributedCount,
  memberLimits,
}) => {
  const allLocked = useMemo(() => areAllLocked(memberLocks), [memberLocks]);

  const handleMemberQuotaChange = useCurrCallback(
    (memberId, newQuota) => {
      if (isNaN(newQuota)) return;
      const clampedQuota = clamp(
        0,
        Math.min(
          memberLimits[memberId] ?? maxCount,
          studiesPerMember[memberId] + undistributedCount
        ),
        newQuota
      );

      onChangeStudiesPerMember({ ...studiesPerMember, [memberId]: clampedQuota });
    },
    [onChangeStudiesPerMember, studiesPerMember, undistributedCount, memberLimits]
  );

  const toggleMemberLock = useCurrCallback(
    (memberId, _evt) => {
      onChangeMemberLocks({
        ...memberLocks,
        [memberId]: has(memberId, memberLocks) ? !memberLocks[memberId] : false,
      });
    },
    [onChangeMemberLocks, memberLocks]
  );

  const handleToggleAll = useCallback(() => {
    onChangeMemberLocks(allLocked ? markMembersUnlocked(teamMembers) : {});
  }, [onChangeMemberLocks, allLocked, teamMembers]);

  return (
    <div>
      <div className="p-3 flex flex-row flex-no-wrap justify-between items-center">
        <div>
          <Trans>Undistributed</Trans>: <span className="font-bold">{undistributedCount}</span>
        </div>
        <div className="flex flex-row items-center">
          <Icon className="mx-3" color={Colors.GRAY4} icon={IconNames.DOCUMENT} />
          <Button
            className="p-0"
            icon={allLocked ? IconNames.LOCK : IconNames.UNLOCK}
            minimal
            onClick={handleToggleAll}
          />
        </div>
      </div>
      <div className="members-list">
        {teamMembers.map((member) => {
          const memberId = member.id;
          const isMemberLocked = memberLocks[memberId] ?? true;
          const memberStudies = studiesPerMember[memberId] ?? 0;

          return (
            <Card
              className="p-3 flex flex-row flex-no-wrap justify-between items-center mb-4 relative"
              key={memberId}
            >
              <Text className="w-1/2" ellipsize>
                {member.user?.name ?? <Trans>User removed</Trans>}
              </Text>
              <div className="flex flex-row flex-no-wrap items-center flex-auto justify-between">
                <Slider
                  min={0}
                  max={toDistribute}
                  stepSize={1}
                  value={memberStudies}
                  disabled={isMemberLocked}
                  onChange={handleMemberQuotaChange(memberId)}
                  labelRenderer={false}
                />
                <NumericInput
                  fill
                  className="w-24 mx-3"
                  buttonPosition="none"
                  min={0}
                  max={memberLimits[memberId] ?? maxCount}
                  value={memberStudies}
                  disabled={isMemberLocked}
                  clampValueOnBlur
                  onValueChange={handleMemberQuotaChange(memberId)}
                  name="studies-count"
                />
                <Button
                  minimal
                  icon={
                    <Icon icon={isMemberLocked ? IconNames.LOCK : IconNames.UNLOCK} iconSize={12} />
                  }
                  onClick={toggleMemberLock(memberId)}
                />
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default ReferencesDistributionIndividual;
