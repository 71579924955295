/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  Button,
  Card,
  Classes,
  Colors,
  Divider,
  Drawer,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
  Position,
} from '@blueprintjs/core';
import { t, Trans } from '@lingui/macro';
import React, { ChangeEvent, KeyboardEvent, useCallback, useMemo } from 'react';
import { darkGray5Color, fancyScrollCss } from '../../../common/styles';
import { ReferenceAttachment } from '../../../common/types';
import { useCurrCallback, useI18n, useSetState } from '../../../lib/utils';
import { IconNames } from '@blueprintjs/icons';
import ActionsMenu from '../../common/actions_menu';
import { debounce, findIndex, isEmpty, trim } from 'lodash/fp';

const drawerCss = css`
  ${darkGray5Color};
  .${Classes.DRAWER_HEADER} {
    background-color: ${Colors.LIGHT_GRAY5};
  }
`;

interface IAttachmentsListProps {
  isOpen: boolean;
  onClose: () => void;
  onSetActiveAttachment: (attachmentIdx: number) => void;
  onDeleteAllAttachments: () => void;
  attachments: ReferenceAttachment[];
  deleting?: boolean;
}

const AttachmentsList: React.FC<IAttachmentsListProps> = ({
  isOpen,
  onClose,
  attachments,
  onSetActiveAttachment,
  onDeleteAllAttachments,
  deleting,
}) => {
  const i18n = useI18n();
  const [state, setState] = useSetState({
    searchQuery: '',
    searchValue: '',
  });

  const { searchQuery, searchValue } = state;

  const handleClick = useCurrCallback(
    (attachmentKey: string, _evt) => {
      const attachmentIdx = findIndex({ key: attachmentKey }, attachments);
      onSetActiveAttachment(attachmentIdx);
      onClose();
    },
    [onSetActiveAttachment, onClose, attachments]
  );

  const updateSearchQuery = useCallback(
    debounce(200, (searchValue: string) => {
      const trimmed = trim(searchValue);
      setState({
        searchQuery: trimmed ?? null,
      });
    }),
    [setState]
  );

  const handleSearchValueChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      setState({ searchValue: value });
      updateSearchQuery(value);
    },
    [setState, updateSearchQuery]
  );

  const clearSearch = useCallback(() => {
    setState({ searchQuery: '', searchValue: '' });
  }, [setState]);

  const handleKey = useCallback(
    (evt: KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Escape') return clearSearch();
    },
    [setState]
  );

  const handleDeleteAll = useCallback(() => {
    onClose();
    onDeleteAllAttachments();
  }, [onClose, onDeleteAllAttachments]);

  const filteredAttachments = useMemo(() => {
    if (isEmpty(searchQuery)) return attachments;

    return attachments.filter(({ filename }) => filename.includes(searchQuery));
  }, [searchQuery, attachments]);

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      position={Position.LEFT}
      title={
        <span className="text-xl font-normal">
          <Trans>PDFs with no reference attached</Trans>
        </span>
      }
      canEscapeKeyClose={false}
      css={drawerCss}
    >
      <div className={`${Classes.DRAWER_BODY} flex flex-col`}>
        <div className="px-8 py-3 flex items-center w-full">
          <InputGroup
            leftIcon={IconNames.SEARCH}
            className="flex-1 mr-4"
            placeholder={`${i18n._(t`Search for unmatched PDF files`)}...`}
            value={searchValue}
            onChange={handleSearchValueChange}
            onKeyDown={handleKey}
            autoFocus
            rightElement={
              isEmpty(searchValue) ? undefined : (
                <Button
                  minimal
                  icon={IconNames.CROSS}
                  intent={Intent.DANGER}
                  onClick={clearSearch}
                />
              )
            }
          />
          <ActionsMenu
            menuPosition={Position.BOTTOM}
            disabled={deleting || isEmpty(filteredAttachments)}
            actions={[
              {
                text: i18n._(t`Remove all unassigned PDF files`),
                intent: Intent.DANGER as Intent,
                onClick: handleDeleteAll,
              },
            ]}
          />
        </div>
        <Divider className="m-0" />
        <div className="flex-1 overflow-auto px-8 py-3" css={fancyScrollCss}>
          {isEmpty(filteredAttachments) && !isEmpty(searchQuery) ? (
            <NonIdealState title={<Trans>Nothing found</Trans>} icon={IconNames.SEARCH} />
          ) : (
            filteredAttachments.map(({ filename, key }) => (
              <Card interactive onClick={handleClick(key)} className="py-2 px-3 mb-3" key={key}>
                <div className="flex w-full">
                  <Icon className="mr-2" icon={IconNames.DOCUMENT} color={Colors.DARK_GRAY5} />
                  <span className="truncate flex-1">{filename}</span>
                </div>
              </Card>
            ))
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AttachmentsList;
