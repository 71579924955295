import { isEmpty, isEqual, isNull, last, map, reject, sortBy, union, reduce } from 'lodash/fp';

export enum HighlightingType {
  NoHighlight = 'noHighlight',
  Desired = 'desired',
  Undesired = 'undesired',
  DesiredAndUndesired = 'desiredAndUndesired',
  Search = 'search',
}

export interface HighlightingChunk {
  start: number; // Inclusive
  end: number; // Exclusive
  type: HighlightingType;
}

export function combineHighlightingTypes(
  x: HighlightingType,
  y: HighlightingType,
): HighlightingType {
  if (x === HighlightingType.NoHighlight) return y;
  if (y === HighlightingType.NoHighlight) return x;
  if (x === HighlightingType.Search) return x;
  if (y === HighlightingType.Search) return y;
  if (x === y) return x;
  return HighlightingType.DesiredAndUndesired;
}

export function regexpFromKeyword(keyword: string): RegExp | null {
  const trimmedKeyword = keyword.trim();
  if (isEmpty(trimmedKeyword)) return null;

  const hasAsteriskAtBeginning = trimmedKeyword.startsWith('*');
  const hasAsteriskAtEnd = trimmedKeyword.endsWith('*');

  const keywordWithoutBeginningAsterisk = hasAsteriskAtBeginning ?
    trimmedKeyword.substring(1, trimmedKeyword.length) : trimmedKeyword;
  const keywordWithoutAsterisk = hasAsteriskAtEnd ?
    keywordWithoutBeginningAsterisk.substring(0, keywordWithoutBeginningAsterisk.length - 1) :
    keywordWithoutBeginningAsterisk;
  const hasSpecialCharacters = !isNull(keywordWithoutAsterisk.match(/[.+*?^${}()|[\]\\]/g));
  const keywordWithoutSpacesAndHyphens = keywordWithoutAsterisk.replace(/[\s\-]+/g, '[\\-\\s]+');
  const keywordWithEscapedSpecialCharacters = keywordWithoutSpacesAndHyphens.replace(
    /[.+*?^${}()|[\]\\]/g,
    '\\$&'
  );
  const regexPrefix = hasAsteriskAtBeginning ? '\\w*' : '';
  const regexSuffix = hasAsteriskAtEnd ? '\\w*' : '';
  return new RegExp(
    hasSpecialCharacters
      ? `${regexPrefix}${keywordWithEscapedSpecialCharacters}${regexSuffix}`
      : `\\b${regexPrefix}${keywordWithoutSpacesAndHyphens}${regexSuffix}\\b`,
    'ig'
  );
}

export function findMatchesForKeywords(
  keywords: string[],
  highlightingType: HighlightingType,
  text: string,
): HighlightingChunk[] {
  return reduce<HighlightingChunk[], HighlightingChunk[]>(
    union,
    [],
    map<string, HighlightingChunk[]>(
      (keyword) => {
        const regexp = regexpFromKeyword(keyword);
        if (regexp === null) return [];
        return map<RegExpMatchArray, HighlightingChunk>(
          (match) => ({
            start: match.index!,
            end: match.index! + match[0].length,
            type: highlightingType,
          }),
          [...text.matchAll(regexp)],
        );
      },
      keywords,
    )
  );
}

enum SweepEventType {
  HighlightStart = 'highlight_start',
  HighlightEnd = 'highlight_end',
}

interface SweepEvent {
  index: number;
  eventType: SweepEventType;
  chunk: HighlightingChunk;
}

export function highlightKeywords(
  desiredKeywords: string[],
  undesiredKeywords: string[],
  searchKeywords: string[],
  text: string,
): HighlightingChunk[] {
  const events: SweepEvent[] = [];
  let chunksOnSweep: HighlightingChunk[] = [];
  const chunksToReturn: HighlightingChunk[] = [];
  const putChunkToResults: (chunk: HighlightingChunk) => void = (chunk) => {
    if (chunk.start < chunk.end) chunksToReturn.push(chunk);
  };

  reduce<HighlightingChunk[], HighlightingChunk[]>(
    union,
    [],
    [
      findMatchesForKeywords(desiredKeywords, HighlightingType.Desired, text),
      findMatchesForKeywords(undesiredKeywords, HighlightingType.Undesired, text),
      findMatchesForKeywords(searchKeywords, HighlightingType.Search, text),
    ],
  ).forEach((chunk) => {
    events.push({
      index: chunk.start,
      eventType: SweepEventType.HighlightStart,
      chunk,
    });
    events.push({
      index: chunk.end,
      eventType: SweepEventType.HighlightEnd,
      chunk,
    });
  });

  const eventsQueue = sortBy('index', events);

  eventsQueue.forEach((event) => {
    const type = reduce<HighlightingChunk, HighlightingType>(
      (currentType, { type }) => combineHighlightingTypes(currentType, type),
      HighlightingType.NoHighlight,
      chunksOnSweep,
    );
    const lastChunkEnd = last(chunksToReturn)?.end ?? 0;
    putChunkToResults({
      start: lastChunkEnd,
      end: event.index,
      type,
    });

    switch (event.eventType) {
      case SweepEventType.HighlightStart:
        // Push new chunk onto sweep
        chunksOnSweep.push(event.chunk);
        break;
      case SweepEventType.HighlightEnd:
        // Remove current chunk from sweep
        chunksOnSweep = reject(isEqual(event.chunk), chunksOnSweep);
        break;
    }
  });
  // Push artificial chunk with no highlight if last chunk is not covering whole text
  const lastChunkEnd = last(chunksToReturn)?.end ?? 0;
  putChunkToResults({
    start: lastChunkEnd,
    end: text.length,
    type: HighlightingType.NoHighlight,
  });

  return chunksToReturn;
}
