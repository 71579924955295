import { isEmpty, keys, range, xorBy } from 'lodash/fp';
import Immutable from 'immutable';
import { SearchConfig, SearchResults } from './pdf_search_provider';
import { memoize } from 'lodash';

export interface SearchMatchResult {
  offset: number;
  length: number;
  match: string;
}

const SPECIAL_CHARS_RE = RegExp(
  '\\^|\\$|\\.|\\*|\\+|\\?|\\=|\\!|\\:|\\||\\|\\/|\\(|\\)|\\[|\\]|\\{|\\}',
  'g'
);

function sanitizeSearchString(str: string): string {
  return str.trim().replace(/\s+/g, ' ').replace(SPECIAL_CHARS_RE, '\\$&');
}

const regExpFromStr = memoize(
  (reStr: string, flagsStr = '') => RegExp(reStr, flagsStr),
  (reStr: string, flagsStr = '') => `${reStr}_${flagsStr}`
);

export function getMatchRanges(str: string, searchQuery: RegExp): SearchMatchResult[] {
  let result;
  let matchOffsets: SearchMatchResult[] = [];

  while ((result = searchQuery.exec(str)) != null) {
    matchOffsets.push({
      offset: result.index,
      length: result[0].length,
      match: result[0],
    });
  }

  return matchOffsets;
}

export function getQueryRE(query: string, config: SearchConfig): RegExp {
  const sanitizedQuery = sanitizeSearchString(query);
  const subQueries = sanitizedQuery.split(/\s/);
  const queryREString = subQueries
    .map((subQuery) => {
      return config.matchWholeWord ? `\\b${subQuery}\\b` : subQuery;
    })
    .join('\\s+');

  return regExpFromStr(queryREString, config.caseSensitive ? 'gm' : 'gmi');
}

export function withoutIgnoredResults(
  results: SearchResults,
  ignoredResults: SearchResults
): SearchResults {
  return keys(results).reduce((acc, pageNum) => {
    const pageResults = results[pageNum];
    if (pageNum in ignoredResults) {
      const ignoredPageResults = ignoredResults[pageNum];

      const remainingPageResults = keys(pageResults).reduce((pageAcc, itemIndex) => {
        const itemRanges = pageResults[itemIndex];

        if (itemIndex in ignoredPageResults) {
          const ignoredItemRanges = ignoredPageResults[itemIndex];
          const remainingRanges = xorBy(['offset', 'length'], ignoredItemRanges, itemRanges);

          return isEmpty(remainingRanges) ? pageAcc : { ...pageAcc, [itemIndex]: remainingRanges };
        } else {
          return { ...pageAcc, [itemIndex]: itemRanges };
        }
      }, {});
      return isEmpty(remainingPageResults) ? acc : { ...acc, [pageNum]: remainingPageResults };
    } else {
      return { ...acc, [pageNum]: pageResults };
    }
  }, {});
}

export function searchResultsToQueryMatches(
  results: SearchResults,
  searchQuery: string
): SearchResults[] {
  let matches: SearchResults[] = [];
  if (isEmpty(results) || isEmpty(searchQuery)) return matches;
  let currentMatch: {
    text: string;
    ranges: Immutable.Map<number, { [itemIdx: number]: Immutable.List<SearchMatchResult> }>;
  } = {
    text: '',
    ranges: Immutable.Map(),
  };

  const pageNums = keys(results).sort();
  pageNums.forEach((pageNum) => {
    const pageRanges = results[pageNum];
    const itemIdxs = keys(pageRanges).sort();

    itemIdxs.forEach((itemIdx) => {
      const itemRanges: SearchMatchResult[] = pageRanges[itemIdx];

      itemRanges.forEach(({ offset, length, match }) => {
        currentMatch = {
          text: currentMatch.text + match,
          ranges: currentMatch.ranges.updateIn([pageNum, itemIdx], Immutable.List(), (itemRanges) =>
            itemRanges.push({ offset, length, match })
          ),
        };

        // if this range completes full query text, save it to matches and reset the current match
        // TODO: will need refactoring if case-sensitive search is introduced
        if (currentMatch.text.toLowerCase() === searchQuery.toLowerCase()) {
          matches.push(currentMatch.ranges.toJS() as SearchResults);
          currentMatch = { text: '', ranges: Immutable.Map() };
        }
      });
    });
  });

  return matches;
}

function getTextItemElementFromSelectionNode(selectionNode: Node | HTMLElement | null) {
  if (selectionNode instanceof HTMLElement) {
    return selectionNode.hasAttribute('data-item-index') ? selectionNode : null;
  }

  if (selectionNode instanceof Node) {
    return selectionNode.parentElement
      ? selectionNode.parentElement.closest('[data-item-index]')
      : null;
  }

  return null;
}

export function getPDFSearchConfigFromCurrentSelection(): SearchConfig | null {
  const selection = document.getSelection();
  let selectedTextSearchConfig: SearchConfig | null = null;

  if (selection != null && !isEmpty(selection.toString().trim())) {
    const { anchorNode, focusNode, focusOffset } = selection;
    const $selectionStartTextItemElement: any = getTextItemElementFromSelectionNode(anchorNode);
    let $selectionEndTextItemElement: any = getTextItemElementFromSelectionNode(focusNode);

    if (focusOffset === 0 && $selectionEndTextItemElement) {
      $selectionEndTextItemElement = $selectionEndTextItemElement.previousElementSibling;
    }

    if ($selectionStartTextItemElement && $selectionEndTextItemElement) {
      let selectionStartItem = {
        pageNum: parseInt($selectionStartTextItemElement.dataset.pageNum),
        itemIndex: parseInt($selectionStartTextItemElement.dataset.itemIndex),
      };

      let selectionEndItem = {
        pageNum: parseInt($selectionEndTextItemElement.dataset.pageNum),
        itemIndex: parseInt($selectionEndTextItemElement.dataset.itemIndex),
      };

      // re-order start and end items if selection was reversed (right-to-left)
      if (
        selectionStartItem.pageNum > selectionEndItem.pageNum ||
        (selectionStartItem.pageNum === selectionEndItem.pageNum &&
          selectionStartItem.itemIndex > selectionEndItem.itemIndex)
      ) {
        [selectionStartItem, selectionEndItem] = [selectionEndItem, selectionStartItem];
      }

      selectedTextSearchConfig = {
        pages: range(selectionStartItem.pageNum, selectionEndItem.pageNum + 1),
        pageItems:
          selectionStartItem.pageNum === selectionEndItem.pageNum
            ? {
                [selectionStartItem.pageNum]: {
                  startItem: selectionStartItem.itemIndex,
                  endItem: selectionEndItem.itemIndex,
                },
              }
            : {
                [selectionStartItem.pageNum]: {
                  startItem: selectionStartItem.itemIndex,
                },
                [selectionEndItem.pageNum]: {
                  endItem: selectionEndItem.itemIndex,
                },
              },
      };
    }
  }
  return selectedTextSearchConfig;
}
