/** @jsx jsx */
import { Alignment, Button, Intent, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer } from '@blueprintjs/select';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { useCallback, useMemo } from 'react';
import { FTScreeningCriteria, InclusionExclusionCriteriaFormData } from '../../../common/types';
import { tiabFormDataToFTFormData } from '../../project/helpers';
import { CriteriaSelect } from '../full_text/ft_conflict_resolution_controls';

interface ITiabConflictResolutionControlsProps {
  disabled: boolean;
  onInclude: () => void;
  onExclude: (reasonCode: string) => void;
  formData: InclusionExclusionCriteriaFormData;
  className?: string;
  selectedRefsCounts?: number;
}

const TiabConflictResolutionControls: React.FC<ITiabConflictResolutionControlsProps> = ({
  disabled,
  onInclude,
  onExclude,
  formData,
  className,
  selectedRefsCounts,
}) => {
  const exclusionCriteria = useMemo(() => {
    return tiabFormDataToFTFormData(formData).exclusion;
  }, [formData]);

  const handleExclude = useCallback(
    (criteria: FTScreeningCriteria) => {
      onExclude(criteria.id);
    },
    [onExclude]
  );

  const renderCriteriaOption: ItemRenderer<FTScreeningCriteria> = useCallback(
    (criteria, { handleClick, modifiers }) => (
      <MenuItem
        key={criteria.id}
        active={modifiers.active}
        onClick={handleClick}
        text={criteria.name}
      />
    ),
    []
  );

  const showCount = selectedRefsCounts && selectedRefsCounts > 1;

  return (
    <div className={`flex flex-row w-full ${className ?? ''}`}>
      <div className="flex-1 mr-4">
        <Button
          className="h-full"
          fill
          alignText={Alignment.LEFT}
          disabled={disabled}
          intent={Intent.SUCCESS}
          text={<Trans>Include{showCount ? ` (${selectedRefsCounts})` : ''}</Trans>}
          onClick={onInclude}
        />
      </div>
      <CriteriaSelect
        items={exclusionCriteria}
        itemRenderer={renderCriteriaOption}
        onItemSelect={handleExclude}
        filterable={false}
        popoverProps={{
          className: 'flex-1',
          targetClassName: 'w-full',
          minimal: true,
        }}
      >
        <Button
          fill
          disabled={disabled}
          alignText={Alignment.LEFT}
          intent={Intent.DANGER}
          text={<Trans>Exclude{showCount ? ` (${selectedRefsCounts})` : ''}</Trans>}
          rightIcon={IconNames.CHEVRON_UP}
        />
      </CriteriaSelect>
    </div>
  );
};

export default TiabConflictResolutionControls;
