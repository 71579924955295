/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  TActiveAttributeFilters,
  TActiveKeywordFilters,
  TActiveKeywordVariable,
  TActiveScreeningTagFilters,
  TKeywordData,
  TKeywordType,
  TPdfFilter,
  YearFilters,
} from '../../apollo/screening_state';
import { useMemo } from 'react';
import {
  compose,
  entries,
  filter,
  forEach,
  get,
  keys,
  omitBy,
  propEq,
  reduce,
  sortBy,
  includes,
  keyBy,
} from 'lodash/fp';
import { Button, Tag } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { useCurrCallback, useKeywordTagStyles } from '../../lib/utils';
import { TScreeningTag } from '../references/admin';
import { pdfFilterOptions } from '../references/admin/references_filters';
import { useScreeningFilters } from '../hooks/use_screening_filters';

type TKeywordFilterEntry = { variablePath: string; keyword: string; type: TKeywordType };

const keywordFiltersEntriesReducer = (
  acc: TKeywordFilterEntry[],
  [variablePath, variableKeywords]: [string, TActiveKeywordVariable]
) => {
  compose(
    forEach(([keyword, { type }]: [string, TKeywordData]) =>
      acc.push({ variablePath, keyword, type })
    ),
    entries,
    omitBy(propEq('active', false)),
    get('keywords')
  )(variableKeywords);
  return acc;
};

interface IActiveFiltersBarProps {
  phraseTokens: string[];
  keywordFilters: TActiveKeywordFilters;
  decisionCodeFilters: TActiveAttributeFilters;
  documentTypeFilters: TActiveAttributeFilters;
  screeningTagFilters?: TActiveScreeningTagFilters;
  yearsFilters: YearFilters;
  onYearsFilterReset: () => void;
  onlyWithCommentsFilter?: boolean;
  pdfFilter?: TPdfFilter;
  onlyWithoutAbstractFilter?: boolean;
  screeningTags?: TScreeningTag[];
}

const ActiveFiltersBar: React.FC<IActiveFiltersBarProps> = ({
  phraseTokens,
  keywordFilters,
  decisionCodeFilters,
  documentTypeFilters,
  screeningTagFilters,
  yearsFilters,
  onlyWithCommentsFilter,
  pdfFilter,
  onlyWithoutAbstractFilter,
  screeningTags,
  onYearsFilterReset,
}) => {
  const [desiredKeywordCss, undesiredKeywordCss, neutralKeywordCss] = useKeywordTagStyles();
  const {
    toggleKeyword,
    toggleAttribute,
    toggleScreeningTag,
    clearFilters,
    changeSearchTokens,
    setOnlyWithCommentsFilter,
    setOnlyWithoutAbstractFilter,
    setPdfFilter,
  } = useScreeningFilters();

  const keywords = useMemo(() => {
    return compose(
      sortBy(({ type }) => (type === 'included' ? -1 : 1)),
      reduce(
        keywordFiltersEntriesReducer,
        [] as { variablePath: string; type: TKeywordType; keyword: string }[]
      ),
      entries
    )(keywordFilters);
  }, [keywordFilters]);

  const decisionCodes = useMemo(() => {
    return omitBy(propEq('active', false), decisionCodeFilters);
  }, [decisionCodeFilters]);

  const documentTypes = useMemo(() => {
    return omitBy(propEq('active', false), documentTypeFilters);
  }, [documentTypeFilters]);

  const activeScreeningTags = useMemo(() => {
    const activeTagsIds = compose(keys, omitBy(propEq('active', false)))(screeningTagFilters);
    return filter(
      (screeningTag: TScreeningTag) => includes(screeningTag.id, activeTagsIds),
      screeningTags
    );
  }, [screeningTagFilters, screeningTags]);

  const handlePhraseTokenRemove = useCurrCallback(
    (tokenIdx, _evt) => {
      const newKeywords = phraseTokens.slice();
      newKeywords.splice(tokenIdx, 1);
      changeSearchTokens(newKeywords);
    },
    [changeSearchTokens, phraseTokens]
  );
  const handleKeywordRemove = useCurrCallback(
    ({ variablePath, keyword, type }, _evt) => {
      toggleKeyword(variablePath, { keyword, type, active: false });
    },
    [toggleKeyword]
  );

  const handleAttributeRemove = useCurrCallback(
    ({ key, attribute, label }, _evt) => {
      toggleAttribute({ key, attribute, label, active: false });
    },
    [toggleAttribute]
  );

  const handleScreeningTagRemove = useCurrCallback(
    (id, _evt) => {
      toggleScreeningTag({ id, active: false });
    },
    [toggleScreeningTag]
  );

  return (
    <div className="flex flex-row flex-wrap items-center justify-between bg-gray-600 p-3">
      <div className="flex flex-row flex-wrap items-center">
        {phraseTokens.map((token, idx) => (
          <Tag
            className="mr-2 my-1"
            key={token}
            onRemove={handlePhraseTokenRemove(idx)}
            css={neutralKeywordCss}
          >
            {token}
          </Tag>
        ))}
        {keywords.map(({ keyword, type, variablePath }) => (
          <Tag
            className="mr-2 my-1"
            key={`${variablePath}_${keyword}`}
            css={type === 'excluded' ? undesiredKeywordCss : desiredKeywordCss}
            onRemove={handleKeywordRemove({ variablePath, keyword, type })}
          >
            {keyword}
          </Tag>
        ))}
        {keys(documentTypes).map((key) => (
          <Tag
            className="mr-2 my-1"
            key={key}
            css={neutralKeywordCss}
            onRemove={handleAttributeRemove({
              key: 'activeDocumentTypeFilters',
              attribute: key,
              label: documentTypes[key].label,
            })}
          >
            {documentTypes[key].label}
          </Tag>
        ))}
        {screeningTags &&
          activeScreeningTags.map(({ id, tag }) => (
            <Tag
              className="mr-2 my-1"
              key={id}
              css={neutralKeywordCss}
              onRemove={handleScreeningTagRemove(id)}
            >
              {tag}
            </Tag>
          ))}
        {yearsFilters.active && (
          <Tag className="mr-2 my-1" css={neutralKeywordCss} onRemove={() => onYearsFilterReset()}>
            {yearsFilters.minYear}-{yearsFilters.maxYear}
          </Tag>
        )}
        {keys(decisionCodes).map((key) => (
          <Tag
            className="mr-2 my-1"
            key={key}
            css={neutralKeywordCss}
            onRemove={handleAttributeRemove({
              key: 'activeDecisionCodeFilters',
              attribute: key,
              label: decisionCodes[key].label,
            })}
          >
            {decisionCodes[key].label}
          </Tag>
        ))}
        {onlyWithCommentsFilter && (
          <Tag
            className="mr-2 my-1"
            css={neutralKeywordCss}
            onRemove={() => setOnlyWithCommentsFilter(false)}
          >
            <Trans>Only with comments</Trans>
          </Tag>
        )}
        {pdfFilter && pdfFilter !== 'all' && (
          <Tag className="mr-2 my-1" css={neutralKeywordCss} onRemove={() => setPdfFilter('all')}>
            {keyBy('value', pdfFilterOptions)[pdfFilter].text}
          </Tag>
        )}
        {onlyWithoutAbstractFilter && (
          <Tag
            className="mr-2 my-1"
            css={neutralKeywordCss}
            onRemove={() => setOnlyWithoutAbstractFilter(false)}
          >
            <Trans>Only without abstract</Trans>
          </Tag>
        )}
      </div>
      <Button className="my-1" text={<Trans>Clear all filters</Trans>} onClick={clearFilters} />
    </div>
  );
};

export default ActiveFiltersBar;
