/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { PropsWithChildren, ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Colors, Intent } from '@blueprintjs/core';

const batchControlsCss = css`
  background-color: ${Colors.GRAY4};
  border: 1px solid #a7b6c2;
`;

interface IBatchControlsProps {
  batchSize: number;
  batchName?: ReactNode;
  batchTotal?: number;
  allReferencesSelected?: boolean;
  onSelectAll?: () => void;
}

const BatchControls: React.FC<PropsWithChildren<IBatchControlsProps>> = ({
  batchSize,
  children,
  batchName,
  batchTotal,
  allReferencesSelected,
  onSelectAll,
}) => {
  return (
    <div css={batchControlsCss} className="flex flex-row flex-no-wrap items-center h-12 p-5">
      <div className="mr-5 text-base flex-none">
        <Trans>Selected {batchName ?? ''}</Trans>:{' '}
        {batchTotal && allReferencesSelected ? (
          <Trans>
            {batchTotal} (showing {batchSize} of {batchTotal})
          </Trans>
        ) : batchTotal ? (
          <Trans>
            {batchSize} of {batchTotal}
          </Trans>
        ) : (
          batchSize
        )}
        {onSelectAll && !allReferencesSelected && batchSize < (batchTotal ?? 0) ? (
          <Button
            minimal
            className="ml-2"
            onClick={onSelectAll}
            intent={Intent.PRIMARY}
            text={
              <Trans>
                Select all {batchTotal} {batchName}
              </Trans>
            }
          />
        ) : null}
      </div>
      <div className="flex-1 text-right">{children}</div>
    </div>
  );
};

export default BatchControls;
