/** @jsx jsx */
import React, { Fragment, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/core';
import { plural, Plural, Trans } from '@lingui/macro';
import { AccountType, ReferenceClaim, Role, TeamMember, WorkerStatus } from '../../../common/types';
import {
  Button,
  Card,
  Classes,
  Colors,
  Dialog,
  Divider,
  Icon,
  Intent,
  Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useI18n, useSetState } from '../../../lib/utils';
import UserAvatar from '../../common/user_avatar';
import { gql } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { compose, filter, find, get, isEmpty, isNil, propEq } from 'lodash/fp';
import { useKeycloak } from '../../../keycloak';
import AppToaster from '../../../lib/toaster';
import ExportDialog, { ExportDialogMode } from './export_dialog';
import useActionLogger from '../../hooks/use_action_logger';
import i18n from '../../../i18n';

const EMPTY_MANAGERS: TeamMember[] = [];

const GET_PROJECT_MEMBERS_QUERY = gql`
  query GetProjectMembers($projectId: uuid!) {
    team_member(where: { project_id: { _eq: $projectId } }) {
      id
      role
      user {
        id
        name
        firstName
        lastName
        role
      }
    }
  }
`;

const GET_REFERENCES_TO_CLAIM_COUNT_QUERY = gql`
  query GetReferencesToClaimCount($projectId: uuid!, $stageId: uuid!) {
    get_references_to_claim_aggregate(args: { project_id: $projectId, stage_id: $stageId }) {
      aggregate {
        count
      }
    }
  }
`;

const INSERT_REFERENCE_CLAIM_MUTATION = gql`
  mutation InsertReferenceClaim($projectId: uuid!, $stageId: uuid!, $teamMemberId: uuid!) {
    insert_reference_claim_one(
      object: { project_id: $projectId, stage_id: $stageId, claimed_by: $teamMemberId }
    ) {
      id
      project_id
      stage_id
      claimed_by
      completed
      created_at
    }
  }
`;

const DELETE_REFERENCE_CLAIM_MUTATION = gql`
  mutation DeleteReferenceClaim($claimId: uuid!) {
    update_reference(
      where: { claims: { claim_id: { _eq: $claimId } } }
      _set: { no_report: false }
    ) {
      affected_rows
    }
    delete_reference_claim_by_pk(id: $claimId) {
      id
    }
  }
`;

interface IDeleteClaimDialog {
  isOpen: boolean;
  claimId: string | null;
  projectManagers: TeamMember[];
  onClose: () => void;
  referenceClaim?: ReferenceClaim;
}

const DeleteClaimDialog: React.FC<IDeleteClaimDialog> = ({
  isOpen,
  claimId,
  projectManagers,
  referenceClaim,
  onClose,
}: IDeleteClaimDialog) => {
  const insertActionLog = useActionLogger();
  const [deleteReferenceClaimById] = useMutation(DELETE_REFERENCE_CLAIM_MUTATION);

  const claimedById = get('claimed_by', referenceClaim);
  const claimedByName = isOpen
    ? compose(get('user.name'), find(propEq('id', claimedById)))(projectManagers)
    : null;
  const allClaimedReferencesCount: number =
    get('all_claimed_references_aggregate.aggregate.count', referenceClaim) ?? 0;
  const completedClaimedReferencesCount: number =
    get('completed_claimed_references_aggregate.aggregate.count', referenceClaim) ?? 0;

  const unclaimReferences = useCallback(() => {
    deleteReferenceClaimById({
      variables: {
        claimId,
      },
    })
      .then(() => {
        insertActionLog('successfully deleted reference claim', { claimId });
        AppToaster.show({
          message: i18n._(
            plural({
              value: allClaimedReferencesCount,
              one: 'Unclaimed 1 reference',
              other: 'Unclaimed # references',
            })
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        insertActionLog('error while deleting reference claim', { claimId, error: error.message });
        AppToaster.show({
          icon: IconNames.ERROR,
          message: (
            <span>
              <Trans>Failed to unclaim references</Trans>: {error.message}
            </span>
          ),
          intent: Intent.DANGER,
        });
      })
      .finally(() => onClose());
  }, [deleteReferenceClaimById, insertActionLog, onClose, allClaimedReferencesCount, claimId]);

  return (
    <Dialog
      className="w-1/3 bg-white"
      isCloseButtonShown
      isOpen={isOpen}
      onClose={onClose}
      icon={IconNames.WARNING_SIGN}
      title={<Trans>Unclaim references</Trans>}
    >
      {isOpen && (
        <div className={Classes.DIALOG_BODY}>
          <p>
            <Plural
              value={allClaimedReferencesCount}
              one={`Are you sure you want to unclaim 1 reference from ${claimedByName}?`}
              other={`Are you sure you want to unclaim # references from ${claimedByName}?`}
            />
          </p>
          {completedClaimedReferencesCount > 0 && (
            <p>
              <Plural
                value={completedClaimedReferencesCount}
                one="1 reference already got the attachment uploaded so it will not be added back to the unclaimed references."
                other="# references already got their attachments uploaded so they will not be added back to the unclaimed references."
              />
            </p>
          )}
        </div>
      )}
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex flex-row justify-end">
          <Button large onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button large className="ml-2 px-4" intent={Intent.WARNING} onClick={unclaimReferences}>
            <Trans>Unclaim</Trans>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const studiesWithoutAttachmentsCardCss = css`
  background: ${Colors.ORANGE5};
  border: 2px solid ${Colors.ORANGE4};
`;

interface IReferenceClaimsPanelProps {
  projectId: string;
  stageId: string;
  referenceClaims: ReferenceClaim[];
  studiesWithoutAttachmentsCount: number;
  openImportPDFsDialog: () => void;
  openExportReferencesDialog: () => void;
}

interface IReferenceClaimsPanelState {
  isOpen: boolean;
  isAddingNewClaim: boolean;
  selectedClaimId: string | null;
  actionDialogFor: 'delete' | 'export' | null;
}

const REFERENCE_CLAIMS_PANEL_INITIAL_STATE: IReferenceClaimsPanelState = {
  isOpen: false,
  isAddingNewClaim: false,
  selectedClaimId: null,
  actionDialogFor: null,
};

const ReferenceClaimsPanel: React.FC<IReferenceClaimsPanelProps> = ({
  projectId,
  stageId,
  referenceClaims,
  studiesWithoutAttachmentsCount,
  openImportPDFsDialog,
  openExportReferencesDialog,
}) => {
  const {
    user: { id: userId },
  } = useKeycloak();
  const i18n = useI18n();
  const [state, setState] = useSetState<IReferenceClaimsPanelState>(
    REFERENCE_CLAIMS_PANEL_INITIAL_STATE
  );
  const { isOpen, isAddingNewClaim, selectedClaimId, actionDialogFor } = state;

  const { data: projectMembersData } = useQuery(GET_PROJECT_MEMBERS_QUERY, {
    variables: {
      projectId,
    },
  });
  const projectManagers: TeamMember[] = useMemo(
    () =>
      compose(
        filter<TeamMember>(
          (teamMember) =>
            teamMember.role === Role.Manager ||
            (teamMember.role === Role.Screener && teamMember.user?.role === AccountType.TechAdmin)
        ),
        get('team_member')
      )(projectMembersData) ?? EMPTY_MANAGERS,
    [projectMembersData]
  );

  const { data: referencesToClaimCountData, refetch: refetchReferencesToClaimCount } = useQuery(
    GET_REFERENCES_TO_CLAIM_COUNT_QUERY,
    {
      variables: {
        projectId,
        stageId,
      },
      pollInterval: 1000,
    }
  );
  const referencesToClaimCount: number =
    get('get_references_to_claim_aggregate.aggregate.count', referencesToClaimCountData) ?? 0;

  const [insertReferenceClaim] = useMutation(INSERT_REFERENCE_CLAIM_MUTATION);

  const togglePanelOpen = () =>
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
    }));

  const openClaimDialog = useCallback(
    (actionDialogFor: 'delete' | 'export', claimId?: string) => {
      setState({ selectedClaimId: claimId, actionDialogFor });
    },
    [setState]
  );

  const claimReferences = useCallback(() => {
    const currentTeamMember = find(propEq('user.id', userId), projectManagers);
    if (isNil(currentTeamMember)) return;

    setState({ isAddingNewClaim: true });

    insertReferenceClaim({
      variables: {
        projectId,
        stageId,
        teamMemberId: currentTeamMember.id,
      },
    })
      .then(() =>
        AppToaster.show({
          message: i18n._(
            plural({
              value: referencesToClaimCount,
              one: 'Reference claimed!',
              other: '# references claimed!',
            })
          ),
          intent: Intent.SUCCESS,
        })
      )
      .catch((err) => {
        AppToaster.show({
          message: (
            <span>
              <Trans>Failed to claim references</Trans>: {err.toString()}
            </span>
          ),
          intent: Intent.WARNING,
        });
      })
      .finally(() => setState({ isAddingNewClaim: false }));
  }, [
    setState,
    insertReferenceClaim,
    i18n,
    projectManagers,
    userId,
    projectId,
    stageId,
    referencesToClaimCount,
  ]);

  const renderClaimRow = useCallback(
    (referenceClaim: Partial<ReferenceClaim>) => {
      const {
        id,
        claimed_by,
        all_claimed_references_aggregate,
        completed_claimed_references_aggregate,
        status,
      } = referenceClaim;
      const allClaimedReferencesCount: number =
        get('aggregate.count', all_claimed_references_aggregate) ?? 0;
      const completedClaimedReferencesCount: number =
        get('aggregate.count', completed_claimed_references_aggregate) ?? 0;

      const teamMember = find(propEq('id', claimed_by), projectManagers);

      return (
        <Fragment key={id}>
          <div className="flex flex-row items-center my-2 claim-batch">
            <div className="w-1/3 ml-6 flex-shrink-0">
              <Icon icon={claimed_by ? IconNames.GEOSEARCH : IconNames.DOCUMENT} />
              <span className="ml-2 inline-block">
                {status === WorkerStatus.InProgress ? (
                  <Spinner size={12} />
                ) : claimed_by && completedClaimedReferencesCount > 0 ? (
                  <Plural
                    value={allClaimedReferencesCount - completedClaimedReferencesCount}
                    one={`#/${allClaimedReferencesCount} claimed reference`}
                    other={`#/${allClaimedReferencesCount} claimed references`}
                  />
                ) : claimed_by ? (
                  <Plural
                    value={allClaimedReferencesCount}
                    one="1 claimed reference"
                    other="# claimed references"
                  />
                ) : (
                  <Plural
                    value={allClaimedReferencesCount}
                    one="1 unclaimed reference"
                    other="# unclaimed references"
                  />
                )}
              </span>
            </div>
            <div className="w-1/4 flex-shrink-0">
              <Button
                minimal
                icon={IconNames.DOWNLOAD}
                onClick={() => openClaimDialog('export', id)}
              >
                <Trans>Download</Trans>
              </Button>
            </div>
            <div className="flex-grow">
              {id ? (
                <div className="flex flex-row items-center">
                  <span className="mx-2 text-xs text-gray-500">
                    <Trans>claimed by</Trans>
                  </span>
                  {teamMember ? <UserAvatar user={teamMember.user} withName size={24} /> : '-'}
                  <Button
                    minimal
                    className="ml-2"
                    icon={IconNames.CROSS}
                    onClick={() => openClaimDialog('delete', id)}
                  />
                </div>
              ) : (
                <Button
                  minimal
                  icon={IconNames.FLAG}
                  loading={isAddingNewClaim}
                  onClick={claimReferences}
                >
                  <Trans>Claim</Trans>
                </Button>
              )}
            </div>
          </div>
          <Divider className="m-0" />
        </Fragment>
      );
    },
    [openClaimDialog, claimReferences, projectManagers, isAddingNewClaim]
  );

  const newReferenceClaimDetails: Pick<ReferenceClaim, 'all_claimed_references_aggregate'> = {
    all_claimed_references_aggregate: { aggregate: { count: referencesToClaimCount } },
  };
  const claimsInProgress: ReferenceClaim[] = filter(
    { status: WorkerStatus.InProgress },
    referenceClaims
  );
  const newClaimRow: ReactNode =
    isEmpty(claimsInProgress) && referencesToClaimCount > 0
      ? renderClaimRow(newReferenceClaimDetails)
      : null;

  const referencesToExportCount: number =
    selectedClaimId == null
      ? referencesToClaimCount
      : referenceClaims.find(propEq('id', selectedClaimId))?.all_claimed_references_aggregate
          .aggregate.count ?? 0;

  useEffect(() => {
    setState({ isAddingNewClaim: false });
    refetchReferencesToClaimCount();
  }, [setState, referenceClaims, refetchReferencesToClaimCount]);

  return (
    <Fragment>
      <Card
        data-testid="references-claims-panel"
        className="p-0 m-0 shadow-none"
        css={studiesWithoutAttachmentsCardCss}
      >
        <div className="flex flex-row items-center justify-between">
          <span className="font-normal ml-6 my-2">
            <Plural
              value={studiesWithoutAttachmentsCount}
              one="There is 1 reference without the PDF."
              other="There are # references without the PDF."
            />
          </span>
          <div className="flex flex-row h-full">
            <Button
              minimal
              large
              className="mr-2"
              icon={IconNames.DOWNLOAD}
              onClick={openExportReferencesDialog}
            />
            <Button minimal large icon={IconNames.CLOUD_UPLOAD} onClick={openImportPDFsDialog} />
            <Divider className="h-full" />
            <Button
              minimal
              large
              className="h-full"
              icon={isOpen ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              onClick={togglePanelOpen}
            />
          </div>
        </div>
      </Card>
      {isOpen && (
        <div className="flex flex-col bg-white claim-batches">
          {newClaimRow}
          {referenceClaims.map(renderClaimRow)}
        </div>
      )}
      <ExportDialog
        isOpen={actionDialogFor === 'export'}
        mode={
          selectedClaimId != null
            ? ExportDialogMode.ReferenceClaim
            : ExportDialogMode.ReferencesNotClaimed
        }
        onClose={() => setState({ selectedClaimId: null, actionDialogFor: null })}
        projectId={projectId}
        referenceClaimId={selectedClaimId}
        stageId={stageId}
        allReferencesCount={referencesToExportCount}
      />
      <DeleteClaimDialog
        isOpen={actionDialogFor === 'delete' && selectedClaimId != null}
        onClose={() => setState({ selectedClaimId: null, actionDialogFor: null })}
        claimId={selectedClaimId}
        projectManagers={projectManagers}
        referenceClaim={find(propEq('id', selectedClaimId), referenceClaims)}
      />
    </Fragment>
  );
};

export default ReferenceClaimsPanel;
