/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useCallback, memo, useEffect, useRef, useMemo } from 'react';
import {
  Button,
  InputGroup,
  Position,
  ProgressBar,
  Intent,
  Tooltip,
  Card,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { usePrevious, useSetState } from '../../lib/utils';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PDFSearch from '../../lib/pdf_search_provider';
import { SearchConfig, PDFSearchHandle } from '../../lib/pdf_search_provider';
import { initialPDFSearchData, PDFSearchData } from '../../apollo/pdf_viewer_state';
import { clamp, get, isEmpty } from 'lodash/fp';

const updatePDFSearchDataMutation = loader('../../graphql/local/update_pdf_search_data.gql');
const activePDFTextRangesQuery = loader('../../graphql/local/get_active_pdf_text_ranges.gql');
const PDFSearchDataQuery = loader('../../graphql/local/get_pdf_search_data.gql');

const bottomLeftPositionCss = css`
  position: absolute;
  bottom: 15px;
  left: 0;
`;

const topRightPositionCss = css`
  position: absolute;
  top: -1px;
  right: 15px;
`;

const bottomRightPositionCss = css`
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const searchBarCss = css`
  z-index: 999;
  display: none;

  &.open {
    display: flex;
  }
`;

type PDFSearchQueryData = {
  PDFViewer: {
    search: PDFSearchData;
  };
};

export interface ISearchBarProps {
  searchDoc:
    | ((searchText: string, searchConfig?: SearchConfig | undefined) => PDFSearchHandle)
    | null;
  position?: 'topRight' | 'bottomLeft' | 'bottomRight';
}

const SearchBar = memo((props: ISearchBarProps) => {
  const { searchDoc, position = 'bottomLeft' } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useSetState<{ searchQuery: string; activeMatch: number | null }>({
    searchQuery: '',
    activeMatch: null,
  });
  const [updatePDFSearchData] = useMutation(updatePDFSearchDataMutation);
  const { data } = useQuery<PDFSearchQueryData>(PDFSearchDataQuery);

  const {
    query: searchedQuery,
    results: searchResults,
    isOpen: isSearchOpen,
    isSearching,
    progress,
    searchKey,
    matches,
  } = (get('PDFViewer.search', data) ?? initialPDFSearchData) as PDFSearchData;

  const wasOpen = usePrevious(isSearchOpen);
  const { searchQuery, activeMatch } = state;

  const handleSearchQueryChange = useCallback(
    (evt) => {
      setState({ searchQuery: evt.target.value });
    },
    [setState]
  );

  const doSearch = useCallback(() => {
    if (searchDoc == null) return;

    const searchHandle = searchDoc(searchQuery, {
      onProgress: ({ progress: { progress, results }, done, cancelled }) => {
        updatePDFSearchData({
          variables: {
            searchData: cancelled
              ? initialPDFSearchData
              : { isSearching: !done, results, progress },
          },
          refetchQueries: done ? [{ query: activePDFTextRangesQuery }] : [],
        });
      },
    });

    updatePDFSearchData({
      variables: {
        searchData: {
          query: searchQuery,
          isSearching: true,
          searchKey: searchHandle.key,
        },
      },
    });
  }, [searchDoc, updatePDFSearchData, searchQuery]);

  const cancelSearch = useCallback(() => {
    searchKey && PDFSearch.cancelSearch(searchKey);
    updatePDFSearchData({
      variables: { searchData: { ...initialPDFSearchData, isOpen: isSearchOpen } },
      refetchQueries: [{ query: activePDFTextRangesQuery }],
    });
    setState({ searchQuery: '' });
  }, [setState, updatePDFSearchData, searchKey, isSearchOpen]);

  const handleKeyUp = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      switch (evt.keyCode) {
        case 27 /* escape */: {
          return cancelSearch();
        }
        case 13 /* enter */: {
          return doSearch();
        }
        default:
          return;
      }
    },
    [doSearch, cancelSearch]
  );

  const setRef = useCallback(
    (el: HTMLInputElement | null) => {
      inputRef.current = el;
    },
    [inputRef]
  );

  const positionCss = useMemo(() => {
    switch (position) {
      case 'bottomLeft':
        return bottomLeftPositionCss;
      case 'topRight':
        return topRightPositionCss;
      case 'bottomRight':
        return bottomRightPositionCss;
      default:
        return topRightPositionCss;
    }
  }, [position]);

  useEffect(() => {
    setState({ activeMatch: isEmpty(matches) ? null : 0 });
  }, [setState, matches]);

  useEffect(() => {
    if (activeMatch != null && !isEmpty(matches)) {
      updatePDFSearchData({
        variables: { searchData: { activeMatchRanges: matches[activeMatch] } },
      });
    }
  }, [updatePDFSearchData, activeMatch, matches]);

  useEffect(() => {
    if (isSearchOpen && !wasOpen) {
      inputRef.current?.focus();
    }
  }, [isSearchOpen, wasOpen]);

  return (
    <Card
      className={`flex flex-col p-2 ${isSearchOpen ? 'open' : ''}`}
      css={[positionCss, searchBarCss]}
    >
      <div className="flex flex-row">
        <InputGroup
          className="mr-2"
          fill
          inputRef={setRef}
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search text"
          onKeyUp={handleKeyUp}
          rightElement={
            <span>
              <span className="text-gray-600">
                {`${activeMatch == null ? '-' : activeMatch + 1}/${matches.length}`}
              </span>
              {isSearching || (!isEmpty(searchResults) && searchQuery === searchedQuery) ? (
                <Tooltip hoverOpenDelay={1000} position={Position.BOTTOM} content="Clear search">
                  <Button
                    minimal
                    intent={Intent.DANGER}
                    icon={IconNames.CROSS}
                    onClick={cancelSearch}
                  />
                </Tooltip>
              ) : (
                <Button
                  minimal
                  icon={IconNames.SEARCH}
                  disabled={searchDoc == null || isEmpty(searchQuery)}
                  onClick={doSearch}
                  loading={isSearching}
                />
              )}
            </span>
          }
        />
        <Button
          small
          className="mr-1"
          disabled={isEmpty(matches)}
          onClick={() =>
            setState({ activeMatch: clamp(0, matches.length - 1, (activeMatch ?? 0) - 1) })
          }
          icon={IconNames.CARET_UP}
        />
        <Button
          small
          disabled={isEmpty(matches)}
          onClick={() =>
            setState({ activeMatch: clamp(0, matches.length - 1, (activeMatch ?? 0) + 1) })
          }
          icon={IconNames.CARET_DOWN}
        />
      </div>
      {isSearching && <ProgressBar value={progress} intent={Intent.PRIMARY} />}
    </Card>
  );
});

export default SearchBar;
