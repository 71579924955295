/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Classes,
  Colors,
  Divider,
  Drawer,
  Icon,
  NonIdealState,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React, { ReactNode, useCallback, useState } from 'react';
import { fancyScrollCss, lightGray5bg } from '../../../common/styles';
import BibliographicData from './bibliographic_data';
import { TReferenceData } from '..';
import Attachments from './attachments';
import CriteriaScreener from '../../criteria/criteria_screener';

export interface IFullTextReferenceScreeningProps {
  projectId: string;
  stageId: string;
  sidebarContent: ReactNode;
  decisionControls: ReactNode;
  closeFocusMode?: () => void;
  reference?: TReferenceData;
  decisionLabels?: ReactNode;
  navigateToPrevReference?: () => Promise<any>;
  navigateToNextReference?: () => Promise<any>;
}

const FullTextReferenceScreening: React.FC<IFullTextReferenceScreeningProps> = ({
  projectId,
  stageId,
  reference,
  sidebarContent,
  closeFocusMode,
  decisionControls,
  decisionLabels,
  navigateToPrevReference,
  navigateToNextReference,
}) => {
  const [bibliographicDataVisible, setBibliographicDataVisible] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const toggleBibliographicData = useCallback(() => {
    setBibliographicDataVisible((isVisible) => !isVisible);
  }, [setBibliographicDataVisible]);

  if (!reference?.reference_attachments.length && !reference?.attrs.fullText) {
    return (
      <NonIdealState
        icon={IconNames.HEART_BROKEN}
        title={<Trans>Oops, looks like required data is missing</Trans>}
        description={
          <Trans>
            Ensure there is a PDF file attached to this assignment and/or the project extraction
            form is set up.
          </Trans>
        }
        action={
          closeFocusMode ? (
            <Button
              className="mt-2"
              icon={IconNames.ARROW_LEFT}
              text={<Trans>Reference list</Trans>}
              onClick={closeFocusMode}
            />
          ) : undefined
        }
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-row flex-no-wrap overflow-hidden relative">
      {reference ? (
        <Attachments
          reference={reference}
          {...{ navigateToPrevReference, navigateToNextReference }}
        />
      ) : (
        <NonIdealState title={<Trans>No reference data</Trans>} icon={IconNames.HEART_BROKEN} />
      )}
      <Divider className="m-0" />
      <div className="flex flex-col flex-none max-w-lg w-1/3 overflow-auto">
        <div className="h-12 flex flex-row items-center justify-end" css={lightGray5bg}>
          {/*TODO: pending spec
          <Divider className="m-0 h-full" />
          <Button className="h-full" minimal large icon={IconNames.MANUAL} />
          */}
          <div className="flex-1 text-left truncate px-2">{decisionLabels}</div>
          <Divider className="m-0 h-full" />
          <Tooltip className="flex h-full" content={<Trans>Bibliographic data</Trans>}>
            <Button
              className="h-full"
              minimal
              large
              disabled={reference == null}
              icon={
                <Icon
                  icon={IconNames.INFO_SIGN}
                  color={bibliographicDataVisible ? Colors.BLUE3 : undefined}
                />
              }
              onClick={toggleBibliographicData}
            />
          </Tooltip>
          <Divider className="m-0 h-full" />
          <Tooltip
            className="flex h-full"
            content={<Trans>Inclusion and Exclusion Instructions</Trans>}
          >
            <Button
              className="h-full"
              minimal
              large
              icon={<Icon icon={IconNames.MANUAL} />}
              onClick={() => setInstructionsOpen(true)}
            />
          </Tooltip>
        </div>
        <Divider className="m-0" />
        <div className="flex flex-col flex-1 overflow-auto" css={fancyScrollCss}>
          {reference && bibliographicDataVisible ? (
            <BibliographicData reference={reference} onClose={toggleBibliographicData} />
          ) : (
            sidebarContent
          )}
        </div>
        <div
          className="flex-0 h-12 flex flex-row flex-no-wrap justify-between px-4 py-2"
          css={lightGray5bg}
        >
          {decisionControls}
        </div>
      </div>
      <Drawer
        onClose={() => setInstructionsOpen(false)}
        isOpen={instructionsOpen}
        title={<Trans>Inclusion and Exclusion Instructions</Trans>}
        size="50%"
      >
        <div className={Classes.DRAWER_BODY}>
          <CriteriaScreener projectId={projectId} stageId={stageId} />
        </div>
      </Drawer>
    </div>
  );
};

export default FullTextReferenceScreening;
