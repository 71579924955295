/** @jsx jsx **/
import { Button, Classes, DialogProps, Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { ReactNode } from 'react';
import Dialog from './dialog';

interface IConfirmationDialogProps extends DialogProps {
  onConfirm: () => void;
  icon?: IconName;
  confirmBtnText?: ReactNode;
  confirmBtnIntent?: Intent;
  confirmBtnDisabled?: boolean;
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  icon,
  onClose,
  onConfirm,
  confirmBtnIntent,
  confirmBtnText,
  confirmBtnDisabled,
  children,
  ...rest
}) => {
  return (
    <Dialog {...rest} onClose={onClose} icon={icon ?? IconNames.WARNING_SIGN}>
      <div className={Classes.DIALOG_BODY}>{children}</div>
      <div className={`${Classes.DIALOG_FOOTER}`}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={onClose} />
          <Button
            intent={confirmBtnIntent ?? Intent.WARNING}
            text={confirmBtnText ?? <Trans>Ok</Trans>}
            onClick={onConfirm}
            disabled={confirmBtnDisabled}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
