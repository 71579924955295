/** @jsx jsx */
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { find, isEmpty, propEq, sumBy } from 'lodash/fp';
import { ReferenceClaim, ScreeningType, StageType, StudyDistribution } from '../../../common/types';
import ReferencesDistribution from './references_distribution';
import { DashboardCardWithButton } from './dashboard_card';
import { TScreenerData } from '../../../lib/distribution_helpers';
import { TStudyToDistribute } from '../../../lib/study_helpers';
import ReferenceClaimsPanel from './reference_claims_panel';

interface IDistributionCardProps {
  hasCriteria: boolean;
  isDistributing: boolean;
  onDistribute: (
    studiesPerMember: { [memberId: string]: number },
    distributionType: StudyDistribution,
    studies: TStudyToDistribute[]
  ) => Promise<any>;
  screeningTypes: ScreeningType[];
  selectedScreeningTypeId: string;
  referenceClaims: ReferenceClaim[];
  projectId: string;
  stageId: string;
  stageType: StageType;
  studiesToDistribute: TStudyToDistribute[];
  teamMembers: TScreenerData[];
  studiesWithoutAttachmentsCount?: number;
  openImportPDFsDialog: () => void;
  openExportReferencesDialog: () => void;
  returnedReferences?: boolean;
  isImportingReferences?: boolean;
}

const DistributionCard: React.FC<IDistributionCardProps> = ({
  hasCriteria,
  isDistributing,
  isImportingReferences,
  onDistribute,
  returnedReferences = false,
  screeningTypes,
  projectId,
  stageId,
  referenceClaims,
  selectedScreeningTypeId,
  stageType,
  studiesWithoutAttachmentsCount,
  studiesToDistribute,
  teamMembers,
  openImportPDFsDialog,
  openExportReferencesDialog,
}) => {
  const screeningType = useMemo(
    () => find(propEq('id', selectedScreeningTypeId), screeningTypes)!,
    [selectedScreeningTypeId, screeningTypes]
  );

  const [distributionDialogOpen, setDistributionDialogOpen] = useState(false);
  const [sendingDistribution, setSendingDistribution] = useState(false);

  const onCloseDistributionDialog = useCallback(() => {
    setDistributionDialogOpen(false);
  }, [setDistributionDialogOpen]);

  const distributionDisabled = useMemo(
    () =>
      isEmpty(studiesToDistribute) ||
      isImportingReferences ||
      isDistributing ||
      screeningType.screeners_no > teamMembers.length ||
      !hasCriteria,
    [
      studiesToDistribute,
      isImportingReferences,
      isDistributing,
      screeningType.screeners_no,
      teamMembers.length,
      hasCriteria,
    ]
  );

  const distributedTasksCount = useMemo(() => {
    return sumBy('tasks_aggregate.aggregate.count', teamMembers);
  }, [teamMembers]);

  const cardName = useMemo(
    () =>
      returnedReferences ? (
        <Trans>Returned references distribution</Trans>
      ) : (
        <Trans>Distribution</Trans>
      ),
    [returnedReferences]
  );

  const cardContents = useMemo(
    () =>
      isEmpty(studiesToDistribute) ? (
        stageType === StageType.FullTextScreening && distributedTasksCount === 0 ? (
          <div className="px-6">
            <Trans>
              All available studies have PDF reports missing. Please upload reports to proceed with
              distribution
            </Trans>
          </div>
        ) : (
          <div className="px-6">
            <Trans>All studies are distributed among reviewers</Trans>
          </div>
        )
      ) : (
        <ReferencesDistribution
          distributionDialogOpen={distributionDialogOpen}
          hasCriteria={hasCriteria}
          onCloseDistributionDialog={onCloseDistributionDialog}
          onDistribute={onDistribute}
          returnedReferences={returnedReferences}
          screeningType={screeningType}
          setSendingDistribution={setSendingDistribution}
          studies={studiesToDistribute}
          teamMembers={teamMembers}
        />
      ),
    [
      distributionDialogOpen,
      hasCriteria,
      onCloseDistributionDialog,
      onDistribute,
      returnedReferences,
      screeningType,
      stageType,
      studiesToDistribute,
      teamMembers,
    ]
  );

  const studiesWithoutAttachmentsCountVal = studiesWithoutAttachmentsCount ?? 0;

  return (
    <Fragment>
      {stageType === StageType.FullTextScreening && studiesWithoutAttachmentsCountVal > 0 && (
        <ReferenceClaimsPanel
          projectId={projectId}
          stageId={stageId}
          referenceClaims={referenceClaims}
          studiesWithoutAttachmentsCount={studiesWithoutAttachmentsCountVal}
          openImportPDFsDialog={openImportPDFsDialog}
          openExportReferencesDialog={openExportReferencesDialog}
        />
      )}
      <DashboardCardWithButton
        buttonProps={{
          disabled: distributionDisabled,
          loading: sendingDistribution || isDistributing,
          text: <Trans>Configure distribution</Trans>,
          tooltip: isImportingReferences ? <Trans>References upload in progress</Trans> : undefined,
        }}
        name={cardName}
        onClick={() => setDistributionDialogOpen(true)}
      >
        {cardContents}
      </DashboardCardWithButton>
    </Fragment>
  );
};

export default DistributionCard;
