import { isEmpty, last, map, sortBy } from 'lodash/fp';
import React, { ReactNode } from 'react';

export interface StrFragmentSpec {
  offset: number;
  length: number;
  data?: any;
}

interface DecoratedFragment {
  offset: number;
  length: number;
  content: ReactNode;
}

type TDecoratorProps = { [key: string]: any };

export type StringFragmentDecorator = React.FC<{
  text: string;
  fragmentSpec: StrFragmentSpec;
  decoratorProps: TDecoratorProps;
}>;

interface StringDecorationConfig {
  decorator: StringFragmentDecorator;
  decoratorProps?: TDecoratorProps;
}

// (1) removes overlapping fragments: if one fragment overlaps another the first one recorded will
// be kept, the second one will be dropped; (2) orders all fragments by offset
function sanitizeFragments(fragments: StrFragmentSpec[]): StrFragmentSpec[] {
  let result: StrFragmentSpec[] = [];
  let decorated = {};

  // iterate over given fragments. For each fragment, check if any of its range position has already
  // been recorded within previous framgnet range. If there is none, add this range to resulst and
  // mark all of its positions as decorated.
  fragments.forEach((f) => {
    // got through fragment positions and check if any is occupied, if not, mark it as on.
    for (let i = f.offset; i < f.offset + f.length; i++) {
      // this index is already occupied, do not keep this fragment
      if (decorated[i]) return;
      // mark as occupied
      decorated[i] = true;
    }

    result.push(f);
  });

  return sortBy('offset', result);
}

export const decorateString = (
  str: string,
  decoratedFragments: StrFragmentSpec[],
  config: StringDecorationConfig
): ReactNode[] => {
  if (isEmpty(str)) return [];
  if (isEmpty(decoratedFragments)) return [str];
  const { decorator: Decorator, decoratorProps } = config;
  const sanitized = sanitizeFragments(decoratedFragments);

  const stringFragments = sanitized.reduce(
    (resultingFragments: DecoratedFragment[], fragment: StrFragmentSpec, idx: number) => {
      const prevFragment: DecoratedFragment | null = last(resultingFragments) || null;
      const { offset, length } = fragment;
      const isLast = idx === sanitized.length - 1;
      // if there is a "gap" of raw string between this fragment and previous fragment or
      // this fragment and start of the string, insert raw string fragment
      if ((prevFragment && offset > prevFragment.offset + prevFragment.length) || offset > 0) {
        const rawStrOffset = prevFragment ? prevFragment.offset + prevFragment.length : 0;
        const rawStrLength = prevFragment ? offset - rawStrOffset : offset;

        resultingFragments.push({
          offset: rawStrOffset,
          length: rawStrLength,
          content: str.slice(rawStrOffset, rawStrOffset + rawStrLength),
        });
      }

      resultingFragments.push({
        offset,
        length,
        content: (
          <Decorator
            key={idx}
            text={str.slice(offset, offset + length)}
            fragmentSpec={fragment}
            decoratorProps={decoratorProps || {}}
          />
        ),
      });

      if (isLast && offset + length < str.length) {
        const tailRawStringOffset = offset + length;
        const tailRawStringLength = str.length - tailRawStringOffset;
        resultingFragments.push({
          offset: tailRawStringOffset,
          length: tailRawStringLength,
          content: str.slice(tailRawStringOffset),
        });
      }

      return resultingFragments;
    },
    []
  );

  return map('content', stringFragments);
};

interface DecoratedStringProps {
  string: string;
  decorationRanges: StrFragmentSpec[];
  decorator: StringFragmentDecorator;
  decoratorProps?: TDecoratorProps;
}

export const DecoratedString: React.FC<DecoratedStringProps> = ({
  string,
  decorationRanges,
  decorator,
  decoratorProps,
}) => (
  <React.Fragment>
    {decorateString(string, decorationRanges, { decorator, decoratorProps })}
  </React.Fragment>
);
