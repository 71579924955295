/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { projectHeaderCss } from '../../common/styles';
import Header2 from '../header2';
import ProjectsListSidebar from '../project/projects_list_sidebar';

interface IAppModulePageProps extends RouteComponentProps {}

const AppModulePage: React.FC<IAppModulePageProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div className="w-full h-full flex flex-row flex-no-wrap">
      <ProjectsListSidebar {...rest} />
      <div className="flex flex-col flex-1 overflow-auto" style={{ paddingTop: 50 }}>
        <Header2 {...rest} css={projectHeaderCss} />
        {children}
      </div>
    </div>
  );
};

export default AppModulePage;
