/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import React, { useCallback, useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { compose, get, isEmpty, map } from 'lodash/fp';
import AppToaster from '../../lib/toaster';
import { useI18n, useSetState } from '../../lib/utils';
import useActionLogger from '../hooks/use_action_logger';
import { GetProjectsQueryVariables } from '../../common/types';
import { gql, loader } from 'graphql.macro';
import { addFolderToProjectsQueryCache } from './helpers';

const CreateFolderMutation = loader('../../graphql/insert_admin_projects_folder.gql');
const UpdateFolderMutation = loader('../../graphql/update_admin_projects_folder.gql');

const GetProjectNamesSubscription = gql`
  subscription GetProjectNames {
    folders: project_folder {
      name
    }
  }
`;

interface ICreateProjectsFolderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectsQueryVariables: GetProjectsQueryVariables;
  refetchProjects: () => void;
  folderId?: string;
  folderName?: string;
}

interface IProjectsFolderEditState {
  folderName: string;
}

const getInitialState = (folderName?: string): IProjectsFolderEditState => ({
  folderName: folderName ?? '',
});

const CreateOrEditProjectsFolderDialog: React.FC<ICreateProjectsFolderDialogProps> = ({
  isOpen,
  onClose,
  folderId,
  folderName,
  projectsQueryVariables,
  refetchProjects,
}) => {
  const i18n = useI18n();
  const insertActionLog = useActionLogger();
  const [state, setState] = useSetState<IProjectsFolderEditState>(getInitialState(folderName));
  const { folderName: newFolderName } = state;
  const [createFolder, { loading: creatingFolder }] = useMutation(CreateFolderMutation);
  const [updateFolder, { loading: updatingFolder }] = useMutation(UpdateFolderMutation);
  const { data: projectNamesData } = useSubscription(GetProjectNamesSubscription, {
    skip: !isOpen,
  });
  const isNewFolder = folderId == null;
  const projectNames: string[] = compose(map('name'), get('folders'))(projectNamesData);
  const trimmedNewFolderName = newFolderName.trim();

  const isFolderNameInvalid: boolean =
    isEmpty(trimmedNewFolderName) || projectNames.includes(trimmedNewFolderName);

  const handleNameInput = useCallback(
    (evt) => {
      setState({ folderName: evt.target.value });
    },
    [setState]
  );

  const showErrorToast = useCallback((error) => {
    AppToaster.show({
      intent: Intent.WARNING,
      message: (
        <Trans>
          Projects folder creation failed: {error.message || <Trans>unknown error</Trans>}
        </Trans>
      ),
    });
  }, []);

  const handleClose = useCallback(() => {
    setState(getInitialState(folderName));
    onClose();
  }, [folderName, onClose, setState]);

  const handleFolderUpdate = useCallback(() => {
    updateFolder({
      variables: {
        folderId,
        folderData: {
          name: trimmedNewFolderName,
        },
      },
    })
      .then(() =>
        insertActionLog('updated projects folder', {
          folderId,
          newFolderName: trimmedNewFolderName,
        })
      )
      .catch((err) => {
        AppToaster.show({
          intent: Intent.WARNING,
          message: <Trans>Failed to update folder: {err.toString()}</Trans>,
        });
      });

    handleClose();
  }, [updateFolder, folderId, trimmedNewFolderName, handleClose, insertActionLog]);

  const handleFolderCreate = useCallback(async () => {
    try {
      await createFolder({
        variables: {
          folder: {
            name: trimmedNewFolderName,
          },
        },
        update: (proxy, { data }) =>
          addFolderToProjectsQueryCache({
            proxy,
            data,
            newFolderName: trimmedNewFolderName,
            projectsQueryVariables,
            refetchProjects,
          }),
      });
      insertActionLog('created a folder', { newFolderName: trimmedNewFolderName });
      handleClose();
    } catch (error) {
      showErrorToast(error);
    }
  }, [
    trimmedNewFolderName,
    createFolder,
    handleClose,
    showErrorToast,
    insertActionLog,
    projectsQueryVariables,
    refetchProjects,
  ]);

  useEffect(() => {
    setState(getInitialState(folderName));
  }, [setState, folderName]);

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onClose}
      title={isNewFolder ? <Trans>Create new folder</Trans> : <Trans>Folder settings</Trans>}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={<Trans>Folder name</Trans>}
          labelFor="folderName"
          labelInfo={<Trans>(required)</Trans>}
          intent={isFolderNameInvalid ? Intent.DANGER : Intent.NONE}
          helperText={
            isEmpty(trimmedNewFolderName) ? (
              <Trans>Folder name cannot be empty</Trans>
            ) : isFolderNameInvalid ? (
              <Trans>Folder with this name already exists</Trans>
            ) : undefined
          }
        >
          <InputGroup
            className="w-full resize-none mb-4"
            id="folderName"
            name="folderName"
            onChange={handleNameInput}
            value={newFolderName}
            placeholder={i18n._(t`Title`)}
            intent={isFolderNameInvalid ? Intent.DANGER : Intent.NONE}
            autoFocus
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large text={<Trans>Close</Trans>} onClick={onClose} />
          <Button
            disabled={isFolderNameInvalid}
            large
            text={isNewFolder ? <Trans>Create</Trans> : <Trans>Save</Trans>}
            intent={Intent.SUCCESS}
            loading={creatingFolder || updatingFolder}
            onClick={isNewFolder ? handleFolderCreate : handleFolderUpdate}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CreateOrEditProjectsFolderDialog;
