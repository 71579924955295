import { useApolloClient } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useCallback } from 'react';
import { ReferenceLog, TReferenceLogType, User } from '../../common/types';

const InsertReferenceDetailsLogsMutation = loader(
  '../../graphql/insert_reference_details_logs.gql'
);

type TReferenceDetailsLogInput = Pick<ReferenceLog, 'reference_id' | 'data'>;

export default function useReferenceDetailsLogger() {
  const client = useApolloClient();

  return useCallback(function <T = any>(
    action: Promise<T>,
    logsFactory: (actionResult: T) => TReferenceDetailsLogInput[]
  ) {
    return action.then((response) =>
      client.mutate({
        mutation: InsertReferenceDetailsLogsMutation,
        variables: { logs: logsFactory(response) },
      })
    );
  },
  []);
}

export function createSimpleReferenceDetailsLogs(
  type: TReferenceLogType,
  user: null | Pick<User, 'id'>,
  referenceIds: string[],
  data?: { [key: string]: any }
): TReferenceDetailsLogInput[] {
  return referenceIds.map((refId) => ({
    reference_id: refId,
    data: { ...data, type, userId: user ? user.id : null },
  }));
}
