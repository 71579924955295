/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { Button, Colors, Icon, ProgressBar } from '@blueprintjs/core';
import React, { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';

const hiddenElementCss = css`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s ease, transform 1s ease, visibility 1s ease;

  &.visible {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
`;

const hiddenElementContainerCss = css`
  ${hiddenElementCss}

  background-color: rgba(246, 247, 249, 0.7);
  left: 0;
  right: 0;
  bottom: 0;
`;

const indicationArrowCss = css`
  ${hiddenElementCss}

  right: 40px;
  bottom: 40px;
  z-index: 5;
  transition: transform 1s ease;
  border: 1px solid ${Colors.GRAY3};
  border-radius: 3px;

  &.rotated {
    transform: rotate(180deg);
  }
`;

interface ILoadMoreReferencesButtonProps {
  showLoadMoreButton: boolean;
  referenceCount: number;
  totalReferencesCount: number;
  onLoadMore: () => void;
  loadingMore: boolean;
}

const LoadMoreReferencesButton: React.FC<ILoadMoreReferencesButtonProps> = ({
  showLoadMoreButton,
  referenceCount,
  totalReferencesCount,
  onLoadMore,
  loadingMore,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const toogleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <React.Fragment>
      <div
        className={`flex flex-col items-center text-center py-4 ${
          showLoadMoreButton && expanded ? 'visible' : ''
        }`}
        css={hiddenElementContainerCss}
      >
        <p className="mb-0">
          <Trans>
            Displayed: {referenceCount} of {totalReferencesCount}
          </Trans>
        </p>
        <ProgressBar
          stripes={false}
          className="my-2 w-1/6"
          value={referenceCount / totalReferencesCount}
        />
        <Button
          small
          text={<Trans>Load more references</Trans>}
          onClick={onLoadMore}
          loading={loadingMore}
        />
      </div>
      <Button
        small
        minimal
        onClick={toogleExpand}
        className={`${showLoadMoreButton ? 'visible' : ''} ${expanded ? 'rotated' : ''}`}
        css={indicationArrowCss}
      >
        <Icon icon={IconNames.CHEVRON_UP} iconSize={25} />
      </Button>
    </React.Fragment>
  );
};

export default LoadMoreReferencesButton;
