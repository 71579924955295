/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Button,
  Dialog,
  Classes,
  Divider,
  RadioGroup,
  Radio,
  Callout,
  Intent,
  Alert,
} from '@blueprintjs/core';
import { ScreeningType } from '../../../common/types';
import { Trans, t } from '@lingui/macro';
import { useI18n } from '../../../lib/utils';
import { useState, useCallback } from 'react';
import { getScreeningTypeInfo } from '../helpers';

const dialogCss = css`
  width: 50vw;
  background: #fff;
`;

const ScreeningOptionLabel = ({ value }: { value: string }) => {
  const { label, helpText } = getScreeningTypeInfo(value);

  return (
    <div>
      <div className="text-base text-gray-700">{label}</div>
      <div className="text-xs text-gray-700">{helpText}</div>
    </div>
  );
};

interface IChangeScreeningTypeDialog {
  isOpen: boolean;
  onClose: () => void;
  onApply: (screeningTypeId: string) => void;
  currentValue: string;
  screeningTypes: ScreeningType[];
}

const ChangeScreeningTypeDialog: React.FC<IChangeScreeningTypeDialog> = ({
  isOpen,
  onClose,
  onApply,
  currentValue,
  screeningTypes,
}) => {
  const i18n = useI18n();
  const [updatedScreeningType, setUpdatedScreeningType] = useState<string | null>(currentValue);
  const [changeScreeningTypeAlertOpen, setChangeScreeningTypeAlertOpen] = useState<boolean>(false);

  const closeDialog = useCallback(() => {
    updatedScreeningType && setUpdatedScreeningType(null);
    onClose();
  }, [updatedScreeningType, setUpdatedScreeningType, onClose]);

  const changeScreeningType = useCallback(async () => {
    await setChangeScreeningTypeAlertOpen(false);
    updatedScreeningType && onApply(updatedScreeningType);
    closeDialog();
  }, [updatedScreeningType, onApply, closeDialog]);

  return (
    <Dialog css={dialogCss} isOpen={isOpen} onClose={onClose} title={<Trans>Screening type</Trans>}>
      <div className={Classes.DIALOG_BODY}>
        <div className="flex flex-col">
          <RadioGroup
            className="justify-between"
            selectedValue={updatedScreeningType ?? currentValue}
            onChange={(evt) => {
              setUpdatedScreeningType(evt.currentTarget.value);
            }}
          >
            {screeningTypes.map(({ id }) => (
              <Radio
                key={id}
                className="flex flex-row items-center m-0 mb-4"
                value={id}
                labelElement={<ScreeningOptionLabel value={id} />}
              />
            ))}
          </RadioGroup>
          <Divider className="mx-0 my-6" />
          <Callout intent={Intent.PRIMARY}>
            <Trans>
              Changing the type of screening will re-arrange the assignments of documents to review
              among reviewers
            </Trans>
          </Callout>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large text={<Trans>Cancel</Trans>} onClick={closeDialog} />
          <Button
            large
            text={<Trans>Change</Trans>}
            disabled={!updatedScreeningType || currentValue === updatedScreeningType}
            onClick={() => setChangeScreeningTypeAlertOpen(true)}
          />
        </div>
      </div>
      <Alert
        cancelButtonText={i18n._(t`Cancel`)}
        confirmButtonText={i18n._(t`Change screening type`)}
        intent={Intent.PRIMARY}
        isOpen={changeScreeningTypeAlertOpen}
        onCancel={() => setChangeScreeningTypeAlertOpen(false)}
        onConfirm={changeScreeningType}
      >
        <p className="font-bold mb-3">
          <Trans>
            Are you sure to change screening type from {currentValue} to {updatedScreeningType}?
          </Trans>
        </p>
      </Alert>
    </Dialog>
  );
};

export default ChangeScreeningTypeDialog;
