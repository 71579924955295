/** @jsx jsx */
import { Button, Divider, Icon, IconName, Classes } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import React, { ReactNode } from 'react';

interface IScreenHeaderProps {
  title: ReactNode;
  rightElement?: ReactNode;
  backIcon?: IconName;
  onBack?: () => void;
  large?: boolean;
  className?: string;
}

const ScreenHeader: React.FC<IScreenHeaderProps> = ({
  className,
  backIcon,
  onBack,
  title,
  rightElement,
  large = true,
}) => {
  return (
    <div
      className={`
        ${Classes.ELEVATION_0} flex-no-wrap flex flex-row justify-between items-center
        ${className ?? ''}
      `}
    >
      <div className="h-full flex flex-row items-center flex-auto">
        {onBack && (
          <React.Fragment>
            <Button
              className="h-full"
              icon={<Icon className="px-2" icon={backIcon} iconSize={large ? 24 : 20} />}
              onClick={onBack}
              large={large}
              minimal
            />
            <Divider className="h-full m-0" />
          </React.Fragment>
        )}
        {title}
      </div>
      {rightElement}
    </div>
  );
};

export default ScreenHeader;
