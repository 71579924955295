import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@blueprintjs/core';
import { DocumentNode } from 'graphql';
import { get } from 'lodash/fp';
import React, { Component, FC } from 'react';
import ErrorScreen from './error_screen';

type TWithQueryConfig<P> = {
  query: DocumentNode;
  variables?: { [key: string]: any };
  dataPropName?: string; // name of the target prop to provide data under
  queryDataPath?: string; // path in query result's `data` object to get data from
  targetProps: Partial<P>;
};

const TargetRenderer = ({
  Target,
  targetProps,
  query,
  queryVariables,
  dataPropName,
  queryDataPath,
}) => {
  const { data, loading, error } = useQuery(query, { variables: queryVariables });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <Target
      {...targetProps}
      {...{ [dataPropName]: queryDataPath ? get(queryDataPath, data) : data }}
    />
  );
};

export function withQuery<P = any>(config: TWithQueryConfig<P>) {
  const { query, variables, queryDataPath, targetProps = {}, dataPropName = 'data' } = config;

  return (Target: typeof Component | FC<P>) => {
    return (props: P) => {
      const resultingProps: P = { ...props, ...targetProps };
      return (
        <TargetRenderer
          Target={Target}
          targetProps={resultingProps}
          query={query}
          queryVariables={variables}
          dataPropName={dataPropName}
          queryDataPath={queryDataPath}
        />
      );
    };
  };
}
