/** @jsx jsx */
import { jsx } from '@emotion/core';
import { find, keyBy, propEq } from 'lodash/fp';
import { TExclusionReason } from '.';
import { fancyScrollCss, lightGray4bg, lightGray5bg } from '../../common/styles';
import React from 'react';
import { useMemo } from 'react';
import {
  InclusionStatus,
  ScreeningTag,
  ScreeningTask,
  ScreeningTaskType,
} from '../../common/types';
import { useCurrCallback } from '../../lib/utils';
import { getTaskResultControlId } from '../../lib/task_helpers';
import { ReferenceCommentFragmentType } from '../../graphql/reference_comment_fragment';
import ConflictDecision from './conflict_decision';

interface IDecisionColumnProps {
  tasks: ScreeningTask[];
  formId: string;
  exclusionReasons: TExclusionReason[];
  referenceComments: ReferenceCommentFragmentType[];
  handleInclude: () => void;
  handleExclude: (reasonCode: string) => void;
  conflictsResolved: boolean;
  screeningTags: ScreeningTag[];
}

const DecisionsColumn: React.FC<IDecisionColumnProps> = ({
  tasks,
  formId,
  exclusionReasons,
  handleInclude,
  handleExclude,
  conflictsResolved,
  screeningTags,
  referenceComments,
}) => {
  const referenceCommentsById = useMemo(() => {
    return keyBy('id', referenceComments);
  }, [referenceComments]);
  const decisions = useMemo(() => {
    const conflictTask = find({ task_type: ScreeningTaskType.ConflictResolution }, tasks);

    return (conflictTask?.screening_results ?? [])
      .filter(propEq('form_id', formId))
      .map((screeningResult) => {
        return {
          result: screeningResult.result,
          comment_id: screeningResult.comment_id,
          memberName: screeningResult.team_member?.user?.name ?? '-',
          memberDeleted: screeningResult.team_member?.deleted_at != null,
        };
      })
      .sort((a, b) => a.memberName?.localeCompare(b.memberName) ?? 0);
  }, [formId, tasks]);

  const handleApply = useCurrCallback(
    (reason: string | undefined, _evt) => {
      reason == null ? handleInclude() : handleExclude(reason);
    },
    [handleInclude, handleExclude]
  );

  return (
    <div className="h-full flex flex-col overflow-auto" css={[lightGray4bg, fancyScrollCss]}>
      {decisions.map((decision, idx) => {
        if (decision.result == null) return null;
        const { tags, inclusionStatus } = decision.result;
        const isIncludeDecision = inclusionStatus === InclusionStatus.Included;
        const exclusionReasonId = getTaskResultControlId(decision.result);
        const exclusionReasonName = find(propEq('id', exclusionReasonId), exclusionReasons)?.label;
        const comment = decision.comment_id
          ? referenceCommentsById[decision.comment_id].comment
          : '';

        return (
          <ConflictDecision
            key={idx}
            className="mb-8"
            inclusionStatus={inclusionStatus}
            screenerName={decision.memberName}
            screenerDeleted={decision.memberDeleted}
            screeningTags={screeningTags}
            selectedTags={tags}
            decisionReason={exclusionReasonName}
            comment={comment}
            onApply={
              conflictsResolved
                ? undefined
                : handleApply(isIncludeDecision ? undefined : exclusionReasonId)
            }
          />
        );
      })}
    </div>
  );
};

export default DecisionsColumn;
