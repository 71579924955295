/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Colors } from '@blueprintjs/core';
import React, { ReactNode } from 'react';
import { createTable, TableCol } from '../common/gba_table';
import { TABLE_COLUMN_HEADERS } from '@blueprintjs/table/lib/esm/common/classes';

const tableCss = css`
  .${TABLE_COLUMN_HEADERS} {
    border: 1px solid ${Colors.LIGHT_GRAY1};
  }
`;

interface IFolderProjectsList<T> {
  projects: T[];
  selectedProjects: number[];
  updateSelectedProjects: (updater: (selected: number[]) => number[]) => any;
  handleTableClick: (rowIdx: number, colIdx: number) => void;
  isCollapsed: boolean;
  listColumns: TableCol<T>[];
  renderTableCell: (colId: string, project: T) => ReactNode;
}

function FolderProjectsList<T>({
  projects,
  selectedProjects,
  updateSelectedProjects,
  isCollapsed,
  listColumns,
  renderTableCell,
  handleTableClick,
}: IFolderProjectsList<T>) {
  const ProjectsTable = createTable<T>();

  return (
    <ProjectsTable
      tableCustomCss={tableCss}
      className="mb-4"
      numRows={projects.length}
      rows={projects}
      cols={listColumns}
      cellContentRenderer={renderTableCell}
      selection={{
        rows: selectedProjects,
        onChange: updateSelectedProjects,
      }}
      defaultRowHeight={60}
      autoSizeCols
      onClick={handleTableClick}
      isCollapsed={isCollapsed}
    />
  );
}

export default FolderProjectsList;
