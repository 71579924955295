/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const diagramVerticalBlock = (rowStart: number, rowEnd: number, column: number) => css`
  grid-column-start: ${column};
  grid-column-end: ${column + 1};
  grid-row-start: ${rowStart};
  grid-row-end: ${rowEnd};
  height: 100%;
  width: 80%;
`;

const textCss = css`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
`;

interface IDiagramVerticalNodeProps {
  id: string;
  col: number;
  rowStart: number;
  rowEnd: number;
}

const DiagramVerticalNode: React.FC<IDiagramVerticalNodeProps> = ({
  id,
  children,
  col,
  rowStart,
  rowEnd,
}) => {
  return (
    <div
      className="px-4 py-4 border border-black rounded bg-white flex justify-center"
      css={diagramVerticalBlock(rowStart, rowEnd, col)}
      id={id}
      style={{ display: 'none' }}
    >
      <div className="flex justify-center align-middle font-bold" css={textCss}>
        {children}
      </div>
    </div>
  );
};

export default DiagramVerticalNode;
