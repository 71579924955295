/** @jsx jsx */
import { InputGroup, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx, css } from '@emotion/core';
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { IZoomToggleProps, ZoomControl as PDFZoomControl } from '../../pdf_viewer/controls';

const inputCss = css`
  input {
    text-align: right;
  }
`;

const DocZoomToggles: IZoomToggleProps['renderer'] = ({ currentZoom, onChangeZoom, className }) => {
  const [zoomInputVal, setZoomInputVal] = useState<number | string>(currentZoom);

  const handleZoomInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setZoomInputVal(evt.target.value);
    },
    [setZoomInputVal]
  );

  const handleInputKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.charCode !== 13) return;
    const maybeZoom = parseInt(zoomInputVal.toString());

    if (Number.isNaN(maybeZoom)) {
      return setZoomInputVal(currentZoom * 100);
    }

    onChangeZoom(maybeZoom / 100);
  };

  useEffect(() => {
    setZoomInputVal((currentZoom * 100).toString());
  }, [currentZoom]);

  return (
    <div className={`${className ?? ''} flex flex-row items-center`}>
      <PDFZoomControl
        minimal
        icon={IconNames.MINUS}
        zoomKind="out"
        currentZoom={currentZoom}
        onChangeZoom={onChangeZoom}
      />
      <InputGroup
        className="w-20"
        value={zoomInputVal.toString()}
        onChange={handleZoomInputChange}
        onKeyPress={handleInputKeyPress}
        rightElement={<Tag minimal>%</Tag>}
        css={inputCss}
      />
      <PDFZoomControl
        minimal
        icon={IconNames.PLUS}
        zoomKind="in"
        currentZoom={currentZoom}
        onChangeZoom={onChangeZoom}
      />
    </div>
  );
};

export default DocZoomToggles;
