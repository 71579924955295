/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { screeningListColHeaderHeight } from '../../common/styles';
import { ProjectsLibraryFilter } from '../../lib/utils';
import FiltersHeader from '../screening/filters_header';
import ProjectsFilters from './projects_filters';

interface IProjectsFiltersColumnProps {
  onToggle: () => void;
  filtersApplied: boolean;
  projectsLibraryFilter: ProjectsLibraryFilter;
  onChangeProjectsLibraryFilter: (owner: ProjectsLibraryFilter) => void;
  onResetProjectsSearch?: () => void;
}

const ProjectsFiltersColumn: React.FC<IProjectsFiltersColumnProps> = ({
  onResetProjectsSearch,
  filtersApplied,
  onToggle,
  projectsLibraryFilter,
  onChangeProjectsLibraryFilter,
}) => {
  const handleClearFilters = useCallback(() => {
    onResetProjectsSearch!();
  }, [onResetProjectsSearch]);

  return (
    <div className="h-full flex flex-col overflow-auto">
      <div className="flex-none" css={screeningListColHeaderHeight}>
        <FiltersHeader
          onClearFilters={filtersApplied ? handleClearFilters : undefined}
          onToggle={onToggle}
        />
      </div>
      <ProjectsFilters
        projectsLibraryFilter={projectsLibraryFilter}
        onChangeProjectsLibraryFilter={onChangeProjectsLibraryFilter}
      />
    </div>
  );
};

export default ProjectsFiltersColumn;
