/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { Button, MenuItem, Alignment } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import { PrismaExportFormat } from '../../common/types';

const exportButtonStyle = css`
  width: 90px;
`;

const ExportFormatSelect = Select.ofType<PrismaExportFormat>();

interface IExportControlsProps {
  exportFormats: PrismaExportFormat[];
  handleExportFormatChange: (exportFormat: PrismaExportFormat) => void;
  currentFormat: PrismaExportFormat;
  handleExportDiagram: () => void;
}

const ExportControls: React.FC<IExportControlsProps> = ({
  handleExportFormatChange,
  exportFormats,
  currentFormat,
  handleExportDiagram,
}) => {
  return (
    <div className="flex flex-row items-center flex-no-wrap px-3">
      <Trans>Export format</Trans>
      <ExportFormatSelect
        className="mx-2"
        filterable={false}
        items={exportFormats}
        itemRenderer={(format, { handleClick, modifiers }) => (
          <MenuItem key={format} onClick={handleClick} text={format} />
        )}
        onItemSelect={handleExportFormatChange}
      >
        <Button
          alignText={Alignment.LEFT}
          css={exportButtonStyle}
          text={currentFormat}
          rightIcon={IconNames.CARET_DOWN}
        />
      </ExportFormatSelect>
      <Button className="px-8" text={<Trans>Export</Trans>} onClick={handleExportDiagram} />
    </div>
  );
};

export default ExportControls;
