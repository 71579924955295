/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { InputGroup } from '@blueprintjs/core';
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { IPageTogglesProps } from '../../pdf_viewer/controls';

const inputCss = css`
  input {
    text-align: right;
  }
`;

const DocPageToggles: IPageTogglesProps['renderer'] = ({ activePage, onChangePage, numPages }) => {
  const [activePageInputVal, setActivePageInputVal] = useState<number | string>(activePage);

  const handleInputChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setActivePageInputVal(evt.target.value);
    },
    [setActivePageInputVal]
  );

  const handleInputKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.charCode !== 13) return;
    const maybePageNum = parseInt(activePageInputVal.toString());

    if (Number.isNaN(maybePageNum)) {
      return setActivePageInputVal(activePage);
    }

    onChangePage(maybePageNum);
  };

  useEffect(() => {
    setActivePageInputVal(activePage.toString());
  }, [activePage]);

  return (
    <div className="inline-flex flex-row items-center">
      <InputGroup
        value={activePageInputVal.toString()}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        className="w-10"
        css={inputCss}
      />
      <span className="mx-1">/ {numPages}</span>
    </div>
  );
};

export default DocPageToggles;
