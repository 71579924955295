/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useQuery } from '@apollo/react-hooks';
import { H1, Spinner } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';
import { centerItemCss, fancyScrollCss } from '../../../common/styles';
import { AppContentProps, ProjectMatchParams, Stage } from '../../../common/types';
import ErrorScreen from '../../common/error_screen';
import PageContentWrapper from '../../common/page_content_wrapper';
import DashboardStageCard from './dashboard_stage_card';

const projectStagesQuery = gql`
  query projectStages($projectId: uuid!) {
    stages: stage(where: { project_id: { _eq: $projectId } }, order_by: { order_number: asc }) {
      id
      name
      completed
      study_pools {
        id
        stage_id
        study_pool_team_members {
          study_pool_id
          team_member_id
          team_member {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
      reference_count: stage_references_aggregate(
        where: { reference: { deleted_at: { _is_null: true } } }
      ) {
        aggregate {
          count
        }
      }
      reference_with_pdf_count: stage_reference_attachments_aggregate {
        aggregate {
          count
        }
      }
      studies_by_status {
        inclusion_status
        count
      }
      tasks_aggregate(where: { task_type: { _eq: screening }, deleted_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }
      type
    }
  }
`;

interface IStagesDashboardProps extends AppContentProps<ProjectMatchParams> {}

const StagesDashboard: React.FC<IStagesDashboardProps> = ({ match }) => {
  const { projectId } = match.params;
  const { data, loading, error, refetch } = useQuery(projectStagesQuery, {
    variables: { projectId },
    fetchPolicy: 'network-only',
  });

  if (error) {
    return <ErrorScreen error={error} retry={refetch} />;
  }

  const stages: Pick<
    Stage,
    | 'id'
    | 'name'
    | 'completed'
    | 'study_pools'
    | 'reference_count'
    | 'reference_with_pdf_count'
    | 'studies_by_status'
    | 'tasks_aggregate'
    | 'type'
  >[] = get('stages', data) ?? [];

  return loading ? (
    <Spinner css={centerItemCss} />
  ) : (
    <PageContentWrapper>
      <H1 className="my-5 px-5 font-normal">
        <Trans>Review</Trans>
      </H1>
      <div className="flex-1 overflow-auto px-5" css={fancyScrollCss}>
        {stages.map((stage) => (
          <DashboardStageCard key={stage.id} projectId={projectId} stage={stage} />
        ))}
      </div>
    </PageContentWrapper>
  );
};

export default StagesDashboard;
