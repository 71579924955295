/** @jsx jsx */
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Colors,
  Divider,
  Icon,
  Intent,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx, css } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React, { FC, Fragment, useMemo } from 'react';
import ProgressBar from '../../common/progress_bar';
import ScreenHeader from '../../common/screen_header';
import { TTaskCounts } from '../../../lib/task_helpers';
import { DecisionFilter } from '../../../apollo/screening_state';
import { useTheme } from '../../settings/theme_context';
import AnimatedCountTag from '../../common/animated_count_tag';
import CountTag from '../../common/count_tag';
import { ScreeningHistoryLength } from '.';
import useSetDecisionFilter from '../../hooks/use_set_decision_filter';
import { ScreeningListMode } from '../../../common/types';

const progressTagsContainerCss = css`
  flex-grow: 0.5;
  max-width: 700px;
`;

const sideContainerCss = css`
  width: 120px;
`;

const ICON_SIZE = 14;

interface IHeaderProps {
  highlightsEnabled: boolean;
  onHighlightsToggle: () => void;
  taskCounts: TTaskCounts;
  decisionFilter: DecisionFilter;
  screeningHistoryLength: ScreeningHistoryLength;
  onOpenCriteria: () => void;
  screeningListMode: ScreeningListMode;
  loading?: boolean;
  onUndoScreeningDecision?: () => void;
  onRedoScreeningDecision?: () => void;
}

const Header: FC<IHeaderProps> = ({
  highlightsEnabled,
  onHighlightsToggle,
  taskCounts,
  decisionFilter,
  loading,
  onUndoScreeningDecision,
  onRedoScreeningDecision,
  screeningHistoryLength,
  onOpenCriteria,
  screeningListMode,
}) => {
  const counts = taskCounts ?? { included: 0, excluded: 0, total: 0, toReview: 0 };
  const { included, excluded, total } = counts;
  const { statusColors } = useTheme();
  const setDecisionFilter = useSetDecisionFilter();

  const decisionFilterIn = decisionFilter === 'in';
  const decisionFilterOut = decisionFilter === 'out';

  const { undoLength: undoHistoryLength, redoLength: redoHistoryLength } = screeningHistoryLength;

  const includedProgressTag = useMemo(
    () => (
      <AnimatedCountTag
        count={included}
        title={<Trans>In</Trans>}
        inclusionStatus="included"
        onClick={() => setDecisionFilter('in')}
        active={decisionFilterIn}
        loading={loading}
        animationBlinkColor={statusColors.acceptedPrimary}
      />
    ),
    [included, decisionFilterIn, setDecisionFilter, statusColors.acceptedPrimary, loading]
  );

  const excludedProgressTag = useMemo(
    () => (
      <AnimatedCountTag
        count={excluded}
        title={<Trans>Out</Trans>}
        inclusionStatus="excluded"
        onClick={() => setDecisionFilter('out')}
        active={decisionFilterOut}
        loading={loading}
        animationBlinkColor={statusColors.rejectedPrimary}
      />
    ),
    [excluded, decisionFilterOut, setDecisionFilter, statusColors.rejectedPrimary, loading]
  );

  return (
    <Fragment>
      <ScreenHeader
        large={false}
        title={
          <div className="mx-3 flex-shrink flex-auto flex justify-between items-center w-20">
            <div className="mr-3">
              <Tooltip content={<Trans>Undo last decision</Trans>}>
                <AnchorButton
                  className="h-full text-xs underline"
                  rightIcon={<Icon className="p-2" icon={IconNames.UNDO} iconSize={ICON_SIZE} />}
                  minimal
                  disabled={undoHistoryLength === 0 || onUndoScreeningDecision == null}
                  onClick={onUndoScreeningDecision}
                >
                  <Trans>Undo</Trans>
                </AnchorButton>
              </Tooltip>
              <Tooltip content={<Trans>Redo last decision</Trans>}>
                <AnchorButton
                  className="h-full text-xs underline"
                  rightIcon={<Icon className="p-2" icon={IconNames.REDO} iconSize={ICON_SIZE} />}
                  minimal
                  disabled={redoHistoryLength === 0 || onRedoScreeningDecision == null}
                  onClick={onRedoScreeningDecision}
                >
                  <Trans>Redo</Trans>
                </AnchorButton>
              </Tooltip>
            </div>
            <div className="flex justify-center items-center" css={progressTagsContainerCss}>
              {screeningListMode === ScreeningListMode.Completed && (
                <React.Fragment>
                  <CountTag
                    active={decisionFilter === 'all'}
                    className="mr-4"
                    count={taskCounts.total}
                    onClick={loading ? undefined : () => setDecisionFilter('all')}
                    title={<Trans>All references</Trans>}
                    loading={loading}
                  />
                  <span className="mr-4">:</span>
                </React.Fragment>
              )}
              {includedProgressTag}
              {screeningListMode === ScreeningListMode.ToScreen && (
                <React.Fragment>
                  <CountTag
                    count={taskCounts.toReview}
                    title={<Trans>To review</Trans>}
                    onClick={() => setDecisionFilter('to_review')}
                    active={decisionFilter === 'to_review'}
                    loading={loading}
                    className="mr-2"
                  />
                  <CountTag
                    count={taskCounts.postponed}
                    title={<Trans>Postponed</Trans>}
                    onClick={() => setDecisionFilter('postponed')}
                    active={decisionFilter === 'postponed'}
                    loading={loading}
                    className="mr-2"
                  />
                </React.Fragment>
              )}
              {excludedProgressTag}
            </div>
            <div css={sideContainerCss} />
          </div>
        }
        rightElement={
          <ButtonGroup className="h-full items-center text-xs">
            <Tooltip
              content={
                highlightsEnabled ? (
                  <Trans>Click to hide highlighted keywords</Trans>
                ) : (
                  <Trans>Click to highlight keywords</Trans>
                )
              }
            >
              <Button
                icon={highlightsEnabled ? IconNames.EYE_OPEN : IconNames.EYE_OFF}
                intent={highlightsEnabled ? Intent.PRIMARY : Intent.NONE}
                minimal
                onClick={onHighlightsToggle}
                text={<Trans>Keywords</Trans>}
              />
            </Tooltip>

            <Divider className="mx-3 h-full" />
            <Tooltip content={<Trans>Inclusion and Exclusion Instructions</Trans>}>
              <Button
                className="mr-3"
                color={Colors.GRAY3}
                icon={IconNames.MANUAL}
                minimal
                onClick={onOpenCriteria}
              />
            </Tooltip>
          </ButtonGroup>
        }
      />
      <ProgressBar studiesIn={included} studiesOut={excluded} total={total} height={2} />
    </Fragment>
  );
};

export default Header;
