/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PropsWithChildren } from 'react';
import { Card } from '@blueprintjs/core';
import { useStatusColor } from '../../lib/utils';

interface ICardWithMarkerProps {
  className?: string;
  status?: string;
  onClick?: () => void;
}

const CardWithMarker: React.FC<PropsWithChildren<ICardWithMarkerProps>> = ({
  children,
  status,
  className,
  onClick,
}) => {
  const getStatusColor = useStatusColor();

  return (
    <Card
      className={`p-0 flex flex-row flex-no-wrap ${className ?? ''}`}
      interactive={onClick != null}
      onClick={onClick}
    >
      <div css={{ backgroundColor: getStatusColor(status) }} className="flex-none w-3" />
      <div className="flex-1">{children}</div>
    </Card>
  );
};

export default CardWithMarker;
