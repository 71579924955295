import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import React from 'react';
import ConfirmationDialog from '../../common/confirmation_dialog';

interface IRestoreRemovedDuplicatesConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RestoreRemovedDuplicatesConfirmation: React.FC<IRestoreRemovedDuplicatesConfirmationProps> =
  ({ isOpen, onClose, onConfirm }) => {
    return (
      <ConfirmationDialog
        title={<Trans>Restore reference</Trans>}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        confirmBtnText={<Trans>Restore</Trans>}
        confirmBtnIntent={Intent.SUCCESS}
        icon={IconNames.UNDO}
      >
        <div className="mb-2">
          <Trans>
            Duplicated reference will be returned to the references list and will no longer appear in
            duplicated references list.
          </Trans>
        </div>
      </ConfirmationDialog>
    );
  };

export default RestoreRemovedDuplicatesConfirmation;
