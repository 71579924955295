import { Reference } from '../../common/types';
import {
  first,
  get,
  isEmpty,
  isObject,
  last,
  pick,
  reject,
  sortedLastIndex,
  trim,
} from 'lodash/fp';
import { uid } from '../../lib/utils';
import { TReferenceFilters } from '../../apollo/screening_state';
import { DataProxy } from 'apollo-cache';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { ReferenceCommentFragmentType } from '../../graphql/reference_comment_fragment';
import { DeleteReferenceCommentMutationDataType } from '../../graphql/delete_reference_comment';
import { MutationUpdaterFn } from 'apollo-client';
import i18n from '../../i18n';
import { t } from '@lingui/macro';

const ReferenceCommentFragment = loader('../../graphql/reference_comment_fragment.gql');

export function getAuthors(reference: Pick<Reference, 'attrs'>) {
  return get('attrs.authors', reference) || [];
}

export function getYear(reference: Pick<Reference, 'attrs'>) {
  return get('attrs.year', reference);
}

export function getAuthorNames(reference: Pick<Reference, 'attrs'>) {
  const authors = getAuthors(reference);
  if (isEmpty(authors)) return '';
  return authors
    .map((author: any) => [author.lastName, ...(author.firstNames ?? [])].join(' '))
    .join(', ');
}

export function getShortCitation(reference: Pick<Reference, 'attrs'>): string {
  const { attrs } = reference;
  const firstAuthor = get('authors[0].lastName', attrs) ?? '';
  const year = attrs.year ?? '';

  return `${firstAuthor} ${year}`;
}

// NOTE: this is copy/paste from workers/src/lib/import_helpers.ts
function getIncrementedPostfix(current: string): string {
  if (isEmpty(current)) return 'a';
  const lastChar = last(current);

  // reaching the max "digit" (last char is 'z') should increment the next significant "digit"
  // (preceding char)
  if (lastChar === 'z') {
    if (first(current) === 'z') {
      // zz -> aaa
      return 'a' + current.replace(/\w/g, 'a');
    } else {
      // abzz -> acaa
      const targetIdx = current.indexOf('z');
      return (
        getIncrementedPostfix(current.slice(0, targetIdx)) +
        current.slice(targetIdx).replace(/\w/g, 'a')
      );
    }
  } else {
    // just increment the least significant "digit" (char)
    return current.slice(0, -1) + String.fromCharCode(lastChar!.charCodeAt(0) + 1);
  }
}

// NOTE: this is almost 1 to 1 copy/paste from workers/src/lib/import_helpers.ts
export function getStudyIdFromReference(
  reference: Pick<Reference, 'attrs'>,
  existingStudyIds: string[] = [] // assumes existingStudyIds are sorted in ASC order
): [string, string[]] {
  const maybeFirstAuthor = reference.attrs.authors[0]?.lastName;
  const prefix = maybeFirstAuthor ?? uid();
  const baseStudyId = (prefix + reference.attrs.year).replace(' ', '').toLowerCase();

  if (isEmpty(existingStudyIds)) {
    return [baseStudyId, [baseStudyId]];
  }

  // Intentionally add `zzzzz` to the end of baseStudyId to get the very last possible insert index
  // to maintain the sorted order. It will always point right after the last existing similar (same
  // author and year) studyId
  const insertIdx = sortedLastIndex(`${baseStudyId}zzzzz`, existingStudyIds);
  const existingId = existingStudyIds[Math.max(0, insertIdx - 1)];

  const currentPostfix = existingId.split(baseStudyId, 2)[1];
  let studyId = baseStudyId;

  // if currentPostfix == null, it means existing studyId doesn't collide with baseStudyId
  if (currentPostfix != null) {
    if (currentPostfix === 'zzzzz') {
      throw Error(`More than 11 881 376 studies from ${baseStudyId}. Assuming it is incorrect`);
    }
    const newPostfix = getIncrementedPostfix(currentPostfix);
    studyId = baseStudyId + newPostfix;
  }

  // update existing studyIds with new one at proper position (to maintain the order)
  const updatedExistingStudyIds = [...existingStudyIds];
  updatedExistingStudyIds.splice(insertIdx, 0, studyId);

  return [studyId, updatedExistingStudyIds];
}

const REFERENCE_FILTERING_KEYS = [
  'decisionFilter',
  'searchPhraseTokens',
  'activeKeywordFilters',
  'activeDocumentTypeFilters',
  'activeYearFilters',
  'activeDecisionCodeFilters',
  'activeScreeningTagFilters',
  'filtersTarget',
  'orderedBy',
  'onlyWithComments',
  'pdfFilter',
  'onlyWithoutAbstract',
  'activeBatchKey',
];

export function locationSearchToReferenceFilters(locationSearch: string): TReferenceFilters | null {
  try {
    const searchParams = new URLSearchParams(locationSearch);
    const appliedFiltersString = searchParams.get('appliedFilters');
    if (appliedFiltersString) {
      const parsed = JSON.parse(decodeURIComponent(appliedFiltersString));

      if (isObject(parsed)) return pick(REFERENCE_FILTERING_KEYS, parsed);
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function referenceFiltersToLocationSearchString(filters: TReferenceFilters): string {
  return encodeURIComponent(JSON.stringify(filters));
}

export function getReferenceCacheKey(referenceId: string) {
  return `reference:${referenceId}`;
}

export function updateCachedReferenceCommentText(
  cache: DataProxy,
  commentId: string,
  text: string
) {
  const referenceCommentFragment = gql`
    fragment ReferenceCommentLocalFragment on reference_comment {
      id
      comment
    }
  `;

  cache.writeFragment({
    id: `reference_comment:${commentId}`,
    fragment: referenceCommentFragment,
    data: {
      id: commentId,
      comment: text,
      __typename: 'reference_comment',
    },
  });
}

const ReferenceFragment = gql`
  fragment ReferenceLocalFragment on reference {
    reference_comments(order_by: { updated_at: desc }) {
      ...ReferenceCommentFragment
    }
  }
  ${ReferenceCommentFragment}
`;

export function updateReferenceCommentsCache(
  cache: DataProxy,
  referenceId: string,
  updater: (referenceComments: ReferenceCommentFragmentType[]) => ReferenceCommentFragmentType[]
) {
  const referenceCacheId = getReferenceCacheKey(referenceId);
  const referenceData = cache.readFragment<{
    reference_comments: ReferenceCommentFragmentType[];
  }>({
    id: referenceCacheId,
    fragment: ReferenceFragment,
    fragmentName: 'ReferenceLocalFragment',
  });

  if (referenceData) {
    const updatedComments = updater(referenceData.reference_comments);

    cache.writeFragment({
      id: referenceCacheId,
      fragment: ReferenceFragment,
      fragmentName: 'ReferenceLocalFragment',
      data: {
        ...referenceData,
        reference_comments: updatedComments,
      },
    });
  }
}

export const removeCachedReferenceComment: MutationUpdaterFn<DeleteReferenceCommentMutationDataType> =
  (cache, { data }) => {
    if (data == null) return;

    updateReferenceCommentsCache(
      cache,
      data.delete_reference_comment_by_pk.reference_id,
      (cachedComments) => reject({ id: data.delete_reference_comment_by_pk.id }, cachedComments)
    );
  };

export const getReferenceStudyIdAndTitle = (
  reference: Pick<Reference, 'title' | 'attrs'>
): string => {
  const firstAuthorLastName: string = get('attrs.authors[0].lastName', reference) ?? '';
  const referenceYear: string = get('attrs.year', reference) ?? '';
  const referenceTitle: string = get('title', reference) ?? '';
  const studyId = trim(`${firstAuthorLastName} ${referenceYear}`);

  return isEmpty(studyId) && isEmpty(referenceTitle)
    ? '-'
    : isEmpty(studyId)
    ? referenceTitle
    : isEmpty(referenceTitle)
    ? `${studyId} - (${i18n._(t`no title`)})`
    : `${firstAuthorLastName} ${referenceYear} - ${referenceTitle}`;
};
