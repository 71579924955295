/** @jsx jsx **/
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { jsx } from '@emotion/core';
import { t, Trans } from '@lingui/macro';
import { defaults, includes, isEmpty, find } from 'lodash/fp';
import React, { FormEvent, ReactNode, useCallback, useState } from 'react';
import { DomainItem, UserKeyword } from '../../common/types';
import { useI18n } from '../../lib/utils';
import CustomTagsInput from '../common/custom_tag_input';
import { useTheme } from '../settings/theme_context';

export enum KeywordsFormMode {
  KeywordsList,
  InclusionExclusionCriteria,
}

interface IKeywordsFormProps {
  domains: DomainItem[];
  keywordsData: UserKeyword;
  mode: KeywordsFormMode;
  onSave: (data: UserKeyword) => Promise<any>;
  onClose: () => void;
}

const StringSelect = Select.ofType<string>();
const stringItemRenderer: ItemRenderer<string> = (item, { handleClick, index, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return <MenuItem active={modifiers.active} key={index} onClick={handleClick} text={item} />;
};

const KeywordsForm: React.FC<IKeywordsFormProps> = ({
  domains,
  keywordsData,
  mode,
  onClose,
  onSave,
}) => {
  const i18n = useI18n();
  const { statusColors } = useTheme();
  const [editedData, setEditedData] = useState<Partial<UserKeyword>>(keywordsData);

  const [domainSearchQuery, setDomainSearchQuery] = useState<string>('');
  const [variableSearchQuery, setVariableSearchQuery] = useState<string>('');

  const domain = 'domain' in editedData ? editedData.domain : keywordsData.domain;
  const variable = 'variable' in editedData ? editedData.variable : keywordsData.variable;
  const excluded = editedData.excluded ?? keywordsData.excluded;
  const included = editedData.included ?? keywordsData.included;
  const title = editedData.title ?? keywordsData.title;

  const updateFormMany = (update: Partial<UserKeyword>) => {
    setEditedData((data) => ({ ...data, ...update }));
  };

  const updateForm = (key: keyof UserKeyword, value: any) => {
    updateFormMany({ [key]: value });
  };

  const canSave = !isEmpty(title) && !(isEmpty(excluded) && isEmpty(included));

  const domainsToShow = domains
    .filter(({ domain }) => {
      const shouldBeOmitted = includes(domain, ['All', 'Uncategorized']);
      const searchQueryMatches = includes(domainSearchQuery.toLowerCase(), domain.toLowerCase());
      return !shouldBeOmitted && searchQueryMatches;
    })
    .map(({ domain }) => domain);

  const variablesForDomain: string[] =
    domain === null ? [] : find({ domain }, domains)!.variables ?? [];

  const variablesToShow = variablesForDomain.filter((variable) =>
    includes(variableSearchQuery.toLowerCase(), variable.toLowerCase())
  );

  const handleSave = useCallback(() => {
    onSave(defaults(keywordsData, editedData));
    setEditedData({});
    onClose();
  }, [onSave, onClose, editedData, keywordsData, setEditedData]);

  return (
    <Dialog
      className="w-1/2"
      isCloseButtonShown={false}
      isOpen
      title={
        <p>
          <Icon icon={IconNames.EDIT} iconSize={16} />
          {mode === KeywordsFormMode.InclusionExclusionCriteria ? (
            <Trans>Save keyword set</Trans>
          ) : keywordsData.id === undefined ? (
            <Trans>Add keyword set</Trans>
          ) : (
            <Trans>Edit keyword set</Trans>
          )}
        </p>
      }
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label={i18n._(t`Title:`)} labelFor="title" labelInfo={i18n._(t`(required)`)}>
          <InputGroup
            autoFocus
            onChange={(e: FormEvent<HTMLElement>) => {
              updateForm('title', (e.target as HTMLInputElement).value);
            }}
            name="title"
            id="title"
            value={title}
          />
        </FormGroup>
        <FormGroup
          label={
            <span css={{ color: statusColors.acceptedPrimary }}>
              <Trans>Desired keywords:</Trans>
            </span>
          }
          labelFor="included"
        >
          <CustomTagsInput
            addOnBlur
            inputProps={{ id: 'included' }}
            onChange={(newValues: ReactNode[]) => {
              updateForm('included', newValues as string[]);
            }}
            inclusionIntent="desired"
            values={included}
          />
        </FormGroup>
        <FormGroup
          label={
            <span css={{ color: statusColors.rejectedPrimary }}>
              <Trans>Undesired keywords:</Trans>
            </span>
          }
          labelFor="excluded"
        >
          <CustomTagsInput
            addOnBlur
            inputProps={{ id: 'excluded' }}
            onChange={(newValues: ReactNode[]) => {
              updateForm('excluded', newValues as string[]);
            }}
            inclusionIntent="undesired"
            values={excluded}
          />
        </FormGroup>
        <FormGroup label={i18n._(t`Domain:`)}>
          <StringSelect
            activeItem={domain}
            disabled={isEmpty(domains)}
            itemRenderer={stringItemRenderer}
            items={domainsToShow}
            noResults={i18n._(t`No domains matching your query`)}
            onItemSelect={(newDomain) => {
              updateFormMany({ domain: newDomain, variable: null });
            }}
            onQueryChange={(query) => setDomainSearchQuery(query)}
            query={domainSearchQuery}
          >
            <Button
              disabled={isEmpty(domains)}
              icon={IconNames.FOLDER_OPEN}
              rightIcon={IconNames.CARET_DOWN}
              text={domain ?? i18n._(t`Select domain`)}
            />
            {domain !== null && (
              <Button
                icon={IconNames.CROSS}
                minimal
                onClick={(e) => {
                  e.stopPropagation();
                  updateFormMany({ domain: null, variable: null });
                }}
              />
            )}
          </StringSelect>
        </FormGroup>
        <FormGroup label={i18n._(t`Variable:`)}>
          <StringSelect
            activeItem={variable}
            disabled={isEmpty(variablesForDomain)}
            itemRenderer={stringItemRenderer}
            items={variablesToShow}
            noResults={i18n._(t`No variables matching your query`)}
            onItemSelect={(newVariable) => {
              updateForm('variable', newVariable);
            }}
            onQueryChange={(query) => setVariableSearchQuery(query)}
            query={variableSearchQuery}
          >
            <Button
              disabled={isEmpty(variablesForDomain)}
              icon={IconNames.VARIABLE}
              rightIcon={IconNames.CARET_DOWN}
              text={variable ?? i18n._(t`Select variable`)}
            />
            {variable !== null && (
              <Button
                icon={IconNames.CROSS}
                minimal
                onClick={(e) => {
                  e.stopPropagation();
                  updateForm('variable', undefined);
                }}
              />
            )}
          </StringSelect>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon={IconNames.CROSS}
            intent={Intent.DANGER}
            onClick={() => onClose()}
            text={i18n._(t`Cancel`)}
          />
          <Button
            disabled={!canSave}
            icon={IconNames.FLOPPY_DISK}
            intent={Intent.PRIMARY}
            onClick={handleSave}
            text={i18n._(t`Save`)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default KeywordsForm;
