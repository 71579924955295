/** @jsx jsx */
import { Button, Colors, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { jsx, css } from '@emotion/core';
import { ReactNode } from 'react';
import { lightGray5bg } from '../../common/styles';

const instructionSectionHeaderCss = css`
  ${lightGray5bg};
  border-bottom: 1px solid ${Colors.GRAY5};
`;

const FTInstructionSection: React.FC<{ title: ReactNode; onAddItem?: () => void }> = ({
  children,
  title,
  onAddItem,
}) => {
  return (
    <div>
      <div
        className="h-12 flex flex-row items-center ft-instructions-section"
        css={instructionSectionHeaderCss}
      >
        <div className="flex-1 mx-4">{title}</div>
        <Divider className="m-0 h-full" />
        <Button
          icon={IconNames.PLUS}
          className="h-full flex-none"
          onClick={onAddItem}
          disabled={onAddItem == null}
          minimal
          large
        />
      </div>
      <div className="px-4 py-2">{children}</div>
    </div>
  );
};

export default FTInstructionSection;
