import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { InputGroup, FormGroup, Intent } from '@blueprintjs/core';
import EditableSection from './editable_section';

const VariableEditSection = EditableSection.ofType<string>();

interface IVariableTitleEdit {
  title: string;
  onSave?: (title: string) => Promise<any>;
  saving?: boolean;
  isTitleTaken?: (title: string) => boolean;
  readOnly?: boolean;
}

const VariableTitleEdit: React.FC<IVariableTitleEdit> = ({
  title,
  onSave,
  saving,
  isTitleTaken,
  readOnly,
}) => {
  const isTitleInvalid = useCallback(
    (editedTitle) => {
      if (isTitleTaken == null) return true;
      return editedTitle ? editedTitle !== title && isTitleTaken(editedTitle) : false;
    },
    [title, isTitleTaken]
  );

  const editRenderer = useCallback(
    (editedTitle, setEditedTitle) => {
      const titleInvalid = isTitleInvalid(editedTitle);

      return (
        <FormGroup
          intent={titleInvalid ? Intent.DANGER : Intent.NONE}
          helperText={
            titleInvalid ? <Trans>Variable with such title already exists</Trans> : undefined
          }
        >
          <InputGroup
            intent={titleInvalid ? Intent.DANGER : Intent.NONE}
            autoFocus
            value={editedTitle}
            onChange={(evt) => setEditedTitle(evt.target.value)}
          />
        </FormGroup>
      );
    },
    [isTitleInvalid]
  );

  return (
    <VariableEditSection
      className="mb-3"
      title={<Trans>Title</Trans>}
      saving={saving}
      onSave={onSave}
      content={title}
      editRenderer={readOnly ? undefined : editRenderer}
      saveDisabled={isTitleInvalid}
    />
  );
};

export default VariableTitleEdit;
