/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import {
  ChangeEvent,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defer } from 'lodash/fp';

const textareaCss = css`
  transition: height 0.1s ease;
`;

interface ITextareaProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  grow?: boolean;
}

const Textarea: React.FC<ITextareaProps> = ({ grow, value, onChange, ...rest }) => {
  const elRef = useRef<null | HTMLTextAreaElement>(null);
  const [height, setHeight] = useState<number | undefined>();
  const valueRef = useRef<typeof value>(value);

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      setHeight(evt.target.scrollHeight);
      valueRef.current = evt.target.value;
      onChange?.(evt);
    },
    [onChange, setHeight]
  );

  const updateHeight = useCallback(() => {
    if (grow && elRef.current) {
      setHeight(undefined);
      const height = elRef.current.scrollHeight;
      defer(() => setHeight(height));
    }
  }, [grow]);

  // onmount
  useEffect(() => {
    updateHeight();
  }, []);

  // update height if value prop changes (but not as a result of input)
  useEffect(() => {
    if (value !== valueRef.current) {
      valueRef.current = value;
      updateHeight();
    }
  }, [value, updateHeight]);

  // TODO: they it works it will override the `height` prop if one is provided
  return (
    <textarea
      {...rest}
      css={textareaCss}
      ref={elRef}
      value={value}
      onChange={handleChange}
      style={{ height, overflow: grow ? 'hidden' : rest.style?.overflow }}
    />
  );
};

export default Textarea;
