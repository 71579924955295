/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button, Classes, Colors, Divider, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Plural, t, Trans } from '@lingui/macro';
import { defaults } from 'lodash/fp';
import { Fragment, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { darkGray1Color } from '../../../common/styles';
import {
  ConflictsResolutionSettings,
  ConflictType,
  ResolutionStrategy,
  ScreeningResult,
  ScreeningTask,
  StageMatchParams,
  StageType,
} from '../../../common/types';
import {
  conflictTaskCountsReducer,
  getConflictTypeLabel,
  getResolutionStrategyLabel,
  INITIAL_CONFLICT_COUNTS,
  partitionConflictTasksByConflictType,
  TConflictTaskCounts,
} from '../../../lib/conflict_resolution_helpers';
import { useTheme } from '../../settings/theme_context';
import ConflictsManagementDialog, {
  DEFAULT_RESOLUTION_STRATEGIES,
} from '../conflicts_resolution/conflicts_management_dialog';
import { DashboardCard } from './dashboard_card';
import { useI18n } from '../../../lib/utils';

const borderColorCss = css`
  border-color: ${Colors.GRAY5};
`;

const conflictTypeSummaryCss = css`
  border: 1px solid;
  border-bottom-width: 0;
  ${borderColorCss};

  &:last-child {
    border-bottom-width: 1px;
  }

  .${Classes.TAG} {
    background-color: ${Colors.BLUE3};
  }
`;

const CONFLICT_TYPES_ORDER = [
  ConflictType.Status,
  ConflictType.ExclusionReason,
  ConflictType.InclusionReason,
];

type ConflictTaskData = Pick<ScreeningTask, 'id' | 'is_draft' | 'completed'> & {
  study: {
    id: string;
    screeningTasks: {
      id: string;
      task_results: {
        result: ScreeningResult;
      }[];
    }[];
  };
};

interface IConflictsCardProps {
  conflictTasks: ConflictTaskData[];
  conflictResolutionStrategies: ConflictsResolutionSettings | null;
  stageType: StageType;
}

const ConflictsCard: React.FC<IConflictsCardProps> = ({
  conflictTasks,
  conflictResolutionStrategies,
  stageType,
}) => {
  const { projectId, stageId } = useParams<StageMatchParams>();
  const missingParams = projectId == null || stageId == null;
  const [showConflictSettingsFor, setShowConflictSettingsFor] = useState<ConflictType | null>(null);

  const resolutionStrategies = defaults(
    DEFAULT_RESOLUTION_STRATEGIES,
    conflictResolutionStrategies
  );
  const [statusConflicts, exclusionReasonConflicts, inclusionReasonConflicts] = useMemo(
    () => partitionConflictTasksByConflictType(conflictTasks),
    [conflictTasks]
  );

  const counts: Record<ConflictType, TConflictTaskCounts> = useMemo(() => {
    return {
      [ConflictType.Status]: statusConflicts.reduce(conflictTaskCountsReducer, {
        ...INITIAL_CONFLICT_COUNTS,
      }),
      [ConflictType.InclusionReason]: inclusionReasonConflicts.reduce(conflictTaskCountsReducer, {
        ...INITIAL_CONFLICT_COUNTS,
      }),
      [ConflictType.ExclusionReason]: exclusionReasonConflicts.reduce(conflictTaskCountsReducer, {
        ...INITIAL_CONFLICT_COUNTS,
      }),
    };
  }, [statusConflicts, exclusionReasonConflicts, inclusionReasonConflicts]);

  if (missingParams) return null;

  return (
    <DashboardCard
      name={<Trans>Conflicts</Trans>}
      rightElement={
        <div className="flex items-center">
          <Link to={`${stageId}/references/conflicts`} className="mr-4" css={darkGray1Color}>
            <Trans>Show list</Trans>
            <Icon icon={IconNames.CHEVRON_RIGHT} />
          </Link>
        </div>
      }
    >
      <div className="px-5">
        {CONFLICT_TYPES_ORDER.map((conflictType) => {
          const resolutionSettings = resolutionStrategies[conflictType];
          const { resolved, unresolved, drafts } = counts[conflictType];

          return stageType === StageType.TitlesAbstractScreening &&
            conflictType === ConflictType.InclusionReason ? null : (
            <ConflictTypeSummary
              key={conflictType}
              conflictType={conflictType}
              automatedResolution={resolutionSettings.automated}
              resolutionStrategy={resolutionSettings.strategy}
              resolvedCount={resolved}
              unresolvedCount={unresolved}
              draftCount={drafts}
              onOpenSettings={setShowConflictSettingsFor}
            />
          );
        })}
      </div>
      <ConflictsManagementDialog
        isOpen={showConflictSettingsFor != null}
        onClose={() => setShowConflictSettingsFor(null)}
        conflictType={showConflictSettingsFor ?? ConflictType.Status}
        projectId={projectId}
        stageId={stageId}
      />
    </DashboardCard>
  );
};

interface ConflictTypeSummaryProps {
  conflictType: ConflictType;
  unresolvedCount: number;
  resolvedCount: number;
  resolutionStrategy: ResolutionStrategy;
  automatedResolution: boolean;
  onOpenSettings: (conflictType: ConflictType) => void;
  draftCount?: number;
}

const ConflictTypeSummary: React.FC<ConflictTypeSummaryProps> = ({
  conflictType,
  unresolvedCount,
  resolvedCount,
  resolutionStrategy,
  automatedResolution,
  onOpenSettings,
  draftCount,
}) => {
  const { statusColors } = useTheme();
  const i18n = useI18n();
  const conflictTypeLabel = getConflictTypeLabel(conflictType, statusColors);
  const strategyLabel = automatedResolution ? getResolutionStrategyLabel(resolutionStrategy) : '';

  return (
    <div className="flex flex-row items-center h-12" css={conflictTypeSummaryCss}>
      <div className="w-24 text-center flex-none truncate px-2">{conflictTypeLabel}</div>
      <Divider css={borderColorCss} className="h-full m-0" />
      <div className="w-32 text-center flex-none truncate px-2">
        {resolvedCount} / {resolvedCount + unresolvedCount} <Trans>solved</Trans>
      </div>
      <Divider css={borderColorCss} className="h-full m-0" />
      <div className="flex-1 flex flex-row items-center px-2">
        {draftCount ? (
          <div className="truncate flex-1 mr-2" css={{ color: Colors.ORANGE1 }}>
            <Plural value={draftCount} one="# conflict to manage" other="# conflicts to manage" />
          </div>
        ) : (
          <Fragment>
            <div className="truncate flex-1 mr-2">{strategyLabel}</div>
            {automatedResolution && (
              <Tag round>
                <Trans>automated</Trans>
              </Tag>
            )}
          </Fragment>
        )}
        <Button
          title={i18n._(t`Open conflict resolution settings`)}
          minimal
          className="ml-2"
          icon={IconNames.ARROW_RIGHT}
          onClick={() => onOpenSettings(conflictType)}
        />
      </div>
    </div>
  );
};

export default ConflictsCard;
