import React, { ComponentClass, FunctionComponent } from 'react';
import Observer from '@researchgate/react-intersection-observer';

interface IntersectionObserverConfig {
  root: HTMLElement | string;
  rootMargin?: string;
  threshold?: number | number[];
}

export interface WithIntersectionObserverWrapperState {
  isIntersecting: boolean;
  intersectionRatio: number;
}

export default function withIntersectionObserver<P = {}>(config: IntersectionObserverConfig) {
  return (
    Target:
      | FunctionComponent<P & WithIntersectionObserverWrapperState>
      | ComponentClass<P & WithIntersectionObserverWrapperState>
  ) => {
    const threshold = config.threshold ? config.threshold : 0;

    return class WithIntersectionObserverWrapper extends React.PureComponent<
      P,
      WithIntersectionObserverWrapperState
    > {
      constructor(props: P) {
        super(props);
        this.state = { isIntersecting: false, intersectionRatio: 0 };
        this.handleChange = this.handleChange.bind(this);
      }

      handleChange({ isIntersecting, intersectionRatio }) {
        this.setState({
          isIntersecting: isIntersecting,
          intersectionRatio,
        });
      }

      render() {
        const { isIntersecting, intersectionRatio } = this.state;

        return (
          <Observer onChange={this.handleChange} threshold={threshold}>
            <Target
              {...this.props}
              isIntersecting={isIntersecting}
              intersectionRatio={intersectionRatio}
            />
          </Observer>
        );
      }
    };
  };
}
