/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { FormEvent } from 'react';
import { InclusionStatus } from '../../../common/types';
import { useTheme } from '../../settings/theme_context';

interface IInclusionStatusStrategyProps {
  value: InclusionStatus | null;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
}

const InclusionStatusStrategy = ({ value, onChange }) => {
  const { statusColors } = useTheme();
  return (
    <RadioGroup selectedValue={value} onChange={onChange}>
      <Radio
        disabled={onChange == null}
        value={InclusionStatus.Included}
        labelElement={
          <span>
            <Trans>Always</Trans>{' '}
            <span css={{ color: statusColors.acceptedPrimary }}>
              <Trans>In</Trans>
            </span>
          </span>
        }
      />
      <Radio
        disabled={onChange == null}
        value={InclusionStatus.Excluded}
        labelElement={
          <span>
            <Trans>Always</Trans>{' '}
            <span css={{ color: statusColors.rejectedPrimary }}>
              <Trans>Out</Trans>
            </span>
          </span>
        }
      />
    </RadioGroup>
  );
};

export default InclusionStatusStrategy;
