import { noop } from 'lodash/fp';
import React, { useMemo } from 'react';
import { FTScreeningFormData } from '../../common/types';
import CommentBox from '../references/comment_box';
import NavAndDecisionControls from '../screening/navigation_and_decision_controls';
import ScreeningTags from '../screening/screening_tags';

interface ITitleAndAbstractScreeningControlsPreviewProps {
  form: FTScreeningFormData;
}

const TitleAndAbstractScreeningControlsPreview: React.FC<ITitleAndAbstractScreeningControlsPreviewProps> =
  ({ form }) => {
    const exclusionReasons = useMemo(() => {
      return form.exclusion.map(({ id, name, code }) => ({
        id,
        label: code ? `${code}: ${name}` : name,
        code,
      }));
    }, [form.exclusion]);
    return (
      <div className="m-12 h-full">
        <NavAndDecisionControls
          disabled
          onToggleActiveReferenceSelect={noop}
          onShiftActiveReference={noop}
          onInclude={noop}
          onExclude={noop}
          onPostpone={noop}
          onUndoScreeningDecision={noop}
          onRedoScreeningDecision={noop}
          exclusionReasons={exclusionReasons}
        >
          <div className="flex-1 flex flex-col justify-end px-2 overflow-auto">
            <CommentBox />
            <ScreeningTags tags={form.tags} readOnly />
          </div>
        </NavAndDecisionControls>
      </div>
    );
  };

export default TitleAndAbstractScreeningControlsPreview;
