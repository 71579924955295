/** @jsx jsx */
import { Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { compose, isEmpty, isEqual, some, toLower } from 'lodash/fp';
import { useCallback, useState } from 'react';
import { useSetState } from '../../lib/utils';

interface ISaveCriteriaDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => Promise<any>;
  existingSets: string[];
}

type TemplateNameState = {
  templateName: string;
  nameInvalid: boolean;
};

const SaveCriteriaDialog: React.FC<ISaveCriteriaDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  existingSets,
}) => {
  const [saving, setSaving] = useState(false);
  const [state, setState] = useSetState<TemplateNameState>({
    templateName: '',
    nameInvalid: false,
  });

  const { templateName, nameInvalid } = state;

  const handleTemplateNameChange = useCallback(
    (evt) => {
      const newName = evt.target.value;
      const nameTaken = some(compose(isEqual(newName.toLowerCase()), toLower), existingSets);

      setState({
        templateName: newName,
        nameInvalid: nameTaken,
      });
    },
    [setState, existingSets]
  );

  const handleCancel = useCallback(() => {
    setState({
      templateName: '',
      nameInvalid: false,
    });
    onClose();
  }, [onClose, setState]);

  const handleSave = useCallback(() => {
    setSaving(true);
    onSave(templateName)
      .then(() => handleCancel())
      .finally(() => setSaving(false));
  }, [setSaving, onSave, handleCancel, templateName]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleCancel}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      title={<Trans>Save criteria set</Trans>}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={<Trans>Set name</Trans>}
          intent={nameInvalid ? Intent.DANGER : Intent.NONE}
          helperText={
            nameInvalid ? (
              <Trans>Set with such name already exists. Provide a different name</Trans>
            ) : undefined
          }
        >
          <InputGroup
            autoFocus
            value={templateName}
            name="template-name"
            onChange={handleTemplateNameChange}
            intent={nameInvalid ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={<Trans>Cancel</Trans>} onClick={handleCancel} />
          <Button
            disabled={isEmpty(templateName) || nameInvalid}
            text={<Trans>Save</Trans>}
            onClick={handleSave}
            loading={saving}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SaveCriteriaDialog;
