/** @jsx jsx */
import { Checkbox, Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import Immutable from 'immutable';
import React, { useEffect, useState } from 'react';
import { UserKeyword } from '../../../common/types';
import { stopPropagationHandler } from '../../../lib/utils';
import KeywordsImportDialogCard from './keywords_import_dialog_card';

interface IKeywordsImportDialogTableProps {
  keywordsToShow: UserKeyword[];
  selectedKeywords: Immutable.Map<string, UserKeyword>;
  toggleKeyword: (keyword: UserKeyword) => void;
}

const rowCss = css`
  height: 40px;
`;

const activeRowCss = css`
  border-color: ${Colors.BLUE3};
  ${rowCss}
`;

const leftMarginCss = css`
  margin-left: 2px;
`;

const rightMarginCss = css`
  margin-right: 2px;
`;

const KeywordsImportDialogTable: React.FC<IKeywordsImportDialogTableProps> = ({
  keywordsToShow,
  selectedKeywords,
  toggleKeyword,
}) => {
  const [activeKeyword, setActiveKeyword] = useState<UserKeyword>(keywordsToShow[0]);

  useEffect(() => setActiveKeyword(keywordsToShow[0]), [keywordsToShow]);

  return (
    <div className="flex flex-row h-full items-stretch">
      <div className="flex-grow overflow-y-auto">
        {keywordsToShow.map((keyword) => {
          const isActive = keyword.id === activeKeyword.id;
          const borderClassNames = isActive ? 'border-2 border-solid' : 'border-b border-solid';

          return (
            <div
              className={`bg-white cursor-pointer flex flex-row items-center
                 px-5 ${borderClassNames}`}
              css={isActive ? activeRowCss : rowCss}
              key={keyword.id!}
              onClick={(e) => {
                e.stopPropagation();
                setActiveKeyword(keyword);
              }}
            >
              <div onClick={stopPropagationHandler}>
                <Checkbox
                  className="m-0"
                  css={isActive ? undefined : leftMarginCss}
                  checked={selectedKeywords.has(keyword.id!)}
                  onClick={() => toggleKeyword(keyword)}
                  label={keyword.title}
                />
              </div>
              {keyword.user_id !== null && (
                <Icon
                  className="ml-auto text-gray-600"
                  css={isActive ? undefined : rightMarginCss}
                  icon={IconNames.USER}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="border-l border-solid w-5/12">
        <KeywordsImportDialogCard keyword={activeKeyword} />
      </div>
    </div>
  );
};

export default KeywordsImportDialogTable;
