/** @jsx jsx */
import {
  Button,
  Classes,
  Colors,
  FormGroup,
  InputGroup,
  Intent,
  NumericInput,
  TextArea,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { findIndex, reject } from 'lodash/fp';
import { ChangeEvent, useCallback } from 'react';
import { Reference } from '../../../common/types';
import { uid, updateArrayItem, useSetState } from '../../../lib/utils';
import Dialog from '../../common/dialog';

const dialogCss = css`
  width: 600px;
  .${Classes.LABEL} {
    font-size: 12px;
    color: ${Colors.DARK_GRAY5};
  }

  counter-reset: authors;

  .author-item:before {
    counter-increment: authors;
    position: absolute;
    right: calc(100% - 10px);
    content: counter(authors) '.';
  }
`;

function referenceToEditedData(reference: Pick<Reference, 'title' | 'attrs'>): IEditedData {
  return {
    title: reference.title,
    attrs: {
      doi: reference.attrs.doi ?? '',
      venue: reference.attrs.venue ?? '',
      year: reference.attrs.year ?? '',
      pages: reference.attrs.pages ?? '',
      volume: reference.attrs.volume ?? '',
      fullText: reference.attrs.fullText ?? '',
      id: reference.attrs.id ?? '',
      accessionNumber: reference.attrs.accessionNumber ?? '',
      source: reference.attrs.source ?? '',
      issue: reference.attrs.issue ?? '',
      authors:
        reference.attrs?.authors.map(({ firstNames, lastName }) => ({
          lastName,
          firstName: firstNames.join(' '),
          tempId: uid(),
        })) ?? [],
    },
  };
}

interface IBibliographicalDataEditDialogProps {
  reference: Pick<Reference, 'id' | 'attrs' | 'title'>;
  isOpen: boolean;
  onClose: () => void;
  onApply: (referenceData: Pick<Reference, 'id' | 'title' | 'attrs'>) => void;
}

type TAuthor = { lastName: string; firstName: string; tempId: string };
interface IEditedData {
  title: string;
  attrs: {
    authors: TAuthor[];
    doi: string;
    venue: string;
    year: string;
    pages: string;
    volume: string;
    fullText: string;
    id: string;
    accessionNumber: string;
    source: string;
    issue: string;
  };
}

const BibliographicalDataEditDialog: React.FC<IBibliographicalDataEditDialogProps> = ({
  reference,
  isOpen,
  onClose,
  onApply,
}) => {
  const [editedData, setEditedData] = useSetState(referenceToEditedData(reference));
  const { title: editedTitle, attrs: editedAttrs } = editedData;
  const referenceId = reference.id;

  const handleTitleChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      setEditedData({ title: evt.target.value.replace(/\n/g, ' ') });
    },
    [setEditedData]
  );

  const handleSave = useCallback(() => {
    onApply({
      id: referenceId,
      title: editedData.title,
      attrs: {
        ...editedData.attrs,
        authors: editedData.attrs.authors.map(({ firstName, lastName }) => ({
          lastName,
          firstNames: firstName.split(' '),
        })),
      },
    });
  }, [onApply, editedData, referenceId]);

  const updateEditedAttribute = useCallback(
    (attrName: string, value: any) => {
      setEditedData((editedData) => ({
        ...editedData,
        attrs: { ...editedData.attrs, [attrName]: value },
      }));
    },
    [setEditedData]
  );

  const updateAuthors = useCallback(
    (updater: (authors: TAuthor[]) => TAuthor[]) => {
      setEditedData((editedData) => ({
        ...editedData,
        attrs: { ...editedData.attrs, authors: updater(editedData.attrs.authors) },
      }));
    },
    [setEditedData]
  );

  const handleAttrChange = useCallback(
    (evt) => {
      const attrName = evt.target.getAttribute('name');
      const value = evt.target.value;

      updateEditedAttribute(attrName, value);
    },
    [updateEditedAttribute]
  );

  const handleAddAuthor = useCallback(() => {
    const newAuthor = { firstName: '', lastName: '', tempId: uid() };

    updateAuthors((currentAuthors) => [newAuthor].concat(currentAuthors));
  }, [updateAuthors]);

  const handleRemoveAuthor = useCallback(
    (tempId: string) => {
      updateAuthors((currentAuthors) => reject({ tempId }, currentAuthors));
    },
    [updateAuthors]
  );

  const handleAuthorFieldUpdate = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const authorId = evt.target.dataset.authorId;
      const fieldName = evt.target.getAttribute('name');
      const value = evt.target.value;

      updateAuthors((authors) => {
        const authorIdx = findIndex({ tempId: authorId }, authors);

        if (authorIdx < 0) return authors;

        return updateArrayItem(
          authorIdx,
          (author) => ({ ...author, [fieldName!]: value }),
          authors
        );
      });
    },
    [updateAuthors]
  );

  const handleCancel = useCallback(() => {
    onClose();
    setEditedData(referenceToEditedData(reference));
  }, [setEditedData, reference, onClose]);

  const isReferenceNumberInvalid: boolean = /\D/.test(editedAttrs.id);

  return (
    <Dialog
      title={<Trans>Edit bibliographical data</Trans>}
      isOpen={isOpen}
      onClose={handleCancel}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      css={dialogCss}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="flex flex-col overflow-auto px-2 pr-6">
          <FormGroup labelFor="doi" label={<Trans>DOI</Trans>}>
            <InputGroup
              className="w-40"
              id="doi"
              name="doi"
              value={editedAttrs.doi}
              onChange={handleAttrChange}
            />
          </FormGroup>
          <FormGroup className="relative" label={<Trans>Authors</Trans>}>
            <div className="px-4">
              <div className="flex flex-row">
                <FormGroup className="m-0 mr-2 w-40" label={<Trans>Last Name</Trans>} />
                <FormGroup className="m-0 w-40" label={<Trans>First Name</Trans>} />
              </div>
              {editedAttrs.authors.map(({ lastName, firstName, tempId }) => {
                return (
                  <div key={tempId} className="mb-2 flex flex-row items-center author-item">
                    <InputGroup
                      data-author-id={tempId}
                      name="lastName"
                      className="w-40 mr-2"
                      value={lastName}
                      onChange={handleAuthorFieldUpdate}
                    />
                    <InputGroup
                      data-author-id={tempId}
                      name="firstName"
                      className="w-40"
                      value={firstName}
                      onChange={handleAuthorFieldUpdate}
                    />
                    <Button
                      icon={IconNames.CROSS}
                      minimal
                      intent={Intent.DANGER}
                      onClick={() => handleRemoveAuthor(tempId)}
                    />
                  </div>
                );
              })}
              <Button
                alignText="left"
                text="Add an author"
                icon={IconNames.ADD}
                onClick={() => handleAddAuthor()}
              />
            </div>
          </FormGroup>
          <FormGroup label={<Trans>Title</Trans>} labelFor="title">
            <TextArea
              className="w-full resize-none h-24 mb-4"
              id="title"
              onChange={handleTitleChange}
              value={editedTitle}
              growVertically
            />
          </FormGroup>
          <FormGroup labelFor="venue" label={<Trans>Journal</Trans>}>
            <InputGroup
              id="venue"
              name="venue"
              value={editedAttrs.venue}
              onChange={handleAttrChange}
            />
          </FormGroup>
          <div className="flex flex-row">
            <FormGroup className="w-20 mr-3" labelFor="year" label={<Trans>Year</Trans>}>
              <NumericInput
                allowNumericCharactersOnly
                buttonPosition="none"
                id="year"
                name="year"
                value={editedAttrs.year}
                onValueChange={(_, value) => updateEditedAttribute('year', value)}
              />
            </FormGroup>
            <FormGroup className="w-20 mr-3" labelFor="volume" label={<Trans>Volume</Trans>}>
              <InputGroup
                id="volume"
                name="volume"
                value={editedAttrs.volume}
                onChange={handleAttrChange}
              />
            </FormGroup>
            <FormGroup className="w-20 mr-3" labelFor="issue" label={<Trans>Issue</Trans>}>
              <InputGroup
                id="issue"
                name="issue"
                value={editedAttrs.issue}
                onChange={handleAttrChange}
              />
            </FormGroup>
            <FormGroup className="w-20 mr-3" labelFor="pages" label={<Trans>Pages</Trans>}>
              <InputGroup
                id="pages"
                name="pages"
                value={editedAttrs.pages}
                onChange={handleAttrChange}
              />
            </FormGroup>
          </div>
          <FormGroup labelFor="fullText" label={<Trans>Link</Trans>}>
            <InputGroup
              id="fullText"
              name="fullText"
              value={editedAttrs.fullText}
              onChange={handleAttrChange}
            />
          </FormGroup>
          <div className="flex flex-row justify-between">
            <FormGroup labelFor="accessionNumber" label={<Trans>Accession number</Trans>}>
              <InputGroup
                className="w-40"
                id="accessionNumber"
                name="accessionNumber"
                value={editedAttrs.accessionNumber}
                onChange={handleAttrChange}
              />
            </FormGroup>
            <FormGroup labelFor="source" label={<Trans>Source</Trans>}>
              <InputGroup
                className="w-40"
                id="source"
                name="source"
                value={editedAttrs.source}
                onChange={handleAttrChange}
              />
            </FormGroup>
          </div>
          <FormGroup
            labelFor="id"
            label={<Trans>Reference number</Trans>}
            intent={isReferenceNumberInvalid ? Intent.DANGER : Intent.NONE}
            helperText={
              isReferenceNumberInvalid ? (
                <Trans>Only non-negative integers allowed</Trans>
              ) : undefined
            }
          >
            <NumericInput
              allowNumericCharactersOnly
              buttonPosition="none"
              className="block w-40"
              id="id"
              name="id"
              intent={isReferenceNumberInvalid ? Intent.DANGER : Intent.NONE}
              value={editedAttrs.id}
              onValueChange={(_, value) => updateEditedAttribute('id', value)}
            />
          </FormGroup>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleCancel} text={<Trans>Cancel</Trans>} />
          <Button
            disabled={isReferenceNumberInvalid}
            onClick={handleSave}
            text={<Trans>Save</Trans>}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default BibliographicalDataEditDialog;
