/** @jsx jsx */
import { useMutation } from '@apollo/react-hooks';
import { Button, Classes, Colors, H3, H5, Icon, Intent, Tooltip } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { gql, loader } from 'graphql.macro';
import { findIndex, isEmpty, keys, reduce, reject } from 'lodash/fp';
import { plural, Trans } from '@lingui/macro';
import React, { ReactNode, useCallback } from 'react';
import { IconName, IconNames } from '@blueprintjs/icons';
import { captureException } from '@sentry/browser';
import { Buckets, TUploadedFile, uploadMultipleAttachments } from '../lib/attachmentUpload';
import { useKeycloak } from '../keycloak';
import { useSetState } from '../lib/utils';
import Dialog from './common/dialog';
import { MutationFunctionOptions } from '@apollo/react-common';
import { ReferenceLog, TReferenceLogType } from '../common/types';
import AppToaster from '../lib/toaster';
import i18n from '../i18n';

const InsertAttachmentsMutation = loader('../graphql/insert_attachments.gql');
const InsertAndAssignAttachmentsMutation = loader('../graphql/insert_and_assign_attachments.gql');

export const dragZoneCss = css`
  height: 300px;
`;

export const dragZoneIconCss = css`
  color: #adadad;
`;

export enum UploadState {
  InProgress,
  Completed,
  Error,
}

interface FileUploadDialogProps {
  isOpen: boolean;
  projectId?: string;
  referenceId?: string;
  continueFn: () => void;
  uploadMutationOptions?: MutationFunctionOptions<any, Record<string, any>>;
}

interface FileUploadDialogState {
  filesToUpload: File[];
  uploadState: UploadState | null;
  uploadProgress: number;
  dragging: boolean;
}

const FILE_UPLOAD_DIALOG_INITIAL_STATE: FileUploadDialogState = {
  filesToUpload: [],
  uploadState: null,
  uploadProgress: 0,
  dragging: false,
};

interface IconWithActionScreenProps {
  color?: string | string[];
  icon: IconName;
  text: ReactNode;
}

export const IconWithActionScreen: React.FC<IconWithActionScreenProps> = ({
  text,
  icon,
  color,
}) => (
  <div className="text-center mt-8" css={{ color }}>
    <Icon icon={icon} iconSize={60} />
    <h3 className="mt-12">{text}</h3>
  </div>
);

const FilesUploadDialog: React.FC<FileUploadDialogProps> = ({
  isOpen,
  projectId,
  referenceId,
  continueFn,
  uploadMutationOptions,
}) => {
  const pdfFilesInput = React.createRef<HTMLInputElement>();
  const [state, setState] = useSetState<FileUploadDialogState>(FILE_UPLOAD_DIALOG_INITIAL_STATE);
  const { filesToUpload, uploadState, uploadProgress, dragging } = state;
  const isUploadInProgress = uploadState === UploadState.InProgress;
  const isUploadCompleted = uploadState === UploadState.Completed;
  const isUploadError = uploadState === UploadState.Error;
  const filesListEmpty = isEmpty(filesToUpload);
  const { user, getToken } = useKeycloak();
  const [insertFilesData] = useMutation(InsertAttachmentsMutation);
  const [insertAndAssignFilesData] = useMutation(InsertAndAssignAttachmentsMutation);

  const resetState = () => setState(FILE_UPLOAD_DIALOG_INITIAL_STATE);

  const addFilesToUpload = (files: FileList | null) => {
    const updatedFilesList: File[] = reduce<File, File[]>(
      (acc: File[], f: File) => {
        const fileIdx = findIndex((file: File) => file.name === f.name, acc);
        return fileIdx === -1 ? [...acc, f] : acc;
      },
      filesToUpload,
      files
    );

    setState({ filesToUpload: updatedFilesList });
  };

  const updateUploadProgress = useCallback(
    (value: number) => setState({ uploadProgress: value }),
    [setState]
  );

  const filesDataMapper = useCallback(
    (file: TUploadedFile) => ({
      project_id: projectId,
      filename: file.originalname,
      key: file.key,
      reference_id: referenceId,
    }),
    [projectId, referenceId]
  );

  const logsMapper = useCallback(
    (file: TUploadedFile): Partial<ReferenceLog> => ({
      reference_id: referenceId,
      data: {
        type: 'attachment_assigned' as TReferenceLogType,
        userId: user.id,
        key: file.key,
        filename: file.originalname,
      },
    }),
    [referenceId, user.id]
  );

  const uploadFiles = async () => {
    setState({ uploadState: UploadState.InProgress });
    const token = await getToken();
    try {
      AppToaster.show({
        icon: IconNames.INFO_SIGN,
        intent: Intent.PRIMARY,
        message: <Trans>File upload started.</Trans>,
      });
      await uploadMultipleAttachments({
        token,
        projectId,
        bucket: Buckets.ReferencesAttachments,
        updateUploadProgress,
        files: filesToUpload,
        filesDataMapper,
        logsMapper: referenceId ? logsMapper : undefined,
        insertFilesMutation: referenceId ? insertAndAssignFilesData : insertFilesData,
        insertFilesMutationVariables: referenceId
          ? { claimedReferencesIds: [referenceId] }
          : undefined,
        insertFilesMutationOptions: uploadMutationOptions,
      }).then(() =>
        AppToaster.show({
          icon: IconNames.TICK,
          intent: Intent.SUCCESS,
          message: i18n._(
            plural({
              value: filesToUpload.length,
              one: '1 file uploaded successfully.',
              other: '# files uploaded successfully.',
            })
          ),
        })
      );
      setState({ uploadState: UploadState.Completed });
    } catch (err) {
      captureException(err);
      AppToaster.show({
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
        message: `${i18n._(
          plural({
            value: filesToUpload.length,
            one: 'Failed to upload 1 file',
            other: 'Failed to upload # files',
          })
        )}: ${(err as Error).toString()}`,
      });
      setState({ uploadState: UploadState.Error });
    }
  };

  const removeFile = (name: string) => () => {
    setState({ filesToUpload: reject(['name', name], filesToUpload) });
  };

  const handleContinue = () => {
    continueFn();
    isUploadCompleted && resetState();
  };

  return (
    <Dialog
      canEscapeKeyClose={!isUploadInProgress}
      canOutsideClickClose={!isUploadInProgress}
      className="w-2/5"
      isOpen={isOpen}
      isCloseButtonShown={!isUploadInProgress}
      onClose={handleContinue}
      title={<Trans>Upload PDF files</Trans>}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="mb-4">
          <p>
            <Trans>Uploaded PDF files will be matched against a list of references.</Trans>
          </p>
          <p>
            <Trans>
              To speed up the process, please use the name format{' '}
              <code>[1st Author]-[Year]-[Title].pdf</code>.
              <Tooltip content={<Trans>Learn how to do this in reference management tools.</Trans>}>
                <Icon className="ml-2" color={Colors.GRAY1} icon={IconNames.INFO_SIGN} />
              </Tooltip>
            </Trans>
          </p>
        </div>
        <div
          className="border border-solid border-gray-500 rounded-lg"
          css={{
            backgroundColor: dragging ? '#f7f7f7' : filesListEmpty ? '#e4e4e4' : 'white',
          }}
        >
          <div
            className="overflow-auto p-4"
            css={dragZoneCss}
            onDragOver={(e) => {
              e.preventDefault();
              setState({ dragging: true });
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setState({ dragging: false });
            }}
            onDrop={(e) => {
              e.preventDefault();
              addFilesToUpload(e.dataTransfer.files);
            }}
          >
            {filesListEmpty ? (
              <div className="flex flex-col justify-between h-full p-4 border border-dashed border-gray-500">
                <div className="mt-8 text-center">
                  <Icon css={dragZoneIconCss} icon="document" iconSize={60} />
                  <H3>
                    <Trans>Drag PDF files here</Trans>
                  </H3>
                </div>
                <div className="text-center">
                  <div>
                    <Trans>Or if you prefer...</Trans>
                  </div>
                  <Button className="mx-auto" small onClick={() => pdfFilesInput.current!.click()}>
                    <Trans>Select files to upload</Trans>
                  </Button>
                </div>
              </div>
            ) : isUploadInProgress ? (
              <IconWithActionScreen
                text={
                  <Trans>{`Uploading ${
                    keys(filesToUpload).length
                  } PDF files... (${uploadProgress}%)`}</Trans>
                }
                icon="refresh"
                color={Colors.BLUE1}
              />
            ) : isUploadCompleted ? (
              <IconWithActionScreen
                text={
                  <Trans>{`${keys(filesToUpload).length} files were successfully uploaded`}</Trans>
                }
                icon="tick"
                color={Colors.GREEN1}
              />
            ) : isUploadError ? (
              <IconWithActionScreen
                text={<Trans>Upload error</Trans>}
                icon="error"
                color={Colors.RED1}
              />
            ) : (
              <React.Fragment>
                <H5>
                  <Trans>Selected files</Trans>
                </H5>
                <div className="text-black">
                  {filesToUpload.map((file: File) => (
                    <div key={file.name} className="flex items-center mx-0 my-2">
                      <Tooltip hoverOpenDelay={500} content={<Trans>Remove</Trans>}>
                        <Icon
                          icon="cross"
                          iconSize={12}
                          className="mr-2 cursor-pointer text-gray-500"
                          onClick={removeFile(file.name)}
                        />
                      </Tooltip>
                      <span className="truncate">{file.name}</span>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <input
        className="hidden"
        type="file"
        multiple
        accept=".pdf"
        ref={pdfFilesInput}
        onChange={(e) => addFilesToUpload(e.target.files)}
      />
      <div className={Classes.DIALOG_FOOTER}>
        {isUploadCompleted || isUploadError ? (
          <div className="flex justify-between">
            <div>
              {isUploadError && (
                <Button onClick={resetState} large>
                  <Trans>Try again</Trans>
                </Button>
              )}
            </div>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button intent={Intent.SUCCESS} large onClick={handleContinue}>
                <Trans>OK</Trans>
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div>
              {!filesListEmpty && (
                <Button
                  onClick={() => pdfFilesInput.current!.click()}
                  large
                  disabled={isUploadInProgress}
                >
                  <Trans>Add more files</Trans>
                </Button>
              )}
            </div>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={handleContinue} large disabled={isUploadInProgress}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                intent={Intent.SUCCESS}
                large
                loading={isUploadInProgress}
                onClick={uploadFiles}
                disabled={isEmpty(filesToUpload)}
              >
                <Trans>Upload</Trans>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default FilesUploadDialog;
