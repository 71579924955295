import React, { useMemo } from 'react';
import { Intent, OverflowList, Popover, Position, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { FTScreeningCriteria } from '../../../common/types';
import { Trans } from '@lingui/macro';

interface IFTDecisionLabelsProps {
  criteria: FTScreeningCriteria[];
  isIncluded: boolean;
  screeningCompleted: boolean;
  onEraseInclusionDecision: (criteria: FTScreeningCriteria) => void;
}

type IDecisionLabelProps = Pick<
  IFTDecisionLabelsProps,
  'isIncluded' | 'screeningCompleted' | 'onEraseInclusionDecision'
> & {
  screeningCriteria: FTScreeningCriteria;
};

const criteriaToDecisionTag = ({
  screeningCriteria,
  isIncluded,
  screeningCompleted,
  onEraseInclusionDecision,
}: IDecisionLabelProps) => (
  <Tag
    key={screeningCriteria.id}
    className="mr-2"
    intent={isIncluded ? Intent.SUCCESS : Intent.DANGER}
    large
    onRemove={screeningCompleted ? undefined : () => onEraseInclusionDecision(screeningCriteria)}
  >
    <span className="uppercase">{isIncluded ? <Trans>In</Trans> : <Trans>Out</Trans>}:</span>
    <span>{screeningCriteria.name}</span>
  </Tag>
);

const overflowRenderer = (items: IDecisionLabelProps[]) => (
  <Popover
    interactionKind="hover"
    position={Position.BOTTOM}
    content={<div className="p-2 flex flex-row flex-wrap">{items.map(criteriaToDecisionTag)}</div>}
  >
    <Tag className="ml-2" minimal intent={Intent.PRIMARY} icon={IconNames.PLUS}>
      {items.length}
    </Tag>
  </Popover>
);

const FTDecisionLabels: React.FC<IFTDecisionLabelsProps> = ({
  criteria,
  isIncluded,
  screeningCompleted,
  onEraseInclusionDecision,
}) => {
  const items: IDecisionLabelProps[] = useMemo(
    () =>
      criteria.map((screeningCriteria) => ({
        screeningCriteria,
        isIncluded,
        screeningCompleted,
        onEraseInclusionDecision,
      })),
    [criteria, isIncluded, screeningCompleted, onEraseInclusionDecision]
  );

  return (
    <OverflowList
      className="items-center"
      collapseFrom="end"
      items={items}
      overflowRenderer={overflowRenderer}
      visibleItemRenderer={criteriaToDecisionTag}
    />
  );
};

export default FTDecisionLabels;
