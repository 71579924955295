/** @jsx jsx */
import { Card, Tag } from '@blueprintjs/core';
import { jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import React from 'react';
import { UserKeyword } from '../../../common/types';
import { useKeywordTagStyles } from '../../../lib/utils';
import { useTheme } from '../../settings/theme_context';

interface IKeywordsImportDialogCardProps {
  keyword: UserKeyword;
}

const KeywordsImportDialogCard: React.FC<IKeywordsImportDialogCardProps> = ({ keyword }) => {
  const { excluded, included, title } = keyword;
  const { statusColors } = useTheme();
  const [desiredKeywordCss, undesiredKeywordCss] = useKeywordTagStyles();

  return (
    <Card className="m-3 p-0">
      <div className="border-b border-solid p-5 text-lg">{title}</div>
      <div className="p-5">
        <div className="font-bold pb-5" css={{ color: statusColors.acceptedPrimary }}>
          <Trans>Desired keywords</Trans>:
        </div>
        <div>
          {included.map((keyword, keywordIdx) => (
            <Tag className="mr-1 mb-1" css={desiredKeywordCss} key={keywordIdx}>
              {keyword}
            </Tag>
          ))}
        </div>
      </div>
      <div className="p-5">
        <div className="font-bold pb-5" css={{ color: statusColors.rejectedPrimary }}>
          <Trans>Undesired keywords</Trans>:
        </div>
        <div>
          {excluded.map((keyword, keywordIdx) => (
            <Tag className="mr-1 mb-1" css={undesiredKeywordCss} key={keywordIdx}>
              {keyword}
            </Tag>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default KeywordsImportDialogCard;
