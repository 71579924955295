/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { t } from '@lingui/macro';
import { get, keyBy } from 'lodash/fp';
import { useMemo } from 'react';
import { TReferenceData } from '..';
import {
  ConflictTaskScreeningResult,
  FTScreeningCriteria,
  ReferenceLog,
  ScreeningForm,
  ScreeningResult,
} from '../../../common/types';
import {
  getConflictTasksFromReference,
  getTaskResultFromReference,
} from '../../../lib/task_helpers';
import { useCurrCallback, useI18n } from '../../../lib/utils';
import { ReferenceCommentFragmentType } from '../../../graphql/reference_comment_fragment';
import ConflictDecision from '../conflict_decision';

const EMPTY_LIST = [];

interface IReferencesConflictsDataProps {
  form: ScreeningForm;
  stageId: string;
  reference: TReferenceData;
  referenceComments: ReferenceCommentFragmentType[];
  onApplyDecision: (result: ScreeningResult) => void;
}

const ReferencesConflictsData: React.FC<IReferencesConflictsDataProps> = ({
  reference,
  stageId,
  form,
  onApplyDecision,
  referenceComments,
}) => {
  const referenceCommentsById = useMemo(() => {
    return keyBy('id', referenceComments);
  }, [referenceComments]);

  const i18n = useI18n();
  const { id: formId, form: formData } = form;

  const criteriaMap = useMemo(() => {
    const inclusion = keyBy('id', formData.inclusion as FTScreeningCriteria[]);
    const exclusion = keyBy('id', formData.exclusion as FTScreeningCriteria[]);

    return { ...inclusion, ...exclusion };
  }, [formData]);

  const screeningResults = useMemo(() => {
    const tasks: ConflictTaskScreeningResult[] = getConflictTasksFromReference(reference, stageId);

    return tasks.map((task) => {
      const user = get('team_member.user', task);

      return {
        userId: user ? user.id : null,
        username: user ? user.name : i18n._(t`User removed`),
        result: task.result,
        comment_id: task.comment_id,
      };
    });
  }, [reference, formId, stageId]);

  const handleApply = useCurrCallback(
    (result: ScreeningResult, _evt) => {
      onApplyDecision(result);
    },
    [onApplyDecision]
  );

  return (
    <div>
      {screeningResults.map(({ userId, username, result, comment_id }) => {
        const { criteria = [], tags } = result;
        const comment = comment_id ? referenceCommentsById[comment_id].comment : '';

        return (
          <ConflictDecision
            key={userId}
            className="mb-8"
            inclusionStatus={result.inclusionStatus}
            screenerName={username}
            screenerDeleted={userId == null}
            screeningTags={get('form.tags', form) ?? EMPTY_LIST}
            selectedTags={tags}
            decisionReason={criteria.map(({ id }) => ({
              inclusionStatus: criteriaMap[id].inclusionStatus,
              reason: criteriaMap[id].name,
            }))}
            comment={comment}
            onApply={handleApply(result)}
          />
        );
      })}
    </div>
  );
};

export default ReferencesConflictsData;
