import React, { memo, ReactNode } from 'react';
import {
  AnchorButton,
  Button,
  Card,
  Colors,
  Divider,
  H4,
  IButtonProps,
  Icon,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { omit } from 'lodash/fp';

interface IDashboardCardProps {
  name: ReactNode;
  interactive?: boolean;
  onClick?: () => void;
  rightElement?: ReactNode;
  withDivider?: boolean;
}

export const DashboardCard: React.FC<IDashboardCardProps> = ({
  children,
  interactive = true,
  name,
  onClick,
  rightElement,
  withDivider = true,
}) => (
  <Card className="px-0 mb-4 shadow-none" interactive={interactive && !!onClick} onClick={onClick}>
    <div className="px-6 flex items-center justify-between">
      <H4 className="font-normal mb-0">{name}</H4>
      {rightElement}
    </div>
    {children && (
      <React.Fragment>
        {withDivider && <Divider className="mx-0 mt-4 mb-0" />}
        <div className="mt-4">{children}</div>
      </React.Fragment>
    )}
  </Card>
);

interface IDashboardCardButtonProps extends IButtonProps {
  tooltip?: string | JSX.Element;
}

const DashboardCardButton: React.FC<IDashboardCardButtonProps> = memo(
  ({ tooltip, ...buttonProps }) => {
    const propsToPass = omit('children', buttonProps);

    if (!tooltip) {
      return <Button {...propsToPass} />;
    }

    const ButtonComponent = buttonProps.disabled ? AnchorButton : Button;

    return (
      <Tooltip content={tooltip}>
        <ButtonComponent {...omit('elementRef', propsToPass)} />
      </Tooltip>
    );
  }
);

type TButtonProps = Omit<IDashboardCardButtonProps, 'onClick'>;

interface IDashboardCardWithButtonProps extends IDashboardCardProps {
  buttonProps: TButtonProps;
  onClick?: () => void;
}

type TDashboardCardWithButtonProps = Omit<
  IDashboardCardWithButtonProps,
  'rightElement' | 'interactive'
>;

export const DashboardCardWithButton: React.FC<TDashboardCardWithButtonProps> = ({
  buttonProps,
  onClick,
  ...restProps
}) => (
  <DashboardCard
    interactive={false}
    rightElement={<DashboardCardButton onClick={onClick} {...buttonProps} />}
    {...restProps}
  />
);

type TDashboardCardWithChevronProps = Omit<IDashboardCardProps, 'rightElement'>;

export const DashboardCardWithChevron: React.FC<TDashboardCardWithChevronProps> = (props) => (
  <DashboardCard
    rightElement={
      <Icon className="ml-auto" color={Colors.GRAY1} icon={IconNames.CHEVRON_RIGHT} iconSize={20} />
    }
    {...props}
  />
);
