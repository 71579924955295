/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface IInstructionItemMovementControlsProps {
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  className?: string;
}

const controlCss = css`
  cursor: pointer;
  color: ${Colors.GRAY1};

  &:not(.disabled):hover {
    background-color: ${Colors.LIGHT_GRAY4};
  }

  &.disabled {
    cursor: not-allowed;
    color: ${Colors.GRAY5};
  }
`;

const InstructionItemMovementControls: React.FC<IInstructionItemMovementControlsProps> = ({
  onMoveDown,
  onMoveUp,
  className,
}) => {
  const isUpDisabled = onMoveUp == null;
  const isDownDisabled = onMoveDown == null;
  return (
    <div className={`flex flex-col py-2 ${className ?? ''}`}>
      <div css={controlCss} className={isUpDisabled ? 'disabled' : undefined}>
        <Icon icon={IconNames.CARET_UP} onClick={onMoveUp} />
      </div>
      <div css={controlCss} className={isDownDisabled ? 'disabled' : undefined}>
        <Icon icon={IconNames.CARET_DOWN} onClick={onMoveDown} />
      </div>
    </div>
  );
};

export default InstructionItemMovementControls;
