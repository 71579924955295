/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import { plural, t, Trans } from '@lingui/macro';
import { ProjectsFolder } from '../../common/types';
import { useCurrCallback } from '../../lib/utils';
import useActionLogger from '../hooks/use_action_logger';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import i18n from '../../i18n';
import { folderSelectOptionRenderer } from './helpers';
import { BUTTON_TEXT } from '@blueprintjs/core/lib/esm/common/classes';

const FolderSelect = Select.ofType<ProjectsFolder | null>();

const folderButtonCss = css`
  .${BUTTON_TEXT} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface IMoveProjectsToFolderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  folders: ProjectsFolder[];
  projectIds: string[];
  onConfirm: (folderId: string | null) => void;
  folderId?: string;
}

const getInitialState = (folders: ProjectsFolder[], folderId?: string): ProjectsFolder | null =>
  folders.find((folder) => folder.id === folderId) ?? null;

const MoveProjectsToFolderDialog: React.FC<IMoveProjectsToFolderDialogProps> = ({
  isOpen,
  onClose,
  folders,
  projectIds,
  onConfirm,
  folderId,
}) => {
  const insertActionLog = useActionLogger();
  const [newFolder, setNewFolder] = useState<ProjectsFolder | null>(
    getInitialState(folders, folderId)
  );

  const handleClose = useCallback(() => {
    setNewFolder(getInitialState(folders, folderId));
    onClose();
  }, [onClose, setNewFolder, folders, folderId]);

  const handleMoveProjects = useCallback(() => {
    onConfirm(newFolder?.id ?? null);
    insertActionLog('moved projects to the folder', {
      newFolder,
      projectIds,
    });
    handleClose();
  }, [insertActionLog, projectIds, onConfirm, newFolder, handleClose]);

  const renderFolderSelectOption = useCurrCallback(folderSelectOptionRenderer, []);

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={isOpen}
      onClose={onClose}
      title={i18n._(
        plural({
          value: projectIds.length,
          one: 'Move project to folder',
          other: 'Move # projects to folder',
        })
      )}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={i18n._(
            plural({
              value: projectIds.length,
              one: 'Select a folder for this project',
              other: 'Select a folder for these # projects',
            })
          )}
          labelFor="projectFolder"
        >
          <FolderSelect
            fill
            filterable={false}
            items={folders}
            itemRenderer={renderFolderSelectOption(newFolder)}
            onItemSelect={(item) => setNewFolder(item)}
          >
            <Button
              fill
              css={folderButtonCss}
              alignText="left"
              rightIcon={IconNames.CARET_DOWN}
              text={newFolder ? newFolder.name : `${i18n._(t`Choose the folder`)}...`}
            />
          </FolderSelect>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button large text={<Trans>Close</Trans>} onClick={onClose} />
          <Button
            large
            text={i18n._(
              plural({
                value: projectIds.length,
                one: 'Move project',
                other: 'Move # projects',
              })
            )}
            intent={Intent.SUCCESS}
            onClick={handleMoveProjects}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default MoveProjectsToFolderDialog;
