import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { loader } from 'graphql.macro';
import { get, isEmpty, memoize } from 'lodash/fp';
import { useCallback, useEffect, useState } from 'react';
import {
  DecisionFilter,
  EMPTY_ORDERED_BY,
  ORDER_BY_RANK_AND_UPDATED_AT,
} from '../../apollo/screening_state';
import { StageType } from '../../common/types';

const SetReferencesListOrderedByMutation = loader(
  '../../graphql/local/set_references_list_ordered_by.gql'
);

const GetReferencesListOrderedByQuery = gql`
  query {
    ScreeningState @client {
      orderedBy
    }
  }
`;

// There is a discrepancy between column names kept in local state (which are used in gql queries
// and within pg functions) and table column ids used in table layouts. This helper translates
// state's column name to corresponding table column id.
function stateColumnToTableColumnId(stateColumn: string) {
  switch (stateColumn) {
    case 'decision':
      return 'status';
    case 'last_changed':
      return 'lastChanged';
    case 'document_type':
      return 'documentType';
    case 'first_author':
      return 'firstAuthorsLastName';
    case 'reference_number':
      return 'referenceNumber';
    case 'accession_number':
      return 'accessionNumber';
    case 'stage_comment':
      return 'comment';
    default:
      return stateColumn;
  }
}

export default function useReferencesListSort(stageType: StageType) {
  const [setListOrderedBy] = useMutation(SetReferencesListOrderedByMutation);
  const [sortedBy, setSortedBy] = useState<undefined | { columnId: string; asc: boolean }>();
  const apolloClient = useApolloClient();

  const handleSortBy = useCallback(
    (columnId: string, asc: boolean) => {
      const order = asc ? 'asc' : 'desc';
      let orderedBy: object;

      switch (columnId) {
        case 'status':
          orderedBy = {
            column: 'decision',
            order,
          };
          break;
        case 'lastChanged':
          orderedBy = {
            column: 'last_changed',
            order,
          };
          break;
        case 'documentType':
          orderedBy = {
            column: 'document_type',
            order,
          };
          break;
        case 'firstAuthorsLastName':
          orderedBy = {
            column: 'first_author',
            order,
          };
          break;
        case 'year':
          orderedBy = {
            column: 'year',
            order,
          };
          break;
        case 'title':
          orderedBy = {
            column: 'title',
            order,
          };
          break;
        case 'referenceNumber':
          orderedBy = {
            column: 'reference_number',
            order,
          };
          break;
        case 'accessionNumber':
          orderedBy = {
            column: 'accession_number',
            order,
          };
          break;
        case 'comment':
          orderedBy = {
            column: 'stage_comment',
            order,
          };
          break;
        case 'language':
          orderedBy = {
            column: 'language',
            order,
          };
          break;
        default:
          orderedBy = EMPTY_ORDERED_BY;
      }

      setListOrderedBy({ variables: { orderedBy } });
      setSortedBy({ columnId, asc });
    },
    [setListOrderedBy, setSortedBy]
  );

  const resetSortedBy = useCallback(() => {
    setSortedBy(undefined);
  }, [setSortedBy]);

  const handleClearSort = useCallback(
    memoize((decisionFilter: DecisionFilter) => () => {
      setListOrderedBy({
        variables: {
          orderedBy: decisionFilter === 'all' ? ORDER_BY_RANK_AND_UPDATED_AT : EMPTY_ORDERED_BY,
        },
      });
      resetSortedBy();
    }),
    [setListOrderedBy, resetSortedBy]
  );

  // when mounted, update the sortedBy value with orderedBy value from screening state
  useEffect(() => {
    apolloClient
      .query({
        query: GetReferencesListOrderedByQuery,
      })
      .then((response) => {
        const orderedBy = get('ScreeningState.orderedBy', response.data);

        if (!isEmpty(orderedBy)) {
          setSortedBy({
            columnId: stateColumnToTableColumnId(orderedBy.column),
            asc: orderedBy.order === 'asc',
          });
        }
      });
  }, []);

  return {
    sortedBy,
    resetSortedBy,
    onSortBy: handleSortBy,
    onClearSort: handleClearSort,
  };
}
