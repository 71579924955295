/** @jsx jsx */
/* FIXME: once T5240 is implemented this component (and all related ones) will become obsolete */
import { css, jsx } from '@emotion/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Colors, Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Trans } from '@lingui/macro';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import {
  __,
  assign,
  compose,
  find,
  findIndex,
  get,
  propEq,
  reject,
  some,
  isEmpty,
  map,
} from 'lodash/fp';
import React, { useCallback, useState, useMemo } from 'react';
import {
  DomainData,
  DomainItem,
  InclusionExclusionCriteriaFormData,
  Question,
  ScreeningTag,
  VariableData,
} from '../../common/types';
import {
  createDomain,
  createVariable,
  updateDomain,
  updateDomainVariable,
} from '../../lib/criteria_utils';
import { uid, updateArrayItem } from '../../lib/utils';
import ErrorScreen from '../common/error_screen';
import PageContent from '../common/page_content';
import ScreenHeader from '../common/screen_header';
import Domain from './domain';
import DomainEditDialog from './domain_edit_dialog';
import QuestionEdit from './question_edit';
import QuestionTypeEdit from './question_type_edit';
import VariableEditDialog, { TVariableEditData } from './variable_edit_dialog';
import LoadCriteriaDialog from './load_criteria_dialog';
import SaveCriteriaDialog from './save_criteria_dialog';
import AppToaster from '../../lib/toaster';
import { getStageStudyCounts } from '../../lib/study_helpers';
import useActionLogger from '../hooks/use_action_logger';
import ScreeningTags from './screening_tags';

const UpdateQuestionMutation = loader('../../graphql/update_question.gql');
const InsertQuestionMutation = loader('../../graphql/insert_question.gql');

const UpdateFormMutation = gql`
  mutation UpdateForm($id: uuid!, $input: form_set_input!) {
    update_form_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      form
    }
  }
`;

const SaveFormTemplateMutation = gql`
  mutation SaveFormTemplate($template: form_template_insert_input!) {
    insert_form_template_one(object: $template) {
      id
    }
  }
`;

const GetDomainsProjectQuestionAndFormQuery = gql`
  query GetDomainsProjectQuestionAndFormQuery($id: uuid!, $stageId: uuid!) {
    templates: form_template {
      id
      name
      template
    }
    domains: form_domains_and_variables {
      domain
      variables
    }
    project: project_by_pk(id: $id) {
      id
      stages(where: { id: { _eq: $stageId } }) {
        id
        studies_by_status {
          inclusion_status
          count
        }
      }
      questions {
        id
        question
        type
        forms(where: { stage_id: { _eq: $stageId } }) {
          id
          template_id
          form
        }
      }
    }
  }
`;

export type QuestionData = Pick<Question, 'id' | 'question' | 'type'>;

export const domainsListCss = css`
  background-color: ${Colors.LIGHT_GRAY4};

  .domains-list-item {
    border-bottom: 1px solid ${Colors.GRAY4};
  }
`;

interface ICriteriaProps {
  projectId: string;
  stageId: string;
}

const TiAbCriteria: React.FC<ICriteriaProps> = ({ projectId, stageId }) => {
  const insertActionLog = useActionLogger();
  const [editingDomain, setEditingDomain] = useState<
    null | (Pick<DomainData, 'name' | 'exclusionButtonLabel' | 'exclusionCode'> & { id?: string })
  >(null);
  const [editingVariable, setEditingVariable] = useState<null | { id?: string; domainId: string }>(
    null
  );
  const [loadCriteriaDialogOpen, setLoadCriteriaDialogOpen] = useState(false);
  const [saveCriteriaDialogOpen, setSaveCriteriaDialogOpen] = useState(false);

  const [saveFormTemplate] = useMutation(SaveFormTemplateMutation);
  const [updateForm, { loading: updatingForm }] = useMutation(UpdateFormMutation);
  const [updateQuestion, { loading: updatingQuestion }] = useMutation(UpdateQuestionMutation);
  const [createQuestion, { loading: creatingQuestion }] = useMutation(InsertQuestionMutation);
  const { data, loading, error } = useQuery(GetDomainsProjectQuestionAndFormQuery, {
    pollInterval: 1000,
    variables: { id: projectId, stageId },
  });
  const templates = get('templates', data) ?? [];
  const templateNames = useMemo(() => map('name', templates), [templates]);
  // one state per project in GBA (picking the first one)
  const stage = get('project.stages[0]', data);
  // one question per project in GBA (picking the first one)
  const questionData: QuestionData | null = get('project.questions[0]', data) ?? null;
  // one form per question in GBA (picking the first one)
  const formData = questionData ? get('forms[0]', questionData) : null;
  const tags = get('form.tags', formData);
  const domains = get('form.domains', formData) ?? [];
  const formTemplateId = get('template_id', formData);
  const formId = get('id', formData);
  const allDomains: DomainItem[] = get('domains', data) ?? [];
  const formTemplateName = compose(get('name'), find(propEq('id', formTemplateId)))(templates);

  const studiesDistributed = useMemo(() => {
    const studyCounts = stage ? getStageStudyCounts(stage) : false;
    return Boolean(studyCounts && studyCounts.total > 0);
  }, [stage]);

  const updateDomains = useCallback(
    async (updater: (domains: DomainData[]) => DomainData[]) => {
      if (formData == null) return;
      const { form, id } = formData;

      const updatedDomains = updater(form.domains ?? []);

      return updateForm({
        variables: {
          id,
          input: {
            form: {
              ...form,
              domains: updatedDomains,
            },
          },
        },
      });
    },
    [formData, updateForm]
  );

  const saveUpdatedQuestion = useCallback(
    (id, data) => {
      return updateQuestion({
        variables: {
          id,
          data,
        },
      });
    },
    [updateQuestion]
  );

  // TODO: Once D5637 is landed this should no longer be needed
  const createNewQuestion = useCallback(
    (initialData: { question?: string; type?: string }) => {
      return createQuestion({
        variables: {
          question: {
            question: initialData.question ?? '',
            type: initialData.type ?? '',
            project_id: projectId,
            forms: {
              data: [
                {
                  stage_id: stageId,
                },
              ],
            },
          },
        },
      }).then(({ data }) => {
        const formId = get('insert_question_one.forms[0].id', data);
        const id = get('insert_question_one.id', data);

        return updateQuestion({
          variables: {
            id,
            data: { current_form_id: formId },
          },
        }).then(() => insertActionLog('created question', { id, data, current_form_id: formId }));
      });
    },
    [stageId, projectId, insertActionLog, updateQuestion, createQuestion]
  );

  const saveQuestionData = useCallback(
    (data): Promise<any> => {
      return questionData == null
        ? createNewQuestion(data)
        : saveUpdatedQuestion(questionData.id, data);
    },
    [questionData, saveUpdatedQuestion, createNewQuestion]
  );

  const handleSaveQuestion = useCallback(
    (question: string) => {
      // TODO: adding optimistic update might be needed, if UI update is slow
      return saveQuestionData({ question }).then(() =>
        insertActionLog('changed question text', { id: questionData?.id, question })
      );
    },
    [questionData, saveQuestionData, insertActionLog]
  );

  const handleSaveQuestionType = useCallback(
    (type: string) => {
      // TODO: adding optimistic update might be needed, if UI update is slow
      return saveQuestionData({ type }).then(() =>
        insertActionLog('changed question type', { id: questionData?.id, type })
      );
    },
    [questionData, saveQuestionData, insertActionLog]
  );

  const handleDomainEdit = useCallback(
    ({ id, name, exclusionButtonLabel, exclusionCode }) => {
      setEditingDomain({ id, name, exclusionButtonLabel, exclusionCode });
    },
    [setEditingDomain]
  );

  const handleDomainDelete = useCallback(
    (id) => {
      updateDomains((domains) => reject(propEq('id', id), domains)).then(() =>
        insertActionLog('deleted a domain', { id })
      );
    },
    [updateDomains, insertActionLog]
  );

  const handleEditDomainVariable = useCallback(
    (domainId: string, id?: string) => {
      setEditingVariable({ domainId, id });
    },
    [setEditingVariable]
  );

  const handleVariableDelete = useCallback(
    (domainId: string, id: string) => {
      updateDomains(
        updateDomain(domainId, (domain) => ({
          ...domain,
          variables: reject(propEq('id', id), domain.variables),
        }))
      ).then(() => insertActionLog('deleted a variable', { domainId, variableId: id }));
    },
    [updateDomains, insertActionLog]
  );

  const handleSaveDomain = useCallback(
    (domainData: Partial<DomainData>) => {
      const domainId = editingDomain?.id ?? uid();

      return updateDomains((domains) => {
        const existingDomainIdx = findIndex(propEq('id', domainId), domains);

        return existingDomainIdx === -1
          ? [...domains, createDomain(domainData)]
          : updateArrayItem(existingDomainIdx, assign(__, domainData), domains);
      }).then(() => insertActionLog('saved domain', { domainData, domainId }));
    },
    [updateDomains, editingDomain, insertActionLog]
  );

  const handleUpdateVariableData = useCallback(
    (domainId: string, variableId: string, variableData: Partial<VariableData>) => {
      return updateDomains(
        updateDomain(domainId, updateDomainVariable(variableId, assign(__, variableData)))
      ).then(() =>
        insertActionLog('updated variable data', { domainId, variableId, variableData })
      );
    },
    [updateDomains, insertActionLog]
  );

  const handleTagsUpdate = useCallback(
    (updatedTags: ScreeningTag[]) => {
      if (formData == null) return;
      const { form, id } = formData;
      return updateForm({
        variables: {
          id,
          input: {
            form: { ...form, tags: updatedTags },
          },
        },
      });
    },
    [formData, updateForm]
  );

  const handleSaveVariable = useCallback(
    (variableData: TVariableEditData) => {
      if (editingVariable == null) return Promise.resolve();
      const { domainId, id } = editingVariable;

      return updateDomains(
        updateDomain(domainId, (domain) => {
          const existingVariableIdx = findIndex(propEq('id', id), domain.variables);

          return {
            ...domain,
            variables:
              existingVariableIdx === -1
                ? [...domain.variables, createVariable(variableData)]
                : updateArrayItem(
                    existingVariableIdx,
                    (variable) => ({
                      ...variable,
                      name: variableData.variableName,
                      instructions: variableData.instructions ? variable.instructions ?? '' : null,
                      keywords: variableData.keywords
                        ? variable.keywords ?? { included: [], excluded: [] }
                        : null,
                    }),
                    domain.variables
                  ),
          };
        })
      ).then(() => insertActionLog('saved variable', { variableData, editingVariable }));
    },
    [editingVariable, updateDomains, insertActionLog]
  );

  const closeAddDomainDialog = useCallback(() => {
    setEditingDomain(null);
  }, [setEditingDomain]);

  const getVariableData = ({ id, domainId }: { id?: string; domainId: string }) => {
    if (id) {
      const domain = find(propEq('id', domainId), domains);
      const variable = find(propEq('id', id), domain!.variables);

      return {
        id,
        variableName: variable!.name,
        keywords: variable!.keywords != null,
        instructions: variable!.instructions != null,
      };
    } else {
      return {
        id,
        variableName: '',
        keywords: true,
        instructions: true,
      };
    }
  };

  const domainExists = useCallback(
    (domainName: string) => {
      return some(propEq('name', domainName), domains);
    },
    [domains]
  );

  const handleLoadSet = useCallback(
    async (templateId: string, form: InclusionExclusionCriteriaFormData) => {
      if (formId == null) return;

      updateForm({
        variables: {
          id: formId,
          input: {
            form,
            template_id: templateId,
          },
        },
      })
        .then(() => {
          insertActionLog('criteria set loaded', { id: formId, templateId });
          AppToaster.show({
            message: <Trans>Criteria set loaded</Trans>,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          insertActionLog('error loading criteria set', { id: formId, templateId, err });
          AppToaster.show({
            message: <Trans>Error loading criteria set: {err.message}</Trans>,
            intent: Intent.WARNING,
          });
        });
    },
    [updateForm, formId, insertActionLog]
  );

  const handleSaveSet = useCallback(
    async (templateName: string) => {
      saveFormTemplate({
        variables: {
          template: {
            name: templateName,
            template: { domains, tags },
          },
        },
      })
        .then(() => {
          insertActionLog('criteria set saved', { templateName, template: { domains, tags } });
          AppToaster.show({
            message: <Trans>Criteria set saved</Trans>,
            intent: Intent.SUCCESS,
          });
        })
        .catch((err) => {
          insertActionLog('error saving criteria set', {
            templateName,
            template: { domains, tags },
            err,
          });
          AppToaster.show({
            message: <Trans>Error saving criteria set: {err.message}</Trans>,
            intent: Intent.WARNING,
          });
        });
    },
    [saveFormTemplate, domains, insertActionLog, tags]
  );

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <PageContent>
      <ScreenHeader
        css={{ height: '40px' }}
        title={
          <span className="text-gray-600 text-xl mx-3">
            <Trans>Inclusion and Exclusion Criteria</Trans>
          </span>
        }
        rightElement={
          <div className="flex flex-row flex-no-wrap px-3">
            {formTemplateName && (
              <div className="flex items-center mr-2">
                <span className="text-gray-600">
                  <Trans>Template name</Trans>
                </span>
                <span className="mx-2">{formTemplateName}</span>
              </div>
            )}
            <Button
              className="mr-3"
              text={<Trans>Load criteria set</Trans>}
              onClick={() => setLoadCriteriaDialogOpen(true)}
              disabled={studiesDistributed}
            />
            <Button
              text={<Trans>Save criteria set</Trans>}
              onClick={() => setSaveCriteriaDialogOpen(true)}
              disabled={isEmpty(formData)}
            />
            <LoadCriteriaDialog
              isOpen={loadCriteriaDialogOpen}
              onClose={() => setLoadCriteriaDialogOpen(false)}
              onLoad={handleLoadSet}
              currentFormTemplateId={formTemplateId}
              templates={templates}
            />
            <SaveCriteriaDialog
              isOpen={saveCriteriaDialogOpen}
              onClose={() => setSaveCriteriaDialogOpen(false)}
              onSave={handleSaveSet}
              existingSets={templateNames}
            />
          </div>
        }
      />
      {loading ? (
        <Spinner className="h-full" />
      ) : (
        <div className="flex flex-col h-full w-full overflow-auto" style={{ marginTop: '1px' }}>
          <div style={{ marginBottom: '1px' }}>
            <QuestionEdit
              question={questionData?.question}
              onSave={handleSaveQuestion}
              saving={updatingQuestion || creatingQuestion}
              readOnly={studiesDistributed}
            />
            <QuestionTypeEdit
              questionType={questionData?.type}
              onSave={handleSaveQuestionType}
              saving={updatingQuestion || creatingQuestion}
              readOnly={studiesDistributed}
            />
          </div>
          <div className="flex flex-col flex-1 items-center w-full" css={domainsListCss}>
            {domains.map((domain) => (
              <Domain
                data={domain}
                domains={allDomains}
                onEdit={handleDomainEdit}
                onDelete={handleDomainDelete}
                onEditVariable={handleEditDomainVariable}
                onDeleteVariable={handleVariableDelete}
                onUpdateVariableData={handleUpdateVariableData}
                saving={updatingForm}
                key={domain.id}
                readOnly={studiesDistributed}
              />
            ))}
            {!studiesDistributed && (
              <div className="w-64 bg-white my-4">
                <Button
                  className="h-full w-full"
                  outlined
                  large
                  icon={IconNames.ADD}
                  alignText="left"
                  onClick={() =>
                    setEditingDomain({ name: '', exclusionButtonLabel: '', exclusionCode: '' })
                  }
                  text={
                    <div className="text-center">
                      <Trans>Add new domain</Trans>
                    </div>
                  }
                  disabled={formData == null}
                />
                <DomainEditDialog
                  isOpen={editingDomain != null}
                  onClose={closeAddDomainDialog}
                  onApply={handleSaveDomain}
                  domainData={editingDomain}
                  domainExists={domainExists}
                  saving={updatingForm}
                />
                <VariableEditDialog
                  isOpen={editingVariable != null}
                  onClose={() => setEditingVariable(null)}
                  onApply={handleSaveVariable}
                  variableData={editingVariable ? getVariableData(editingVariable) : null}
                  saving={updatingForm}
                />
              </div>
            )}
          </div>
          <ScreeningTags
            tags={tags}
            onTagsUpdate={handleTagsUpdate}
            readOnly={studiesDistributed}
          />
        </div>
      )}
    </PageContent>
  );
};

export default TiAbCriteria;
