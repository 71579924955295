/** @jsx jsx **/
import { css, jsx } from '@emotion/core';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { compose, flatMap, map, uniq, get } from 'lodash/fp';
import { Card, Colors, Divider, H4 } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { Stage, StageType, User } from '../../../common/types';
import { useTheme } from '../../settings/theme_context';
import { UserAvatar } from '../../header2';
import StatsCount from '../stats_count';
import ProgressBar from '../../common/progress_bar';
import { getStageStudyCounts } from '../../../lib/study_helpers';

const StageUsersCss = css`
  flex: 0 0 180px;
`;

interface IDashboardStageCardProps {
  projectId: string;
  stage: Pick<
    Stage,
    | 'id'
    | 'name'
    | 'completed'
    | 'study_pools'
    | 'reference_count'
    | 'reference_with_pdf_count'
    | 'studies_by_status'
    | 'tasks_aggregate'
    | 'type'
  >;
}

const DashboardStageCard: React.FC<IDashboardStageCardProps> = ({ projectId, stage }) => {
  const history = useHistory();
  const { statusColors } = useTheme();

  const users = useMemo<(User | null)[]>(
    () =>
      compose(uniq, map('team_member.user'), flatMap('study_pool_team_members'))(stage.study_pools),
    [stage]
  );

  const studyCounts = useMemo(() => {
    if (stage.type === StageType.DataExtraction || !stage.tasks_aggregate) return undefined;
    return getStageStudyCounts(stage);
  }, [stage]);
  const conflictsCount = studyCounts?.conflicts ?? 0;

  const goToStage = useCallback(
    (stageId) => () => {
      history.push(['/projects', projectId, 'stages', stageId].join('/'));
    },
    [history, projectId]
  );

  const referencesCount = get('reference_count.aggregate.count', stage) ?? 0;
  const referencesWithPdfCount = get('reference_with_pdf_count.aggregate.count', stage) ?? 0;

  const stageActive = referencesCount > 0;

  return (
    <Card
      className="mb-4 p-0 shadow-none project-stage-card"
      interactive
      key={stage.id}
      onClick={goToStage(stage.id)}
    >
      <div className="flex items-center p-6">
        <H4 className="font-normal mb-0">{stage.name}</H4>
        <div
          className="ml-auto text-sm"
          css={{ color: stage.completed ? Colors.GREEN3 : Colors.DARK_GRAY5 }}
        >
          {stage.completed ? (
            <Trans>Completed</Trans>
          ) : stageActive ? (
            <Trans>Active</Trans>
          ) : (
            <Trans>Draft</Trans>
          )}
        </div>
      </div>
      <Divider className="mx-4 my-0" />
      <div className="flex justify-between items-center p-6">
        <div className="flex flex-row flex-wrap" css={StageUsersCss}>
          {users.map((user, idx) => (
            <UserAvatar key={user?.id ?? idx} user={user} />
          ))}
        </div>
        <div className="flex-grow flex flex-row flex-no-wrap items-center">
          <div className="flex items-center" css={{ width: '120px' }}>
            <StatsCount className="mr-3" count={referencesCount} />
            {![StageType.PreliminaryScreening, StageType.TitlesAbstractScreening].includes(
              stage.type
            ) && (
              <StatsCount
                className="mr-3"
                count={`(pdf: ${referencesWithPdfCount})`}
                showIcon={false}
              />
            )}
          </div>
          {stage.type !== StageType.DataExtraction && studyCounts && (
            <div className="flex items-center">
              <StatsCount
                className="mr-3"
                label={
                  <span className="uppercase" css={{ color: statusColors.acceptedPrimary }}>
                    <Trans>In</Trans>:
                  </span>
                }
                count={studyCounts.included}
              />
              <StatsCount
                className="mr-3"
                label={
                  <span className="uppercase" css={{ color: statusColors.rejectedPrimary }}>
                    <Trans>Out</Trans>:
                  </span>
                }
                count={studyCounts.excluded}
              />
              <div>
                <span
                  css={{ color: conflictsCount > 0 ? Colors.GOLD2 : undefined }}
                  className="mr-1"
                >
                  <Trans>Conflicts</Trans>:
                </span>
                {conflictsCount}
              </div>
            </div>
          )}
          {stage.type === StageType.DataExtraction && (
            <div className="flex items-center">
              {/* TODO: stats for DE */}
              <StatsCount
                count={0}
                label={
                  <span className="uppercase" css={{ color: Colors.ORANGE3 }}>
                    <Trans>In progress</Trans>:
                  </span>
                }
              />
              <StatsCount
                count={0}
                label={
                  <span className="uppercase" css={{ color: Colors.GREEN3 }}>
                    <Trans>Completed</Trans>:
                  </span>
                }
              />
            </div>
          )}
        </div>
      </div>
      <ProgressBar
        className="h-1"
        showIfTotal0
        studiesIn={studyCounts?.included ?? 0}
        studiesOut={studyCounts?.excluded ?? 0}
        total={studyCounts?.total ?? 0}
      />
    </Card>
  );
};

export default DashboardStageCard;
