/** @jsx jsx */
import React, { ReactNode } from 'react';
import { Colors, H1 } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { fancyScrollCss, lightGray5bg, maxContentWidth } from '../../common/styles';

interface IAdminPageContentWrapperProps {
  rightHeaderElement?: ReactNode;
  title: ReactNode;
}

const headerCss = css`
  color: ${Colors.DARK_GRAY5};
`;

const AdminPageContentWrapper: React.FC<IAdminPageContentWrapperProps> = ({
  children,
  rightHeaderElement,
  title,
}) => (
  <div className="h-full w-full flex flex-col">
    <div className="flex flex-row mb-8 px-8 py-6 items-center justify-between" css={lightGray5bg}>
      <H1 className="font-normal m-0" css={headerCss}>
        {title}
      </H1>
      {rightHeaderElement}
    </div>
    <div className="w-full overflow-auto h-full" css={fancyScrollCss}>
      <div className="mx-auto w-full h-full" css={maxContentWidth}>
        {children}
      </div>
    </div>
  </div>
);

export default AdminPageContentWrapper;
