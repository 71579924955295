import { Button, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ApolloError } from 'apollo-client';
import React from 'react';

type ErrorScreenProps = {
  error: ApolloError;
  retry?: () => void;
};

const ErrorScreen: React.FC<ErrorScreenProps> = ({ retry }) => (
  <NonIdealState
    icon={IconNames.ERROR}
    title="There was an error fetching the data"
    action={retry ? <Button onClick={retry}>Retry</Button> : undefined}
  />
);

export default ErrorScreen;
