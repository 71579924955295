import React from 'react';
import { Tag } from '@blueprintjs/core';
import { Trans } from '@lingui/macro';
import { InclusionStatus } from '../../common/types';
import { inclusionStatusToIntent } from '../../lib/references';
import { getInclusionStatusLabel } from '../../lib/task_helpers';

interface IDecisionTagProps {
  className?: string;
  decision?: InclusionStatus;
  decisionReason?: string | null;
  minimal?: boolean;
}

const DecisionTag: React.FC<IDecisionTagProps> = ({
  className,
  decision,
  decisionReason,
  minimal,
}) => {
  const decisionLabel =
    decision == null ? (
      <Trans>Not evaluated</Trans>
    ) : (
      decisionReason ?? getInclusionStatusLabel(decision)
    );

  return (
    <Tag className={className} minimal={minimal} intent={inclusionStatusToIntent(decision)}>
      {decisionLabel}
    </Tag>
  );
};

export default DecisionTag;
