/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Checkbox, Intent, Text } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans } from '@lingui/macro';
import { useI18n } from '../../../lib/utils';
import { useKeycloak } from '../../../keycloak';
import ScreenHeader from '../../common/screen_header';
import { Fragment } from 'react';
import { AccountType } from '../../../common/types';
import i18n from '../../../i18n';

export const SHORT_TITLES = {
  dr: i18n._(t`Dr.`),
  prof: i18n._(t`Prof. `),
  'prof dr': i18n._(t`Prof. Dr.`),
};

interface IUserDetailsHeaderProps {
  onClose: () => void;
  userTitle: string;
  salutation: string;
  userName: string;
  userRole: AccountType;
  // excludedFromAutoDelete: boolean;
  // handleDeletionRoutineExclude: (e: any) => void;
  onUserEdit: () => void;
  onUserDelete: () => void;
}

const UserDetailsHeader: React.FC<IUserDetailsHeaderProps> = ({
  onClose,
  userName,
  userTitle,
  userRole,
  salutation,
  // excludedFromAutoDelete,
  // handleDeletionRoutineExclude,
  onUserEdit,
  onUserDelete,
}) => {
  const i18n = useI18n();
  const {
    user: { role: currentUserRole },
  } = useKeycloak();
  return (
    <ScreenHeader
      backIcon={IconNames.ARROW_LEFT}
      onBack={onClose}
      title={
        <Fragment>
          <span className="text-3xl text-gray-600 mx-3 py-3 capitalize">
            {[salutation, SHORT_TITLES[userTitle]].join(' ')}
          </span>
          <Text ellipsize className="text-3xl flex-shrink flex-auto py-3 w-20">
            {userName}
          </Text>
        </Fragment>
      }
      rightElement={
        (userRole !== AccountType.ItAdmin || currentUserRole === AccountType.ItAdmin) && (
          <Fragment>
            {/* {userRole === AccountType.User && currentUserRole === AccountType.ItAdmin && (
              <Checkbox
                className="m-0"
                label={i18n._(t`Exclude user from the deletion routine`)}
                checked={excludedFromAutoDelete}
                onChange={handleDeletionRoutineExclude}
              />
            )} */}
            <Button
              className="ml-3 px-8 flex-shrink-0"
              text={<Trans>Edit profile</Trans>}
              onClick={onUserEdit}
            />
            <Button
              className="mx-3 px-8 flex-shrink-0"
              text={<Trans>Delete user</Trans>}
              intent={Intent.DANGER}
              onClick={onUserDelete}
              outlined
            />
          </Fragment>
        )
      }
    />
  );
};

export default UserDetailsHeader;
