/** @jsx jsx */
import {
  Colors,
  Spinner,
  NonIdealState,
  Icon,
  Drawer,
  Classes,
  Button,
  Tabs,
  TabId,
  Tab,
  TabProps,
  Tag,
} from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import NavAndDecisionControls from '../navigation_and_decision_controls';
import { TExclusionReason, TReferenceData, TUpdateTaskResultArgs } from '..';
import {
  TActiveKeywordFilters,
  DecisionFilter,
  TActiveAttributeFilters,
  YearFilters,
  TActiveScreeningTagFilters,
} from '../../../apollo/screening_state';
import ActiveFiltersBar from '../active_filters_bar';
import {
  SearchAndDesiredUndesiredKeywords,
  EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS,
} from '../../../lib/criteria_utils';
import Header from './header';
import StudyData from './study_data';
import {
  getTaskResultControlId,
  TTaskCounts,
  getReferenceComment,
  useScreeningHistory,
  getTaskInclusionStatus,
  getTaskCountForDecisionFilter,
  TTaskResult,
} from '../../../lib/task_helpers';
import ReferenceCard from './reference_card';
import { compose, filter, find, get, isEmpty, isNil, map, propEq } from 'lodash/fp';
import EmptyState from './empty_state';
import { useKeycloak } from '../../../keycloak';
import {
  DomainData,
  InclusionStatus,
  ScreeningListMode,
  ScreeningTag,
  Stage,
} from '../../../common/types';
import ScreeningTags from '../screening_tags';
import { useSetState } from '../../../lib/utils';
import { IconNames } from '@blueprintjs/icons';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import CriteriaScreener from '../../criteria/criteria_screener';
import ReferencesHUDList from './references_hud_list';
import CommentBox from '../../references/comment_box';
import ReferenceCommentsByStage from '../../references/reference_comments_by_stage';
import { ReferenceCommentFragmentType } from '../../../graphql/reference_comment_fragment';
import { useScreeningFilters } from '../../hooks/use_screening_filters';
import {
  CollapsibleSection,
  CollapsibleSections,
  useCollapsibleSections,
} from '../../common/collapsible_sections';

const sidebars = css`
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 350px;
  overflow: auto;
`;

const mainArea = css`
  flex-grow: 3;
  overflow: hidden;
  max-width: 640px;
  margin: 5px auto;
  margin-bottom: 0;
`;

const SetActiveAndSelectedReferencesMutation = loader(
  '../../../graphql/local/set_active_and_selected_references.gql'
);

export interface ScreeningHistoryLength {
  undoLength: number;
  redoLength: number;
}

enum FocusModeTabIds {
  ReferenceList = 'focus:references_list',
  BibliographicData = 'focus:bibliographic_data',
}
interface ITiAbFocusModeState {
  selectedTabId: TabId;
  criteriaOpen: boolean;
}

const TI_AB_FOCUS_MODE_INITIAL_STATE: ITiAbFocusModeState = {
  selectedTabId: FocusModeTabIds.BibliographicData,
  criteriaOpen: false,
};

interface ITitleAndAbstractScreeningProps {
  projectId: string;
  handleActiveReferenceSelect: () => void;
  handleInclude: () => void;
  handleExclude: (reasonCode: string) => void;
  handlePostpone: () => void;
  handleUnreview: () => void;
  onGoToReferencesList: () => void;
  referencesLength: number;
  shiftActiveReference: (direction: -1 | 1) => void;
  taskCounts: TTaskCounts;
  exclusionReasons: TExclusionReason[];
  activeKeywordFilters: TActiveKeywordFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activeYearFilters: YearFilters;
  searchPhraseTokens: string[];
  filtersApplied: boolean;
  keywordsData: SearchAndDesiredUndesiredKeywords;
  highlightsVisible: boolean;
  onToggleHighlights: () => void;
  onUpdateComment: (data: { comment: string; id?: string }) => void;
  onDeleteComment: (commentId: string) => void;
  onScreeningTagsUpdate: (newTags: string[]) => void;
  decisionFilter: DecisionFilter;
  searchingReferences: boolean;
  formId: string;
  formDomains: DomainData[];
  formTags: ScreeningTag[];
  handleTaskResultUpdate: (payload: TUpdateTaskResultArgs) => Promise<any>;
  fetchAdditionalReferences: () => void;
  references: readonly TReferenceData[];
  screeningListMode: ScreeningListMode;
  unsentTasksCount: number;
  closeFocusMode: () => void;
  stageId: string;
  projectStages: Pick<Stage, 'id' | 'name' | 'order_number'>[];
  reference?: TReferenceData;
  activeReferenceResult?: TTaskResult;
  onLoadMoreReferences?: () => void;
  loadingCounts?: boolean;
  savingResult?: boolean;
}

const TitleAndAbstractScreening: FC<ITitleAndAbstractScreeningProps> = ({
  projectId,
  handleActiveReferenceSelect,
  handleExclude,
  handleInclude,
  handlePostpone,
  handleUnreview,
  onGoToReferencesList,
  referencesLength,
  shiftActiveReference,
  reference,
  taskCounts,
  exclusionReasons,
  activeKeywordFilters,
  activeDecisionCodeFilters,
  activeDocumentTypeFilters,
  activeScreeningTagFilters,
  activeYearFilters,
  searchPhraseTokens,
  filtersApplied,
  keywordsData,
  highlightsVisible,
  onToggleHighlights,
  onUpdateComment,
  onDeleteComment,
  activeReferenceResult,
  decisionFilter,
  searchingReferences,
  formId,
  formDomains,
  formTags,
  handleTaskResultUpdate,
  fetchAdditionalReferences,
  onScreeningTagsUpdate,
  references,
  onLoadMoreReferences,
  screeningListMode,
  unsentTasksCount,
  closeFocusMode,
  loadingCounts,
  stageId,
  projectStages,
  savingResult,
}) => {
  const { clearFilters, resetYearsFilter } = useScreeningFilters();
  const referenceFocusModeTabs: Pick<TabProps, 'id' | 'title'>[] =
    decisionFilter === 'to_review'
      ? [
          {
            id: FocusModeTabIds.BibliographicData,
            title: <Trans>Bibliographic data</Trans>,
          },
        ]
      : [
          {
            id: FocusModeTabIds.ReferenceList,
            title: <Trans>Reference list</Trans>,
          },
          {
            id: FocusModeTabIds.BibliographicData,
            title: <Trans>Bibliographic data</Trans>,
          },
        ];
  const {
    user: { id: userId },
  } = useKeycloak();
  const {
    undo: onUndoScreeningDecision,
    redo: onRedoScreeningDecision,
    getLength: getScreeningHistoryLength,
    push: addToScreeningHistory,
  } = useScreeningHistory(userId, formId, formDomains, handleTaskResultUpdate);
  const { expandedSections, toggleSection } = useCollapsibleSections(['criteria', 'comments']);
  const [setActiveAndSelectedReferences] = useMutation(SetActiveAndSelectedReferencesMutation);
  const [state, setState] = useSetState<ITiAbFocusModeState>(TI_AB_FOCUS_MODE_INITIAL_STATE);
  const { selectedTabId, criteriaOpen } = state;
  const myCurrentComment = reference
    ? getReferenceComment(reference, activeReferenceResult)
    : undefined;
  const isCompletedListMode = screeningListMode === ScreeningListMode.Completed;

  const currentStage = useMemo(() => find(propEq('id', stageId), projectStages), [projectStages]);

  const previousStagesIds: string[] = useMemo(
    () =>
      compose(
        map('id'),
        filter(
          (stage: Pick<Stage, 'id' | 'name' | 'order_number'>) =>
            (stage.order_number ?? 0) < (currentStage?.order_number ?? 0)
        )
      )(projectStages),
    [projectStages, currentStage]
  );

  // get only comments from the previous stages or the current stage admin comment
  const previousComments: ReferenceCommentFragmentType[] = useMemo(
    () =>
      compose(
        filter(
          (referenceComment: ReferenceCommentFragmentType) =>
            previousStagesIds.includes(referenceComment.stage_id ?? '') ||
            (referenceComment.stage_id === stageId && !referenceComment.task_id)
        ),
        get('reference_comments')
      )(reference),
    [reference, previousStagesIds, stageId]
  );

  const handleEraseInclusionDecision = useCallback(() => {
    if (reference == null) return;
    const { inclusionStatus: prevInclusionStatus, exclusionReasonId: prevExclusionCode } =
      getTaskInclusionStatus(reference, formId, userId, exclusionReasons);

    addToScreeningHistory({
      reference,
      previousDecision: {
        inclusionStatus: prevInclusionStatus,
        exclusionCode: prevExclusionCode,
      },
      currentDecision: { inclusionStatus: null, exclusionCode: null },
    });
    handleUnreview();
  }, [
    reference,
    addToScreeningHistory,
    handleInclude,
    exclusionReasons,
    formId,
    userId,
    handleUnreview,
  ]);

  const includeStudy = useCallback(() => {
    if (reference == null) return;
    const { inclusionStatus: prevInclusionStatus, exclusionReasonId: prevExclusionCode } =
      getTaskInclusionStatus(reference, formId, userId, exclusionReasons);

    const isIncluded = prevInclusionStatus === InclusionStatus.Included;
    if (isIncluded) return;

    addToScreeningHistory({
      reference,
      previousDecision: {
        inclusionStatus: prevInclusionStatus,
        exclusionCode: prevExclusionCode,
      },
      currentDecision: {
        inclusionStatus: isIncluded ? null : InclusionStatus.Included,
        exclusionCode: null,
      },
    });

    handleInclude();
  }, [reference, addToScreeningHistory, handleInclude, exclusionReasons, formId, userId]);

  const excludeStudy = useCallback(
    (reasonCode: string) => {
      if (reference == null) return;
      const { inclusionStatus: prevInclusionStatus, exclusionReasonId: prevExclusionCode } =
        getTaskInclusionStatus(reference, formId, userId, exclusionReasons);

      const isExcludedWithSameReason =
        prevInclusionStatus === InclusionStatus.Excluded && prevExclusionCode === reasonCode;
      if (isExcludedWithSameReason) return;

      addToScreeningHistory({
        reference,
        previousDecision: {
          inclusionStatus: prevInclusionStatus,
          exclusionCode: prevExclusionCode,
        },
        currentDecision: {
          inclusionStatus: isExcludedWithSameReason ? null : InclusionStatus.Excluded,
          exclusionCode: isExcludedWithSameReason ? null : reasonCode,
        },
      });

      handleExclude(reasonCode);
    },
    [reference, addToScreeningHistory, handleExclude, exclusionReasons, userId, formId]
  );

  const handleUndo = () => {
    const undoneReference = onUndoScreeningDecision();

    if (undoneReference) {
      setActiveAndSelectedReferences({
        variables: { activeReference: undoneReference.id },
      });
    }
  };

  const handleReferenceSelect = useCallback(
    (referenceId) => {
      setActiveAndSelectedReferences({
        variables: { activeReference: referenceId },
      });
    },
    [setActiveAndSelectedReferences]
  );

  const handleRedo = () => {
    const redoneReference = onRedoScreeningDecision();
    if (redoneReference) {
      shiftActiveReference(1);
    }
  };

  const screeningHistoryLength: ScreeningHistoryLength = getScreeningHistoryLength();

  const selectedTagIds = activeReferenceResult?.result.tags;

  const handleChangeSelectedTab = useCallback(
    (selectedTabId: TabId) => setState({ selectedTabId }),
    [setState]
  );

  const renderPanel = useCallback(
    (tabId: TabId) => {
      if (isNil(reference)) return;
      switch (tabId) {
        case FocusModeTabIds.ReferenceList:
          return (
            <ReferencesHUDList
              decisionFilter={decisionFilter}
              activeReferenceId={reference?.id}
              referencesCount={getTaskCountForDecisionFilter(decisionFilter, taskCounts)}
              references={references}
              onReferenceSelect={handleReferenceSelect}
              onLoadMoreReferences={onLoadMoreReferences}
            />
          );
        case FocusModeTabIds.BibliographicData:
          return <StudyData reference={reference} />;
        default:
          return;
      }
    },
    [handleReferenceSelect, onLoadMoreReferences, decisionFilter, reference, references, taskCounts]
  );

  const handleMyCommentChange = (editedComment: string) => {
    if (editedComment != (myCurrentComment?.comment ?? '')) {
      if (isEmpty(editedComment)) {
        onDeleteComment(myCurrentComment!.id);
      } else {
        onUpdateComment({
          comment: editedComment,
          id: myCurrentComment?.id,
        });
      }
    }
  };

  useEffect(() => {
    referencesLength === 0 &&
      getTaskCountForDecisionFilter(decisionFilter, taskCounts) > 0 &&
      fetchAdditionalReferences();
  }, [fetchAdditionalReferences, referencesLength, decisionFilter, taskCounts]);

  // reset selected tab to bibliographic data when changing the decision filter to toReview
  useEffect(() => {
    if (decisionFilter === 'to_review' && selectedTabId !== FocusModeTabIds.BibliographicData) {
      setState({
        selectedTabId: FocusModeTabIds.BibliographicData,
      });
    }
  }, [setState, selectedTabId, decisionFilter]);

  return (
    <div className="h-full w-full flex flex-col mx-auto overflow-auto">
      <Header
        highlightsEnabled={highlightsVisible}
        onHighlightsToggle={onToggleHighlights}
        taskCounts={taskCounts}
        decisionFilter={decisionFilter}
        loading={loadingCounts}
        onUndoScreeningDecision={savingResult ? undefined : handleUndo}
        onRedoScreeningDecision={savingResult ? undefined : handleRedo}
        screeningHistoryLength={screeningHistoryLength}
        onOpenCriteria={() => setState({ criteriaOpen: true })}
        screeningListMode={screeningListMode}
      />
      <div
        className="h-full w-full flex flex-col overflow-auto"
        style={{ background: Colors.GRAY3 }}
      >
        {reference == null ? (
          searchingReferences ? (
            <NonIdealState icon={<Spinner />} title={<Trans>Loading references</Trans>} />
          ) : decisionFilter === 'to_review' &&
            taskCounts.postponed === 0 &&
            unsentTasksCount > 0 ? (
            <NonIdealState
              title={<Trans>All tasks have been completed!</Trans>}
              action={
                <Button
                  icon={IconNames.ARROW_LEFT}
                  text={<Trans>Reference list</Trans>}
                  onClick={closeFocusMode}
                />
              }
            />
          ) : (
            <NonIdealState
              icon={<Icon icon={IconNames.DOCUMENT} color={Colors.GRAY1} iconSize={30} />}
              title={<Trans>There are no references in selected category</Trans>}
            />
          )
        ) : referencesLength === 0 ? (
          <NavAndDecisionControls
            onlyHotkeys
            disabled={isCompletedListMode || savingResult}
            onToggleActiveReferenceSelect={handleActiveReferenceSelect}
            onInclude={includeStudy}
            onExclude={excludeStudy}
            onPostpone={handlePostpone}
            onUndoScreeningDecision={handleUndo}
            onRedoScreeningDecision={handleRedo}
            screeningHistoryLength={screeningHistoryLength}
            exclusionReasons={[]}
          >
            <EmptyState
              taskCounts={taskCounts}
              filtersApplied={filtersApplied}
              onResetReferencesSearch={clearFilters}
              onGoToReferencesList={onGoToReferencesList}
            />
          </NavAndDecisionControls>
        ) : (
          <Fragment>
            <div className="flex flex-1 flex-row overflow-auto">
              <div className="flex flex-col px-4 py-5 overflow-auto" css={sidebars}>
                <Tabs
                  selectedTabId={selectedTabId}
                  onChange={handleChangeSelectedTab}
                  renderActiveTabPanelOnly
                  className="h-full w-full flex flex-col overflow-auto text-white"
                >
                  {referenceFocusModeTabs.map((tab) => (
                    <Tab
                      key={tab.id}
                      id={tab.id}
                      title={
                        <span
                          className={`text-white ${selectedTabId === tab.id ? 'font-bold' : ''}`}
                        >
                          {tab.title}
                        </span>
                      }
                      panel={renderPanel(tab.id)}
                    />
                  ))}
                </Tabs>
              </div>
              <div css={mainArea} className="flex flex-row flex-no-wrap overflow-auto p-5">
                <ReferenceCard
                  reference={reference}
                  keywordsData={
                    highlightsVisible ? keywordsData : EMPTY_SEARCH_AND_DESIRED_UNDESIRED_KEYWORDS
                  }
                  exclusionReasons={exclusionReasons}
                  onEraseDecision={
                    isCompletedListMode || savingResult ? undefined : handleEraseInclusionDecision
                  }
                  formId={formId}
                />
              </div>
              <div className="flex flex-col overflow-hidden border-l border-white" css={sidebars}>
                <CollapsibleSections>
                  <CollapsibleSection
                    id="criteria"
                    isOpen={expandedSections.includes('criteria')}
                    onToggle={toggleSection}
                    title={<Trans>Criteria</Trans>}
                    titleClass="text-white px-4 py-1 text-xl border-t border-b border-white"
                    toggleColor={Colors.WHITE}
                  >
                    <NavAndDecisionControls
                      activeControl={
                        (activeReferenceResult &&
                          getTaskResultControlId(activeReferenceResult.result)) ||
                        undefined
                      }
                      disabled={isCompletedListMode || savingResult}
                      onToggleActiveReferenceSelect={handleActiveReferenceSelect}
                      onCloseFocusMode={closeFocusMode}
                      onShiftActiveReference={shiftActiveReference}
                      onInclude={includeStudy}
                      onExclude={excludeStudy}
                      onPostpone={handlePostpone}
                      onUndoScreeningDecision={handleUndo}
                      onRedoScreeningDecision={handleRedo}
                      screeningHistoryLength={screeningHistoryLength}
                      exclusionReasons={exclusionReasons}
                    />
                  </CollapsibleSection>
                  <CollapsibleSection
                    id="comments"
                    isOpen={expandedSections.includes('comments')}
                    onToggle={toggleSection}
                    title={
                      <div className="flex items-center">
                        <span css={{ lineHeight: '1.25rem' }}>
                          <Trans>Comments</Trans> (
                          {previousComments.length + (myCurrentComment ? 1 : 0)})
                        </span>{' '}
                        <Tag className="ml-2">{selectedTagIds?.length ?? 0}</Tag>
                      </div>
                    }
                    titleClass="text-white px-4 py-1 text-xl border-t border-b border-white"
                    toggleColor={Colors.WHITE}
                  >
                    <div className="px-4 py-6 border-b border-white">
                      <CommentBox
                        comment={myCurrentComment?.comment}
                        title={<Trans>My comment</Trans>}
                        onChange={isCompletedListMode ? undefined : handleMyCommentChange}
                      />
                    </div>
                    <div className="flex-1">
                      {isEmpty(previousComments) ? (
                        <NonIdealState
                          className="px-4 py-6"
                          title={
                            <span className="text-sm font-normal text-white">
                              <Trans>No comments have been added yet</Trans>
                            </span>
                          }
                        />
                      ) : (
                        <ReferenceCommentsByStage
                          className="px-4 text-white"
                          comments={previousComments}
                          stages={projectStages}
                        />
                      )}
                    </div>
                    {isEmpty(formTags) ? null : (
                      <div className="px-4 py-6 border-t border-white">
                        <ScreeningTags
                          tags={formTags}
                          selectedTagIds={selectedTagIds}
                          onTagsChange={onScreeningTagsUpdate}
                          readOnly={isCompletedListMode}
                        />
                      </div>
                    )}
                  </CollapsibleSection>
                </CollapsibleSections>
              </div>
            </div>
            <div>
              {filtersApplied && (
                <ActiveFiltersBar
                  screeningTags={formTags}
                  phraseTokens={searchPhraseTokens}
                  keywordFilters={activeKeywordFilters}
                  decisionCodeFilters={activeDecisionCodeFilters}
                  documentTypeFilters={activeDocumentTypeFilters}
                  screeningTagFilters={activeScreeningTagFilters}
                  yearsFilters={activeYearFilters}
                  onYearsFilterReset={resetYearsFilter}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <Drawer
        onClose={() => setState({ criteriaOpen: false })}
        isOpen={criteriaOpen}
        title={<Trans>Inclusion and Exclusion Criteria</Trans>}
        size="50%"
      >
        <div className={Classes.DRAWER_BODY}>
          <CriteriaScreener projectId={projectId} stageId={stageId} />
        </div>
      </Drawer>
    </div>
  );
};

export default TitleAndAbstractScreening;
