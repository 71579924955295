import { Divider } from '@blueprintjs/core';
import { Trans, Plural } from '@lingui/macro';
import React, { useCallback } from 'react';
import { ScreeningType, StudyDistribution } from '../../../common/types';
import ReferencesDistributionDialog from './references_distribution_dialog';
import useActionLogger from '../../hooks/use_action_logger';
import { TScreenerData } from '../../../lib/distribution_helpers';
import { TStudyToDistribute } from '../../../lib/study_helpers';

interface IReferencesDistributionProps {
  distributionDialogOpen: boolean;
  hasCriteria: boolean;
  onCloseDistributionDialog: () => void;
  onDistribute: (
    studiesPerMember: { [memberId: string]: number },
    distributionType: StudyDistribution,
    studies: TStudyToDistribute[]
  ) => Promise<any>;
  returnedReferences?: boolean;
  screeningType: ScreeningType;
  setSendingDistribution: React.Dispatch<React.SetStateAction<boolean>>;
  studies: TStudyToDistribute[];
  teamMembers: TScreenerData[];
}

const ReferencesDistribution: React.FC<IReferencesDistributionProps> = ({
  distributionDialogOpen,
  hasCriteria,
  onCloseDistributionDialog,
  onDistribute,
  returnedReferences,
  screeningType,
  setSendingDistribution,
  studies,
  teamMembers,
}) => {
  const insertActionLog = useActionLogger();

  const undistributed = studies.length;

  const handleDistribute = useCallback(
    (distribution, distributionType, studies) => {
      setSendingDistribution(true);
      onDistribute(distribution, distributionType, studies)
        .then(() =>
          insertActionLog('changed studies distribution type', {
            distribution,
            distributionType,
            studies,
          })
        )
        .finally(() => setSendingDistribution(false));
    },
    [onDistribute, insertActionLog, setSendingDistribution]
  );

  return (
    <div className="flex flex-col">
      <div className="px-6">
        <div className="font-bold text-base text-blue-600">
          {returnedReferences ? (
            <Trans>Returned References to distribute</Trans>
          ) : (
            <Trans>References to distribute</Trans>
          )}
        </div>
        <div className="text-xs text-gray-600 my-4">
          <Plural value={undistributed} one="There is" other="There are" />{' '}
          <span className="font-bold">
            <Plural value={undistributed} one="# reference" other="# references" />
          </span>{' '}
          <Trans>to be distributed among reviewers</Trans>
        </div>
      </div>
      {(screeningType.screeners_no > teamMembers.length || !hasCriteria) && (
        <React.Fragment>
          <Divider className="mx-0 my-6" />
          <div className="px-6 text-gray-600 text-xs">
            {screeningType.screeners_no > teamMembers.length ? (
              <Trans>
                Selected screening type requires at least{' '}
                <span className="font-bold">
                  <Plural value={screeningType.screeners_no} one="# reviewer" other="# reviewers" />
                </span>
                . Invite more team members to distribute references.
              </Trans>
            ) : (
              <Trans>
                To start screening process please set up Inclusion and Exclusion Criteria
              </Trans>
            )}
          </div>
        </React.Fragment>
      )}
      <ReferencesDistributionDialog
        isOpen={distributionDialogOpen}
        studies={studies}
        teamMembers={teamMembers}
        screeningType={screeningType}
        onClose={onCloseDistributionDialog}
        onApply={handleDistribute}
        isRedistribution={returnedReferences}
      />
    </div>
  );
};

export default ReferencesDistribution;
