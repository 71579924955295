import { useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { some, union } from 'lodash/fp';
import { useMemo, useState } from 'react';
import { DomainItem, UserKeyword } from '../../common/types';

export const KEYWORDS_GENERAL_CATEGORIES = ['All', 'Uncategorized'];

interface IKeywordsQueryResult {
  domains: DomainItem[];
  keywords_set: UserKeyword[];
}

interface IUseKeywordsListQueryParams<Variables> {
  defaultDomain?: string;
  defaultVariable?: string;
  additionalFilterFn?: (keyword: UserKeyword) => boolean;
  query: DocumentNode;
  useAdditionalFilter?: boolean;
  variables?: Variables;
}

interface ActiveTreeItem {
  domain: string;
  variable: string | null;
}

interface IUseKeywordsListQueryResult<Variables> {
  activeTreeItem: ActiveTreeItem;
  domains: DomainItem[];
  error?: ApolloError;
  filteredKeywords: UserKeyword[];
  keywordsToShow: UserKeyword[];
  loading: boolean;
  refetch: (variables?: Variables) => Promise<ApolloQueryResult<IKeywordsQueryResult>>;
  searchQuery: string;
  setActiveTreeItem: (newActiveTreeItem: ActiveTreeItem) => void;
  setSearchQuery: (newQuery: string) => void;
}

export function useKeywordsListQuery<Variables = {}>(
  options: IUseKeywordsListQueryParams<Variables>
): IUseKeywordsListQueryResult<Variables> {
  const additionalFilterFn = options.additionalFilterFn ?? (() => true);
  const defaultDomain = options.defaultDomain ?? 'All';
  const defaultVariable = options.defaultVariable ?? null;
  const { query } = options;
  const useAdditionalFilter = options.useAdditionalFilter ?? false;
  const variables = options.variables ?? ({} as Variables);

  const [activeTreeItem, setActiveTreeItem] = useState<ActiveTreeItem>({
    domain: defaultDomain,
    variable: defaultVariable,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { domain: currentDomain, variable: currentVariable } = activeTreeItem;

  const { data, error, loading, refetch } = useQuery<IKeywordsQueryResult, Variables>(query, {
    pollInterval: 2000,
    variables,
  });
  const keywords: UserKeyword[] = loading ? [] : data?.keywords_set ?? [];
  const domains = loading ? [] : data?.domains ?? [];

  const filteredKeywords = useMemo(
    () =>
      keywords.filter((keyword) => {
        if (useAdditionalFilter && !additionalFilterFn(keyword)) {
          return false;
        }
        const inTitleSearch = keyword.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
        const inKeywordsSearch = some<string>(
          (keywordStr) => keywordStr.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
          union(keyword.excluded, keyword.included)
        );
        return inTitleSearch || inKeywordsSearch;
      }),
    [additionalFilterFn, keywords, searchQuery, useAdditionalFilter]
  );

  const keywordsToShow: UserKeyword[] = useMemo(
    () =>
      filteredKeywords.filter((keyword) => {
        const sameDomains =
          currentDomain === 'All' ||
          (currentDomain === 'Uncategorized' && keyword.domain === null) ||
          keyword.domain === currentDomain;
        const sameVariables = currentVariable ? keyword.variable === currentVariable : true;
        return sameDomains && sameVariables;
      }),
    [currentDomain, currentVariable, filteredKeywords]
  );

  return {
    activeTreeItem,
    domains,
    error,
    filteredKeywords,
    keywordsToShow,
    loading,
    refetch,
    searchQuery,
    setActiveTreeItem,
    setSearchQuery,
  };
}
