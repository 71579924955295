/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useApolloClient, useMutation, useSubscription } from '@apollo/react-hooks';
import { Button, Colors, Divider, Intent, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans, Plural } from '@lingui/macro';
import gql from 'graphql-tag';
import { clamp, compose, get, isEmpty, map, pick, reject, trim } from 'lodash/fp';
import React, { useCallback, useEffect } from 'react';
import { fancyScrollCss, lightGray1bg, lightGray4bg, lightGray5bg } from '../../../common/styles';
import {
  AppContentProps,
  ReferenceAttachment,
  ReferenceLog,
  TReferenceLogType,
} from '../../../common/types';
import ErrorScreen from '../../common/error_screen';
import PDFViewer from '../../pdf_viewer/pdf_viewer';
import { useI18n, useSetState } from '../../../lib/utils';
import AppToaster from '../../../lib/toaster';
import { getStudyIdFromReference } from '../helpers';
import { useKeycloak } from '../../../keycloak';
import AttachmentsControls from './attachments_controls';
import DocPageToggles from '../../screening/full_text/doc_page_toggles';
import {
  ZoomToggle as PDFZoomToggle,
  PageToggles as PDFPages,
  SearchToggle as PDFSearchToggle,
  RotationToggle as PDFRotationToggle,
} from '../../pdf_viewer/controls';
import DocZoomToggles from '../../screening/full_text/doc_zoom_toggles';
import ReferencesColumn from './references_column';
import AttachmentsList from './attachments_drawer';
import ConfirmationDialog from '../../common/confirmation_dialog';
import { IBibliographicDetailsData } from './bibliographic_details';

const EMPTY_LIST = [];

const unassignedAttachmentCss = css`
  ${lightGray5bg};
  .screen-title {
    font-size: 28px;
    padding: 6px 14px;
    color: ${Colors.DARK_GRAY5};
  }
`;

const magnifyingGlassCss = css`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  min-width: 40px;
  min-height: 40px;
`;

const unassignedReferenceAttachmentsSubscription = gql`
  subscription unassignedReferenceAttachments($projectId: uuid!) {
    attachments: reference_attachment(
      where: { project_id: { _eq: $projectId }, reference_id: { _is_null: true } }
      order_by: { filename: asc }
    ) {
      filename
      key
    }
  }
`;

type TUnassignedAttachmentData = {
  attachments: ReferenceAttachment[];
};

const updateReferenceAttachmentReferenceMutation = gql`
  mutation updateReferenceAttachmentReference(
    $fileKey: String!
    $referenceId: uuid!
    $logData: jsonb!
  ) {
    update_reference_attachment_by_pk(
      pk_columns: { key: $fileKey }
      _set: { reference_id: $referenceId }
    ) {
      key
      reference_id
    }
    insert_reference_details_log_one(object: { reference_id: $referenceId, data: $logData }) {
      reference_id
      data
    }
    update_claimed_references(
      where: { reference_id: { _eq: $referenceId } }
      _set: { completed: true }
    ) {
      returning {
        reference_id
        completed
      }
    }
  }
`;

const deleteReferenceAttachmentMutation = gql`
  mutation deleteReferenceAttachment($fileKeys: [String!]!) {
    delete_reference_attachment(where: { key: { _in: $fileKeys } }) {
      returning {
        key
      }
    }
  }
`;

const getProjectStagesDataQuery = gql`
  query getProjectStagesData($projectId: uuid!) {
    project_by_pk(id: $projectId) {
      id
      stages(order_by: { order_number: asc }, limit: 1) {
        id
        order_number
      }
    }
  }
`;

const getSimilarStudyIdsQuery = gql`
  query getSimilarStudyIds($studyIdPattern: String!) {
    study(where: { id: { _ilike: $studyIdPattern } }, order_by: { id: asc }) {
      id
    }
  }
`;

// every `reference` is bound to `study`, so we need to create a new study record too. To achieve a
// single-transaction mutation we nest several entities creation: study -> reference and upsert the
// reference-attachment via a complex $studyInput (see TCreateAndAssignNewReferenceMutationInput)
const createAndAssignNewReferenceMutation = gql`
  mutation createAndAssignNewReference($studyInput: study_insert_input!) {
    insert_study_one(object: $studyInput) {
      id
      current_stage_id
      references {
        id
        reference_attachments {
          key
          reference_id
        }
      }
    }
  }
`;

type TCreateAndAssignNewReferenceMutationInput = {
  id: string;
  project_id: string;
  current_stage_id: string;
  references: {
    data: {
      attrs: object;
      title: string;
      reference_attachments: {
        data: {
          key: string;
          filename: string;
          project_id: string;
        };
        on_conflict: object;
      };
      logs: {
        data: {
          data: ReferenceLog['data'];
        }[];
      };
    };
  };
};

interface IUnassignedAttachmentsState {
  activeAttachment: number | null;
  confirmRemovalFor: string[] | null;
  attachmentsListVisible: boolean;
}

interface IUnassignedAttachmentsProps extends AppContentProps<{ projectId: string }> {}

const UnassignedAttachments: React.FC<IUnassignedAttachmentsProps> = ({ match }) => {
  const { projectId } = match.params;
  const i18n = useI18n();
  const apolloClient = useApolloClient();
  const { user } = useKeycloak();
  const [state, setState] = useSetState<IUnassignedAttachmentsState>({
    activeAttachment: null,
    confirmRemovalFor: null,
    attachmentsListVisible: false,
  });
  const { activeAttachment, confirmRemovalFor, attachmentsListVisible } = state;
  const [setAttachmentReferenceId, { loading: settingReferenceId }] = useMutation<
    any,
    {
      fileKey: string;
      referenceId: string;
      logData: { type: TReferenceLogType; [key: string]: any };
    }
  >(updateReferenceAttachmentReferenceMutation);
  const [removeReferenceAttachment, { loading: deletingAttachments }] = useMutation<
    any,
    { fileKeys: string[] }
  >(deleteReferenceAttachmentMutation);

  const [createAndAssignReference, { loading: creatingAndAssigningReference }] = useMutation<
    any,
    { studyInput: TCreateAndAssignNewReferenceMutationInput }
  >(createAndAssignNewReferenceMutation);

  const {
    data: attachmentsData,
    error,
    loading,
  } = useSubscription<TUnassignedAttachmentData>(unassignedReferenceAttachmentsSubscription, {
    variables: { projectId },
  });

  const attachments = get('attachments', attachmentsData) ?? EMPTY_LIST;

  const assignReferenceToAttachment = (referenceId: string) => {
    if (activeAttachment == null) {
      return Promise.reject('There is no active attachment to assign');
    }
    const fileKey = attachments[activeAttachment].key;
    const attachmentFilename = attachments[activeAttachment].filename;

    return setAttachmentReferenceId({
      variables: {
        referenceId,
        fileKey,
        logData: {
          type: 'attachment_assigned',
          key: fileKey,
          filename: attachmentFilename,
        },
      },
    }).then(() => setState({ activeAttachment: attachments.length > 1 ? 0 : null }));
  };

  const handleAttachmentDelete = () => {
    if (confirmRemovalFor) {
      removeReferenceAttachment({ variables: { fileKeys: confirmRemovalFor } }).finally(() => {
        setState({
          confirmRemovalFor: null,
          activeAttachment: null,
        });
      });
    }
  };

  const handleActiveAttachmentChange = useCallback(
    (attachmentIdx: number) => {
      setState({
        activeAttachment: isEmpty(attachments)
          ? null
          : clamp(0, attachments.length - 1, attachmentIdx),
      });
    },
    [setState, attachments]
  );

  const createAndAssignNewReference = async (referenceFields: IBibliographicDetailsData) => {
    if (activeAttachment == null) return;

    // we need 3 important pieces of information to be able to create study->reference and bind
    // created reference to reference attachment: study id, study stage, attachment filename
    try {
      // prepare new reference input
      const { authors, title, ...refAttrs } = referenceFields;
      const referenceInput = {
        title,
        attrs: {
          ...refAttrs,
          authors: reject(({ lastName }) => isEmpty(lastName.trim()), authors),
        },
      };
      // new study id must not conflict with existing studies
      const baseStudyId = ((authors[0]?.lastName ?? '') + refAttrs.year ?? '')
        .replace(' ', '')
        .toLowerCase();
      const { data: studyIdsData } = await apolloClient.query({
        query: getSimilarStudyIdsQuery,
        variables: { studyIdPattern: `${baseStudyId}%` },
      });
      const existingStudyIds = map('id', get('study', studyIdsData) ?? []);
      // use helper to get conflict-free study id using reference data and existing study ids data
      const [studyId] = getStudyIdFromReference(referenceInput, existingStudyIds);

      // newly created reference should land in the 1st stage
      const { data: stagesData } = await apolloClient.query({
        query: getProjectStagesDataQuery,
        variables: { projectId },
      });
      const firstStageId = get('project_by_pk.stages[0].id', stagesData);
      if (firstStageId == null) throw new Error('Failed to fetch stage information');

      // attachment filename is needed for the upsert mutation we are using
      const { filename, key } = attachments[activeAttachment];

      const userData = pick(['id', 'name'], user);

      // all data collected, fire the mutation
      await createAndAssignReference({
        variables: {
          studyInput: {
            id: studyId,
            project_id: projectId,
            current_stage_id: firstStageId,
            references: {
              data: {
                ...referenceInput,
                reference_attachments: {
                  data: {
                    key,
                    filename,
                    project_id: projectId,
                  },
                  on_conflict: {
                    constraint: 'reference_attachment_pkey',
                    update_columns: 'reference_id',
                  },
                },
                logs: {
                  data: [
                    {
                      data: {
                        type: 'attachment_assigned',
                        user: userData,
                        key,
                        filename,
                      },
                    },
                    {
                      data: { type: 'reference_uploaded', user: userData },
                    },
                  ],
                },
              },
            },
          },
        },
      });
    } catch (error) {
      AppToaster.show({
        message: i18n._(t`Failed to create reference`),
        intent: Intent.WARNING,
      });
    }
  };

  const handleAttachmentsListClose = useCallback(() => {
    setState({ attachmentsListVisible: false });
  }, [setState]);

  useEffect(() => {
    if (activeAttachment == null && !isEmpty(attachments)) {
      setState({ activeAttachment: 0 });
    }
  }, [attachments, activeAttachment, setState]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  if (isEmpty(attachments)) {
    return <NonIdealState icon={IconNames.CLEAN} title={<Trans>No unassigned PDFs found</Trans>} />;
  }

  return (
    <div className="flex-1 flex flex-col overflow-auto" css={unassignedAttachmentCss}>
      <div className="screen-title">
        <Trans>Unassigned PDFs</Trans>
      </div>
      <Divider className="m-0" />
      <div className="flex-1 overflow-hidden flex flex-row">
        <div className="h-full w-7/12 flex-none overflow-y-auto flex flex-col" css={fancyScrollCss}>
          <div className="flex h-12 items-center px-8">
            <Button
              className="w-40"
              text={<Trans>Browse all PDFs</Trans>}
              onClick={() => setState({ attachmentsListVisible: true })}
            />
            <div className="flex-grow flex-shrink-0 text-center">
              {!isEmpty(attachments) && (
                <AttachmentsControls
                  attachmentsCount={attachments.length}
                  current={activeAttachment ?? 0}
                  onChange={handleActiveAttachmentChange}
                />
              )}
            </div>
            <Button
              className="w-40"
              text={<Trans>Delete PDF file</Trans>}
              loading={deletingAttachments}
              onClick={
                activeAttachment == null
                  ? undefined
                  : () => setState({ confirmRemovalFor: [attachments[activeAttachment].key] })
              }
            />
          </div>
          <Divider className="m-0" />
          {activeAttachment != null && (
            <React.Fragment>
              <div className="flex-1 overflow-auto" css={[fancyScrollCss, lightGray4bg]}>
                <PDFViewer
                  fileKey={attachments[activeAttachment].key}
                  searchBarPosition="bottomRight"
                />
              </div>
              <div
                className="flex-0 h-12 flex flex-row items-center justify-center px-3"
                css={lightGray1bg}
              >
                <PDFPages renderer={DocPageToggles} />
                <PDFZoomToggle className="mx-3" renderer={DocZoomToggles} />
                <PDFRotationToggle />
                <PDFSearchToggle css={magnifyingGlassCss} minimal />
              </div>
            </React.Fragment>
          )}
        </div>
        <Divider className="h-full m-0" />
        <ReferencesColumn
          projectId={projectId}
          onAssign={assignReferenceToAttachment}
          assigning={settingReferenceId}
          onCreateAndAssignReference={createAndAssignNewReference}
        />
        <ConfirmationDialog
          icon={IconNames.TRASH}
          confirmBtnText={i18n._(t`Delete`)}
          isOpen={confirmRemovalFor != null}
          onClose={() => setState({ confirmRemovalFor: null })}
          onConfirm={handleAttachmentDelete}
          confirmBtnIntent={Intent.DANGER}
          title={
            <Plural
              value={confirmRemovalFor?.length ?? 0}
              one="Delete unassigned PDF file"
              other="Delete unassigned PDF files"
            />
          }
        >
          <p className="mb-3">
            <Plural
              value={confirmRemovalFor?.length ?? 0}
              one="Are you sure you want to remove unassigned PDF file from the project?"
              other="Are you sure you want to remove unassigned PDF files from the project?"
            />
          </p>
          <p>
            <Plural
              value={confirmRemovalFor?.length ?? 0}
              one="You will be not able to recover it."
              other="You will be not able to recover them."
            />
          </p>
        </ConfirmationDialog>
      </div>
      <AttachmentsList
        isOpen={attachmentsListVisible}
        onClose={handleAttachmentsListClose}
        attachments={attachments}
        onSetActiveAttachment={handleActiveAttachmentChange}
        onDeleteAllAttachments={() => setState({ confirmRemovalFor: map('key', attachments) })}
        deleting={deletingAttachments}
      />
    </div>
  );
};

export default UnassignedAttachments;
