import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { TextArea } from '@blueprintjs/core';
import EditableSection from './editable_section';

const VariableInstructionsEditSection = EditableSection.ofType<string>();

interface IVariableInstructionsEdit {
  instructions: string;
  onSave?: (instructions: string) => Promise<any>;
  saving?: boolean;
  readOnly?: boolean;
}

const VariableInstructionsEdit: React.FC<IVariableInstructionsEdit> = ({
  instructions,
  onSave,
  saving,
  readOnly,
}) => {
  const editRenderer = useCallback(
    (editedInstructions, setEditedInstructions) => (
      <TextArea
        className="w-full"
        autoFocus
        value={editedInstructions}
        onChange={(evt) => setEditedInstructions(evt.target.value)}
      />
    ),
    []
  );

  return (
    <VariableInstructionsEditSection
      title={<Trans>Instructions</Trans>}
      className="mb-3"
      onSave={onSave}
      saving={saving}
      content={instructions}
      editRenderer={readOnly ? undefined : editRenderer}
    />
  );
};

export default VariableInstructionsEdit;
