/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  HTMLInputProps,
  ITagInputProps,
  TagInput,
  TagInputAddMethod,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ChangeEvent, CSSProperties, ReactNode, useCallback, useMemo, useState } from 'react';
import { isString, isEmpty } from 'lodash/fp';
import { keywordsFromInputValue } from '../../lib/task_helpers';
import { useTheme } from '../settings/theme_context';

type TCustomTagInputProps = Omit<ITagInputProps, 'inputProps' | 'inputValue' | 'onInputChange'> & {
  inputValue?: string;
  onInputChange?: (newVal: string) => void;
  inputProps?: Omit<HTMLInputProps, 'value' | 'onChange'>;
  inclusionIntent?: 'desired' | 'undesired';
};

const CustomTagsInput: React.FC<TCustomTagInputProps> = (props) => {
  const {
    inputValue: consumerInputValue,
    onInputChange: consumerChangeHandler,
    inputProps,
    tagProps,
    inclusionIntent,
    onAdd,
    onChange,
    ...rest
  } = props;
  const [editedValue, setEditedValue] = useState('');
  const { statusColors } = useTheme();

  const tagPropsWithStyles = useMemo(() => {
    const [inclusionIntentPrimaryColor, inclusionIntentSecondaryColor] = (() => {
      switch (inclusionIntent) {
        case 'desired':
          return [statusColors.acceptedPrimary, statusColors.acceptedSecondary];
        case 'undesired':
          return [statusColors.rejectedPrimary, statusColors.rejectedSecondary];
        default:
          return [statusColors.neutralPrimary, statusColors.neutralSecondary];
      }
    })();

    const inclusionStyles: CSSProperties = {
      backgroundColor: inclusionIntentPrimaryColor,
      color: inclusionIntentSecondaryColor,
    };

    return { ...(tagProps ?? {}), style: inclusionStyles };
  }, [statusColors, tagProps, inclusionIntent]);

  // We need to use controlled input value for PLUS button, so here we check if consumer controls
  // the input value and if he does, we will use it, otherwise we will control the input value
  // ourselves
  const updateInputValue = useCallback(
    (newVal) => {
      (consumerChangeHandler ?? setEditedValue)(newVal);
    },
    [setEditedValue, consumerChangeHandler]
  );

  const inputValue = useMemo(() => {
    return consumerInputValue ?? editedValue;
  }, [consumerInputValue, editedValue]);

  const handleInputValueChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      updateInputValue(evt.target.value);
    },
    [updateInputValue]
  );

  // since we use controlled input value, we need to manually clear it when keywords are added with
  // PLUS button.
  const handleAdd = useCallback(
    (values: string[], method: TagInputAddMethod = 'default') => {
      if (onAdd == null) return;
      const shouldClearInput = onAdd(values, method);

      if (shouldClearInput) {
        updateInputValue('');
      }
    },
    [updateInputValue, onAdd]
  );

  const handleChange = useCallback(
    (values: ReactNode[]) => {
      if (onChange == null) return;
      // onChange callback is invoked both when items are added and removed. We only want to clear the
      // input when items were added
      const shouldClearInput = props.values.length < values.length;
      onChange(values);

      if (shouldClearInput) {
        updateInputValue('');
      }
    },
    [updateInputValue, onChange, props.values]
  );

  const handleCLick = () => {
    const newValues =
      isString(inputValue) && props.separator !== false
        ? keywordsFromInputValue(inputValue, props.separator)
        : [inputValue];

    const handler = onAdd ? handleAdd : onChange ? handleChange : null;

    handler && handler([...(props.values as string[]), ...newValues]);
  };

  return (
    <TagInput
      {...rest}
      tagProps={tagPropsWithStyles}
      onChange={handleChange}
      onAdd={handleAdd}
      inputProps={{
        ...(inputProps ?? {}),
        value: inputValue,
        onChange: handleInputValueChange,
      }}
      // according to BP docs, inputValue is just a shorthand for inputProps.value we use above,
      // but without passing it here BP seems to be using internal value which produces duplicated
      // input when addOnBlur=true and PLUS button is clicked
      inputValue={inputValue}
      onInputChange={handleInputValueChange}
      rightElement={
        props.disabled ? undefined : (
          <Button
            small
            minimal
            outlined
            icon={IconNames.SMALL_PLUS}
            disabled={isEmpty(inputValue)}
            onClick={handleCLick}
          />
        )
      }
    />
  );
};

export default CustomTagsInput;
