/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button, Colors, FormGroup, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash/fp';
import React, { ReactNode, useCallback, useState } from 'react';
import { DomainItem, KeywordsData, UserKeyword } from '../../common/types';
import i18n from '../../i18n';
import { EMPTY_KEYWORDS_DATA, mergeKeywordsDataAndUserKeywords } from '../../lib/criteria_utils';
import { useCurrCallback, useStatusColor } from '../../lib/utils';
import CustomTagsInput from '../common/custom_tag_input';
import useKeywordsExport from '../hooks/use_keywords_export';
import KeywordsImportDialog from '../keywords/import_dialog/keywords_import_dialog';
import KeywordsForm, { KeywordsFormMode } from '../keywords/keywords_form';

const KEYWORDS_INPUT_PLACEHOLDER = i18n._(t`Type a phrase and hit enter to make it a keyword`);
type TKeywordKind = 'included' | 'excluded';
const keywordKinds = ['included', 'excluded'] as const;

interface IKeywordsEditorProps {
  keywords: KeywordsData;
  domains: DomainItem[];
  onChange: (newKeywords: KeywordsData) => void;
  className?: string;
}

const KeywordsEditor: React.FC<IKeywordsEditorProps> = ({
  keywords,
  onChange,
  domains,
  className,
}) => {
  const { included, excluded } = keywords;
  const exportKeywords = useKeywordsExport();
  const [isKeywordsImportDialogOpen, setIsKeywordsImportDialogOpen] = useState<boolean>(false);
  const [isKeywordsExportDialogOpen, setIsKeywordsExportDialogOpen] = useState<boolean>(false);
  const [keywordInputs, setKeywordInputs] = useState({
    included: '',
    excluded: '',
  });
  const getStatusColor = useStatusColor();

  const edited = !(
    isEmpty(included) &&
    isEmpty(excluded) &&
    isEmpty(keywordInputs.included) &&
    isEmpty(keywordInputs.excluded)
  );

  const handleKeywordsImport = useCallback(
    (userKeywords: UserKeyword[], overwriteExisting: boolean) => {
      const currentKeywords = overwriteExisting
        ? EMPTY_KEYWORDS_DATA
        : keywords ?? EMPTY_KEYWORDS_DATA;
      const mergedKeywords = mergeKeywordsDataAndUserKeywords(currentKeywords, userKeywords);
      onChange(mergedKeywords);
    },
    [keywords, onChange]
  );

  const handleKeywordsChange = (keywordsKind: TKeywordKind) => (changedKeywords: ReactNode[]) => {
    return onChange({
      ...keywords,
      [keywordsKind]: changedKeywords,
    });
  };

  const handleKeywordInputChange = useCurrCallback(
    (keywordsKind: TKeywordKind, value: string) => {
      setKeywordInputs((current) => ({
        ...current,
        [keywordsKind]: value,
      }));
    },
    [setKeywordInputs]
  );

  return (
    <div className={className}>
      <div className="flex flex-row justify-between h-6">
        <span>
          <Trans>Keywords</Trans>
        </span>
        <span>
          <Tooltip content={<Trans>Import existing keywords set</Trans>}>
            <Button
              minimal
              className="mr-2"
              icon={IconNames.IMPORT}
              onClick={() => setIsKeywordsImportDialogOpen(true)}
            />
          </Tooltip>
          <Tooltip content={<Trans>Save these keywords as set</Trans>}>
            <Button
              minimal
              icon={IconNames.FLOPPY_DISK}
              onClick={() => setIsKeywordsExportDialogOpen(true)}
            />
          </Tooltip>
        </span>
      </div>
      <div className="flex flex-row flex-no-wrap">
        {keywordKinds.map((keywordsKind, idx) => {
          const includedKind = keywordsKind === 'included';
          const labelColor = getStatusColor(keywordsKind);

          return (
            <FormGroup
              key={keywordsKind}
              className={`flex-1 ${idx > 0 ? 'ml-3' : ''}`}
              label={
                <span css={{ color: labelColor }}>
                  {includedKind ? (
                    <Trans>Desired keywords</Trans>
                  ) : (
                    <Trans>Undesired keywords</Trans>
                  )}
                </span>
              }
            >
              <CustomTagsInput
                inclusionIntent={includedKind ? 'desired' : 'undesired'}
                values={keywords[keywordsKind]}
                onChange={handleKeywordsChange(keywordsKind)}
                placeholder={KEYWORDS_INPUT_PLACEHOLDER}
                inputValue={keywordInputs[keywordsKind]}
                onInputChange={handleKeywordInputChange(keywordsKind)}
              />
            </FormGroup>
          );
        })}
      </div>
      {edited && (
        <div className="mt-2 flex flex-row items-center">
          <Icon icon={IconNames.INFO_SIGN} className="mr-3" color={Colors.GRAY2} />
          <span className="text-gray-700 text-xs">
            <Trans>
              To highlight all the keywords sharing common core, eg. child and children, please use
              the wildcard ('*') at the end or in the beginning of the core. In this exemplary case
              this would be 'child*'.
            </Trans>
          </span>
        </div>
      )}
      {isKeywordsImportDialogOpen && (
        <KeywordsImportDialog
          onClose={() => setIsKeywordsImportDialogOpen(false)}
          onImport={handleKeywordsImport}
        />
      )}
      {isKeywordsExportDialogOpen && (
        <KeywordsForm
          domains={domains}
          keywordsData={{
            excluded: keywords?.excluded ?? [],
            included: keywords?.included ?? [],
            title: '',
            domain: null,
            variable: null,
          }}
          mode={KeywordsFormMode.InclusionExclusionCriteria}
          onSave={exportKeywords}
          onClose={() => setIsKeywordsExportDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default KeywordsEditor;
