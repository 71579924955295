/** @jsx jsx */
import { Button, Classes, Colors, H6, Icon, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { plural, Trans } from '@lingui/macro';
import Dialog from '../../common/dialog';
import { IconNames } from '@blueprintjs/icons';
import React, { useCallback, useState } from 'react';
import { Buckets, TUploadedFile, uploadMultipleAttachments } from '../../../lib/attachmentUpload';
import { useKeycloak } from '../../../keycloak';
import { ReferenceAttachment, ReferenceLog, TReferenceLogType } from '../../../common/types';
import { MutationFunctionOptions } from '@apollo/react-common';
import { noop, isEmpty } from 'lodash/fp';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useCurrCallback } from '../../../lib/utils';
import AppToaster from '../../../lib/toaster';
import i18n from '../../../i18n';
import { captureException } from '@sentry/browser';
import DownloadButton from '../../common/download_button';

interface PDFAttachmentsDialogProps {
  isOpen: boolean;
  projectId: string;
  referenceId: string;
  referenceAttachments: ReferenceAttachment[];
  onDeletePDF: (deletedAttachmentKey: string) => void;
  onClose: () => void;
  uploadMutationOptions?: MutationFunctionOptions<any, Record<string, any>>;
}

const dialogBodyCss = css`
  .attached-files-header {
    color: ${Colors.GRAY1};
  }
  .upload-icon {
    color: ${Colors.GRAY3};
  }
`;

const InsertAndAssignAttachmentsMutation = loader(
  '../../../graphql/insert_and_assign_attachments.gql'
);

const PDFAttachmentsDialog: React.FC<PDFAttachmentsDialogProps> = ({
  isOpen,
  projectId,
  referenceId,
  uploadMutationOptions,
  referenceAttachments,
  onDeletePDF,
  onClose,
}) => {
  const filesInput = React.createRef<HTMLInputElement>();
  const [dragging, setDragging] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const { user, getToken } = useKeycloak();
  const [insertAndAssignFilesData] = useMutation(InsertAndAssignAttachmentsMutation);

  const isUploading = !isEmpty(uploadingFiles);

  const filesDataMapper = useCallback(
    (file: TUploadedFile) => {
      return {
        project_id: projectId,
        filename: file.originalname,
        key: file.key,
        reference_id: referenceId,
      };
    },
    [projectId, referenceId]
  );

  const logsMapper = useCallback(
    (file: TUploadedFile): Partial<ReferenceLog> => {
      return {
        reference_id: referenceId,
        data: {
          type: 'attachment_assigned' as TReferenceLogType,
          user: {
            id: user.id,
            name: user.name,
          },
          key: file.key,
          filename: file.originalname,
        },
      };
    },
    [referenceId, user.id, user.name]
  );

  const processUploading = useCallback(
    async (files: File[]) => {
      const token = await getToken();
      setUploadingFiles(files.map(({ name }) => name));
      AppToaster.show({
        icon: IconNames.INFO_SIGN,
        intent: Intent.PRIMARY,
        message: <Trans>File upload started.</Trans>,
      });
      try {
        await uploadMultipleAttachments({
          token,
          projectId,
          bucket: Buckets.ReferencesAttachments,
          updateUploadProgress: noop,
          files,
          filesDataMapper,
          logsMapper,
          insertFilesMutation: insertAndAssignFilesData,
          insertFilesMutationVariables: referenceId
            ? { claimedReferencesIds: [referenceId] }
            : undefined,
          insertFilesMutationOptions: uploadMutationOptions,
        });
        AppToaster.show({
          icon: IconNames.TICK,
          intent: Intent.SUCCESS,
          message: i18n._(
            plural({
              value: files.length,
              one: '1 file uploaded successfully.',
              other: '# files uploaded successfully.',
            })
          ),
        });
      } catch (err) {
        captureException(err);
        AppToaster.show({
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
          message: `${i18n._(
            plural({
              value: files.length,
              one: 'Failed to upload 1 file',
              other: 'Failed to upload # files',
            })
          )}: ${(err as Error).toString()}`,
        });
      } finally {
        setUploadingFiles([]);
      }
    },
    [
      filesDataMapper,
      getToken,
      insertAndAssignFilesData,
      logsMapper,
      projectId,
      uploadMutationOptions,
    ]
  );

  const handleDragOver = useCallback(
    (evt: React.DragEvent) => {
      evt.preventDefault();
      if (isUploading) {
        return;
      }
      setDragging(true);
    },
    [isUploading]
  );

  const handleDragLeave = useCallback(
    (evt: React.DragEvent) => {
      evt.preventDefault();
      if (isUploading) {
        return;
      }
      setDragging(false);
    },
    [isUploading]
  );

  const handleDrop = useCallback(
    (evt: React.DragEvent) => {
      evt.preventDefault();
      if (isUploading) {
        return;
      }
      setDragging(false);
      processUploading([...evt.dataTransfer.files]);
    },
    [processUploading, isUploading]
  );

  const handleUploadFiles = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      processUploading([...(evt.target.files || [])]);
    },
    [processUploading]
  );

  const handleSelectFiles = useCallback(() => {
    if (filesInput.current) {
      filesInput.current.click();
    }
  }, [filesInput]);

  const handleDeleteFile = useCurrCallback(
    (deletedAttachmentKey, _evt) => {
      onDeletePDF(deletedAttachmentKey);
    },
    [onDeletePDF]
  );

  return (
    <Dialog
      canEscapeKeyClose={!isUploading}
      canOutsideClickClose={!isUploading}
      className="w-2/5"
      isOpen={isOpen}
      isCloseButtonShown={!isUploading}
      onClose={onClose}
      title={<Trans>PDF attachments</Trans>}
    >
      <div className={Classes.DIALOG_BODY} css={dialogBodyCss}>
        <div className="rounded-lg p-4 mb-4 border border-solid border-gray-500">
          {referenceAttachments.length || uploadingFiles.length ? (
            <React.Fragment>
              <H6 className="attached-files-header">
                <Trans>Attached files</Trans>:
              </H6>
              <ul>
                {referenceAttachments.map(({ key, filename }) => (
                  <li key={key} className="py-2 flex justify-between items-center">
                    <span>{filename}</span>
                    <div className="flex flex-row">
                      <DownloadButton
                        minimal
                        bucketName={Buckets.ReferencesAttachments.name}
                        downloadKey={key}
                        downloadName={filename}
                        text={<Trans>Download</Trans>}
                      />
                      <Button
                        minimal
                        intent={Intent.DANGER}
                        icon={IconNames.TRASH}
                        onClick={handleDeleteFile(key)}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </div>
                  </li>
                ))}
                {uploadingFiles.map((name) => (
                  <li key={name} className="py-2 flex justify-between items-center">
                    <span>{name}</span>
                    <Button loading>
                      <Trans>Delete</Trans>
                    </Button>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <div className="text-lg text-center">
              <Trans>No files attached yet.</Trans>
            </div>
          )}
        </div>
        <div
          className={`flex justify-between items-center rounded-lg px-4 py-8 mt-4 border border-dashed border-gray-500 ${
            dragging ? 'bg-gray-100' : 'bg-gray-200'
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Icon icon={IconNames.CLOUD_UPLOAD} size={48} className="upload-icon ml-4" />
          <span className="text-lg">
            <Trans>Select a PDF file to upload or drag it here.</Trans>
          </span>
          <div>
            <input
              className="hidden"
              type="file"
              multiple
              accept=".pdf"
              ref={filesInput}
              onChange={handleUploadFiles}
            />
            <Button onClick={handleSelectFiles} disabled={isUploading}>
              <Trans>Select file</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div className={`${Classes.DIALOG_FOOTER} text-right`}>
        <Button onClick={onClose}>
          <Trans>OK</Trans>
        </Button>
      </div>
    </Dialog>
  );
};

export default PDFAttachmentsDialog;
