import axios from 'axios';

export const storageUrl =
  process.env.REACT_APP_STORAGE_URL || `https://${window.location.host}/storage`;

interface UploadOptions {
  path?: string;
  projectId?: string;
  token: string;
  progressCb?: (percentage: number) => void;
  bucket: string;
}

export const upload = async <T = any>(
  data: FormData,
  { path, projectId, token, progressCb, bucket }: UploadOptions
) => {
  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progressCb && progressCb(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-path': path ?? '',
      'x-project-id': projectId ?? '',
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.post<T>(`${storageUrl}/${bucket}/upload`, data, config);
};

export const download = async (bucket: string, key: string, token: string, signal?: AbortSignal) =>
  await axios.get(`${storageUrl}/${bucket}/download/${key}`, {
    signal,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
