import { compose, get, isEmpty, map, reduce } from 'lodash/fp';
import {
  TActiveAttributeFilters,
  TActiveScreeningTagFilters,
  TPdfFilter,
  YearFilters,
} from '../../apollo/screening_state';
import { Stage, StageType } from '../../common/types';
import { TSearchWithOperatorObject } from '../../lib/task_helpers';
import { TDomainReasonsData } from '../references/admin';

export function hasFiltersApplied({
  searchPhraseTokens,
  searchTokensWithOperator,
  activeDocumentTypeFilters,
  activeYearFilters,
  activeDecisionCodeFilters,
  activeScreeningTagFilters,
  activePdfFilter,
  activeOnlyWithoutAbstract,
  activeOnlyWithComments,
}: {
  searchPhraseTokens: string[];
  searchTokensWithOperator: TSearchWithOperatorObject[];
  activeDocumentTypeFilters: TActiveAttributeFilters;
  activeYearFilters: YearFilters;
  activeDecisionCodeFilters: TActiveAttributeFilters;
  activeScreeningTagFilters: TActiveScreeningTagFilters;
  activePdfFilter?: TPdfFilter;
  activeOnlyWithoutAbstract?: boolean;
  activeOnlyWithComments?: boolean;
}): boolean {
  return (
    !isEmpty(searchPhraseTokens) ||
    !isEmpty(searchTokensWithOperator) ||
    !isEmpty(activeDocumentTypeFilters) ||
    !isEmpty(activeYearFilters) ||
    !isEmpty(activeDecisionCodeFilters) ||
    !isEmpty(activeScreeningTagFilters) ||
    Boolean(activePdfFilter && activePdfFilter !== 'all') ||
    Boolean(activeOnlyWithoutAbstract) ||
    Boolean(activeOnlyWithComments)
  );
}

export const tiAbMapper = compose(
  map(({ exclusionButtonLabel, exclusionCode, id }) => ({
    id,
    code: exclusionCode,
    label: exclusionButtonLabel,
  })),
  get('forms[0].form.domains')
);

export const fullTextMapper = (field: 'inclusion' | 'exclusion') =>
  compose(
    map(({ id, code, name }) => ({ id, code, label: name })),
    get(`forms[0].form.${field}`)
  );

export function getReasonCodesData(
  stages: (Pick<Stage, 'id' | 'type' | 'completed' | 'study_pools' | 'forms'> | undefined)[]
): TDomainReasonsData {
  return reduce(
    (acc: TDomainReasonsData, stage) => {
      if (stage == null) return acc;
      switch (stage.type) {
        case StageType.PreliminaryScreening:
          acc['preliminary'].push(...tiAbMapper(stage));
          break;
        case StageType.TitlesAbstractScreening:
          acc['titlesAbstracts'].push(...tiAbMapper(stage));
          break;
        case StageType.FullTextScreening:
          acc['fullText']['inclusion'].push(...fullTextMapper('inclusion')(stage));
          acc['fullText']['exclusion'].push(...fullTextMapper('exclusion')(stage));
      }
      return acc;
    },
    {
      preliminary: [],
      titlesAbstracts: [],
      fullText: {
        inclusion: [],
        exclusion: [],
      },
    },
    stages
  );
}
